import React, {Component} from "react";
// import "admin/administrator/CustomProductPrices.css";
// import "admin/administrator/CustomElements.css";
import _ from "lodash";
import { useTranslation } from 'react-i18next';

function CustomProductPrices(props) {

    const { t, i18n } = useTranslation();
    return ( 
        <div className="text-right ml-auto">
            <p className="fw-700">{t("Total")}: {props.total}</p>
            <p>{t("All prices are excluding VAT")}</p>
        </div>
    );
}

export default CustomProductPrices;