import React, { Component } from "react";
import CompareItemsContent from "components/CompareItemsContent";

export default class CompareItems extends Component
{
    constructor(props)
    {
        super(props);
    }

    render()
    {
        return (
            <div className="content">
                <CompareItemsContent />
            </div>
        );
    }
}