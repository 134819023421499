import React, {useEffect} from 'react';
import {ProtectedRoute} from 'components/catalogue/protected.route';
import Catalogue from "components/pages/Catalogue";
import ShoppingCart from "components/pages/ShoppingCart";
import Checkout from "components/pages/Checkout";
import CompareItems from "components/pages/CompareItems";
import ManageHubs from "admin/administrator/ManageHubs";
import Login from 'components/pages/Login';
import Registration from 'components/pages/Registration';
import MasterPage from 'components/layouts/MasterPage';
import AdminPanel from "admin/index";
import userAccount from "account/index";
import ScrollTop from "components/catalogue/ScrollTop";
import InitRedux from "redux/InitRedux";
import SearchProducts from "components/pages/SearchProducts";
import ColoredScrollbars from "components/catalogue/ColoredScrollbars";
import ComponentSamples from "ComponentSamples";
import "sass/main.scss";
import $ from "jquery";
import AlertSessionTimeOutModal from 'components/catalogue/AlertSessionTimeOutModal';
import Auth from 'components/pages/Auth';
import {BrowserRouter as Router, Route, Link, Switch, useLocation } from "react-router-dom";

function AppRouter (props) {

    let location = useLocation();

    useEffect(() => {
        closeSidebar();
    }, [location]);

    function closeSidebar() {
        let element = document.getElementsByClassName('hamburger-container')[0];
        if(document.contains(element)) {
            element.classList.remove('active');
        }
    }

    return (
        <ScrollTop>
            <InitRedux/>
                <MasterPage>
                    <Route path="/Login" exact component={Auth}/>
                    <Route path="/" exact component={SearchProducts}/>
                    <Switch>
                        {/*<ProtectedRoute path="/Catalogue" exact component={Catalogue} />*/}
                        <Route path="/News" exact component={Catalogue}/>
                        <Route path="/Media" exact component={Catalogue}/>
                        <Route path="/Contact" exact component={Catalogue}/>
                        <Route path="/ManageHubs" component={ManageHubs}/>
                        <Route path="/Registration" exact component={Registration}/>
                        <ProtectedRoute path="/ComponentSamples/:componentName?" exact component={ComponentSamples}/>
                        <ProtectedRoute path="/shoppingCart" exact component={ShoppingCart}/>
                        <ProtectedRoute path="/Checkout" exact component={Checkout}/>
                        <ProtectedRoute path="/compareItems" exact component={CompareItems}/>
                        <ProtectedRoute path="/account" component={userAccount}/>
                        <ProtectedRoute path="/search" component={SearchProducts}/>
                        <ProtectedRoute path="/admin" component={AdminPanel}/>
                    </Switch>
                </MasterPage>
        </ScrollTop>
    );
}

export default AppRouter