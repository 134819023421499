import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Notifications.css';

class Notifications extends Component {
    constructor(props)
    {
        super(props);
    }

    render() {
        let notifications = 0;

        return (
            <span className={"notification-icon icon-bell"}>
                {
                    this.props.token ?
                        <span className="compare-items-length">{notifications}</span>
                        : null
                }
            </span>
        );
    }
}

const mapStateToProps = state => ({
})

export default connect(mapStateToProps)(Notifications);