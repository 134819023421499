import React, {Fragment} from "react";
import _ from "lodash";
import { t } from 'i18next';
import Loading from "admin/administrator/LoadingComponent/Loading.component";

const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const TabDataList = ({items, columns, title, loading = true}) => (
    <Fragment>
        <Loading loading={loading} />
        {
            items.length > 0 ?
                <div className="table mh-270">
                    <table className="table__table">
                        <thead className="table__thead" style={{ position: "sticky", top: 0 }}>
                            <tr className="table__tr">
                                {
                                    columns && columns.map(column => <th className="table__th textLeft">{t(capitalizeFirstLetter(column))}</th>)
                                }
                            </tr>
                        </thead>
                        <tbody className="table__tbody">
                        {
                            _.map(items, vehicle => {
                                return <tr key={`${title}_${vehicle.id}`} className="table__tr">
                                    {
                                        _.map(vehicle, (value, key) => {
                                            return columns.includes(capitalizeFirstLetter(key)) && <td className="table__td" data-attr={title}>
                                                <div className={"full-info-account"}>
                                                    <div>{ key === 'Kw' || key === 'Hp' ? parseInt(value) : value}</div>
                                                </div>
                                            </td>
                                        })
                                    }
                                </tr>
                            })
                        }
                        </tbody>
                    </table>
            </div>
            : <p className="NoProducts">{t("No more "+ title +" available")}</p>
        }
    </Fragment>
);

export default TabDataList;