import React from 'react'
import CustomSortableItem from "admin/administrator/CustomSortableItem";
import {SortableContainer, arrayMove} from 'react-sortable-hoc';

const SortableList = SortableContainer(({items, maxHeight, selectedId, disabled, onClick, onChange}) => {
  return (
    <ul className={"sortable"} style={{maxHeight: maxHeight}}>
      { 
        items &&
          items.map((value, index) => (
            <CustomSortableItem
              key={`item-${index}`}
              index={index}
              disabled={disabled}
              selectedId={selectedId}
              value={value}
              onClick={onClick}
              onChange={onChange}
            /> 
          ))
      }
    </ul>
  );
});

const CustomSortable = props => {
    const onSortEnd = ({oldIndex, newIndex}) => {
    let items = arrayMove(props.items, oldIndex, newIndex);
    props.callBack(items);
      
  };

  return ( <SortableList
      items={props.items}
      disabled={props.disabled || false}
      maxHeight={props.maxHeight || "initial"}
      useDragHandle={true}
      selectedId={props.selectedId || null}
      // Handlers ==>
      onChange={props.onChangeCheckBox}
      onClick={props.onClick}
      onSortEnd={onSortEnd}
      // <== Handlers
      lockAxis="y">
    </SortableList>
  ); 
}

export default CustomSortable;