import React, {Fragment} from "react";
import "components/CustomDate.css";

const CustomDate = (props) => {
    return (
        <Fragment>
            <span className={"customDate"}>{props.date}</span>
        </Fragment>
    )
}

export default CustomDate;