import React, {Component} from "react";
import VehicleLookups from "admin/administrator/VehicleLookups";
import BestSellers from "admin/administrator/BestSellers/BestSellers.js";
import ActivityTrends from "admin/administrator/ActivityTrends";
import SalesMixedChart from "admin/administrator/SalesMixedChart";
// import "admin/administrator/DashboardAnalytics.css";
import LostSalesWrapper from "admin/administrator/LostSalesWrapper";
import TopAccountsWrapper from "admin/administrator/TopAccounts/TopAccountsWrapper";
import LoginTotalsWrapper from "admin/administrator/LoginTotals/LoginTotalsWrapper";
import LookupsTotal from "admin/administrator/LookupsTotal";
import CustomContainer from 'custom_components/CustomContainer.js';
import TopProductSections from "admin/administrator/TopProductSections";
import TopVehicleMakes from "admin/administrator/TopVehicleMakes";

export default class DashboardAnalytics extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div>
                <div className={"totals-wrapper"}>
                    <div className={"total-item"}>
                        <LookupsTotal/>
                    </div>
                    <div className={"total-item"}>
                        <LoginTotalsWrapper/>
                    </div>
                    <div className={"total-item"}>
                        <TopAccountsWrapper/>
                    </div>
                </div>
                <div className={"analytics-wrapper"}>
                    <div className={"analytics-item"}>
                        <SalesMixedChart/>
                    </div>
                    <div className={"analytics-item"}>
                        <BestSellers/>
                    </div>
                    <div className={"analytics-item"}>
                        <VehicleLookups/>
                    </div>
                    <div className={"analytics-item"}>
                        <LostSalesWrapper/>
                    </div>
                </div>
            </div>
        );
    }
}