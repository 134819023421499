import React, { Component, Fragment } from "react";
import 'components/ShoppingCartContent.css';
import 'components/CustomElements.css';
import { connect } from "react-redux";
import ShoppingCartProduct from "components/ShoppingCartProduct";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import CartSummary from "components/CartSummary";

class ShoppingCartContent extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            isEmpty: false,
            shoppingCart:[
            ]
        }


    }
    // componentDidMount() {
    //     this.getShoppingCartItems();
    // }

    componentWillReceiveProps(nextProps) {
        if (nextProps.shoppingCart.length == 0) {
            this.setState({
                isEmpty: true
            });
        }
    }

    // getShoppingCartItems() {
    //     axios.get("/catalogue/getCart")
    //     .then(response => {
    //         this.setState({shoppingCart:response.data});
    //     })
    // }
    render()
    {
        let shoppingCart = this.props.shoppingCart;

        return (
            <div className="shoppingCartWrapper">
                <div className="shopping-cart-header">
                    <div className={"container"}>
                        <div className={"row"}>
                            <h1 className="centered-title-h1 heading">SHOPPING CART</h1>
                        </div>
                    </div>
                </div>
                <div className="shopping-cart-body">
                <div className="container">
                    {
                        shoppingCart.length > 0 ?
                        <div className="row">
                            <div className="col-md-8 cart-table-left">
                                <div className="products-list">
                                    <div className="products-list-wrapper">
                                        <div className="products-in-card-label">
                                            <ul >
                                                <li className="line-w-17">PART NUMBER</li>
                                                <li className="line-w-17">LOOKUP</li>
                                                <li className="line-w-17">DESCRIPTION</li>
                                                <li className="line-w-17">QUANTITY</li>
                                                {
                                                    this.props.settings.show_prices ? 
                                                        <Fragment>
                                                            <li className="line-w-17">UNIT PRICE</li>
                                                            <li className="line-w-17">TOTAL PRICE</li> 
                                                        </Fragment>
                                                    : null
                                                }
                                            </ul>
                                        </div>
                                        {
                                            shoppingCart.map((product) => {
                                                return <ShoppingCartProduct settings={this.props.settings} key={product.id} product={product}/>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                this.props.settings.show_prices ?
                                    <div className="col-md-4 cart-summary-right">
                                        <CartSummary />
                                    </div>
                                :null    
                            }
                        </div> :
                        this.state.isEmpty ?
                        <div className="row">
                            <div className="empty-list-wrapper">
                                <div className="empty-list-alert">
                                    <h5>Shopping cart is empty</h5>
                                    <NavLink className={"custom-gray-btn"} to="/Search">Back to Catalogue</NavLink>
                                </div>
                            </div>
                        </div> : null
                    }
                </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    shoppingCart: state.shoppingCart,
    settings: state.settings
})

export default connect(mapStateToProps)(ShoppingCartContent);