import React, { Fragment, useState, useEffect, useCallback} from "react";
import CustomReactTable from "admin/administrator/CustomReactTable.js";
import CustomModal from "admin/administrator/customModal.js";
import CustomButton from "admin/administrator/CustomButton";
import UserAddress from "admin/administrator/UserAddress";
import CustomProductPrices from "admin/administrator/CustomProductPrices";
import axios from "axios1";
import _ from "lodash";
import { render } from "@testing-library/react";
import { components } from "react-select";
import { func } from "prop-types";
import Modal from "components/Modal";
import { useTranslation } from 'react-i18next';

const CustomVRMLookups = (props) => {
    const [show, setShow] = useState(false);
    const [data, setData] = useState([]);
    const [orderMeta, setOrderMeta] = useState([]);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(0);
    const [printData, setPrintData] = useState([]);
    const { t, i18n } = useTranslation();


    const columns = React.useMemo(
        () => [
            {
                Header: t("Part Number"),
                accessor: "part_number",
                className: "textLeft"
            },
            {
                Header: t("Lookup"),
                accessor: "lookup",
                className: "textUpperCase textLeft"
            },
            {
                Header: t("Location"),
                accessor: "location",
                className: "textLeft"
            },
            {
                Header: t("Description"),
                accessor: "section",
                className: "textLeft",
                Cell: ({value, row}) => {
                    return <ul style={{'list-style-type': 'none'}}>
                                <li>{value}</li>
                                {
                                    row.original.description ?
                                        <li style={{marginTop: '4px'}}>{row.original.description}</li> : null
                                }
                            </ul>
                }
            },
            {
                Header: t("QTY"),
                accessor: "quantity",
                className: "textRight"
            },
            {
                Header: t("Unit Price"),
                accessor: "price",
                className: "textRight",
                Cell: ({ row, value }) => {
                    return <span>{value}</span>;
                    // return <span>{row.original.symbol + " " + value}</span>;
                },
                
            },
            {
                Header: t("Total Price"),
                accessor: "total",
                className: "textRight",
                Cell: ({ row, value }) => {
                    return <span>{ value }</span>;
                    // return <span>{row.original.symbol + " " +  value }</span>;
                },
            },
        ]
    );

    const accountAdresses = (order_meta) => {
        return {
            name: order_meta.name,
            address1: order_meta.address_1,
            town: order_meta.town,
            country: order_meta.county,
            postal_code: order_meta.postal_code
        };
    }

    const getOrder = useCallback((order_id, page = 1, size = 5) => {
        let byBranch = typeof props.by_branch != "undefined" ? '&by_branch=' + props.by_branch : "";
        axios.get(`catalogue/orders/${order_id}?page=${page}&size=${size}${byBranch}`)
        .then((response) => {
            if (response.data.success) {
                var order_meta =  response.data.order_meta && response.data.order_meta[0] ? response.data.order_meta[0] : [];
                setOrderMeta(order_meta)
                setData(response.data.order_details);
                setPage(page);
                setPages(response.data.pages);
            }
        })
    }, []);

    const getPrintData = (order_id, page = 1, size = 9999) => {
        axios.get(`catalogue/orders/${order_id}?page=${page}&size=${size}`)
            .then((response) => {
                if(response.data.success) {
                    setPrintData(response.data.order_details);
                    window.print();
                }
            })
    }

    const closeModal = () => {
        setShow(false);
        if(props?.setRequestInterval) props.setRequestInterval();
    }

    return (
        <Fragment>
            <CustomButton title={props.title} type={"blue"} width={"80px"} onClick={() => {
                getOrder(props.value)
                setShow(true);
                if(props?.clearRequestInterval)
                    props.clearRequestInterval();
            }}/>
            <Modal size="xl" title={t("order details")} type="light" printable={true} active={show} closeModal={() => closeModal()} backdrop={props.backdrop}>
                <UserAddress data={{from:t("From"), title:t("Invoice to"), info: accountAdresses(orderMeta)}}
                            withOrderInfo = {true}
                            orderDate = {orderMeta.date}
                            orderId = {orderMeta.order_id}
                            userName = {orderMeta.name}
                            companyInfo={props.companyInfo}/>
                <CustomReactTable columns={columns} 
                                data={data} 
                                pagination={true}
                                page={page}
                                pages={pages}
                                getData={getOrder.bind(this, orderMeta.order_id)}/>
                <CustomReactTable columns={columns} 
                                data={printData} 
                                print={1}/>
                <div className="flex flex-justify-start mt-24">
                    <div>
                        {orderMeta.additional_notes && (<div className="additional-info mb-24">{orderMeta.additional_notes}</div>)}
                        {
                            props.printable &&
                                <div className="flex">
                                    <button className="btn btn--primary no-print" onClick={() => getPrintData(orderMeta.order_id)}>{ t("Print") }</button>
                                </div>
                        }
                    </div>
                    <CustomProductPrices total={orderMeta.total} />
                </div>
            </Modal>
        </Fragment>
    );
}

export default CustomVRMLookups;
