import React from "react";
import "account/Orders/ReturnForm/Components/layout.css";

const Layout = React.forwardRef( (props, ref) => {
    return (
        <div className="pdf-wrapper pageA4" ref={ref}>
            { props.children }
        </div>
    );
});

export default Layout;