import React, {useRef} from "react";
import 'chartjs-plugin-labels'
// import "admin/administrator/OrderStatus.css";
import PageTitle from "admin/administrator/PageTitle";
import OrderStatusTable from "admin/administrator/OrderStatusTable";
import ReactToPrint from "react-to-print";
import CustomButton from "./CustomButton";

const OrderStatus = (props) => {
    const componentRef = useRef();
    return (
        <div className={"dashboard-wrapper admin-dashboard"}>
        <ReactToPrint
            trigger={() =>  <CustomButton title="print" type="blue"/>}
            content={() => componentRef.current}
        />
        <OrderStatusTable 
            orderDetails={props.orderDetails}
            products={props.products}
            ref={componentRef}
            >
        </OrderStatusTable>
      </div>
    );
};

export default OrderStatus;