import React, { Component } from "react";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
//import VehicleLookups from "admin/administrator/VehicleLookups";
// import "admin/administrator/Charts.css";

export default class Charts extends Component
{
    constructor(props)
    {
        super(props);
    }

    render() {

        return (
            <div className={"dashboard-page-wrapper"}>
                <div className={"dashboard-page-navigations"}>
                    <div>
                        <i className="fas fa-chart-line"></i>
                        <span> Top Charts</span>
                    </div>
                    <div>
                        <button className="back-btn" onClick={() => this.props.history.goBack()}>
                            Go Back
                        </button>
                    </div>
                </div>
                <div className={"dashboard-page-body"}>
                    <div className="stats chart-stats">
                        <div className="stat">
                            <div className={"stat-heading"}>Top Sales</div>
                            <div className="stat-content">
                                <div>
                                    <NavLink to="/admin/salesDetails" className="stat-btn"><i className="fas fa-chart-pie"></i>Details</NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="stat">
                            <div className={"stat-heading"}>Top Lookups</div>
                            <div className="stat-content">
                                <div>
                                    <NavLink to="/admin/LookupCharts" className="stat-btn"><i className="fas fa-chart-pie"></i>Details</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<VehicleLookups />*/}
            </div>
        )
    }
}