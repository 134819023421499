import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from "axios1";
import $ from "jquery";
import 'components/ChangeThemeIcon.css';
import Switch from "react-switch";
var store = require('store');

class ChangeThemeIcon extends Component {

    constructor(props)
    {
        super(props);
        this.state = {
            colors: {
                'dark': 'Dark - Transparent blue',
                'light': 'White - Light blue'
            },
            visible: false,
            theme:false
        }
        this.changeTheme = this.changeTheme.bind(this);
    }

    toggleThemeDropdown() {
        let visible = !this.state.visible;
        this.setState({visible: visible});
    }

    changeTheme(property) {
        let newObj = {};
        newObj[property] = !this.state.theme;
        let theme = (newObj[property])? "light":'dark';
        this.setState(newObj);
        axios.post("/catalogue/account/updateTheme", {theme: theme}).then((response) => {
            if(response.data.success) {
                $("body").removeAttr('class');
                $(".theme-icon").removeAttr('class');
                $("body").addClass(response.data.theme);
                $(".theme-icon").addClass(response.data.theme);
                this.props.changeTheme(response.data.theme);
                this.setState({"visible": false});
            }
        });
    }

    render() {
        let theme = true;
        if(this.props.settings.theme === '') {
            theme = false;
        }
        this.state.theme = theme;
        return (
            <Switch
            className={"light-datk-switcher"}
            width={38}
            height={20}
            onChange={() => {
                this.changeTheme('theme')
            }}
            checked={this.state.theme}/>

        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => {
    return {
        changeTheme: (theme) => dispatch({type: 'CHANGE_THEME', payload: theme}),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangeThemeIcon);