import React from "react";

function Block({title, subtitle, type, children, style, disabled}) {
    return (
        <div className={"block block--type-" + type + (disabled ? " block--disabled" : "")} style={style}>
            <div className="block__title">
                {title}
                {subtitle && <span className="block__subtitle">{subtitle}</span>}
            </div>
            <div className="block__content">{children}</div>
       </div>
    )
}

Block.defaultProps = {
    type: 1,
    title: "Enter block title",
    subtitle: "",
    style: {},
    disabled: false
}

export default Block