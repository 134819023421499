import React, {Fragment, useState, useEffect} from 'react';
import "./MainSlide.css";
import { useTranslation } from 'react-i18next';

function AddSlide(props) {
    const { t, i18n } = useTranslation();
    return (
        <div className="add-slide" onClick={() => props.openModal()}>
            <div>+ {t("Add Slide")}</div>
            <div>{t("At least 1250px wide")}</div>
        </div>
    )
}

export default AddSlide