import React, {Fragment, useState, useEffect} from 'react';
// import './BespokePrices.css';
import CustomButton from "admin/administrator/CustomButton";
// import {Modal} from "react-bootstrap";
import PageTitle from "admin/administrator/PageTitle";
import BespokePricesBody from "admin/administrator/BespokePrices/BespokePricesBody";
import CustomReactTable from "admin/administrator/CustomReactTable.js";
import CustomDeleteModal from "admin/administrator/CustomDeleteModal/CustomDeleteModal.js";
import Loading from "admin/administrator/LoadingComponent/Loading.component";
import axios from "axios1";
import alertify from "alertifyjs";
import _ from "lodash";
import DeleteAccountModal from '../Account_ref/DeleteAccountModal';
import Notify from "components/Notify";
import Modal from "components/Modal";
import Block from "components/Block";
import FormInput from "admin/administrator/FormInput/FormInput.component";
import CustomDelete from "admin/administrator/CustomDelete";
import Badge from "components/Badge";
import { useTranslation } from 'react-i18next';

alertify.defaults = {
    notifier: {
        position: 'top-right',
        delay: 5
    }
}

function BespokePrices() {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [bespokes, setBespokes] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showCustomersModal, setShowCustomersModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [bespokeName, setBespokeName] = useState(null);
    const [partNumbers, setPartNumbers] = useState([]);
    const [dbPartNumbers, setDbPartNumbers] = useState([]);
    const [dbPartNumbersForExport, setDbPartNumbersForExport] = useState([]);
    const [bespokeId, setBespokeId] = useState(null);
    const [deleteBespokeId, setDeleteBespokeId] = useState(null);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(0);
    const [errors, setErrors] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [assignedCustomers, setAssignedCustomers] = useState([]);
    const [customerIds, setCustomerIds] = useState([]);
    const [filterValue, setFilterValue] = useState(null);

    const columns = React.useMemo(
        () => [
            {
                Header: t('Bespoke Name'),
                accessor: 'name',
            },
            {
                Header: t('Part Number Amount'),
                className: "textCenter",
                Cell: props => (
                    <span>{props.row.original.bespoke_prices_count}</span>
                ),
            },
            {
                Header: t('Action'),
                className: "textCenter",
                Cell: props => (
                    <CustomButton type={"blue"} title={t("View/Edit")} onClick={() => {
                        viewBespoke(props.row.original.id);
                    }}></CustomButton>
                ),
            },
            {
                Header: t('Status'),
                className: "textCenter",
                Cell: props => (
                    <div>
                        {
                            props.row.original.accounts_with_bespokes.length > 0 ?
                                <div onClick={() => {
                                    setShowCustomersModal(true);
                                    getAssignedCustomers(props.row.original.id);
                                    setBespokeName(props.row.original.name);
                                }}>
                                    <Badge title={t("Assigned ")+" " + props.row.original.accounts_with_bespokes.length} type={"success"} cursor="pointer"/>
                                </div> 
                                : 
                                <Badge title={t("Not Assigned")} type={"danger"}/>
                        }
                    </div>
                ),
            },
            {
                Header: t('Delete'),
                className: "textCenter",
                Cell: props => (
                    <Fragment>
                        <CustomDelete
                            key={props.row.original.id}
                            onClick={setBespoke.bind(this, props.row.original.name, props.row.original.id)}
                        />
                    </Fragment>
                ),
            }
        ]
    );

    useEffect(() => {
        getBespokePrices();
    }, []);

    function setBespoke(name, id) {
        setDeleteBespokeId(id);
        setBespokeName(name);
        setShowDeleteModal(true);
    }

    function deleteBespokePrice() {
        setLoading(true);
        axios.post("catalogue/price-levels/" + deleteBespokeId, {_method: 'DELETE'})
            .then((response) => {
                setLoading(false);
                setShowDeleteModal(false);
                setBespokeName(null);
                setDeleteBespokeId(null);
                getBespokePrices();
            });
    }

    function createBespokePrice() {
        let pns = partNumbers.map((partNumber) => {
            return {
                part_number: partNumber[0],
                price: partNumber[1],
                part_number_id: partNumber[2] || null
            }
        });
        let bespokes = {
            name: bespokeName,
            bespoke_id: bespokeId,
            part_numbers: pns
        };
        setLoading(true);
        axios.post("catalogue/bespoke", {bespokes})
            .then((response) => {
                if(response.data.hasOwnProperty('errors')) {
                    setErrors(response.data.errors);
                    setLoading(false);
                    return;
                }
                
                setShowModal(false);
                setPartNumbers([]);
                setBespokeName("");
                setLoading(false);
                setBespokeId(null);

                alertify.success(t("Bespoke prices are being uploaded. You will receive an email notification once the upload is complete"));

                getBespokePrices();
            });
    }

    function getBespokePrices(page = 1) {
        setLoading(true);
        axios.get("catalogue/bespokes?page=" + page)
            .then((response) => {
                setBespokes(response.data.bespokes.data);
                setPage(page);
                setPages(response.data.bespokes.last_page);
                setLoading(false);
            });
    }

    function getAssignedCustomers(bespoke_id) {
        let bespoke = _.find(bespokes, {'id': bespoke_id});
        let ids = bespoke.accounts_with_bespokes.map((account) => {
            return account.id;
        });
        setAssignedCustomers(bespoke.accounts_with_bespokes);
        setCustomerIds(ids);
    }

    function unassignCustomer(id) {
        setLoading(true);
        axios.post("catalogue/bespoke/customer/" + id)
            .then((response) => {
                let accountId = response.data.account_id;
                let customers = assignedCustomers.filter((customer) => {
                    return customer.id != accountId;
                });
                if(customers.length == 0) setShowCustomersModal(false);
                setAssignedCustomers(customers);
                setLoading(false);
                getBespokePrices();
            })
    }

    function unassignAllCustomers(ids) {
        setLoading(true);
        axios.post("catalogue/bespoke/unassign/all", {ids: ids})
            .then((response) => {
                setShowCustomersModal(false);
                setAssignedCustomers([]);
                setCustomerIds([]);
                getBespokePrices();
                setDbPartNumbers([]);
                setLoading(false);
            })
    }

    function handleFilterBespokes(e) {
        setFilterValue(e.target.value);
        setLoading(true);
        axios.get("catalogue/bespokes/filter?q=" + e.target.value)
            .then((response) => {
                setBespokes(response.data.bespokes.data);
                setPage(page);
                setPages(response.data.bespokes.last_page);
                setLoading(false);
            });
    }

    function processPartNumbers(partNumbersArray) {
        let partNumbers;
        if(partNumbersArray.length > 0) {
            partNumbers = partNumbersArray.map(partNumber => {
                return [
                    partNumber?.part_number?.part_number,
                    partNumber.price,
                    partNumber.part_number_id
                ]
            });
        }
        return partNumbers;
    }

    function processPartNumbersForExport(partNumbersArray) {
        let partNumbers;
        if(partNumbersArray.length > 0) {
            partNumbers = partNumbersArray.map((partNumber) => {
                return [
                    partNumber?.part_number?.part_number,
                    partNumber.price
                ]
            });
        }
        return partNumbers;
    }

    function viewBespoke(bespoke_id) {
        setLoading(true);
        axios.get('catalogue/bespoke/' + bespoke_id)
                .then((response) => {
                    setBespokeName(response.data.name)
                    setIsEdit(true);
                    setBespokeId(bespoke_id);
                    setLoading(false);
                    setShowModal(true);

                    if(response.data.bespoke_prices.length > 0) {
                        let partNumbers = processPartNumbers(response.data.bespoke_prices);
                        let partNumbersForExport = processPartNumbersForExport(response.data.bespoke_prices);
                        setPartNumbers(partNumbers);
                        setDbPartNumbers(partNumbers);
                        setDbPartNumbersForExport(partNumbersForExport);
                    }                   
            });
    }

    return (
        <Fragment>
            <Loading loading={loading}/>
            <Modal size="sm" 
                    title={t("Create Bespoke Price Level")} 
                    type="light" 
                    active={showModal}
                    scrollable={true}
                    closeModal={() => {
                        setShowModal(false);
                        setPartNumbers([]);
                        setIsEdit(false);
                        setBespokeName(null);
                        setBespokeId(null);
                        setErrors([]);
                    }}>
                <BespokePricesBody setBespokeName={setBespokeName} 
                                    setPartNumbers={setPartNumbers} 
                                    errors={errors} 
                                    partNumbers={partNumbers} 
                                    bespokeName={bespokeName}
                                    isEdit={isEdit}
                                    dbPartNumbers={dbPartNumbers}
                                    dbPartNumbersForExport={dbPartNumbersForExport}
                                    />
                <div className="modal__actions">
                    <CustomButton type={"lightgray"} title={t("Cancel")} onClick={() => {
                        setShowModal(false);
                        setPartNumbers([]);
                        setIsEdit(false);
                        setBespokeName(null);
                        setBespokeId(null);
                        setErrors([]);
                    }}></CustomButton>
                    <CustomButton type={"green"} title={isEdit ? t("Save") : t("Create")} margins={['ml-8']} onClick={createBespokePrice}></CustomButton>
                </div>
            </Modal>
            <Modal size="sm" 
                    title={bespokeName} 
                    type="light" 
                    active={showCustomersModal} 
                    closeModal={() => {
                        setShowCustomersModal(false);
                    }}>
                <div className="table">
                    <table className={"table__table"}>
                        <thead className="table__thead">
                            <tr className="table__tr">
                                <th className="table__th">{t("Assigned To")}</th>
                                <th className="table__th">{t("Action")}</th>
                            </tr>
                        </thead>
                        <tbody className="table__tbody">
                        {
                            assignedCustomers.map((customer, i) => {
                                return <tr key={i} className="table__tr">
                                    <td className="table__td">{customer.name}</td>
                                    <td className="table__td">
                                        <CustomButton type={"red"} title={t("Unassign")} margins={['ml-1']} onClick={() => unassignCustomer(customer.id)}></CustomButton>
                                    </td>
                                </tr>
                            })
                        }
                        </tbody>
                    </table>
                </div>
                <div className="modal__actions">
                    <CustomButton type={"lightgray"} title={t("Cancel")} onClick={() => setShowCustomersModal(false)}></CustomButton>
                    {
                        assignedCustomers.length > 1 ? 
                            <CustomButton type={"red"} 
                                        title={t("Unassign All")} 
                                        margins={['ml-8']} 
                                        onClick={() => unassignAllCustomers(customerIds)}></CustomButton> : null
                    }
                </div>
            </Modal>
            <div className={"page-content admin-wrapper"}>
                <Block title={t("Bespoke Prices")} type="1">
                    <div class="table">
                        {
                        bespokes.length > 0 || filterValue ?
                            <Fragment>
                                <div class="table__header">
                                    <FormInput
                                        type='text'
                                        placeholder={t("Search")}
                                        error={""}
                                        value={filterValue}
                                        onChange={(e) => handleFilterBespokes(e)}
                                    />
                                    <CustomButton type={"blue"} 
                                                    title={[<i class="fas fa-plus"></i>, " ", t("New Bespoke Price Level")]} 
                                                    onClick={() => { 
                                                        setBespokeName(null);
                                                        setIsEdit(false);
                                                        setShowModal(true)
                                                    }}></CustomButton>
                                </div> 
                                <CustomReactTable columns={columns}
                                        pagination={true}
                                        page={page}
                                        pages={pages}
                                        getData={getBespokePrices.bind(this)}
                                        data={bespokes}
                                />
                            </Fragment>
                            : null
                        }
                    </div>
                </Block>
                {
                    bespokes.length === 0 && !loading && !filterValue ?
                        <div className="initial-create">
                            <p>{t("You have not created any bespoke prices")}</p>
                            <CustomButton type={"blue"} 
                                        title={[<i class="fas fa-plus"></i>, " ", t("New Bespoke Price Level")]} 
                                        margins={['mt-1']}
                                        onClick={() => setShowModal(true)}></CustomButton>
                        </div> : null
                }
            </div>
            <Notify type={"danger"}
                    visible={showDeleteModal}
                    close={() => {
                        setShowDeleteModal(false);
                        setBespokeName(null);
                        setDeleteBespokeId(null);
                    }}
                    primaryText={t("Are you sure") + "?"}
                    secondaryText={t("Bespoke price")+`: "${bespokeName}" `+ t("will be deleted permanently")}
                    actions={[
                        {
                            name: t("cancel"),
                            action: setShowDeleteModal.bind(this, false),
                            type: "lightgray"
                        },
                        {
                            name: t("delete"),
                            action: deleteBespokePrice.bind(this),
                            type: "red"
                        }
                    ]}/>
        </Fragment>

    );
}

export default BespokePrices;
