import React from "react";
import Layout from "account/Orders/ReturnForm/Components/Layout";
import _ from "lodash";
import "account/Orders/ReturnForm/Forms/ReturnFormPDF.css";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const ReturnFormCorePdf =  React.forwardRef((props, ref) => {
    const { t, i18n } = useTranslation();
    const tableRowCount = 7;
    let date = new Date().toLocaleString({timeZone: 'Europe/London'}, {
        month:"2-digit", day: "numeric", year: "numeric", hour: "2-digit", minute: "2-digit"
    });
    const local = {
        'DEU': 'de',
        'DNK': 'da',
        'FRA': 'fr'
    };
    return (
        <Layout ref={ref}>
            <div className={`pdf-section`}>
                <div className="pdf-card">
                    <div className="pdf-mg-bottom-25">{date}</div>

                    <div className="pdf-card-top pdf-mg-bottom-25">
                        <div className="pdf-flex-centred">
                            <h3>{t("return_form_return")}</h3>
                        </div>
                    </div>
                    <div className="pdf-card-body">
                        <div className="pdf-form-input"><p className="pdf-font-size-sm">{t("return_form_tick_appropriate")}</p></div>
                        <div className="pdf-form-input pdf-flex"><p>1. {t("return_form_night_express")}</p> <input type="checkbox" className="pdf-checkbox"/> </div>
                        <div className="pdf-form-input pdf-flex"><p>2. {t("return_form_carrier")}</p> <input type="checkbox" className="pdf-checkbox"/> </div>
                        <div className="pdf-form-input pdf-flex"><p>3. {t("return_form_own_choice")}</p> <input type="checkbox" className="pdf-checkbox"/> </div>
                    </div>
                    <div className="pdf-card-footer pdf-flex">
                        <h4>{t("return_form_recipient")}</h4>
                        <ul>
                            <li>{i18next.t('return_form_address_1', { lng:  local[props.account?.areaid] ?? 'en' })}</li>
                            <li>{i18next.t('return_form_address_2', { lng:  local[props.account?.areaid] ?? 'en' })}</li>
                            <li>{i18next.t('return_form_address_3', { lng:  local[props.account?.areaid] ?? 'en' })}</li>
                            <li>{i18next.t('return_form_address_4', { lng:  local[props.account?.areaid] ?? 'en' })}</li>
                            <li>{i18next.t('return_form_address_5', { lng:  local[props.account?.areaid] ?? 'en' })}</li>
                        </ul>
                    </div>
                </div>
                <div className="pdf-card">
                    <p className="pdf-mg-bottom-25">{t("return_form_Rucklieferung")}</p>
                    <div className="pdf-flex">
                        <div className="pdf-card-left">
                            <div className="pdf-form-input pdf-flex"><p className="pdf-bold">{t("return_form_reference")}</p> <input type="text"/> </div>
                            <div className="pdf-form-input pdf-flex"><p className="pdf-bold">{t("return_form_date_top")}</p> <input type="text" value={date}/> </div>
                            <div className="pdf-form-input pdf-flex"><p className="pdf-bold">{t("return_form_customer_number")}</p> <input type="text" value={props.account?.account_number}/> </div>
                        </div>
                        <div className="pdf-card-right">
                            <img src="https://nk2.availcat.com/static/media/nk-logo.00bc6c05.png" width={123} height={80}></img>
                        </div>
                    </div>
                    <div className="pdf-card-address-wrapper">
                        <h5>{t("return_form_company_stamp")}</h5>
                        {/* <div className="pdf-card-address-body" style={{height: '30px'}}> */}
                            {/* <p>ARC GROUP</p> */}
                            <textarea className="pdf-card-address-body" style={{height: '100px'}}>{props.account?.name +' - '+ props.account?.address_1}</textarea>
                        {/* </div> */}
                    </div>
                </div>
            </div>

            <div className="pdf-section pdf-mg-top">
                <div className="pdf-card">
                    <table className="pdf-table">
                        <tr>
                            <th style={{width:"30%"}}>{t("return_form_qty")}</th>
                            <th style={{width:"35%"}}>{t("return_form_item_reference")}</th>
                            <th style={{width:"35%"}}>{t("return_form_number_packages")}</th>
                        </tr>
                        {
                            _.times(tableRowCount, () => {
                                return (
                                    <tr>
                                        <td><input type="text"/></td>
                                        <td><input type="text"/></td>
                                        <td><input type="text"/></td>
                                    </tr>
                                );
                            })
                        }
                    </table>
                </div>
                <div className="pdf-card">
                    <table className="pdf-table">
                        <tr>
                            <th style={{width:"30%"}}>{t("return_form_qty")}</th>
                            <th style={{width:"35%"}}>{t("return_form_item_reference")}</th>
                            <th style={{width:"35%"}}>{t("return_form_number_packages")}</th>
                        </tr>
                        {
                            _.times(tableRowCount, () => {
                                return (
                                    <tr>
                                        <td><input type="text"/></td>
                                        <td><input type="text"/></td>
                                        <td><input type="text"/></td>
                                    </tr>
                                );
                            })
                        }
                    </table>
                </div>
            </div>

            <div className="pdf-section pdf-mg-top">
                <div className="pdf-card pdf-mg-top">
                    <h2>{t("Can only be used for Core return")}</h2>

                    <div className="pdf-list-warpper pdf-mg-top pdf-mg-bottom-25">
                        <div className="pdf-form-input pdf-flex-verticaly-centred"><p className="pdf-bold">{t("Number of packages")}</p><input style={{height: '30px'}} type="text"/></div>
                        <div className="pdf-form-input pdf-flex-verticaly-centred"><p className="pdf-bold">{t("core_Weight")}</p><input style={{height: '30px'}} type="text"/></div>
                    </div>

                    <div className="pdf-reason-desc pdf-mg-top">
                        <p>{t("return_form_reason_1")}</p>
                        <p>{t("return_form_reason_2")}</p>
                        <p>{t("return_form_reason_3")}</p>
                    </div>

                    {/* <a href="https://www.sbs-shop.de/formulare.php" className="pdf-site-link">https://www.sbs-shop.de/formulare.php</a> */}
                </div>
                <div className="pdf-card">
                    <div className="pdf-returnprocessing-wrapper pdf-mg-top">
                            <div className="pdf-form-input pdf-flex"><p>{t("return_form_shipment")}</p> <div className="pdf-fake-input"></div></div>
                            <div className="pdf-form-input pdf-flex "><p>{t("return_form_processed_on")}</p> <div className="pdf-fake-input"></div> </div>

                            <div className="pdf-mg-top">
                                <div className="pdf-form-input pdf-flex"><p>{t("return_form_finished_processing")}</p> <span className="pdf-underline"></span> </div>
                                <div className="pdf-form-input pdf-flex"><p>{t("return_form_date_right")}</p> <span className="pdf-underline"></span> </div>
                                <div className="pdf-form-input pdf-flex"><p>{t("return_form_employee")}</p> <span className="pdf-underline"></span> </div>
                            </div>
                    </div>
                </div>
            </div>

        </Layout>
    );
});

export default ReturnFormCorePdf;
