import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'components/CompareItemIcon.css';
import Icon from 'components/Icon';

class CompareItemIcon extends Component {
    constructor(props)
    {
        super(props);
    }

    render() {
        let compareList = this.props.compareList;

        return (
            <span className={"nav__icon"}>
                <Icon file="compare.svg" type="dark" width="24px" height="24px"/>
                {
                    this.props.token ?
                        <span className="badge badge--danger">{compareList.length > 99 ? "99+" : compareList.length}</span>
                        : null
                }
            </span>
        );
    } 
}

const mapStateToProps = state => ({
    compareList: state.compareList,
    token: state.auth.token
})

export default connect(mapStateToProps)(CompareItemIcon);