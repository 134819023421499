import React, {Fragment, useRef} from "react";
import CustomButton from "admin/administrator/CustomButton";
import { useTranslation } from 'react-i18next';

const ChooseFile = (props) => {
    const fileRef = useRef(null);

    const openFileDialog = () => {
        fileRef.current.click();
    };
    const { t, i18n } = useTranslation();

    return (
        <Fragment>
            <input type={"file"}
                   ref={fileRef}
                   style={{display: 'none'}}
                   onChange={props.handleFile.bind(this)}
            />
            <CustomButton title={props.title ?? t("Browse")}
                          type={"blue"}
                          onClick={openFileDialog}
            />
        </Fragment>
    )
}

export default ChooseFile;