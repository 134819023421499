import React, { useState, useEffect, Fragment } from "react";
import CustomModal from "admin/administrator/customModal";
import CustomButton from "admin/administrator/CustomButton";
// import "./LostSale.style.css";
import _ from "lodash";
import Modal from "components/Modal";
import { useTranslation } from 'react-i18next';

const LostSale = ({ product, lostSales, showlostSale, handleOptionChange, lostSaleClick }) => {
    const [selectedLostSale, setSelectedLostSale] = useState({});
    const [currentLostSales, setCurrentLostSales] = useState([]);
    const [reason, setReason] = useState(null);
    const [comment, setComment] = useState(null);    
    const [show, setShow] = useState(false);
    const { t, i18n } = useTranslation();

    const checkCurrentLostSale = (lost_sale_id, uncheckOther = false) => {
        console.log(lost_sale_id);
        setCurrentLostSales(
            _.map(currentLostSales, function (element) {
                if (element.id == lost_sale_id) {
                    element.checked = !element.checked;
                }else if(uncheckOther) {
                        element.checked = false;
                    }
                    return element;
                }
            ));
    }

    useEffect(() => {
        setCurrentLostSales(lostSales);
    }, [lostSales])
    
    return (
        <Fragment>
            <div className={"lost-sale"}>
                <span onClick={ () => lostSaleClick(product.product_price_id) } title={t("Register lost sale")} className="lost-sale__btn">
                    <i className="fas fa-dolly"></i>
                </span>
                {
                    (product.product_price_id && showlostSale[product.product_price_id]) &&
                        <div className={"lost-sale__list"}>
                            {
                                currentLostSales.map((lostSale) => {
                                    return <div className={"lost-sale__item " + (lostSale.checked ? "lost-sale__item--active" : "")}
                                                onClick={() => {
                                                    setSelectedLostSale( lostSale )
                                                    setReason( lostSale.id )
                                                    setShow( !lostSale.checked )
                                                    checkCurrentLostSale(lostSale.id);
                                                }}>
                                            {t(lostSale.title)}
                                        </div>
                                })
                            }
                        </div>
                }
            </div>
            <Modal size="sm" title={t(selectedLostSale.title)} type="dark" active={show} closeModal={() => { setShow(false)}}>
                <div>
                    <textarea className="lost-sale__comment" onChange={ e => setComment( e.target.value )} placeholder={t("Comments (optional)")}>{ comment }</textarea>
                    <div className={"modal__actions"}>
                        <CustomButton title={t("Close")} type={"lightgray"} onClick={() => { 
                            setShow(false)
                            setSelectedLostSale({})
                            setReason( null )
                        }}/>
                        <CustomButton title={t("Submit")} type={"green"} margins={['ml-8']} onClick={() => {
                            checkCurrentLostSale(reason, true)
                            handleOptionChange( reason, product.product_price_id, comment );
                            setShow(false)
                        }}/>
                    </div>
                </div>
            </Modal>
        </Fragment>
    )
}

export default LostSale; 