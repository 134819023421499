import React, { Component } from "react";
import CatalogueMenu from "components/CatalogueMenu";
import SearchVehicle from "components/SearchVehicle";

export default class Catalogue extends Component
{
    constructor(props)
    {
        super(props);
    }

    render()
    {
        return (
            <div>
                <CatalogueMenu />
                <SearchVehicle />
            </div>
        );
    }
}