import React from 'react';
// import "./Fees.css";
import { useTranslation } from 'react-i18next';

function Fees({licenceFee, lookupFee}) {
    const { t, i18n } = useTranslation();
    return (
        <div className={"mt-24"}>
            <div className={"fw-700 mb-8"}>{t("Fees")}</div>
            <div>
                <div className={"flex mb-4"}>
                    <span>{t("Licence Fee")}</span>
                    <span>{licenceFee}</span>
                </div>
                <div className={"flex"}>
                    <span>{t("VRM Lookup Fee")}</span>
                    <span>{lookupFee}</span>
                </div>
            </div>
        </div>
    );
}

export default Fees;