import React, { Component } from "react";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import {Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup} from "react-bootstrap";
// import "admin/administrator/LoginTotals/UserSessionDetail.css";
// import "admin/administrator/CustomElements.css";
import axios from "axios1";
import _ from "lodash";

export default class UserSessionDetail extends Component
{
    constructor(props){
        super(props);

        this.state = {
            lookups: {},
            modalShow: false,
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.show !== this.props.show) {
            this.setStateValues(); 
        }
    } 

    setStateValues() {
        var lookups = this.props.getDetail();
        lookups.then((lookups)=>{
            this.setState({
                lookups: lookups,
         });
        });
    }        

    modalClose() {
        this.props.onHide();
    }
    
    render()
        { 
            var lookups = this.state.lookups;
            return (
                <Modal
                    {...this.props}
                    dialogClassName = "user-session-modal"
                    centered
                    onHide={this.modalClose.bind(this)}
                    >
                    <Modal.Body>
                        {
                        <div className={"lost-sales-all"}>
                            <Container fluid={true}>
                                <Row className="show-grid">
                                    <Col md={12}>
                                        {
                                                <table className={"custom-table table table-bordered"}>
                                                    <thead>
                                                    <tr>
                                                        <th>SECTION</th>
                                                        <th>ADDED TO CART</th>
                                                        <th>QUANTITY</th>
                                                        <th>STOCK</th>
                                                        <th>ORDER</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    { 
                                                        lookups.length > 0 ? 
                                                            lookups.map((item) => {
                                                                return item.section_lookup_id ? <tr>
                                                                            <td>{item.section}</td>
                                                                            <td>{item.part_number ? item.part_number : 
                                                                                <NavLink to={"/admin/lost-sales/" + item.section_lookup_id} exact>
                                                                                    <span className={"worning-icon-box-detail"}><i className={"fas fa-exclamation-triangle"}></i></span>
                                                                                </NavLink>
                                                                            }</td>
                                                                            <td>{item.quantity}</td>
                                                                            <td>{item.branch}</td>
                                                                            <td> 
                                                                                { 
                                                                                    item.order ?
                                                                                    <NavLink to={"/admin/top-accounts/sale-details/" + item.order} exact>
                                                                                        <button className={"custom-btn"}>
                                                                                            {'#' + item.order}  
                                                                                        </button>
                                                                                    </NavLink>
                                                                                    : null
                                                                                }
                                                                           </td>
                                                                        </tr>
                                                                    :null
                                                                })
                                                                 : null
                                                    } 
                                                    </tbody>
                                                </table> 
                                        }
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        }
                    </Modal.Body>
                </Modal>
            ); 
        }
        
}