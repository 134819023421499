import React from "react";
import PricingOption from "admin/administrator/PricingOption/PricingOption.component";
// import "admin/administrator/ChargingOptions/MainChargingOptionsContainer.css";
import { useTranslation } from 'react-i18next';

const MainChargingOptionsContainer = ({ pricingOpions, handleChange }) => {
    const { t, i18n } = useTranslation();
    return (
        <div className={'price-setting-group'}>
            <div className="pricing-option">
                <PricingOption 
                    title={t('cost')}
                    handleChange={() => {return;}}
                    disabled={true}
                    className='bordered'
                    inputProps = {[
                        {
                            label: t('login licence fee'),
                            name: 'login_fee',
                            value: pricingOpions.login_fee,
                        },
                        {
                            label: t('VRM lookup fee'),
                            name: 'vrm_lookup_fee',
                            value: pricingOpions.vrm_lookup_fee,
                        }
                    ]} 
                />
            </div>
            <div className="pricing-option" style={{margin: '16px 0'}}>
                <PricingOption 
                    title={t('branch pricing')}
                    handleChange={handleChange}
                    className='bordered'
                    disabled={false}
                    inputProps = {[
                        {
                            label: t('Branch login licence fee'),
                            name: 'branch_login_fee',
                            value: pricingOpions.branch_login_fee,
                        },
                        {
                            label: t(`Branch VRM lookup fee`),
                            name: 'branch_vrm_lookup_fee',
                            value: pricingOpions.branch_vrm_lookup_fee,
                        }
                    ]} 
                />
            </div>
            <div className="pricing-option">
                <PricingOption 
                    title={t('branch customer pricing')}
                    handleChange={handleChange}
                    className='bordered'
                    disabled={false}
                    inputProps = {[
                        {
                            label: t('Customer login licence fee'),
                            name: 'costumer_login_fee',
                            value: pricingOpions.costumer_login_fee,
                        },
                        {
                            label: t(`Customer VRM lookup fee`),
                            name: 'costumer_vrm_lookup_fee',
                            value: pricingOpions.costumer_vrm_lookup_fee,
                        }
                    ]} 
                />     
            </div>
        </div>
    );
}

export default MainChargingOptionsContainer;