let initialState = {
    ordersQty: 0,
    customerOrdersQty: 0
}

const orders = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_OUTSTANDING_ORDERS_QTY':
            let settedQty = action.qty;
            return {
                ...state,
                qty: settedQty
            };
        case 'SET_ORDERS_QTY':
            let ordersQty = action.qty;
            return {
                ...state,
                ordersQty: ordersQty
            };
        case 'SET_CUSTOMER_ORDERS_QTY':
            let customerOrdersQty = action.qty;
            return {
                ...state,
                customerOrdersQty: customerOrdersQty
            };
        case 'UPDATE_OUTSTANDING_ORDERS_QTY':
            let updatedQty = action.payload;

            let newData = {
                    ...state,
                    qty: updatedQty,
            };

            if(action.account && typeof action.account != "undefined" ) {
                newData.ordersQty = state.ordersQty + 1;
                if(action.account.business_type_id == 3)
                    newData.customerOrdersQty = state.customerOrdersQty + 1;
            }

            return newData;
        default:
            return state;
    }
}

export default orders;