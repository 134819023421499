import React, { Component } from "react";
import _ from "lodash";
import ReactTable from "react-table";
import DatePicker from "react-datepicker";
// import "admin/administrator/Lookups.css";
import "react-datepicker/dist/react-datepicker.css";
var moment = require('moment');

export default class LookupsVehicle extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            filteredHistory: {
                lookups: [],
                lookupsCount: 0
            },
            company: "",
            dateFrom: "",
            dateTo: "",
            columns: [
                {
                    id: 'company',
                    Header: 'Company',
                    accessor: d => d.users[0].company,
                    style: {
                        textAlign: "center"
                    }
                },
                {
                    id: 'make',
                    Header: 'Vehicle',
                    accessor: d => d.vehicle.Make + ' ' + d.vehicle.Model + ' ' + d.vehicle.BodyStyle,
                    style: {
                        textAlign: "center"
                    }
                },
                {
                    id: 'created_at',
                    Header: 'Date',
                    accessor: d => d.users[0].pivot.created_at,
                    style: {
                        textAlign: "center"
                    }
                }
            ]
        }
    }

    handleChangeDateFrom(date) {
        this.setState({dateFrom: date});
    }

    handleChangeDateTo(date) {
        this.setState({dateTo: date});
    }

    handleChangeUser(e) {
        const company = e.target.value;
        this.setState({company: company});
    }

    handleFilter() {
        let data = [...this.props.lookupsHistory.lookups]
        let filteredData = this.state.filteredHistory;

        const dateFrom = this.state.dateFrom ? moment(this.state.dateFrom).format('YYYY-MM-DD') : "";
        const dateTo = this.state.dateTo ? moment(this.state.dateTo).format('YYYY-MM-DD') : "";
        const company = this.state.company;

        filteredData['lookups'] = data.filter((item) => {
            let filterByDate = dateFrom != "" && dateTo != "" ? moment(item.users[0].pivot.created_at).format('YYYY-MM-DD') >= dateFrom && item.users[0].pivot.created_at <= dateTo : true;
            let filterByCompany = company != "" ? item.users[0].company == company : true;
            return filterByDate && filterByCompany;
        })
        
        this.setState({filteredHistory: filteredData});
    }

    handleResetFilter() {
        let history = {
            lookups: [],
            lookupsCount: 0
        }

        this.setState({filteredHistory: history});
    }

    render() {

        const data = this.state.filteredHistory.lookups.length > 0 ? this.state.filteredHistory : this.props.lookupsHistory;
        let users = this.props.lookupsHistory.lookups.map((lookup) => {
            return lookup.users[0];
        });
        users = _.uniqBy(users, "company");
        
        return (
            <div>
                <div className="filter">
                    <div className="filter-from-to">
                        <DatePicker
                            selected={this.state.dateFrom}
                            onChange={this.handleChangeDateFrom.bind(this)}
                            name="dateFrom"
                            value={this.state.dateFrom}
                            placeholderText="Date From"
                            dateFormat="dd/MM/y"
                        />
                        <DatePicker
                            selected={this.state.dateTo}
                            onChange={this.handleChangeDateTo.bind(this)}
                            name="dateTo"
                            value={this.state.dateTo}
                            placeholderText="Date To"
                            dateFormat="dd/MM/y"
                        />
                        {
                            (this.props.showSelect)? 
                                <select className="form-control users-ddl" onChange={this.handleChangeUser.bind(this)}>
                                    <option value="">Choose Company</option>
                                    {
                                        users.map((user) => {
                                            return <option value={user.company}>{user.company}</option>;
                                        })
                                    }
                                </select>:""
                        }
                        
                        <button 
                            className="btn btn-success btn-filter"
                            onClick={this.handleFilter.bind(this)}
                        >Filter
                        </button>
                        <button 
                            className="btn btn-default btn-filter-reset"
                            onClick={this.handleResetFilter.bind(this)}
                        >Reset Filter
                        </button>
                    </div>
                </div>
                <ReactTable
                    data={data.lookups}
                    columns={this.state.columns}
                    defaultPageSize={10}
                    minRows={0}
                    defaultSorted={[{
                        id: 'created_at',
                        desc: true,
                    }]}
                />
            </div>
        )
    }
}