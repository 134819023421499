import React, {Component} from "react";
// import "admin/administrator/LoginTotals/UserSession.css";
// import "admin/administrator/CustomElements.css";
import {BrowserRouter as Router, Route, NavLink} from "react-router-dom";
import axios from "axios1";
import _ from "lodash";
import PageTitle from 'admin/administrator/PageTitle';
import {GridLoader} from 'react-spinners';
import {withRouter} from 'react-router-dom';
import DatePicker from "react-datepicker";
import CustomPagination from "admin/administrator/CustomPagination";
import UserSessionDetail from "admin/administrator/LoginTotals/UserSessionDetail.js";

var moment = require('moment');

class UserSession extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: {
                time_from: "",
                time_to: "",
            },
            selectedDate: {
                time_from: new Date(),
                time_to: new Date()
            },
            page: 1,
            pages: 0,
            lookups: [],
            login_id: [],
            arrForChart: [],
            loading: false,
        }
    }

    componentDidMount() {
        this.setState({loading: true});
        this.LookupsByAccountLogins(this.state.page);
    }

    handleDetailClick(lookupId) {
        var lookups = this.state.lookups;
        let element = _.find(lookups, {lookup_id: lookupId});
        element.modalShow = true;
        this.setState({lookups: lookups});
    }

    modalClose(lookupId) {
        var lookups = this.state.lookups;
        let element = _.find(lookups, {lookup_id: lookupId});
        element.modalShow = false;
        this.setState({lookups: lookups});
    }

    LookupsByAccountLogins(page = 1) {
        var filters = {...this.state.filters};
        var params = {};
        _.forEach(filters, function (value, key) {
            if (value) {
                params[key] = moment(value).format('HH-mm');
                ;
            }
        });
        axios.get("/catalogue/lookups-by-login/" + this.props.match.params.id + "?page=" + page, {params: params})
            .then(response => {
                var lookups = response.data.lookups;
                lookups = _.map(lookups, function (element) {
                    element.modalShow = false;
                    return element;
                });
                this.setState({
                    lookups: lookups,
                    login_id: this.props.match.params.id,
                    page: page,
                    pages: response.data.pages,
                    loading: false
                });
            })
    }

    handleChangeDate(key, date) {
        let selectedDate = this.state.selectedDate;
        let filters = this.state.filters;
        filters[key] = date;
        selectedDate[key] = date;
        this.setState({filters: filters, selectedDate: selectedDate}, () => {
            this.LookupsByAccountLogins();
        });
    }

    async getDetail(lookup_id) {
        var lookups = {};
        await axios.get("catalogue/lookups/" + lookup_id).then((response) => {
            lookups = response.data.lookups;
        })
        return lookups;
    }

    render() {
        return (
            <div className={"page-content admin-wrapper"}>

                <div className={"container-fluid"}>
                    <div className={"lookups-page-wrapper"}>
                        {
                            this.state.loading ?
                                <div className='sweet-loading'>
                                    <GridLoader
                                        sizeUnit={"px"}
                                        size={15}
                                        color={'#0066b8'}
                                        loading={this.state.loading}
                                    />
                                </div> : null
                        }
                        <div className={"row"}>
                            <div className={"col-lg-12"}>
                                <div className={"page-title-wrapper"}>
                                    <span>User Session</span>
                                    <button className={"go-back"} onClick={() => this.props.history.goBack()}><i
                                        className={"fas fa-reply"}></i> Back
                                    </button>
                                </div>
                                <div className={"lookups-filter"}>
                                    <div className={"custom-filter"}>
                                        <div className={"filter-group"}>
                                            <label htmlFor="account">From</label>
                                            <DatePicker
                                                className={"myDatePicker"}
                                                onChange={this.handleChangeDate.bind(this, "time_from")}
                                                selected={this.state.selectedDate.time_from}
                                                value={this.state.filters['time_from']}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                dateFormat="HH:mm"
                                                timeCaption="Time"
                                                placeholderText="Date Time From"
                                            />
                                        </div>
                                        <div className={"filter-group"}>
                                            <label htmlFor="account">To</label>
                                            <DatePicker
                                                className={"myDatePicker"}
                                                onChange={this.handleChangeDate.bind(this, "time_to")}
                                                selected={this.state.selectedDate.time_to}
                                                value={this.state.filters['time_to']}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                dateFormat="HH:mm"
                                                timeCaption="Time"
                                                placeholderText="Date Time To"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-lg-12"}>
                                {
                                    this.state.lookups.length > 0 ?
                                        <table className={"custom-table table table-bordered"}>
                                            <thead>
                                            <tr>
                                                <th>TIME</th>
                                                <th>LOOKUP TYPE</th>
                                                <th>DETAILS</th>
                                                <th>SECTION VIEWD</th>
                                                <th>ADDED TO CART</th>
                                                <th>ORDERED</th>
                                                <th>DETAILS</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.lookups.map((lookup) => {
                                                    return <tr>
                                                        <td>{lookup.time}</td>
                                                        <td>{lookup.lookup_type}</td>
                                                        <td>{lookup.details}</td>
                                                        <td>{lookup.sections_viewed}</td>
                                                        <td>{lookup.added_to_cart}</td>
                                                        <td>{lookup.ordered}</td>
                                                        <td>
                                                            <button className={"custom-btn"}
                                                                    onClick={this.handleDetailClick.bind(this, lookup.lookup_id)}>Details
                                                            </button>
                                                            <UserSessionDetail
                                                                getDetail={this.getDetail.bind(this, lookup.lookup_id)}
                                                                show={lookup.modalShow}
                                                                onHide={this.modalClose.bind(this, lookup.lookup_id)}/>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table> : null
                                }
                                {
                                    this.state.pages > 0 ?
                                        <CustomPagination
                                            page={this.state.page}
                                            pages={this.state.pages}
                                            sendRequest={this.LookupsByAccountLogins.bind(this)}
                                        /> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(UserSession);