import React, { useState, useEffect, Fragment } from "react";
import axios from "axios1";
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from "react-redux";
import { switchUser, setCart, setCompare, resetSwitchUser } from "redux/actions/authActions";
import { reloadSettings } from "redux/actions/settings";
import { updateOutstandingOrdersQty } from "redux/actions/orders";
import CustomButton from "admin/administrator/CustomButton";
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

var store = require('store');

function SwitchUser(props){
	const [branchList, setBranchList] = useState([]);
	const [usersList, setUsersList] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	
	const [selectedBranch, setSelectedBranch] = useState();
	const [selectedUser, setSelectedUser] = useState();
	
	useEffect(() => {
		getBranches();
	}, []);

    const { t, i18n } = useTranslation();

	const getBranches = () => {

		axios.get("catalogue/userBranchSettings")
			.then((response) => {
				setIsLoading(false);
				let branches = response.data.map( branch => {
					return {
						label: branch.name,
						value: branch.id
					}
				})
				let defaultBranch = {
					label: props.auth.user.account.name,
					value: props.auth.user.account.id
				}
				branches.unshift(defaultBranch)
				setBranchList(branches);
				setSelectedBranch(defaultBranch)
				getUsers(props.auth.user.account.id)
			});
	}

	function getUsers(branchId){

		axios.get(`catalogue/branchCustomersUsers?branch_id=${branchId}`)
			.then((response) => {
				setIsLoading(false);
				let accounts = response.data.map((account) => {
					let users = account.users;
					return {
						label: account.name + " - " + account.account_number,
						options: users
					}
				})
				setUsersList(accounts);
			});
	}

	const handleSelectUser = (newValue) => {
		if(newValue.id < 1) return;
        props.switchUser(newValue.id).then((response) => {
            if(!response.token) { return false; }
            reloadUserData();
        });
    }

	const handleSelectBranch = branch => {
		if(branch.value < 1) return;
		setSelectedBranch(branch);
		getUsers(branch.value);
	}

    function handleClearSwitch(){
    	props.resetSwitchUser();
    	reloadUserData();
    }

    function reloadUserData(){
    	window.location.reload();
		// props.reloadSettings();
  //       props.history.push('/search');
  //       props.setCart();
  //       props.setCompare();
  //       props.updateOutstandingOrdersQty();
    }

	const filterOption = (itemsList, isBranch = false, { label, value }, string) => {
		if (label.toLocaleLowerCase().includes(string.toLocaleLowerCase())) return true;
		const groupOptions = itemsList.filter(group =>
		  	group.label.toLocaleLowerCase().includes(string.toLocaleLowerCase())
		);

		if (groupOptions) {			
			if(isBranch) {				
				const option = groupOptions.find(opt => opt.id === value);
				if (option) {
					return true;
				}
			}else {
				for (const groupOption of groupOptions) {
					const option = groupOption.options.find(opt => opt.id === value);
					if (option) {
						return true;
					}
				}
			}			
		}
		return false;
	};
	
	return (
		<div className="container mb-24">
			{
			!props.auth.previousToken ?
				props.settings.account_selection ? 
					<div className="account-switcher">
						<div className="flex account-switcher__wrapper">
							<div className="mr-16">{t("view as")}:</div>
							<div className="account-switcher__selector">
								<Select
									options={branchList}
									closeMenuOnSelect={true}
									onChange={handleSelectBranch}
									value={selectedBranch}
									className={"branch-select"}
									placeholder={t("Select Branch")}
									filterOption={filterOption.bind(this, branchList, true)}
								/>
							</div>
							<div className="account-switcher__selector">
								<Select
									options={usersList}
									isDisabled={!selectedBranch}
									closeMenuOnSelect={true}
									getOptionLabel={(user) => user.name}
									getOptionValue={(user) => user.id}
									onChange={handleSelectUser}
									value={selectedUser}
									className={"users-select"}
									placeholder={t("Select Account")}
									filterOption={filterOption.bind(this, usersList, false)}
								/>
							</div>
						</div>
					</div> : null	
			:
			<div className="account-switcher">
				<div className="account-switcher__clear">
					{
						props.auth.user ?
							<Fragment>
								<i class="fas fa-exclamation-circle account-switcher__icon"></i>
								<span className="account-switcher__name">{ t("Account") }: {props.auth.user.account.account_number} - {props.auth.user.name}</span>
								<CustomButton onClick={ handleClearSwitch } type="lightgray" title={ t("Clear") } />
							</Fragment>
						: null
					}
				</div>
			</div>		
			}
		</div>
	)
}

const mapStateToProps = (state) => ({
    settings: state.settings,
    auth: state.auth
})

const mapDispatchToProps = {
    switchUser: switchUser,
    resetSwitchUser: resetSwitchUser,
    setCart: setCart,
    setCompare: setCompare,
    reloadSettings: reloadSettings,
    updateOutstandingOrdersQty: updateOutstandingOrdersQty
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SwitchUser));