import React, { Component } from "react";
import {Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup} from "react-bootstrap";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import LookupTotalsByUser from 'admin/administrator/lookup_totals/LookupTotalsByUser';
import LookupDetails from 'admin/administrator/lookup_totals/LookupDetails';
import LookupTotals from 'admin/administrator/lookup_totals/LookupTotals';
// import "./LookupsTotal.css";
import axios from "axios1";
import _ from "lodash";

var moment = require('moment');

export default class LookupsTotal extends Component
{
    constructor(props){
        super(props);

        this.state = {
            modalShow: false,
            lookups: {
                vrm: 0, 
                vehicle: 0,
                PartNumber: 0
            },
            month: new Date(),
        }
    }
    
    componentDidMount() { 
        this.LookupTotals(); 
    }

    LookupTotals() {  
        axios.get("/catalogue/lookups-stats")
            .then(response => {
                var data = response.data.lookups[0];
                var vrm = (data.vrm !== null) ? data.vrm : 0;
                var vehicle = (data.vehicle !== null) ? data.vehicle : 0;
                var partNumber = (data.partNumber !== null) ? data.partNumber : 0;
                this.setState({lookups: {vrm: vrm, vehicle: vehicle, PartNumber: partNumber}});
            })
    }

    render() 
        { 
         
        return (
            <div className={"lookup-totals-wrapper"}>
                <div className={"lookup-totals-month"}>
                    <span className={"icon icon-car-front"}></span>
                    <span className={"analiticsMainText"}>Lookup Totals</span>
                    <span className={"analiticsSubText"}>{moment(new Date()).format('MMMM YYYY') }</span>
                    <NavLink to={"/admin/lookup-totals/accounts"} exact>
                        <button className={"custom-btn"}>Details</button>
                    </NavLink>
                </div>
                <div className={"lookup-totals-ranks"}>
                    <table className={"table table-bordered"}>
                        <tr>
                            <td>VRM</td>
                            <td>{this.state.lookups.vrm}</td>
                        </tr>
                        <tr>
                            <td>Vehicle</td>
                            <td>{this.state.lookups.vehicle}</td>
                        </tr>
                        <tr>
                            <td>Part Num</td>
                            <td>{this.state.lookups.PartNumber}</td>
                        </tr>
                    </table>
                </div>
            </div>
           
        ); 
    }
    
}