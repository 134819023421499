import React, {useState, useEffect} from "react";
// import "admin/administrator/TopProductSections.css";
import ReactTable from "react-table";
import axios from "axios1";
import CustomPieChart2d from 'components/Charts/CustomPieChart2d';
import CustomReactTable from "admin/administrator/CustomReactTable.js";
var moment = require('moment');

const columns = [
    {
        Header: 'Product Section',
        accessor: 'section'
    },
    {
        Header: 'Orders',
        accessor: 'orders'
    },
];

const TopProductSections = (props) => {
    const [sections, setSections] = useState([]);
    const [labels, setLabels] = useState([]);
    const [values, setValues] = useState([]);

    useEffect(() => {
        getTopSections();
    },[]);

    function getTopSections() {
        axios.get("stats/accounts/" + props.branchId + "/top-sections?date=" + moment().format("YYYY-MM"))
            .then((response) => {
                let sections = response.data.sections;
                let labels = [];
                let values = [];
                sections.map((section) => {
                     labels.push(section.section);
                     values.push(section.orders);
                });
                setSections(sections);
                setLabels(labels);
                setValues(values);
            })
    }

    return (
        <div className={"table-chart"}>
            <div className={"table-wrap"}>
                <CustomReactTable columns={columns} data={sections}/>
            </div>
            <div className={"chart-wrap"}>
                <CustomPieChart2d labels={labels}
                                  values={values}/>
            </div>
        </div>
    )
}

export default TopProductSections;