import React, { Component } from "react";
import {withRouter} from 'react-router-dom';

const PageTitle = props => (
    <div className={"row"}>
        <div className={"page-title-wrapper"}>
            <span>{props.title}</span>
            {
                props.withBackBtn &&
                    <button className="go-back" onClick={() => props.history.goBack()}>
                        <i className="fas fa-reply"></i> Back
                    </button>
            }
        </div>
    </div>
)

PageTitle.defaultProps = {
    withBackBtn: true
}

export default withRouter(PageTitle);