// Init here only global constants for current utility file
const singleIntegerRegex = new RegExp('^[0-9]+$');

// Global functions for operations on number -->
export function singleIntegerToFloatInString(string) {
    if(string) {
        let splitedStr = string.split(" ");
        return splitedStr.map( item => {
            if(singleIntegerRegex.test(item)) {
                item = parseFloat(item).toFixed(1)
            }
            return item;
        }).join(" ");
    }
}