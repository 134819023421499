import React, {Component} from "react";
import "account/user/Menu.css";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import Image from 'images/image.gif';
import PasswordChnage from 'account/user/ChangePassword';
import axios from "axios1";
import PersonalInformation from 'account/user/PersonalInformation';
import $ from 'jquery';
export default class Menu extends Component
{
    constructor(props)
    {
        super(props);
        this.state={
            name:''
        }
    }
    componentDidMount() {
        this.getUserInfo();
    }

    getUserInfo(){
        axios.get("/catalogue/account/getUserName")
            .then(response => {
                var data = response.data;
                this.setState({
                    name:data.user.name
                });
            })
    }

    changeActive(e){
        $('.nav-item').removeClass('active');
        $(e.currentTarget).parent().addClass('active');
    }
    render()
    {
        const image = {
            backgroundImage: 'url('+Image+')',
        };        
        return (
 
            <div className={"dashboard-wrapper"}>
                <div className={"dashboard-header-wrapper"}>
                    <div className={"row account-row"}>
                        <div className={"col-md-3"}>
                            <div className={"col user-menu-colomn"}>
                                <div className={"user-img-header"}></div>
                                <div className={"user-img"} style={image}></div>
                                <div className={"col nopd menu-buttons-wrapper"}>
                                    <div className={"user-name"}>{this.state.name}</div>
                                    <div id={"usersCollapse"}>
                                        <div  id={"settings1"}>
                                            <button data-toggle="collapse" data-target={"#settingCollapseTarget" } aria-expanded="false" aria-controls={"settingCollapseTarget"} className={"user-menu-button"}>
                                                    <i className="fas fa-cogs"></i> Settings
                                            </button>
                                        </div>
                                        <div id={"settingCollapseTarget" } className="collapse" aria-labelledby={"settings1" } data-parent={"#usersCollapse" }>
                                            <div className={"Usercollapse"}>
                                                <div className={"columns"}>
                                                    <ul className="nav nav-pills text-center" role="tablist">
                                                        <li className="nav-item text-center userNav" >
                                                            <a onClick={this.changeActive.bind(this)}  className="text" id="personalInfomrationTab" data-toggle="pill" href="#pills-personal-information" role="tab" aria-controls="pills-personal-information" aria-selected="true">Personal Information</a>
                                                        </li>
                                                        <li  className="nav-item text-center userNav" >
                                                            <a  onClick={this.changeActive.bind(this)} className="text" id="change-password-tab" data-toggle="pill" href="#pills-change-password" role="tab" aria-controls="pills-change-password" aria-selected="true">Change Password</a>
                                                        </li>
                                                       
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div  id={"report1"}>
                                            <button data-toggle="collapse" data-target={"#repportsCollapseTarget" } aria-expanded="false" aria-controls={"repportsCollapseTarget"} className={"user-menu-button"}>
                                                <i className=" fas fa-chart-line"></i> Reports
                                            </button>
                                        </div>
                                        <div id={"repportsCollapseTarget" } className="collapse" aria-labelledby={"report1" } data-parent={"#usersCollapse" }>
                                            <div className={"Usercollapse"}>
                                                <div className={"columns"}>
                                                    <ul>
                                                        <li>
                                                            <NavLink style={{textDecoration: "none"}} to="/account/statistics/user" exact className={"admin-link"} activeClassName="active">
                                                                <div className={"text"}>Statistics</div>
                                                            </NavLink>
                                                        </li>
                                                        {/*<li>*/}
                                                            {/*<div className={"text"}>User Charts</div>*/}
                                                        {/*</li>*/}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"col-md-6 account-info-border-top"}>
                            <div className="tab-content">
                                <div className="tab-pane fade" id="pills-change-password" role="tabpanel" aria-labelledby="change-password-tab">
                                    <PasswordChnage  />
                                </div>
                                <div className="tab-pane fade" id="pills-personal-information" role="tabpanel" aria-labelledby="personalInfomrationTab">
                                    <PersonalInformation  />
                                </div>
                            </div>
                        </div>
                        <div className={"col-md-1  pdr-0"}>
                        </div>
                        <div className={"col-md-2  pdr-0"}>
                            <div className={"col pdr-0"}>
                                <button className="back-btn" onClick={() => this.props.history.goBack()}>
                                    Go Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         
        );
    }

}