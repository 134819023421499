import React, {Component} from "react";
// import "admin/administrator/lookup_totals/LookupTotalsByUser.css";
// import "admin/administrator/CustomElements.css";
import {BrowserRouter as Router, Route, NavLink} from "react-router-dom";
import axios from "axios1";
import _ from "lodash";
import CustomPagination from "admin/administrator/CustomPagination";
import CustomPieChart from 'components/Charts/CustomPieChart';
import PageTitle from 'admin/administrator/PageTitle';
import {GridLoader} from 'react-spinners';
import {withRouter} from 'react-router-dom';
import DatePicker from "react-datepicker";
import CustomPieChart2d from "components/Charts/CustomPieChart2d";
import CustomDatePicker from 'admin/administrator/CustomDatePicker';
import { connect } from "react-redux";
import {selectLookupsMonth} from "redux/actions/statisticActions";


var moment = require('moment');

class LookupTotalsByUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: {
                user_id: null,
                month: "",
            },
            selectedDate: new Date(),
            lookups: [],
            page: 1,
            pages: 0,
            users: [],
            account_id: 0,
            loading: false,
            dataLabelsForChart: [],
            dataValuesForChart: [],
        }
    }

    componentDidMount() {
        this.setState({loading: true});
        this.initMonth('month');
        this.LookupsByAccountLogins();
    }


    LookupsByAccountLogins(page = 1) {
        let id = this.props.match.params.id;
        var filters = {...this.state.filters};
        axios.get("/catalogue/lookups-by-accounts/" + id + "?page=" + page, {params: filters})
            .then(response => {
                let users = response.data.users.map((user) => {
                    return {id: user.id, name: user.name.toUpperCase()}
                });
                let dataLabelsForChart = [];
                let dataValuesForChart = [];
                _.map(response.data.loockups, (item) => {
                    dataLabelsForChart.push(item.name);
                    dataValuesForChart.push(parseInt(item.partNumber) + parseInt(item.vehicle) + parseInt(item.vrm));
                });
                this.setState({
                    lookups: response.data.loockups,
                    users: users,
                    page: page,
                    pages: response.data.pages,
                    dataLabelsForChart: dataLabelsForChart,
                    dataValuesForChart: dataValuesForChart,
                    account_id: id,
                    loading: false
                });
            })
    }

    handleChange(key, event) {
        let filters = this.state.filters;
        let value = event.target.value;
        filters[key] = (value != "all") ? value : null;
        this.setState({filters: filters}, () => {
            this.LookupsByAccountLogins();
        });
    }

    handleChangeDate(key, date) {
        let filters = this.state.filters;
        filters[key] = moment(date).format('YYYY-MM');
        this.props.dispatch(selectLookupsMonth(filters[key]))
        this.setState({filters: filters, selectedDate: date}, () => {
            this.LookupsByAccountLogins();
        });
    }

    initMonth(key = 'month') {
        var month = this.props.month ? moment(this.props.month).toDate() : this.state.selectedDate;
        var filters = this.state.filters;
        filters[key] = moment(month).format('YYYY-MM');
        this.setState({selectedDate: month, filters: filters});
    }

    render() {
        return (
            <div className={"page-content admin-wrapper"}>
                <div className={"container-fluid"}>
                    <div className={"lookups-page-wrapper"}>
                        {
                            this.state.loading ?
                                <div className='sweet-loading'>
                                    <GridLoader
                                        sizeUnit={"px"}
                                        size={15}
                                        color={'#0066b8'}
                                        loading={this.state.loading}
                                    />
                                </div> : null
                        }
                        <div className={"row"}>
                            <div className={"col-lg-12"}>
                                <div class="page-title-wrapper">
                                    <span>Lookup Totals By Login</span>
                                    <button class="go-back" onClick={() => this.props.history.goBack()}><i
                                        class="fas fa-reply"></i> Back
                                    </button>
                                </div>
                                <div className={"lookups-filter"}>
                                    <div className={"custom-filter"}>
                                        <div className={"filter-group"}>
                                            <label htmlFor="account">Login</label>
                                            <select onChange={this.handleChange.bind(this, 'user_id')}>
                                                <option value={""}>All Logins</option>
                                                {
                                                    this.state.users.map((user) => {
                                                        return <option key={user.id}
                                                                       value={user.id}>{user.name}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className={"filter-group"}>
                                            <label htmlFor="account">Month</label>
                                            <CustomDatePicker value={this.state.filters['month']} selected={this.state.selectedDate} placeholder={"Month"} onChange={this.handleChangeDate.bind(this, "month")} format={"MMMM YYYY"} showMonthYearPicker={true} ></CustomDatePicker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-lg-6"}>
                                {
                                    this.state.lookups.length > 0 ?
                                        <table className={"custom-table table table-bordered"}>
                                            <thead>
                                            <tr>
                                                <th>LOGIN</th>
                                                <th>VRM LOOKUPS</th>
                                                <th>VEHICLE LOOKUPS</th>
                                                <th>PART NO LOOKUPS</th>
                                                <th>INFO</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.lookups.map((lookup) => {
                                                    return <tr>
                                                        <td>{lookup.name}</td>
                                                        <td>{lookup.vrm}</td>
                                                        <td>{lookup.vehicle}</td>
                                                        <td>{lookup.partNumber}</td>
                                                        <td>
                                                            <NavLink to={"/admin/lookup-totals/lookups/" + lookup.id}
                                                                     exact>
                                                                <button className={"custom-btn"}>Details</button>
                                                            </NavLink>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table> : null
                                }
                                {
                                    this.state.pages > 0 ?
                                        <CustomPagination
                                            page={this.state.page}
                                            pages={this.state.pages}
                                            sendRequest={this.LookupsByAccountLogins.bind(this)}
                                        /> : null
                                }
                            </div>
                            <div className={"col-lg-6"}>
                                <CustomPieChart2d labels={this.state.dataLabelsForChart}
                                                  values={this.state.dataValuesForChart}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    month: state.statistic.lookupsMonth
})

export default withRouter(connect(mapStateToProps)(LookupTotalsByUser));