import React, { Component } from "react";

export default class Brands extends Component
{
    constructor(props)
    {
        super(props);
    }

    render()
    {
        return (
            <div>
                Brands
            </div>
        );
    }
}