import React, { Component } from "react";
// import "admin/administrator/GeneralClientOrBranchStatistics/index.css";
import _ from "lodash";
import { withTranslation } from 'react-i18next';
import i18n, { t } from 'i18next';

export default withTranslation()(class Cost extends Component
    {
        constructor(props)
        {
            super(props);
         }
    
        render()
        {
            return (
                <div className="flex mt-16">
                    <div className="flex">
                        <div>{t("Cost")}</div>
                    </div>
                    <div className="fw-700">{ (Math.round((this.props.cost + Number.EPSILON) * 100) / 100).toFixed(2)}</div> 
                </div>
            );
        }
    
    })
