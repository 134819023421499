import React from "react";
import SectionImage from "./SectionImage";
import SectionName from "./SectionName";
import NoImage from "images/no-image.png";

const SectionsItem = ({section, selectedSections, chooseSection}) => {
    return (
        <div key={section.section}
             className={"section " + (selectedSections.includes(section.section) ? "section--selected" : "")}
             onClick={() => chooseSection(section.section, section.section_id)}>
            <SectionImage image={section.section_image ? section.section_image : NoImage}/>
            <SectionName name={section.section}/>
        </div>
    )
}

export default SectionsItem