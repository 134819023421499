import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from "./languages/en.json";
import translationRU from "./languages/ru.json";
import translationDE from "./languages/de.json";
import translationDa from "./languages/da.json";
import translationFR from "./languages/fr.json";

const resources = {
  en: {
    translation: translationEN
  },
  ru: {
    translation: translationRU
  },
  de: {
    translation: translationDE
  },
  da: {
    translation: translationDa
  },
  fr: {
    translation: translationFR
  }
};
i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources
  });
export default i18n;