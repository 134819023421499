import React, {Fragment} from "react";
import CustomCheckBox from "admin/administrator/CustomCheckBox";
import CustomSortable from "admin/administrator/CustomSortable";
import _ from "lodash";
import Block from "components/Block";
import { useTranslation } from 'react-i18next';

const handleCheckBranch = (branches, setBranches, name, event) => {
    let element = _.find(branches, { name: name });
    element.checked = event.target.checked;
    setBranches([...branches]);
}

const StockPreferences = props => {
    const { t, i18n } = useTranslation();
    return (
        <Fragment>
            {
                props.isBranch &&
                    <div className="stock-providers__default">
                        <div>{t("Default Stock Provider")}</div>
                        <div>
                            <span>{props.currentBranch.name || t('Default Stock Provider Name')}</span>
                            {
                                props.defaultChange &&
                                <CustomCheckBox
                                    name={props.currentBranch.name}
                                    checked={props.currentBranch.default_branch}
                                    onChange={event => props.defaultChange(event)}
                                />
                            }
                        </div>
                    </div>
            }
            <Block type="4" title={t("Branches")}>
                <CustomSortable items={props.branches}
                                disabled={false}
                                callBack={items => props.setBranches(items)}
                                
                                onChangeCheckBox={handleCheckBranch.bind(this, props.branches, props.setBranches)}/>
            </Block>
        </Fragment>
    )
}

export default StockPreferences;