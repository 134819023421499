import React from 'react';
import FormInput from "admin/administrator/FormInput/FormInput.component";
import CustomButton from "admin/administrator/CustomButton";
import { useTranslation } from 'react-i18next';

function VinInput(props) {

    const { t, i18n } = useTranslation();
    return (
        <div className={"flex"}>
            <FormInput
                name='vinNumber'
                type='text'
                placeholder={t("Enter VIN Number")}
                error={""}
                value={props.vinNumber}
                onChange={props.handleVinNumberChange}
                onKeyDown={props._handleKeyDown}
            />
            <CustomButton title={t("Go")} 
                        type={"blue"} 
                        onClick={props.handleFindByVinNumber}
                        style={{borderTopLeftRadius: "0", borderBottomLeftRadius: "0", textTransform: "uppercase"}}/>
        </div>
    );
}

export default VinInput;