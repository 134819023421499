import axios from 'axios1';

export const updateOutstandingOrdersQty = () => (
    (dispatch) => {
        return axios.get("/catalogue/account/orders/outstanding-orders-qty")
            .then(response => {
            	if(response) {
	                dispatch({ type: 'SET_OUTSTANDING_ORDERS_QTY', qty: response.data });
	                return response.data;
            	}
            });
    }
);

export const setOrdersQty = () => (
    (dispatch) => {
        axios.get("/catalogue/account/orderHistory?all=true")
            .then((response) => {
                dispatch({ type: 'SET_ORDERS_QTY', qty: response.data.orders.length });
            });
    }
);

export const setCustomerOrdersQty = () => (
    (dispatch) => {
        axios.get("/catalogue/account/reverseOrderHistory?all=true")
            .then((response) => {
                dispatch({ type: 'SET_CUSTOMER_ORDERS_QTY', qty: response.data.orders.length });
            });
    }
);