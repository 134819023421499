import React, {Component, Fragment} from "react";
import {BrowserRouter as Router, Route, NavLink} from "react-router-dom";
import PageTitle from "admin/administrator/PageTitle";
import axios from "axios1";
import _ from "lodash";
import CustomPagination from "admin/administrator/CustomPagination";
import CustomDatePicker from 'admin/administrator/CustomDatePicker';
import alertify from "alertifyjs";
import OrdersDataTable from "account/Orders/OrdersDataTable";
import OrdersUserActionTable from "account/Orders/OrdersUserActionTable";
var moment = require('moment');

export default class myOrders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: {
                start_date: "",
                end_date: ""
            },
            selectedDate: new Date(),
            selectedEndDate: new Date(),
            data: [],
            page: 1,
            pages: 0,
            loading: false,
        }
    }

    componentDidMount() {
        this.setState({loading: true});
        this.LookupsByAccounts();
    }

    LookupsByAccounts(page = 1) {
        var filters = {...this.state.filters};
        axios.get("/catalogue/account/orderHistory?page=" + page, {params: filters})
            .then(response => {
                var orders = response.data.orders;
                this.setState({
                    data: orders,
                    page: page,
                    pages: response.data.pages,
                    loading: false
                });
            })
    }


    handleChange(key, event) {
        let filters = this.state.filters;
        let value = event.target.value;
        filters[key] = (value != "all") ? value : null;
        this.setState({filters: filters}, () => {
            this.LookupsByAccounts();
        });
    }

    handleChangeDate(key, date) {
        let filters = this.state.filters;
        filters[key] = moment(date).format('YYYY-MM-DD');
        (key == 'start_date') ? this.setState({selectedDate: date}) : this.setState({selectedEndDate: date});

        this.setState({filters: filters}, () => {
            this.LookupsByAccounts();
        });
    }

    render() {
        return (
            <Fragment>
                <div className={"row"}>
                    <div className={"col-lg-12"}>
                        <div className={"lookups-filter"}>
                            <div className={"custom-filter"}>
                                <div className={"filter-group"}>
                                    <label htmlFor="account">Date From</label>
                                    <CustomDatePicker
                                        value={this.state.filters['start_date']} selected={this.state.selectedDate}
                                        placeholder={"Start Date"}
                                        onChange={this.handleChangeDate.bind(this, "start_date")}
                                        format={"DD-MM-YYYY"}>
                                    </CustomDatePicker>
                                </div>

                                <div className={"filter-group"}>
                                    <label htmlFor="account">Date To</label>
                                    <CustomDatePicker
                                        value={this.state.filters['end_date']} selected={this.state.selectedEndDate}
                                        placeholder={"End Date"}
                                        onChange={this.handleChangeDate.bind(this, "end_date")}
                                        format={"DD-MM-YYYY"}>
                                    </CustomDatePicker>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"row"}>

                    <div className={"col-lg-12"}>
                        <div className={"row"}>

                            <div className="col-sm-12">
                                {
                                    this.state.data.length > 0 ?
                                        <div className={"tables-wrapper"}>
                                            <OrdersDataTable orders={this.state.data}/>
                                            <OrdersUserActionTable orders={this.state.data}/>
                                        </div> : null
                                }

                                {
                                    // this.state.pages > 0 ?
                                    //     <CustomPagination
                                    //         page={this.state.page}
                                    //         pages={this.state.pages}
                                    //         sendRequest={this.LookupsByAccounts.bind(this)}
                                    //     /> : null
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </Fragment>
        )
    }
}

