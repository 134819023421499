import React, {Component} from "react";
import "components/checkout/QuantityCheck.css";
import {connect} from "react-redux";
import { addProduct} from "redux/actions/shoppingCart";
import axios from "axios1";
import _ from "lodash";

class QuantityCheck extends Component {

    constructor(props) {
        super(props);
        this.state={
            show:false,
            availableQuantity:null
        }

        this.getItemQuantity(this.props.data.id);
    }

    getItemQuantity(id){
        // axios.get("/catalogue/cart/available-quantity/"+id).then((response) => {
        //     this.setState({availableQuantity:response.data})
        // });
    }
    open(){
        this.setState({show:!this.state.show})
    }

    close(){
        this.setState({show:false})
    }

    getAll(product, quantity){
        this.props.dispatch(addProduct(product, quantity));
        this.close()
    }

    render() {
        const product = this.props.data;
        return (
            <React.Fragment>
                {
                    this.state.availableQuantity?
                    product.quantity>this.state.availableQuantity?
                        <span className={"exc"}>
                            <span className="fas fa-exclamation " onClick={this.open.bind(this)}></span>
                            {
                                this.state.show?
                                    <div className="infoTooltip">
                                        <i className="fas fa-times-circle Close" onClick={this.close.bind(this)}></i>
                                        <p>maximum available product quantity is <b>{this.state.availableQuantity}</b></p>
                                        <button className="btn btn-default" onClick={this.getAll.bind(this,product, this.state.availableQuantity)}> get all </button>
                                    </div>
                                    :null
                            }
                        </span>
                        : null
                    :null

                }
            </React.Fragment>
        );
    }

}

const mapStateToProps = state => ({
    shoppingCart: state.shoppingCart,
    settings: state.settings
})

export default connect(mapStateToProps)(QuantityCheck);