import React, { Component } from "react";
// import "admin/administrator/TopAccounts/TopAccounts.css";
// import "admin/administrator/CustomElements.css";
import CustomPagination from "admin/administrator/CustomPagination";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {GridLoader} from 'react-spinners';
import axios from "axios1";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import {Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup} from "react-bootstrap";
import CustomDatePicker from 'admin/administrator/CustomDatePicker';
import { connect } from "react-redux";
import {selectTopAccountMonth} from "redux/actions/statisticActions";

var moment = require('moment');

class SalesDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loginId: null,
            loading: false,
            sales: [],
            filters: {
                month: "",
            },
            selectedDate: new Date(),
            page: 1,
            pages: 0,
        }
    }

    componentDidMount() {
        this.initMonth('month');
        this.getSales(this.state.page);
    }

    handleChangeDate(key, month) {
        let filters = this.state.filters;
        filters[key] = moment(month).format('YYYY-MM');
        this.props.dispatch(selectTopAccountMonth(filters[key]))
        this.setState({filters: filters, selectedDate: month}, () => {
            this.filterSales();
        });
    }

    filterSales(page = 1) {
        let data = this.state.filters;
        axios.get("catalogue/sales-by-users/" + this.props.match.params.id, {params: data})
            .then((response) => {
                this.setState({sales: response.data.accounts});
            })
    }

    getSales(page = 1) {
        let data = this.state.filters;
        this.setState({loading: true});
        axios.get("/catalogue/sales-by-users/" + this.props.match.params.id + "?page=" + page, {params: data})
            .then((response) => {
                this.setState({sales: response.data.accounts, loading: false, pages: response.data.pages, page: page});
            })
    }

    initMonth(key = 'month') {
        var month = this.props.topAccountsMonth ? moment(this.props.topAccountsMonth).toDate() : this.state.selectedDate.month;
        var selectedDate = this.state.selectedDate;
        selectedDate.month = month;
        var filters = this.state.filters;
        filters[key] = moment(month).format('YYYY-MM');
        this.props.dispatch(selectTopAccountMonth(filters[key]))
        this.setState({selectedDate: selectedDate, filters: filters});
    }

    render() {
        return (
            <div className={"page-content admin-wrapper"}>
                <Container fluid={true}>
                    <Row className="show-grid">
                        <Col md={12}>
                            <div className={"page-title-wrapper"}>
                                <span>sales details</span>
                                <button onClick={() => this.props.history.goBack()} className="go-back">
                                    <i className="fas fa-reply"></i> Back
                                </button>
                            </div>
                        </Col>
                    </Row>
                    <Row className="show-grid">
                        <Col md={12}>
                            <div className={"custom-filter"}>
                                <div className={"filter-group"}>
                                    <label>Month</label>
                                    <CustomDatePicker value={this.state.filters['month']} selected={this.state.selectedDate.month} placeholder={"Month"} onChange={this.handleChangeDate.bind(this, "month")} format={"MMMM YYYY"} showMonthYearPicker={true} ></CustomDatePicker>
                                        
                                    {/* <DatePicker
                                        selected={this.state.selectedDate}
                                        onChange={this.handleChangeDate.bind(this, "month")}
                                        value={this.state.filters['month']}
                                        placeholderText="Month"
                                        showMonthYearPicker
                                        dateFormat="MM/yyyy"
                                    /> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="show-grid">
                        <Col md={12}>
                            {
                                this.state.sales.length > 0 ?
                                    <table className={"custom-table table table-bordered"}>
                                        <thead>
                                        <tr>
                                            <th>date/time</th>
                                            <th>quantity</th>
                                            <th>total price</th>
                                            <th>order</th>
                                        </tr>
                                        </thead> 
                                        <tbody>
                                        {
                                            this.state.sales.map((sale, index) => { 
                                                return <tr key={index}>
                                                    <td>{sale.date}</td>
                                                    <td>{sale.quantity}</td>
                                                    <td>{sale.price}</td>
                                                    <td>
                                                        <NavLink to={"/admin/top-accounts/sale-details/" + sale.id} exact>
                                                            <button className={"custom-btn"}>#{sale.id}</button>
                                                        </NavLink>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                        </tbody>
                                    </table> : null
                            }
                            {
                                this.state.pages > 0 ?
                                    <CustomPagination
                                        page={this.state.page}
                                        pages={this.state.pages}
                                        sendRequest={this.getSales.bind(this)}
                                    /> : null
                            }
                        </Col>
                    </Row>
                </Container>
                {
                    this.state.loading ?
                        <div className='sweet-loading'>
                            <GridLoader
                                sizeUnit={"px"}
                                size={15}
                                color={'#0066b8'}
                                loading={this.state.loading}
                            />
                        </div> : null
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    topAccountsMonth: state.statistic.topAccountsMonth
})

export default connect(mapStateToProps)(SalesDetails);