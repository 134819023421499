import React, { Component } from "react";
import axios from "axios1";
import ReactTable from "react-table";
import DatePicker from "react-datepicker";
// import "admin/administrator/OrdersDetails.css";
import "react-datepicker/dist/react-datepicker.css";
var moment = require('moment');

export default class OrdersDetails extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            orders: [],
            dateFrom: "",
            dateTo: "",
            errors: [],
            columns: [
                {
                    Header: 'Order Id',
                    accessor: 'id',
                    style: {
                        textAlign: "center"
                    }
                },
                {
                    Header: 'Company',
                    accessor: 'user.company',
                    style: {
                        textAlign: "center"
                    }
                },
                {
                    id: 'created_at',
                    Header: 'Date',
                    accessor: d => d.created_at,
                    style: {
                        textAlign: "center"
                    }
                },
                {
                    expander: true,
                    width: 200,
                    Header: 'More',
                    Expander: ({ isExpanded, row, ...rest }) =>
                    <div>
                      {isExpanded
                        ? <button className="btn show-details">Hide</button>
                        : <button className="btn show-details">Details</button>}
                    </div>,
                    style: {
                        textAlign: "center"
                    }
                },
            ],
            subColumns: [
                {
                    Header: 'Part Number',
                    accessor: 'product.part_number.part_number',
                    style: {
                        textAlign: "center"
                    }
                },
                {
                    id: 'order_details',
                    Header: 'Price',
                    accessor: d => d.price,
                    // accessor: d => d.price + ' ' + d.product.currency.symbol,
                    style: {
                        textAlign: "center"
                    }
                },
                {
                    Header: 'Quantity',
                    accessor: 'quantity',
                    style: {
                        textAlign: "center"
                    }
                }
            ]
        }
    }

    componentDidMount() {
        this.getOrders()
    }

    getOrders() {
        axios.get("/catalogue/getOrders")
            .then(response => {
                const orders = response.data;
                this.setState({orders: orders})
            })
    }

    filterOrdersClick() {
        const dateFrom = this.state.dateFrom ? moment(this.state.dateFrom).format('YYYY-MM-DD') : ""
        const dateTo =  this.state.dateTo ? moment(this.state.dateTo).format('YYYY-MM-DD') : ""

        if(!dateFrom || !dateTo) {
            return;
        }

        axios.get("/catalogue/getOrders?dateFrom=" + dateFrom + "&dateTo=" + dateTo)
            .then(response => {
                const orders = response.data;
                this.setState({orders: orders})
            })
    }

    resetFilter() {
        this.getOrders()
    }

    handleChangeDateFrom(date) {
        this.setState({dateFrom: date});
    }

    handleChangeDateTo(date) {
        this.setState({dateTo: date});
    }

    render() {

        const data = this.state.orders

        return (
            <div className={"dashboard-page-wrapper"}>
                <div className={"dashboard-page-navigations"}>
                    <div>
                        <i className="fas fa-chart-line"></i>
                        <span> User Statistics</span>
                    </div>
                    <div>
                        <button className="back-btn" onClick={() => this.props.history.goBack()}>
                            Go Back
                        </button>
                    </div>
                </div>
                <div className={"dashboard-page-body"}>
                    <div className="filter">
                        <div className="filter-from-to">
                            <DatePicker
                                selected={this.state.dateFrom}
                                onChange={this.handleChangeDateFrom.bind(this)}
                                name="dateFrom"
                                value={this.state.dateFrom}
                                placeholderText="Date From"
                                dateFormat="dd-MM-yyyy"
                            />
                            <DatePicker
                                selected={this.state.dateTo}
                                onChange={this.handleChangeDateTo.bind(this)}
                                name="dateTo"
                                value={this.state.dateTo}
                                placeholderText="Date To"
                                dateFormat="dd-MM-yyyy"
                            />
                            <button
                                className="btn btn-filter"
                                onClick={this.filterOrdersClick.bind(this)}
                            >
                                <i className="fas fa-filter"></i>Filter
                            </button>
                            <button
                                className="btn btn-default btn-filter-reset"
                                onClick={this.resetFilter.bind(this)}
                            >
                                <i className="fas fa-redo-alt"></i>Reset
                            </button>
                        </div>
                    </div>
                    <ReactTable
                        data={data}
                        columns={this.state.columns}
                        defaultPageSize={10}
                        minRows={0}
                        SubComponent={row => {
                            return (
                                <div className="sub-orders">
                                    <ReactTable data={this.state.orders[row.index].order_details}
                                                columns={this.state.subColumns}
                                                minRows={0}
                                                showPagination={false}
                                                showPageSizeOptions={false}
                                    />
                                </div>
                            )
                          }}
                    />
                </div>
            </div>
        )
    }
}