import React, {useEffect, useState, Fragment} from "react";
import ChangeSaveOrDiscardModal from 'admin/administrator/ChangeSaveOrDiscardModal/ChangeSaveOrDiscardModal';
import Loading from "admin/administrator/LoadingComponent/Loading.component";
import SupplierDetails from "admin/administrator/account/SupplierDetails";
import EditUploadedProductsFile from "components/EditUploadedProductsFile";
import DownloadSamplePriceFile from "components/DownloadSamplePriceFile";
import CustomStockFeed from 'admin/administrator/CustomStockFeed.js';
import ChooseFileAndUpload from 'components/ChooseFileAndUpload';
import DeleteModal from 'admin/administrator/account/DeleteModal';
import CustomButton from "admin/administrator/CustomButton";
import {BrowserRouter as NavLink} from "react-router-dom";
import Block from "components/Block";
import alertify from 'alertifyjs';
import axios from "axios1";
import _ from "lodash";
import { t } from 'i18next';

const EditSupplier = props => {
    const [supplier, setSupplier] = useState([]);
    const [brands, setBrands] = useState([]);
    const [selectedBrands, setSelectedBrands] = useState([]);;
    const [credentials, setCredentials] = useState([]);
    const [apis, setApis] = useState([]);
    const [loading, setLoading] = useState(true);
    const [apiId, setApiId] = useState(null);
    const [errors, setErrors] = useState({
        name: '',
        contact_name: '',
        address_1: '',
        town: '',
        phone: '',
        email: ''
    });
    const [types, setTypes] = useState({
        prefix: "",
        suffix: ""
    });
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [fileName, setFileName] = useState(null);
    const [file, setFile] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [fileSwitch, setFileSwitch] = useState(0);
    const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
    const [user, setUser] = useState({ login_email: "", password: ""});
    const [initialProducts, setInitialProducts] = useState([]);
    const [percentage, setPercentage] = useState(0);
    const [priceLevelValueTypeId, setPriceLevelValueTypeId] = useState(1);
    const [priceLevelValue, setPriceLevelValue] = useState(0);
    const [hasRefs, setHasRefs] = useState(false);
 
    const textFirst = t("The stock file should be in .csv (comma seperated values) format and in the following structure: Part Number, Supplier Name, Quantity Available");
    const textSecond = t("Once connected to FTP, the file should be placed in the “files” folder");
    const textThird = t("The supplier name column data should exactly match the suppliers company name as entered within Supplier");
    var unblock = () => {};

    useEffect(() => {
        getSupplier();
    }, [])

    useEffect(() => {
        unblock();
    })

    // useEffect(() => {
    //     if(!products.length && shouldBlockNavigation === false) {
    //         props.history.block(() => true)
    //     }
    // }, [shouldBlockNavigation, products])

    // useEffect(() => {
    //     if(products.length > 0) {
    //         unblock = props.history.block(() => {
    //             setShouldBlockNavigation(true);
    //             return false;
    //         });
    //     }
    // }, [products])

    const getSupplier = () => {
        axios.get("catalogue/suppliers/" + props.match.params.id + "/edit")
            .then((response) => {
                if (response.data.success) {
                    var selectedBrands = brandIdsToSectedValidFormat(response.data.selectedBrands);
                    setSupplier(response.data.supplier || []);
                    setApis(response.data.apis);
                    setHasRefs(response.data.hasRefs);
                    setApiId(response.data.apiId);
                    setBrands(response.data.brands);
                    setSelectedBrands(selectedBrands);
                    setLoading(false);
                    setFile(response.data.file);
                    setProducts(response.data.products);
                    setInitialProducts(response.data.products);
                    setPriceLevelValueTypeId(response.data.info.price_level_value_type_id);
                    setPriceLevelValue(response.data.file ? response.data.file.percentage : 0);
                    setUser(response.data.user);
                }
            })
    }

    const brandIdsToSectedValidFormat = brands => brands && brands.map(brand => { return {id: brand.id, name: brand.name} });

    const checkPrefixSuffix = (key, value) => {
        if (key == 'types') {
            let supplier = {...supplier};
            supplier['prefix'] = value.prefix;
            supplier['suffix'] = value.suffix;
            setSupplier(supplier);
        }
    }

    const handleSaveClick = () => {
        const newSupplier = supplier;
        newSupplier.prefix = newSupplier.prefix ? newSupplier.prefix : types.prefix;
        newSupplier.suffix = newSupplier.suffix ? newSupplier.suffix : types.suffix;
        const newSelectedBrands = _.map(selectedBrands, element => element.id);
        setLoading(true);
        axios.put("catalogue/suppliers/" + props.match.params.id, {
            supplier: newSupplier,
            user: user,
            apiId: apiId,
            credentials: credentials,
            selectedBrands: newSelectedBrands,
            products: products,
            fileName: fileName,
            initialProducts:initialProducts,
            priceLevelValueTypeId: priceLevelValueTypeId,
            percentage: percentage
        }).then((response) => {
            setLoading(false);
            let newErrors = response.data.errors ? response.data.errors : [];
            setErrors(newErrors);
            setLoading(false);
            if (response.data.success && !response.data.errors) {
                alertify.success(response.data.message);
                props.history.block(() => true);
                props.history.push("/admin/accounts");
                setShouldBlockNavigation(false);
            } else {
                let message = response.data && response.data.errors && response.data.errors.length > 0 ? response.data.errors.toString() : response.data.message;
                alertify.error(message);
                setShouldBlockNavigation(false);
            }
        })
    }

    alertify.defaults = {
        notifier: {
            position: 'top-right',
            delay: 5
        }
    }
    
    return (
        <Fragment>
            <Loading loading={loading}/>
            {
                shouldBlockNavigation && 
                    <ChangeSaveOrDiscardModal
                        saveChanges={handleSaveClick}
                        closeChangesModalDisregard={() => { 
                            setProducts([]);                             
                            setShouldBlockNavigation(false);
                            props.history.block(() => true);
                        }}
                        modalShow={shouldBlockNavigation}
                        closeChangesModal={() => setShouldBlockNavigation(false)}/>
            }
            <div className={"page-content admin-wrapper"}>
                <div className="grid">
                    <div className="grid__row">
                        <div className="grid__column grid__column--6">
                            <SupplierDetails setSupplier={setSupplier}
                                        apiId={apiId}
                                        brands={brands}
                                        supplier={supplier}
                                        selectedBrands={selectedBrands}
                                        setSelectedBrands={setSelectedBrands}
                                        setErrors={setErrors}
                                        errors={errors}/>
                            <Block title={t("pricing")} type={"1"} style={{marginTop: "24px"}}>
                                <DownloadSamplePriceFile/>
                                <ChooseFileAndUpload products={products}
                                            filteredProducts={filteredProducts}
                                            fileName={fileName}
                                            showModal={showModal}                                                
                                            setProducts={setProducts}
                                            setInitialProducts={setInitialProducts}
                                            setPercentage={setPercentage}
                                            setFileName={setFileName}
                                            setPriceLevelValueTypeId={setPriceLevelValueTypeId}
                                            supplier={supplier}
                                            switch={fileSwitch}/>
                                <hr/>
                                {
                                    file  ? <EditUploadedProductsFile file={file}
                                                            products={products}
                                                            initialProducts={initialProducts}

                                                            setProducts={setProducts}
                                                            setInitialProducts={setInitialProducts}
                                                            setPercentage={setPercentage}
                                                            setFileName={setFileName}
                                                            setPriceLevelValueTypeId={setPriceLevelValueTypeId}
                                                            supplier={supplier}
                                                            priceLevelValueTypeId={priceLevelValueTypeId}
                                                            priceLevelValue={priceLevelValue}/> : ""
                                }

                            </Block>
                        </div>
                        <div className="grid__column grid__column--6">
                            <Block title={t("supplier stock feed")} type={"1"}>
                            <CustomStockFeed apiId={apiId}
                                        apis={apis}
                                        setApiId={setApiId}
                                        setCredentials={setCredentials}
                                        errors={errors}
                                        showFtp={true}
                                        showSendFtpCredentialsBtn={supplier.email ? true : false}
                                        textFirst={textFirst}
                                        textSecond={textSecond}
                                        textThird={textThird}
                                        accountId={props.match.params.id}
                                        supplier={supplier}/>
                            </Block>
                        </div>
                    </div>
                </div>
                <div className="page-actions">
                    <div className="page-actions__action">
                        <NavLink to={"/admin/client/dashboard"}>
                            <CustomButton title={t("Cancel")} type={"lightgray"}/>
                        </NavLink>
                    </div>
                    <div className="page-actions__action">
                        <CustomButton title={t("Delete")} type={"red"} onClick={() => setShowModal(true)}/>
                    </div>
                    <div className="page-actions__action">
                        <CustomButton title={t("Save")} type={"green"} onClick={handleSaveClick}/>
                    </div>
                </div>
            </div>
            <DeleteModal
                id={props.match.params.id}
                accounts={hasRefs}
                undoTitle={t("This supplier has been deleted")}
                onDelete={() => {
                    props.history.push(`/admin/accounts`)
                }}
                onHide={() => {
                    setShowModal(false);
                }}
                show={showModal}/>
        </Fragment>
    );
}

export default EditSupplier;