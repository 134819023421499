import React, {useState, useEffect} from "react";
import axios from "axios1";
import NotFound from "components/NotFound";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import SearchFilterBtn from "components/SearchFilterBtn";
import ExtraFilters from "components/ExtraFilters";
import _ from 'lodash';
import $ from "jquery";
import Loading from "admin/administrator/LoadingComponent/Loading.component";
import Icon from 'components/Icon';
import Groups from "components/groups/Groups";
import Sections from "components/sections/Sections";
import { Fragment } from "react";
import Notify from "components/Notify";
import { useTranslation } from 'react-i18next';

const Filters = ({
        searchResult,
        settings,
        saveSearchResult,
        countProductsQuantity,
        setStockLoad,
        setClearPartNumber
    }) => {

    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [warrningShow, setWarrningShow] = useState(false);
    const multGroups = settings.multi_select_groups;

    useEffect(() => {
        const status = ((searchResult.selectedSections.length && searchResult.selectedGroups.length) || (searchResult.groups.length === 1 && searchResult.selectedSections.length) ) ? true : false;
        saveSearchResult("showProducts", status);
    }, [searchResult.selectedSections, searchResult.selectedGroups])

    useEffect(() => {
        let brandsWrapper = document.getElementById('brandsWrapper');
        if(document.contains(brandsWrapper)) {
            if($(document).scrollTop() > 200) {
                brandsWrapper.classList.add('sticky');
            }
        }
    }, [searchResult.showExtraFilter])

    useEffect(() => {
        if (searchResult.groups.length === 1)
            getSections(searchResult.groups[0].group_id);
    }, [searchResult.groups]);

    useEffect(() => {
        if(searchResult.groups && searchResult.groups.length === 1 && searchResult?.sections[0]?.length === 1 && searchResult.lookup_id) {
            chooseSection(searchResult.sections[0][0].section, searchResult.sections[0][0].section_id);
        }
    }, [searchResult.lookup_id])

    const clearSearch = () => {
        setClearPartNumber(true);
        saveSearchResult("groups", []);
        saveSearchResult("sections", []);
        saveSearchResult("selectedGroups", []);
        saveSearchResult("selectedSections", []);
        saveSearchResult("selectedBrands", []);
        saveSearchResult("products", []);
        saveSearchResult("filteredProducts", []);
        saveSearchResult("productsQuantity", 0);
        saveSearchResult("showProducts", false);
        saveSearchResult("hideForPartNumber", false);
        saveSearchResult("fromPartNumber", false);
        saveSearchResult("fromNonPartNumber", false);
    }

    const chooseGroup = key => {
        let filteredProducts = {...searchResult.filteredProducts};
        let groups = [...searchResult.groups];
        let selectedGroups = [...searchResult.selectedGroups];
        let selectedSections = [...searchResult.selectedSections];

        clearOnNonMultiple();

        getLookupOnNonAuto(groups, key);
        
        // if (!multGroups) {
        //     saveSearchResult('products', []);
        //     saveSearchResult('sections', []);
        //     saveSearchResult('filteredProducts', []);
        //     saveSearchResult('showProducts', false);            
        // }

        // let group = _.find(groups, { 'group_id': key });

        // if(group.no_auto == 1) {
        //     axios.get("catalogue/by-group/" + group.group_id)
        //         .then((response) => {
        //             saveSearchResult("lookup_id", response.data.lookup_id);
        //         });
        // }

        if (selectedGroups.includes(key)) {
            saveSearchResult('showExtraFilter', false);
            const index = selectedGroups.indexOf(key);
            if (index > -1) {
                groups.map(g => {
                    if (g.group_id == key) {
                        g.sections.map(section => {
                            filteredProducts = _.omit(filteredProducts, [section.section]);
                            const sectionindex = selectedSections.indexOf(section.section);
                            if (sectionindex > -1) {
                                selectedSections.splice(index, 1);
                                filter(filteredProducts, selectedSections);
                            }
                        })
                    }
                })
                
                if(!selectedSections.length) {
                    saveSearchResult("showProducts", false);
                }

                saveSearchResult("selectedSections", selectedSections);
                selectedGroups.splice(index, 1);
                getSections(selectedGroups);
            }
        } else {
            if (!multGroups) {
                selectedGroups = [key];
                saveSearchResult("selectedSections", []);
            } else {
                selectedGroups.push(key);
            }

            if(selectedGroups && selectedGroups.length > 0) {
                getSections(selectedGroups);
            }
        }

        if(selectedGroups.length === 0 && selectedSections.length === 0) {
            saveSearchResult('showExtraFilter', false);
            saveSearchResult('selectedAxles', []);
            saveSearchResult('selectedBrands', []);
        }
 
        saveSearchResult("selectedGroups", selectedGroups);
    }

    const getLookupOnNonAuto = (groups, group_id) => {
        let group = _.find(groups, { 'group_id': group_id });

        if(_.every(group.sections, section => section.is_universal === '1')) {
            axios.get("catalogue/by-group/" + group.group_id)
                .then((response) => {
                    saveSearchResult("lookup_id", response.data.lookup_id);
                });
        }
    }

    const clearOnNonMultiple = () => {
        if (!multGroups) {
            saveSearchResult('products', []);
            saveSearchResult('sections', []);
            saveSearchResult('filteredProducts', []);
            saveSearchResult('showProducts', false);            
        }
    }

    const chooseGroupBySection = (group_id, section, section_id) => {
        let selectedGroups = [...searchResult.selectedGroups];
        let groups = [...searchResult.groups];

        if(!selectedGroups.includes(group_id)) {

            clearOnNonMultiple();
            getLookupOnNonAuto(groups, group_id)

            if (!multGroups) {
                selectedGroups = [group_id];
                saveSearchResult("selectedSections", []);
            } else {
                selectedGroups.push(group_id);
            }

            if(selectedGroups && selectedGroups.length > 0) {
                getSections(selectedGroups);
            }

            saveSearchResult("selectedGroups", selectedGroups);
        }

        if(selectedGroups.length === 0 && searchResult.selectedSections.length === 0) {
            saveSearchResult('showExtraFilter', false);
            saveSearchResult('selectedAxles', []);
            saveSearchResult('selectedBrands', []);
        }
        chooseSection(section, section_id)

    }

    const chooseSection = (section, section_id) => {
        let selectedSections = [...searchResult.selectedSections];
        let products = {...searchResult.products}

        if (!selectedSections.length || !selectedSections.includes(section)) {
            saveSearchResult("showExtraFilter", false)
        }

        if (selectedSections.includes(section)) {
            products = _.omit(products, [section]);
            const index = selectedSections.indexOf(section);

            if (index > -1) {
                selectedSections.splice(index, 1);
                filter(products, selectedSections);
            }

        } else {
            selectedSections.push(section);
            setStockLoad(true);
            setLoading(true);
            axios.get("catalogue/search/by-section/" + searchResult.lookup_id + "/" + section_id)
                .then( response => {
                    setLoading(false);
                    if (response.data.hasOwnProperty('success') && response.data.success) {
                        products[section] = response.data.products;
                        saveSearchResult("section_lookup_id", response.data.section_lookup_id);

                        filter(products, selectedSections);
                        getStockByPartNumber(response.data.products).then( partNumbersResponse => {
                            if (partNumbersResponse.data.hasOwnProperty('success') && partNumbersResponse.data.success) {
                                let productIds = partNumbersResponse.data.productIds;
                                products[section] = _.map(response.data.products, function (element2) {
                                    // if (element2.stock.length > 0) {
                                        let currentStock = _.find(productIds, function (stockElement) {
                                            return (stockElement.ItemNumber === element2.part_number.part_number);
                                            // return (stockElement.part_number_id == element2.part_number_id && element2.stock[0].account_id == stockElement.account_id);
                                        })

                                        if (currentStock) {
                                            element2.quantity = currentStock.InventQty;
                                            element2.SalesUnit = currentStock.SalesUnit;
                                            //  currentStock.InventQty;
                                            element2.price = currentStock.NetPrice;
                                            element2.netGrossPrice = currentStock.NetGrossPrice;
                                            element2.depositPrice = currentStock.DepositPrice;
                                            
                                            // element2.stock[0].quantity = currentStock.stock;
                                        }
                                    // }
                                    return element2;
                                })
                                setStockLoad(false);
                            }
                        })
                    }
                });
        }
        saveSearchResult("products", products);
        // saveSearchResult('selectedSections', selectedSections)
    }


    const getStockByPartNumber = products => {
        let productIds = [];
        productIds = sortProductIds(products, productIds);
        return axios.post("catalogue/search/stock", {accounts: productIds});
    }

    const sortProductIds = (products, productIds) => {
        _.map(products, function (product) {
            // if (product.stock.length > 0 && product.stock[0].quantity === null) {
                productIds.push({
                    part_number_id: product.part_number_id,
                    // account_id: product.stock[0].account_id
                });
            // }
        })
        return productIds;
    }

    const getSections = selected => {
        let sections = [];
        let groups = [...searchResult.groups];

        if (groups && groups.length === 1) {
            sections.push(groups[0].sections);
            saveSearchResult('sections', sections)
            return;
        }

        if (selected) {

            if(groups.length > 0 && selected.length > 0 && !multGroups) {
                sections = [_.find(groups, group => selected.includes(group.group_id))?.sections];
            }else {
                groups.map( group => {
                    if(selected.includes(group.group_id)) {
                        sections.push(group.sections);
                    }
                });
            }

            saveSearchResult('sections', sections)

            if (searchResult.fromNonPartNumber && groups.length > 0) {
                chooseSection(sections[0][0].section, sections[0][0].section_id)
            }
        }
    }
    
    const filter = (items, selected) => {
        let filteredProducts = {};
        let uniqueBrands = [];
        let positions = {...searchResult.positions};
        // let axlesArr = [];
        for (let product in items) {
            filteredProducts[product] = (items[product]).filter( item => {
                let positionsArr = item.position ? item.position.split(" ").map(i => i.toLowerCase()) : [];
                // let includesAll = true;
                
                // let selectedAxlesFiltered = earchResult.selectedAxles;
                // selectedAxlesFiltered = selectedAxlesFiltered.filter(item => {
                //     if (!positionsArr.includes(item)) {
                //         includesAll = false;
                //         return false;
                //     } else {
                //         return true;
                //     }
                // });

                // axlesArr = includesAll ? selectedAxlesFiltered : [];
                // axlesArr = selectedAxlesFiltered;
                let filterByBrands = searchResult.selectedBrands.length > 0 ? searchResult.selectedBrands.includes(item.part_number.brand.name) : true
                // let filterByAxles = searchResult.selectedAxles.length > 0 ? axlesArr.length > 0 : true;
                let filterBySections = selected.length > 0 ? selected.includes(product) : true
                if (filterBySections) {
                    _.map( positions, (val, key) => {
                       val = !val ? positionsArr.includes(key) : true;
                       positions[key] = val;
                    });
                    uniqueBrands.push(item.part_number.brand);
                }
                return filterBySections && filterByBrands;
                //  && filterByAxles;
            });
        }

        uniqueBrands = uniqueBrands.reduce(function (unique, o) {
            if (!unique.some(obj => obj.name === o.name)) {
                unique.push(o);
            }

            return unique;
        }, []);

        saveSearchResult('selectedSections', selected)
        saveSearchResult("positions", positions);
        // setUniqueBrands(newUniqueBrands);
        // props.setStateFromChild("hideForPartNumber", fromPartNumber);
        saveSearchResult("filteredProducts", filteredProducts);
        saveSearchResult("uniqueBrands", uniqueBrands);
        saveSearchResult("productsQuantity", countProductsQuantity(filteredProducts));
        saveSearchResult("selectedBrands", searchResult.selectedBrands);
        
        // updateShowProducts(filteredProducts);
    }

    const chooseAxle = axle => {
        let selectedAxles = searchResult.selectedAxles ?? [];
        if (selectedAxles.includes(axle)) {
            let index = selectedAxles.indexOf(axle);
            if (index > -1) {
                selectedAxles.splice(index, 1);
                saveSearchResult("selectedAxles", selectedAxles);
            }
        } else {
            selectedAxles.push(axle);
            saveSearchResult("selectedAxles", selectedAxles);
        }

        filter(searchResult.products, searchResult.selectedSections);
    }

    const chooseBrand = brand => {
        let selectedBrands = searchResult.selectedBrands ?? [];
        if (selectedBrands.includes(brand)) {
            let index = selectedBrands.indexOf(brand);
            if (index > -1) {
                selectedBrands.splice(index, 1);
            }
        } else {
            selectedBrands.push(brand);
        }

        saveSearchResult("selectedBrands", selectedBrands)
        filter(searchResult.products, searchResult.selectedSections);
    }

    return (
        <Fragment>
            <Loading loading={loading}/>
            {/* {
                (searchResult.fromNonPartNumber && searchResult.nonAutoActive) &&
                    <div className={'clear-search-right'}>
                        <CustomButton title={"CLEAR SEARCH"} onClick={() => clearSearch()} type={"blue"}/>
                    </div>
            } */} 
            {
            (searchResult.groups && searchResult.groups.length > 0 && searchResult.vehicleName != "") ?
                <div className="vehicle" id="vehicle">
                    <div className="vehicle__icon">
                        <Icon file="car-side.svg" type="light" width="50px" height="50px"/>
                    </div>
                    <div className="vehicle__name">
                        {searchResult.vehicleName}
                    </div>
                    {
                        (searchResult.productsQuantity > 0 || (searchResult.groups && searchResult.groups.length == 0)) &&
                            <div className={"vehicle__count"}>
                                <b>{searchResult.productsQuantity}</b> {searchResult.productsQuantity > 1 ? t("Products Found") : t("Product Found")}
                            </div>
                    }
                    {
                        (settings.search_filter && searchResult.selectedSections.length > 0) ?
                            <SearchFilterBtn
                                handleClick={() => saveSearchResult('showExtraFilter', !searchResult.showExtraFilter)}
                                showExtraFilter={searchResult.showExtraFilter}
                                filtersCount={searchResult.selectedBrands.length}/>
                        : null
                    }
                </div> : null
            }
            <ExtraFilters showExtraFilter={searchResult.showExtraFilter}
                        selectedSections={searchResult.selectedSections}
                        closeExtraFilters={() => saveSearchResult("showExtraFilter", false)}
                        brands={searchResult.uniqueBrands}
                        hasAxles={true}                            
                        positions={searchResult.positions}
                        selectedAxles={searchResult.selectedAxles}
                        selectedBrands={searchResult.selectedBrands}
                        chooseAxle={chooseAxle}
                        chooseBrand={chooseBrand}/>

            {searchResult.showProducts && <NotFound data={searchResult.groups} sections={searchResult.sections} />}
            
            {
                searchResult.groups &&
                    <div className={"advanced-search"}>
                        <Groups
                            groups={searchResult.groups}
                            fromPartNumber={searchResult.fromNonPartNumber}
                            clearSearch={clearSearch}
                            chooseGroup={chooseGroup}
                            chooseGroupBySection={chooseGroupBySection}
                            selectedGroups={[...searchResult.selectedGroups]}/>
                        { 
                            searchResult.sections.length > 0  && 
                                <Sections sections={searchResult.sections} 
                                            selectedSections={searchResult.selectedSections}
                                            fromPartNumber={searchResult.fromNonPartNumber}
                                            nonAutoActive={searchResult.nonAutoActive}
                                            chooseSection={chooseSection}
                                            clearSearch={clearSearch}/> 
                        }
                    </div>
            }

            <Notify type={"primary"}
                visible={warrningShow}
                close={() => setWarrningShow(false)}
                primaryText={t("The part number you entered could not be found")}
                secondaryText={t("Try search by vehicle selection or registration")}
                actions={[
                    {
                        name: "ok",
                        action: () => setWarrningShow(false),
                        type: "blue"
                    }
                ]}/>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    settings: state.settings,
    searchResult: state.searchResult,
});

const mapDispatchToProps = dispatch => {
    return {
        saveSearchResult: (key, value) => dispatch({
            type: 'SAVE_SEARCH_RESULTS',
            payload: {key, value}
        }),
        saveSelectedSearchType: (key, value) => dispatch({
            type: 'SAVE_SELECTED_SEARCH_TYPE',
            payload: {key, value}
        })
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Filters));
