import React, {Component} from "react";
import { Fragment } from "react";

export default class Price extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let product = this.props.product;
        let price = product.stock ? product.stock[0].price : product.price;
        return (
            <Fragment>
                <span>
                    { parseFloat(price).toFixed(2) }
                    {/* {product.currency.symbol+" "+price} */}
                </span>
            </Fragment>
        )
    }
}