import React, {Fragment} from 'react';
// import "admin/administrator/customModal.css";

const calkCenteredPosition = (props) => {
    let width = props.width || 500;
    // let top = props.top ?  props.top : "";
    let display = props.show ? "block" : "none";
    return {width: width + "px", display: display};
}

const customModal = (props) => {
    return (<Fragment>
                {
                    props.show ?
                    <div className={"custom-modal-overlay"} onClick={props.onHide.bind(this)}> </div> : null 
                }
                <div className={"custom-modal-wrapper"}
                     style={calkCenteredPosition(props)}
                >   
                    <div className={"custom-modal-header"}>
                        {
                            props.title ?
                                <div className="custom-modal-header-title"> {props.title} </div> 
                            : null
                        }
                        {
                            !props.hideClose ? 
                                <i  className={"custom-modal-close fas fa-times"} onClick={props.onHide.bind(this)} ></i>
                            : null
                        }
                    </div>
                    <div className={"custom-modal-body"}>
                        {props.children}
                    </div>
                </div>
            </Fragment>
        );
}

export default customModal;