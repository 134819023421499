import React, { Component } from "react";
import axios from "axios1";
import $ from "jquery";
import _ from "lodash";
// import "admin/administrator/Sections.css";
import { GridLoader } from 'react-spinners';
import alertify from 'alertifyjs';
import Information from "images/information.svg";
import { withTranslation } from 'react-i18next';
import i18n, { t } from 'i18next';

export default class Sections extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            branches: [],
            loading: true
        }
    }

    componentDidMount() {
        this.retrieveBranches();
    }

    handleSaveClick()
    {
        let branches = this.defineSectionsOrder();

        this.updateBranchSections(branches);
    }

    render()
    {

        alertify.defaults = {
            notifier: {
                position: 'top-right',
                delay: 5
            }
        }

        return (
            <div className={"dashboard-page-wrapper"}>
                {
                    this.state.loading ?
                        <div className='sweet-loading'>
                            <GridLoader
                                sizeUnit={"px"} 
                                size={15}
                                color={'#0066b8'}
                                loading={this.state.loading}
                            />
                        </div> : null
                }
                <div className={"dashboard-page-navigations"}>
                    <div>
                        <i className="fas fa-puzzle-piece"></i>
                        <span> Manage Sections</span>
                    </div>
                    <div>
                        <button className="back-btn" onClick={() => this.props.history.goBack()}>
                            Go Back
                        </button>
                    </div>
                </div>
                <div className={"manage-hubs-wrapper"}>
                    <div class="manage-hubs-header">
                        <div> 
                            <img src={Information} style={{width:"36px"}} />
                            <div className={"manage-header-text"}>
                                <h5>On this page you can set order and disable/enable groups and sections for each branch</h5>
                                <span>* Only enabled sections will be visible throughout the branch in selected order</span>
                            </div>
                        </div>
                    </div>
                    <div className="section-hubs">
                        {
                            this.state.branches.map((branch, index) => {
                                return <div key={branch.id} className={"branch-wrapper"}>
                                    <div className={"branch-name"}>{branch.name}</div>
                                    <div className="section-checkbox selectAll">
                                        <label htmlFor={"brand-all"} className={"selectAllLable"} >{t("Select/Deselect All")}</label>
                                        <div className="md-checkbox">
                                            <input type="checkbox" checked={this.state['branches-'+branch.id] ? true:false} onChange={this.handleAllChecked.bind(this,{name:"branches",id: branch.id})} id={"branches-"+branch.id }/>
                                            <label htmlFor={"branches-" + branch.id}></label>
                                        </div>
                                    </div>
                                    <ul id={"sortableGroup-branchId" + branch.id} className={"section-hubs"}>
                                        {
                                            branch.sortedGroups.map((group) => {
                                                return <li className={"group-li"} key={group.id}
                                                           id={"group-" + branch.id + "-" + group.id}
                                                           data-branch-id={branch.id} data-group-id={group.id}>
                                                    <div className={"hub-name-wrapper"}><span className="arrows-icons"><i class="fas fa-sort-up"></i><i class="fas fa-sort-down"></i></span> {group.name}</div>
                                                    <ul id={"sortableSection-branchId" + branch.id + "groupId" + group.id}
                                                        className={"section-hubs"}>
                                                        {
                                                            group.sortedSections.map((section) => {
                                                                return <li key={section.id} className={"section-sub-hubs"}
                                                                           id={"section-" + branch.id + "-" + group.id + "-" + section.id}>
                                                                    <span className="hub-name-wrapper"><span className="arrows-icons"><i class="fas fa-sort-up"></i><i class="fas fa-sort-down"></i></span> {section.name}</span>
                                                                        <div className="md-checkbox">
                                                                            <input type="checkbox"  defaultChecked={section.selected ? true : false}  title="Disable/enable hub" onChange={this.handleSectionChange.bind(this,{groupId:group.id,sectionId:section.id,branchId:branch.id})}  data-section-id={section.id}   id={"section" + branch.id + group.id + section.id}/>
                                                                            <label htmlFor={"section" + branch.id + group.id + section.id}></label>
                                                                        </div>
                                                                </li>
                                                            })
                                                        }
                                                    </ul>
                                                </li> 
                                            })
                                        }
                                    </ul> 

                                </div>;
                            })
                        }
                    </div>
                    <div className={"save-hubs-button"} onClick={this.handleSaveClick.bind(this)}>Update</div>
                </div>
            </div>
        );
    }

    updateBranchSections(branches)
    {
        this.setState({loading: true})

        axios.post("/catalogue/updateBranchSections", {
            branches: branches
        }).then((response) => {
            if(response.data.msg) {
                this.setState({loading: false})
                alertify.success(response.data.msg);
            } else {
                this.setState({loading: false})
                alertify.error("something went wrong");
            }
        });
    }

    defineSectionsOrder()
    {
        let branches = [...this.state.branches];
        branches.forEach((branch) => {
            let sections = $("#sortableGroup-branchId" + branch.id + " [type='checkbox']:checked").toArray().map((checkbox) => { return $(checkbox).attr("data-section-id"); });

            branch.sections = sections;
        })
        return branches;
    }
 
    retrieveBranches()
    {
        axios.get("/catalogue/branches/withGroups")
            .then((response) => {
                if(response.data.success) {
                    this.setState({
                        branches: response.data.branches,
                        loading: false
                    }, () => {
                        this.makeSortable(this.state.branches);
                    })
                } else {
                    alert("Error: " + response.data.msg);
                }
            })
    }

    makeSortable(branches)
    {
        let branchesAllArray = [];
        branches.forEach((branch) => {
            branchesAllArray['branches-'+branch.id] = true;
            $( "#sortableGroup-branchId" + branch.id ).sortable({
                placeholder: "ui-state-highlight"
            });
            branch.sortedGroups.forEach((group) => {
                if(group.sortedSections.length > 0) {
                    _.map(group.sortedSections,function(section){
                        if(!section.selected) {
                            branchesAllArray['branches-'+branch.id] = false;
                            section.selected = false;
                        }
                    });  
                }

                $( "#sortableSection-branchId"+branch.id+"groupId" + group.id ).sortable({
                    placeholder: "ui-state-highlight"
                });
            })
        });
        this.setState(branchesAllArray);
    }

    handleSectionChange(propertys, event){ 
        let branchName = 'branches-'+propertys.branchId,
         checkAll = true,
         result = [],
         branches = this.state.branches,
         branch = _.find(branches, { id: propertys.branchId });
        
         if(branch.sortedGroups) {
            _.map(branch.sortedGroups,function(group){
                _.map(group.sortedSections,function(section){
                    if(section.id == propertys.sectionId)
                        section.selected = event.target.checked;
                    if(!section.selected)
                        checkAll = false;  
                });
            })
            result[branches] = branches;
            result[branchName] = checkAll;
            this.setState(result);
        }
    }

    handleAllChecked(property,event) {
        let elementName = property.name + "-"+ property.id;
        let branches = this.state.branches || "";
        var branch = _.find(branches,{id: property.id});
        let result = [];
        if(branch.sortedGroups) {
            _.map(branch.sortedGroups,function(group){
                _.map(group.sortedSections,function(section){
                    section.selected = event.target.checked;
                    return section;  
                });
            })
            result[branches] = branches;
        }

        // let result = [];
        result[elementName] = event.target.checked;
        this.setState(result);

        $( "#sortableGroup-branchId" + property.id + ' input').each(function(i,v){
            v.checked = event.target.checked;
        });
    }
}