import React, { Component } from "react";
// import "admin/administrator/TopAccounts/TopAccounts.css";
// import "admin/administrator/CustomElements.css";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import axios from "axios1";
import _ from "lodash";
var moment = require('moment');

export default class TopAccountsWrapper extends Component
{
    constructor(props){
        super(props); 

        this.state = {
            accounts: []
        }
    }

    componentDidMount() {
        this.getTopAccounts();
    }

    getTopAccounts() {
        axios.get("/catalogue/sales-by-accounts/tops")
            .then((response) => {
                if(response.data.hasOwnProperty('success') && response.data.success) {
                    this.setState({accounts: response.data.accounts});
                }
            })
    }
 
    render()
    {

        return (
            <div className={"top-accounts-wrapper"}>
                <div className={"top-accounts-month"}>
                    <span className={"icon icon-cup"}></span>
                    <span className={"analiticsMainText"}>Top Accounts</span>
                    <span className={"analiticsSubText"}>{moment(new Date()).format('MMMM YYYY') }</span>
                    <NavLink to={"/admin/top-accounts/accounts"} exact>
                        <button className={"custom-btn"}>Details</button>
                    </NavLink>
                </div> 
                <div className={"top-accounts-ranks"}>
                    <table className={"table table-bordered"}>
                        {
                            this.state.accounts.length > 0 ?
                                this.state.accounts.map((account, index) => {
                                    return <tr key={index}>
                                        <td>{(index > 0) ? (index + 1) + "nd" : (index + 1) + "st"}</td>
                                        <td>{account.name}</td>
                                    </tr>
                                }) : null
                        }
                    </table>
                </div>
            </div>
        ); 
    }

} 