import React from 'react';
// import "./Total.css";
import { useTranslation } from 'react-i18next';

function Total({total}) {
    const { t, i18n } = useTranslation();
    return (
        <div className={"flex fw-700 mt-24 text-uppercase"}>
            <span>{t("Total")}</span>
            <span>{total}</span>
        </div>
    );
}

export default Total;