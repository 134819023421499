import React, {useState, useEffect, Fragment} from "react";
import BranchDetails from "admin/administrator/account/BranchDetails";
import UserPreferences from 'admin/administrator/UserPreferences.js';
import StockPreferences from "admin/administrator/StockPreferences";
import CustomSortable from "admin/administrator/CustomSortable";
import CustomButton from "admin/administrator/CustomButton";
import Logins from "admin/administrator/Logins";
import alertify from 'alertifyjs';
import axios from "axios1";
import _ from "lodash";
import PricingLevels from "admin/administrator/PricingLevels";
import ChangeSaveOrDiscardModal from 'admin/administrator/ChangeSaveOrDiscardModal/ChangeSaveOrDiscardModal';
import FormInput from "admin/administrator/FormInput/FormInput.component";
import Notify from "components/Notify";
import Block from "components/Block";
import Loading from "admin/administrator/LoadingComponent/Loading.component";
import { useTranslation } from 'react-i18next';

const AddBranch = props => {
    const { t, i18n } = useTranslation();
    const [errors, setErrors] = useState({
        name: '',
        contact_name: '',
        address_1: '',
        town: '',
        phone: '',
        email: ''
    });
    const [approveModal, setApproveModal] = useState({
        show: false,
        name: '',
        title: '',
        message: t('Would you like to send an email containing login credentials to each new login')+"?"
    });
    const [branch, setBranch] = useState({orders_email: "", branch_code: "", default_branch: true})
    const [suppliers, setSuppliers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [cataloguePreferences, setCataloguePreferences] = useState([]);
    const [stockPrefBranches, setStockPrefBranches] = useState([]);
    const [stockPrefSuppliers, setStockPrefSuppliers] = useState([]);
    const [priceLevels, setPriceLevels] = useState([]);
    const [logins, setLogins] = useState([]);
    const [accountId, setAccountId] = useState(null);
    const [catalogueModalShow, setCatalogueModalShow] = useState(false);
    const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
    const [priceLevelId, setPriceLevelId] = useState(null);

    var unblock = () => {};

    useEffect(() => {
        getValues();
        getBranches();
    }, [])

    useEffect(() => {
        unblock();
    })

    useEffect(() => {
        let loginsWithPreferences = logins.filter((login) => {
            return (login.preferences && login.preferences.groups && login.preferences.form) ? login : null;
        })

        if (loginsWithPreferences.length > 0) {
            unblock = props.history.block(() => {
                setShouldBlockNavigation(true)
                return false;
            });
        }
    }, [logins])

    useEffect(() => {
        if (cataloguePreferences.hasOwnProperty('form') || cataloguePreferences.hasOwnProperty('groups')) {
            unblock = props.history.block(() => {
                setShouldBlockNavigation(true);
                return false;
            });
        }
    }, [cataloguePreferences])

    const setSPriceLevelIdFunc = value => setBranch({...branch, price_level_id: value});
    const setBespokePrice = value => setBranch({...branch, bespoke_price_id: value});
    
    const getValues = () => {
        axios.get("catalogue/api-branches/create")
            .then((response) => {
                if (response.data.success) {
                    setSuppliers(response.data.suppliers)
                    setLoading(false);
                }
            })
    }

    const handleSaveClick = () => {
        if (logins && logins.length > 0) {
            let collection = approveModal;
            collection.show = true;
            collection.title = t("You have created new logins for branch")+" "+ branch.name + ".";
            setShouldBlockNavigation(false);
            setApproveModal({...collection});
        }else {
            saveBranch(false);
        }
        props.history.block(() => true);
    }

    const saveBranch = (status = false) => {
        let newLogins = JSON.parse(JSON.stringify(logins));
        const newCataloguePreferences = JSON.parse(JSON.stringify(cataloguePreferences));
        // For logins component --->
        if (logins && logins.length > 0) {
            newLogins = _.map(logins, function (login) {
                if (login.preferences) {
                    if (login.preferences.groups) {
                        let groups = login.preferences.groups;
                        login.preferences.groups = filtreGroups(groups);
                    }
                    if (login.preferences.form) {
                        if (login.preferences.form) {
                            login.preferences.form = filtreForm(login.preferences.form);
                        }
                    }
                }
                return login;
            })
        }

        // For catalogue preferences --->
        newCataloguePreferences.groups = filtreGroups(newCataloguePreferences.groups)

        if (newCataloguePreferences.form) {
            newCataloguePreferences.form = filtreForm(newCataloguePreferences.form);
        }
        // <---

        // For stock preferences --->
        let branchesIds = getIdsArray(stockPrefBranches);
        let suppliersIds = getIdsArray(stockPrefSuppliers);
        branchesIds = branchesIds.concat(suppliersIds);
        // <---

        setLoading(true)
        setApproveModal({...approveModal, show: false});
        setShouldBlockNavigation(false);
        
        axios.post("catalogue/api-branches", {
            logins: newLogins,
            branch: branch,
            cataloguePreferences: newCataloguePreferences,
            notificationStatus: status,
            priceLevels: priceLevels,
            branchIds: branchesIds,
            price_level_id: priceLevelId
        }).then((response) => {
            let newErrors = response.data.errors ? response.data.errors : [];
            setLoading(false);
            setErrors(newErrors);
            if (response.data.success && !response.data.errors) {
                alertify.success(response.data.message);
                props.history.block(() => true);
                props.history.push("/admin/accounts");
            } else {
                let message = response.data && response.data.errors && response.data.errors.length > 0 ? response.data.errors.toString() : response.data.message;
                alertify.error(message);
            }
        })
    }

    const setPriceLevelsFromChild = priceLevel => {
        const newPriceLevels = priceLevels;

        let existingPriceLevel = _.find(newPriceLevels, function (element) {
            return (element.section_id == priceLevel.section_id && element.brand_id == priceLevel.brand_id) ? true : false;
        })

        if (existingPriceLevel) {
            existingPriceLevel.price_level_id = priceLevel.price_level_id;
            existingPriceLevel.price_level = priceLevel.price_level
        } else {
            newPriceLevels.push(priceLevel);
        }

        setPriceLevels([...newPriceLevels]);        
    }
    
    const removePriceLevelException = priceLevel => {
        setLoading(true);
        let newPriceLevels =_.clone(priceLevels);
        _.remove(newPriceLevels, function (item) {
            return (item.brand_id == priceLevel.brand_id && item.section_id == priceLevel.section_id) ? true : false;
        });
        setLoading(false);
        setPriceLevels(newPriceLevels);        
    }

    const getIdsArray = elements => {
        let result = [];
        _.map(elements, function(element) { 
            if(element.checked) {
                result.push(element.id)
            }
        })
        return result;
    }

    const updateBranch = (name, event) => {
        let newBranch = branch
        newBranch[name] = event.target.value;
        setBranch({...newBranch});
    }

    const getBranches = () => {
        axios.get("catalogue/accounts/branches")
            .then((response) => {
                if (response.data.success) {
                    let stockPrefBranches = [];
                    let stockPrefSuppliers = [];
                    _.each(response.data.branches, (element) => {
                        element.active = 1;
                        if(element.business_type_id == 3 ) {
                            stockPrefBranches.push(element);
                        }else {
                            stockPrefSuppliers.push(element);
                        }
                    });
                    setStockPrefBranches(stockPrefBranches);
                    setStockPrefSuppliers(stockPrefSuppliers);
                    setLoading(false)
                }
            })
    }

    const handleCheckStockSupplier = (suppliers, name, event) => {
        let element = _.find(suppliers, { name: name });
        element.checked = event.target.checked;
        setStockPrefSuppliers([...suppliers]);
    }

    const defaultChange = event => setBranch({...branch, default_branch: event.target.checked})

    const getPreferences = async () => {
        let newCataloguePreferences = cataloguePreferences;
        if (!newCataloguePreferences.form) {
            await axios.get("catalogue/preferences")
                .then((response) => {
                    if (response.data.success) {
                        newCataloguePreferences = response.data.preferences;
                        return newCataloguePreferences;
                    }
                })
        }
        return newCataloguePreferences;
    }

    const getSections = group_id => axios.get("catalogue/preferences/sections?groupId=" + group_id);
    const getBrands = section_id => axios.get("catalogue/preferences/brands?sectionId=" + section_id);
    const filtreForm = form => {
        if (form.additional_elements) {
            let additional_elements = _.filter({...form.additional_elements}, function (additional_element) {
                if (additional_element.checked) {
                    return additional_element.value;
                }
            })
            form.additional_elements = _.map(additional_elements, 'value');
        }
        if(form.search_types) {
            let search_types = _.filter({...form.search_types}, function (search_type) {
                if (search_type.checked) {
                    return search_type.value;
                }
            })
            form.search_types = _.map(search_types, 'value');
        }
        if (form.manufacturers) {
            let manufacturers = _.filter({...form.manufacturers}, function (manufacture) {
                if (manufacture.checked) {
                    return manufacture.name;
                }
            })
            form.manufacturers = _.map(manufacturers, 'name');
        }
        return form;
    }

    const filtreGroups = groups => {
        groups = _.map(groups, function (group) {
            let response = {
                id: group.id,
                sections: {}
            };
            if (group.sections) {
                response.sections = _.map(group.sections, function (section) {
                    let response = {
                        id: section.id,
                        checked: section.checked,
                        // brands: []
                    };
                    if (section.brands) {
                        let brands = _.filter(section.brands, function (brand) {
                            if (brand.checked) {
                                return brand.id;
                            }
                        })

                        response.brands = _.map(brands, 'id');
                    }

                    return response;
                })
            }
            return response;
        });
        return groups;
    }
    const closeNotifyApprove = () => setApproveModal({...approveModal, show: false});

    alertify.defaults = {
        notifier: {
            position: 'top-right',
            delay: 5
        }
    }
    
    return (
        <Fragment>
            <Loading loading={loading}/>
            {
                shouldBlockNavigation &&
                    <ChangeSaveOrDiscardModal
                        saveChanges={handleSaveClick}
                        closeChangesModalDisregard={() => {
                            unblock();
                            logins.map((login) => {
                                delete login.preferences.groups;
                                delete login.preferences.form;
                            });
                            setShouldBlockNavigation(false)
                            props.history.block(() => true);
                        }}
                        modalShow={shouldBlockNavigation}
                        closeChangesModal={ () => setShouldBlockNavigation(false) }
                    />
            }
            <div className={"page-content admin-wrapper"}>
                <div className="grid">
                    <div className="grid__row">
                        <div className="grid__column grid__column--3">
                            <BranchDetails setBranch={setBranch} 
                                    branch={branch}
                                    errors={errors}
                                    isEdit={false}
                                    setErrors={setErrors}/>
                            <Block type="1" title={t("order recipient")} style={{marginTop: "24px"}}>
                                <FormInput
                                    name='orders_email'
                                    type='email'
                                    placeholder={t('Email Address')}
                                    error={errors.orders_email}
                                    value={branch.orders_email}
                                    onChange={updateBranch.bind(this, "orders_email")}/>
                            </Block>
                            <Block type="1" title={t("stock feed")} style={{marginTop: "24px"}}>
                                <FormInput
                                    name='branch_code'
                                    type='text'
                                    placeholder={t('Branch Code')}
                                    error={errors.orders_email}
                                    value={branch.branch_code}
                                    onChange={updateBranch.bind(this, "branch_code")}/>
                            </Block>
                            <Block type="6" title={t("pricing levels")} style={{marginTop: "24px"}}>
                                <PricingLevels savedPriceLevels={priceLevels}
                                            removePriceLevelException={removePriceLevelException}
                                            save={setPriceLevelsFromChild}
                                            setSPriceLevelId={setSPriceLevelIdFunc}
                                            setBespokePrice={setBespokePrice}
                                            errors={errors}/>
                            </Block>
                        </div>
                        <div className="grid__column grid__column--6">
                            <Block type="1" title={t("users")}>
                                <Logins cataloguePreferences={{...cataloguePreferences}}
                                    logins={logins}
                                    isBranch={true}
                                    setLogins={setLogins}/>
                            </Block>
                        </div>
                        <div className="grid__column grid__column--3">
                            <Block type="1" title={t("stock preferences")}>
                                <div className="stock-providers">
                                <StockPreferences branches={stockPrefBranches}
                                                accountId={accountId}
                                                setBranches={setStockPrefBranches}
                                                defaultChange={defaultChange}
                                                currentBranch={branch}
                                                isBranch={true}/>
                                {
                                    (stockPrefSuppliers && stockPrefSuppliers.length > 0) &&  
                                        <Block type="4" title={t("Suppliers")} style={{marginTop: "24px"}}>
                                            <CustomSortable
                                                items={stockPrefSuppliers}
                                                disabled={false}
                                                // maxHeight = {"300px"}
                                                callBack={setStockPrefSuppliers}
                                                onChangeCheckBox={handleCheckStockSupplier.bind(this, stockPrefSuppliers)}/>
                                        </Block>
                                }
                                </div>
                            </Block>
                        </div>
                    </div>
                </div>
                <div className="page-actions">
                    <div className="page-actions__action">
                        <CustomButton title={t("Account Preferences")} type={"gray"} onClick={() => setCatalogueModalShow(true)}/>
                    </div>
                    <div className="page-actions__action">
                        <CustomButton title={t("Save")} type={"green"} onClick={handleSaveClick}/>
                    </div>
                </div>
            </div>

            <UserPreferences show={catalogueModalShow}
                        preferences={getPreferences}
                        getSections={getSections}
                        getBrands={getBrands}
                        isBranch={true}
                        currentElement={{name: '', title: t("Catalogue Preferences for Account")}}
                        onHide={ setCatalogueModalShow }
                        updatePreferencesValues={preferences => setCataloguePreferences({...preferences, type: "account"})}/>
            <Notify type={"warning"}
                    visible={approveModal.show}
                    close={closeNotifyApprove}
                    primaryText={approveModal.title}
                    secondaryText={approveModal.message}
                    actions={[
                        {
                            name: "no",
                            action: saveBranch.bind(this, false),
                            type: "lightgray"
                        },
                        {
                            name: "yes",
                            action: saveBranch.bind(this, true),
                            type: "blue"
                        },
                    ]}/>
        </Fragment>
    );
}

export default AddBranch;