import React, {Component} from "react";
import "account/user/PersonalInformation.css";
import { connect } from "react-redux";
import axios from "axios1";
import alertify from 'alertifyjs';

class PersonalInformation extends Component
{
    constructor(props)
    {
        super(props);  
        this.state = {
            branches:[
                { id:0, name: "Select branch", active:'1' }
            ],
            userTypes:[
                { id:0, name: "Select user type" }
            ],
            form: {
                business_type_id: 0, 
                branch_id: 0,
                address_line_1: "",
                address_line_2: "", 
                approved:1,
                company: "",
                name: "", 
                email: "",
                contact_no: "",
                town: "",
                county: "",
                country:'',
                postal_code: "",
                website:''
            },
            errors: [],
            loading:false,
        }
        this.handleSwitchChange = this.handleSwitchChange.bind(this);
    }

    componentDidMount() {
        this.getUserInfo();
    }

    getUserInfo() {
        axios.get("/catalogue/account/getUser")
            .then(response => {
                var data = response.data;
                this.setState({
                    branches:data.branches, 
                    userTypes:data.business_types,
                    form:data.user,
                    // theme: data.theme
                });
            })
    }

    handleInputChange(formName, event)
    {
        let value = event.target.value;

        let form = { ...this.state.form };

        form[formName] = value;

        this.setState({ form: form });
    }

    handleSwitchChange(property)
    {
        let newObj = {};
        
        newObj[property] = !this.state[property];
        this.setState(newObj);
    }

    handleSave(){
        this.setState({loading:true});
        axios.post("/catalogue/account/editUser", {form: this.state.form}) 
            .then(response => {
                this.setState({loading:false});
                window.scrollTo(0, 0)
                if(response.data.success){
                    alertify.success(response.data.msg);
                    this.setState({ errors:[]});
                    // $("body").removeAttr('class');
                    // $("body").addClass(response.data.theme);
                    // this.props.changeTheme(response.data.theme);
                }else{
                    alertify.error(response.data.msg);
                    this.setState({ errors: response.data.errors}); 
                }
            })
    }

    render() {


        alertify.defaults = {
            notifier: {
                position: 'top-right',
                delay: 5
            }
        }
        return (
            <>
            <h1 className={"heading UserAccountHeader"}>Personal Information</h1>
            <div className={"col"}>
                <div className={"form-wrapper"}>
                <div className={"dashboard-page-body"}>
                    <div className={"dashboard-add-user-body user-account"}>
                    <div className={"add-user-left"}>
                        <div className={"row"}>
                        <div className={"col-sm-6"}>
                            <label htmlFor={"bussines_type_id"}>Bussines Type:</label>
                                <select className={"add-user-user-type-select user-account-forms"} value={this.state.form.business_type_id} onChange={this.handleInputChange.bind(this, "business_type_id")}>
                                    {
                                        this.state.userTypes.map((userType) => {
                                            return <option key={userType.id} value={userType.id}>
                                                {userType.name}
                                            </option>
                                        })
                                    }
                                </select>
                                {
                                    this.state.errors["business_type_id"] ?
                                    <span className="error">{this.state.errors["business_type_id"]}</span>
                                    : null
                                }
                        </div>
                        <div className={"col-sm-6"}>
                        <label htmlFor={"branch_id"}>Branch:</label>
                        <select className={"add-user-branch-select user-account-forms"} value={this.state.form.branch_id} onChange={this.handleInputChange.bind(this, "branch_id")}>
                            {
                                this.state.branches.map((branch) => {
                                    return <option key={branch.id} value={branch.id} disabled={(branch.active==1)?false:true}>
                                        {branch.name}{(branch.active==1)?"":' (disabled)'}
                                    </option>
                                })
                            }
                        </select>
                            {
                                this.state.errors["branch_id"] ?
                                <span className={"error"}>{this.state.errors["branch_id"]}</span>
                                : null
                            }
                        </div>
                        </div>
                        
                        <div className={"row"}>
                            <div className={"col-sm-12"}>
                                <label htmlFor={"company"}>Company:</label>
                                <input type={"text"} className={"user-account-forms"}  placeholder={"Company"} value={this.state.form.company} onChange={this.handleInputChange.bind(this, "company")} />
                                {
                                    this.state.errors['company'] ? 
                                    <span className={"error"}>{this.state.errors["company"]}</span>
                                    : null
                                }
                            </div>
                            <div className={"col-sm-12"}>
                                <label htmlFor={"name"}>Contact Name:</label>
                                <input type={"text"} className={"user-account-forms"} placeholder={"Contact Name"} value={this.state.form.name} onChange={this.handleInputChange.bind(this, "name")} />
                                {
                                    this.state.errors['name'] ? 
                                    <span className={"error"}>{this.state.errors["name"]}</span>
                                    : null
                                }
                            </div>
                        </div>
                        <div className={"form-group"}>
                            <label htmlFor={"email"}>Email:</label>
                            <input className={"user-account-forms"} disabled type={"email"} placeholder={"Email"} value={this.state.form.email}/>
                        </div>
                        <div className={"form-group"}>
                            <label htmlFor={"address_line_1"}>Address Line 1:</label>
                            <input className={"add-user-address1 user-account-forms"} type={"text"} placeholder={"Address Line 1"} value={this.state.form.address_line_1} onChange={this.handleInputChange.bind(this, "address_line_1")}/>
                            {
                                this.state.errors['address_line_1'] ?
                                <span className={"error"}>{this.state.errors["address_line_1"]}</span>
                                : null
                            }
                        </div>
                        <div className={"form-group"}>
                            <label htmlFor={"address_line_2"}>Address Line 2:</label>
                            <input className={"add-user-address2 user-account-forms"} type={"text"} placeholder={"Address Line 2"} value={this.state.form.address_line_2} onChange={this.handleInputChange.bind(this, "address_line_2")}/>
                            {
                                this.state.errors['address_line_2'] ?
                                <span className={"error"}>{this.state.errors["address_line_2"]}</span>
                                : null
                            }
                        </div>
                        <div className={"form-group"}>
                            <label htmlFor={"country"}>Country:</label>
                            <input className={"user-account-forms"} type={"text"} placeholder={"Country"} value={this.state.form.country} onChange={this.handleInputChange.bind(this, "country")}/>
                            {
                                this.state.errors['country'] ?
                                <span className={"error"}>{this.state.errors["country"]}</span>
                                : null
                            }
                        </div>
                        <div className={"form-row"}>
                            <div className={"col-sm-12"}>
                                <label htmlFor={"town"}>Town:</label>
                                <input type={"text"} className={"user-account-forms"} placeholder={"Town"} value={this.state.form.town} onChange={this.handleInputChange.bind(this, "town")}/>
                                    {
                                        this.state.errors['town'] ?
                                        <span className={"error"}>{this.state.errors["town"]}</span>
                                        : null
                                    }
                            </div>
                            <div className={"col-sm-12"}>
                                <label htmlFor={"County"}>County:</label>
                                <input type={"text"} className={"user-account-forms"} placeholder={"County"} value={this.state.form.county} onChange={this.handleInputChange.bind(this, "county")}/>
                                    {
                                        this.state.errors['county'] ?
                                        <span className={"error"}>{this.state.errors["county"]}</span>
                                        : null
                                    }
                            </div>
                        </div>

                        <div className={"form-row"}>
                            <div className={"col-sm-12"}>
                                <label htmlFor={"postal_code"}>Post Code:</label>
                                <input type={"text"} className={"user-account-forms"} placeholder={"Post Code"} value={this.state.form.postal_code} onChange={this.handleInputChange.bind(this, "postal_code")}/>
                                    {
                                        this.state.errors['postal_code'] ?
                                        <span className={"error"}>{this.state.errors["postal_code"]}</span>
                                        : null
                                    }
                            </div>
                            <div className={"col-sm-12"}>
                                <label htmlFor={"tel"}>Tel:</label>
                                <input type={"text"} className={"user-account-forms"} placeholder={"Tel"} value={this.state.form.contact_no} onChange={this.handleInputChange.bind(this, "contact_no")}/>
                                    {
                                        this.state.errors['contact_no'] ?
                                        <span className={"error"}>{this.state.errors["contact_no"]}</span>
                                        : null
                                    }
                            </div> 
                        </div>
                        <div className={"form-group"}>
                            <label htmlFor={"wbsite"}>Website:</label>
                            <input className={"user-account-forms"} type={"text"} placeholder={"Website"} value={this.state.form.website} onChange={this.handleInputChange.bind(this, "website")}/>
                            {
                                this.state.errors['website'] ?
                                <span className={"error"}>{this.state.errors["website"]}</span>
                                : null
                            }
                        </div>

                        {/* <div className={"form-group"}>
                            <label htmlFor={"wbsite"}>Displayed Theme:</label>
                            <div className={"dashboard-styles-theme-wrapper"}>
                                <div className={"light-dark-text"}>Dark</div>
                                <Switch  
                                    className={"light-datk-switcher"}
                                    width={38}
                                    height={20}
                                    onChange={() => { this.handleSwitchChange("theme") }} 
                                    checked={this.state.theme} />
                                <div className={"light-dark-text"}>Light</div>
                            </div>
                            {
                                this.state.errors['website'] ?
                                <span className={"error"}>{this.state.errors["theme"]}</span>
                                : null
                            }
                        </div> */}


                    </div>
                        <div className={"add-user-cancel-save-buttons-wrapper"}>
                            <div className={"add-user-save-button"} onClick={this.handleSave.bind(this)}>Save</div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </>         
        );
    }
}

const mapStateToProps = (state) => ({
    settings: state.settings
});

// const mapDispatchToProps = dispatch => {
//     return {
//         changeTheme: (theme) => dispatch({type: 'CHANGE_THEME', payload: theme}),
//     }
// }

export default connect(mapStateToProps)(PersonalInformation);