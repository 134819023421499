import React, {Component} from "react";

export default class CustomButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            types: {
                gray: "btn--secondary",
                green: "btn--success",
                lightgray: "btn--cancel",
                blue: "btn--primary",
                red: "btn--danger",
                statusactive: "custom-btn-status-active",
                statusinactive: "custom-btn-status-inactive",
                statuspending: "custom-btn-status-pending",
                statussuspended: "custom-btn-status-suspended",
                statusdeleted: "custom-btn-status-deleted"
            }
        }
    }

    render() {
        let {title, type, width, height, onClick, margins, disabled, style} = this.props;
        let btnClass = {
            type: this.state.types[type],
            margins: margins.join(" ")
        }
        return (
            <button className={"btn " + btnClass.margins + " " + btnClass.type} style={style} onClick={onClick} disabled={disabled}>
                {title}
            </button>
        );
    }
}

CustomButton.defaultProps = {
    margins: [],
    type: "gray",
    title: "title",
    disabled: false,
    style: {}
}