import React, { Component } from "react";
import axios from "axios1";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
// import "admin/administrator/Statistics.css";

export default class Statistics extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            ordersQty: 0,
            abandonedCartsQty: 0,
            lookupsQty: 0,
            ordersDetailsNavLink:'/admin/ordersDetails',
            AbandonedCartsDetailsNavLink:'/admin/AbandonedCartsDetails',
            lookupsNavLink:'/admin/Lookups',
        }
        
    } 

    componentDidMount() {
        this.setLinks();
        this.countOrders();
        this.countAbandonedCarts();
        this.countLookups();
    }

    setLinks(){
        if(this.props.match.params.user){
            this.setState({
                ordersDetailsNavLink:"/account/ordersDetails",
                AbandonedCartsDetailsNavLink:"/account/AbandonedCartsDetails",
                lookupsNavLink:"/account/Lookups",
            });
        }
    }

    countOrders() {
        axios.get("/catalogue/orders-qty")
            .then(response => {
                this.setState({ordersQty: response.data})
            })
    }

    countAbandonedCarts() {
        axios.get("/catalogue/count-abandoned-carts")
            .then(response => {
                let abandonedCartsQty = response.data;
                this.setState({abandonedCartsQty: abandonedCartsQty})
            })
    }

    countLookups() {
        axios.get("/catalogue/lookups/total")
            .then(response => {
                let lookupsQty = response.data;
                this.setState({lookupsQty: lookupsQty})
            })
    }

    render() {

        const ordersQty = this.state.ordersQty;
        const abandonedCartsQty = this.state.abandonedCartsQty;
        const lookupsQty = this.state.lookupsQty;

        return (
            <div className={"dashboard-page-wrapper"}>
                <div className={"dashboard-page-navigations"}>
                    <div>
                        <i className="fas fa-chart-line"></i>
                        <span> User Statistics</span>
                    </div>
                    <div>
                        <button className="back-btn" onClick={() => this.props.history.goBack()}>
                            Go Back
                        </button>
                    </div>
                </div>
                <div className={"dashboard-page-body"}>
                    <div className="stats">
                        <div className="stat">
                            <div className={"stat-heading"}>Orders</div>
                            <div className="stat-content">
                                <div>
                                    <span className="stat-qty">{ordersQty}</span>
                                </div>
                                <div>
                                    <NavLink to={this.state.ordersDetailsNavLink} className="stat-btn"><i className="fas fa-chart-pie"></i>Details</NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="stat">
                            <div className={"stat-heading"}>Abandoned Carts</div>
                            <div className="stat-content">
                                <div>
                                    <span className="stat-qty">{abandonedCartsQty}</span>
                                </div>
                                <div>
                                    <NavLink to={this.state.AbandonedCartsDetailsNavLink} className="stat-btn"><i className="fas fa-chart-pie"></i>Details</NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="stat">
                            <div className={"stat-heading"}>Lookups</div>
                            <div className="stat-content">
                                <div>
                                    <span className="stat-qty">{lookupsQty}</span>
                                </div>
                                <div>
                                    <NavLink to={this.state.lookupsNavLink} className="stat-btn"><i className="fas fa-chart-pie"></i>Details</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}