import React, { Component } from "react";
// import "admin/administrator/UserList.css";
import axios from "axios1";
import _ from "lodash";
import { GridLoader } from 'react-spinners';
import ReactTable from "react-table";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";

export default class UserList extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            users: [],
            filteredUsers: [],
            userNotFound: false,
            loading: false,
            columns: [
                {
                    Header: 'Company Name',
                    accessor: 'company',
                    style: {
                        textAlign: "center"
                    },
                    className: 'td-dark'
                },
                {
                    Header: 'Branch',
                    accessor: 'branch.name',
                    style: {
                        textAlign: "center"
                    },
                    className: 'td-dark'
                },
                {
                    Header: 'Email',
                    accessor: 'email',
                    style: {
                        textAlign: "center"
                    },
                    className: 'td-dark'
                },
                {
                    Header: 'Contact',
                    accessor: 'contact_no',
                    style: {
                        textAlign: "center"
                    },
                    className: 'td-dark'
                },
                {
                    Header: 'Created',
                    accessor: 'created_at',
                    style: {
                        textAlign: "center"
                    },
                    className: 'td-dark'
                },
                {
                    Header: () => (
                        <span>
                        <i className="fas fa-edit" /> Actions
                    </span>
                    ),
                    Cell: props => (
                        <div>
                            <NavLink to={"/admin/addUser/" + props.original.id} exact className={"btn btn-primary user-view"}>
                                View / Edit
                            </NavLink>
                        </div>
                    ),
                    style: {
                        textAlign: "center"
                    },
                    className: 'actions-td'
                },
                {
                    Header: () => (
                        <span>
                        <i className="fas fa-clipboard-check"></i> Approve / Disapprove
                    </span>
                    ),
                    accessor: "approved",
                    Cell: props => (
                        <div>
                            <button className={"like " + (props.original.approved ? "active" : "")}
                                    onClick={() => this.approveUser(props.original.id)}
                            >
                                <i className="fas fa-thumbs-up" aria-hidden="true"></i>
                            </button>
                            <button className={"dislike " + (!props.original.approved ? "active" : "")}
                                    onClick={() => this.disapproveUser(props.original.id)}
                            >
                                <i className="fas fa-thumbs-down" aria-hidden="true"></i>
                            </button>
                        </div>
                    ),
                    style: {
                        textAlign: "center"
                    }
                }
            ]
        }

    }

    componentDidMount() {
        this.getUsers();
    }

    getUsers() {
        axios.get("catalogue/users")
            .then((response) => {
                let users = response.data;
                let branchId = this.props.match.params.branchId;
                users = branchId ? this.filterUsersByBranch(users, branchId) : users;
                this.setState({users: users});
            });
    }

    filterUsersByBranch(users, branchId){
        return _.filter(users, (user) => { return user.branch.id == branchId });
    }

    approveUser(id) {
        const userId = id;
        this.setState({loading: true});
        axios.put("catalogue/users/approve/" + userId)
            .then((response) => {
                let users = this.state.users;
                let index =_.findIndex(users, {id: response.data.id});
                users[index].approved = 1;
                this.setState({users: users, loading: false});
            });
    }

    disapproveUser(id) {
        const userId = id;
        this.setState({loading: true});
        axios.put("catalogue/users/disapprove/" + userId)
            .then((response) => {
                let users = this.state.users;
                let index =_.findIndex(users, {id: response.data.id});
                users[index].approved = 0;
                this.setState({users: users, loading: false});
            });
    }

    filterByCompany(event) {
        const users = this.state.users;
        const searchVal = event.target.value;
        let filteredUsers = [];

        let results = _.filter(users, function(item){
            return item.company.toLowerCase().indexOf(searchVal.toLowerCase()) > -1;
        });

        if(results.length === 0) {
            this.setState({userNotFound: true})
        } else if(results.length > 0) {
            filteredUsers = results;
            this.setState({ filteredUsers: filteredUsers, userNotFound: false})
        } else {
            filteredUsers = users;
            this.setState({filteredUsers: filteredUsers, userNotFound: false})
        }

    }

    render() {
        let data;

        if(this.state.userNotFound) {
            data = [];
        } else {
            data = this.state.filteredUsers.length > 0 ? this.state.filteredUsers : this.state.users
        }

        return (
            <div className={"dashboard-page-wrapper"}>
                {
                    this.state.loading ?
                        <div className='sweet-loading'>
                            <GridLoader
                                sizeUnit={"px"}
                                size={15}
                                color={'#0066b8'}
                                loading={this.state.loading}
                            />
                        </div> : null
                }

                <div className={"dashboard-page-navigations"}>
                    <div>
                        <i className="fas fa-users"></i>
                        <span> View / Edit Users</span>
                    </div>
                    <div>
                        <button className="back-btn" onClick={() => this.props.history.goBack()}>
                            Go Back
                        </button>
                    </div> 
                </div>
                <div className={"dashboard-page-body"}>
                    <div className={"add-new-search-wrapper"}>
                        <div className={"branch-button"}>
                            <div className={"branch-back-new-buttons-wrapper"}>
                                <NavLink style={{textDecoration: "none"}} to="/admin/addUser" exact className={"admin-link"} activeClassName="active">
                                    <div className={"branch-add-new-button"}>Create New User</div>
                                </NavLink>
                            </div>
                        </div>
                        <div className={"search-wrapper"}>
                            <input type="text"
                                   placeholder={"Search..."}
                                   className={"users-search"}
                                   onChange={this.filterByCompany.bind(this)}
                            />
                        </div>
                    </div>
                    <ReactTable
                        data={data}
                        columns={this.state.columns}
                        defaultPageSize={10}
                        minRows={0}
                    />
                </div>
            </div>
        )
    }
}