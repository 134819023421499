import React from "react";
import GroupImage from "components/groups/GroupImage";
import GroupName from "components/groups/GroupName";

const GroupsItem = ({
    group,
    chooseGroup,
    selectedGroups
}) => {
    return (
        <div key={group.group_id}
            className={"group " + (selectedGroups.includes(group.group_id) ? "group--selected" : "")}
            onClick={() => chooseGroup(group.group_id)}>
                {group.group_image && <GroupImage image={group.group_image} />}
                <GroupName name={group.group}/>
        </div>
    );
}

export default GroupsItem;