import React, { Fragment } from 'react';
import "./TakeScreenshot.css";
import html2canvas from 'html2canvas';

function TakeScreenshot() {

    function takeScreenshot() {
        html2canvas(document.body, {
            useCORS: false,
            allowTaint: true
        }).then(function(canvas) {
            document.body.appendChild(canvas);
        });
    }

    return (
        <Fragment>
            <button onClick={() => takeScreenshot()}>screenshot</button>
        </Fragment>
    )
}

export default TakeScreenshot