import React, { Component } from "react";
// import "admin/administrator/BestSellers.css";
import axios from "axios1";
import _ from 'lodash';
import ReactTable from "react-table";

export default class BestSellers extends Component
{
    constructor(props){
        super(props);

        this.state = {
            form: {
                account_id: null,
                month: null,
                group_id: null,
                section_id: null
            },
            accounts:[],
            groups: [],
            months: [],
            sections: [],
            bestSelling: [],
            columns: [
                { 
                    Header: '#',
                    accessor: 'id',
                    width: 25,
                    style: {
                        textAlign: "center" 
                    },
                },
                {
                    Header: "Parn No",
                    accessor: "partNumber",
                    style: {
                        textAlign: "center",
                    },
                },
                {
                    Header: 'Description',
                    accessor: 'section',
                    Cell: row => (
                        <div>
                            {
                                <div className={"best-sallers-cell-desc"}><i className={row.row.icon}></i>{row.row.section}</div>
                            }
                        </div>
                    ),
                    style: {
                        textAlign: "center"
                    },
                },
                {
                    Header: "QTY Sold",
                    accessor: "sold",
                    style: {
                        textAlign: "center",
                    },
                },
                {
                    Header: "Stock",
                    accessor: "stock",
                    style: {
                        textAlign: "center",
                    },
                },
                {
                    Header: "Minutes Out Of Stock",
                    accessor: "minutest_out_of_stock",
                    style: {
                        textAlign: "center",
                    },
                },
            ],
        }

        

        this.getBestSellers();
        this.getBestSellingFiltres();
    }

    getBestSellers() {
        axios.get("catalogue/bestselling",this.state.form)
            .then((response) => {
                if(response.data.success){
                    this.setState({bestSelling: response.data.bestSelling});
                }else{
                    this.setState({bestSelling:[]});
                }
            });
    }

    getBestSellingFiltres() {
        axios.get("catalogue/bestselling/filters")
            .then((response) => {
                if(response.data.success){
                    this.setState({
                        accounts: response.data.accounts ,
                        months: response.data.months,
                        groups: response.data.groups,
                    });
                }else{
                    this.setState({bestSelling:[]});
                }
            });
    }
    
    handleGroupChange(event) {
        let form = { ...this.state.form };
        let sections = [];
        form['group_id'] = event.target.value;
        form['section_id'] = null;
        let groups = {...this.state.groups};
        if(parseInt(event.target.value)){
            let curretntGroup = _.find(groups,{'id': parseInt(event.target.value)});
            sections = curretntGroup['sections'] ? curretntGroup['sections'] : [];
        }

        this.setState( { form: form , sections: sections },() => {
            this.filtreBestSellers();
        });
    }

    handleValueChange(property,event){
        let form = { ...this.state.form };
        form[property] = event.target.value;
        this.setState( { form: form },() => {
            this.filtreBestSellers();
        });
    }
    
    filtreBestSellers() {
        let params = this.state.form;
        axios.get("/catalogue/bestselling",{params})    
        .then((response) => {
            if(response.data.success) {
                let bestSelling = response.data.bestSelling;
                this.setState({ bestSelling:bestSelling });
            }
        });
    }


    render()
    {
        let data = [];
        if(this.state.bestSelling) {
            data = {...this.state.bestSelling}
            data = _.map(data,function(element,key){
                key = parseInt(key) + 1;
                return _.extend({}, element, {id: key});
            })
        }
        return (
            <div className={"dashboard-bestsellers-wrapper"}>
                <div className={"bestsellers-header"}>
                    <div className={"AcitivityTrendsHeader-label"}>Best Sellers</div>
                    <div className={"bestsellers-header-filtress-wrapper"}>
                        <div className={"bestsellers-select-container"}>
                            <label>Account</label>
                            <select className="bestsellers-select" value = {this.state.form.account_id} onChange={this.handleValueChange.bind(this,"account_id")}>
                                <option value="">All Accounts</option>
                                {
                                    this.state.accounts.map((account) => {
                                        return <option key={account.id} value={account.id}>{account.name}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className={"bestsellers-select-container"}>
                            <label>Month</label>
                            <select className={"bestsellers-select"} value = {this.state.form.month} onChange={this.handleValueChange.bind(this,"month")}>
                                <option value="">Select Month</option>
                                {
                                    this.state.months.map((month) => {
                                        return <option key={month.code} value={month.code}>{month.date}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className={"bestsellers-select-container"}>
                            <label>Product Group</label>
                            <select className={"bestsellers-select"} value = {this.state.form.group_id} onChange={this.handleGroupChange.bind(this)}>
                                <option value="">All Groups</option>
                                {
                                    this.state.groups.map((group) => {
                                        return <option key={group.id} value={group.id}>{group.name}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className={"bestsellers-select-container"}>
                            <label>Product Section</label>
                            <select className={"bestsellers-select"} value = {this.state.form.section_id} onChange={this.handleValueChange.bind(this,'section_id')}>
                                <option value="">All Sections</option>
                                {
                                    this.state.sections.map((section) => {
                                        return <option key={section.id} value={section.id}>{section.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <div className={"bestsellers-react-table-wrappers"}>
                <ReactTable 
                        data={data}
                        columns={this.state.columns}
                        defaultPageSize={10}
                        showPagination={false}
                        minRows={0}
                    />
                </div>
            </div>
        ); 
    }
    
}