import React, { Component } from "react";
// import "admin/administrator/GeneralClientOrBranchStatistics/index.css";
import _ from "lodash";

export default class BranchAccounts extends Component
{
    constructor(props)
    {
        super(props);
    }

    render()
    {
        return (
            <div className="statisticsPart row">
                <i className={'icon-branch statisticsIcons col-sm-1'}></i>
                <p className="col">Branch accounts</p>
                <p className="col font-weight-bold">{this.props.branchAccounts}</p> 
            </div>
        );
    }
}