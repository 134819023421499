import React, {Component, Fragment} from "react";
import _ from "lodash";
import axios from "axios1";
import {GridLoader} from 'react-spinners';
import alertify from "alertifyjs";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {updateOutstandingOrdersQty} from "redux/actions/shoppingCart";
import CustomCheckBox from "admin/administrator/CustomCheckBox";

class OrdersAdminActionTable extends Component {

    constructor(props) {
        super(props);
    }

    handleIsCompletedChecked(order_id, is_completed) {
        let isCompleted = (is_completed == 1) ? 0 : 1;
        let orders = [...this.props.orders];
        _.find(orders, {order_id: order_id}).is_completed = isCompleted;
        this.props.setStateFromChild("data", orders);
        axios.put("catalogue/account/orders/status/" + order_id + '/' + isCompleted)
            .then((response) => {
                if (response.data.success) {
                    this.props.dispatch(updateOutstandingOrdersQty(response.data.count));
                    alertify.success(response.data.msg);
                } else {
                    alertify.error(response.data.msg);
                }
            });
    }

    render() {
        let orders = this.props.orders;
        return (
            <Fragment>
                <table className={"custom-table table"}>
                    <thead>

                    <tr>
                        <th>Completed</th>
                    </tr>

                    </thead>
                    <tbody>
                    {
                        orders.map((order) => {
                            return <tr>
                                <td style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <CustomCheckBox
                                        name={order.order_id}
                                        checked={order.is_completed}
                                        value={order.is_completed}
                                        onChange={this.handleIsCompletedChecked.bind(this, order.order_id, order.is_completed)}
                                    />
                                </td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
            </Fragment>
        )
    }
}

export default connect(
    null,
    null
)(OrdersAdminActionTable);