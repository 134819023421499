import React from "react";
import axios from "axios1";
import alertify from 'alertifyjs';
import Loading from "admin/administrator/LoadingComponent/Loading.component";
import { Fragment } from "react";

export default function RequestCallBtn( { product } ){ 
    const [ loading, setLoading ] = React.useState( false );

    function handleCallRequestClick(  ){ 
        setLoading( true );
        axios.post( "catalogue/requestCall", { part_number: product.part_number.part_number } )
            .then( ( response ) => { 
                setLoading( false );
                if( response.data.status == "success" ) { 
                    alertify.success( "Call request successfully sent. We will contact you shortly." )
                } else { 
                    alertify.error("Something went wrong. Call request wasn't sent.");
                }    
            } )
    }

    return (
        <Fragment>
            <Loading loading={loading} />
            <div className="call-btn ml-8" onClick={ handleCallRequestClick } title="Request a callback">
                <i class="fa fa-phone" aria-hidden="true"></i>
            </div>
        </Fragment>
    )
}
