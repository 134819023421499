import React, {Component, Fragment} from 'react';
import axios from 'axios1';
import CustomButton from "admin/administrator/CustomButton";
import {GridLoader} from 'react-spinners';
import PriceLevelMainContainer from './PriceLevelMainContainer';
import ChangeSaveOrDiscardModal from '../ChangeSaveOrDiscardModal/ChangeSaveOrDiscardModal';
import Notify from "components/Notify";
import Modal from "components/Modal";
import { withTranslation } from 'react-i18next';
import i18n, { t } from 'i18next';

export default withTranslation() (class PriceLevelForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            loading: false,
            modalShow: false,
            changesSaved: true,
            showNotSavedModal: false,
            levels: []
        };
        this.child = React.createRef()
    }

    closeModal() {
        if (!this.state.changesSaved) {
            this.setState({showNotSavedModal: true});
        } else {
            this.setState({modalShow: false});
        }
    }

    componentDidMount() {
        this.getPriceLevels();
    }

    MustcloseModal() {
        this.setState({modalShow: false, changesSaved: true});
    }

    closeChangesModal() {
        this.setState({showNotSavedModal: false});
    }

    closeChangesModalDisregard() {
        this.setState({showNotSavedModal: false, changesSaved: true, modalShow: false});
    }

    setStateFromChild(key, value) {
        let state = {};
        state[key] = value;
        this.setState(state);
    }


    showModal() {
        this.setState({modalShow: true});
    }

    savePriceChanges() {
        // this.child.current.handleSaveClick();
        this.closeChangesModalDisregard();
    }

    getPriceLevels() {
        axios.get("catalogue/price-levels")
            .then((response) => {
                this.setState({levels: response.data});
            })
    }

    render() {
        return (
            <Fragment>
                <Notify type={"danger"}
                        visible={this.state.showNotSavedModal}
                        backdrop={false}
                        close={this.closeChangesModal.bind(this)}
                        primaryText={t("You have made changes to this screen but not saved them. Are you sure you wish to leave without saving") + "?"}
                        secondaryText={t("What would you like to do")}
                        actions={[
                            {
                                name: t("no"),
                                action: this.closeChangesModal.bind(this),
                                type: "lightgray"
                            },
                            {
                                name: t("yes"),
                                action: this.closeChangesModalDisregard.bind(this, false),
                                type: "blue"
                            },
                        ]}/>
                <CustomButton title={t("Manage")} type={"blue"} width={"91px"} onClick={this.showModal.bind(this)}/>
                <Modal size="xl" title={t("Set Price Levels")} type="light" active={this.state.modalShow} closeModal={this.closeModal.bind(this)} scrollable={true}>
                    <PriceLevelMainContainer ref={this.child}
                                            getPriceLevels={this.getPriceLevels.bind(this)}
                                            setStateFromChild={this.setStateFromChild.bind(this)}
                                            closeModal={this.MustcloseModal.bind(this)}/>
                </Modal>
            </Fragment>
        );
    }
});