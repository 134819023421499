import React, {Component} from "react";
import {Chart} from 'react-google-charts';
import "components/Charts/CustomPieChart.css";
import PropTypes from "prop-types";

class CustomPieChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            width:"100%",
            height:"100%",
        }
    }

    render() {
        return ( 
            <Chart
                width={'100%'}
                height={'500px'}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={this.props.data}
                options={{
                    title: '',
                    is3D: true,
                    width: this.props.width || this.state.width,
                    height: this.props.height || this.state.height,
                    slices: [
                        {
                            color:"#D9E2F3"
                        },
                        {
                            color:"#FAFBFF"
                        },
                        {
                            color:"#D4DDEE"
                        },
                        {
                            color:"#CED7E8"
                        },
                        {
                            color:"#B9C2D3"
                        },
                        {
                            color:"#B9C2D3"
                        },
                        {
                            color:"#BCC5D6"
                        },
                        {
                            color:"#D9E2F3"
                        },
                        {
                            color:"#9EA7B8"
                        },
                        {
                            color:"#666F80"
                        }
                     ],
                     pieSliceTextStyle: {
                         color: 'black',
                       },
                }}
            />
        );
    }
}

CustomPieChart.propTypes = {
    data: PropTypes.array,
    width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
}

export default CustomPieChart;