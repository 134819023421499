import axios from "axios1";
var moment = require('moment');

export const addProduct = (stockProduct, quantity, section_lookup_id) => (
    (dispatch) => {
        stockProduct.section_lookup_id = parseInt( section_lookup_id );
        stockProduct.quantity =  parseInt( quantity );
        stockProduct.created_at = moment().format('YY-MM-DD hh:mm:ss');
        return axios.put("/catalogue/add-to-cart", stockProduct)
            .then((response) => {
                dispatch({ type: 'ADD_PRODUCT', product: stockProduct, data: response.data });
                return response;
            })
    }
)

export const increaseQuantity = (stockProduct, quantity) => {
    return function(dispatch) {
        stockProduct.quantity =  parseInt( quantity );
        axios.put("/catalogue/increase-quantity", stockProduct).then((response) => {
            dispatch({ type: 'INCREASE_QUANTITY', product: stockProduct, data: response.data });
        })
    }
}

export const decreaseQuantity = (stockProduct, quantity) => {
    return function(dispatch) {
        stockProduct.quantity =  parseInt( quantity );
        axios.put("/catalogue/decrease-quantity", stockProduct).then((response) => {
            dispatch({ type: 'DECREASE_QUANTITY', product: stockProduct, data: response.data });
        })
    }
}

export const removeProduct = (product) => {
    return function(dispatch) {
        axios.put("/catalogue/remove-from-cart/" + product.id ).then((response) => {
            dispatch({ type: "REMOVE_PRODUCT", product: product })
        })
    }
}

export const cleanCart = (product) => {
    return function(dispatch){
        axios.delete("catalogue/clean-cart").then(() => {
            dispatch({ type: "CLEAN_CART" })
        })
    }
}

export const updateOutstandingOrdersQty = (qty, account) => {
    return function(dispatch){
        dispatch({ type: "UPDATE_OUTSTANDING_ORDERS_QTY", payload: qty, account: account })
    }
}
