import React, { Component } from "react";
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from "react-redux";
import axios from 'axios1';
import 'components/Registration/RegistrationForm.css';
import successIcon from "images/success-png.png" ;
import _ from 'lodash';

var store = require('store');

class RegistrationForm extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            form: {
                name: '',
                business_type_id: null,
                branch_id: null,
                name: '',
                contact_name: '',
                email: '',
                phone: '',
                address_1: '',
                address_2: '',
                town: '',
                county: '',
                postal_code: '',
            },
            styleFor: process.env.REACT_APP_CLIENT,
            buttonColor: '#F9A700',
            errors: [],
            businessTypes: [],
            accounts: [],
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    setStyles() {
        let buttonColor = "";
        switch(process.env.REACT_APP_CLIENT) {
            case 'nk' : 
                buttonColor = '#FC3000';
                break;
            case 'newNordic' : 
                buttonColor = 'black';
                break;
            case 'gt' : {
                buttonColor = '#FF271E';
                }
                break;
            case 'stoneacre' : {
                buttonColor = '#348e44';
                }
                break;
            case 'eurobrake' : {
                buttonColor = '#009dd8';
                }
                break;    
            case 'titan' : {
                buttonColor = 'black';
                }
                break;
            default: {
                buttonColor = '#F9A700';
            }
        }
        this.setState({ buttonColor: buttonColor });
    }

    componentDidMount() {
        this.getBusinessTypes();
        this.setStyles();
    }

    getAccountsByBusinessType = (id) => {
        axios.get('/catalogue/accounts-by-business-type/' + id).then(response => {
            this.setState({accounts: response.data.accounts})
        })
        .catch(function (error) {

        });
    }

    getBusinessTypes = () => {
        axios.get('/catalogue/getBusinessTypes').then(response => {
            let businessTypesWithoutSupplierAndBranch = response.data.filter((bt) => {
                return bt.id != 9 && bt.id != 3;
            });
            this.setState({businessTypes: businessTypesWithoutSupplierAndBranch})
        })
        .catch(function (error) {

        });
    }

    handleInputChange(event) {
        let form = this.state.form;
        form[event.target.name] = event.target.value;
        if(event.target.name == 'business_type_id') {
            this.getAccountsByBusinessType(form[event.target.name]);
        }

        this.setState({ form: form });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        axios.post('/register', this.state.form).then(response => {
            if(!response.data.token) {
                window.scrollTo(0, 0);
                this.setState({errors: response.data.errors})                
                if( response.data.errors && response.data.errors['approval'] ) {
                    var form = [];
                    _.map( this.state.form, (value, key) => {
                        form[key] = '';
                    });
            
                    this.setState({form: form});
                }
                
                return false;
            }
            const token = response.data.token;
            store.set("token", token);
            this.props.afterRegistration(token)
            this.props.history.push('/search');            
        })
        .catch(function (error) {

        })
    }

    render() {
        let accounts = this.state.accounts;
        let businessTypes = this.state.businessTypes;
        return (
            <div className="loginWrapper registerWrapper">
                {
                    this.props.allowRegistration ?
                        ( 
                            this.state.errors && !this.state.errors['approval'] ? 
                            <form className="login-form" onSubmit={this.handleSubmit}>
                                <div className={"login-form-header"}>
                                    Create Account
                                </div>
                                <div className={"login-form-body register-form-body"}>
                                    <div className={"multi-col"}>
                                        <div className="form-group">
                                            <input type="text"
                                                className={this.state.errors && this.state.errors["name"] ? "input-error" : null}
                                                value={this.state.form.name}
                                                name="name"
                                                placeholder={this.state.errors && this.state.errors["name"] ?  this.state.errors["name"][0]: "Name Of Company/Account*"}
                                                onChange={this.handleInputChange}
                                            />
                                            {
                                                this.state.errors && this.state.errors["name"] ? 
                                                    <span className="reg-error">{this.state.errors["name"][0]}</span> : null
                                            }
                                        </div>
                                        <div className="form-group">
                                            <select className={this.state.errors && this.state.errors["business_type_id"] ? "input-error" : null}
                                                    name="business_type_id"
                                                    onChange={this.handleInputChange}
                                            >
                                                <option value="">Select business type</option>
                                                {
                                                    businessTypes.map((item) => {
                                                        return <option key={item.id} value={item.id}>{item.name}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className={"multi-col"}>
                                        <div className="form-group">
                                            <input type="text"
                                                className={this.state.errors && this.state.errors["contact_name"] ? "input-error" : null}
                                                value={this.state.form.contact_name}
                                                name="contact_name"
                                                placeholder={this.state.errors && this.state.errors["contact_name"] ? "Full name is required*" : "Full Name*"}
                                                onChange={this.handleInputChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input type="text"
                                                className={this.state.errors && this.state.errors["email"] ? "input-error" : null}
                                                value={this.state.form.email}
                                                name="email"
                                                placeholder={this.state.errors && this.state.errors["email"] ? "Email address is required*" : "Email address (the username)*"}
                                                onChange={this.handleInputChange}
                                            />
                                            {
                                                this.state.errors && this.state.errors["email"] ? 
                                                    <span className="reg-error">{this.state.errors["email"][0]}</span> : null
                                            }
                                        </div>
                                    </div>
                                    
                                    <div className={"multi-col"}>
                                        <div className="form-group">
                                            <input type="text"
                                                className={this.state.errors && this.state.errors["phone"] ? "input-error" : null}
                                                value={this.state.form.phone}
                                                name="phone"
                                                placeholder={this.state.errors && this.state.errors["phone"] ? "Contact no is required*" : "Contact no*"}
                                                onChange={this.handleInputChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input type="text"
                                                className={this.state.errors && this.state.errors["address_1"] ? "input-error" : null}
                                                value={this.state.form.address_1}
                                                name="address_1"
                                                placeholder={this.state.errors && this.state.errors["address_1"] ? "Address line 1 is required*" : "Address line 1*"}
                                                onChange={this.handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className={"multi-col"}>
                                        <div className="form-group">
                                            <input type="text"
                                                className="form-control"
                                                value={this.state.form.address_2}
                                                name="address_2"
                                                placeholder="Address Line 2"
                                                onChange={this.handleInputChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input type="text"
                                                className={this.state.errors && this.state.errors["town"] ? "input-error" : null}
                                                value={this.state.form.town}
                                                name="town"
                                                placeholder={this.state.errors && this.state.errors["town"] ? "Town is required*" : "Town*"}
                                                onChange={this.handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className={"multi-col"}>
                                        <div className="form-group">
                                            <input type="text"
                                                className={this.state.errors && this.state.errors["county"] ? "input-error" : null}
                                                value={this.state.form.county}
                                                name="county"
                                                placeholder={"County"}
                                                onChange={this.handleInputChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input type="text"
                                                className={this.state.errors && this.state.errors["postal_code"] ? "input-error" : null}
                                                value={this.state.form.postal_code}
                                                name="postal_code"
                                                placeholder={"Post Code/ZIP"}
                                                onChange={this.handleInputChange}
                                            />
                                        </div>
                                    </div>                               
                                    {
                                        this.state.errors && this.state.errors['name'] ?
                                            <span className={"registration-error"}>*Required</span>
                                            : null
                                    }
                                </div>
                                <div className="form-footer">
                                    <button type="submit" style={{
                                        background: this.state.buttonColor
                                    }}>Sign Up</button>
                                </div>
                            </form> : 
                            <div className="registration-on-success" >
                                <img src={ successIcon } alt="check" />
                                <h3>Your catalogue application has been submitted successfully!</h3>
                                <p>{ this.state.errors["approval"] }</p>
                            </div>
                        ) :
                        <div className={"allow-reg-warning"}>
                            <p>Registration from website is currently disabled</p>
                            <p>Please contact administration to get login credentials</p>
                        </div>
                }
            </div>
        )
    }

}

const mapDispatchToProps = dispatch => {
    return {
        afterRegistration: (token) => dispatch({ type: 'AFTER_REGISTRATION', payload: token }),
    }
}

export default withRouter(connect(
    null,
    mapDispatchToProps
)(RegistrationForm));