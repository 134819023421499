import React, {Component} from "react";
import LoginForm from 'components/Login/LoginForm';
import {connect} from "react-redux";
import RegistrationForm from 'components/Registration/RegistrationForm';
import $ from "jquery";
import 'components/Login.css';
import {withRouter} from "react-router-dom";
import LogoObject from "components/clients/MainLogo.js";
import LoginSmallImage from "components/clients/LoginSmallImage.js";
import SlideVauxhall from "images/vauxhall.jpg";
import SlideCitroen from "images/Citroen.jpg";
import SlideDaciaDuster from "images/Dacia-Duster.jpg";
import SlideDS from "images/DS.jpg";
import SlideFordGT from "images/Ford_GT.jpg";
import SlideHyundai from "images/Hyundai-RN30.jpg";
import SlideKia from "images/Kia.jpg";
import SlideNissan from "images/Nissan-Ariya.jpg";
import LogoVauxhall from "images/Vauxhall-Motors-logo.png";
import LogoCitroen from "images/Citroen-Logo.png";
import LogoDaciaDuster from "images/Dacia-Logo.png";
import LogoDS from "images/DS-automobiles-Logo.png";
import LogoFordGT from "images/Ford-Logo.png";
import LogoHyundai from "images/Hyundai-logo.png";
import LogoKia from "images/Kia-logo.png";
import LogoNissan from "images/Nissan-Logo.png";
import Slider from "react-slick";

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            styleFor: process.env.REACT_APP_CLIENT,
            showLoginForm: true,
            showLoginCaption: true,
            showRegForm: false,
            showRegCaption: false,
            allowRegistration: false,
            showNda: false,
            isChecked: false,
            versionStyles: {
                color: '#F9A700',
                background: '#F9A700'
            }
        }
    }

    setStyles() {
        let color = "";
        let background = ""
        switch(process.env.REACT_APP_CLIENT) {
            case 'nk' : {
                    background = '#FC3000';
                    color = '#FC3000';
                }
                break;
            case 'newNordic' : {
                    background = 'black';
                }
                break;
            case 'gt' : {
                    background = '#FF271E';
                    color = '#fff';
                }
            break;
            case 'stoneacre' : {
                background = '#348e44';
                color = '#fff';
            }
            break;
            case 'eurobrake' : {
                background = '#009dd8';
                color = '#fff';
            }
            break;
            case 'titan' : {
                background = 'black';
                color = '#fff';
            }
            break;
            default: {
                background = '#F9A700';
                color = '#F9A700';
            }
        }
        this.setState({ versionStyles: {background: background, color: color } });
    }

    componentDidMount() {
        this.setStyles();
        document.body.addEventListener('click', (e) => {
            if (document.contains(document.getElementById('nda-wrapper')) && document.getElementById('nda-wrapper').contains(e.target)){
                return;
            } else{
                this.setState({showNda: false});
            }
        });
    }

    showNda() {
        this.setState({showNda: true});
    }

    closeNda() {
        this.setState({showNda: false});
    }

    openRegisterForm() {
        $('.bg').fadeOut();
        $('.auth-wrapper .small').css({
            'right': 'calc(100% / 4 - 1.5%)',
            'width': 'calc(100% * 3 / 4 + 1.5%)'
        });

        $('.auth-wrapper .small').one('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', () => {
            $('.auth-wrapper .small').css({
                'right': 'calc(100% * 3 / 4 + 1.5%)',
                'width': 'calc(100% / 4 - 1.5%)'
            });
            $('.auth-wrapper .big').css({
                'left': 'calc(100% / 4 - 1.5%)',
                'width': 'calc(100% * 3 / 4 + 1.5%)'
            }).one('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', () => {
                $('.bg-part-1, .bg-part-4, .bg-part-5').fadeIn();
                this.setState({showLoginCaption: false, showRegCaption: true});
            });
            this.setState({showLoginForm: false, showRegForm: true});
        });
    }

    openLoginForm() {
        $('.bg').fadeOut();
        $('.auth-wrapper .small').css({
            'right': '0px',
            'width': 'calc(100% * 3 / 4 + 1.5%)',
        });

        $('.auth-wrapper .small').one('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', () => {
            $('.auth-wrapper .small').css({
                'right': '0px',
                'width': 'calc(100% / 4 - 1.5%)',
            });
            $('.auth-wrapper .big').css({
                'left': '0px',
                'width': 'calc(100% * 3 / 4 + 1.5%)'
            }).one('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', () => {
                $('.bg-part-2, .bg-part-3, .bg-part-6, .bg-part-7').fadeIn();
                this.setState({showLoginCaption: true, showRegCaption: false});
            });
            this.setState({showLoginForm: true, showRegForm: false});
        });
    }

    render() {
        let allowRegistration = this.props.settings.registration_from_site;
        const settings = {
            dots: false,
            arrows: false,
            infinite: true,
            speed: 2000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            fade: true
        };
        const images = [
            {
                logo: LogoVauxhall,
                slide: SlideVauxhall
            },
            {
                logo: LogoCitroen,
                slide: SlideCitroen
            },
            {
                logo: LogoDaciaDuster,
                slide: SlideDaciaDuster
            },
            {
                logo: LogoDS,
                slide: SlideDS
            },
            {
                logo: LogoFordGT,
                slide: SlideFordGT
            },
            {
                logo: LogoHyundai,
                slide: SlideHyundai
            },
            {
                logo: LogoKia,
                slide: SlideKia
            },
            {
                logo: LogoNissan,
                slide: SlideNissan
            }
        ];
        return (
            <div className={"auth-wrapper"}>
                {
                    this.state.showNda ?
                        <div id={"nda-wrapper"} className={this.state.styleFor == 'nk' ? 'nda-popup nk' : 'nda-popup default'}>
                            <i onClick={this.closeNda.bind(this)} className="far fa-times-circle"></i>
                            <div className="WordSection1">
                                <p style={{
                                    margin: '0in 6.75pt 0.0001pt 0in',
                                    lineHeight: '95%',
                                    fontSize: '11pt',
                                }}>The date of<strong> THIS&nbsp; AGREEMENT </strong>is the time and date that
                                    the <strong>THE USER </strong>accepts this agreement by selecting the NDA tick-box
                                    during the logon process for the Beta Testing Version 1.0.0. <strong>THE
                                        USER’s </strong>ISP will be recorded and logged with the time and date to form
                                    this record.</p>
                                <p style={{
                                    margin: '0in 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><strong><span style={{fontSize: '12.0pt'}}>&nbsp;</span></strong></p>
                                <p style={{
                                    margin: '0.15pt 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><strong><span style={{fontSize: '17.5pt'}}>&nbsp;</span></strong></p>
                                <h1 style={{
                                    margin: '0in 0in 0.0001pt',
                                    textAlign: 'center',
                                    fontSize: '11pt',
                                }}><span style={{fontSize: '20.0pt'}}>CONFIDENTIALITY AGREEMENT (MUTUAL)</span></h1>
                                <p style={{
                                    margin: '0.5pt 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><strong><span style={{fontSize: '20.5pt'}}>&nbsp;</span></strong></p>
                                <p style={{
                                    margin: '0in 0in 0.0001pt 0.15pt',
                                    textAlign: 'center',
                                    fontSize: '11pt',
                                }} align="center">between</p>
                                <p style={{
                                    margin: '0.1pt 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><span style={{fontSize: '12.0pt'}}>&nbsp;</span></p>
                                <p style={{
                                    margin: '0in 0in 0.0001pt 0.1pt',
                                    textAlign: 'center',
                                    fontSize: '11pt',
                                }} align="center"><strong><span
                                    style={{fontSize: '14.0pt'}}>The Nordic Car Company Ltd</span></strong></p>
                                <p style={{
                                    margin: '0in 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><strong><span style={{fontSize: '12.0pt'}}>&nbsp;</span></strong></p>
                                <p style={{
                                    margin: '0in 0in 0.0001pt 0.05pt',
                                    textAlign: 'center',
                                    fontSize: '11pt',
                                }} align="center">And</p>
                                <p style={{
                                    margin: '0in 0in 0.0001pt 0.05pt',
                                    textAlign: 'center',
                                    fontSize: '11pt',
                                }} align="center">&nbsp;</p>
                                <p style={{
                                    margin: '0in 0in 0.0001pt 0.05pt',
                                    textAlign: 'center',
                                    fontSize: '11pt',
                                }} align="center"><strong><span style={{fontSize: '14.0pt'}}>The User</span></strong>
                                </p>
                                <p style={{
                                    margin: '0in 0in 0.0001pt 0.05pt',
                                    textAlign: 'center',
                                    fontSize: '11pt',
                                }} align="center"><strong><span style={{fontSize: '14.0pt'}}>&nbsp;</span></strong></p>
                                <p style={{
                                    margin: '0in 0in 0.0001pt 0.05pt',
                                    textAlign: 'center',
                                    fontSize: '11pt',
                                }} align="center"><strong><span style={{fontSize: '14.0pt'}}>&nbsp;</span></strong></p>
                                <p style={{
                                    margin: '0in 0in 0.0001pt 0.05pt',
                                    textAlign: 'center',
                                    fontSize: '11pt',
                                }} align="center"><strong><span style={{fontSize: '14.0pt'}}>&nbsp;</span></strong></p>
                                <p style={{
                                    margin: '0in 0in 0.0001pt 0.05pt',
                                    textAlign: 'center',
                                    fontSize: '11pt',
                                }} align="center"><strong><span style={{fontSize: '14.0pt'}}>&nbsp;</span></strong></p>
                                <p style={{
                                    margin: '0in 0in 0.0001pt 0.05pt',
                                    textAlign: 'center',
                                    fontSize: '11pt',
                                }} align="center"><strong><span style={{fontSize: '14.0pt'}}>&nbsp;</span></strong></p>
                                <p style={{
                                    margin: '0in 0in 0.0001pt 0.05pt',
                                    textAlign: 'center',
                                    fontSize: '11pt',
                                }} align="center"><strong><span style={{fontSize: '14.0pt'}}>&nbsp;</span></strong></p>
                                <p style={{
                                    margin: '0in 0in 0.0001pt 0.05pt',
                                    textAlign: 'center',
                                    fontSize: '11pt',
                                }} align="center"><strong><span style={{fontSize: '14.0pt'}}>&nbsp;</span></strong></p>
                                <p style={{
                                    margin: '0in 0in 0.95pt 7pt',
                                    lineHeight: '14.95pt',
                                    fontSize: '11pt',
                                }}><strong>C</strong><strong><span style={{fontSize: '9.0pt'}}>ONTENTS</span></strong>
                                </p>
                                <p style={{
                                    margin: '0in 0in 0.0001pt 5.3pt',
                                    lineHeight: '1pt',
                                    fontSize: '11pt',
                                }}></p>
                                <p style={{
                                    margin: '0in 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><strong><span style={{fontSize: '3.0pt'}}>&nbsp;</span></strong></p>
                                <p style={{
                                    margin: '0.35pt 0in 0.0001pt 7pt',
                                    lineHeight: '15.45pt',
                                    fontSize: '11pt',
                                }}><strong>C</strong><strong><span style={{fontSize: '9.0pt'}}>LAUSE</span></strong></p>
                                <ol style={{marginBottom: '0in', marginTop: '0px'}}>
                                    <li style={{
                                        textAlign: 'left',
                                        lineHeight: '15pt',
                                        margin: '0in 0in 0.0001pt 0px',
                                        fontSize: '11pt',
                                        textIndent: '23.2px'
                                    }}>Definitions
                                        and
                                        interpretation.....................................................................
                                        1
                                    </li>
                                    <li style={{
                                        textAlign: 'left',
                                        lineHeight: '15pt',
                                        margin: '0in 0in 0.0001pt 0px',
                                        fontSize: '11pt',
                                        textIndent: '23.2px'
                                    }}>Obligations
                                        of
                                        confidentiality.......................................................................
                                        3
                                    </li>
                                    <li style={{
                                        textAlign: 'left',
                                        lineHeight: '15pt',
                                        margin: '0in 0in 0.0001pt 0px',
                                        fontSize: '11pt',
                                        textIndent: '23.2px'
                                    }}>Return
                                        of
                                        information........................................................................................
                                        5
                                    </li>
                                    <li style={{
                                        textAlign: 'left',
                                        lineHeight: '15pt',
                                        margin: '0in 0in 0.0001pt 0px',
                                        fontSize: '11pt',
                                        textIndent: '23.2px'
                                    }}>Reservation
                                        of rights and acknowledgement.................................. 6
                                    </li>
                                    <li style={{
                                        textAlign: 'left',
                                        lineHeight: '15pt',
                                        margin: '0in 0in 0.0001pt 0px',
                                        fontSize: '11pt',
                                        textIndent: '23.2px'
                                    }}>Warranty
                                        and
                                        indemnity..................................................................................
                                        6
                                    </li>
                                    <li style={{
                                        textAlign: 'left',
                                        lineHeight: '15pt',
                                        margin: '0in 0in 0.0001pt 0px',
                                        fontSize: '11pt',
                                        textIndent: '23.2px'
                                    }}>Term
                                        and
                                        termination........................................................................................
                                        6
                                    </li>
                                    <li style={{
                                        textAlign: 'left',
                                        lineHeight: '15pt',
                                        margin: '0in 0in 0.0001pt 0px',
                                        fontSize: '11pt',
                                        textIndent: '23.2px'
                                    }}>Entire
                                        agreement and
                                        variation.................................................................. 7
                                    </li>
                                    <li style={{
                                        textAlign: 'left',
                                        lineHeight: '15pt',
                                        margin: '0in 0in 0.0001pt 0px',
                                        fontSize: '11pt',
                                        textIndent: '23.2px'
                                    }}>No
                                        waiver.....................................................................................................................
                                        7
                                    </li>
                                    <li style={{
                                        textAlign: 'left',
                                        lineHeight: '15pt',
                                        margin: '0in 0in 0.0001pt 0px',
                                        fontSize: '11pt',
                                        textIndent: '23.2px'
                                    }}>Assignment................................................................................................................
                                        7
                                    </li>
                                    <li style={{
                                        textAlign: 'left',
                                        lineHeight: '15pt',
                                        margin: '0in 0in 0.0001pt 0px',
                                        fontSize: '11pt',
                                        textIndent: '23.2px'
                                    }}>Notices...........................................................................................................................
                                        7
                                    </li>
                                    <li style={{
                                        textAlign: 'left',
                                        lineHeight: '15pt',
                                        margin: '0in 0in 0.0001pt 0px',
                                        fontSize: '11pt',
                                        textIndent: '23.2px'
                                    }}>No
                                        partnership.........................................................................................................
                                        8
                                    </li>
                                    <li style={{
                                        textAlign: 'left',
                                        lineHeight: '15pt',
                                        margin: '0in 0in 0.0001pt 0px',
                                        fontSize: '11pt',
                                        textIndent: '23.2px'
                                    }}>Third
                                        party
                                        rights....................................................................................................
                                        8
                                    </li>
                                    <li style={{
                                        textAlign: 'left',
                                        lineHeight: '15.45pt',
                                        margin: '0in 0in 0.0001pt 0px',
                                        fontSize: '11pt',
                                        textIndent: '23.2px'
                                    }}>Governing
                                        law and
                                        jurisdiction.....................................................................
                                        8
                                    </li>
                                </ol>
                            </div>
                            <p style={{textAlign: 'center'}}><span
                                style={{fontSize: '11.0pt'}}>&nbsp;</span></p>
                            <div className="WordSection2">
                                <p style={{
                                    margin: '0in 6.75pt 0.0001pt 0in',
                                    lineHeight: '95%',
                                    fontSize: '11pt',
                                }}><a name="_Hlk18401821"/>The date of<strong> THIS&nbsp; AGREEMENT </strong>is the time
                                    and date that the <strong>THE USER </strong>accepts this agreement by selecting the
                                    NDA tick-box during the logon process for the Beta Testing Version 1.0.0. <strong>THE
                                        USER’s </strong>ISP, their unique logon and password and the time and date of
                                    logon will be recorded and logged to form this record.</p>
                                <p style={{
                                    margin: '0.05pt 0in 0.0001pt 7pt',
                                    fontSize: '11pt',
                                }}>&nbsp;</p>
                                <p style={{
                                    margin: '0.55pt 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><span style={{fontSize: '11.5pt'}}>&nbsp;</span></p>
                                <p style={{
                                    margin: '0.05pt 0in 0.0001pt 7pt',
                                    fontSize: '11pt',
                                }}><strong>P</strong><strong><span style={{fontSize: '9.0pt'}}>ARTIES</span></strong>
                                </p>
                                <p style={{
                                    margin: '0.25pt 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><strong><span style={{fontSize: '8.0pt'}}>&nbsp;</span></strong></p>
                                <ol style={{marginBottom: '0in', marginTop: '0px'}}>
                                    <li style={{
                                        margin: '0in 6.75pt 0.0001pt 0px',
                                        lineHeight: '95%',
                                        textAlign: 'justify',
                                        fontSize: '11pt',
                                        textIndent: '24px'
                                    }}><a name="_Hlk18401512"/><a name="_Hlk18400875"/><a name="_Hlk18401982"/><strong>The
                                        Nordic Car Company Ltd</strong><strong>. </strong>incorporated and registered in
                                        England and Wales with company number 03479951 whose registered office is at
                                        Egerton House, 68 Baker Street, Weybridge, Surrey, United Kingdom, KT13 8AL.
                                    </li>
                                </ol>
                                <p style={{
                                    margin: '0in 6.75pt 0.0001pt 43pt',
                                    textAlign: 'left',
                                    textIndent: '0in',
                                    lineHeight: '95%',
                                    fontSize: '11pt',
                                }} align="left">&nbsp;</p>
                                <ol style={{marginBottom: '0in', marginTop: '0px'}} start={2}>
                                    <li style={{
                                        margin: '0in 6.75pt 0.0001pt 0px',
                                        lineHeight: '95%',
                                        textAlign: 'justify',
                                        fontSize: '11pt',
                                        textIndent: '24px'
                                    }}><strong>‘THE USER’ </strong>who has read and accepted this agreement by selecting
                                        the NDA tick-box during the logon process for the Beta Testing Version 1.0.0.
                                    </li>
                                </ol>
                                <p style={{
                                    margin: '0in 6.6pt 0.0001pt 43pt',
                                    textAlign: 'left',
                                    textIndent: '0in',
                                    lineHeight: '102%',
                                    fontSize: '11pt',
                                }} align="left">&nbsp;</p>
                                <p style={{
                                    margin: '0.4pt 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><em><span style={{fontSize: '9.5pt'}}>&nbsp;</span></em></p>
                                <p style={{
                                    margin: '0in 0in 0.0001pt 7pt',
                                    fontSize: '11pt',
                                }}><strong>B</strong><strong><span style={{fontSize: '9.0pt'}}>ACKGROUND</span></strong>
                                </p>
                                <p style={{
                                    margin: '5.8pt 6.65pt 0.0001pt 7pt',
                                    textAlign: 'justify',
                                    lineHeight: '95%',
                                    fontSize: '11pt',
                                }}>Each party wishes to disclose to the other party Confidential Information in relation
                                    to the Purpose. Each party wishes to ensure that the other party maintains the
                                    confidentiality of its Confidential Information. In consideration of the benefits to
                                    the parties of the disclosure of the Confidential Information, the parties have
                                    agreed to comply with the following terms in connection with the use and disclosure
                                    of Confidential Information.</p>
                                <p style={{
                                    margin: '5.05pt 0in 0.0001pt 7pt',
                                    fontSize: '11pt',
                                }}><strong>A</strong><strong><span
                                    style={{fontSize: '9.0pt'}}>GREED TERMS</span></strong></p>
                                <p style={{
                                    margin: '0.2pt 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><strong><span style={{fontSize: '14.5pt'}}>&nbsp;</span></strong></p>
                                <ol style={{marginBottom: '0in', marginTop: '0px'}}>
                                    <li style={{
                                        textAlign: 'left',
                                        margin: '0in 0in 0.0001pt 0px',
                                        fontSize: '11pt',
                                        textIndent: '24px'
                                    }}><strong>D</strong><strong><span style={{fontSize: '9.0pt'}}>EFINITIONS AND INTERPRETATION</span></strong>
                                    </li>
                                </ol>
                                <p style={{
                                    margin: '0.15pt 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><strong><span style={{fontSize: '9.5pt'}}>&nbsp;</span></strong></p>
                                <ol style={{marginBottom: '0in', marginTop: '0px'}} start={2}>
                                    <li style={{
                                        listStyle: 'none',
                                        margin: '0in 6.85pt 0.0001pt 0px',
                                        textAlign: 'left',
                                        lineHeight: '95%',
                                        fontSize: '11pt',
                                        textIndent: '24px'
                                    }}>
                                        <ol style={{marginBottom: '0in', marginTop: '0px'}}>
                                            <li style={{
                                                margin: '0in 6.85pt 0.0001pt 0px',
                                                textAlign: 'left',
                                                lineHeight: '95%',
                                                fontSize: '11pt',
                                                textIndent: '24px'
                                            }}>The following definitions and rules of interpretation in this clause
                                                apply in this agreement:
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                                <p style={{
                                    margin: '5.9pt 6.75pt 0.0001pt 43pt',
                                    textAlign: 'justify',
                                    lineHeight: '95%',
                                    fontSize: '11pt',
                                }}><strong>Business Day: </strong>a day (other than a Saturday, Sunday or public
                                    holiday) when banks in London are open for business.</p>
                                <p style={{
                                    margin: '5.85pt 6.8pt 0.0001pt 43pt',
                                    textAlign: 'justify',
                                    lineHeight: '95%',
                                    fontSize: '11pt',
                                }}><strong>Confidential Information: </strong>all confidential information (however
                                    recorded, preserved or disclosed) disclosed by a party or its Representatives to the
                                    other party and that party's Representatives after the date of this agreement
                                    including but not limited to:</p>
                                <ol style={{marginTop: '5.8pt', marginBottom: '.0001pt'}} start={3}>
                                    <li style={{
                                        listStyle: 'none',
                                        margin: '5.8pt 6.75pt 0.0001pt 3.3997px',
                                        textAlign: 'left',
                                        lineHeight: '95%',
                                        fontSize: '11pt',
                                        textIndent: '13.9333px'
                                    }}>
                                        <ol style={{marginTop: '5.8pt', marginBottom: '.0001pt'}} start={2}>
                                            <li style={{
                                                listStyle: 'none',
                                                margin: '5.8pt 6.75pt 0.0001pt 0px',
                                                textAlign: 'left',
                                                lineHeight: '95%',
                                                fontSize: '11pt',
                                                textIndent: '13.9333px'
                                            }}>
                                                <ol style={{
                                                    listStyleType: 'lower-alpha',
                                                    marginTop: '5.8pt',
                                                    marginBottom: '.0001pt'
                                                }}>
                                                    <li style={{
                                                        margin: '5.8pt 6.75pt 0.0001pt 0px',
                                                        textAlign: 'left',
                                                        lineHeight: '95%',
                                                        fontSize: '11pt',
                                                        textIndent: '13.9333px'
                                                    }}>the fact that discussions and negotiations are taking place
                                                        concerning the Purpose and the status of those discussions and
                                                        negotiations;
                                                    </li>
                                                    <li style={{
                                                        margin: '5.25pt 0in 0.0001pt 0px',
                                                        textAlign: 'left',
                                                        fontSize: '11pt',
                                                        textIndent: '13.9333px'
                                                    }}>the existence and terms of this agreement;
                                                    </li>
                                                    <li style={{
                                                        margin: '5.8pt 6.85pt 0.0001pt 0px',
                                                        textAlign: 'left',
                                                        lineHeight: '95%',
                                                        fontSize: '11pt',
                                                        textIndent: '13.9333px'
                                                    }}>any information that would be regarded as confidential by a
                                                        reasonable business person relating to:
                                                        <ol style={{
                                                            listStyleType: 'lower-roman',
                                                            marginTop: '5.9pt',
                                                            marginBottom: '.0001pt'
                                                        }}>
                                                            <li style={{
                                                                margin: '5.9pt 6.75pt 0.0001pt 0px',
                                                                lineHeight: '95%',
                                                                textAlign: 'justify',
                                                                fontSize: '11pt',
                                                                textIndent: '13.8px'
                                                            }}>the business, affairs, customers, clients, suppliers,
                                                                plan, intentions, or market opportunities of the
                                                                Disclosing Party or of the Disclosing Party's Group; and
                                                            </li>
                                                            <li style={{
                                                                margin: '5.8pt 6.75pt 0.0001pt 0px',
                                                                lineHeight: '95%',
                                                                textAlign: 'justify',
                                                                fontSize: '11pt',
                                                                textIndent: '13.8px'
                                                            }}>the operations, processes, product information, know-how,
                                                                designs, trade secrets or software of the Disclosing
                                                                Party or of the Disclosing Party's Group;
                                                            </li>
                                                        </ol>
                                                    </li>
                                                    <li style={{
                                                        margin: '5.15pt 46.6pt 0.0001pt 0px',
                                                        textAlign: 'left',
                                                        lineHeight: '132%',
                                                        fontSize: '11pt',
                                                        textIndent: '14px'
                                                    }}>any information or analysis derived from Confidential
                                                        Information; but not including any information that:
                                                    </li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </div>
                            <p style={{textAlign: 'center'}}><span style={{
                                fontSize: '11.0pt',
                                lineHeight: '132%',
                            }}>&nbsp;</span></p>
                            <div className="WordSection3">
                                <ol style={{marginTop: '.7pt', marginBottom: '.0001pt'}}>
                                    <li style={{
                                        listStyle: 'none',
                                        margin: '0.7pt 6.7pt 0.0001pt 3.3997px',
                                        lineHeight: '95%',
                                        textAlign: 'justify',
                                        fontSize: '11pt',
                                        textIndent: '13.9333px'
                                    }}>
                                        <ol style={{marginTop: '.7pt', marginBottom: '.0001pt'}} start={3}>
                                            <li style={{
                                                listStyle: 'none',
                                                margin: '0.7pt 6.7pt 0.0001pt 0px',
                                                lineHeight: '95%',
                                                textAlign: 'justify',
                                                fontSize: '11pt',
                                                textIndent: '13.9333px'
                                            }}>
                                                <ol style={{
                                                    listStyleType: 'lower-alpha',
                                                    marginTop: '.7pt',
                                                    marginBottom: '.0001pt'
                                                }} start={5}>
                                                    <li style={{
                                                        margin: '0.7pt 6.7pt 0.0001pt 0px',
                                                        lineHeight: '95%',
                                                        textAlign: 'justify',
                                                        fontSize: '11pt',
                                                        textIndent: '13.9333px'
                                                    }}>is or becomes generally available to the public other than as a
                                                        result of its disclosure by the Recipient or its Representatives
                                                        in breach of this agreement or of any other undertaking of
                                                        confidentiality addressed to the party to whom the information
                                                        relates (except that any compilation of otherwise public
                                                        information in a form not publicly known shall nevertheless be
                                                        treated as Confidential Information); or
                                                    </li>
                                                    <li style={{
                                                        margin: '5.65pt 6.65pt 0.0001pt 0px',
                                                        lineHeight: '95%',
                                                        textAlign: 'justify',
                                                        fontSize: '11pt',
                                                        textIndent: '13.9333px'
                                                    }}>was available to the Recipient on a non-confidential basis prior
                                                        to disclosure by the Disclosing Party;
                                                    </li>
                                                    <li style={{
                                                        margin: '5.85pt 6.7pt 0.0001pt 0px',
                                                        lineHeight: '95%',
                                                        textAlign: 'justify',
                                                        fontSize: '11pt',
                                                        textIndent: '13.9333px'
                                                    }}>was, is or becomes available to the Recipient on a
                                                        non-confidential basis from a person who, to the Recipient's
                                                        knowledge, is not bound by a confidentiality agreement with the
                                                        Disclosing Party or otherwise prohibited from disclosing the
                                                        information to the Recipient; or
                                                    </li>
                                                    <li style={{
                                                        margin: '5.8pt 6.7pt 0.0001pt 0px',
                                                        lineHeight: '95%',
                                                        textAlign: 'justify',
                                                        fontSize: '11pt',
                                                        textIndent: '13.9333px'
                                                    }}>was lawfully in the possession of the Recipient before the
                                                        information was disclosed to it by the Disclosing Party; or
                                                    </li>
                                                    <li style={{
                                                        margin: '5.2pt 0in 0.0001pt 0px',
                                                        textAlign: 'left',
                                                        fontSize: '11pt',
                                                        textIndent: '13.9333px'
                                                    }}>the parties agree in writing is not confidential or may be
                                                        disclosed; or
                                                    </li>
                                                    <li style={{
                                                        margin: '5.8pt 6.9pt 0.0001pt 0px',
                                                        lineHeight: '95%',
                                                        textAlign: 'justify',
                                                        fontSize: '11pt',
                                                        textIndent: '13.9333px'
                                                    }}>is developed by or for the Recipient independently of the
                                                        information disclosed by the Disclosing Party; or
                                                    </li>
                                                    <li style={{
                                                        margin: '5.25pt 0in 0.0001pt 0px',
                                                        textAlign: 'left',
                                                        fontSize: '11pt',
                                                        textIndent: '13.9333px'
                                                    }}>is trivial, obvious or useless.
                                                    </li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                                <p style={{
                                    margin: '5.75pt 6.8pt 0.0001pt 43pt',
                                    textAlign: 'justify',
                                    lineHeight: '95%',
                                    fontSize: '11pt',
                                }}><strong>Disclosing Party: </strong>a party to this agreement which discloses or makes
                                    available directly or indirectly Confidential Information.</p>
                                <p style={{
                                    margin: '5.9pt 6.8pt 0.0001pt 43pt',
                                    textAlign: 'justify',
                                    lineHeight: '95%',
                                    fontSize: '11pt',
                                }}><strong>Group: </strong>in relation to a company, that company, each and any
                                    subsidiary or holding company from time to time OR at the date of this agreement of
                                    that company, and each and any subsidiary from time to time OR at the date of this
                                    agreement of a holding company of that company.</p>
                                <p style={{
                                    margin: '5.75pt 6.9pt 0.0001pt 43pt',
                                    textAlign: 'justify',
                                    lineHeight: '95%',
                                    fontSize: '11pt',
                                }}><strong>Holding company and subsidiary: </strong>mean a "holding company" and
                                    "subsidiary" as defined in section 1159 of the Companies Act 2006</p>
                                <h1 style={{
                                    margin: '5.9pt 6.7pt 0.0001pt 43pt',
                                    textAlign: 'justify',
                                    lineHeight: '95%',
                                    fontSize: '11pt',
                                }}>Purpose: “The parties intend to initiate discussions related to exploring a
                                    partnership about purchase of <a name="_Hlk18402015"/>The Nordic Car Company Ltd
                                    Technical Information and Systems provision. During the discussions the parties may
                                    furnish each other with Confidential Information related to our parts identification
                                    solutions. The Nordic &nbsp;Car &nbsp;Company &nbsp;&nbsp;&nbsp;&nbsp;Ltd may need
                                    to be able to compare the performance of data and solutions it provides with
                                    existing data and solutions deployed by the Recipient to measure resulting
                                    efficiencies achieved. Under no circumstances should the customer enable the The
                                    Nordic Car Company Ltd information to be: distributed to an individual or business,
                                    supplied externally or viewed on a “public facing” web site (not until a commercial
                                    agreement is in place).”</h1>
                                <p style={{
                                    margin: '0in 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><strong>&nbsp;</strong></p>
                                <p style={{
                                    margin: '0.5pt 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><strong><span style={{fontSize: '7.0pt'}}>&nbsp;</span></strong></p>
                                <p style={{
                                    margin: '0in 6.9pt 0.0001pt 43pt',
                                    textAlign: 'justify',
                                    lineHeight: '95%',
                                    fontSize: '11pt',
                                }}><strong>Recipient: </strong>a party to this agreement which receives or obtains
                                    directly or indirectly Confidential Information.</p>
                                <p style={{
                                    margin: '5.9pt 6.7pt 0.0001pt 43pt',
                                    textAlign: 'justify',
                                    lineHeight: '95%',
                                    fontSize: '11pt',
                                }}><strong>Representative: </strong>employees, agents, officers, advisers and other
                                    representatives of the Recipient.</p>
                            </div>
                            <p style={{textAlign: 'center'}}><span style={{
                                fontSize: '11.0pt',
                                lineHeight: '95%',
                            }}>&nbsp;</span></p>
                            <div className="WordSection4">
                                <ol style={{marginTop: '.7pt', marginBottom: '.0001pt'}} start={2}>
                                    <li style={{
                                        listStyle: 'none',
                                        margin: '0.7pt 6.85pt 0.0001pt 0px',
                                        lineHeight: '95%',
                                        textAlign: 'justify',
                                        fontSize: '11pt',
                                        textIndent: '24px'
                                    }}>
                                        <ol style={{marginTop: '.7pt', marginBottom: '.0001pt'}} start={4}>
                                            <li style={{
                                                margin: '0.7pt 6.85pt 0.0001pt 0px',
                                                lineHeight: '95%',
                                                textAlign: 'justify',
                                                fontSize: '11pt',
                                                textIndent: '24px'
                                            }}>Clause, schedule and paragraph headings shall not affect the
                                                interpretation of this agreement.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                                <p style={{
                                    margin: '0.45pt 0in 0.0001pt',
                                    fontSize: '11pt',

                                }}><span style={{fontSize: '13.5pt'}}>&nbsp;</span></p>
                                <ol style={{marginBottom: '0in', marginTop: '0px'}} start={3}>
                                    <li style={{
                                        listStyle: 'none',
                                        margin: '0in 6.75pt 0.0001pt 0px',
                                        lineHeight: '95%',
                                        textAlign: 'justify',
                                        fontSize: '11pt',
                                        textIndent: '24px'
                                    }}>
                                        <ol style={{marginBottom: '0in', marginTop: '0px'}} start={5}>
                                            <li style={{
                                                margin: '0in 6.75pt 0.0001pt 0px',
                                                lineHeight: '95%',
                                                textAlign: 'justify',
                                                fontSize: '11pt',
                                                textIndent: '24px'
                                            }}>A <strong>person </strong>includes a natural person, corporate or
                                                unincorporated body (whether or not having separate legal personality)
                                                and that person's legal and personal representatives, successors and
                                                permitted assigns.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                                <p style={{
                                    margin: '0.3pt 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><span style={{fontSize: '13.5pt'}}>&nbsp;</span></p>
                                <ol style={{marginBottom: '0in', marginTop: '0px'}} start={4}>
                                    <li style={{
                                        listStyle: 'none',
                                        margin: '0in 6.95pt 0.0001pt 0px',
                                        lineHeight: '95%',
                                        textAlign: 'justify',
                                        fontSize: '11pt',
                                        textIndent: '24px'
                                    }}>
                                        <ol style={{marginBottom: '0in', marginTop: '0px'}} start={6}>
                                            <li style={{
                                                margin: '0in 6.95pt 0.0001pt 0px',
                                                lineHeight: '95%',
                                                textAlign: 'justify',
                                                fontSize: '11pt',
                                                textIndent: '24px'
                                            }}>The schedules form part of this agreement and shall have effect as if set
                                                out in full in the body of this agreement. Any reference to this
                                                agreement includes the schedules.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                                <p style={{
                                    margin: '0.45pt 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><span style={{fontSize: '13.5pt'}}>&nbsp;</span></p>
                                <ol style={{marginBottom: '0in', marginTop: '0px'}} start={5}>
                                    <li style={{
                                        listStyle: 'none',
                                        margin: '0in 6.75pt 0.0001pt 0px',
                                        lineHeight: '95%',
                                        textAlign: 'justify',
                                        fontSize: '11pt',
                                        textIndent: '24px'
                                    }}>
                                        <ol style={{marginBottom: '0in', marginTop: '0px'}} start={7}>
                                            <li style={{
                                                margin: '0in 6.75pt 0.0001pt 0px',
                                                lineHeight: '95%',
                                                textAlign: 'justify',
                                                fontSize: '11pt',
                                                textIndent: '24px'
                                            }}>Unless the context otherwise requires, words in the singular shall
                                                include the plural and in the plural include the singular.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                                <p style={{
                                    margin: '0.45pt 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><span style={{fontSize: '13.5pt'}}>&nbsp;</span></p>
                                <ol style={{marginTop: '.05pt', marginBottom: '.0001pt'}} start={6}>
                                    <li style={{
                                        listStyle: 'none',
                                        margin: '0.05pt 6.8pt 0.0001pt 0px',
                                        lineHeight: '95%',
                                        textAlign: 'justify',
                                        fontSize: '11pt',
                                        textIndent: '24px'
                                    }}>
                                        <ol style={{marginTop: '.05pt', marginBottom: '.0001pt'}} start={8}>
                                            <li style={{
                                                margin: '0.05pt 6.8pt 0.0001pt 0px',
                                                lineHeight: '95%',
                                                textAlign: 'justify',
                                                fontSize: '11pt',
                                                textIndent: '24px'
                                            }}>A reference to a statute or statutory provision is a reference to it as
                                                it is in force for the time being, taking account of any amendment,
                                                extension, or re-enactment, and includes any subordinate legislation for
                                                the time being in force made under it.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                                <p style={{
                                    margin: '0.25pt 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><span style={{fontSize: '13.5pt'}}>&nbsp;</span></p>
                                <ol style={{marginBottom: '0in', marginTop: '0px'}} start={7}>
                                    <li style={{
                                        listStyle: 'none',
                                        margin: '0in 6.75pt 0.0001pt 0px',
                                        lineHeight: '95%',
                                        textAlign: 'justify',
                                        fontSize: '11pt',
                                        textIndent: '24px'
                                    }}>
                                        <ol style={{marginBottom: '0in', marginTop: '0px'}} start={9}>
                                            <li style={{
                                                margin: '0in 6.75pt 0.0001pt 0px',
                                                lineHeight: '95%',
                                                textAlign: 'justify',
                                                fontSize: '11pt',
                                                textIndent: '24px'
                                            }}>Any obligation in this agreement on a person not to do something includes
                                                an obligation not to agree or allow that thing to be done.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                                <p style={{
                                    margin: '0.45pt 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><span style={{fontSize: '13.5pt'}}>&nbsp;</span></p>
                                <ol style={{marginBottom: '0in', marginTop: '0px'}} start={8}>
                                    <li style={{
                                        listStyle: 'none',
                                        margin: '0in 6.95pt 0.0001pt 0px',
                                        lineHeight: '95%',
                                        textAlign: 'justify',
                                        fontSize: '11pt',
                                        textIndent: '24px'
                                    }}>
                                        <ol style={{marginBottom: '0in', marginTop: '0px'}} start={10}>
                                            <li style={{
                                                margin: '0in 6.95pt 0.0001pt 0px',
                                                lineHeight: '95%',
                                                textAlign: 'justify',
                                                fontSize: '11pt',
                                                textIndent: '24px'
                                            }}>References to clauses and schedules are to the clauses and schedules of
                                                this agreement; references to paragraphs are to paragraphs of the
                                                relevant schedule.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                                <p style={{
                                    margin: '0.3pt 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><span style={{fontSize: '14.5pt'}}>&nbsp;</span></p>
                                <ol style={{marginBottom: '0in', marginTop: '0px'}} start={4}>
                                    <li style={{
                                        textAlign: 'left',
                                        margin: '0in 0in 0.0001pt 0px',
                                        fontSize: '11pt',
                                        textIndent: '24px'
                                    }}><strong>O</strong><strong><span style={{fontSize: '9.0pt'}}>BLIGATIONS OF CONFIDENTIALITY</span></strong>
                                    </li>
                                </ol>
                                <p style={{
                                    margin: '0.15pt 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><strong><span style={{fontSize: '9.5pt'}}>&nbsp;</span></strong></p>
                                <ol style={{marginBottom: '0in', marginTop: '0px'}} start={5}>
                                    <li style={{
                                        listStyle: 'none',
                                        margin: '0in 7pt 0.0001pt 0px',
                                        lineHeight: '95%',
                                        textAlign: 'justify',
                                        fontSize: '11pt',
                                        textIndent: '24px'
                                    }}>
                                        <ol style={{marginBottom: '0in', marginTop: '0px'}} start={11}>
                                            <li style={{
                                                margin: '0in 7pt 0.0001pt 0px',
                                                lineHeight: '95%',
                                                textAlign: 'justify',
                                                fontSize: '11pt',
                                                textIndent: '24px'
                                            }}>The Recipient shall keep the Disclosing Party's Confidential Information
                                                confidential and, except with the prior written consent of the
                                                Disclosing Party, shall:
                                                <ol style={{
                                                    listStyleType: 'lower-alpha',
                                                    marginTop: '5.9pt',
                                                    marginBottom: '.0001pt'
                                                }} start={12}>
                                                    <li style={{
                                                        margin: '5.9pt 6.8pt 0.0001pt 0px',
                                                        lineHeight: '95%',
                                                        textAlign: 'justify',
                                                        fontSize: '11pt',
                                                        textIndent: '13.9333px'
                                                    }}>not use or exploit the Confidential Information in any way except
                                                        for the Purpose;
                                                    </li>
                                                    <li style={{
                                                        margin: '5.85pt 6.85pt 0.0001pt 0px',
                                                        lineHeight: '95%',
                                                        textAlign: 'justify',
                                                        fontSize: '11pt',
                                                        textIndent: '13.9333px'
                                                    }}>not disclose or make available the Confidential Information in
                                                        whole or in part to any third party, except as expressly
                                                        permitted by this agreement;
                                                    </li>
                                                    <li style={{
                                                        margin: '5.9pt 6.6pt 0.0001pt 0px',
                                                        lineHeight: '95%',
                                                        textAlign: 'justify',
                                                        fontSize: '11pt',
                                                        textIndent: '13.9333px'
                                                    }}>not copy, reduce to writing or otherwise record the Confidential
                                                        Information except as strictly necessary for the Purpose (and
                                                        any such copies, reductions to writing and records shall be the
                                                        property of the Disclosing Party);
                                                    </li>
                                                    <li style={{
                                                        margin: '5.8pt 6.55pt 0.0001pt 0px',
                                                        lineHeight: '95%',
                                                        textAlign: 'justify',
                                                        fontSize: '11pt',
                                                        textIndent: '13.9333px'
                                                    }}>not use, reproduce, transform, or store the Confidential
                                                        Information in an externally accessible computer or electronic
                                                        information retrieval system or transmit it in any form or by
                                                        any means whatsoever outside of its usual place of business;
                                                    </li>
                                                    <li style={{
                                                        margin: '5.75pt 6.85pt 0.0001pt 0px',
                                                        lineHeight: '95%',
                                                        textAlign: 'justify',
                                                        fontSize: '11pt',
                                                        textIndent: '13.9333px'
                                                    }}>keep separate the Confidential Information from all documents and
                                                        other records of the Recipient
                                                    </li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </div>
                            <p style={{textAlign: 'center'}}><span style={{
                                fontSize: '11.0pt',
                                lineHeight: '95%',
                            }}>&nbsp;</span></p>
                            <div className="WordSection5">
                                <ol style={{marginTop: '.7pt', marginBottom: '.0001pt'}} start={9}>
                                    <li style={{
                                        listStyle: 'none',
                                        margin: '0.7pt 6.7pt 0.0001pt 3.3997px',
                                        lineHeight: '95%',
                                        textAlign: 'justify',
                                        fontSize: '11pt',
                                        textIndent: '13.9333px'
                                    }}>
                                        <ol style={{marginTop: '.7pt', marginBottom: '.0001pt'}} start={12}>
                                            <li style={{
                                                listStyle: 'none',
                                                margin: '0.7pt 6.7pt 0.0001pt 0px',
                                                lineHeight: '95%',
                                                textAlign: 'justify',
                                                fontSize: '11pt',
                                                textIndent: '13.9333px'
                                            }}>
                                                <ol style={{
                                                    listStyleType: 'lower-alpha',
                                                    marginTop: '.7pt',
                                                    marginBottom: '.0001pt'
                                                }} start={17}>
                                                    <li style={{
                                                        margin: '0.7pt 6.7pt 0.0001pt 0px',
                                                        lineHeight: '95%',
                                                        textAlign: 'justify',
                                                        fontSize: '11pt',
                                                        textIndent: '13.9333px'
                                                    }}>apply the same security measures and degree of care to the
                                                        Confidential Information as the Recipient applies to its own
                                                        confidential information, which the Recipient warrants as
                                                        providing adequate protection from unauthorised disclosure,
                                                        copying or use;
                                                    </li>
                                                    <li style={{
                                                        margin: '5.75pt 6.75pt 0.0001pt 0px',
                                                        lineHeight: '95%',
                                                        textAlign: 'justify',
                                                        fontSize: '11pt',
                                                        textIndent: '13.9333px'
                                                    }}>keep a written record of: any document or other Confidential
                                                        Information received from the other in tangible form; any copy
                                                        made of the Confidential Information; and
                                                    </li>
                                                    <li style={{
                                                        margin: '5.8pt 6.7pt 0.0001pt 0px',
                                                        lineHeight: '95%',
                                                        textAlign: 'justify',
                                                        fontSize: '11pt',
                                                        textIndent: '13.9333px'
                                                    }}>ensure that any document or other records containing Confidential
                                                        Information shall be kept at its premises and shall not remove
                                                        or allow to be removed such document or records from its
                                                        premises.
                                                    </li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                                <p style={{
                                    margin: '0.45pt 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><span style={{fontSize: '13.5pt'}}>&nbsp;</span></p>
                                <ol style={{marginBottom: '0in', marginTop: '0px'}} start={10}>
                                    <li style={{
                                        listStyle: 'none',
                                        margin: '0in 6.95pt 0.0001pt 0px',
                                        lineHeight: '95%',
                                        textAlign: 'justify',
                                        fontSize: '11pt',
                                        textIndent: '24px'
                                    }}>
                                        <ol style={{marginBottom: '0in', marginTop: '0px'}} start={13}>
                                            <li style={{
                                                margin: '0in 6.95pt 0.0001pt 0px',
                                                lineHeight: '95%',
                                                textAlign: 'justify',
                                                fontSize: '11pt',
                                                textIndent: '24px'
                                            }}>The Recipient may disclose the Disclosing Party's Confidential
                                                Information to those of its Representatives who need to know this
                                                Confidential Information for the Purpose, provided that:
                                                <ol style={{
                                                    listStyleType: 'lower-alpha',
                                                    marginTop: '5.8pt',
                                                    marginBottom: '.0001pt'
                                                }} start={20}>
                                                    <li style={{
                                                        margin: '5.8pt 6.95pt 0.0001pt 0px',
                                                        lineHeight: '95%',
                                                        textAlign: 'justify',
                                                        fontSize: '11pt',
                                                        textIndent: '13.9333px'
                                                    }}>it informs its Representatives of the confidential nature of the
                                                        Confidential Information before disclosure;
                                                    </li>
                                                    <li style={{
                                                        margin: '5.9pt 6.7pt 0.0001pt 0px',
                                                        lineHeight: '95%',
                                                        textAlign: 'justify',
                                                        fontSize: '11pt',
                                                        textIndent: '13.9333px'
                                                    }}>it procures that its Representatives shall, in relation to any
                                                        Confidential Information disclosed to them, comply with this
                                                        agreement as if they were the Recipient and, if the Disclosing
                                                        Party so requests, procure that any relevant Representative
                                                        enters into a confidentiality agreement with the Disclosing
                                                        Party on terms equivalent to those contained in this agreement;
                                                        and
                                                    </li>
                                                    <li style={{
                                                        margin: '5pt 0in 0.0001pt 0px',
                                                        textAlign: 'left',
                                                        fontSize: '11pt',
                                                        textIndent: '13.9333px'
                                                    }}>it keeps a written record of these Representatives,
                                                    </li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                                <p style={{
                                    margin: '0.45pt 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><span style={{fontSize: '12.0pt'}}>&nbsp;</span></p>
                                <p style={{
                                    margin: '0in 0in 0.0001pt 43pt',
                                    lineHeight: '95%',
                                    fontSize: '11pt',
                                }}>and it shall at all times be liable for the failure of any Representative to comply
                                    with the terms of this agreement.</p>
                                <p style={{
                                    margin: '0.35pt 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><span style={{fontSize: '13.5pt'}}>&nbsp;</span></p>
                                <ol style={{marginBottom: '0in', marginTop: '0px'}} start={11}>
                                    <li style={{
                                        listStyle: 'none',
                                        margin: '0in 6.8pt 0.0001pt 0px',
                                        lineHeight: '95%',
                                        textAlign: 'justify',
                                        fontSize: '11pt',
                                        textIndent: '24px'
                                    }}>
                                        <ol style={{marginBottom: '0in', marginTop: '0px'}} start={14}>
                                            <li style={{
                                                margin: '0in 6.8pt 0.0001pt 0px',
                                                lineHeight: '95%',
                                                textAlign: 'justify',
                                                fontSize: '11pt',
                                                textIndent: '24px'
                                            }}>A party may disclose Confidential Information to the extent such
                                                Confidential Information is required to be disclosed by law, by any
                                                governmental or other regulatory authority, or by a court or other
                                                authority of competent jurisdiction provided that, to the extent it is
                                                legally permitted to do so, it gives the other party as much notice of
                                                this disclosure as possible.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                                <p style={{
                                    margin: '0.3pt 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><span style={{fontSize: '13.5pt'}}>&nbsp;</span></p>
                                <ol style={{marginBottom: '0in', marginTop: '0px'}} start={12}>
                                    <li style={{
                                        listStyle: 'none',
                                        margin: '0in 6.65pt 0.0001pt 0px',
                                        lineHeight: '95%',
                                        textAlign: 'justify',
                                        fontSize: '11pt',
                                        textIndent: '24px'
                                    }}>
                                        <ol style={{marginBottom: '0in', marginTop: '0px'}} start={15}>
                                            <li style={{
                                                margin: '0in 6.65pt 0.0001pt 0px',
                                                lineHeight: '95%',
                                                textAlign: 'justify',
                                                fontSize: '11pt',
                                                textIndent: '24px'
                                            }}>The Recipient may, provided that the Recipient has reasonable grounds to
                                                believe that the Disclosing Party is involved in activity that may
                                                constitute a criminal offence under the Bribery Act 2010, disclose
                                                Confidential Information to the Serious Fraud Office without first
                                                notifying the Disclosing Party of such disclosure.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                                <p style={{
                                    margin: '0.3pt 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><span style={{fontSize: '13.5pt'}}>&nbsp;</span></p>
                                <ol style={{marginTop: '.05pt', marginBottom: '.0001pt'}} start={13}>
                                    <li style={{
                                        listStyle: 'none',
                                        margin: '0.05pt 6.85pt 0.0001pt 0px',
                                        lineHeight: '95%',
                                        textAlign: 'justify',
                                        fontSize: '11pt',
                                        textIndent: '24px'
                                    }}>
                                        <ol style={{marginTop: '.05pt', marginBottom: '.0001pt'}} start={16}>
                                            <li style={{
                                                margin: '0.05pt 6.85pt 0.0001pt 0px',
                                                lineHeight: '95%',
                                                textAlign: 'justify',
                                                fontSize: '11pt',
                                                textIndent: '24px'
                                            }}>The Recipient shall establish and maintain adequate security measures
                                                (including any reasonable security measures proposed by the Disclosing
                                                party from time to time) to safeguard the Confidential Information from
                                                unauthorised access or use.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </div>
                            <p style={{textAlign: 'center'}}><span style={{
                                fontSize: '11.0pt',
                                lineHeight: '95%',
                            }}>&nbsp;</span></p>
                            <div className="WordSection6">
                                <ol style={{marginTop: '.7pt', marginBottom: '.0001pt'}} start={14}>
                                    <li style={{
                                        listStyle: 'none',
                                        margin: '0.7pt 6.8pt 0.0001pt 0px',
                                        lineHeight: '95%',
                                        textAlign: 'justify',
                                        fontSize: '11pt',
                                        textIndent: '24px'
                                    }}>
                                        <ol style={{marginTop: '.7pt', marginBottom: '.0001pt'}} start={17}>
                                            <li style={{
                                                margin: '0.7pt 6.8pt 0.0001pt 0px',
                                                lineHeight: '95%',
                                                textAlign: 'justify',
                                                fontSize: '11pt',
                                                textIndent: '24px'
                                            }}>No party shall make, or permit any person to make, any public
                                                announcement concerning this agreement, the Purpose or its prospective
                                                interest in the Purpose without the prior written consent of the other
                                                party (such consent not to be unreasonably withheld or delayed) except
                                                as required by law or any governmental or regulatory authority
                                                (including, without limitation, any relevant securities exchange) or by
                                                any court or other authority of competent jurisdiction. No party shall
                                                make use of the other party's name or any information acquired through
                                                its dealings with the other party for publicity or marketing purposes
                                                without the prior written consent of the other party.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                                <p style={{
                                    margin: '0in 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><span style={{fontSize: '13.5pt'}}>&nbsp;</span></p>
                                <ol style={{marginTop: '.05pt', marginBottom: '.0001pt'}} start={15}>
                                    <li style={{
                                        listStyle: 'none',
                                        margin: '0.05pt 6.65pt 0.0001pt 0px',
                                        lineHeight: '95%',
                                        textAlign: 'justify',
                                        fontSize: '11pt',
                                        textIndent: '24px'
                                    }}>
                                        <ol style={{marginTop: '.05pt', marginBottom: '.0001pt'}} start={18}>
                                            <li style={{
                                                margin: '0.05pt 6.65pt 0.0001pt 0px',
                                                lineHeight: '95%',
                                                textAlign: 'justify',
                                                fontSize: '11pt',
                                                textIndent: '24px'
                                            }}>The terms of confidentiality under this Agreement shall not be construed
                                                to limit in any way whatsoever <strong>The Nordic Car Company
                                                    Ltd</strong>’<strong>s</strong> right to independently develop or
                                                acquire products or services of the same type as may be included within
                                                any Confidential Information or to enter into any business transaction
                                                with any other individual, company, corporation, partnership, joint
                                                venture or other entity which owns or has rights to any such similar
                                                products or services, as long as such right is exercised without the use
                                                of any other party's Confidential Information in violation of this
                                                Agreement.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                                <p style={{
                                    margin: '0.7pt 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><span style={{fontSize: '14.0pt'}}>&nbsp;</span></p>
                                <ol style={{marginBottom: '0in', marginTop: '0px'}} start={6}>
                                    <li style={{
                                        textAlign: 'left',
                                        margin: '0in 0in 0.0001pt 0px',
                                        fontSize: '11pt',
                                        textIndent: '24px'
                                    }}><strong>R</strong><strong><span
                                        style={{fontSize: '9.0pt'}}>ETURN OF INFORMATION</span></strong></li>
                                </ol>
                                <p style={{
                                    margin: '0.2pt 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><strong><span style={{fontSize: '9.0pt'}}>&nbsp;</span></strong></p>
                                <ol style={{marginBottom: '0in', marginTop: '0px'}} start={7}>
                                    <li style={{
                                        listStyle: 'none',
                                        textAlign: 'left',
                                        margin: '0in 0in 0.0001pt 0px',
                                        fontSize: '11pt',
                                        textIndent: '27.3333px'
                                    }}>
                                        <ol style={{marginBottom: '0in', marginTop: '0px'}} start={19}>
                                            <li style={{
                                                textAlign: 'left',
                                                margin: '0in 0in 0.0001pt 0px',
                                                fontSize: '11pt',
                                                textIndent: '27.3333px'
                                            }}>At the request of the Disclosing Party, the Recipient shall:
                                                <ol style={{
                                                    listStyleType: 'lower-alpha',
                                                    marginTop: '5.8pt',
                                                    marginBottom: '.0001pt'
                                                }} start={23}>
                                                    <li style={{
                                                        margin: '5.8pt 6.7pt 0.0001pt 0px',
                                                        lineHeight: '95%',
                                                        textAlign: 'justify',
                                                        fontSize: '11pt',
                                                        textIndent: '13.9333px'
                                                    }}>destroy or return to the Disclosing Party all documents and
                                                        materials (and any copies) containing, reflecting,
                                                        incorporating, or based on the Disclosing Party's Confidential
                                                        Information;
                                                    </li>
                                                    <li style={{
                                                        margin: '5.85pt 7pt 0.0001pt 0px',
                                                        lineHeight: '95%',
                                                        textAlign: 'justify',
                                                        fontSize: '11pt',
                                                        textIndent: '13.9333px'
                                                    }}>erase all the Disclosing Party's Confidential Information from
                                                        its computer systems or which is stored in electronic form (to
                                                        the extent possible); and
                                                    </li>
                                                    <li style={{
                                                        margin: '5.85pt 6.65pt 0.0001pt 0px',
                                                        lineHeight: '95%',
                                                        textAlign: 'justify',
                                                        fontSize: '11pt',
                                                        textIndent: '13.9333px'
                                                    }}>certify in writing to the Disclosing Party that it has complied
                                                        with the requirements of this clause, provided that a Recipient
                                                        may retain documents and materials containing, reflecting,
                                                        incorporating, or based on the Disclosing Party's Confidential
                                                        Information to the extent required by law or any applicable
                                                        governmental or regulatory authority and to the extent
                                                        reasonable to permit the Recipient to keep evidence that it has
                                                        performed its obligations under this agreement. The provisions
                                                        of this agreement shall continue to apply to any documents and
                                                        materials retained by the Recipient.
                                                    </li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                                <p style={{
                                    margin: '0in 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><span style={{fontSize: '13.5pt'}}>&nbsp;</span></p>
                                <ol style={{marginBottom: '0in', marginTop: '0px'}} start={16}>
                                    <li style={{
                                        listStyle: 'none',
                                        margin: '0in 6.75pt 0.0001pt 0px',
                                        lineHeight: '95%',
                                        textAlign: 'justify',
                                        fontSize: '11pt',
                                        textIndent: '24px'
                                    }}>
                                        <ol style={{marginBottom: '0in', marginTop: '0px'}} start={20}>
                                            <li style={{
                                                margin: '0in 6.75pt 0.0001pt 0px',
                                                lineHeight: '95%',
                                                textAlign: 'justify',
                                                fontSize: '11pt',
                                                textIndent: '24px'
                                            }}>If the Recipient develops or uses a product or a process which, in the
                                                reasonable opinion of the Disclosing Party, might have involved the use
                                                of any of the Disclosing Party's Confidential Information, the Recipient
                                                shall, at the request of the Disclosing Party, supply to the Disclosing
                                                Party information reasonably necessary to establish that the Disclosing
                                                Party's Confidential Information has not been used or disclosed.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </div>
                            <p style={{textAlign: 'center'}}><span style={{
                                fontSize: '11.0pt',
                                lineHeight: '95%',
                            }}>&nbsp;</span></p>
                            <div className="WordSection7">
                                <ol style={{marginTop: '0.05pt', marginBottom: '0in'}} start={8}>
                                    <li style={{
                                        margin: '0.05pt 0in 0.0001pt 0px',
                                        textAlign: 'left',
                                        fontSize: '11pt',
                                        textIndent: '24px'
                                    }}><strong>R</strong><strong><span style={{fontSize: '9.0pt'}}>ESERVATION OF RIGHTS AND ACKNOWLEDGEMENT</span></strong>
                                    </li>
                                </ol>
                                <p style={{
                                    margin: '0.15pt 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><strong><span style={{fontSize: '9.5pt'}}>&nbsp;</span></strong></p>
                                <ol style={{marginBottom: '0in', marginTop: '0px'}} start={9}>
                                    <li style={{
                                        listStyle: 'none',
                                        margin: '0in 6.7pt 0.0001pt 0px',
                                        lineHeight: '95%',
                                        textAlign: 'justify',
                                        fontSize: '11pt',
                                        textIndent: '24px'
                                    }}>
                                        <ol style={{marginBottom: '0in', marginTop: '0px'}} start={21}>
                                            <li style={{
                                                margin: '0in 6.7pt 0.0001pt 0px',
                                                lineHeight: '95%',
                                                textAlign: 'justify',
                                                fontSize: '11pt',
                                                textIndent: '24px'
                                            }}>All Confidential Information shall remain the property of the Disclosing
                                                Party. Each party reserves all rights in its Confidential Information.
                                                No rights, including, but not limited to, intellectual property rights,
                                                in respect of a party's Confidential Information are granted to the
                                                other party and no obligations are imposed on the Disclosing Party other
                                                than those expressly stated in this agreement.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                                <p style={{
                                    margin: '0.15pt 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><span style={{fontSize: '13.5pt'}}>&nbsp;</span></p>
                                <ol style={{marginBottom: '0in', marginTop: '0px'}} start={10}>
                                    <li style={{
                                        listStyle: 'none',
                                        margin: '0in 6.85pt 0.0001pt 0px',
                                        lineHeight: '95%',
                                        textAlign: 'justify',
                                        fontSize: '11pt',
                                        textIndent: '24px'
                                    }}>
                                        <ol style={{marginBottom: '0in', marginTop: '0px'}} start={22}>
                                            <li style={{
                                                margin: '0in 6.85pt 0.0001pt 0px',
                                                lineHeight: '95%',
                                                textAlign: 'justify',
                                                fontSize: '11pt',
                                                textIndent: '24px'
                                            }}>Except as expressly stated in this agreement, no party makes any express
                                                or implied warranty or representation concerning its Confidential
                                                Information, or the accuracy or completeness of the Confidential
                                                Information.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                                <p style={{
                                    margin: '0.4pt 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><span style={{fontSize: '13.5pt'}}>&nbsp;</span></p>
                                <ol style={{marginBottom: '0in', marginTop: '0px'}} start={11}>
                                    <li style={{
                                        listStyle: 'none',
                                        margin: '0in 6.7pt 0.0001pt 0px',
                                        lineHeight: '95%',
                                        textAlign: 'justify',
                                        fontSize: '11pt',
                                        textIndent: '24px'
                                    }}>
                                        <ol style={{marginBottom: '0in', marginTop: '0px'}} start={23}>
                                            <li style={{
                                                margin: '0in 6.7pt 0.0001pt 0px',
                                                lineHeight: '95%',
                                                textAlign: 'justify',
                                                fontSize: '11pt',
                                                textIndent: '24px'
                                            }}>The disclosure of Confidential Information by the Disclosing Party shall
                                                not form any offer by, or representation or warranty on the part of, the
                                                Disclosing Party to enter into any further agreement in relation to the
                                                Purpose, or the development or supply of any product or service to which
                                                the Confidential Information relates.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                                <p style={{
                                    margin: '0.35pt 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><span style={{fontSize: '13.5pt'}}>&nbsp;</span></p>
                                <ol style={{marginBottom: '0in', marginTop: '0px'}} start={12}>
                                    <li style={{
                                        listStyle: 'none',
                                        margin: '0in 6.7pt 0.0001pt 0px',
                                        lineHeight: '95%',
                                        textAlign: 'justify',
                                        fontSize: '11pt',
                                        textIndent: '24px'
                                    }}>
                                        <ol style={{marginBottom: '0in', marginTop: '0px'}} start={24}>
                                            <li style={{
                                                margin: '0in 6.7pt 0.0001pt 0px',
                                                lineHeight: '95%',
                                                textAlign: 'justify',
                                                fontSize: '11pt',
                                                textIndent: '24px'
                                            }}>The Recipient acknowledges that damages alone would not be an adequate
                                                remedy for the breach of any of the provisions of this agreement.
                                                Accordingly, without prejudice to any other rights and remedies it may
                                                have, the Disclosing Party shall be entitled to the granting of
                                                equitable relief (including without limitation injunctive relief)
                                                concerning any threatened or actual breach of any of the provisions of
                                                this agreement.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                                <p style={{
                                    margin: '0.05pt 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><span style={{fontSize: '14.5pt'}}>&nbsp;</span></p>
                                <ol style={{marginBottom: '0in', marginTop: '0px'}} start={13}>
                                    <li style={{
                                        textAlign: 'left',
                                        margin: '0in 0in 0.0001pt 0px',
                                        fontSize: '11pt',
                                        textIndent: '24px'
                                    }}><strong>W</strong><strong><span
                                        style={{fontSize: '9.0pt'}}>ARRANTY AND INDEMNITY</span></strong></li>
                                </ol>
                                <p style={{
                                    margin: '0.15pt 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><strong><span style={{fontSize: '9.5pt'}}>&nbsp;</span></strong></p>
                                <ol style={{marginBottom: '0in', marginTop: '0px'}} start={14}>
                                    <li style={{
                                        listStyle: 'none',
                                        margin: '0in 6.85pt 0.0001pt 0px',
                                        lineHeight: '95%',
                                        textAlign: 'justify',
                                        fontSize: '11pt',
                                        textIndent: '24px'
                                    }}>
                                        <ol style={{marginBottom: '0in', marginTop: '0px'}} start={25}>
                                            <li style={{
                                                margin: '0in 6.85pt 0.0001pt 0px',
                                                lineHeight: '95%',
                                                textAlign: 'justify',
                                                fontSize: '11pt',
                                                textIndent: '24px'
                                            }}>Each Disclosing Party warrants that it has the right to disclose its
                                                Confidential Information to the Recipient and to authorise the Recipient
                                                to use such Confidential Information for the Purpose.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                                <p style={{
                                    margin: '0.25pt 0in 0.0001pt',
                                    fontSize: '11pt',
                                }}><span style={{fontSize: '13.5pt'}}>&nbsp;</span></p>
                                <ol style={{marginBottom: '0in', marginTop: '0px'}} start={15}>
                                    <li style={{
                                        listStyle: 'none',
                                        margin: '0in 6.65pt 0.0001pt 0px',
                                        lineHeight: '95%',
                                        textAlign: 'justify',
                                        fontSize: '11pt',
                                        textIndent: '24px'
                                    }}>
                                        <ol style={{marginBottom: '0in', marginTop: '0px'}} start={26}>
                                            <li style={{
                                                margin: '0in 6.65pt 0.0001pt 0px',
                                                lineHeight: '95%',
                                                textAlign: 'justify',
                                                fontSize: '11pt',
                                                textIndent: '24px'
                                            }}>Each Recipient shall indemnify and keep fully indemnified the Disclosing
                                                Party and its Group at all times against all liabilities, costs
                                                (including legal costs on an indemnity basis), expenses, damages and
                                                losses (including any direct, indirect or consequential losses, loss of
                                                profit, loss of reputation and all interest, penalties and other
                                                reasonable costs and expenses suffered or incurred by the Disclosing
                                                Party and/or its Group) arising from any breach of this agreement by the
                                                Recipient and from the actions or omissions of any Representative of the
                                                Recipient.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                                <p style={{
                                    margin: '0in 0in 0.0001pt',
                                    fontSize: '11pt',

                                }}><span style={{fontSize: '14.5pt'}}>&nbsp;</span></p>
                                <ol style={{marginBottom: '0in', marginTop: '0px'}} start={16}>
                                    <li style={{
                                        textAlign: 'left',
                                        margin: '0in 0in 0.0001pt 0px',
                                        fontSize: '11pt',

                                        textIndent: '24px'
                                    }}><strong>T</strong><strong><span
                                        style={{fontSize: '9.0pt'}}>ERM AND TERMINATION</span></strong></li>
                                </ol>
                                <p style={{
                                    margin: '0.15pt 0in 0.0001pt',
                                    fontSize: '11pt',

                                }}><strong><span style={{fontSize: '9.5pt'}}>&nbsp;</span></strong></p>
                                <ol style={{marginBottom: '0in', marginTop: '0px'}} start={17}>
                                    <li style={{
                                        listStyle: 'none',
                                        margin: '0in 6.75pt 0.0001pt 0px',
                                        lineHeight: '95%',
                                        textAlign: 'justify',
                                        fontSize: '11pt',

                                        textIndent: '24px'
                                    }}>
                                        <ol style={{marginBottom: '0in', marginTop: '0px'}} start={27}>
                                            <li style={{
                                                margin: '0in 6.75pt 0.0001pt 0px',
                                                lineHeight: '95%',
                                                textAlign: 'justify',
                                                fontSize: '11pt',

                                                textIndent: '24px'
                                            }}>If either party decides not to become or continue to be involved in the
                                                Purpose with the other party it shall notify the other party in writing
                                                immediately. The obligations of each party shall, notwithstanding any
                                                earlier termination of negotiations or
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </div>
                            <p style={{textAlign: 'center'}}><span style={{
                                fontSize: '11.0pt',
                                lineHeight: '95%',

                            }}>&nbsp;</span></p>
                            <p style={{
                                margin: '0in 6.95pt 0.0001pt 43pt',
                                textAlign: 'justify',
                                lineHeight: '95%',
                                fontSize: '11pt',

                            }}>discussions between the parties in relation to the Purpose, continue for a period of
                                three years from the termination of this agreement.</p>
                            <p style={{
                                margin: '0.15pt 0in 0.0001pt',
                                fontSize: '11pt',

                            }}><span style={{fontSize: '13.5pt'}}>&nbsp;</span></p>
                            <ol style={{marginBottom: '0in', marginTop: '0px'}} start={18}>
                                <li style={{
                                    listStyle: 'none',
                                    margin: '0in 6.75pt 0.0001pt 0px',
                                    lineHeight: '95%',
                                    textAlign: 'justify',
                                    fontSize: '11pt',

                                    textIndent: '24px'
                                }}>
                                    <ol style={{marginBottom: '0in', marginTop: '0px'}} start={28}>
                                        <li style={{
                                            margin: '0in 6.75pt 0.0001pt 0px',
                                            lineHeight: '95%',
                                            textAlign: 'justify',
                                            fontSize: '11pt',

                                            textIndent: '24px'
                                        }}>Termination of this agreement shall not affect any accrued rights or remedies
                                            to which either party is entitled.
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <p style={{
                                margin: '0.3pt 0in 0.0001pt',
                                fontSize: '11pt',

                            }}><span style={{fontSize: '14.5pt'}}>&nbsp;</span></p>
                            <ol style={{marginBottom: '0in', marginTop: '0px'}} start={19}>
                                <li style={{
                                    textAlign: 'left',
                                    margin: '0in 0in 0.0001pt 0px',
                                    fontSize: '11pt',

                                    textIndent: '24px'
                                }}><strong>E</strong><strong><span style={{fontSize: '9.0pt'}}>NTIRE AGREEMENT AND VARIATION</span></strong>
                                </li>
                            </ol>
                            <p style={{
                                margin: '0.15pt 0in 0.0001pt',
                                fontSize: '11pt',

                            }}><strong><span style={{fontSize: '9.5pt'}}>&nbsp;</span></strong></p>
                            <ol style={{marginBottom: '0in', marginTop: '0px'}} start={20}>
                                <li style={{
                                    listStyle: 'none',
                                    margin: '0in 6.8pt 0.0001pt 0px',
                                    lineHeight: '95%',
                                    textAlign: 'justify',
                                    fontSize: '11pt',

                                    textIndent: '24px'
                                }}>
                                    <ol style={{marginBottom: '0in', marginTop: '0px'}} start={29}>
                                        <li style={{
                                            margin: '0in 6.8pt 0.0001pt 0px',
                                            lineHeight: '95%',
                                            textAlign: 'justify',
                                            fontSize: '11pt',

                                            textIndent: '24px'
                                        }}>This agreement constitutes the entire agreement between the parties and
                                            supersedes and extinguishes all previous drafts, agreements, arrangements
                                            and understandings between them, whether written or oral, relating to its
                                            subject matter.
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <p style={{
                                margin: '0.3pt 0in 0.0001pt',
                                fontSize: '11pt',

                            }}><span style={{fontSize: '13.5pt'}}>&nbsp;</span></p>
                            <ol style={{marginBottom: '0in', marginTop: '0px'}} start={21}>
                                <li style={{
                                    listStyle: 'none',
                                    margin: '0in 6.8pt 0.0001pt 0px',
                                    lineHeight: '95%',
                                    textAlign: 'justify',
                                    fontSize: '11pt',

                                    textIndent: '24px'
                                }}>
                                    <ol style={{marginBottom: '0in', marginTop: '0px'}} start={30}>
                                        <li style={{
                                            margin: '0in 6.8pt 0.0001pt 0px',
                                            lineHeight: '95%',
                                            textAlign: 'justify',
                                            fontSize: '11pt',

                                            textIndent: '24px'
                                        }}>Each party agrees that it shall have no remedies in respect of any
                                            representation or warranty (whether made innocently or negligently) that is
                                            not set out in this agreement. Each party agrees that its only liability in
                                            respect of those representations and warranties that are set out in this
                                            agreement (whether made innocently or negligently) shall be for breach of
                                            contract.
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <p style={{
                                margin: '0.25pt 0in 0.0001pt',
                                fontSize: '11pt',

                            }}><span style={{fontSize: '13.5pt'}}>&nbsp;</span></p>
                            <ol style={{marginBottom: '0in', marginTop: '0px'}} start={22}>
                                <li style={{
                                    listStyle: 'none',
                                    margin: '0in 6.75pt 0.0001pt 0px',
                                    lineHeight: '95%',
                                    textAlign: 'justify',
                                    fontSize: '11pt',

                                    textIndent: '24px'
                                }}>
                                    <ol style={{marginBottom: '0in', marginTop: '0px'}} start={31}>
                                        <li style={{
                                            margin: '0in 6.75pt 0.0001pt 0px',
                                            lineHeight: '95%',
                                            textAlign: 'justify',
                                            fontSize: '11pt',

                                            textIndent: '24px'
                                        }}>No variation of this agreement shall be effective unless it is in writing and
                                            signed by each of the parties (or their authorised representatives).
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <p style={{
                                margin: '0.3pt 0in 0.0001pt',
                                fontSize: '11pt',

                            }}><span style={{fontSize: '14.5pt'}}>&nbsp;</span></p>
                            <ol style={{marginBottom: '0in', marginTop: '0px'}} start={23}>
                                <li style={{
                                    textAlign: 'left',
                                    margin: '0in 0in 0.0001pt 0px',
                                    fontSize: '11pt',

                                    textIndent: '24px'
                                }}><strong>N</strong><strong><span style={{fontSize: '9.0pt'}}>O WAIVER</span></strong>
                                </li>
                            </ol>
                            <p style={{
                                margin: '0.15pt 0in 0.0001pt',
                                fontSize: '11pt',

                            }}><strong><span style={{fontSize: '9.5pt'}}>&nbsp;</span></strong></p>
                            <ol style={{marginBottom: '0in', marginTop: '0px'}} start={24}>
                                <li style={{
                                    listStyle: 'none',
                                    margin: '0in 6.75pt 0.0001pt 0px',
                                    lineHeight: '95%',
                                    textAlign: 'justify',
                                    fontSize: '11pt',

                                    textIndent: '24px'
                                }}>
                                    <ol style={{marginBottom: '0in', marginTop: '0px'}} start={32}>
                                        <li style={{
                                            margin: '0in 6.75pt 0.0001pt 0px',
                                            lineHeight: '95%',
                                            textAlign: 'justify',
                                            fontSize: '11pt',

                                            textIndent: '24px'
                                        }}>Failure to exercise, or any delay in exercising, any right or remedy provided
                                            under this agreement or by law shall not constitute a waiver of that or any
                                            other right or remedy, nor shall it preclude or restrict any further
                                            exercise of that or any other right or remedy.
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <p style={{
                                margin: '0.35pt 0in 0.0001pt',
                                fontSize: '11pt',

                            }}><span style={{fontSize: '13.5pt'}}>&nbsp;</span></p>
                            <ol style={{marginBottom: '0in', marginTop: '0px'}} start={25}>
                                <li style={{
                                    listStyle: 'none',
                                    margin: '0in 6.7pt 0.0001pt 0px',
                                    lineHeight: '95%',
                                    textAlign: 'justify',
                                    fontSize: '11pt',

                                    textIndent: '24px'
                                }}>
                                    <ol style={{marginBottom: '0in', marginTop: '0px'}} start={33}>
                                        <li style={{
                                            margin: '0in 6.7pt 0.0001pt 0px',
                                            lineHeight: '95%',
                                            textAlign: 'justify',
                                            fontSize: '11pt',

                                            textIndent: '24px'
                                        }}>No single or partial exercise of any right or remedy provided under this
                                            agreement or by law shall preclude or restrict the further exercise of that
                                            or any other right or remedy.
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <p style={{
                                margin: '0.25pt 0in 0.0001pt',
                                fontSize: '11pt',

                            }}><span style={{fontSize: '14.5pt'}}>&nbsp;</span></p>
                            <ol style={{marginBottom: '0in', marginTop: '0px'}} start={26}>
                                <li style={{
                                    textAlign: 'left',
                                    margin: '0in 0in 0.0001pt 0px',
                                    fontSize: '11pt',

                                    textIndent: '24px'
                                }}><strong>A</strong><strong><span style={{fontSize: '9.0pt'}}>SSIGNMENT</span></strong>
                                </li>
                            </ol>
                            <p style={{
                                margin: '0.25pt 0in 0.0001pt',
                                fontSize: '11pt',

                            }}><strong><span style={{fontSize: '8.0pt'}}>&nbsp;</span></strong></p>
                            <p style={{
                                margin: '0in 6.65pt 0.0001pt 43pt',
                                textAlign: 'justify',
                                lineHeight: '95%',
                                fontSize: '11pt',

                            }}>Except as otherwise provided in this agreement, no party may assign, sub-contract or deal
                                in any way with, any of its rights or obligations under this agreement or any document
                                referred to in it.</p>
                            <p style={{
                                margin: '0.2pt 0in 0.0001pt',
                                fontSize: '11pt',

                            }}><span style={{fontSize: '14.5pt'}}>&nbsp;</span></p>
                            <ol style={{marginTop: '0.05pt', marginBottom: '0in'}} start={27}>
                                <li style={{
                                    margin: '0.05pt 0in 0.0001pt 0px',
                                    textAlign: 'left',
                                    fontSize: '11pt',

                                    textIndent: '24px'
                                }}><strong>N</strong><strong><span style={{fontSize: '9.0pt'}}>OTICES</span></strong>
                                </li>
                            </ol>
                            <p style={{
                                margin: '0.1pt 0in 0.0001pt',
                                fontSize: '11pt',

                            }}><strong><span style={{fontSize: '9.5pt'}}>&nbsp;</span></strong></p>
                            <ol style={{marginBottom: '0in', marginTop: '0px'}} start={28}>
                                <li style={{
                                    listStyle: 'none',
                                    margin: '0in 6.85pt 0.0001pt 0px',
                                    lineHeight: '95%',
                                    textAlign: 'justify',
                                    fontSize: '11pt',

                                    textIndent: '24px'
                                }}>
                                    <ol style={{marginBottom: '0in', marginTop: '0px'}} start={34}>
                                        <li style={{
                                            margin: '0in 6.85pt 0.0001pt 0px',
                                            lineHeight: '95%',
                                            textAlign: 'justify',
                                            fontSize: '11pt',

                                            textIndent: '24px'
                                        }}>Any notice or other communication required to be given under this agreement,
                                            shall be in writing and shall be delivered personally, or sent by pre-paid
                                            first class post or
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <p style={{
                                margin: '0in 6.7pt 0.0001pt 43pt',
                                textAlign: 'justify',
                                lineHeight: '95%',
                                fontSize: '11pt',

                            }}>recorded delivery or by commercial courier, to each party required to receive the notice
                                or communication at its address as set out below:</p>
                            <p style={{
                                margin: '0in 6.7pt 0.0001pt 43pt',
                                textAlign: 'justify',
                                lineHeight: '95%',
                                fontSize: '11pt',

                            }}>&nbsp;</p>
                            <ol style={{marginBottom: '0in', marginTop: '0px'}} start={29}>
                                <li style={{
                                    listStyle: 'none',
                                    textAlign: 'left',
                                    margin: '0in 0in 0.0001pt 3.3997px',
                                    fontSize: '11pt',

                                    textIndent: '13.9333px'
                                }}>
                                    <ol style={{marginBottom: '0in', marginTop: '0px'}} start={35}>
                                        <li style={{
                                            listStyle: 'none',
                                            textAlign: 'left',
                                            margin: '0in 0in 0.0001pt 0px',
                                            fontSize: '11pt',

                                            textIndent: '13.9333px'
                                        }}>
                                            <ol style={{
                                                listStyleType: 'lower-alpha',
                                                marginBottom: '0in',
                                                marginTop: '0px'
                                            }} start={26}>
                                                <li style={{
                                                    textAlign: 'left',
                                                    margin: '0in 0in 0.0001pt 0px',
                                                    fontSize: '11pt',

                                                    textIndent: '13.9333px'
                                                }}>The Nordic Car Company Ltd. <a name="_Hlk18401678"/>Egerton House, 68
                                                    Baker Street, Weybridge, Surrey, United Kingdom, KT13 8AL.
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <p style={{
                                margin: '0.55pt 0in 0.0001pt',
                                fontSize: '11pt',

                            }}><span style={{fontSize: '11.5pt'}}>&nbsp;</span></p>
                            <p style={{
                                margin: '0in 0in 0.0001pt 43pt',
                                fontSize: '11pt',

                            }}>or as otherwise specified by the relevant party by notice in writing to each other
                                party.</p>
                            <p style={{
                                margin: '0.45pt 0in 0.0001pt',
                                fontSize: '11pt',

                            }}><span style={{fontSize: '13.0pt'}}>&nbsp;</span></p>
                            <ol style={{marginBottom: '0in', marginTop: '0px'}} start={17}>
                                <li style={{
                                    listStyle: 'none',
                                    textAlign: 'left',
                                    margin: '0in 0in 0.0001pt 0px',
                                    fontSize: '11pt',

                                    textIndent: '24px'
                                }}>
                                    <ol style={{marginBottom: '0in', marginTop: '0px'}} start={36}>
                                        <li style={{
                                            textAlign: 'left',
                                            margin: '0in 0in 0.0001pt 0px',
                                            fontSize: '11pt',

                                            textIndent: '24px'
                                        }}>Any notice or other communication shall be deemed to have been duly received:
                                            <ol style={{
                                                listStyleType: 'lower-alpha',
                                                marginTop: '5.8pt',
                                                marginBottom: '.0001pt'
                                            }} start={27}>
                                                <li style={{
                                                    margin: '5.8pt 6.8pt 0.0001pt 0px',
                                                    textAlign: 'left',
                                                    lineHeight: '95%',
                                                    fontSize: '11pt',

                                                    textIndent: '13.9333px'
                                                }}>if delivered personally, when left at the address and for the contact
                                                    referred to in this clause; or
                                                </li>
                                                <li style={{
                                                    margin: '5.85pt 6.6pt 0.0001pt 0px',
                                                    textAlign: 'left',
                                                    lineHeight: '95%',
                                                    fontSize: '11pt',

                                                    textIndent: '13.9333px'
                                                }}>if sent by pre-paid first class post or recorded delivery, at 11.00
                                                    am on the second Business Day after posting; or
                                                </li>
                                                <li style={{
                                                    margin: '5.9pt 6.7pt 0.0001pt 0px',
                                                    textAlign: 'left',
                                                    lineHeight: '95%',
                                                    fontSize: '11pt',

                                                    textIndent: '13.9333px'
                                                }}>if delivered by commercial courier, on the date and at the time that
                                                    the courier's delivery receipt is signed.
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <p style={{
                                margin: '0.35pt 0in 0.0001pt',
                                fontSize: '11pt',

                            }}><span style={{fontSize: '13.5pt'}}>&nbsp;</span></p>
                            <ol style={{marginBottom: '0in', marginTop: '0px'}} start={18}>
                                <li style={{
                                    listStyle: 'none',
                                    margin: '0in 6.75pt 0.0001pt 0px',
                                    textAlign: 'left',
                                    lineHeight: '95%',
                                    fontSize: '11pt',

                                    textIndent: '24px'
                                }}>
                                    <ol style={{marginBottom: '0in', marginTop: '0px'}} start={37}>
                                        <li style={{
                                            margin: '0in 6.75pt 0.0001pt 0px',
                                            textAlign: 'left',
                                            lineHeight: '95%',
                                            fontSize: '11pt',

                                            textIndent: '24px'
                                        }}>A notice or other communication required to be given under this agreement
                                            shall not be validly given if sent by e-mail.
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <p style={{
                                margin: '0.3pt 0in 0.0001pt',
                                fontSize: '11pt',

                            }}><span style={{fontSize: '14.5pt'}}>&nbsp;</span></p>
                            <ol style={{marginBottom: '0in', marginTop: '0px'}} start={30}>
                                <li style={{
                                    textAlign: 'left',
                                    margin: '0in 0in 0.0001pt 0px',
                                    fontSize: '11pt',

                                    textIndent: '24px'
                                }}><strong>N</strong><strong><span
                                    style={{fontSize: '9.0pt'}}>O PARTNERSHIP</span></strong></li>
                            </ol>
                            <p style={{
                                margin: '0.35pt 0in 0.0001pt',
                                fontSize: '11pt',

                            }}><strong><span style={{fontSize: '8.0pt'}}>&nbsp;</span></strong></p>
                            <p style={{
                                margin: '0in 6.65pt 0.0001pt 43pt',
                                textAlign: 'justify',
                                lineHeight: '95%',
                                fontSize: '11pt',

                            }}>Nothing in this agreement is intended to, or shall be deemed to, establish any
                                partnership or joint venture between any of the parties, constitute any party the agent
                                of another party, nor authorise any party to make or enter into any commitments for or
                                on behalf of any other party.</p>
                            <p style={{
                                margin: '0.2pt 0in 0.0001pt',
                                fontSize: '11pt',

                            }}><span style={{fontSize: '14.5pt'}}>&nbsp;</span></p>
                            <ol style={{marginBottom: '0in', marginTop: '0px'}} start={31}>
                                <li style={{
                                    textAlign: 'left',
                                    margin: '0in 0in 0.0001pt 0px',
                                    fontSize: '11pt',

                                    textIndent: '24px'
                                }}><strong>T</strong><strong><span style={{fontSize: '9.0pt'}}>HIRD PARTY RIGHTS</span></strong>
                                </li>
                            </ol>
                            <p style={{
                                margin: '0.25pt 0in 0.0001pt',
                                fontSize: '11pt',

                            }}><strong><span style={{fontSize: '8.0pt'}}>&nbsp;</span></strong></p>
                            <p style={{
                                margin: '0in 6.95pt 0.0001pt 43pt',
                                textAlign: 'justify',
                                lineHeight: '95%',
                                fontSize: '11pt',

                            }}>A person who is not a party to this agreement shall not have any rights under or in
                                connection with it.</p>
                            <p style={{
                                margin: '0.3pt 0in 0.0001pt',
                                fontSize: '11pt',

                            }}><span style={{fontSize: '14.5pt'}}>&nbsp;</span></p>
                            <ol style={{marginBottom: '0in', marginTop: '0px'}} start={32}>
                                <li style={{
                                    textAlign: 'left',
                                    margin: '0in 0in 0.0001pt 0px',
                                    fontSize: '11pt',

                                    textIndent: '24px'
                                }}><strong>G</strong><strong><span style={{fontSize: '9.0pt'}}>OVERNING LAW AND JURISDICTION</span></strong>
                                </li>
                            </ol>
                            <p style={{
                                margin: '0.15pt 0in 0.0001pt',
                                fontSize: '11pt',

                            }}><strong><span style={{fontSize: '9.5pt'}}>&nbsp;</span></strong></p>
                            <ol style={{marginBottom: '0in', marginTop: '0px'}} start={33}>
                                <li style={{
                                    listStyle: 'none',
                                    margin: '0in 6.75pt 0.0001pt 0px',
                                    lineHeight: '95%',
                                    textAlign: 'justify',
                                    fontSize: '11pt',

                                    textIndent: '24px'
                                }}>
                                    <ol style={{marginBottom: '0in', marginTop: '0px'}} start={38}>
                                        <li style={{
                                            margin: '0in 6.75pt 0.0001pt 0px',
                                            lineHeight: '95%',
                                            textAlign: 'justify',
                                            fontSize: '11pt',

                                            textIndent: '24px'
                                        }}>This agreement and any dispute or claim arising out of or in connection with
                                            it or its subject matter or formation (including non-contractual disputes or
                                            claims) shall be governed by and construed in accordance with English law.
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <p style={{
                                margin: '0.4pt 0in 0.0001pt',
                                fontSize: '11pt',

                            }}><span style={{fontSize: '13.5pt'}}>&nbsp;</span></p>
                            <ol style={{marginBottom: '0in', marginTop: '0px'}} start={34}>
                                <li style={{
                                    listStyle: 'none',
                                    margin: '0in 6.6pt 0.0001pt 0px',
                                    lineHeight: '95%',
                                    textAlign: 'justify',
                                    fontSize: '11pt',

                                    textIndent: '24px'
                                }}>
                                    <ol style={{marginBottom: '0in', marginTop: '0px'}} start={39}>
                                        <li style={{
                                            margin: '0in 6.6pt 0.0001pt 0px',
                                            lineHeight: '95%',
                                            textAlign: 'justify',
                                            fontSize: '11pt',

                                            textIndent: '24px'
                                        }}>The parties irrevocably agree that the courts of England and Wales shall have
                                            exclusive jurisdiction to settle any dispute or claim that arises out of or
                                            in connection with this agreement or its subject matter or formation
                                            (including non-contractual disputes or claims).
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <p style={{
                                margin: '0.65pt 0in 0.0001pt',
                                fontSize: '11pt',

                            }}><span style={{fontSize: '13.5pt'}}>&nbsp;</span></p>
                            <p style={{
                                margin: '0in 0in 0.0001pt 7pt',
                                fontSize: '11pt',

                            }}>&nbsp;</p>
                            <p style={{
                                margin: '0in 0in 0.0001pt 7pt',
                                fontSize: '11pt',

                            }}>This agreement has been entered into on the date recorded during the logon process.</p>
                            <p style={{
                                margin: '0in 0in 0.0001pt',
                                fontSize: '11pt',

                            }}><span style={{fontSize: '10.0pt'}}>&nbsp;</span></p>
                            <p style={{
                                margin: '0in 0in 0.0001pt',
                                fontSize: '11pt',

                            }}><span style={{fontSize: '10.0pt'}}>&nbsp;</span></p>
                            <p style={{
                                margin: '0in 0in 0.0001pt',
                                fontSize: '11pt',

                            }}><span style={{fontSize: '10.0pt'}}>&nbsp;</span></p>
                            <p style={{
                                margin: '0.3pt 0in 0.05pt',
                                fontSize: '11pt',

                            }}><span style={{fontSize: '14.0pt'}}>&nbsp;</span></p>
                            <table className="MsoNormalTable" style={{marginLeft: '8.15pt', borderCollapse: 'collapse'}}
                                   border={0} width={570} cellSpacing={0} cellPadding={0}>
                                <tbody>
                                <tr style={{height: '65.5pt'}}>
                                    <td style={{width: '211.6pt', padding: '0in 0in 0in 0in', height: '65.5pt'}}
                                        valign="top" width={282}>
                                        <p style={{
                                            margin: '0in 0in 0.0001pt 10pt',
                                            lineHeight: '10.8pt',
                                            fontSize: '11pt',

                                        }}>Electronically Signed by:</p>
                                        <p style={{
                                            margin: '0in 0in 0.0001pt 10pt',
                                            lineHeight: '15.45pt',
                                            fontSize: '11pt',

                                        }}>&nbsp;</p>
                                    </td>
                                    <td style={{width: '215.55pt', padding: '0in 0in 0in 0in', height: '65.5pt'}}
                                        valign="top" width={287}>
                                        <p style={{
                                            margin: '0in 0in 0.0001pt 37.4pt',
                                            lineHeight: '10.8pt',
                                            fontSize: '11pt',

                                        }}>Damon Peacock</p>
                                        <p style={{
                                            margin: '0in 0in 0.0001pt 37.4pt',
                                            lineHeight: '15.45pt',
                                            fontSize: '11pt',

                                        }}>Managing Director</p>
                                        <p style={{
                                            margin: '0in -24.45pt 0.0001pt 37.4pt',
                                            lineHeight: '15.45pt',
                                            fontSize: '11pt',

                                        }}>The Nordic Car Company Ltd</p>
                                    </td>
                                </tr>
                                <tr style={{height: '65.5pt'}}>
                                    <td style={{width: '211.6pt', padding: '0in 0in 0in 0in', height: '65.5pt'}}
                                        valign="top" width={282}>
                                        <p style={{
                                            margin: '0in 0in 0.0001pt',
                                            fontSize: '11pt',

                                        }}>&nbsp;</p>
                                        <p style={{
                                            margin: '0.25pt 0in 0.0001pt',
                                            fontSize: '11pt',

                                        }}><span style={{fontSize: '13.0pt'}}>&nbsp;</span></p>
                                        <p style={{
                                            margin: '0in 0in 0.0001pt 10pt',
                                            lineHeight: '10.8pt',
                                            fontSize: '11pt',

                                        }}>Electronically Signed via the Logon Process:</p>
                                        <p style={{
                                            margin: '0in 0in 0.0001pt 10pt',
                                            lineHeight: '14.25pt',
                                            fontSize: '11pt',

                                        }}>&nbsp;</p>
                                    </td>
                                    <td style={{width: '215.55pt', padding: '0in 0in 0in 0in', height: '65.5pt'}}
                                        valign="top" width={287}>
                                        <p style={{
                                            margin: '0in 0in 0.0001pt',
                                            fontSize: '11pt',

                                        }}>&nbsp;</p>
                                        <p style={{
                                            margin: '0.25pt 0in 0.0001pt',
                                            fontSize: '11pt',

                                        }}><span style={{fontSize: '13.0pt'}}>&nbsp;</span></p>
                                        <p style={{
                                            margin: '0in 0in 0.0001pt 37.4pt',
                                            lineHeight: '15.45pt',
                                            fontSize: '11pt',

                                        }}>The User of Beta Testing Version 1.0.0</p>
                                        <p style={{
                                            lineHeight: '14.25pt',
                                            margin: '0in 0in 0.0001pt',
                                            fontSize: '11pt',

                                        }}>&nbsp;</p>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <p style={{
                                margin: '0in 0in 0.0001pt',
                                fontSize: '11pt',

                            }}>&nbsp;</p>
                            <p style={{textAlign: 'center'}}><br/><br/></p>
                        </div> : null
                }
                {/* {"http://quickco.imagebin.co.uk/theme/"} */}
                <a href={null}>
                    {/* <img style={{ width: LogoObject.size }} className={"auth-logo"} src={LogoObject.logo} /> */}
                </a>
                <div className={"big " + this.state.styleFor}>
                    {
                        this.state.showLoginForm ? <LoginForm setShowNda={this.showNda.bind(this)}/> : null
                    }
                    {       
                        this.state.showRegForm ? <RegistrationForm allowRegistration={allowRegistration}/> : null
                    }
                </div>
                <div 
                    className={"small " + this.state.styleFor} 
                    style={{
                        backgroundImage: this.state.styleFor != "quickco" ? `url(${LoginSmallImage})` : "", 
                        backgroundRepeat: this.state.styleFor != "quickco" ? 'no-repeat' : ""
                    }}
                >
                    {
                        this.state.styleFor == 'quickco' ?
                            <Slider {...settings}>
                                {images.map((img) => (
                                    <div class="slide-item-wrap">
                                        <div class="slide-item" style={{
                                            backgroundImage: `url(${img.slide})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'center',
                                            backgroundSize: 'cover'
                                        }}>
                                            <img class="slide-logo" width="150px" src={img.logo}/>
                                        </div>
                                    </div>
                                ))}
                            </Slider> : null
                    }
                    {
                        // !this.state.styleFor == 'newNordic' || !this.state.styleFor == 'spartan' &&  this.state.showLoginCaption
                        allowRegistration && this.state.showLoginCaption && this.state.styleFor != 'quickco' ?
                            <div className={"caption caption-register " + this.state.styleFor}>
                                <h1 className={"heading"}>New here?</h1>
                                <p>Enter your business details to<br/> register an account with us</p>
                                <button onClick={() => this.openRegisterForm()}
                                        className={"open-register"}
                                        type={"button"}>
                                            register
                                </button>
                            </div> : null
                    }
                    {
                        this.state.showRegCaption ?
                            <div className={"caption caption-login " + this.state.styleFor}>
                                <h1 className={"heading"}
                                style={{
                                    color: this.state.versionStyles.color
                                }}
                                >Login to your account</h1>
                                <p>If you already have an account, just sign in</p>
                                <button onClick={() => this.openLoginForm()}
                                        className={"open-login"}
                                        type={"button"}
                                        style={{
                                            background: this.state.versionStyles.background 
                                        }}>Login
                                </button>
                            </div> : null
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    settings: state.settings,
})

export default withRouter(connect(
    mapStateToProps,
    null
)(Login));