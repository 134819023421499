import React, { Component } from "react";
import DashboardAnalytics from "admin/administrator/DashboardAnalytics";
// import "admin/administrator/Menu.css";

export default class Menu extends Component
{
    constructor(props)
    {
        super(props);
    }

    render()
    {
        return (
            <div className={"dashboard-wrapper admin-dashboard"}>
                <DashboardAnalytics />
            </div>
        );
    }
}