import React, { Component } from "react";
import SearchByMake from "components/SearchByMake";
import SearchByRegNumber from "components/SearchByRegNumber";
import SearchByPartNumber from "components/SearchByPartNumber";
// import "components/SearchVehicle.css";

export default class SearchVehicle extends Component {
    constructor(props)
    {
        super(props);

        this.state = {
            products: []
        }
    }

    handleProductsListChange(products)
    {
        this.setState({ products: products });
    }

    render()
    {
        return (
            <div className="container">
                <div className="row">
                    <div className="tab-content" id="pills-tabContent">
                        <SearchByMake onProductsListChange={this.handleProductsListChange}/>
                        <SearchByRegNumber/>
                        <SearchByPartNumber />
                    </div>
                </div>
            </div>
        );
    }
}
