import React, {Component, useState, useEffect } from "react";
import NoResult from "images/no-result.png";
import SectionTable from "components/SectionTable";
import _ from 'lodash';

const Products = ({
        products,
        stockLoad,
        section_lookup_id,
        lookup,
        axlesArr
    }) => {
    
    const [showItems, setShowItems] = useState(false);

    const checkSaveNumber = products => {
        setShowItems(false);
        _.map(products, item => {
            if(item.length > 0)
                setShowItems(true);
        });
    }

    useEffect(() => {
        checkSaveNumber(products);
    }, [products]);
    
    return (
        <div className="products">
            {
                showItems ?
                    Object.keys(products).map(section => {
                        return products[section].length > 0 &&
                            <SectionTable stockLoad={stockLoad}
                                        key={section}
                                        section_lookup_id={section_lookup_id}
                                        lookup={lookup}
                                        axlesArr={axlesArr}
                                        section={section}
                                        products={products}/>
                    }) : <div className={"no-result-wrapper"} > </div>
            }
        </div> 
    );
}
export default Products;
