import React, {Component, Fragment} from "react";
import "components/Charts/CustomPieChart2d.css";
import { Pie } from 'react-chartjs-2';

class CustomPieChart2d extends Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }

    render() {

        let {labels, values} = this.props;

        let chartData = {
            labels: labels,
                datasets: [
                {
                    label: 'Population',
                    data: values,
                    backgroundColor:[
                        '#63B6DE',
                        '#6492DF',
                        '#676EE0',
                        '#8064DF',
                        '#A464DF',
                        '#C862DF',
                        '#DE62D0',
                        '#DE65AC',
                        '#DE6588',
                        '#DD4671'
                    ]
                }
            ]
        };

        return (
            <Fragment>
                <Pie
                    data={chartData}
                    width={500}
                    height={300}
                    options={{
                        legend: {
                            display: false
                        }
                    }}
                />
            </Fragment>
        );
    }
}

export default CustomPieChart2d;