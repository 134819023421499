import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import 'components/LoginBtn.css';

class LoginBtn extends Component 
{
    constructor(props)
    {
        super(props);
    }

    render() {
        return (
            <NavLink to="/Login" className="login-link">
                <i className="fas fa-sign-in-alt"></i>
            </NavLink>
        );
    }
}

export default LoginBtn