import React from "react";
import Image from 'images/not_found.png';
import "components/NotFound.css";

// Stateless component
const NotFound = ({data, sections = null}) => {
    return data && (data.length == 0 && sections.length == 0) ? <div className={"not-found-wrapper"}>
            <img src={Image} alt={"not found image"}/>
            <p>Sorry, no items available</p>
        </div> : null
}
  
export default NotFound;