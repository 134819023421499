import React, { Component } from "react";
// import "admin/administrator/BestSellers/BestSellers.css";
// import "admin/administrator/CustomElements.css";
import {withRouter} from 'react-router-dom';
import {Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup} from "react-bootstrap";
import {GridLoader} from 'react-spinners';
import axios from "axios1";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
var moment = require('moment');

class BestSellerDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            productDetailsTotal: [],
            productDetails: [],
            part_number: null
        }
    }

    componentDidMount() {
        let part_number = this.props.match.params.part_number;
        this.setState({part_number: part_number}, () => {
            this.getProductDetails(part_number);
        })
    }

    getProductDetails() {
        axios.get("catalogue/selling-by-part-number/" + this.state.part_number)
            .then((response) => {
                this.setState({productDetails: response.data.sales, productDetailsTotal: response.data.total});
            })
    }

    render() {
        return (
            <div className={"page-content admin-wrapper"}>
                <Container fluid={true}>
                    <Row className="show-grid">
                        <Col md={12}>
                            <div className={"page-title-wrapper"}>
                                <span>best sellers details</span>
                                <button onClick={() => this.props.history.goBack()} className="go-back">
                                    <i className="fas fa-reply"></i> Back
                                </button>
                            </div>
                        </Col>
                    </Row>
                    <Row className="show-grid">
                        <Col md={12}>
                            <div className={"best-sellers-total"}>
                                <div className={"total-wrapper"}>
                                    <p>sales for part no: <strong>{this.props.match.params.part_number}</strong></p>
                                    <p>sales volume total: <strong>{this.state.productDetailsTotal.length > 0 ? this.state.productDetailsTotal[0].quantity : null}</strong></p>
                                    <p>sales value total: <strong>{this.state.productDetailsTotal.length > 0 ? this.state.productDetailsTotal[0].price : null}</strong></p>
                                </div>
                                <div className={"custom-filter"}>
                                    <div className={"filter-group"}>
                                        <label htmlFor="account">Part Number</label>
                                        <input type={"text"} value={this.state.part_number}/>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="show-grid">
                        <Col md={12}>
                            {
                                this.state.productDetails.length > 0 ?
                                    <table className={"custom-table table table-bordered"}>
                                        <thead>
                                        <tr>
                                            <th>date</th>
                                            <th>account name</th>
                                            <th>account type</th>
                                            <th>qty</th>
                                            <th>price</th>
                                            <th>order no</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.productDetails.map((detail) => {
                                                return <tr>
                                                            <td>{moment(detail.date).format("DD-MM-YYYY HH:mm:ss")}</td>
                                                            <td>{detail.account}</td>
                                                            <td>{detail.type}</td>
                                                            <td>{detail.quantity}</td>
                                                            <td>{detail.price}</td>
                                                            <td>
                                                                <NavLink to={"/admin/top-accounts/sale-details/" + detail.order_id} exact>
                                                                    <button className={"custom-btn"}>#{detail.order_id}</button>
                                                                </NavLink>
                                                            </td>
                                                        </tr>
                                            })
                                        }
                                        </tbody>
                                    </table> : null
                            }
                        </Col>
                    </Row>
                </Container>
                {
                    this.state.loading ?
                        <div className='sweet-loading'>
                            <GridLoader
                                sizeUnit={"px"}
                                size={15}
                                color={'#0066b8'}
                                loading={this.state.loading}
                            />
                        </div> : null
                }
            </div>
        )
    }
}

export default withRouter(BestSellerDetails);