import React, {Component, Fragment} from "react";
// import "admin/administrator/SalesMixedChart.css";
import Chart from "react-apexcharts";
import axios from "axios1";
import _ from 'lodash';
import DatePicker from "react-datepicker";
import CustomDatePicker from 'admin/administrator/CustomDatePicker';
import downloadLogo from 'images/download_chart_icon.svg';

var moment = require('moment');

class SalesMixedChart extends Component {

    constructor(props) {
        super(props);

        this.state = {
            current_month: [],
            prev_month: [],
            accounts: [],
            groups: [],
            months: [],
            sections: [],
            reportType: "daily_sales",
            sales: [],
            lookups: [],
            filters: {
                account_id: null,
                group_id: null,
                section_id: null,
                month: null,
                comparison_month: null,
                year: null,
                comparison_year: null,
                quarter: null,
                comparison_quarter: null
            },
            filters2: {
                account_id: null,
                group_id: null,
                section_id: null,
                day: moment().format('YYYY-MM-DD')
            },
            selectedDay: new Date(),
            selectedMonth: new Date(),
            selectedComparisonMonth: new Date(),
            periodType: "month",
            years: []
        }
    }

    componentDidMount() {
        this.getBestSellingFiltres();
    }

    getBestSellingFiltres() {
        axios.get("catalogue/bestselling/filters?periodType=" + this.state.periodType, {params: this.state.filters})
            .then((response) => {
                let accounts = response.data.accounts.map((account) => {
                    return {id: account.id, name: account.name.toUpperCase()}
                });
                let current_month = [];
                let prev_month = [];
                response.data.current_month.map((item) => {
                    current_month.push(item.quantity)
                });
                response.data.prev_month.map((item) => {
                    prev_month.push(item.quantity)
                });
                this.setState({
                    current_month: current_month,
                    prev_month: prev_month,
                    accounts: accounts,
                    groups: response.data.groups
                });
            });
    }

    getSalesAndLookupsByDay() {
        axios.get("catalogue/sales-lookups-hourly", {params: this.state.filters2})
            .then((response) => {
                let sales = response.data.sales.map((item) => {
                    return item.sold;
                });
                let lookups = response.data.lookups.map((item) => {
                    return item.lookedup
                });
                this.setState({
                    sales: sales,
                    lookups: lookups
                });
            });
    }

    handleValueChange(property, event) {
        let filters = {...this.state.filters};
        filters[property] = event.target.value;
        this.setState({filters: filters}, () => {
            this.getBestSellingFiltres();
        });
    }

    handleValueChange2(property, event) {
        let filters2 = {...this.state.filters2};
        filters2[property] = event.target.value;
        this.setState({filters2: filters2}, () => {
            this.getSalesAndLookupsByDay();
        });
    }

    handleGroupChange(event) {
        let filters = {...this.state.filters};
        let sections = [];
        filters['group_id'] = event.target.value;
        filters['section_id'] = null;
        let groups = {...this.state.groups};
        if (parseInt(event.target.value)) {
            let currentGroup = _.find(groups, {'id': parseInt(event.target.value)});
            sections = currentGroup['sections'] ? currentGroup['sections'] : [];
        }

        this.setState({filters: filters, sections: sections}, () => {
            this.getBestSellingFiltres();
        });
    }

    handleGroupChange2(event) {
        let filters2 = {...this.state.filters2};
        let sections = [];
        filters2['group_id'] = event.target.value;
        filters2['section_id'] = null;
        let groups = {...this.state.groups};
        if (parseInt(event.target.value)) {
            let currentGroup = _.find(groups, {'id': parseInt(event.target.value)});
            sections = currentGroup['sections'] ? currentGroup['sections'] : [];
        }

        this.setState({filters2: filters2, sections: sections}, () => {
            this.getSalesAndLookupsByDay();
        });
    }

    handleReportTypeValueChange(event) {
        let reportType = event.target.value;
        this.setState({reportType: reportType}, () => {
            this.getSalesAndLookupsByDay();
        });
    }

    handleChangeDay(day) {
        let filters2 = {...this.state.filters2};
        filters2.day = moment(day).format('YYYY-MM-DD');
        this.setState({filters2: filters2, selectedDay: day}, () => {
            this.getSalesAndLookupsByDay();
        })
    }

    handleChangeMonth(month) {
        let filters = {...this.state.filters};
        filters.month = moment(month).format('YYYY-MM-DD');
        filters.year = null;
        filters.comparison_year = null;
        filters.quarter = null;
        filters.comparison_quarter = null;
        this.setState({filters: filters, selectedMonth: month}, () => {
            if (this.state.filters.month && this.state.filters.comparison_month) {
                this.getBestSellingFiltres();
            }
        })
    }

    handleChangeComparisonMonth(month) {
        let filters = {...this.state.filters};
        filters.comparison_month = moment(month).format('YYYY-MM-DD');
        filters.year = null;
        filters.comparison_year = null;
        filters.quarter = null;
        filters.comparison_quarter = null;
        this.setState({filters: filters, selectedComparisonMonth: month}, () => {
            if (this.state.filters.month && this.state.filters.comparison_month) {
                this.getBestSellingFiltres();
            }
        })
    }

    handleChangeYear(e) {
        let filters = {...this.state.filters};
        filters.year = e.target.value;
        filters.month = e.target.value;
        filters.quarter = null;
        filters.comparison_quarter = null;
        this.setState({filters: filters}, () => {
            if (this.state.filters.year && this.state.filters.comparison_year) {
                this.getBestSellingFiltres();
            }
        })
    }

    handleChangeComparisonYear(e) {
        let filters = {...this.state.filters};
        filters.comparison_year = e.target.value;
        filters.comparison_month = e.target.value;
        filters.quarter = null;
        filters.comparison_quarter = null;
        this.setState({filters: filters}, () => {
            if (this.state.filters.year && this.state.filters.comparison_year) {
                this.getBestSellingFiltres();
            }
        })
    }

    handleChangeQuarter(e) {
        let filters = {...this.state.filters};
        filters.quarter = e.target.value;
        filters.month = e.target.value;
        filters.year = null;
        filters.comparison_year = null;
        this.setState({filters: filters}, () => {
            if (this.state.filters.quarter && this.state.filters.comparison_quarter) {
                this.getBestSellingFiltres();
            }
        })
    }

    handleChangeComparisonQuarter(e) {
        let filters = {...this.state.filters};
        filters.comparison_quarter = e.target.value;
        filters.comparison_month = e.target.value;
        filters.year = null;
        filters.comparison_year = null;
        this.setState({filters: filters}, () => {
            if (this.state.filters.quarter && this.state.filters.comparison_quarter) {
                this.getBestSellingFiltres();
            }
        })
    }

    choosePeriod(e) {
        let years = [];
        let currentYear = moment().year();
        let filters = {...this.state.filters};
        if (e.target.value === "year") {
            for (let i = 0; i < 5; i++) {
                years.push(currentYear - i);
            }
        }
        this.setState({periodType: e.target.value, years: years, filters: filters, current_month: [], prev_month: []});
    }
    render() {
        let current_month = this.state.current_month;
        let prev_moth = this.state.prev_month;
        let current_year = current_month.length > 0 ? current_month[0] : 0;
        let prev_year = prev_moth.length > 0 ? prev_moth[0] : 0;
        let current_quarter = current_month.length > 0 ? current_month[0] : 0;
        let prev_quarter = prev_moth.length > 0 ? prev_moth[0] : 0;
        let sales = this.state.sales;
        let lookups = this.state.lookups;
        let options = {
            chart: {
                toolbar: {
                    tools: {
                        download: '<img src="'+ downloadLogo +'" class="ico-download" width="20">',
                    }
                }
            },
            colors: ['#D9F0FE', '#46B3F6'],
            stroke: {
                width: [0, 2],
                colors: ['#46B3F6']
            },
            title: {
                text: ''
            },
            xaxis: {
              categories: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
            },
            fill: {
                colors: ['#D9F0FE'],
                opacity: 1,
            },
        };
        let optionsYears = {
            chart: {
                id: "years-bar",
                toolbar: {
                    tools: {
                        download: '<img src="'+ downloadLogo +'" class="ico-download" width="20">',
                    }
                }
            },
            xaxis: {
                categories: [this.state.filters.year, this.state.filters.comparison_year]
            }
        };
        let optionsQuarters = {
            chart: {
                id: "quarters-bar",
                toolbar: {
                    tools: {
                        download: '<img src="'+ downloadLogo +'" class="ico-download" width="20">',
                    }
                }
            },
            xaxis: {
                categories: [this.state.filters.quarter, this.state.filters.comparison_quarter]
            }
        };
        let options2 = {
            chart: {
                toolbar: {
                    tools: {
                        download: '<img src="'+ downloadLogo +'" class="ico-download" width="20">',
                    }
                }
            },
            stroke: {
                width: [0, 2],
                colors: ['#46B3F6']
            },
            title: {
                text: ''
            },
            labels: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"],
            fill: {
                colors: ['#D9F0FE'],
                opacity: 0.5
            }
        };
        let series = [{
            name: this.state.filters.month && this.state.filters.comparison_month ? moment(this.state.selectedMonth).format('MMMM') : moment().format('MMMM'),
            type: 'column',
            data: current_month,
            fill: {
                colors: ['#D9F0FE'],
                opacity: 0.5
            }
        }, {
            name: this.state.filters.month && this.state.filters.comparison_month ? moment(this.state.selectedComparisonMonth).format('MMMM') : moment().subtract(1, "month").format('MMMM'),
            type: 'line',
            data: prev_moth,
            fill: {
                colors: ['#D9F0FE'],
                opacity: 0.5
            },
            dataLabels: {
                style: {
                    colors: ['#F44336', '#E91E63', '#9C27B0']
                }
            }
        }];
        let seriesYears = [
            {
                name: "series-years",
                data: [current_year, prev_year]
            }
        ];
        let seriesQuarters = [
            {
                name: "series-quarters",
                data: [current_quarter, prev_quarter]
            }
        ];
        let series2 = [{
            name: "Products Sold",
            type: 'column',
            data: sales,
            dataLabels: {
                style: {
                    colors: ['#F44336', '#E91E63', '#9C27B0']
                }
            }
        }, {
            name: "Lookups",
            type: 'line',
            data: lookups
        }];
        return (
            <div className={"custom-wrapper"}>
                <div className={"custom-heading"}>
                    <span>Sales / Lookups</span>
                </div>
                {
                    this.state.reportType == "daily_sales" ?
                        <Fragment>
                            <div className={"custom-filter"} style={{marginBottom: '10px'}}>
                                <div className={"filter-group"}>
                                    <label>Report Type</label>
                                    <select className={"bestsellers-select"}
                                            onChange={this.handleReportTypeValueChange.bind(this)}
                                            value={this.state.reportType}
                                    >
                                        <option value={"daily_sales"}>Sales v. Past Sales</option>
                                        <option value={"hourly_sales"}>Hourly Sales v. Lookups</option>
                                    </select>
                                </div>
                                <div className={"filter-group"}>
                                    <label>Account</label>
                                    <select className="bestsellers-select textUppercase"
                                            onChange={this.handleValueChange.bind(this, "account_id")}
                                            value={this.state.filters.account_id}>
                                        <option value="">All Accounts</option>
                                        {
                                            this.state.accounts.map((account) => {
                                                return <option key={account.id}
                                                               value={account.id}>{account.name}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className={"filter-group"}>
                                    <label>Product Group</label>
                                    <select className={"bestsellers-select"}
                                            onChange={this.handleGroupChange.bind(this)}>
                                        <option value="">All Groups</option>
                                        {
                                            this.state.groups.map((group) => {
                                                return <option key={group.id} value={group.id}>{group.name}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className={"filter-group"}>
                                    <label>Product Section</label>
                                    <select className={"bestsellers-select"}
                                            onChange={this.handleValueChange.bind(this, 'section_id')}>
                                        <option value="">All Sections</option>
                                        {
                                            this.state.sections.map((section) => {
                                                return <option key={section.id}
                                                               value={section.id}>{section.name}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className={"filter-group"}>
                                    <label>Period</label>
                                    <select className={"bestsellers-select"} onChange={this.choosePeriod.bind(this)}>
                                        <option value={"month"}>Month</option>
                                        <option value={"quarter"}>Quarter</option>
                                        <option value={"year"}>Year</option>
                                    </select>
                                </div>
                                {
                                    (this.state.periodType == "month") ?
                                        <Fragment>
                                            <div className={"filter-group"}>
                                                <label>Month</label>
                                                <CustomDatePicker value={this.state.filters.month}
                                                                  selected={this.state.selectedMonth}
                                                                  placeholder={"Month"}
                                                                  onChange={this.handleChangeMonth.bind(this)}
                                                                  format={"MMMM YYYY"}
                                                                  showMonthYearPicker={true}></CustomDatePicker>
                                            </div>
                                            <div className={"filter-group"}>
                                                <label>Comparison Month</label>
                                                <CustomDatePicker value={this.state.filters.comparison_month}
                                                                  selected={this.state.selectedComparisonMonth}
                                                                  placeholder={"Month"}
                                                                  onChange={this.handleChangeComparisonMonth.bind(this)}
                                                                  format={"MMMM YYYY"}
                                                                  showMonthYearPicker={true}></CustomDatePicker>
                                            </div>
                                        </Fragment>
                                        : (this.state.periodType == "quarter") ?
                                        <Fragment>
                                            <div className={"filter-group"}>
                                                <label>Quarter</label>
                                                <select onChange={this.handleChangeQuarter.bind(this)} className={"bestsellers-select"}>
                                                    <option value={""}>choose quarter</option>
                                                    <option value={"1"}>Quarter 1</option>
                                                    <option value={"2"}>Quarter 2</option>
                                                    <option value={"3"}>Quarter 3</option>]
                                                    <option value={"4"}>Quarter 4</option>
                                                </select>
                                            </div>
                                            <div className={"filter-group"}>
                                                <label>Comparison Quarter</label>
                                                <select onChange={this.handleChangeComparisonQuarter.bind(this)} className={"bestsellers-select"}>
                                                    <option value={""}>choose quarter</option>
                                                    <option value={"1"}>Quarter 1</option>
                                                    <option value={"2"}>Quarter 2</option>
                                                    <option value={"3"}>Quarter 3</option>]
                                                    <option value={"4"}>Quarter 4</option>
                                                </select>
                                            </div>
                                        </Fragment>
                                        :
                                        <Fragment>
                                            <div className={"filter-group"}>
                                                <label>Year</label>
                                                <select onChange={this.handleChangeYear.bind(this)}
                                                        className={"bestsellers-select"}>
                                                    <option value={""}>choose year</option>
                                                    {
                                                        this.state.years.map((year) => {
                                                            return <option value={year}>{year}</option>;
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className={"filter-group"}>
                                                <label>Comparsion Year</label>
                                                <select onChange={this.handleChangeComparisonYear.bind(this)}
                                                        className={"bestsellers-select"}>
                                                    <option value={""}>choose year</option>
                                                    {
                                                        this.state.years.map((year) => {
                                                            return <option value={year}>{year}</option>;
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </Fragment>

                                }
                            </div>
                            {
                                this.state.periodType == "year" && this.state.filters.year && this.state.filters.comparison_year ?
                                    <Chart
                                        options={optionsYears}
                                        series={seriesYears}
                                        type="bar"
                                        height="400"
                                        width="100%"
                                    />
                                :
                                    this.state.periodType == "quarter" && this.state.filters.quarter && this.state.filters.comparison_quarter ?
                                    <Chart
                                        options={optionsQuarters}
                                        series={seriesQuarters}
                                        type="bar"
                                        height="400"
                                        width="100%"
                                    />
                                :
                                    <Chart options={options}
                                           series={series}
                                           type="line"
                                           height="400"
                                           width="100%"
                                    />
                            }

                        </Fragment>
                        :
                        <Fragment>
                            <div className={"custom-filter"}>
                                <div className={"filter-group"}>
                                    <label>Report Type</label>
                                    <select className={"bestsellers-select"}
                                            onChange={this.handleReportTypeValueChange.bind(this)}
                                            value={this.state.reportType}
                                    >
                                        <option value={"daily_sales"}>Sales v. Past Sales</option>
                                        <option value={"hourly_sales"}>Hourly Sales v. Lookups</option>
                                    </select>
                                </div>
                                <div className={"filter-group"}>
                                    <label>Account</label>
                                    <select className="bestsellers-select textUppercase"
                                            onChange={this.handleValueChange2.bind(this, "account_id")}
                                            value={this.state.filters2.account_id}>
                                        <option value="">All Accounts</option>
                                        {
                                            this.state.accounts.map((account) => {
                                                return <option key={account.id}
                                                               value={account.id}>{account.name}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className={"filter-group"}>
                                    <label>Day</label>
                                    <DatePicker
                                        selected={this.state.selectedDay}
                                        onChange={this.handleChangeDay.bind(this)}
                                        name="day"
                                        value={this.state.filters2.day}
                                        placeholderText="Choose Day"
                                    />
                                </div>
                                <div className={"filter-group"}>
                                    <label>Product Group</label>
                                    <select className={"bestsellers-select"}
                                            onChange={this.handleGroupChange2.bind(this)}>
                                        <option value="">All Groups</option>
                                        {
                                            this.state.groups.map((group) => {
                                                return <option key={group.id} value={group.id}>{group.name}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className={"filter-group"}>
                                    <label>Product Section</label>
                                    <select className={"bestsellers-select"}
                                            onChange={this.handleValueChange2.bind(this, 'section_id')}>
                                        <option value="">All Sections</option>
                                        {
                                            this.state.sections.map((section) => {
                                                return <option key={section.id}
                                                               value={section.id}>{section.name}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <Chart options={options2}
                                   series={series2}
                                   type="line"
                                   height="400"
                                   width="100%"
                            />
                        </Fragment>
                }
            </div>
        )
    }
}

export default SalesMixedChart;