import React from 'react';
// import "./Totals.css";
import { useTranslation } from 'react-i18next';

function Totals({title, licencesUsed, licencesCost, lookupsUsed, lookupsCost, total}) {
    const { t, i18n } = useTranslation();
    return (
        <div className={"mt-24"}>
            <div className={"fw-700 mb-8"}>{title} {t("Totals")}</div>
            <div>
                <div className={"flex mb-4"}>
                    <span>{t("Licences used")} x {licencesUsed}</span>
                    <span>{licencesCost}</span>
                </div>
                <div className={"flex mb-4"}>
                    <span>{t("VRM Lookups")} x {lookupsUsed}</span>
                    <span>{lookupsCost}</span>
                </div>
            </div>
            <div className={"flex fw-700 text-uppercase"}>
                <span>{t("Total")}</span>
                <span>{total}</span>
            </div>
        </div>
    );
}

export default Totals;