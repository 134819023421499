import React, {Component} from "react";
// import "admin/administrator/LostSalesWrapper.css";
// import "admin/administrator/CustomElements.css";
import "react-datepicker/dist/react-datepicker.css";
import {Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup} from "react-bootstrap";
import LostSaleDetails from "admin/administrator/LostSaleDetails";
import CustomDatePicker from 'admin/administrator/CustomDatePicker';
import axios from "axios1";
var moment = require('moment');

class LostSalesWrapper extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modalShow: false,
            detail: "",
            lostSales: [],
            lostSalesAll: [],
            lostSaleDetails: [], 
            filters: {
                account_id: null,
                date_from: "",
                date_to: "",
                group_id: null,
                section_id: null
            },
            selectedDate: {
                date_from: new Date(),
                date_to: new Date()
            },
            selectedDateModal: {
                date_from: new Date(),
                date_to: new Date()
            },
            accounts: [],
            groups: [],
            sections: []
        }
    }

    componentDidMount() {
        this.getLostSalesLimited();
        this.getAccounts();
        //this.getGroups();
    }

    handleModalShow() {
        this.setState({modalShow: true})
    }

    handleCloseModalInfoShow() {
        this.setState({modalShow: false, detail: "", lostSalesAll: [], lostSaleDetails: []});
    }

    showLostSaleDetails(detail, date_for_user) {
        axios.get("catalogue/lost-sales-stats-details", {params: {detail: detail}})
            .then((response) => {
                let lostSaleDetails = {
                    date: date_for_user,
                    group: response.data.group,
                    image: response.data.image,
                    section: response.data.section,
                    stock: response.data.stock,
                    section_image: response.data.section_image,
                    stats: response.data.stats
                };
                this.setState({modalShow: true, detail: detail, lostSaleDetails: lostSaleDetails})
            })
    }

    getLostSalesLimited() {
        axios.get("catalogue/lost-sales-stats", {params: {limit: 10}})
            .then((response) => {
                if(response.data.hasOwnProperty('success')&& response.data.success) {
                    this.setState({lostSales: response.data.stats, groups: response.data.groups});
                }
            })
    }
  
    getLostSalesAll() {
        axios.get("catalogue/lost-sales-stats")
            .then((response) => {
                this.setState({lostSalesAll: response.data.stats, groups: response.data.groups});
            })
    }

    handleChange(key, event){
        let filters = this.state.filters;
        filters[key] = event.target.value;
        if(key === "group_id") {
            filters['section_id'] = null;
        }
        this.setState({filters: filters}, () => {
            this.filterLostSales();
        });
    }

    handleChangeDate(key, date) {
        let selectedDate = this.state.selectedDate;
        let filters = this.state.filters;
        filters[key] = moment(date).format('YYYY-MM-DD');
        selectedDate[key] = date;
        this.setState({filters: filters, selectedDate: selectedDate}, () => {
            this.filterLostSales();
        });
    }

    handleChangeModal(key, event){
        let filters = this.state.filters;
        filters[key] = event.target.value;
        if(key === "group_id") {
            filters['section_id'] = null;
        }
        this.setState({filters: filters}, () => {
            this.filterLostSalesModal();
        });
    }

    handleChangeDateModal(key, date) {
        let selectedDateModal = this.state.selectedDateModal;
        let filters = this.state.filters;
        filters[key] = moment(date).format('YYYY-MM-DD');
        selectedDateModal[key] = date;
        this.setState({filters: filters, selectedDateModal: selectedDateModal}, () => {
            this.filterLostSalesModal();
        });
    }

    filterLostSales() {
        let data = this.state.filters;
        data['limit'] = 10;
        axios.get("catalogue/lost-sales-stats", {params: data})
            .then((response) => {
                this.setState({lostSales: response.data.stats, groups: response.data.groups, sections: response.data.sections});
            })
    }

    filterLostSalesModal() {
        let data = this.state.filters;
        axios.get("catalogue/lost-sales-stats", {params: data})
            .then((response) => {
                this.setState({lostSalesAll: response.data.stats, groups: response.data.groups, sections: response.data.sections});
            })
    }

    getAccounts() {
        axios.get("catalogue/view-accounts")
            .then((response) => {
                let accounts = response.data.accounts.map((account) => {
                    return {accountId: account.accountId, accountName: account.accountName.toUpperCase()}
                });
                this.setState({accounts: accounts})
            });
    }

    getGroups() {
        axios.get("catalogue/groups")
            .then((response) => {
                this.setState({groups: response.data});
            });
    }

    getSectionsByGroupId(groupId) {
        axios.get("catalogue/preferences/sections", {params: {groupId: groupId}})
            .then((response) => {
                this.setState({sections: response.data});
            })
    }

    render() {
        return (
            <div className={"custom-wrapper"}>
                <div className={"custom-heading"}>
                    <span>lost sales</span>
                </div>
                <div className={"custom-filter"}>
                    <div className={"filter-group"}>
                        <label for="account">Account</label>
                        <select className={"textUppercase"} onChange={this.handleChange.bind(this, 'account_id')}>
                            <option value={""}>All Accounts</option>
                            {
                                this.state.accounts.map((account) => {
                                    return <option key={account.id} value={account.accountId}>{account.accountName}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className={"filter-group"}>
                        <label htmlFor="account">From</label>
                        <CustomDatePicker value={this.state.filters['date_from']} selected={this.state.selectedDate.date_from} placeholder={"Date From"} onChange={this.handleChangeDate.bind(this, "date_from")} format={"DD-MM-YYYY"}></CustomDatePicker>
                    </div>
                    <div className={"filter-group"}>
                        <label htmlFor="account">To</label>
                        <CustomDatePicker value={this.state.filters['date_to']} selected={this.state.selectedDate.date_to} placeholder={"Date To"} onChange={this.handleChangeDate.bind(this, "date_to")} format={"DD-MM-YYYY"}></CustomDatePicker>
                    </div>
                    <div className={"filter-group"}>
                        <label htmlFor="account">Product Group</label>
                        <select onChange={this.handleChange.bind(this, 'group_id')}>
                            <option value={""}>All Groups</option>
                            {
                                this.state.groups.map((group) => {
                                    return <option
                                        key={group.group_id}
                                        value={group.group_id}
                                    >{group.group_name}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className={"filter-group"}>
                        <label htmlFor="account">Product Section</label>
                        <select onChange={this.handleChange.bind(this, 'section_id')}>
                            <option value={""}>All Sections</option>
                            {
                                this.state.sections.map((section) => {
                                    return <option
                                        key={section.section_id}
                                        value={section.section_id}
                                    >{section.section_name}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                {
                    this.state.lostSales.length > 0 ?
                        <table className={"custom-table table table-bordered"}>
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>Account</th>
                                <th>Section</th>
                                <th>Info</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.lostSales.map((lostSale) => {
                                    let detail = lostSale.date + "_" + lostSale.account_id + "_" + lostSale.section_id;
                                    return <tr>
                                                <td>{lostSale.date_for_user}</td>
                                                <td>{lostSale.account}</td>
                                                <td>{lostSale.section}</td>
                                                <td>
                                                    <button
                                                        onClick={this.showLostSaleDetails.bind(this, detail, lostSale.date_for_user)}
                                                        className={"custom-btn"}
                                                    >
                                                        <i className="fas fa-info-circle"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                })
                            }
                            </tbody>
                        </table> : null
                }
                <div className={"lost-sales-all"}>
                    <button onClick={this.handleModalShow.bind(this)} className={"custom-btn"}>
                        View All
                    </button>
                </div>
                <Modal
                    {...this.props}
                    show={this.state.modalShow}
                    // size="lg"
                    centered
                    dialogClassName='lost-sale-custom-dialog'
                    onEnter={this.getLostSalesAll.bind(this)}
                    onHide={this.handleCloseModalInfoShow.bind(this)}
                >
                    <Modal.Header>
                        <Modal.Title>
                            <div>
                                <span className={"custom-heading mb-none"}>LOST SALE: {this.state.lostSaleDetails.date}</span>
                            </div>
                        </Modal.Title>
                        <a className={"modal-close"} onClick={this.handleCloseModalInfoShow.bind(this)}>
                            <i className="far fa-times-circle"></i>
                        </a>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.detail === "" ?
                                <div className={"lost-sales-all"}>
                                    <Container fluid={true}>
                                        <Row className="show-grid">
                                            <Col md={12}>
                                                <div className={"custom-filter"}>
                                                    <div className={"filter-group"}>
                                                        <label htmlFor="account">Account</label>
                                                        <select className={"textUppercase"} onChange={this.handleChangeModal.bind(this, 'account_id')}>
                                                            <option value={""}>All Account</option>
                                                            {
                                                                this.state.accounts.map((account) => {
                                                                    return <option key={account.id}
                                                                                   value={account.accountId}>{account.accountName}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className={"filter-group"}>
                                                        <label htmlFor="account">From</label>
                                                        <CustomDatePicker value={this.state.filters['date_from']} selected={this.state.selectedDateModal.date_from} placeholder={"Date From"} onChange={this.handleChangeDateModal.bind(this, "date_from")} format={"DD-MM-YYYY"}></CustomDatePicker>
                                                    </div>
                                                    <div className={"filter-group"}>
                                                        <label htmlFor="account">To</label>
                                                        <CustomDatePicker value={this.state.filters['date_to']} selected={this.state.selectedDateModal.date_to} placeholder={"Date To"} onChange={this.handleChangeDateModal.bind(this, "date_to")} format={"DD-MM-YYYY"}></CustomDatePicker>
                                                    </div>
                                                    <div className={"filter-group"}>
                                                        <label htmlFor="account">Product Group</label>
                                                        <select onChange={this.handleChangeModal.bind(this, 'group_id')}>
                                                            <option value={""}>all</option>
                                                            {
                                                                this.state.groups.map((group) => {
                                                                    return <option
                                                                        key={group.group_id}
                                                                        value={group.group_id}
                                                                    >{group.group_name}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className={"filter-group"}>
                                                        <label htmlFor="account">Product Section</label>
                                                        <select onChange={this.handleChangeModal.bind(this, 'section_id')}>
                                                            <option value={""}>all</option>
                                                            {
                                                                this.state.sections.map((section) => {
                                                                    return <option
                                                                        key={section.section_id}
                                                                        value={section.section_id}
                                                                    >{section.section_name}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="show-grid">
                                            <Col md={12}>
                                                {
                                                    this.state.lostSalesAll.length > 0 ?
                                                        <table className={"custom-table table table-bordered"}>
                                                            <thead>
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>Account</th>
                                                                <th>Section</th>
                                                                <th>Info</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                this.state.lostSalesAll.map((lostSale) => {
                                                                    let detail = lostSale.date + "_" + lostSale.account_id + "_" + lostSale.section_id;
                                                                    return <tr>
                                                                            <td>{lostSale.date_for_user}</td>
                                                                            <td>{lostSale.account}</td>
                                                                            <td>{lostSale.section}</td>
                                                                            <td>
                                                                                <button
                                                                                    onClick={this.showLostSaleDetails.bind(this, detail,lostSale.date_for_user)}
                                                                                    className={"custom-btn"}
                                                                                >
                                                                                    Details
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                })
                                                            } 
                                                            </tbody>
                                                        </table> : null
                                                }
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                                : <LostSaleDetails stats={this.state.lostSaleDetails}/>
                        }
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

export default LostSalesWrapper;