import React, { Fragment } from 'react';
import "./MobileNav.css";
import {BrowserRouter as Router, Route, NavLink} from "react-router-dom";
import ShoppingCartIcon from "components/ShoppingCartIcon";
import CompareItemIcon from "components/CompareItemIcon";
import LogoutBtn from 'components/Login/LogoutBtn';

function MobileNav({user, isOnMyOrders, outstandingOrdersQty, enable_shopping_cart}) {

    function toggleHamburger(className) {
        let hamburgerContainer = document.querySelector('.hamburger-container');
        if(hamburgerContainer.classList.contains(className)) {
            hamburgerContainer.classList.remove(className);
        } else {
            hamburgerContainer.classList.add(className);
        }
    }

    function closeHamburger(className) {
        let hamburgerContainer = document.querySelector('.hamburger-container');
        hamburgerContainer.classList.remove(className);
    }

    return (
        <Fragment>
            <div className="hamburger-container">
                <div className="hamburger-wrap" onClick={() => toggleHamburger('active')}>
                    <div className="hamburger">
                        <div className="bar">
                            <span className="line"></span>
                            <span className="line"></span>
                            <span className="line"></span>
                        </div>
                    </div>
                    <div className="hamburger-text">menu</div>
                </div>
                <div className="nav-overlay" onClick={() => closeHamburger('active')}></div>
                <div className="nav-container">
                    <div className={"actions-content"}>
                        {
                            user && (user.admin || user.account.business_type_id == 3) ?
                                <Fragment>
                                    <NavLink
                                        to={user.account.business_type_id == 3 && !user.admin ? "/admin/branch/" + user.account.id + "/" + user.account.name + "/dashboard/" : "/admin/client/dashboard"}
                                        exact
                                        className={"topIconsLinks"}
                                        activeClassName="active"
                                    >
                                        <span className={"icon-dashboard"}></span>
                                        Dashboard
                                    </NavLink>
                                </Fragment> : null
                        }
                        <NavLink to={"/search"} className={"topIconsLinks"}
                                    activeClassName="active"> <span
                            className={"icon-book"}></span>
                            Catalogue
                        </NavLink>
                        <NavLink to={" "}
                                    className={"topIconsLinks " + (isOnMyOrders ? "nav nav-pills" : "nav nav-pills")}>
                            <NavLink to="/account"
                                        data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
                                        aria-expanded="false"
                                        className="profile-icon"><span
                                className="icon-User notification-icon"></span>
                                My Account
                            </NavLink>
                            <div className="dropdown-menu">
                                <div className="dropdown-div-profile">
                                    <h5 className={"dropdown-div-profile-item"}>{(user) ? user.name : ""}</h5>
                                    <p className={"dropdown-div-profile-item"}>{(user) ? user.email : ""}</p>
                                </div>
                                <div
                                    className={isOnMyOrders ? "dropdown-div-item with-bottom-border active" : "dropdown-div-item with-bottom-border"}>
                                    <ul className={"account-pages"}>
                                        <li>
                                            {
                                                user && user.admin ?
                                                    <NavLink to="/account/orders">
                                                        Orders
                                                        {
                                                            user && user.admin ?
                                                                    <div className={"outstanding-orders"}>
                                                                        {outstandingOrdersQty}
                                                                    </div>
                                                                    : null
                                                        }
                                                    </NavLink>
                                                    : <NavLink to="/account/my-orders">My Orders</NavLink>
                                            }
                                        </li>
                                        <li>
                                            <hr/>
                                        </li>
                                        <li>
                                            <LogoutBtn size={{
                                                width: '100%',
                                            }}></LogoutBtn>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </NavLink>
                        <NavLink to="/compareItems" className="topIconsLinks"
                                    activeClassName="active"><CompareItemIcon/>
                            Compare
                        </NavLink>
                        {
                            enable_shopping_cart ?
                                <NavLink to="/Checkout"
                                            className="topIconsLinks"><ShoppingCartIcon/>
                                    Basket
                                </NavLink> : null
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default MobileNav