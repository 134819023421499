import React, {Fragment} from "react";
import "components/FileName.css";

const FileName = (props) => {
    return (
        <Fragment>
            <span className={"file-name mr-8"}>{props.fileName}</span>
        </Fragment>
    )
}

export default FileName;