import React, { Component } from 'react';
import { connect } from "react-redux";
import "components/CatalogueMenu.css";

class CatalogueMenu extends Component {
    render() {
        let user = this.props.user;
        return (
            <div className="catalogueMenu">
                {/*<div className={"layer"}>*/}
                    {/*<h1 className={"heading"}>find your part</h1>*/}
                {/*</div>*/}
                {/*<div className="container">*/}
                    {/*<div className="row">*/}
                        {/*<div className="right">*/}
                            {/*/!*<div className={"choose_search_option_label heading"}>Choose search option</div>*!/*/}
                            {/*<ul className="nav nav-pills" id="pills-tab" role="tablist">*/}
                                {/*<li className="nav-item">*/}
                                    {/*<NavLink exact to="/search" className="searchType nav-link" activeClassName="active">*/}
                                        {/*<img className={"catalogueMenuIcon"} src={PlateIcon} />*/}
                                        {/*<p className={"search-type-label"}>BY PLATE</p>*/}
                                    {/*</NavLink>*/}
                                {/*</li>*/}
                                {/*<li className="nav-item">*/}
                                    {/*<NavLink to="/search/Vehicle" className="searchType nav-link" activeClassName="active">*/}
                                        {/*<img className={"catalogueMenuIcon"} src={VehicleIcon} />*/}
                                        {/*<p className={"search-type-label"}>BY VEHICLE</p>*/}
                                    {/*</NavLink>*/}
                                {/*</li>*/}
                                {/*<li className="nav-item">*/}
                                    {/*<NavLink to="/search/PartNumber" className="searchType nav-link" activeClassName="active">*/}
                                        {/*<img className={"catalogueMenuIcon"} src={PartNumberIcon} />*/}
                                        {/*<p className={"search-type-label"}>BY PART NUMBER</p>*/}
                                    {/*</NavLink>*/}
                                {/*</li>*/}
                            {/*</ul>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

export default connect(mapStateToProps)(CatalogueMenu)
