import React, {Component} from "react";
// import "admin/administrator/lookup_totals/LookupTotals.css";
// import "admin/administrator/CustomElements.css";
import {BrowserRouter as Router, Route, NavLink} from "react-router-dom";
import axios from "axios1";
import _ from "lodash";
import CustomPagination from "admin/administrator/CustomPagination";
import CustomPieChart from 'components/Charts/CustomPieChart';
import PageTitle from 'admin/administrator/PageTitle';
import {GridLoader} from 'react-spinners';
import DatePicker from "react-datepicker";
import CustomPieChart2d from "components/Charts/CustomPieChart2d";
import CustomDatePicker from 'admin/administrator/CustomDatePicker';
import { connect } from "react-redux";
import {selectLookupsMonth} from "redux/actions/statisticActions";

var moment = require('moment');

class LookupTotals extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: {
                account_id: null,
                month: "",
            },
            selectedDate: new Date(),
            data: [],
            page: 1,
            pages: 0,
            accounts: [],
            dataLabelsForChart: [],
            dataValuesForChart: [],
            loading: false,
        }
    }

    componentDidMount() {
        this.setState({loading: true});
        this.initMonth('month');
        this.LookupsByAccounts();
    }

    LookupsByAccounts(page = 1) {
        var filters = {...this.state.filters};
        axios.get("/catalogue/lookups-by-accounts?page=" + page, {params: filters})
            .then(response => {
                var accounts = response.data.accounts.map((account) => {
                    return {id: account.id, name: account.name.toUpperCase()}
                });
                var lookups = response.data.loockups;
                let dataLabelsForChart = ["PartNumber", "Vehicle", "VRM"];
                var dataValuesForChart = [_.sumBy(lookups, e => parseInt(e.partNumber)), _.sumBy(lookups, e => parseInt(e.vehicle)), _.sumBy(lookups, e => parseInt(e.vrm))];
                this.setState({
                    data: lookups,
                    accounts: accounts,
                    page: page,
                    pages: response.data.pages,
                    dataLabelsForChart: dataLabelsForChart,
                    dataValuesForChart: dataValuesForChart,
                    loading: false
                });
            })
    }

    handleChange(key, event) {
        let filters = this.state.filters;
        let value = event.target.value;
        filters[key] = (value != "all") ? value : null;
        this.setState({filters: filters}, () => {
            this.LookupsByAccounts();
        });
    }

    handleChangeDate(key, date) {
        let filters = this.state.filters;
        filters[key] = moment(date).format('YYYY-MM');
        this.props.dispatch(selectLookupsMonth(date))
        this.setState({filters: filters, selectedDate: date}, () => {
            this.LookupsByAccounts();
        });
    }

    initMonth(key = 'month') {
        var month = this.props.month ? moment(this.props.month).toDate() : this.state.selectedDate; 
        var filters = this.state.filters;
        filters[key] = moment(month).format('YYYY-MM');
        this.setState({selectedDate: month, filters: filters});
    }

    render() {
        return (
            <div className={"page-content admin-wrapper"}>

                <div className={"container-fluid"}>
                    <div className={"lookups-page-wrapper"}>
                        {
                            this.state.loading ?
                                <div className='sweet-loading'>
                                    <GridLoader
                                        sizeUnit={"px"}
                                        size={15}
                                        color={'#0066b8'}
                                        loading={this.state.loading}
                                    />
                                </div> : null
                        }

                        <div className={"row"}>
                            <div className={"col-lg-12"}>
                                <div class="page-title-wrapper">
                                    <span>lookup totals</span>
                                </div>
                                <div className={"lookups-filter"}>
                                    <div className={"custom-filter"}>
                                        <div className={"filter-group"}>
                                            <label htmlFor="account">Account</label>
                                            <select className={"textUppercase"} onChange={this.handleChange.bind(this, 'account_id')}>
                                                <option value={""}>All Accounts</option>
                                                {
                                                    this.state.accounts.map((account) => {
                                                        return <option key={account.id}
                                                                       value={account.id}>{account.name}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className={"filter-group"}>
                                            <label htmlFor="account">Month</label>
                                            <CustomDatePicker value={this.state.filters['month']} selected={this.state.selectedDate} placeholder={"Month"} onChange={this.handleChangeDate.bind(this, "month")} format={"MMMM YYYY"} showMonthYearPicker={true}></CustomDatePicker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-lg-6"}>
                                {
                                    this.state.data.length > 0 ?
                                        <table className={"custom-table table table-bordered"}>
                                            <thead>
                                            <tr>
                                                <th>ACCOUNT NAME</th>
                                                <th>ACCOUNT TYPE</th>
                                                <th>VRM LOOKUPS</th>
                                                <th>VEHICLE LOOKUPS</th>
                                                <th>PART NO LOOKUPS</th>
                                                <th>INFO</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.data.map((lookup) => {
                                                    return <tr>
                                                        <td>{lookup.name}</td>
                                                        <td>{lookup.type}</td>
                                                        <td>{lookup.vrm}</td>
                                                        <td>{lookup.vehicle}</td>
                                                        <td>{lookup.partNumber}</td>
                                                        <td>
                                                            <NavLink to={"/admin/lookup-totals/logins/" + lookup.id}
                                                                     exact>
                                                                <button className={"custom-btn"}>Details</button>
                                                            </NavLink>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table> : null
                                }
                                {
                                    this.state.pages > 0 ?
                                        <CustomPagination
                                            page={this.state.page}
                                            pages={this.state.pages}
                                            sendRequest={this.LookupsByAccounts.bind(this)}
                                        /> : null
                                }
                            </div>
                            <div className={"col-lg-6"}>
                                <CustomPieChart2d labels={this.state.dataLabelsForChart}
                                                  values={this.state.dataValuesForChart}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    month: state.statistic.lookupsMonth
})

export default connect(mapStateToProps)(LookupTotals);