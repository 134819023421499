import React, {Component} from "react";
import {Doughnut} from 'react-chartjs-2';
import 'chartjs-plugin-labels'

export default class OrderStatusTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let info = this.props.orderDetails;
        let products = this.props.products;
        let totalProductsPrices = 0;
        return (
            <div style={{paddingTop: "24px"}}>
                <div className="row mb-5">
                    <div className={"col invoice"}>
                        <h6>Invoice to</h6>
                        <p>{info.address_1}</p>
                        <p>{info.address_2}</p>
                        <p>{info.town}</p>
                        <p>{info.postal_code}</p>

                    </div>
                    <div className={"col text-right order-details"}>
                        <p><span className={'boldText text-left'}>Order Date:</span> {info.date}</p>
                        <p><span className={'boldText'}>Order ID:</span> #{info.order_id}</p>
                    </div>
                </div>
                <div className="row mb-5">
                    <table class="table table-striped order-details-table">
                        <thead>
                        <tr>
                            <th scope="col">Part Number</th>
                            <th scope="col">Lookup</th>
                            <th scope="col">Description</th>
                            <th scope="col">Location</th>
                            <th scope="col">Qty</th>
                            <th scope="col">Unit Price</th>
                            <th scope="col">Total Price</th>
                        </tr>
                        </thead>
                        <tbody>

                        {

                            products.map((product) => {
                                totalProductsPrices += parseFloat(product.total);
                                return <tr>
                                    <td>{product.part_number}</td>
                                    <td>{product.lookup.toUpperCase()}</td>
                                    <td>{product.section}</td>
                                    <td>{product.location}</td>
                                    <td>{product.quantity}</td>
                                    <td>£ {product.price}</td>
                                    <td>£ {product.total}</td>
                                </tr>
                            })
                        }
                        </tbody>
                    </table>
                </div>

                <div className={"row"}>
                    {
                        info.additional_notes ? 
                        <div className="col text-left">
                            <div className={"additional-notes-wrapper"}>
                                {info.additional_notes}
                            </div>
                        </div>
                        : null
                    }                   
                    <div className={"col text-right"}>
                        <p><span className={'boldText text-left'}>Total:</span><span style={{color:'#808080',fontSize:'14px'}}> £{totalProductsPrices.toFixed(2)}</span></p>
                    </div>
                </div>

            </div>
        )
    }
}
