import React, { Component } from "react";
// import "admin/administrator/ManageBrands.css";
import $ from "jquery";
import _ from "lodash";
import 'jquery-ui/ui/widgets/sortable';
import axios from "axios1";
import Information from "images/information.svg";
import { GridLoader } from 'react-spinners';
import alertify from "alertifyjs";

export default class ManageHubs extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            branches: [],
            allBrands: [],
            loading: true
        }
    } 
    
    componentDidMount() {
        this.retrieveBranches();
    }

    handleSaveClick()
    {
        let branches = this.defineHubsOrder();
        this.updateBranchHubs(branches);
    }

    render()
    {
        alertify.defaults = {
            notifier: {
                position: 'top-right',
                delay: 5  
            }
        }
        var branches =  _.map(this.state.branches,function(element){return element;})
        return (
            <div className={"dashboard-page-wrapper"}>
                {
                    this.state.loading ?
                        <div className='sweet-loading'>
                            <GridLoader
                                sizeUnit={"px"}
                                size={15}
                                color={'#0066b8'}
                                loading={this.state.loading}
                            />
                        </div> : null
                }
                <div className={"dashboard-page-navigations"}>
                    <div>
                        <i class="fab fa-hubspot"></i>
                        <span> Manage Brands</span>
                    </div>
                    <div>
                        <button className="back-btn" onClick={() => this.props.history.goBack()}>
                            Go Back
                        </button>
                    </div>
                </div>
                <div className={"manage-brands-wrapper"}>
                    <div className={"manage-hubs-header"}>
                        <div> <img src={Information} style={{width:"36px", marginLeft:"10px"}} /> On this page you can sort and enable/disable brands for branches</div>
                    </div>
                    <div className="manage-brands">
                        {
                            this.state.branches.length ?  this.state.branches.map((branch, index) => {
                                return <div className={"branch-wrapper"}>
                                    <div className={"branch-name"}>{branch.name}</div>
                                    <div className={"branchesSelectAll"}>
                                        <label htmlFor={"branches-"+branch.id} className={"selectAllLable"} >Select/Deselect All</label>
                                        <div className="md-checkbox check-all">
                                            <input type="checkbox" checked={this.state['branches-'+branch.id] ? true:false} onChange={this.handleAllChecked.bind(this,{name:"branches",id: branch.id})} id={"branches-"+branch.id }/>
                                            <label htmlFor={"branches-"+branch.id}></label>
                                        </div>
                                    </div>
                                    <ul id={"sortable" + branch.id} className={"branch-brands overflow-auto"}>
                                    {
                                        branch.brands.map((brand) => {
                                            return <li id={branch.id + "-" + brand.id} data-branch-id={branch.id} data-hub-id={brand.id}>
                                                <span className={"brands-name-wrapper"}><span className="arrows-icons"><i class="fas fa-sort-up"></i><i class="fas fa-sort-down"></i></span> {brand.name}</span>
                                                <div className="md-checkbox">
                                                    <input type="checkbox"  defaultChecked={brand.selected ? true:false}  title="Disable/enable brands" onChange={this.handleHubChange.bind(this,{brandId:brand.id,branchId:branch.id})}   name={branch.id + "-" + brand.id}  id={"section" + branch.id + brand.id}/>
                                                    <label htmlFor={"section" + branch.id + brand.id}></label>
                                                </div>
                                          </li>  
                                        })
                                    }
                                    </ul> 
                                </div>
                            }): null
                        }
                    </div> 
                    <div className={"save-brands-button"} onClick={this.handleSaveClick.bind(this)}>Update</div>
                </div>
            </div>
        );
    }

    retrieveBranches()
    {
        axios.get("catalogue/branches/brands")
            .then((response) => {
                if(response.data.success) {
                    this.setState({
                        branches:  response.data.branches,
                        allBrands: response.data.allBrands,
                        loading:false
                    }, () => {
                        this.makeSortable(this.state.branches);
                    })
                } else { 
                    alert("Error: " + response.data.msg);
                }
            })
    }

    initBranches(branches,brands){
        var currentBrands = [];
        var brands = _.map(brands,function(element){return element});
        _.map(branches,function(branch){
            if(branch.brands){
                currentBrands = _.map(branch.brands,function(element){
                    element.selected = true;
                    return element;
                });
                branch.brands = _.merge(currentBrands,brands);
            }else{
                branch.brands = brands;
            }
       });
       return branches;
    }

    handleHubChange(propertys, event){
        let branchName = 'branches-'+propertys.branchId,
         checkAll = true,
         result = [],
         branches = this.state.branches,
         branch = _.find(branches, { id: propertys.branchId });
         if(branch.brands) {
            let brand = _.map(branch.brands,function(brand){
               if(brand.id == propertys.brandId)
                    brand.selected = event.target.checked;
               if(!brand.selected)
                 checkAll = false;
            })
            result[branches] = branches;
            result[branchName] = checkAll;
            this.setState(result);
        }
    }

    updateBranchHubs(branches) 
    {
        this.setState({loading: true});
        axios.post("catalogue/branches/brands", {
            branches: branches
        }).then((response) => {
            if(response.data.msg) {
                this.setState({loading: false});
                alertify.success(response.data.msg);
            } else {
                this.setState({loading: false});
                alertify.error("something went wrong");
            }
        });
    }

    defineHubsOrder()
    {
        let branches = [...this.state.branches];
        branches.forEach((branch) => {
            let sorted = $("#sortable" + branch.id).sortable("toArray");
            let valuesToRemove = [];
            sorted.forEach((name, index) => {
                if(!($("[name='"+name+"']").is(':checked'))) {
                    valuesToRemove.push(name);
                }
            });
            sorted = sorted.filter((element) => { return !valuesToRemove.includes(element); }).map((element) => { return element.split("-")[1] })
            branch.sortedBrands = sorted;
        })
        return branches;
    }

    makeSortable(branches)
    {
        let branchesAllArray = [];
        _.map(branches,function(branch){
            branchesAllArray['branches-'+branch.id] = true;
            if(branch.brands && _.find(branch.brands,{selected: false}))
                    branchesAllArray['branches-'+branch.id] = false;
            $( "#sortable" + branch.id ).sortable({
                placeholder: "ui-state-highlight"
            }); 
        });
        this.setState(branchesAllArray);
    }

    handleAllChecked(property,event) {
        let elementName = property.name + "-"+ property.id;
        let branches = this.state.branches || "";
        var branch = _.find(branches,{id: property.id});
        let result = [];
        if(branch &&  branch.brands) {
            branch = _.map(branch.brands,function(element){
                element.selected = event.target.checked;
                return element;
            });
            result[branches] = branches;
        }
        result[elementName] = event.target.checked;
        this.setState(result);
        $( "#sortable" + property.id + ' input').each(function(i,v){
            v.checked = event.target.checked;
        });
    }
}