import React, { Fragment, useState, useEffect } from "react";
import CustomCheckBox from "admin/administrator/CustomCheckBox";
import CustomSortable from "admin/administrator/CustomSortable";
// import "admin/administrator/CustomSelectAllAble.css";
import _ from "lodash";
import { useTranslation } from 'react-i18next';


const checkElements = (items) => {
    let checkAll = true;
    if(items && items.length > 0) {
        _.map(items, function(element){
            if(!element.checked) {
                checkAll = false;
            }
        });
    } else
        checkAll = false;
    return checkAll;
}

function handleCheckBoxChange(onChangeCheckBox, items, setCheckAll, value, event) {
    let item = _.find(items, { name: value });
    item.checked = event.target.checked;
    onChangeCheckBox([...items]);
    setCheckAll(checkElements(items, setCheckAll))
}

const checkAllHnadler = (items, onCheckAll, value) => {
    if(items) {
        _.map(items, function(element) {
            element.checked = value;
            return element;
        });
    }
    onCheckAll(items)
}

const CustomSelectAllAble = (props) => {
    const { t, i18n } = useTranslation();
    const [checkAll, setCheckAll] = useState(false);

    useEffect(()=>{
        var status = props.items? checkElements(props.items) : false;
        setCheckAll(status)
    },[props.items])

    return <Fragment>
        <div className={"flex flex-align-center flex-justify-end mb-16 mr-12"}>
        <label htmlFor={"brand-all"} className={"mr-8"} >{t("Select/Deselect All")}</label>
            <CustomCheckBox
                name={"check-all-" + props.cheeck_all_name}
                checked={checkAll}
                onChange={(event) => {
                    setCheckAll(event.target.checked);
                    checkAllHnadler(props.items || [], props.onCheckAll, event.target.checked)
                }}/>
        </div>
        <CustomSortable
            items={props.items || []}
            disabled={false}
            callBack={props.callBack}
            onChangeCheckBox={handleCheckBoxChange.bind(this, props.onChangeCheckBox, props.items, setCheckAll)}
        />
    </Fragment>
}
  
export default CustomSelectAllAble;