import React, {Component} from "react";
import _ from "lodash";
// import "admin/administrator/ClientDashboard/ClientDashboardIndex.css";
import ClientDetails from "admin/administrator/ClientDashboard/ClientDetails";
import CustomContainer from 'custom_components/CustomContainer.js';
import BranchesTable from "admin/administrator/ClientDashboard/BranchesTable";
import {connect} from "react-redux";
import Block from "components/Block";
import { withTranslation } from 'react-i18next';
import i18n, { t } from 'i18next';

var moment = require('moment');

class ClientDashboardIndex extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={'page-content admin-wrapper'}>
                <div className={'dashboard'}>
                    <div className="dashboard__left">
                        <Block title={t("monthly report")} type={"1"}>
                            <ClientDetails date={this.props.date}></ClientDetails>
                        </Block>
                    </div>
                    <div className="dashboard__right">
                        <Block title={t("branch accounts")} type={"1"}>
                            <BranchesTable date={this.props.date}></BranchesTable>
                        </Block>
                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => ({
    date: state.dashboardDate.date
})

export default withTranslation()(connect(mapStateToProps, null)(ClientDashboardIndex));