import React, {Component} from "react";
// import "admin/administrator/TopAccounts/TopAccounts.css";
// import "admin/administrator/CustomElements.css";
import CustomPieChart from "components/Charts/CustomPieChart.js";
import CustomPagination from "admin/administrator/CustomPagination";
import {BrowserRouter as Router, Route, NavLink} from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {GridLoader} from 'react-spinners';
import axios from "axios1";
import {Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup} from "react-bootstrap";
import SalesTotalsByUser from "./SalesTotalsByUser";
import {withRouter} from 'react-router-dom';
import CustomPieChart2d from "components/Charts/CustomPieChart2d";
import CustomDatePicker from 'admin/administrator/CustomDatePicker';
import { connect } from "react-redux";
import {selectTopAccountMonth} from "redux/actions/statisticActions";

var moment = require('moment');

class TopAccounts extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            accounts: [],
            accountsForSelect: [],
            filters: {
                account_id: null,
                month: moment(new Date()).format('YYYY-MM'),
            },
            selectedDate: new Date(),
            page: 1,
            pages: 0,
            dataLabelsForChart: [],
            dataValuesForChart: []
        }
    }

    componentDidMount() {
        this.initMonth('month');
        this.getAccounts(this.state.page);
        this.getAccountsForSelect();
    }

    getAccounts(page = 1) {
        this.setState({loading: true});
        var filters = {...this.state.filters};
        axios.get("/catalogue/sales-by-accounts?page=" + page, {params: filters})
            .then((response) => {
                let dataLabelsForChart = [];
                let dataValuesForChart = [];
                response.data.accounts.map((account) => {
                    dataLabelsForChart.push(account.name);
                    dataValuesForChart.push(account.price);
                });
                this.setState({
                    accounts: response.data.accounts,
                    dataLabelsForChart: dataLabelsForChart,
                    dataValuesForChart: dataValuesForChart,
                    page: page,
                    pages: response.data.pages,
                    loading: false
                });
            })
    }

    getAccountsForSelect() {
        axios.get("catalogue/view-accounts")
            .then((response) => {
                this.setState({accountsForSelect: response.data.accounts})
            });
    }

    handleChange(key, event) {
        let filters = this.state.filters;
        filters[key] = event.target.value;
        this.setState({filters: filters}, () => {
            this.getAccounts();
        });
    }

    handleChangeDate(key, month) {
        let filters = this.state.filters;
        filters[key] = moment(month).format('YYYY-MM');
        this.props.dispatch(selectTopAccountMonth(filters[key]))
        this.setState({filters: filters, selectedDate: month}, () => {
            this.getAccounts();
        });
    }

    initMonth(key = 'month') {
        var month = this.props.topAccountsMonth ? moment(this.props.topAccountsMonth).toDate() : this.state.selectedDate.month;
        var selectedDate = this.state.selectedDate;
        selectedDate.month = month;
        var filters = this.state.filters;
        filters[key] = moment(month).format('YYYY-MM');
        this.setState({selectedDate: selectedDate, filters: filters});
    }

    render() {
        return (
            <div className={"page-content admin-wrapper"}>
                <Container fluid={true}>
                    <Row className="show-grid">
                        <Col md={12}>
                            <div className={"page-title-wrapper"}>
                                <span>Top Accounts</span>
                                <button onClick={() => this.props.history.goBack()} className="go-back">
                                    <i className="fas fa-reply"></i> Back
                                </button>
                            </div>
                        </Col>
                    </Row>
                    <Row className="show-grid">
                        <Col md={12}>
                            <div className={"custom-filter"}>
                                {/* <div className={"filter-group"}>
                                    <label>Account</label>
                                    <select onChange={this.handleChange.bind(this, 'account_id')}>
                                        <option value={""}>All</option>
                                        {
                                            this.state.accountsForSelect.map((account) => {
                                                return <option key={account.id}
                                                               value={account.accountId}>{account.accountName}</option>
                                            })
                                        }
                                    </select>
                                </div> */}
                                <div className={"filter-group"}>
                                    <label>Month</label>
                                    <CustomDatePicker value={this.state.filters['month']} selected={this.state.selectedDate.month} placeholder={"Month"} onChange={this.handleChangeDate.bind(this, "month")} format={"MMMM YYYY"} showMonthYearPicker={true} ></CustomDatePicker>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="show-grid">
                        <Col md={6}>
                            {
                                this.state.accounts.length > 0 ?
                                    <table className={"custom-table table table-bordered"}>
                                        <thead>
                                        <tr>
                                            <th>pos</th>
                                            <th>account type</th>
                                            <th>account name</th>
                                            <th>total sales</th>
                                            <th>info</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.accounts.map((account, index) => {
                                                return <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{account.type}</td>
                                                    <td>{account.name}</td>
                                                    <td>{account.price}</td>
                                                    <td>
                                                        <NavLink to={"/admin/top-accounts/logins/" + account.id} exact>
                                                            <button className={"custom-btn"}>Details</button>
                                                        </NavLink>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                        </tbody>
                                    </table> : null
                            }
                            {
                                this.state.pages > 0 ?
                                    <CustomPagination
                                        page={this.state.page}
                                        pages={this.state.pages}
                                        sendRequest={this.getAccounts.bind(this)}
                                    /> : null
                            }
                        </Col>
                        <Col md={6}>
                            <div className={"top-accounts-chart"}>
                                <CustomPieChart2d labels={this.state.dataLabelsForChart}
                                                  values={this.state.dataValuesForChart}/>
                            </div>
                        </Col>
                    </Row>
                </Container>
                {
                    this.state.loading ?
                        <div className='sweet-loading'>
                            <GridLoader
                                sizeUnit={"px"}
                                size={15}
                                color={'#0066b8'}
                                loading={this.state.loading}
                            />
                        </div> : null
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    topAccountsMonth: state.statistic.topAccountsMonth
})

export default withRouter(connect(mapStateToProps)(TopAccounts));