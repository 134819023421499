import React, { Component } from "react";
// import "admin/administrator/TopAccounts/TopAccounts.css";
// import "admin/administrator/CustomElements.css";
import {GridLoader} from 'react-spinners';
import { connect } from "react-redux";
import axios from "axios1";
import {Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup} from "react-bootstrap";
import UserAddress from "admin/administrator/UserAddress";
import CustomTable from "admin/administrator/CustomTable";
import CustomProductPrices from "../CustomProductPrices";
var moment = require('moment');

class SalesDetailsPrint extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            products: []
        }
    }

    componentDidMount() {
        this.getOrderDetails();
    }

    getOrderDetails() {
        axios.get("catalogue/orders/" + this.props.match.params.id)
            .then((response) => {
                this.setState({products: response.data.order_details})
            });
    }

    accountAdresses() {
        let account = {...this.props.user.user.account};
        return {
            address1: account.address_1,
            address2: account.address_2,
            town: account.town,
            postal_code: account.postal_code
        }
    }

    render() {
        const account = this.accountAdresses();
        return (
            <div className={"page-content admin-wrapper"}>
                <Container fluid={true}>
                    <Row className="show-grid">
                        <Col md={12}>
                            <div className={"page-title-wrapper"}>
                                <span>order</span>
                                <button onClick={() => this.props.history.goBack()} className="go-back">
                                    <i className="fas fa-reply"></i> Back
                                </button>
                            </div>
                        </Col>
                    </Row>
                    <Row className={"show-grid mb-5"}>
                        <Col md={12}>
                            <UserAddress data={ {title:"Invoice to", info:account}}  />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <CustomTable data={this.state.products} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <CustomProductPrices data={this.state.products} />
                        </Col>
                    </Row>
                </Container>
                {
                    this.state.loading ?
                        <div className='sweet-loading'>
                            <GridLoader
                                sizeUnit={"px"}
                                size={15}
                                color={'#0066b8'}
                                loading={this.state.loading}
                            />
                        </div> : null
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.auth
})

export default connect(mapStateToProps)(SalesDetailsPrint);