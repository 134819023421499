import React, { Fragment } from 'react';
import "custom_components/styles/CustomToggleRadioButton.css";

const CustomToggleRadioButton = ({ items, checked, handleSwitchChange }) => {
    return  <div class="switch-field" onChange={handleSwitchChange.bind(this)}>
        {
            items.map((item, key)=>{
                return <Fragment>
                    <input type="radio" id={"radio-" + key} name="switch-one" value={item.value} checked={checked == item.value ? true : false}/>
                    <label for={"radio-" + key}>{item.title}</label>
                </Fragment>
            })
        }
    </div>
}
    
export default CustomToggleRadioButton;