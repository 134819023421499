import React, { Component } from "react";
// import "admin/administrator/GeneralClientOrBranchStatistics/index.css";
import _ from "lodash";
import { withTranslation } from 'react-i18next';
import i18n, { t } from 'i18next';


export default withTranslation()(class CustomerAccounts extends Component
    {
        constructor(props)
        {
            super(props);
        }
    
        render()
        {
            return (
                <div className="flex mt-24">
                    <div className="flex">
                        <i className={'fas fa-user-circle fs-24'}></i>
                        <div className="ml-8">{t("Customer Accounts")}</div>
                    </div>
                    <div className="fw-700">{this.props.customerAccounts}</div>
                </div>
            );
        }
    })
