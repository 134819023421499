import React, {Component} from "react";
// import "admin/administrator/BranchDashboard/BranchDashboard.css";
import CustomContainer from 'custom_components/CustomContainer.js';
import TopProductSections from "admin/administrator/TopProductSections";
import TopVehicleMakes from "admin/administrator/TopVehicleMakes";
import BranchDetails from "./BranchDetails";
import BranchLoginsComponent from "admin/administrator/BranchLoginsComponent.js";
import CustomerAccounts from "admin/administrator/CustomerAccounts";
import {connect} from "react-redux";
import Block from "components/Block";
import { withTranslation } from 'react-i18next';
import i18n, { t } from 'i18next';

var moment = require('moment');

class BranchDashboard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let props = this.props;
        return (
            <div className={'page-content admin-wrapper'}>
                <div className={"dashboard"}>
                    <div className={"dashboard__left"}>
                        <Block type={"1"} title={ props.match.params.name ? props.match.params.name.replace(`_deleted_${props.match.params.id}`, "") : props.match.params.name }>
                            <BranchDetails id={props.match.params.id} date={props.date}></BranchDetails>
                        </Block>
                    </div>
                    <div className={"dashboard__right"}>
                        <Block type={"1"} title={props.match.params.name ? props.match.params.name.replace(`_deleted_${props.match.params.id}`, "") + " " + t("branch users") : props.match.params.name + " " + t("branch users") } style={{marginBottom: "24px"}}>
                            <BranchLoginsComponent branch={props.match.params}
                                                    name={props.match.params.name}
                                                    date={moment(props.date).format('YYYY-MM')}/>
                        </Block>
                        <Block type={"1"} title={props.match.params.name ? props.match.params.name.replace(`_deleted_${props.match.params.id}`, "") + " " + t("customer accounts") : props.match.params.name  + " " + t("customer accounts")}>

                            <CustomerAccounts branch={props.match.params}
                                                date={moment(props.date).format('YYYY-MM')}/>
                        </Block>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    date: state.dashboardDate.date
})

export default withTranslation()(connect(mapStateToProps, null)(BranchDashboard));