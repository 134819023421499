import React, { Component } from "react";
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-labels'

export default class Chart extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
        }

    }

    render() {

        return (
            <div>
                <Bar
                    data={this.props.numbers}
                    options={{
                        plugins: {
                            labels: [
                                {
                                    render: 'value',
                                    position: 'inside',
                                    fontColor: 'rgba(255,255,255,0)',
                                }
                            ]
                        },
                        legend: {
                            display: false,
                        },
                        scales: {
                            yAxes: [{
                                gridLines: {
                                    display: false,
                                    color: '#5c676b'
                                },
                                scaleLabel: {
                                    display: false,
                                    labelString: '',
                                    fontColor: '#8e8e8e',
                                    fontSize: 14,
                                },
                                ticks: {
                                    fontColor: '#ADABB8',
                                },
                            }],
                            xAxes: [{
                                gridLines: {
                                    display: true,
                                    color: 'rgba(255,255,255,0)'
                                },
                                scaleLabel: {
                                    display: false,
                                    labelString: '',
                                    fontColor: '#8e8e8e',
                                    fontSize: 14
                                },
                                ticks: {
                                    fontColor: '#ADABB8',
                                }
                            }],
                        },
                        aspectRatio: 1
                    }}
                    // redraw
                />
            </div>
        )
    }
}