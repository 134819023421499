import React from "react";
// import "admin/administrator/CustomElements.css";
import Switch from "react-switch";

const CustomSwitch = (data) => {
    return <Switch 
        className={"custom-swith"}
        width={data.width || 38}
        height={data.height || 20 }
        disabled={data.disabled || false}
        handleDiameter={data.handleDiameter || 16}
        checkedIcon={false}
        uncheckedIcon={false}
        offColor={data.offColor || '#d7d9db'}
        onColor={data.onColor || '#4176a6'}
        offHandleColor={data.offHandleColor || '#ffffff'}
        onHandleColor={data.onHandleColor || '#fffff'}  /* #4176a6 */
        checked={data.checked}
        onChange={data.onChange}
    />;
}
  
export default CustomSwitch;