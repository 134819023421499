import React, {Component} from "react";
// import "admin/administrator/lookup_totals/LookupTotals.css";
// import "admin/administrator/vrmServiceStatistics/vrmServiceStatistics.css";
// import "admin/administrator/CustomElements.css";
import {BrowserRouter as Router, Route, NavLink} from "react-router-dom";
import axios from "axios1";
import _ from "lodash";
import CustomPagination from "admin/administrator/CustomPagination";
import CustomPieChart from 'components/Charts/CustomPieChart';
import PageTitle from 'admin/administrator/PageTitle';
import {GridLoader} from 'react-spinners';
import DatePicker from "react-datepicker";
import CustomPieChart2d from "components/Charts/CustomPieChart2d";
import CustomDatePicker from 'admin/administrator/CustomDatePicker';
import { connect } from "react-redux";
import {selectLookupsMonth} from "redux/actions/statisticActions";

var moment = require('moment');

class vrmServiceStatistics extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: {
                account_id: null,
                start_date: "",
                end_date: ""
            },
            selectedDate: new Date(),
            selectedEndDate: new Date(),
            data: [],
            page: 1,
            pages: 0,
            accounts: [],
            dataLabelsForChart: [],
            dataValuesForChart: [],
            loading: false,
            bovsoft:0,
            cartell:0,
            localDb:0,
            noResp:0,
            lookupCounts:[]
        }
    }

    componentDidMount() {
        this.setState({loading: true});
        this.LookupsByAccounts();
    }

    LookupsByAccounts(page = 1) {
        var filters = {...this.state.filters};
        filters.start_date = moment(filters.start_date).format('YYYY-MM-DD');
        filters.end_date = moment(filters.end_date).format('YYYY-MM-DD');
        axios.get("/catalogue/lookups-by-reg-number?page=" + page, {params: filters})
            .then(response => {
                var accounts = response.data.accounts;
                var lookups = response.data.lookups;
                var data = this.chartData(response.data.lookupsTypesCount);
                this.setState({
                    data: lookups,
                    accounts: accounts,
                    page: page,
                    pages: response.data.pages,
                    dataLabelsForChart: data['dataLabelsForChart'],
                    dataValuesForChart: data['dataValuesForChart'],
                    loading: false
                });
            })
    }  

    chartData(lookupsTypesCount){
        let data = [];
        data['dataLabelsForChart'] = ["Bovsoft", "Cartell", "Local DB","VRM Not Found","No Response"];
        data['dataLabelsForChart'] = [];
        data['dataValuesForChart'] = [];
        lookupsTypesCount.map((count,i) => {
            data['dataLabelsForChart'][i]=(count.service==null)?'No Response':count.service;
            data['dataValuesForChart'][i] = [count.count];
        });
        this.setState({
            lookupCounts:lookupsTypesCount
        });
        return data;
    }

    handleChange(key, event) {
        let filters = this.state.filters;
        let value = event.target.value;
        filters[key] = (value != "all") ? value : null;
        this.setState({filters: filters}, () => {
            this.LookupsByAccounts();
        });
    }

    handleChangeDate(key, date) {
        let filters = this.state.filters;
        filters[key] = moment(date).format('YYYY-MM-DD');
        (key=='start_date')? this.setState({selectedDate: date}) : this.setState({selectedEndDate: date});

        this.setState({filters: filters}, () => {
            this.LookupsByAccounts();
        });
    }

    render() {
        return (
            <div className={"page-content admin-wrapper"}>

                <div className={"container-fluid"}>
                    <div className={"lookups-page-wrapper"}>
                        
                        {
                            this.state.loading ?
                                <div className='sweet-loading'>
                                    <GridLoader
                                        sizeUnit={"px"}
                                        size={15}
                                        color={'#0066b8'}
                                        loading={this.state.loading}
                                    />
                                </div> : null
                        }

                        <div className={"row"}>
                            <div className={"col-lg-12"}>
                                <div class="page-title-wrapper">
                                    <span>VRM SERVICE STATISTICS</span>
                                </div>
                                <div className={"lookups-filter"}>
                                    <div className={"custom-filter"}>
                                        <div className={"filter-group"}>
                                            <label htmlFor="account">Account</label>
                                            <select onChange={this.handleChange.bind(this, 'account_id')}>
                                                <option>all</option>
                                                {
                                                    this.state.accounts.map((account) => {
                                                        return <option key={account.id}
                                                                       value={account.id}>{account.name}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className={"filter-group"}>
                                            <label htmlFor="account">Start Date</label>
                                            <CustomDatePicker 
                                            value={this.state.filters['start_date']} selected={this.state.selectedDate} 
                                            placeholder={"Start Date"} 
                                            onChange={this.handleChangeDate.bind(this, "start_date")} 
                                            format={"DD-MM-YYYY"}>
                                            </CustomDatePicker>
                                        </div>

                                        <div className={"filter-group"}>
                                            <label htmlFor="account">End Date</label>
                                            <CustomDatePicker 
                                            value={this.state.filters['end_date']} selected={this.state.selectedEndDate} 
                                            placeholder={"End Date"} 
                                            onChange={this.handleChangeDate.bind(this, "end_date")} 
                                            format={"DD-MM-YYYY"}>
                                            </CustomDatePicker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"row"}>
                            
                            <div className={"col-lg-6"}>
                                <div className={"row"}>
                                    <div className="col-sm-6 mb-3">
                                        {
                                            this.state.data.length > 0 ?
                                                <table className={"custom-table table table-bordered"}>
                                                    <thead>
                                                    <tr>
                                                        {
                                                            this.state.lookupCounts.map((count)=>{
                                                                return <th>{(count.service==null)?"No Response":count.service}</th>
                                                            })
                                                        }
                                                        
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            {
                                                                this.state.lookupCounts.map((count)=>{
                                                                    return <td>{count.count}</td>
                                                                })
                                                            }
                                                        </tr>
                                                    </tbody>
                                                </table> : null
                                        }
                                    </div>
                                    <div className="col-sm-12">
                                {
                                    this.state.data.length > 0 ?
                                        <table className={"custom-table table table-bordered"}>
                                            <thead>
                                            <tr>
                                                <th>ACCOUNT</th>
                                                <th>VRM</th>
                                                <th>SERVICE</th>
                                                <th>DATE/TIME</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.data.map((lookup) => {
                                                    return <tr>
                                                        <td>{lookup.account}</td>
                                                        <td>{lookup.vrm}</td>
                                                        <td>{(!lookup.service)?"No Response":lookup.service}</td>
                                                        <td>{lookup.datetime}</td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table> : null
                                }

                                {
                                    this.state.pages > 0 ?
                                        <CustomPagination
                                            page={this.state.page}
                                            pages={this.state.pages}
                                            sendRequest={this.LookupsByAccounts.bind(this)}
                                        /> : null
                                }
                                    </div>
                                </div>
                            </div>
                            <div className={"col-lg-6"}>
                                <CustomPieChart2d labels={this.state.dataLabelsForChart}
                                                  values={this.state.dataValuesForChart}/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    month: state.statistic.lookupsMonth
})

export default connect(mapStateToProps)(vrmServiceStatistics);