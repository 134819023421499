import React, { Component } from "react";
import axios from "axios1";
import {connect} from "react-redux";
import { reloadSettings } from "redux/actions/settings";
import { getUser, setCart, setCompare, LogoutUser } from "redux/actions/authActions";
import { updateOutstandingOrdersQty, setOrdersQty, setCustomerOrdersQty } from "redux/actions/orders";

class InitRedux extends Component
{
    constructor(props)
    {
        super(props);
        // if(this.props.isTest == "true") { 
        this.authorizeUser();
        // else { this.initialize() }
    }

    authorizeUser() {
        this.setSettings();
        
        if( !this.props.user ) {
            return;
        }

        axios.post("/last-login-diff")
            .then(response => {
                if(response.data.diff > 32) {
                    this.props.LogoutUser();
                }else {
                    this.initialize();
                }
            })
    }

    initialize(){
        this.setShoppingCart();
        this.setCompareList();
        this.setOrders();
        this.setCustomerOrders();
    }

    setShoppingCart(){
        this.props.setCart();
    }

    setCompareList() {
        axios.get("/catalogue/getCompare")
            .then(response => {
                this.props.setCompare();
            })
            .catch(() => { // This catch is used to avoid breaking the code if token is expired and axios returns 401 unauthorised response
            });
    }

    setSettings(){
        this.props.reloadSettings().then(() => { });
    }

    setOrders() {
        this.props.setOrdersQty();
    }

    setCustomerOrders() {
        this.props.setCustomerOrdersQty();
    }

    render()
    {
        return (
            null
        );
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    user: state.auth.user,
    settings: state.settings
})

const mapDispatchToProps = dispatch => {
    return {
        reloadSettings: reloadSettings,
        setCart: setCart,
        getUser: getUser,
        LogoutUser: LogoutUser,
        setCompare: setCompare,
        updateOutstandingOrdersQty: updateOutstandingOrdersQty,
        setOrdersQty: setOrdersQty,
        setCustomerOrdersQty: setCustomerOrdersQty
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(InitRedux);