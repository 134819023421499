import React, {Component} from "react";
// import "admin/administrator/LoginTotals/LoginTotalsByUser.css";
// import "admin/administrator/CustomElements.css";
import {BrowserRouter as Router, Route, NavLink} from "react-router-dom";
import axios from "axios1";
import _ from "lodash";
import CustomPagination from "admin/administrator/CustomPagination";
import CustomPieChart from 'components/Charts/CustomPieChart';
import PageTitle from 'admin/administrator/PageTitle';
import {GridLoader} from 'react-spinners';
import {withRouter} from 'react-router-dom';
import DatePicker from "react-datepicker";
import CustomPieChart2d from "components/Charts/CustomPieChart2d";
import CustomDatePicker from 'admin/administrator/CustomDatePicker';
import { connect } from "react-redux";
import {selectLoginTotalsMonth} from "redux/actions/statisticActions";
var moment = require('moment');

class LoginTotalsByUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: {
                user_id: null,
                from: "",
                to: "",
            },
            selectedDate: {
                from: new Date(),
                to: new Date()
            },
            logins: [],
            users: [],
            page: 1,
            pages: 0,
            account_id: 0,
            dataLabelsForChart: [],
            dataValuesForChart: [],
            loading: false,
        }
    }

    componentDidMount() {
        this.setState({loading: true});
        this.initMonth();
        this.LookupsByAccountLogins(this.state.page);
    }

    setActive(component, loginId = null) {
        this.props.setActiveComponent(component, loginId);
    }

    LookupsByAccountLogins(page = 1) {
        var filters = {...this.state.filters};
        axios.get("/catalogue/logins-by-accounts/" + this.props.match.params.id + "?page=" + page, {params: filters})
            .then(response => {
                let users = response.data.users.map((user) => {
                    return {id: user.id, name: user.name.toUpperCase()}
                });
                let dataLabelsForChart = [];
                let dataValuesForChart = [];
                response.data.logins.map((login) => {
                    dataLabelsForChart.push(login.user);
                    dataValuesForChart.push(login.total);
                });
                this.setState({
                    logins: response.data.logins,
                    users: users,
                    page: page,
                    pages: response.data.pages,
                    dataLabelsForChart: dataLabelsForChart,
                    dataValuesForChart: dataValuesForChart,
                    account_id: this.props.match.params.id,
                    loading: false
                });
            })
    }


    handleChange(key, event) {
        let filters = this.state.filters;
        let value = event.target.value;
        filters[key] = (value != "all") ? value : null;
        this.setState({filters: filters}, () => {
            this.LookupsByAccountLogins();
        });
    }

    handleChangeDate(key, date) {
        let selectedDate = this.state.selectedDate;
        let filters = this.state.filters;
        filters[key] = moment(date).format('YYYY-MM-DD');
        selectedDate[key] = date;
        this.props.dispatch(selectLoginTotalsMonth(selectedDate))
        this.setState({filters: filters, selectedDate: selectedDate}, () => {
            this.LookupsByAccountLogins();
        });
    }

    initMonth() {
        let from = this.props.loginTotalsFrom ? moment(this.props.loginTotalsFrom).toDate() : "";
        let to = this.props.loginTotalsTo ? moment(this.props.loginTotalsTo).toDate() : "";
        var selectedDate = this.state.selectedDate;
        selectedDate['from'] = from;
        selectedDate['to'] = to;
        var filters = this.state.filters;
        filters['from'] = from ? moment(from).format('YYYY-MM-DD') : from;
        filters['to'] = to ? moment(to).format('YYYY-MM-DD') : to;
        this.setState({selectedDate: selectedDate, filters: filters});
    }

    render() {
        return (
            <div className={"page-content admin-wrapper"}>

                <div className={"container-fluid"}>
                    <div className={"lookups-page-wrapper"}>
                        {
                            this.state.loading ?
                                <div className='sweet-loading'>
                                    <GridLoader
                                        sizeUnit={"px"}
                                        size={15}
                                        color={'#0066b8'}
                                        loading={this.state.loading}
                                    />
                                </div> : null
                        }
                        <div className={"row"}>
                            <div className={"col-lg-12"}>
                                <div class="page-title-wrapper">
                                    <span>Login Totals By User</span>
                                    <button class="go-back" onClick={() => this.props.history.goBack()}><i
                                        class="fas fa-reply"></i> Back
                                    </button>
                                </div>
                                <div className={"lookups-filter"}>
                                    <div className={"custom-filter"}>
                                        <div className={"filter-group"}>
                                            <label htmlFor="account">Logins</label>
                                            <select onChange={this.handleChange.bind(this, 'user_id')}>
                                                <option>All Logins</option>
                                                {
                                                    this.state.users.map((user) => {
                                                        return <option key={user.id}
                                                                       value={user.id}>{user.name}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className={"filter-group"}>
                                            <label htmlFor="account">Form</label>
                                            <CustomDatePicker value={this.state.filters['from']} selected={this.state.selectedDate.from} placeholder={"Date From"} onChange={this.handleChangeDate.bind(this, "from")} format={"DD-MM-YYYY"}></CustomDatePicker>
                                        </div>
                                        <div className={"filter-group"}>
                                            <label htmlFor="account">To</label>
                                            <CustomDatePicker value={this.state.filters['to']} selected={this.state.selectedDate.to} placeholder={"Date To"} onChange={this.handleChangeDate.bind(this, "to")} format={"DD-MM-YYYY"}></CustomDatePicker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-lg-6"}>
                                {
                                    this.state.logins.length > 0 ?
                                        <table className={"custom-table table table-bordered"}>
                                            <thead>
                                            <tr>
                                                <th>LOGIN</th>
                                                <th>LOGIN TOTAL</th>
                                                <th>INFO</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.logins.map((login) => {
                                                    return <tr>
                                                        <td>{login.user}</td>
                                                        <td>{login.total}</td>
                                                        <td>
                                                            <NavLink to={"/admin/login-totals/lookups/" + login.id}
                                                                     exact>
                                                                <button className={"custom-btn"}>Details</button>
                                                            </NavLink>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table> : null
                                }
                                {
                                    this.state.pages > 0 ?
                                        <CustomPagination
                                            page={this.state.page}
                                            pages={this.state.pages}
                                            sendRequest={this.LookupsByAccountLogins.bind(this)}
                                        /> : null
                                }
                            </div>
                            <div className={"col-lg-6"}>
                                <CustomPieChart2d labels={this.state.dataLabelsForChart}
                                                  values={this.state.dataValuesForChart}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    loginTotalsFrom: state.statistic.tloginTotalsFrom,
    loginTotalsTo: state.statistic.tloginTotalsTo
})

export default withRouter(connect(mapStateToProps)(LoginTotalsByUser));