import React from "react";
import CustomButton from "admin/administrator/CustomButton.js";
import SectionsItem from "components/sections/SectionsItem"; 
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';

const sectionSettings = {
    slidesToShow: 9,
    slidesToScroll: 3,
    infinite: false,
    arrows: true,
    draggable: false,
    prevArrow: <button className="slick-custom-prev"><i class="fas fa-chevron-left"></i></button>,
    nextArrow: <button className="slick-custom-next"><i class="fas fa-chevron-right"></i></button>,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 320,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const Sections = ({sections, selectedSections, fromPartNumber, nonAutoActive, chooseSection, clearSearch}) => {

    const { t, i18n } = useTranslation();
    return (
        <div className={"selections mb-16"}>
            <div className="selections__header">
                <div className="selections__title">
                    <span>{t("Sections")}</span>
                </div>
                {
                    (fromPartNumber && nonAutoActive && Object.keys(sections).length > 0) &&
                        <CustomButton title={t("Clear Search")} type={"blue"} onClick={clearSearch}/>
                }
            </div>
            <div className="selections__content">
                <div className="sections">
                    <Slider {...sectionSettings}>
                        {
                            sections.map( section => {
                                return section.map( sec => <SectionsItem section={sec} selectedSections={selectedSections} chooseSection={chooseSection}/>);
                            })
                        }
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default Sections