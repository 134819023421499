import React, { Component } from 'react';
import axios from 'axios1';
// import "admin/administrator/PriceLevels/PriceLevelProducts.css";
import $ from "jquery";
import CustomPagination from "admin/administrator/CustomPagination";
import { withTranslation } from 'react-i18next';
import i18n, { t } from 'i18next';

export default withTranslation() (class PriceLevelProducts extends Component {
    constructor(props)
    {
        super(props);

        this.state = {
            data: [],
            page: 1,
            pages: 0,
            loading: false,
        }

    }

    componentDidMount(){
        this.LookupsByAccounts();
    }

    LookupsByAccounts(page = 1) {
        axios.get("/catalogue/products-for-price-levels?page=" + page)
            .then(response => {
                this.setState({
                    page: page,
                    pages: response.data.pages,
                    data:response.data.products
                });
            })
    }

    render() {
        return (
            <div className="table">
                <table className={"table__table"}>
                    <thead className="table__thead">
                        <tr className="table__tr">
                            <th className="table__th">{t("Part Number")}</th>
                            <th className="table__th">{t("Base Price")}</th>
                            {
                                this.props.levels.map((level) => {
                                return <th className="table__th">{t(level.name)}</th>
                            })
                            
                            }
                        </tr>
                    </thead>
                    <tbody className="table__tbody">
                        {
                            this.state.data.map((d) =>{
                            return <tr className="table__tr">    
                                <td className="table__td">{d.part_number}</td>
                                <td className="table__td"><span>&nbsp;</span> {parseFloat(d.price).toFixed(2)}</td>
                                {/* <td className="basePrice"><span>£ &nbsp;</span> {parseFloat(d.price).toFixed(2)}</td> */}
                                {
                                    this.props.levels.map((level) => {
                                        let LevelPrice = parseFloat(level.value);
                                        let basePrice = parseFloat(d.price);
                                        return <td className="table__td">
                                        <span>&nbsp;</span>
                                        {/* <span>£ &nbsp;</span> */}

                                        { (level.value != null)?(this.props.ValueType==0)?
                                            (basePrice/(1-LevelPrice/100)).toFixed(2) : (basePrice*(1+LevelPrice/100)).toFixed(2)
                                            :''
                                        }
                                        </td>
                                    })
                                }
                                </tr>
                                })
                        }
                    </tbody>
                </table>

                {
                    this.state.pages > 0 ?
                        <CustomPagination
                            page={this.state.page}
                            pages={this.state.pages}
                            sendRequest={this.LookupsByAccounts.bind(this)}
                        /> : null
                }


            </div>
        );
    }
});
