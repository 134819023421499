import React, { Component } from "react";
import ShoppingCartContent from "components/ShoppingCartContent";

export default class ShoppingCart extends Component
{
    constructor(props)
    {
        super(props);
    }

    render()
    {
        return (
            <div>
                <ShoppingCartContent />
            </div>
        );
    }
}