import React, {useState, Fragment} from "react";
import FormInput from "admin/administrator/FormInput/FormInput.component";
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const SearchCategory = ({
    groups,
    chooseGroup,
    chooseGroupBySection,
}) => {
    const [searchInput, setSearchInput] = useState('');
    const [searcResults, setSearcResults] = useState([]);
    const { t, i18n } = useTranslation();
    const searchHandler = event => {
        let value = event.target.value;
        let results = [];
        if (value != '') {
            _.map(groups, item => {
                if(item.group.toLowerCase().trim().indexOf( value.toLowerCase().trim() ) !== -1){
                    results.push(generateLiForSearch(item.group, item, true));
                }else {
                    _.map(item.sections, section => {
                        if(section.section.toLowerCase().trim().indexOf( value.toLowerCase().trim() ) !== -1) {
                            results.push(generateLiForSearch(section.section, section, false, item));
                        }
                    });
                }
            });
        }

        setSearchInput(value);
        setSearcResults(results);
    }

    const generateLiForSearch = (name, item, isGroup = true, group = {}) => { 
        const handler = () => {
            if(isGroup) {
                chooseGroup(item.group_id)
            } else {
                chooseGroupBySection(group.group_id, item.section, item.section_id);           
            }
            setSearchInput('');
            setSearcResults([]);
        }
        return <li key={item.section_id} onClick={handler}>{name}</li>;
    }

    return (
        <Fragment>
            <FormInput
                type='text'
                value={searchInput}
                placeholder={t("Search Category")+'...'}
                onChange={searchHandler}
                className="bordered"
            />
            {
                searcResults.length > 0 &&
                    <ul>
                        {searcResults.map(item => item) }
                    </ul>
            }
        </Fragment>
    );
}

export default SearchCategory;