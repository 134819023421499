import React, {Component} from "react";
import axios from "axios1";
import ReactTable from "react-table";
import DatePicker from "react-datepicker";
import CustomPieChart from 'components/Charts/CustomPieChart';
import CustomPieChart2d from 'components/Charts/CustomPieChart2d';
import CustomDatePicker from 'admin/administrator/CustomDatePicker';
// import "admin/administrator/VehicleLookups.css";
// import "react-datepicker/dist/react-datepicker.css";
import {NavLink} from "react-router-dom";

var moment = require('moment');

export default class VehicleLookups extends Component {
    constructor(props) {
        super(props);
        let d = new Date();
        d = d.setMonth(d.getMonth() - 2);
        const d2 = new Date();
        this.state = {
            lookupsChart: [],
            lookupsTable: [], 
            month: d,
            endMonth: d2,
            columns: [
                {
                    Header: () => (
                        <span>#</span>
                    ),
                    Cell: row => (
                        <span>{row.index + 1}</span>
                    )
                },
                {
                    id: 'name',
                    Header: 'Manufacturer',
                    accessor: d => d.name
                },
                {
                    id: 'y',
                    Header: 'Lookups',
                    accessor: (d) => (
                        <NavLink to={{pathname: "admin/vehicle-manufactuer-lookups/" + d.name + "/" + moment(this.state.month).format('YYYY-MM-DD') + "/" + moment(this.state.endMonth).format('YYYY-MM-DD')}} prop={d}
                                 className="lookupsNumbers btn">{d.number}</NavLink>
                    )
                }
            ],
            dataLabelsForChart: [],
            dataValuesForChart: []
        }
    }

    componentDidMount() {
        this.getVehicleLookups();
    }


    getVehicleLookups() {
        const month = this.state.month != "" ? moment(this.state.month).format('YYYY-MM-DD') : "";
        const endMonth = this.state.month != "" ? moment(this.state.endMonth).format('YYYY-MM-DD') : "";
        axios.get("/catalogue/lookups-by-manufacturers/tops?month=" + month + "&endMonth=" + endMonth)
            .then((response) => {
                let dataLabelsForChart = [];
                let dataValuesForChart = [];
                response.data.makes.map((make) => { 
                    dataLabelsForChart.push(make.name);
                    dataValuesForChart.push(make.number);
                })
                this.setState({dataLabelsForChart: dataLabelsForChart, dataValuesForChart: dataValuesForChart, lookupsTable: response.data.makes});
            });
    }

    handleChangeDate(month) {
        this.setState({month: month}, () => {
            this.getVehicleLookups();
        });
    }

    handleChangeEndDate(month) {
        this.setState({endMonth: month}, () => {
            this.getVehicleLookups();
        });
    }

    render() {
        return (
            <div className={"custom-wrapper lookups-wrapper vehicles"}>
                <div className={"custom-heading"}>
                    <span>Vehicle Manufacturer Lookups</span>
                </div>
                <div className={"custom-filter"}>
                    <div className="filter-group">
                        <label>Date From</label>
                        <CustomDatePicker
                            value={this.state.month} selected={this.state.month}
                            placeholder={"choose from month"}
                            onChange={this.handleChangeDate.bind(this)}
                            format={"DD-MM-YYYY"}>
                        </CustomDatePicker>
                    </div>
                    <div className="filter-group">
                        <label>Date To</label>
                        <CustomDatePicker
                            value={this.state.endMonth} selected={this.state.endMonth}
                            placeholder={"choose to month"}
                            onChange={this.handleChangeEndDate.bind(this)}
                            format={"DD-MM-YYYY"}>
                        </CustomDatePicker>
                    </div>
                </div>
                <div className={"lookups-wrapper-body"}>
                    <div className={"body-chart"}> 
                        <CustomPieChart2d labels={this.state.dataLabelsForChart} values={this.state.dataValuesForChart}/>
                    </div>
                    <div className={"body-table"}>
                        <ReactTable
                            data={this.state.lookupsTable}
                            showPagination={false}
                            columns={this.state.columns}
                            defaultPageSize={10}
                            minRows={0}
                        />
                    </div> 
                </div>
            </div>
        )
    }
}
