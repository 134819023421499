import React from 'react';
import Notify from "components/Notify";

const ChangeSaveOrDiscardModal = props => (
        <Notify type={"warning"}
                visible={props.modalShow}
                close={props.closeChangesModal}
                primaryText={"You have made changes to this screen but not saved them."}
                secondaryText={"What would you like to do?"}
                actions={[
                    {
                        name: "Discard Changes",
                        action: props.closeChangesModalDisregard,
                        type: "lightgray"
                    },
                    {
                        name: "Save Changes",
                        action: props.saveChanges,
                        type: "blue"
                    }
                ]}/>
);

export default ChangeSaveOrDiscardModal;