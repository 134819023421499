import React from 'react';
import "custom_components/styles/customContainer.css";

const CustomContainer = (props) => {
    let margins = props.margins.join(" ");
    return (<div className={"custom-container " + margins + " " + props.type} style={{width: (props.width || "100%"), height: (props.height || "auto")}}>
                <div className={"custom-container-header"}>
                    {props.name}
                </div>
                <div className={"custom-container-body"}>
                    {props.children}
                </div>
            </div>);
}

export default CustomContainer;

CustomContainer.defaultProps = {
    margins: []
}
