import React, {Component, Fragment} from "react";
// import "admin/administrator/CustomPagination.css";

export default class CustomPagination extends Component {

    constructor(props) {
        super(props);
    }
    
    pageItem(number) {
        if(typeof number == 'number') {
            let activeClass = this.props.page === number ? 'pagination__item--active' : '';
            return (<span key={number} id={number} className={"pagination__item " + activeClass} onClick={this.props.sendRequest.bind(this, number)}>{number}</span>);
        } else {
            return (<span key={number} className="pagination__item pagination__item--dots">{number}</span>);
        }
    }

    renderPages(pages, page) {
        const showSDots = page > 4;
        const showEDots = page < pages - 3;
        const r = [];
        if(pages > 1) {
            r.push(this.pageItem(1));
        }
        if (showSDots) {
            r.push(this.pageItem('..'));
            r.push(this.pageItem(page - 1));
        } else {
            for(let i = 2; i <= page - 1; i++) {
                r.push(this.pageItem(i));
            }
        }
        if (page > 1 && page != pages) {
            r.push(this.pageItem(page));
        }
        if (page < pages - 1)
            r.push(this.pageItem(page + 1));
        if (showEDots) {
            r.push(this.pageItem('...'));
        } else {
            for (let i = page + 2; i < pages; i++) {
                r.push(this.pageItem(i));
            }
        }
        if(pages > 1){
            r.push(this.pageItem(pages));
        }
        return r;
    }

    render() {
        let {pages, page} = this.props;
        return (
            <Fragment>
                {
                    pages > 1 ? 
                        <div className={"pagination"}>
                        {
                            this.renderPages(pages, page)
                        }
                        </div> : null
                }
            </Fragment>
        );
    }

}