import React, {useState, useEffect} from "react";
import FormSelect from "admin/administrator/FormSelect/FormSelect.component";
import FormInput from "admin/administrator/FormInput/FormInput.component";
// import "admin/administrator/CustomStockFeed.css";
import CustomContainer from 'custom_components/CustomContainer.js';
import FtpCredentials from 'components/FtpDetails/FtpCredentials/FtpCredentials';
import FtpDescription from 'components/FtpDetails/FtpDescription/FtpDescription';
import FtpSendEmail from 'components/FtpDetails/FtpSendEmail/FtpSendEmail';
import _ from "lodash";
import axios from "axios1";
import Block from "components/Block";
import { json } from "d3-fetch";
import { useTranslation } from 'react-i18next';

const CustomStockFeed = ({apis, apiId, setApiId, setCredentials, errors = {}, showSendFtpCredentialsBtn, showFtp, textFirst, textSecond, textThird, supplier}) => {
    const [currentApi, setCurrentApi] = useState({
        id: null,
        name: '',
        credentails: []
    });
    const [showFtpDetails, setShowFtpDetails] = useState(false);
    const [ftpCredentials, setFtpCredentials] = useState([]);
    const { t, i18n } = useTranslation();
    
    useEffect(() => {
        let currentApi = _.find(apis, {'id': parseInt(apiId)})
        if (apiId == 3) {
            getFtpCredentials();
        }
        if (currentApi) {
            setCurrentApi(currentApi);
            setCredentials(currentApi.credentails || []);
        } else {
            setCurrentApi({
                id: null,
                name: '',
                credentails: []
            });
            setCredentials([]);
        }
    }, [apiId, apis]);

    useEffect(() => {
        setShowFtpDetails(true);
    }, [ftpCredentials]);

    const getFtpCredentials = () => {        
        axios.get("catalogue/ftp-credentials")
            .then(response => {
                const data = response.data;
                if(typeof supplier !== 'undefined' && supplier.ftp_password) {
                    _.forEach(data, item => {
                        if(item.key === 'Username') {
                            item.value = supplier.ftp_username ?? _.replace(supplier.name,new RegExp(" ","g"),"_");
                        }
                        if(item.key === 'Password') {
                            item.value = supplier.ftp_password;
                        }
                        return item
                    });
                }
                setFtpCredentials(data);

            });
    }

    const handleChange = (setToParentData, event) => {
        let {name, value} = event.target;
        if (value == 3) {
            getFtpCredentials();
        } else {
            setShowFtpDetails(false);
        }
        if (name) {
            _.find(currentApi.credentails, {column: name}).value = value;
            value = currentApi.credentails;
            setCurrentApi({...currentApi, credentails: value});
        }
        setToParentData(value);
    }

    return (
        <div className={'company-stock-feed'}>
            <FormSelect
                options={apis}
                selectedId={apiId}
                error={errors['credentials']}
                defaultValue={t('Select Stock Resource')}
                handleChange={handleChange.bind(this, setApiId)}
                disabled={((supplier && supplier.id && !supplier.ftp_password && apiId === 3) || (apiId !== 3 && supplier && supplier.id && supplier.ftp_password)) ? true : false}
            />
            {
                currentApi && currentApi.credentails.length > 0 &&
                    <div className={"credentials"}>
                        <h3 className={"mb-16 mt-24"}>{currentApi.name}</h3>
                        {
                            currentApi.credentails.map(({column, value}) =>
                                (<FormInput
                                    type='text'
                                    key={column}
                                    name={column}
                                    value={value || ''}
                                    placeholder={column}
                                    className={'mb-16'}
                                    error={errors[column]}
                                    onChange={handleChange.bind(this, setCredentials)}
                                    required
                                />))
                        }
                    </div>
            }
            {

                apiId === 3 && (supplier && !supplier.ftp_password) && showFtpDetails && showFtp ? 
                    <Block title={t("ftp details")} type="1" style={{marginTop: "16px"}}>
                        <FtpDescription textFirst={t("Secure FTP setup for this supplier is currently in progress and can take a few hours. The credentials will be displayed here once this process is complete")} textSecond={''}/>
                    </Block>
                : null
            }
            {
                ftpCredentials && ftpCredentials.length > 0 && showFtp && ((supplier && supplier.ftp_password) || !supplier) ?
                    <Block title={t("ftp details")} type="1" style={{marginTop: "16px"}}>
                        <FtpCredentials credentials={ftpCredentials}/>
                        <FtpDescription textFirst={textFirst} textSecond={textSecond} textThird={textThird}/>
                        {
                            showSendFtpCredentialsBtn ?
                                <FtpSendEmail credentials={ftpCredentials} supplier={supplier}/> : null
                        }
                    </Block> : null
            }
        </div>
    );
}

export default CustomStockFeed;