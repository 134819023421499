import React, {Component, Fragment} from "react";
import {BrowserRouter as Router, Route, NavLink} from "react-router-dom";
import CustomButton from "admin/administrator/CustomButton.js";
var moment = require('moment');

class OrdersDataTable extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let orders = this.props.orders;
        return (
            <Fragment>
                <table className={"custom-table table"}>
                    <thead>

                    <tr>
                        <th>Order ID</th>
                        <th>Date/Time</th>
                        <th>Items</th>
                        <th>Value</th>
                        <th>Details</th>
                    </tr>

                    </thead>
                    <tbody>
                    {
                        orders.map((order) => {
                            return <tr>
                                <td>{order.order_id}</td>
                                <td>{moment(order.created_at).format("DD-MM-YYYY HH:mm:ss")}</td>
                                <td>{order.quantity}</td>
                                <td>{order.totalPrice}</td>
                                <td>
                                    <NavLink
                                        to={"/account/order-status-details/" + order.order_id}
                                        exact>
                                        <CustomButton title={"Details"} type={"blue"} />
                                    </NavLink>
                                </td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
            </Fragment>
        )
    }
}

export default OrdersDataTable;