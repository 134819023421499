import React, { Component } from "react";
import { Doughnut } from 'react-chartjs-2';
import 'chartjs-plugin-labels'
import "admin/administrator/LookupCharts";

export default class LookupChartsPlate extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {

        }

    }

    render() {
        
        return (
            <div>
                <Doughnut
                    data={this.props.plates}
                    options={{
                        cutoutPercentage: 50,
                        animation: {
                            animateScale: true
                        },
                        plugins: {
                            labels: [
                                {
                                    render: 'value',
                                    position: 'inside',
                                    fontColor: '#e8e8e8',
                                }
                            ]
                        }
                    }}
                    redraw
                />
            </div>
        )
    }
}