import React, {Component} from "react";
import _ from "lodash";
// import "admin/administrator/Communication.css";
import axios from "axios1";
import {GridLoader} from 'react-spinners';
import alertify from 'alertifyjs';
import PageTitle from "admin/administrator/PageTitle";
import AlertAddresses from 'admin/administrator/AlertAddresses.js';
import CustomButton from "admin/administrator/CustomButton";
import {BrowserRouter as Router, Route, NavLink} from "react-router-dom";
import Schedule from 'admin/administrator/Schedule.js';
import ActivityTrends from "admin/administrator/ActivityTrends";

export default class Communication extends Component {
    constructor(props) {
        super(props);

        this.state = {
            emails: [],
            ftps: [],
            loading: false,
            errors: [],
            formFtp: {
                name: '',
                host: '',
                port: '',
                username: '',
                password: ''
            },
            formEmail: {
                name: '',
                email: ''
            },
            errorsFtp: [],
            errorsEmail: [],
            settings: [],
        }

        this.getCommunications();
        this.updateAlertAddressesValues = this.updateAlertAddressesValues.bind(this);
        this.updateScheduleValues = this.updateScheduleValues.bind(this);
    }

    updateAlertAddressesValues(settings) {
        let currentSettings = {...this.state.settings};
        currentSettings.system_from_address = settings.system_from_address;
        currentSettings.price_alerts_to = settings.price_alerts_to;
        currentSettings.stock_alerts_to = settings.stock_alerts_to;
        this.setState({settings: currentSettings});
    }

    updateScheduleValues(settings) {
        let currentSettings = {...this.state.settings};
        currentSettings.check_ftp_min = settings.check_ftp_min;
        currentSettings.send_emails_min = settings.send_emails_min;
        this.setState({settings: currentSettings});
    }

    getCommunications() {
        axios.get("catalogue/communications/")
            .then((response) => {
                this.setState({
                    emails: response.data.emails,
                    ftps: response.data.ftps,
                    settings: response.data.settings
                });
            });
    }

    render() {
        alertify.defaults = {
            notifier: {
                position: 'top-right',
                delay: 5
            }
        }

        return (
            <div>

                {
                    this.state.loading ?
                        <div className='sweet-loading'>
                            <GridLoader
                                sizeUnit={"px"}
                                size={15}
                                color={'#0066b8'}
                                loading={this.state.loading}
                            />
                        </div> : null
                }
                <div className={"page-content admin-wrapper"}>
                    <div className={""}>
                        <div className={""}>
                            <div className={"dashboard-page-body"}>
                                <div className={"dashboard-communication-body"}>

                                    <div class="communication-type-label">NOTIFICATIONS</div>
                                    <div className={"components-wrapper"}>
                                        <div className={"alert_address-component-wrapper"}><AlertAddresses
                                            settings={this.state.settings}
                                            updateAlertAddressesValues={this.updateAlertAddressesValues}/></div>
                                        <div className={"schedule-component-wrapper"}><Schedule
                                            settings={this.state.settings}
                                            updateScheduleValues={this.updateScheduleValues}/></div>
                                    </div>
                                    <div className={"communication-type-wrapper"}>
                                        <div className={"communication-list"}>
                                            <div className={"communication-type-label"}>Email Addresses</div>
                                            {
                                                this.state.emails.map((email, index) => {
                                                    return <div key={index} className={"communication-list-item"}>
                                                        <div className={"email-wrapper"}>
                                                            <div className={"default-input-wrapper"}>
                                                                {
                                                                    email.default ?
                                                                        <div className={"default-email"}>Default</div> :
                                                                        <div className={"set-default-email"}
                                                                             data-index={index}
                                                                             onClick={this.handleSetDefaultEmail.bind(this)}> Set
                                                                            as
                                                                            Default</div>
                                                                }
                                                                <input className={"email-input"} value={email.email}
                                                                       data-index={index}
                                                                       onChange={this.handleEmailChange.bind(this)}
                                                                       type={"email"}
                                                                       placeholder={"Email Address"}/>
                                                            </div>
                                                            {
                                                                this.state.errors["emails." + index + ".email"] ?
                                                                    <span
                                                                        className="error">{this.state.errors["emails." + index + ".email"]}</span>
                                                                    : null
                                                            }
                                                        </div>

                                                        <div className={"email-name-input"}>
                                                            <input className={"simple-input"} value={email.name}
                                                                   data-index={index}
                                                                   onChange={this.handleNameChange.bind(this)}
                                                                   type={"text"}
                                                                   placeholder={"Name"}/>
                                                            {
                                                                this.state.errors["emails." + index + ".name"] ?
                                                                    <span
                                                                        className="error">{this.state.errors["emails." + index + ".name"]}</span>
                                                                    : null
                                                            }
                                                        </div>

                                                    </div>
                                                })
                                            }

                                            {/* add section */}
                                            <div className={"communication-list-item"}>
                                                <div className={"email-wrapper"}>
                                                    <div className={"default-input-wrapper"}>
                                                        <div className={"addNewEmail text-center"}
                                                             onClick={this.handelEmailAddClick.bind(this)}>Add New
                                                        </div>
                                                        <div className={"nameErrorWrapper"}>
                                                            <input className={"email-input"} autocomplete="off"
                                                                   type={"email"}
                                                                   placeholder={"Email Address"}
                                                                   value={this.state.formEmail.email}
                                                                   onChange={this.handleEmailAddChange.bind(this, "email")}/>
                                                            {
                                                                this.state.errorsEmail["email"] ?
                                                                    <span
                                                                        className="error">{this.state.errorsEmail["email"]}</span>
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className={"email-name-input"}>
                                                    <input className={"simple-input"} type={"text"} autocomplete="off"
                                                           placeholder={"Name"} value={this.state.formEmail.name}
                                                           onChange={this.handleEmailAddChange.bind(this, "name")}/>
                                                    {
                                                        this.state.errorsEmail["name"] ?
                                                            <span
                                                                className="error">{this.state.errorsEmail["name"]}</span>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                            {/* add section over */}
                                        </div>
                                    </div>
                                    <div className={"communication-type-wrapper"}>
                                        <div className={"communication-list"}>
                                            <div className={"communication-type-label"}>Ftp Accounts</div>

                                            {
                                                this.state.ftps.map((ftp, index) => {
                                                    return <div className={"communication-ftp-list-item"}>
                                                        <div className={"communication-ftp-list-item-first-row"}>
                                                            <div className={"communication-ftp-name-wrapper"}>
                                                                <div className={"default-input-wrapper"}>
                                                                    {
                                                                        ftp.default ?
                                                                            <div
                                                                                className={"communication-ftp-default"}> Default</div> :
                                                                            <div
                                                                                className={"communication-ftp-set-default"}
                                                                                data-index={index}
                                                                                onClick={this.handleSetDefaultFtp.bind(this)}> Set
                                                                                as Default</div>
                                                                    }
                                                                    <input className={"communication-ftp-name"}
                                                                           value={ftp.name}
                                                                           data-property={"name"} data-index={index}
                                                                           onChange={this.handleInputChange.bind(this)}
                                                                           placeholder={"Name"}></input>
                                                                </div>
                                                                {
                                                                    this.state.errors["ftps." + index + ".name"] ?
                                                                        <span
                                                                            className="error">{this.state.errors["ftps." + index + ".name"]}</span>
                                                                        : null
                                                                }
                                                            </div>
                                                            <div className={"ftp-host-port-wrapper"}>
                                                                <div className={"communication-ftp-host"}>
                                                                    <input className={"simple-input"}
                                                                           data-property={"host"}
                                                                           data-index={index}
                                                                           onChange={this.handleInputChange.bind(this)}
                                                                           value={ftp.host} placeholder={"Host"}>

                                                                    </input>
                                                                    {
                                                                        this.state.errors["ftps." + index + ".host"] ?
                                                                            <span
                                                                                className="error">{this.state.errors["ftps." + index + ".host"]}</span>
                                                                            : null
                                                                    }
                                                                </div>

                                                                <div className={"communication-ftp-port"}>
                                                                    <input className={"simple-input"}
                                                                           data-property={"port"}
                                                                           data-index={index}
                                                                           onChange={this.handleInputChange.bind(this)}
                                                                           value={ftp.port} placeholder={"Port"}>

                                                                    </input>
                                                                    {
                                                                        this.state.errors["ftps." + index + ".port"] ?
                                                                            <span
                                                                                className="error">{this.state.errors["ftps." + index + ".port"]}</span>
                                                                            : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className={"communication-list-item-second-row"}>
                                                            <div className={"communication-ftp-username"}>
                                                                <input className={"simple-input"}
                                                                       data-property={"username"}
                                                                       data-index={index}
                                                                       onChange={this.handleInputChange.bind(this)}
                                                                       value={ftp.username} placeholder={"Username"}>

                                                                </input>
                                                                {
                                                                    this.state.errors["ftps." + index + ".username"] ?
                                                                        <span
                                                                            className="error">{this.state.errors["ftps." + index + ".username"]}</span>
                                                                        : null
                                                                }
                                                            </div>

                                                            <div className={"communication-ftp-password"}>
                                                                <input className={"simple-input"}
                                                                       data-property={"password"}
                                                                       data-index={index}
                                                                       onChange={this.handleInputChange.bind(this)}
                                                                       value={ftp.password} placeholder={"Password"}>

                                                                </input>
                                                                {
                                                                    this.state.errors["ftps." + index + ".password"] ?
                                                                        <span
                                                                            className="error">{this.state.errors["ftps." + index + ".password"]}</span>
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                })
                                            }


                                            {/* add new ftp section */}
                                            <div className={"communication-ftp-list-item"}>
                                                <div className={"communication-ftp-list-item-first-row"}>
                                                    <div className={"communication-ftp-name-wrapper"}>
                                                        <div className={"default-input-wrapper"}>
                                                            <div className={"addNewEmail text-center"}
                                                                 onClick={this.handelFtpAddClick.bind(this)}>Add New
                                                            </div>
                                                            <div className={"nameErrorWrapper"}>
                                                                <input className={"communication-ftp-name"}
                                                                       value={this.state.formFtp.name}
                                                                       onChange={this.handleFtpAddChange.bind(this, "name")}
                                                                       placeholder={"Name"}></input>
                                                                {
                                                                    this.state.errorsFtp["name"] ?
                                                                        <span
                                                                            className="error">{this.state.errorsFtp["name"]}</span>
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={"ftp-host-port-wrapper"}>
                                                        <div className={"communication-ftp-host"}>
                                                            <input className={"simple-input"} data-property={"host"}
                                                                   placeholder={"Host"} value={this.state.formFtp.host}
                                                                   onChange={this.handleFtpAddChange.bind(this, "host")}></input>

                                                            {
                                                                this.state.errorsFtp["host"] ?
                                                                    <span
                                                                        className="error">{this.state.errorsFtp["host"]}</span>
                                                                    : null
                                                            }
                                                        </div>

                                                        <div className={"communication-ftp-port"}>
                                                            <input className={"simple-input"} data-property={"port"}
                                                                   placeholder={"Port"} value={this.state.formFtp.port}
                                                                   onChange={this.handleFtpAddChange.bind(this, 'port')}></input>
                                                            {
                                                                this.state.errorsFtp["port"] ?
                                                                    <span
                                                                        className="error">{this.state.errorsFtp["port"]}</span>
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={"communication-list-item-second-row"}>
                                                    <div className={"communication-ftp-username"}>
                                                        <input className={"simple-input"} data-property={"username"}
                                                               placeholder={"Username"}
                                                               value={this.state.formFtp.username}
                                                               onChange={this.handleFtpAddChange.bind(this, 'username')}></input>
                                                        {
                                                            this.state.errorsFtp["username"] ?
                                                                <span
                                                                    className="error">{this.state.errorsFtp["username"]}</span>
                                                                : null
                                                        }
                                                    </div>

                                                    <div className={"communication-ftp-password"}>
                                                        <input className={"simple-input"} data-property={"password"}
                                                               placeholder={"Password"}
                                                               value={this.state.formFtp.password}
                                                               onChange={this.handleFtpAddChange.bind(this, 'password')}></input>
                                                        {
                                                            this.state.errorsFtp["password"] ?
                                                                <span
                                                                    className="error">{this.state.errorsFtp["password"]}</span>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {/* add new ftp end */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"dashboard-page-footer"}>
                                <div className={"communication-footer-wrapper"}>
                                    <NavLink to={"/admin"}>
                                        <CustomButton title={"Cancel"} type={"lightgray"}/>
                                    </NavLink>
                                    <CustomButton title={"Save"} type={"green"}
                                                  onClick={this.handleSaveClick.bind(this)} margins={['ml-2']}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <ActivityTrends width={1000} height={500} /> */}
            </div>
        );
    }

    handleSaveClick() {
        this.setState({loading: true});
        axios.put("catalogue/communications/", this.state)
            .then((response) => {
                this.setState({loading: false})
                window.scrollTo(0, 0);
                if (response.data.success) {
                    alertify.success(response.data.msg);
                } else {
                    this.setState({errors: response.data.errors});
                    alertify.error(response.data.msg);
                }
            });
    }

    handelEmailAddClick() {
        this.setState({loading: true});
        axios.post("catalogue/communications/email", this.state.formEmail)
            .then((response) => {
                this.setState({loading: false})
                if (response.data.success) {
                    alertify.success(response.data.msg);
                    this.setState({emails: response.data.emails, formEmail: {name: '', email: ''}});
                } else {
                    this.setState({errorsEmail: response.data.errors});
                    alertify.error(response.data.msg);
                }
            });
    }

    handelFtpAddClick() {
        this.setState({loading: true});
        axios.post("catalogue/communications/ftp", this.state.formFtp)
            .then((response) => {
                this.setState({loading: false})
                if (response.data.success) {
                    alertify.success(response.data.msg)
                    this.setState({
                        ftps: response.data.ftps,
                        formFtp: {name: '', port: '', host: '', username: '', password: ''}
                    });
                } else {
                    this.setState({errorsFtp: response.data.errors});
                    alertify.error(response.data.msg);
                }
            });
    }


    handleFtpAddChange(formName, event) {
        let value = event.target.value;

        let formFtp = {...this.state.formFtp};

        formFtp[formName] = value;

        this.setState({formFtp: formFtp});
    }

    handleEmailAddChange(formName, event) {
        let value = event.target.value;

        let formEmail = {...this.state.formEmail};

        formEmail[formName] = value;

        this.setState({formEmail: formEmail});
    }

    handleInputChange(event) {
        let index = event.target.getAttribute("data-index");
        let property = event.target.getAttribute("data-property");

        let ftps = [...this.state.ftps];

        ftps[index][property] = event.target.value;

        this.setState({ftps: ftps});
    }

    handleEmailChange(event) {
        let index = event.target.getAttribute("data-index");

        let emails = [...this.state.emails];

        emails[index].email = event.target.value;

        this.setState({emails: emails});
    }

    handleNameChange(event) {
        let index = event.target.getAttribute("data-index");

        let emails = [...this.state.emails];

        emails[index].name = event.target.value;

        this.setState({emails: emails});
    }

    handleSetDefaultEmail(event) {
        let index = event.target.getAttribute("data-index");

        let emails = [...this.state.emails];

        // Remove old default email
        _.map(emails, (email) => {
            email.default = false;
            return email;
        })

        emails[index].default = true;

        this.setState({emails: emails});
    }

    handleSetDefaultFtp(event) {
        let index = event.target.getAttribute("data-index");

        let ftps = [...this.state.ftps];

        // Remove old default ftp
        _.map(ftps, (ftp) => {
            ftp.default = false;
            return ftp;
        })

        ftps[index].default = true;

        this.setState({ftps: ftps});
    }
}
