import React from 'react';
import FormInput from "admin/administrator/FormInput/FormInput.component";
import CustomButton from "admin/administrator/CustomButton";
import Notify from "components/Notify";
import { useTranslation } from 'react-i18next';

function PartNumberInput(props) {
    const { t, i18n } = useTranslation();
    return (
        <div className="flex">
            <FormInput
                name='partNumber'
                type='text'
                placeholder={props.placeholder}
                error={""}
                value={props.partNumber}
                onChange={e => props.handlePartNumberChange(e)}
                onKeyDown={ e => props._handlePartNumberKeyDown(e) }
                disabled={props.isDisabled || false}
            />
            <CustomButton title={t("Go")} 
                        type={"blue"} 
                        onClick={e => props.handleFindButtonClick(e)} 
                        disabled={props.isDisabled || false}
                        style={{borderTopLeftRadius: "0", borderBottomLeftRadius: "0", textTransform: "uppercase"}}/>
            <Notify type={"primary"}
                    visible={props.isShowing}
                    close={props.closeModalHandler}
                    primaryText={t("The part number you entered could not be found")}
                    secondaryText={t("Try search by vehicle selection or registration")}
                    actions={[
                        {
                            name: "ok",
                            action: props.closeModalHandler,
                            type: "blue"
                        }
                    ]}/>
        </div>
    )
};

export default PartNumberInput;