import React from "react";
import {GridLoader} from 'react-spinners';

const CustomLoading = ({loading, color, position, opacity}) => {
    const customSweetLoadingStyle = {
        position: position || "absolute",
        width: "100%",
        height: "100%",
        left: "0px",
        top: "0px",
        background: color || "#f5f5f5",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: "10000",
        opacity: opacity || "0.9"
    };

    return loading ? 
                <div style={customSweetLoadingStyle}>
                    <GridLoader
                        sizeUnit={"px"}
                        size={15}
                        color={'#0066b8'}
                        loading={loading}
                    />
                </div>
            :null
}
  
export default CustomLoading;