import React, { Fragment, useState, useEffect} from "react";
import CustomReactTable from "admin/administrator/CustomReactTable.js";
import CustomContainer from 'custom_components/CustomContainer.js';
import CustomModal from "admin/administrator/customModal.js";
// import "admin/administrator/CustomTotalLogins.css";
import axios from "axios1";
import _ from "lodash";
import Modal from "components/Modal";
import { useTranslation } from 'react-i18next';

const moment = require('moment');

const CustomTotalLogins = (props) => {
    const [show, setShow] = useState(false);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(0);
    const { t, i18n } = useTranslation();

    const columns = React.useMemo(
        () => [
            {
                Header: t("Date/Time"),
                accessor: "date",
                className: "textLeft",
                Cell: ({value, original}) => (moment(value,'DD/MM/YYYY HH-mm').format('DD-MM-YYYY HH:mm'))
            },
            {
                Header: t("Duration"),
                accessor: "duration",
                className: "textLeft"
            },
            {
                Header: t("VRM Lookups"),
                accessor: "vrm",
                className: "textRight"
            },
            {
                Header: t("Vehicle Lookups"),
                accessor: "vehicle",
                className: "textRight"
            },
            {
                Header: t("Part No Lookups"),
                accessor: "partNumber",
                className: "textRight"
            }
        ]
    );

    const getTotalLogins = (user_id, date, page) => {
        axios.get("stats/users/" + user_id + "/logins?page=" + page, {params: {date: date}} )
        .then((response) => {
            if (response.data.success) {
                setPage(page);
                setPages(response.data.data.pages);
                setData(response.data.data.logins);
            }
        })
    }
    
    return  (<Fragment>
                <div className={"custom-row-underline"} onClick={() => {
                    setShow(true);
                    getTotalLogins(props.original.id, props.date, page)
                }}>
                   {props.value}
                </div>
                <Modal size="lg" title={props.original.login + " - " + (props.branch.name ? props.branch.name.replace(`_deleted_${props.branch.id}`, "") :props.branch.name) + " - " + moment(props.date).format("MMMM YYYY")} type="light" active={show} closeModal={() => setShow(false)}>
                    <CustomReactTable columns={columns} 
                                    data={data} 
                                    pagination={true} 
                                    getData={getTotalLogins.bind(this, props.original.id, props.date)} page={page} pages={pages} />
                </Modal>
        </Fragment> );
}

export default CustomTotalLogins;
