import React, { Component } from "react";
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from "react-redux";
import { getUser, setCart, setCompare } from "redux/actions/authActions";
import { reloadSettings } from "redux/actions/settings";
import 'components/Login/LoginForm.css';
import { updateOutstandingOrdersQty } from "redux/actions/orders";
import LogoObject from "components/clients/MainLogo.js";

var store = require('store');

class LoginForm extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            styleFor: process.env.REACT_APP_CLIENT,
            email: '',
            password: '',
            errors: [],
            isChecked: false,
            styleColors: {
                'default': '#F9A700',
                'newNordic': 'black',
                'gt': 'black',
                'stoneacre': '#348e44',
                'eurobrake': '#009dd8',
                'viking': 'black',
                'titan': 'black',
                'nk': '#FC3000',
                'quickco': '#55BB44'
            }
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    showNda() {
        this.props.setShowNda();
    }

    handleCheck() {
        this.setState({isChecked: !this.state.isChecked});
    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;

        this.setState({[name]: target.value});
    }

    handleSubmit = (e, email, password) => {
        e.preventDefault();
        email = this.state.email;
        password = this.state.password;

        this.props.getUser(email, password).then((response) => {
            if(!response.token) {
                this.setState({errors: response.errors})
                return false;
            }
            this.props.reloadSettings();
            store.set("token", response.token);
            store.set("user", response.user);
            this.props.history.push('/search');
            this.props.setCart();
            this.props.setCompare();
            this.props.updateOutstandingOrdersQty();
        });
    }

    render() {
        return (
            <div className={ "loginWrapper login-inputs-wrapper " + (this.state.styleFor == 'nk' ? 'nk' : (this.state.styleFor == 'gt' ? 'gt' : (this.state.styleFor == 'titan' ? 'titan' : 'default') ))}>
                {
                    this.state.styleFor == "quickco" ? 
                        <img style={{ width: '272px' }} className={"auth-logo"} src={LogoObject.logo} /> : null
                } 
                <form className={"login-form nika2"} data-testid="loginForm" onSubmit={this.handleSubmit}>
                    <div className={"login-form-title"}>
                        Welcome Back!
                    </div>
                    <div className={"login-form-body"}>
                        {
                            this.state.errors['error'] ?
                            <span className="global-error">{this.state.errors['error']}</span>
                            : null
                        }
                        <div className="form-group">
                            <input type="text"
                                   data-testid="loginEmail"
                                   className={this.state.errors["email"] ? "input-error" : null}
                                   value={this.state.email}
                                   name="email"
                                   placeholder={this.state.errors["email"] ? "Email is required*" : "Email"}
                                   onChange={this.handleInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <input type="password"
                                   data-testid="loginPass"
                                   className={this.state.errors["password"] ? "input-error" : null}
                                   value={this.state.password}
                                   name="password"
                                   placeholder={this.state.errors["password"] ? "Password is required*" : "Password"}
                                   onChange={this.handleInputChange}
                            />
                        </div>
                        {/*{*/}
                            {/*allowRegistration ?*/}
                                {/*<div className={"login-form-body-footer"}>*/}
                                    {/*<div className={"no-account-label"}>Don't have an account</div>*/}
                                    {/*<NavLink to="/Registration" className="reg-link">Sign up now</NavLink>*/}
                                {/*</div> : null*/}
                        {/*}*/}
                    </div>
                    {
                     ['newNordic', 'spartan', 'gt', "viking", "titan"].includes(this.state.styleFor ) ? null:
                    
                        <div className={"login-form-nda"}>
                            <div className={"nda-right"}>
                                <input type={"checkbox"} onChange={this.handleCheck.bind(this)} value={this.state.isChecked} defaultChecked={this.state.isChecked}/>
                            </div>
                            

                            <div className={"nda-left"}> 
                                I have read and agree with the <span onClick={this.showNda.bind(this)}
                                style={{
                                    color: (this.state.styleColors[this.state.styleFor] || this.state.styleColors['default']) 
                                }}
                                
                                >Non Disclosure Agreement</span> for this Beta Testing Version 1.0.0
                            </div>
                        </div>
                    }
                    <span className={"registration-success"}>{this.state.errors["approval"]}</span>
                    <div className="form-footer">
                        {/* <button data-testid="loginBtn" type="submit" className="btn"  disabled={!this.state.isChecked}>Login</button> */}
                        <button data-testid="loginBtn" type="submit" className="btn" style={{
                            background: (this.state.styleColors[this.state.styleFor] || this.state.styleColors['default']) 
                        }}>Login</button>
                    </div>
                    {
                        this.state.errors['warning'] ?
                            <div className="global-warning">
                                <h3 className="global-warning-title">Pending Account</h3>
                                <p className="global-warning-desc">{ this.state.errors['warning'] }</p>
                            </div>
                        : null
                    }
                </form>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    settings: state.settings,
})

const mapDispatchToProps = {
    getUser: getUser,
    setCart: setCart,
    setCompare: setCompare,
    reloadSettings: reloadSettings,
    updateOutstandingOrdersQty: updateOutstandingOrdersQty
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginForm));
