var store = require('store');

const settings = (state = {  }, action) => {
    switch (action.type) {
        case 'INITIAL_SETTINGS':
            return setInitialSettings(state, action);
        case 'CHANGE_THEME':
            return changeTheme(state, action);
        default:
            return state
    }
}

let setInitialSettings = (state, action) => {
    return action.settings;
}

let changeTheme = (state, action) => {
    state.theme = action.payload;
    return state;
}

export default settings