import React, {Fragment, useEffect, useState, useRef} from 'react';
import { connect, useDispatch } from "react-redux"
import { addProduct } from "redux/actions/shoppingCart";
import _ from "lodash";
import alertify from "alertifyjs";
import "components/AddToCartBtn.css";
import Loading from "admin/administrator/LoadingComponent/Loading.component";
import { useTranslation } from 'react-i18next';

const AddToCartBtn = ({lookup, section_lookup_id, product, alternatives, settings, products, stock = null, disabled = false}) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const [index, setIndex] = useState(0);
    const [stockProduct, setStockProduct] = useState([]);
    const [textInput, setTextInput] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const inputRef = useRef(null);

    const handleAddToCartClick = (stockpr, index) => {
        if(disabled) {
            return;
        }
        setLoading(true);
        const handleStock = JSON.parse(JSON.stringify(stockProduct));

        // if(handleStock.account_id != handleStock.branch_id) {
        //     // if(product.defaultStockQty > 0 || handleStock.stock <= 0) return;
        //     if(handleStock.stock < (parseInt(quantity) + parseInt(textInput)) || handleStock.stock <= 0) {
        //         alertify.error(t("Out of stock")+"!");
        //         setLoading(false);
        //         return;
        //     }
        //     dispatch(addProduct(handleStock, quantity, section_lookup_id))
        //         .then(() => {
        //             setLoading(false);
        //         });
        // } else {
        
        // if(handleStock.stock <= 0) return;
        // if (handleStock.stock <= textInput || handleStock.stock < quantity || handleStock.stock < (parseInt(quantity) + parseInt(textInput))) {
        //     alertify.error(t("Out of stock")+"!");
        //     setLoading(false);
        //     return;
        // }

        dispatch(addProduct(handleStock, quantity, section_lookup_id))
        .then(() => {
            setLoading(false);
        });        
    }

    const generateProductForCart = () => {
        let p = product;
        p.part_number_id = product.part_number_id;
        p.part_number = {
            part_number: product.part_number.part_number, // cl
            allImages: product.part_number.allImages, // cl
            section: {name: product.part_number.section.name} // cl
        };
        p.price = parseFloat(p.price.toFixed(2));
        p.lookup = lookup; // cl
        p.section_lookup_id = section_lookup_id; // cl-bk
        p.images = product.part_number.allImages; // cl
        p.stock = p.quantity;
        return p;
    }

    const handleQuantityChange = (value) => {
        let quantity = value <= 0 || !isNumber(value) ? 1 : value;
        console.log('quantity', quantity)
        setQuantity(quantity);
    }

    const findIndex = (products, id) => {
        let productIndex = _.findIndex(products, (element) => {
            return element.part_number_id === id;
        });
        setIndex(productIndex);
        setTextInput((products[productIndex] ? products[productIndex].quantity : "0"))
    }

    // useEffect(() => {
    //     alertify.defaults = {
    //         notifier: {
    //             position: 'top-right',
    //             delay: 5
    //         }
    //     }
    //     setStockProduct(generateProductForCart())
    //     findIndex(products, product.part_number_id);
    // }, []);

    useEffect(() => {
        alertify.defaults = {
            notifier: {
                position: 'top-right',
                delay: 5
            }
        }
        setStockProduct(generateProductForCart())
        findIndex(products, product.part_number_id);
    }, [products, product]);

    function isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }

    return (
        <Fragment>
            <Loading loading={loading}/>
            {
                // parseInt(stockProduct.quantity)  >= 0 ?
                    <div className="cart-btn ml-8"> 
                        <input type="text"
                                className={'cart-btn__input' + (!disabled ? "" : " cart-btn__input--disabled")}
                                step="1"
                                min='0'
                                ref={inputRef}
                                onChange={e => handleQuantityChange(e.target.value)}
                                onKeyPress={event => { if(event.key == "Enter") inputRef.current.blur() }}
                                value={quantity}
                                disabled = {!disabled ? "" : "disabled" }/>
                        <span
                            title={t("Add to cart")}
                            className={'cart-btn__btn' + (!disabled ? "" : " cart-btn__btn--disabled")}
                            onClick={() => handleAddToCartClick(stockProduct, index)}>
                            {
                                settings.show_prices ?
                                    <span>
                                        {(alternatives != 1) ? parseFloat(stockProduct.price).toFixed(2) : t("Add To Cart")}
                                    </span>
                                : null
                            }
                            <i className="fas fa-shopping-cart"></i>
                            {
                                textInput > 0 && <span className="badge badge--danger">{textInput}</span>
                            }
                        </span>
                    </div>
                // : null
            }
        </Fragment>
    );
}

const mapStateToProps = state => ({
    products: state.shoppingCart,
    settings: state.settings
})

export default connect(mapStateToProps)(AddToCartBtn);