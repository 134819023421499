export const selectLookupsMonth = (month) => ({
    type: 'SELECT_LOOKUPS_MONTH',
    month: month
})

export const selectTopAccountMonth = (month) => ({
    type: 'SELECT_TOP_ACCOUNTS_MONTH',
    month: month
})

export const selectLoginTotalsMonth = (timeInterval) => ({
    type: 'SELECT_LOGIN_TOTALS_MONTH',
    timeInterval: timeInterval
})