import React, {Component, Fragment} from "react";
import _ from "lodash";

class OrdersUserActionTable extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let orders = this.props.orders;
        return (
            <Fragment>
                <table className={"custom-table table"}>
                    <thead>

                    <tr>
                        <th>Completed</th>
                    </tr>

                    </thead>
                    <tbody>
                    {
                        orders.map((order) => {
                            return <tr>
                                <td>
                                    {
                                        order.is_completed ?
                                            <i className="fas fa-check fa-2x" style={{color: 'green'}}></i>
                                            : <i className="fas fa-times fa-2x" style={{color: 'red'}}></i>
                                    }
                                </td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
            </Fragment>
        )
    }
}

export default OrdersUserActionTable;