import React, { Component } from "react";
import RegistrationForm from 'components/Registration/RegistrationForm';

export default class Login extends Component
{
    constructor(props)
    {
        super(props);
    }

    render()
    {
        return (
            <div>
                <RegistrationForm />
            </div>
        );
    }
}