import { combineReducers } from 'redux';
import shoppingCart from 'redux/reducers/shoppingCart';
import compareList from "redux/reducers/compareList";
import auth from "redux/reducers/auth";
import products from 'redux/reducers/products';
import settings from "redux/reducers/settings";
import searchResult from 'redux/reducers/searchResult';
import statistic from  'redux/reducers/statistic';
import orders from  'redux/reducers/orders';
import deleteAccounts from 'redux/reducers/deleteAccounts';
import dashboardDate from 'redux/reducers/dashboardDate';

export default combineReducers({
    shoppingCart,
    compareList,
    auth,
    products,
    settings,
    searchResult,
    statistic,
    orders,
    deleteAccounts,
    dashboardDate
})