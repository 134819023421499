import React, { Component } from "react";
import "components/CaptureScreenshot.css";
import {Container, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup} from "react-bootstrap";
import axios from 'axios1';

class CaptureScreenshot extends Component {

    constructor(props) {
        super(props);
    }

    closeModal() {
        this.props.setStateFromChild("showCaptureModal", false);
    }

    render() {
        return (
            <Modal
                show={this.props.showCaptureModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={this.closeModal.bind(this)}
                dialogClassName="screenshot-modal"
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h1>sss</h1>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div id={"screenshot-wrapper"} className={"screenshot-wrapper"}></div>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        )
    }
}

export default CaptureScreenshot;