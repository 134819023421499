import React, {useEffect} from 'react';
import {Provider} from 'react-redux';
import store from "redux/store";
import {BrowserRouter as Router} from "react-router-dom";
import AppRouter from "components/catalogue/AppRouter";
import $ from "jquery";
import i18n from 'i18next';

var cookieStore = require('store');

let client = "";
switch(process.env.REACT_APP_CLIENT){
    case "newNordic":
        client = 'Nordic';
        break;
    case "quickco":
        client = 'Quickco';
        break;
    case "gt":
        client = 'GT';
        break;
    case "titan":
        client = 'Titan';
        break;
    case "stoneacre":
        client = 'Stoneacre';
        break;
    case "eurobrake":
        client = 'Eurobrake';
        break;
    default: break;
}

function App(props) {

    useEffect(() => {
        
        if(localStorage.getItem("lang")){
            i18n.changeLanguage(localStorage.getItem("lang"));
        }

        document.querySelector("link[rel*='icon']").href = window.location.origin+"/favicons/"+client+".ico";
        document.title = client + " Catalogue";
        $(window).scroll(function () {
            let vehicle = document.getElementById('vehicle');
            let brandsWrapper = document.getElementById('brandsWrapper');
            if ($(this).scrollTop() > 50) {
                $(".topMenu").addClass("blackBackground")
            }
            if ($(this).scrollTop() < 50) {
                $(".topMenu").removeClass("blackBackground")
            }

            if(document.contains(vehicle)) {
                if($(this).scrollTop() > 200) {
                    vehicle.classList.add('vehicle--sticky');
                } else {
                    vehicle.classList.remove('vehicle--sticky');
                }
            }

            if(document.contains(brandsWrapper)) {
                if($(this).scrollTop() > 200) {
                    brandsWrapper.classList.add('sticky');
                } else {
                    brandsWrapper.classList.remove('sticky');
                }
            }
        });
    }, []);

    return (
        <Provider store={store}>
            <Router>
                <AppRouter/>
            </Router>
        </Provider>
    );
}

export default App
