import React, {useState, useEffect} from "react";
import FileName from "components/FileName";
import CustomLabel from "components/CustomLabel";
import CustomDate from "components/CustomDate";
import CustomButton from "admin/administrator/CustomButton";
import UploadedProductsFileModal from "components/UploadedProductsFileModal";
import "components/EditUploadedProductsFile.css";
import alertify from "alertifyjs";
import axios from "axios1";
import {GridLoader} from 'react-spinners';
import { useTranslation } from 'react-i18next';

var moment = require('moment');

const EditUploadedProductsFile = (props) => {
    const { t, i18n } = useTranslation();
    const [fileName, setFileName] = useState(null);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [initialData, setInitialData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentProducts, setCurrentProducts] = useState([]);
    const [updatedData, setUpdatedData] = useState([]);
    const [currentPage, setCurrentPage] = useState(null);
    const [totalPages, setTotalPages] = useState(null);
    const [counter, setCounter] = useState(0);
    const [brands, setBrands] = useState([]);
    const [partNumbers, setPartNumbers] = useState([]);
    const [priceLevelValueTypeId, setPriceLevelValueTypeId] = useState(1);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setData(props.products);
        setFilteredData(props.products);
        setInitialData(props.initialProducts);
        setFileName(props.file.name);
        setPriceLevelValueTypeId(props.priceLevelValueTypeId);
    }, [props.products]);

    useEffect(() => {
        setCounter(props.priceLevelValue);
    }, [props.priceLevelValue]);

    useEffect(() => {
        redrawPagination(filteredData.length, currentPage);
    }, [counter]);

    useEffect(() => {
        redrawPagination(filteredData.length, currentPage);
    }, [data, priceLevelValueTypeId]);

    function redrawPagination(totalRecords, currentPage, pageLimit = 10) {
        const paginationData = {
            currentPage: currentPage,
            pageLimit: pageLimit,
            totalRecords: totalRecords
        };
        onPageChanged(paginationData);
    }

    function onPageChanged(data) {
        const {currentPage, totalPages, pageLimit} = data;
        const offset = (currentPage - 1) * pageLimit;

        let currentProducts = filteredData.slice(offset, offset + pageLimit);

        if (priceLevelValueTypeId == 2) {
            console.log("ss");
            currentProducts = currentProducts.map((product) => {
                product[2] = product[1] * (1 + (counter / 100)).toFixed(2);
                return product;
            });
        } else {
            currentProducts = currentProducts.map((product) => {
                product[2] = product[1] / (1 - (counter / 100)).toFixed(2);
                return product;
            });
        }
        setCurrentProducts(currentProducts);
        setCurrentPage(currentPage);
        setTotalPages(totalPages);
    };

    function closeModal() {
        setShowModal(false);
        setFilteredData(data);
        setBrands([]);
        setPriceLevelValueTypeId(0);
    }

    function openModal() {
        if (data.length > 0) {
            // if (data[0].length > 3) {
            //     alertify.error("Incorrect file format");
            //     return;
            // }
            setShowModal(true);
        } else {
            alertify.error("Choose file");
        }
    }

    function increaseCounter() {
        let c = parseInt(counter) + 1;
        if (c > 200) c = 200;
        setCounter(c);

    }

    function saveProducts() {
        let products = data;
        // if (counter > 0) {
        //     products.map((product) => {
        //         product.splice(1, 1);
        //     })
        // }
        props.setProducts(products);
        props.setPercentage(counter);
        props.setFileName(fileName);
        props.setPriceLevelValueTypeId(priceLevelValueTypeId);
        closeModal();
    }

    function changeCounter(e) {
        setCounter(e.target.value);
    }

    function checkPartNumberExistence() {
        setLoading(true);
        axios.post("catalogue/suppliers/check-part-number-existence", {
            'params': {'products': JSON.stringify(data)}
        }).then((response) => {
            setLoading(false);
            if (response.data.brands.length > 0) {
                setBrands(response.data.brands);
                setPartNumbers(response.data.partNumbers);
            } else {
                saveProducts();
            }
        });
    }

    function sayYes() {
        saveProducts();
    }

    function sayNo() {
        removeExistedPartNumbers(partNumbers);
    }

    function removeExistedPartNumbers(pn) {
        let dataWithoutExistedPartNumbers = data.filter(function (el) {
            return pn.indexOf(el[0]) < 0;
        });
        props.setProducts(dataWithoutExistedPartNumbers);
        props.setInitialProducts(dataWithoutExistedPartNumbers);
        props.setPriceLevelValueTypeId(priceLevelValueTypeId);
        setBrands([]);
        closeModal();
    }

    function handleFilterProducts(e) {
        let filteredProducts = data.filter((product) => {
            return (product[0].toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) ? product : null;
        });
        setFilteredData(filteredProducts);
        redrawPagination(filteredProducts.length, currentPage);
    }

    function handleSwitchChange(e) {
        setPriceLevelValueTypeId(e.target.value);
    }

    return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            {
                loading ?
                    <div className='sweet-loading'>
                        <GridLoader
                            sizeUnit={"px"}
                            size={15}
                            color={'#0066b8'}
                            loading={loading}
                        />
                    </div> : null
            }
            <CustomLabel title={t("Filename Uploaded")}/>
            <div>
                <div><FileName fileName={props.file.name}/></div>
                <div><CustomDate date={moment(props.file.updated_at).format("DD/MM/YYYY HH:mm a")}/></div>
            </div>
            <CustomButton title={t("Edit/View")} type={"blue"} onClick={openModal}/>
            {
                showModal &&
                    <UploadedProductsFileModal showModal={showModal}
                        onPageChanged={onPageChanged}
                        currentProducts={currentProducts}
                        closeModal={closeModal}
                        increaseCounter={increaseCounter}
                        changeCounter={changeCounter}
                        handleFilterProducts={handleFilterProducts}
                        saveProducts={saveProducts}
                        counter={counter}
                        totalProducts={filteredData.length}
                        supplier={props.supplier}
                        handleSwitchChange={handleSwitchChange}
                        priceLevelValueTypeId={priceLevelValueTypeId}
                        checkPartNumberExistence={checkPartNumberExistence}
                        brands={brands}
                        partNumbers={partNumbers}
                        sayYes={sayYes}
                        sayNo={sayNo}/>
            }
        </div>
    )
}

export default EditUploadedProductsFile;