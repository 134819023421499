import React, {Component} from "react";
// import "admin/administrator/BestSellers/BestSellers.css";
// import "admin/administrator/CustomElements.css";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios1";
import {GridLoader} from 'react-spinners';
import {Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup} from "react-bootstrap";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import CustomDatePicker from 'admin/administrator/CustomDatePicker';
var moment = require('moment');

class BestSellers extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            activeModal: null,
            products: [],
            filters: {
                account_id: null,
                date_from: "",
                date_to: "",
                group_id: null,
                section_id: null
            },
            selectedDate: {
                date_from: new Date(),
                date_to: new Date()
            },
            accounts: [],
            groups: [],
            sections: [],
            stocks: []
        }
    }

    componentDidMount() {
        this.getProducts();
        this.getAccounts();
        this.getGroups();
    }

    getProducts() {
        axios.get("catalogue/best-selling-products")
            .then((response) => {
                this.setState({products: response.data.products});
            })
    }

    handleChange(key, event) {
        if (key === "group_id") {
            this.getSectionsByGroupId(event.target.value);
        }
        let filters = this.state.filters;
        filters[key] = event.target.value;
        this.setState({filters: filters}, () => {
            this.filterProducts();
        });
    }

    handleChangeDate(key, date) {
        let selectedDate = this.state.selectedDate;
        let filters = this.state.filters;
        filters[key] = moment(date).format('YYYY-MM-DD');
        selectedDate[key] = date;
        this.setState({filters: filters, selectedDate: selectedDate}, () => {
            this.filterProducts();
        });
    }

    filterProducts() {
        let data = this.state.filters;
        axios.get("catalogue/best-selling-products", {params: data})
            .then((response) => {
                this.setState({products: response.data.products});
            })
    }

    getAccounts() {
        axios.get("catalogue/view-accounts")
            .then((response) => {
                let accounts = response.data.accounts.map((account) => {
                    return {accountId: account.accountId, accountName: account.accountName.toUpperCase()}
                });
                this.setState({accounts: accounts})
            });
    }

    getGroups() {
        axios.get("catalogue/groups")
            .then((response) => {
                this.setState({groups: response.data});
            });
    }

    getSectionsByGroupId(groupId) {
        axios.get("catalogue/preferences/sections", {params: {groupId: groupId}})
            .then((response) => {
                this.setState({sections: response.data});
            })
    }

    getAvailableStock(part_number) {
        let activeModal = this.state.activeModal && this.state.activeModal === part_number ? null : part_number;
        if(activeModal) {
            this.setState({loading: true});
            axios.get("catalogue/available-stock/" + part_number)
                .then((response) => {
                    this.setState({stocks: response.data.stock, activeModal: part_number, loading: false});
                })
        } else {
            this.setState({activeModal: null});
        }
    }

    render() {
        return (
            <div className={"custom-wrapper"}>
                <div className={"custom-heading"}>
                    <span>best sellers</span>
                </div>
                <div className={"custom-filter"}>
                    <div className={"filter-group"}>
                        <label for="account">Account</label>
                        <select className={"textUppercase"} onChange={this.handleChange.bind(this, 'account_id')}>
                            <option value={""}>All Accounts</option>
                            {
                                this.state.accounts.map((account) => {
                                    return <option key={account.id}
                                                   value={account.accountId}>{account.accountName}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className={"filter-group"}>
                        <label htmlFor="account">From</label>
                        <CustomDatePicker value={this.state.filters['date_from']} selected={this.state.selectedDate.date_from} placeholder={"Date From"} onChange={this.handleChangeDate.bind(this, "date_from")} format={"DD-MM-YYYY"}></CustomDatePicker>
                    </div>
                    <div className={"filter-group"}>
                        <label htmlFor="account">To</label>
                        <CustomDatePicker value={this.state.filters['date_to']} selected={this.state.selectedDate.date_to} placeholder={"Date To"} onChange={this.handleChangeDate.bind(this, "date_to")} format={"DD-MM-YYYY"}></CustomDatePicker>
                    </div>
                    <div className={"filter-group"}>
                        <label htmlFor="account">Group</label>
                        <select onChange={this.handleChange.bind(this, 'group_id')}>
                            <option value={""}>All Groups</option>
                            {
                                this.state.groups.map((group) => {
                                    return <option
                                        key={group.id}
                                        value={group.id}
                                    >{group.name}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className={"filter-group"}>
                        <label htmlFor="account">Section</label>
                        <select onChange={this.handleChange.bind(this, 'section_id')}>
                            <option value={""}>All Sections</option>
                            {
                                this.state.sections.map((section) => {
                                    return <option
                                        key={section.id}
                                        value={section.id}
                                    >{section.name}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                {
                    this.state.products.length > 0 ?
                        <table className={"custom-table table table-bordered"}>
                            <thead>
                            <tr>
                                <th>Pos</th>
                                <th>Part No</th>
                                <th>Section</th>
                                <th>Vol Sold</th>
                                <th>Val Sold</th>
                                <th>Stock</th>
                                <th>Minutes Out Of Stock</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.products.map((product, index) => {
                                    return <tr>
                                                <td>{index + 1}</td>
                                                <td>{product.part_number}</td>
                                                <td>
                                                    <img width={"40px"} src={product.section_image}/>
                                                </td>
                                                <td>
                                                    <NavLink to={"/admin/best-sellers/detail/" + product.part_number} exact>
                                                        <button className={"custom-btn"}>{product.sold_quantity}</button>
                                                    </NavLink>
                                                </td>
                                                <td>
                                                    <NavLink to={"/admin/best-sellers/detail/" + product.part_number} exact>
                                                        <button className={"custom-btn"}>{product.sold_price}</button>
                                                    </NavLink>
                                                </td>
                                                <td>
                                                    <button onClick={this.getAvailableStock.bind(this, product.part_number)} className={"custom-btn"}>{product.stock}
                                                        {
                                                            product.stock > 0 ?
                                                                <div id={product.part_number} className={"stock-branches " + (this.state.activeModal === product.part_number ? "active" : "")}>
                                                                    <table className={"custom-table table table-bordered"}>
                                                                        <thead>
                                                                        <tr>
                                                                            <th>Branch</th>
                                                                            <th>Qty</th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {
                                                                            this.state.stocks.map((stock) => {
                                                                                return <tr>
                                                                                            <td>{stock.branch}</td>
                                                                                            <td>{stock.quantity}</td>
                                                                                        </tr>
                                                                            })
                                                                        }
                                                                        </tbody>
                                                                    </table>
                                                                </div> : null
                                                        }
                                                    </button>
                                                </td>
                                                <td>0 Mins</td>
                                            </tr>
                                })
                            }
                            </tbody>
                        </table> : null
                }
                {
                    this.state.loading ?
                        <div className='sweet-loading'>
                            <GridLoader
                                sizeUnit={"px"}
                                size={15}
                                color={'#0066b8'}
                                loading={this.state.loading}
                            />
                        </div> : null
                }
            </div>
        )
    }
}

export default BestSellers;