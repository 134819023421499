import React, {Component} from "react";
// import "admin/administrator/lookup_totals/LookupTotals.css";
// import "admin/administrator/CustomElements.css";
import {BrowserRouter as Router, Route, NavLink} from "react-router-dom";
import axios from "axios1";
import _ from "lodash";
import CustomPagination from "admin/administrator/CustomPagination";
import CustomPieChart from 'components/Charts/CustomPieChart';
import PageTitle from 'admin/administrator/PageTitle';
import {GridLoader} from 'react-spinners';
import {withRouter} from 'react-router-dom';
import CustomPieChart2d from "components/Charts/CustomPieChart2d";
import CustomDatePicker from 'admin/administrator/CustomDatePicker';
import { connect } from "react-redux";
import {selectLookupsMonth} from "redux/actions/statisticActions";

var moment = require('moment');

class LookupDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: {
                month: "",
            },
            selectedDate: new Date(),
            data: [],
            page: 1,
            pages: 0,
            login_id: 0,
            dataLabelsForChart: [],
            dataValuesForChart: [],
            loading: false,
        }
    }

    componentDidMount() {
        this.setState({loading: true});
        this.initMonth('month');
        this.LookupsByAccounts(this.state.page);
    }

    LookupsByAccounts(page = 1) {
        var login_id = this.props.match.params.id;
        var filters = {...this.state.filters};
        axios.get("/catalogue/lookups-by-users/" + login_id + "?page= " + page, {params: filters})
            .then(response => {
                var data = response.data.loockups;
                var loockups_for_chart = response.data.loockups_for_chart;
                let dataLabelsForChart = ["PartNumber","Vehicle","VRM"];
                let dataValuesForChart = [];
                _.map(response.data.loockups_for_chart[0], item => {
                    dataValuesForChart.push(parseInt(item))
                });
            
                this.setState({
                    data: data,
                    dataLabelsForChart: dataLabelsForChart,
                    dataValuesForChart: dataValuesForChart,
                    page: page,
                    pages: response.data.pages,
                    login_id: login_id,
                    loading: false
                });
            })
    }

    handleChangeDate(key, date) {
        let filters = this.state.filters;
        filters[key] = moment(date).format('YYYY-MM');
        this.props.dispatch(selectLookupsMonth(filters[key]))
        this.setState({filters: filters, selectedDate: date}, () => {
            this.LookupsByAccounts();
        });
    }

    initMonth(key = 'month') {
        var month = this.props.month ? moment(this.props.month).toDate() : this.state.selectedDate;
        var filters = this.state.filters;
        filters[key] = moment(month).format('YYYY-MM');
        this.setState({selectedDate: month, filters: filters});
    }

    render() {
        return (
            <div className={"page-content admin-wrapper"}>

                <div className={"container-fluid"}>
                    <div className={"lookups-page-wrapper"}>
                        {
                            this.state.loading ?
                                <div className='sweet-loading'>
                                    <GridLoader
                                        sizeUnit={"px"}
                                        size={15}
                                        color={'#0066b8'}
                                        loading={this.state.loading}
                                    />
                                </div> : null
                        }
                        <div className={"row"}>
                            <div className={"col-lg-12"}>
                                <div class="page-title-wrapper">
                                    <span>Lookup Details</span>
                                    <button class="go-back" onClick={() => this.props.history.goBack()}><i
                                        class="fas fa-reply"></i> Back
                                    </button>
                                </div>

                                <div className={"lookups-filter"}>
                                    <div className={"custom-filter"}>
                                        <div className={"filter-group"}>
                                            <label htmlFor="account">Month</label>
                                            <CustomDatePicker value={this.state.filters['month']} selected={this.state.selectedDate} placeholder={"Month"} onChange={this.handleChangeDate.bind(this, "month")} format={"MMMM YYYY"} showMonthYearPicker={true}></CustomDatePicker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-lg-6"}>
                                {
                                    this.state.data.length > 0 ?
                                        <table className={"custom-table table table-bordered"}>
                                            <thead>
                                            <tr>
                                                <th>DATE/TIME</th>
                                                <th>LOOKUPS TYPE</th>
                                                <th>DETAIL</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.data.map((lookup) => {
                                                    return <tr>
                                                        <td>{lookup.date}</td>
                                                        <td>{lookup.lookup_type}</td>
                                                        <td>{lookup.detail}</td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table> : null
                                }
                                {
                                    this.state.pages > 0 ?
                                        <CustomPagination
                                            page={this.state.page}
                                            pages={this.state.pages}
                                            sendRequest={this.LookupsByAccounts.bind(this)}
                                        /> : null
                                }
                            </div>
                            <div className={"col-lg-6"}>
                                <CustomPieChart2d labels={this.state.dataLabelsForChart}
                                                  values={this.state.dataValuesForChart}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    month: state.statistic.lookupsMonth
})

export default withRouter(connect(mapStateToProps)(LookupDetails));