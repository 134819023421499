import React, { Component } from "react";
import { Line } from 'react-chartjs-2';
import "admin/administrator/LookupCharts";

export default class LookupChartsVehicle extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {

        }

    }

    render() {

        return (
            <div>
                <Line
                    data={this.props.vehicles}
                    options={{
                        scales: {
                            yAxes: [{
                                gridLines: {
                                    display: false,
                                    color: '#5c676b'
                                },
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Quantity',
                                    fontColor: '#8e8e8e',
                                    fontSize: 14,
                                },
                                ticks: {
                                    fontColor: '#5c676b',
                                    min: 0,
                                },
                            }],
                            xAxes: [{
                                gridLines: {
                                    display: false,
                                    color: '#5c676b'
                                },
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Vehicle',
                                    fontColor: '#8e8e8e',
                                    fontSize: 14
                                },
                                ticks: {
                                    fontColor: '#5c676b',
                                }
                            }],
                        }
                    }}
                    redraw
                />
            </div>
        )
    }
}