import React, { Fragment, useState, useEffect }  from 'react';
// import {Modal} from "react-bootstrap";
import {connect} from "react-redux";
import { initDeleteAccount } from "redux/actions/deleteAccounts";
import CustomButton from "admin/administrator/CustomButton";
// import "admin/administrator/account/DeleteModal.css";
import _ from "lodash";
import Modal from "components/Modal";

const modalClose = (onHide) => {
   onHide(); 
}

const dataWithoutRef = {
    title: "Delete Supplier ?",
    subtitle: "",
    deleteBtnTitle: "Delete Supplier",
    bodyDesc: "All data related to this supplier will be permanently lost. This includes all history."

}

const dataWithRef = {   
    title: "Warning",
    subtitle: "The supplier has the following linked accounts:",
    deleteBtnTitle: "Delete Supplier",
    bodyDesc: "The account(s) must be linked to an alternative supplier or deleted before the supplier can be successfully removed from the system."
}

const onDelete = (props) => {
    props.initDeleteAccount({undoTitle: props.undoTitle, id: props.id});
    props.onDelete();
}

const DeleteModal = (props) => {
    let accountsExist = false;
    let content = dataWithoutRef;
    if(props.accounts && props.accounts.length > 0) {
        accountsExist = true;
        content = dataWithRef;
    }
    
    return (
        <Fragment>
            <Modal size="sm" title={content.title} type="light" active={props.show} closeModal={props.onHide.bind(this)}>
                {
                    accountsExist &&
                    <ul className="mb-24">
                    {
                        props.accounts.map(function(refs) {
                            return  refs && <li className="fw-500">{refs.name}</li>;
                        })                                      
                    }
                    </ul>
                } 
                <div>{content.bodyDesc}</div>
                <div className={"modal__actions"}>
                    <CustomButton title={"Cancel"} type={"lightgray"} onClick={modalClose.bind(this, props.onHide)}/>
                    {
                        !accountsExist &&
                        <CustomButton type={"red"} title={content.deleteBtnTitle} onClick={onDelete.bind(this, props)} margins={["ml-8"]}/>
                    }
                </div>
            </Modal>
        </Fragment>
    );
}
 
const mapDispatchToProps = { initDeleteAccount: initDeleteAccount };

export default connect(null,mapDispatchToProps)(DeleteModal);