import React from 'react'
// import "admin/administrator/CustomSortable.css";
import CustomCheckBox from "admin/administrator/CustomCheckBox";
import {SortableContainer, SortableElement, sortableHandle, arrayMove} from 'react-sortable-hoc';
import { useTranslation } from 'react-i18next';


const DragHandle = sortableHandle(() => <span className="sortable__icon"><i class="fas fa-grip-vertical"></i></span>);

const CustomSortableItem = SortableElement(({value, index, selectedId, onClick, onChange}) => {
  const { t } = useTranslation();
  return <li className={( onClick && selectedId == value.id ) ? "sortable__item sortable__item--active" : "sortable__item"} 
              key={`item-${index}`}
              onClick={onClick ? onClick.bind(this, (value.id || (value.value || value.name))) : null }>
            <DragHandle />
            <span className="sortable__name">{t(value.name)}</span>
            {
              onChange ? 
              <CustomCheckBox
                name={value.name}
                checked={value.checked}
                onChange={onChange.bind(this, (value.value || value.name))}
              /> : null
            }
  </li>
});

  
export default CustomSortableItem;