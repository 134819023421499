import React, { Component } from "react";
import axios from "axios1";
import UserPreferences from 'admin/administrator/UserPreferences.js';
import { GridLoader } from 'react-spinners';
import alertify from 'alertifyjs';
import {connect} from "react-redux";
import { reloadSettings } from "redux/actions/settings";
// import "admin/administrator/AddUser.css";

class AddUser extends Component
{
    constructor(props)
    {
        super(props);
        
        this.state = {
            modalShow: true,
            userTypes: [
                { id:0, name: "Select user type" },
                { id:1, name: "Branch" },
            ],
            branches : [
                { id:0, name: "Select branch", active:'1' }
            ],
            priceLevels: [
                { id:0, name: "Select price level" }
            ],
            ordersType: [
                { id:0, name: "Select communication type" },
                { id:1, name: "Email" },
                { id:2, name: "Ftp" },
            ],
            ordersEmails: [
                { id:1, name: "Select communication email" }
            ],
            ordersFtps: [
                { id:2, name: "Select communication ftp" }
            ],
            form: {
                business_type_id: 0,
                communication_email_id: 1, //
                communication_ftp_id: 2, //
                branch_id: 0,
                price_level_id: 0, //
                branch_user_limit: 1, //
                orders_type: "Select communication type", //
                company: "",
                name: "",
                email: "",
                contact_no: "",
                address_line_1: "",
                address_line_2: "",
                town: "",
                county: "",
                postal_code: "",
                manager: false,
                password: "",
                password_confirmation: "",
            },
            errors: [],
            loading: true,
            preferences: {} // This is filled later from preferences modal
        };

        this.getProperties();
        this.goBack = this.goBack.bind(this);
        this.updatePreferencesValues = this.updatePreferencesValues.bind(this);
        
    }

    componentDidMount() {
        this.setState({modalShow: false});
        if(this.props.match.params.id) {
            const userId = this.props.match.params.id;
            this.getUser(userId)
        }
    }

    getUser(id) {
        let userId = id;
        axios.get("catalogue/users/" + userId)
            .then((response) => {
                const data = response.data;
                // this.setState({form: data.user,preferences:data.preferences});
                this.setState({form: data.user,loading:false});
            });
    }

    updatePreferencesValues(preferences){
        this.setState({preferences: preferences});
    }
    
    getProperties() {
        let userTypes = [
            { id: 0, name: "Select user type" }
        ];

        let branches = [
            { id: 0, name: "Select user branch", active:'1' }
        ];

        let level = [
            { id: 0, name: "Select price level" }
        ];

        this.setState({
            loading: true,
        });

        axios.get("catalogue/user-properties")
            .then((response) => {
                this.setState({
                    userTypes: userTypes.concat(response.data.userTypes),
                    branches: branches.concat(response.data.branches),
                    priceLevels: level.concat(response.data.priceLevels),
                    ordersEmails: response.data.ordersEmails,
                    ordersFtps: response.data.ordersFtps,
                    loading: false,
                });
            });
    }

    handleInputChange(formName, event)
    {
        let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

        let form = { ...this.state.form };

        form[formName] = value;

        this.setState({ form: form });
    }

    changeUserLimit(sign){
        let form = { ...this.state.form };

        form.branch_user_limit = sign == "-" ? this.state.form.branch_user_limit - 1:this.state.form.branch_user_limit + 1;

        form.branch_user_limit = form.branch_user_limit < 0 ? 0:form.branch_user_limit;

        this.setState({ form: form });
    }
  
    handleSaveClick()
    {
        this.setState({loading: true})
        axios.post("catalogue/user-properties/", { form: this.state.form,preferences: this.state.preferences})
        .then((response) => {
            window.scrollTo(0, 0)
            this.setState({loading: false});
            if(response.data.success){
                alertify.success(response.data.msg);
            }else{
                this.setState({ errors: "An error occured" });
                alertify.error("An error occured"); 
            }
        }); 
    }

    handleUpdateClick()
    {
        this.setState({loading: true})
        axios.put("catalogue/user-properties/" + this.props.match.params.id, { form: this.state.form , preferences: this.state.preferences})
            .then((response) => {
                this.props.reloadSettings();
                this.setState({loading: false});

                window.scrollTo(0, 0);
                if(response.data.success) {
                    alertify.success(response.data.msg);
                } else {
                    this.setState({ errors: response.data.errors });
                    alertify.error(response.data.msg);
                }
            });
    }

    modalClose(){
        this.setState({modalShow: false});
    }
    goBack(){
        this.props.history.goBack();
    }
    
    render()
    {

        alertify.defaults = {
            notifier: {
                position: 'top-right',
                delay: 5
            }
        } 

        return (
            <div className={"dashboard-page-wrapper"}>
                {
                    this.state.loading ?
                        <div className='sweet-loading'>
                            <GridLoader
                                sizeUnit={"px"}
                                size={15}
                                color={'#0066b8'}
                                loading={this.state.loading}
                            />
                        </div> : null
                } 
                {
                    this.props.match.params.id ?
                        <div className={"dashboard-page-navigations"}>
                            <div>
                                <i className="fas fa-edit"></i>
                                <span> View / Edit User</span>
                            </div>
                            <div>
                                <button className="back-btn" onClick={() => this.props.history.goBack()}>
                                    Go Back
                                </button>
                            </div>
                        </div> :
                        <div className={"dashboard-page-navigations"}>
                            <div>
                                <i className="fas fa-user-plus"></i>
                                <span> Add User</span>
                            </div>
                            <div>
                                <button className="back-btn" onClick={() => this.props.history.goBack()}>
                                    Go Back
                                </button>
                            </div>
                        </div>
                }  

                <div className={"dashboard-page-body"}>
                    <div className={"dashboard-add-user-body"}>
                        <div className={"add-user-left"}>
                            <div className={"add-user-inputs-wrapper"}>
                                
                                <div className={"selectsInline"}>
                                    <div className="selectWrap">
                                        <select className={"add-user-user-type-select"} value={this.state.form.business_type_id} onChange={this.handleInputChange.bind(this, "business_type_id")}>
                                            {
                                                this.state.userTypes.map((userType) => {
                                                    return <option key={userType.id} value={userType.id}>
                                                        {userType.name}
                                                    </option>
                                                })
                                            }
                                        </select>
                                    {
                                        this.state.errors["business_type_id"] ?
                                        <span className="error">{this.state.errors["business_type_id"]}</span>
                                        : null
                                    }

                                    
                                    </div>
                                    <div className="selectWrap">
                                        <select className={"add-user-branch-select"} value={this.state.form.branch_id} onChange={this.handleInputChange.bind(this, "branch_id")}>
                                            {
                                                this.state.branches.map((branch) => {
                                                    return <option key={branch.id} value={branch.id} disabled={(branch.active==1)?false:true}>
                                                        {branch.name}{(branch.active==1)?"":' (disabled)'}
                                                    </option>
                                                })
                                            }
                                        </select>
                                        {
                                            this.state.errors['branch_id'] ?
                                            <span className="error">{this.state.errors["branch_id"]}</span>
                                            : null
                                        }
                                    </div>  
                                </div>
                                <input type={"text"} placeholder={"User / Company Name"} value={this.state.form.company} onChange={this.handleInputChange.bind(this, "company")} />
                                {
                                    this.state.errors['company'] ?
                                    <span className="error">{this.state.errors["company"]}</span>
                                    : null
                                }

                                <input type={"text"} placeholder={"Contact Name"} value={this.state.form.name} onChange={this.handleInputChange.bind(this, "name")} />
                                {
                                    this.state.errors['name'] ? 
                                    <span className="error">{this.state.errors["name"]}</span>
                                    : null
                                }

                                <input className={"add-user-address1"} type={"text"} placeholder={"Address Line 1"} value={this.state.form.address_line_1} onChange={this.handleInputChange.bind(this, "address_line_1")}/>
                                {
                                    this.state.errors['address_line_1'] ?
                                    <span className="error">{this.state.errors["address_line_1"]}</span>
                                    : null
                                }

                                <input className={"add-user-address2"} type={"text"} placeholder={"Address Line 2"} value={this.state.form.address_line_2} onChange={this.handleInputChange.bind(this, "address_line_2")}/>
                                {
                                    this.state.errors['address_line_2'] ?
                                    <span className="error">{this.state.errors["address_line_2"]}</span>
                                    : null
                                }

                                <input type={"text"} placeholder={"Town"} value={this.state.form.town} onChange={this.handleInputChange.bind(this, "town")}/>
                                {
                                    this.state.errors['town'] ?
                                    <span className="error">{this.state.errors["town"]}</span>
                                    : null
                                }

                                <input type={"text"} placeholder={"County"} value={this.state.form.county} onChange={this.handleInputChange.bind(this, "county")}/>
                                {
                                    this.state.errors['county'] ?
                                    <span className="error">{this.state.errors["county"]}</span>
                                    : null
                                }

                                <input type={"text"} placeholder={"Post Code"} value={this.state.form.postal_code} onChange={this.handleInputChange.bind(this, "postal_code")}/>
                                {
                                    this.state.errors['postal_code'] ?
                                    <span className="error">{this.state.errors["postal_code"]}</span>
                                    : null
                                }

                                <input type={"text"} placeholder={"Tel"} value={this.state.form.contact_no} onChange={this.handleInputChange.bind(this, "contact_no")}/>
                                {
                                    this.state.errors['contact_no'] ?
                                    <span className="error">{this.state.errors["contact_no"]}</span>
                                    : null
                                }

                                <select className={"add-user-price-level-select"} value={this.state.form.price_level_id} onChange={this.handleInputChange.bind(this, "price_level_id")}>
                                    {
                                        this.state.priceLevels.map((priceLevel) => {
                                            return <option key={priceLevel.id} value={priceLevel.id}>
                                                {priceLevel.name}
                                            </option>
                                        })
                                    }
                                </select>
                                {
                                    this.state.errors['price_level_id'] ?
                                    <span className="error">{this.state.errors["price_level_id"]}</span>
                                    : null
                                }
                            </div>
                        </div>
                        <div className={"add-user-right"}>
                            <div className={"add-user-credentials"}>
                                <input type={"text"} placeholder={"Email"} value={this.state.form.email} onChange={this.handleInputChange.bind(this, "email")}/>
                                {
                                    this.state.errors['email'] ? 
                                    <span className="error">{this.state.errors["email"]}</span>
                                    : null
                                }

                                <input type={"password"} placeholder={"Password"} value={this.state.form.password} onChange={this.handleInputChange.bind(this, "password")}/>
                                {
                                    this.state.errors['password'] ? 
                                    <span className="error">{this.state.errors["password"]}</span>
                                    : null
                                } 

                                <input type={"password"} placeholder={"Re-Type Password"} value={this.state.form.password_confirmation} onChange={this.handleInputChange.bind(this, "password_confirmation")}/>

                                {/*<div className={"add-user-inline"}>*/}
                                    {/*<div className="md-checkbox checkBranchManager">*/}
                                        {/*<input type="checkbox" id={'manageBranch'} type={"checkbox"} checked={this.state.form.manager} onChange={this.handleInputChange.bind(this, "manager")} />*/}
                                        {/*<label htmlFor={'manageBranch'}></label>*/}
                                    {/*</div>*/}
                                    {/*<label className={"add-user-manager-label"} htmlFor={'manageBranch'}>Branch Manager</label>*/}
                                {/*</div>*/}
                            </div>
                            <div className={"add-user-communication"}>
                                <div className={"add-user-send-order-type-wrapper"}>
                                    <div className={"add-user-send-order-type-label"}>Send orders via:</div>
                                    <select className={"add-user-send-order-type"} value={this.state.form.orders_type} onChange={this.handleInputChange.bind(this, "orders_type")}>
                                        {
                                            this.state.ordersType.map((ordersType) => {
                                                return <option key={ordersType.id} value={ordersType.name}>
                                                    {ordersType.name}
                                                </option>
                                            })
                                        }
                                    </select>

                                    {
                                        this.state.errors['orders_type'] ?
                                        <span className="error">{this.state.errors["orders_type"]}</span>
                                        : null
                                    }
                                </div>
                                <div className={"add-user-email-ftp-wrapper"}>
                                    {
                                        this.state.form.orders_type == "Email" ?
                                            <div className={"add-user-email-ftp-item"}>
                                                <div className={"add-user-email-ftp-item-label"}>
                                                    Email orders to:
                                                </div>
                                                <select className={"add-user-email-ftp-item-select"} value={this.state.form.communication_email_id} onChange={this.handleInputChange.bind(this, "communication_email_id")}>
                                                    {
                                                        this.state.ordersEmails.map((ordersEmail) => {
                                                            return <option key={ordersEmail.id} value={ordersEmail.id}>
                                                                {ordersEmail.name}
                                                            </option>
                                                        })
                                                    }
                                                </select>
 
                                                {
                                                    this.state.errors['communication_email_id'] ?
                                                    <span className="error">{this.state.errors["communication_email_id"]}</span>
                                                    : null
                                                }
                                            </div>
                                            :
                                            <div className={"add-user-email-ftp-item"}>
                                                <div className={"add-user-email-ftp-item-label"}>
                                                    Ftp orders to:
                                                </div>
                                                <select className={"add-user-email-ftp-item-select"} value={this.state.form.communication_ftp_id} onChange={this.handleInputChange.bind(this, "communication_ftp_id")}>
                                                    {
                                                        this.state.ordersFtps.map((ordersFtp) => {
                                                            return <option key={ordersFtp.id} value={ordersFtp.id}>
                                                                {ordersFtp.name}
                                                            </option>
                                                        })
                                                    }
                                                </select>

                                                {
                                                    this.state.errors['communication_ftp_id'] ?
                                                    <span className="error">{this.state.errors["communication_ftp_id"]}</span>
                                                    : null
                                                }
                                            </div>
                                    }
                                </div>
                            </div>
                            
                            <div className={"add-user-buttons"}>
                                <UserPreferences show={this.state.modalShow} userId={this.props.match.params.id} onHide={this.modalClose.bind(this)} updatePreferencesValues={this.updatePreferencesValues}></UserPreferences>
                                <div className={"add-user-preferences-button"} onClick={() => this.setState({ modalShow: true })} > <i style={{marginRight:"10px"}} className="fas fa-user-edit"></i> Setup User Preferences</div>
                                <div className={"add-user-cancel-save-buttons-wrapper"}>
                                    {
                                        this.props.match.params.id ?
                                            <div className={"add-user-save-button"} onClick={this.handleUpdateClick.bind(this)}>Update</div>
                                            : <div className={"add-user-save-button"} onClick={this.handleSaveClick.bind(this)}>Save</div>
                                    }
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        ); 
    }
}

const mapDispatchToProps = {
    reloadSettings: reloadSettings
};

export default connect(
    null,
    mapDispatchToProps
)(AddUser);
