import React, {useState, useEffect} from "react";
import FormInput from "admin/administrator/FormInput/FormInput.component";
import FormSelect from "admin/administrator/FormSelect/FormSelect.component";
import SupplierBrands from "admin/administrator/SupplierBrands";
import Block from "components/Block";
import PropTypes from "prop-types";
import axios from "axios1";
import _ from "lodash";
import { useTranslation } from 'react-i18next';

const SupplierDetails = props => {
    const [supplier, setSupplier] = useState({
        name: "",
        branch_code: "",
        contact_name: "",
        address_1: "",
        address_2: "",
        town: "",
        county: "",
        postal_code: "",
        phone: "",
        email: "",
        vrm_country: ""
    });
    const [vrmCountries, setVrmCountries] = useState();
    const { t, i18n } = useTranslation();

    const validateConf = {
        name: (value) => {
            if(value === "") {
                return t("The supplier name field is required");
            }

            let format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/;
            if(format.test(value)) {
                return t("Invalid Character(s)");
            }
            
            validateByRow('name').then( messages => props.setErrors({...props.errors, name: messages[0]}));
        },           
        contact_name: value => {
            if (!value) {
                return t("The contact name field is required");
            }
        },
        address_1: value => {
            if (!value) {
                return t("The address 1 field is required");
            }
        },
        town: value => {
            if (!value) {
                return t("The town field is required");
            }
        },
        phone: value => {
            if (!value) {
                return t("The phone field is required");
            }
        },
        email: (value) => {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            
            if (value === "") {
                return "";
            }
            
            if(!re.test(String(value).toLowerCase())) {
                return t("The email address is not valid")+"!";
            }
                
            validateByRow('email').then( messages => props.setErrors({...props.errors, email: messages[0]}));
        }
    }

    useEffect(() => {
        setSupplier(props.supplier);
    }, [props.supplier])

    useEffect(() => {
        axios.get("catalogue/get-vrm-countries")
            .then((response) => {
                if (response.statusText === "OK") {
                    setVrmCountries(response.data);
                }
            })
    }, []);

    const handleFromBlur = (property, value) => {
        const errors = {...props.errors};
        if(property) {
            errors[property] = validateConf[property](value) ?? "";
        }
        props.setErrors(errors);
    }

    const validateByRow = async property => {
        const supplier = props.supplier;
        supplier.isAccount = true;
        return await axios.post("catalogue/validate-account", { account: supplier, properties: [property] } )
        .then((response) => {
            return (response.data.success && response.data?.errors[property]) ? response.data.errors[property] : "";
        });
    }

    const handleInputChange = (input, event) => {
        let newsupplier = supplier;
        newsupplier[input] = event.target.value;
        if (typeof newsupplier.vrm_country === 'undefined' || newsupplier.vrm_country === "") {
            newsupplier.vrm_country = 1;
        }
        props.setSupplier({...newsupplier});
    }

    return (
        <div className={"account-details"}>
            <Block title={t("supplier details")} type={"1"}>
                <div className="branch-details">
                    <FormInput
                        name='name'
                        type='text'
                        placeholder={t('Supplier Name')+"*"}
                        error={ props.errors.name }
                        value={supplier.name}
                        disabled={ (
                            (supplier && supplier.id && !supplier.ftp_password && props.apiId === 3)|| 
                            (props.apiId !== 3 && supplier && supplier.id && supplier.ftp_password)||
                            (props.apiId === 3 && supplier && supplier.id && supplier.ftp_password)
                        ) ? true : false}
                        onChange={handleInputChange.bind(this, "name")}
                        handleBlur={e => handleFromBlur("name", e.target.value)}
                        required
                        className="bordered"
                    />
                    <FormInput
                        name='contact_name'
                        type='text'
                        placeholder={t('Contact Name')+"*"}
                        error={ props.errors.contact_name }
                        value={supplier.contact_name}
                        onChange={handleInputChange.bind(this, "contact_name")}
                        handleBlur={e => handleFromBlur("contact_name", e.target.value)}
                        required
                        className="bordered"
                    />
                    <FormInput
                        name='address_1'
                        type='text'
                        placeholder={t('Address Line')+" 1*"}
                        error={ props.errors.address_1 }
                        value={supplier.address_1}
                        onChange={handleInputChange.bind(this, "address_1")}
                        handleBlur={e => handleFromBlur("address_1", e.target.value)}
                        required
                        className="bordered"
                    />
                    <FormInput
                        name='address_2'
                        type='text'
                        placeholder={t('Address Line')+" 2"}
                        error={ props.errors.address_2 }
                        value={supplier.address_2}
                        onChange={handleInputChange.bind(this, "address_2")}
                        className="bordered"
                    />
                    <FormInput
                        name='town'
                        type='text'
                        placeholder={t("Town")+'*'}
                        error={ props.errors.town }
                        value={supplier.town}
                        onChange={handleInputChange.bind(this, "town")}
                        handleBlur={e => handleFromBlur("town", e.target.value)}
                        className="bordered"
                        required
                    />
                    <FormInput
                        name='county'
                        type='text'
                        placeholder={t('County')}
                        error={ props.errors.county }
                        value={supplier.county}
                        onChange={handleInputChange.bind(this, "county")}
                        className="bordered"
                    />
                    <FormInput
                        name='postal_code'
                        type='text'
                        placeholder={t('Post Code / Zip')}
                        error={ props.errors.postal_code }
                        value={supplier.postal_code}
                        onChange={handleInputChange.bind(this, "postal_code")}
                        className="bordered"
                    />
                    <FormInput
                        name='phone'
                        type='text'
                        placeholder={t('Phone Number')+"*"}
                        error={ props.errors.phone }
                        value={supplier.phone}
                        onChange={handleInputChange.bind(this, "phone")}
                        handleBlur={e => handleFromBlur("phone", e.target.value)}
                        className="bordered"
                        required
                    />
                    <FormInput
                        name='email'
                        type='email'
                        placeholder={t('Email')}
                        error={ props.errors.email }
                        value={supplier.email}
                        onChange={handleInputChange.bind(this, "email")}
                        handleBlur={e => handleFromBlur("email", e.target.value)}
                        className="bordered"
                        required
                    />
                    {vrmCountries ?
                        <div>
                            <label>{t("VRM Country")}</label>
                            <FormSelect
                                options={vrmCountries}
                                selectedId={supplier.vrm_country ?? 1}
                                handleChange={handleInputChange.bind(this, "vrm_country")}
                            />
                        </div> : null
                    }
                </div>
            </Block>
            <div className={"mt-4"}>
                <Block title={t("supplier brands")} type={"1"} style={{marginTop: '24px'}}>
                    <SupplierBrands brands={props.brands}
                                    // apiId={props.apiId}
                                    error={props.errors.brands}
                                    selectedBrands={props.selectedBrands}
                                    setSelectedBrands={props.setSelectedBrands}
                    />
                </Block>
            </div>
        </div>
    )
}

SupplierDetails.propTypes = {
    setSupplier: PropTypes.func,
    supplier: PropTypes.object
}

export default SupplierDetails;

// class SupplierDetails extends Component {

//     constructor(props) {
//         super(props);

//         this.state = {
//             supplier: {
//                 name: "",
//                 branch_code: "",
//                 contact_name: "",
//                 address_1: "",
//                 address_2: "",
//                 town: "",
//                 county: "",
//                 postal_code: "",
//                 phone: "",
//                 email: "",
//             },
//             selectedBrands: [],
//         }

//         this.validateConf = {
//             name: (value) => {
//                 if(value === "") {
//                     return "The supplier name field is required.";
//                 }
                
//                 this.validateByRow('name').then( messages => this.props.setErrors({...this.props.errors, name: messages[0]}));
//             },           
//             contact_name: value => {
//                 if (!value) {
//                     return "The contact name field is required.";
//                 }
//             },
//             address_1: value => {
//                 if (!value) {
//                     return "The address 1 field is required.";
//                 }
//             },
//             town: value => {
//                 if (!value) {
//                     return "The town field is required.";
//                 }
//             },
//             phone: value => {
//                 if (!value) {
//                     return "The phone field is required.";
//                 }
//             },
//             email: (value) => {
//                 const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                
//                 if (value === "") {
//                     return "";
//                 }
                
//                 if(!re.test(String(value).toLowerCase())) {
//                     return "The email address is not valid!";
//                 }
                    
//                 this.validateByRow('email').then( messages => this.props.setErrors({...this.props.errors, email: messages[0]}));
//             }
//         }
//     }

//     handleFromBlur = (property, value) => {
//         const errors = { ...this.props.errors };        
//         if(property) {
//             errors[property] = this.validateConf[property](value) ?? "";
//         }
//         this.props.setErrors(errors);
//     }

//     validateByRow = async (property) => {
//         const supplier = this.props.supplier;
//         supplier.isAccount = true;
//         return await axios.post("catalogue/validate-account", { account: supplier, properties: [property] } )
//         .then((response) => {
//             return (response.data.success && response.data?.errors[property]) ? response.data.errors[property] : "";
//         });
//     }

//     componentDidUpdate(prevProps) {
//         if (this.props.supplier !== prevProps.supplier) {
//             this.setState({supplier: this.props.supplier})
//         }
//     }

//     handleInputChange(input, event) {
//         let supplier = {...this.state.supplier};
//         supplier[input] = event.target.value;
//         this.setState({supplier: supplier}, () => {
//             this.props.setSupplier(this.state.supplier);
//         });
//     }

//     render() {
//         let errors = this.props.errors
//         let supplier = this.props.supplier
//         return (
//             <div className={"account-details"} style={{width: '49%'}}>
//                 <CustomContainer name={"supplier details"} type={"basic"}>
//                     <div className="branch-details">
//                         <FormInput
//                             name='name'
//                             type='text'
//                             placeholder='Supplier Name*'
//                             error={ errors.name }
//                             value={this.state.supplier.name}
//                             onChange={this.handleInputChange.bind(this, "name")}
//                             handleBlur={e => this.handleFromBlur("name", e.target.value)}
//                             required
//                             className="bordered"
//                         />
//                         <FormInput
//                             name='contact_name'
//                             type='text'
//                             placeholder='Contact Name*'
//                             error={ errors.contact_name }
//                             value={this.state.supplier.contact_name}
//                             onChange={this.handleInputChange.bind(this, "contact_name")}
//                             handleBlur={e => this.handleFromBlur("contact_name", e.target.value)}
//                             required
//                             className="bordered"
//                         />
//                         <FormInput
//                             name='address_1'
//                             type='text'
//                             placeholder='Address Line 1*'
//                             error={ errors.address_1 }
//                             value={this.state.supplier.address_1}
//                             onChange={this.handleInputChange.bind(this, "address_1")}
//                             handleBlur={e => this.handleFromBlur("address_1", e.target.value)}
//                             required
//                             className="bordered"
//                         />
//                         <FormInput
//                             name='address_2'
//                             type='text'
//                             placeholder='Address Line 2'
//                             error={ errors.address_2 }
//                             value={this.state.supplier.address_2}
//                             onChange={this.handleInputChange.bind(this, "address_2")}
//                             className="bordered"
//                         />
//                         <FormInput
//                             name='town'
//                             type='text'
//                             placeholder='Town*'
//                             error={ errors.town }
//                             value={this.state.supplier.town}
//                             onChange={this.handleInputChange.bind(this, "town")}
//                             handleBlur={e => this.handleFromBlur("town", e.target.value)}
//                             className="bordered"
//                             required
//                         />
//                         <FormInput
//                             name='county'
//                             type='text'
//                             placeholder='County'
//                             error={ errors.county }
//                             value={this.state.supplier.county}
//                             onChange={this.handleInputChange.bind(this, "county")}
//                             className="bordered"
//                         />
//                         <FormInput
//                             name='postal_code'
//                             type='text'
//                             placeholder='Post Code / Zip'
//                             error={ errors.postal_code }
//                             value={this.state.supplier.postal_code}
//                             onChange={this.handleInputChange.bind(this, "postal_code")}
//                             className="bordered"
//                         />
//                         <FormInput
//                             name='phone'
//                             type='text'
//                             placeholder='Phone Number*'
//                             error={ errors.phone }
//                             value={this.state.supplier.phone}
//                             onChange={this.handleInputChange.bind(this, "phone")}
//                             handleBlur={e => this.handleFromBlur("phone", e.target.value)}
//                             className="bordered"
//                             required
//                         />
//                         <FormInput
//                             name='email'
//                             type='email'
//                             placeholder='Email'
//                             error={ errors.email }
//                             value={this.state.supplier.email}
//                             onChange={this.handleInputChange.bind(this, "email")}
//                             handleBlur={e => this.handleFromBlur("email", e.target.value)}
//                             className="bordered"
//                             required
//                         />
//                     </div>                   
//                 </CustomContainer>
//                 <div className={"mt-4"}>
//                     <CustomContainer name={"supplier brands"} type={"basic"}>
//                         <SupplierBrands brands={this.props.brands}
//                                         apiId={this.props.apiId}
//                                         errors={errors.brands}
//                                         selectedBrands={this.props.selectedBrands}
//                                         setSelectedBrands={this.props.setSelectedBrands}
//                         />
//                     </CustomContainer>
//                 </div>
//             </div>
//         )
//     }
// }