import react, {Component} from "react";
import axios from "axios1";

class IsAdmin extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isAdmin: 0
        }
    }

    componentDidMount() {
        this.checkUser();
    }

    checkUser() {
        axios.get("/catalogue/check-user").then((response) => {
            this.setState({isAdmin: response.data.admin});
        })
    }

    render() {
        return this.state.isAdmin ? this.props.children : null;
    }
}

export default IsAdmin;