import React, {Fragment} from "react";
import "components/CustomLabel.css";

const CustomLabel = (props) => {
    return (
        <Fragment>
            <label className={"customLabel"}>{props.title}</label>
        </Fragment>
    )
}

export default CustomLabel;