import React from "react";

function Icon({file, type, width, height}) {
    let icon = {
        mask: `url(/Icons/${file})`,
        WebkitMask: `url(/Icons/${file})`,
        WebkitMaskRepeat: 'no-repeat',
        width: width,
        height: height
    }
    return (
        <span class={"icon icon--"+type} style={icon}></span>
    )
}

Icon.defaultProps = {
    width: "16px",
    height: "16px",
    type: "dark",
    file: "User.svg"
}

export default Icon