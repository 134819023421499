import React, {Component} from "react";
import _ from "lodash";
// import "admin/administrator/ClientDashboard/ClientDetails.css";
import CustomDatePicker from 'admin/administrator/CustomDatePicker';
import CustomerAccounts from "admin/administrator/GeneralClientOrBranchStatistics/CustomerAccounts";
import BranchLoginsUsed from "../GeneralClientOrBranchStatistics/BranchLoginsUsed";
import BranchAccounts from "../GeneralClientOrBranchStatistics/BranchAccounts";
import CustomerLoginsUsed from "../GeneralClientOrBranchStatistics/CustomerLoginsUsed";
import VrmLookups from "../GeneralClientOrBranchStatistics/VrmLookups";
import Cost from "../GeneralClientOrBranchStatistics/Cost";
import Orders from "../GeneralClientOrBranchStatistics/Orders";
import SettingsCog from "./SettingsCog";
import axios from "axios1";
import alertify from "alertifyjs";
import CustomContainer from 'custom_components/CustomContainer.js';
import {setDate} from "redux/actions/dashboardDate";
import Fees from "../FeesAndTotals/Fees";
import Totals from "../FeesAndTotals/Totals";
import Total from "../FeesAndTotals/Total";

import { withTranslation } from 'react-i18next';
import i18n, { t } from 'i18next';

import {connect} from "react-redux";

var moment = require('moment');
var store = require('store');

class ClientDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMonth: new Date(),
            metaStats: {
                branchTotals: {
                    licences_used: {
                        count: '',
                        cost: ''
                    },
                    login_fee: '',
                    vrm_lookup_fee: '',
                    vrm_lookups: {
                        count: '',
                        cost: ''
                    }
                },
                customerTotals: {
                    licences_used: {
                        count: '',
                        cost: ''
                    },
                    login_fee: '',
                    vrm_lookup_fee: '',
                    vrm_lookups: {
                        count: '',
                        cost: ''
                    }
                },
                branchesNumber: '',
                costumersNumber: '',
                branchesLoginNumber: {
                    active: '',
                    all: ''
                },
                costumersLoginNumber: {
                    active: '',
                    all: ''
                },
                vrmLookups: '',
                totalFee: '',
                ordersAmount: ''
            }
        }
    }

    handleChangeDate(date) {
        this.props.setDate(date);
    }

    componentDidMount() {
        this.getMetaStats();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.date !== this.props.date) {
            this.getMetaStats();
        }
    }

    getMetaStats() {
        axios.get('stats/meta/' + moment(this.props.date).format('YYYY-MM'))
            .then((response) => {
                if (response.data.success) {
                    let metaStats = response.data.data;
                    this.setState({metaStats: metaStats});
                } else {
                    alertify.error(response.data.msg);
                }
            });

    }

    render() {
        let account = this.props.user ? this.props.user.account : [];
        let metaStats = this.state.metaStats;
        return (
            <React.Fragment>
                <CustomDatePicker value={this.props.date}
                                  selected={this.props.date}
                                  placeholder={t("Month")}
                                  onChange={this.handleChangeDate.bind(this)}
                                  format={"MMMM YYYY"}
                                  showMonthYearPicker={true}>
                </CustomDatePicker>
                <Fees licenceFee={metaStats.branchTotals.login_fee} lookupFee={metaStats.branchTotals.vrm_lookup_fee}/>
                <Totals title={t("Branch")}
                        licencesUsed={metaStats.branchTotals.licences_used.count}
                        licencesCost={parseFloat(metaStats.branchTotals.licences_used.cost).toFixed(2)}
                        lookupsUsed={metaStats.branchTotals.vrm_lookups.count}
                        lookupsCost={parseFloat(metaStats.branchTotals.vrm_lookups.cost).toFixed(2)}
                        total={parseFloat(metaStats.branchTotals.licences_used.cost + metaStats.branchTotals.vrm_lookups.cost).toFixed(2)}/>
                <Totals title={t("Customer")}
                        licencesUsed={metaStats.customerTotals.licences_used.count}
                        licencesCost={parseFloat(metaStats.customerTotals.licences_used.cost).toFixed(2)}
                        lookupsUsed={metaStats.customerTotals.vrm_lookups.count}
                        lookupsCost={parseFloat(metaStats.customerTotals.vrm_lookups.cost).toFixed(2)}
                        total={parseFloat(metaStats.customerTotals.licences_used.cost + metaStats.customerTotals.vrm_lookups.cost).toFixed(2)}/>
                <Total total={parseFloat(metaStats.branchTotals.licences_used.cost + metaStats.branchTotals.vrm_lookups.cost + metaStats.customerTotals.licences_used.cost + metaStats.customerTotals.vrm_lookups.cost).toFixed(2)}/>
                <div className="mt-40">
                    <div className="mb-16">
                        <div className="fw-700 mb-8">{t("Contact")}</div>
                        <div>{account.contact_name}</div>
                    </div>
                    <div className="mb-16">     
                        <div className="fw-700 mb-8">{t("Address")}</div>     
                        <div>{account.address_1}<br/>{account.address_2}</div>
                    </div>
                    <div className="mb-16">
                        <div className="fw-700 mb-8">{t("Tel")}</div>
                        <div>{account.phone}</div>
                    </div>
                    <div>
                        <div className="fw-700 mb-8">{t("Email")}</div>
                        <div>{account.email}</div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    date: state.dashboardDate.date
});

const mapDispatchToProps = dispatch => {
    return {
        setDate: (date) => dispatch(setDate(date)),
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ClientDetails));