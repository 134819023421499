import React, { Fragment, useEffect, useState } from 'react';
import alertify from "alertifyjs";

function ChangeQuantity({product, handleIncreaseQuantity, handleDecreaseQuantity}) {

    const [quantity, setQuantity] = useState(1);
    useEffect(() => {
        setQuantity(product.quantity);
    }, []);

    const actions = {
        inc: {
            message: "Out of stock!",
            // method: qty => product.stock < Number(qty) + 1,
            method: () => {},
            action: handleIncreaseQuantity,
            val: qty => Number(qty) + 1
        },
        dec: {
            message: "Quantity must be greater than zero!",
            method: qty => Number(qty) == 1,
            action: handleDecreaseQuantity,
            val: qty => Number(qty) - 1
        }
    }

    const handleClick = (qty, action) => {
        if(action.method(qty)) {
            alertify.error(action.message);
            return;
        }else {
            qty = action.val(qty);
            setQtyToStore(action, product, qty);
        }
    }

    const setQtyToStore = async (action, product, qty) => {
        await action.action(product, qty);
        setQuantity(qty)
    } 

    return (
        <Fragment>
            <div className="change-quantity">
                <button className="change-quantity__button" value={quantity} onClick={e => handleClick(e.target.value, actions.dec)}>-</button>
                <div className="change-quantity__quantity">{quantity}</div>
                <button className="change-quantity__button" value={quantity} onClick={e => handleClick(e.target.value, actions.inc)}>+</button>
            </div>
        </Fragment>
    );
}

export default ChangeQuantity