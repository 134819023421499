import React from "react";

function Badge({title, type, cursor}) {
    let badgeStyles = {
        cursor: cursor
    }
    return (
        <div className={"badge badge--" + type} style={badgeStyles}>{title}</div>
    )
}

Badge.defaultProps = {
    cursor: "initial"
}

export default Badge