import React, { Component } from "react";
import _ from "lodash";

// import "admin/administrator/ClientDashboard/SettingsCog.css";

import MainChargingOptionsContainer from "admin/administrator/ChargingOptions/MainChargingOptionsContainer";
export default class SettingsCog extends Component
{
    constructor(props)
    {
        super(props);
        this.state={
            modalShow:false,
        }
    }

    setStateFromChild(key, value) {
        let state = {};
        state[key] = value;
        this.setState(state);
    }

    render()
    {
        return (
            <React.Fragment>
                <div className={"Cog"}  onClick={() => this.setState({modalShow: true})}>
                    <i className="fas fa-cog "></i> 
                    <span > Settings</span>  
                </div>

                <MainChargingOptionsContainer 
                        setStateFromChild={this.setStateFromChild.bind(this)}
                        form = {this.state.pricingOpions}
                        show={this.state.modalShow}
                        >
                </MainChargingOptionsContainer>


            </React.Fragment>
        );
    }

}