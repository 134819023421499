let initState = {
    groups: [],
    sections: [],
    selectedGroups: [],
    selectedSections: [],
    products: [],
    filteredProducts: {},
    showProducts: false,
    productsQuantity: 0,
    lookup_id: null,
    section_lookup_id: null,
    showExtraFilter: false,
    selectedAxles: [],
    selectedAxlesSection: {},
    selectedBrands: [],
    uniqueBrands: [],
    nonAutoActive: false,
    vehicleName: "",
    fromNonPartNumber: false,
    positions: {
        left: false,
        right: false,
        rear: false,
        front: false
    },
    type: "",
    value: "",
    makeOptions: {
        make: {
            name: "Make",
            value: "",
            options: [],
            disabled: true
        },
        model_range: {
            name: "Model Range",
            value: "",
            options: [],
            disabled: true
        },
        bodyStyle: {
            name: "BodyStyle",
            value: "",
            options: [],
            disabled: true
        },
        fuel: {
            name: "Fuel",
            value: "",
            options: [],
            disabled: true
        },
        engine: {
            name: "Engine",
            value: "",
            options: [],
            disabled: true
        },
        model: {
            name: "Model",
            value: "",
            options: [],
            disabled: true
        },
        hp: {
            name: "HP",
            value: "",
            options: [],
            disabled: true
        },
        year: {
            name: "Year",
            value: "",
            options: [],
            disabled: true
        }
    }    
}

const searchResult = (state = initState, action) => {
    switch (action.type) {
        case 'SAVE_SEARCH_RESULTS':
            return saveSearchResult(state, action);
        case 'SAVE_SELECTED_SEARCH_TYPE':
            return saveSelectedSearchType(state, action);
        case 'CLEAR_USER_DATA':
            return state = {
                type: "",
                value: "",
                groups: [],
                lookup_id: null,
                section_lookup_id: null,
                sections: [],
                selectedGroups: [],
                selectedSections: [],
                selectedBrands: [],
                selectedAxles: [],
                selectedAxlesSection: [],
                products: [],
                filteredProducts: [],
                showProducts: false,
                uniqueBrands: [],
                positionLeft: false,
                positionRight: false,
                positionRear: false,
                positionFront: false,
                nonAutoActive: false,
                productsQuantity: 0,
                vehicleName: ""
            };
        default:
            return state;
    }
}

let saveSearchResult = (state, action) => {
    let newState = {...state};
    newState[action.payload.key] = action.payload.value;
    return newState;
}

let saveSelectedSearchType = (state, action) => {
    let newState = {...state};
    newState[action.payload.key] = action.payload.value;
    return newState;
}

export default searchResult;
