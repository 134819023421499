import React from "react";
import { SpinnerCircularFixed } from 'spinners-react';

const Loading = ({ loading }) => {
    return (
        loading ?
        <div className='loading'>
            <SpinnerCircularFixed size={75} thickness={180} speed={100} color="#2569A1" secondaryColor="rgba(255,255,255, .5)" />
         </div> : null
    )
}

export default Loading;