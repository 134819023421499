import React, {Component, Fragment} from "react";
// import "admin/administrator/account/styles/PartNumberPrefix.css";
import Switch from "react-switch";
import $ from "jquery";

class PartNumberPrefix extends Component {

    constructor(props) {
        super(props);

        this.state = {
            checked: false,
            types: {
                prefix: "",
                suffix: ""
            }
        };
    }

    componentDidMount() {
        let types = {
            prefix: this.props.supplier.prefix,
            suffix: this.props.supplier.suffix
        }
        this.setState({types: types});
    }

    handleInputChange(e) {
        let types = this.state.types;
        let value = e.target.value;
        let name = e.target.name;
        types[name] = value;
        this.setState({types: types}, () => {
            this.props.setStateFromChild("types", types);
        })
    }


    render() {
        return (
            <Fragment>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{width: '48%'}}>
                        <label>Prefix</label>
                        <input 
                                id="prefix"
                                type={"text"}
                                value={this.props.supplier.prefix}
                                placeholder={"Enter Value"}
                                onChange={this.handleInputChange.bind(this)}
                                name={"prefix"}
                                className={"form-control"}
                        />
                    </div>
                    <div style={{width: '48%'}}>
                        <label>Suffix</label>
                        <input 
                                id="suffix"
                                type={"text"}
                                value={this.props.supplier.suffix}
                                placeholder={"Enter Value"}
                                onChange={this.handleInputChange.bind(this)}
                                name={"suffix"}
                                className={"form-control"}
                        />
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default PartNumberPrefix;