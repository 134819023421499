
const statistic = (state = {}, action) => {
    switch (action.type) {
        case 'SELECT_LOOKUPS_MONTH':
            let lookupsMonth = action.month;
            return {
                lookupsMonth: lookupsMonth
            }
        case 'SELECT_TOP_ACCOUNTS_MONTH':
            let topAccountsMonth = action.month;
            return {
                topAccountsMonth: topAccountsMonth
            }
        case 'SELECT_LOGIN_TOTALS_MONTH':
            let tloginTotalsFrom = action.timeInterval['from'] || '';
            let tloginTotalsTo = action.timeInterval['to'] || '';
            return {
                tloginTotalsFrom: tloginTotalsFrom,
                tloginTotalsTo: tloginTotalsTo,
            }
        default:
            return state;
    }
}

export default statistic;