import React, { Fragment, useEffect, useState } from 'react';
// import "./ForgotPassword.css";
import axios from 'axios1';
import successIcon from "images/success-png.png";
import Loading from "admin/administrator/LoadingComponent/Loading.component";
import FormInput from "admin/administrator/FormInput/FormInput.component";

function ForgotPassword(props) {
    const [ loading, setLoading ] = useState(false);
    const [errors, setErrors] = useState([]);
    const [form, setForm] = useState({
        email: "",
        password: "",
        password_confirmation: ""
    });
    const [isReseted, setIsReseted] = useState(false);
    const [isChanged, setIsChanged] = useState(false);

    function handleResetPassword(e) {
        e.preventDefault();
        setLoading(true);
        axios.post("/password-reset", {email: form.email, url: window.location.href})
            .then((response) => {
                setLoading(false);
                if(response.data.hasOwnProperty('errors')) {
                    setErrors(response.data.errors);
                    return;
                }
                setIsReseted(true);
            })
    }

    function handleInputChange(e) {
        let f = {...form};
        f[e.target.name] = e.target.value;
        setForm(f);
    }

    function handleChangePassword(e) {
        e.preventDefault();
        setLoading(true);
        axios.post("/password-recover", {token: props.token, password: form.password, password_confirmation: form.password_confirmation})
            .then((response) => {
                setLoading(false);
                if(response.data.hasOwnProperty('errors')) {
                    setErrors(response.data.errors);
                    return;
                }
                setIsChanged(true);
            })
    }

    return (
        <Fragment>
            <Loading loading={loading} />
            {
                !isReseted && !props.token ?
                <form onSubmit={handleResetPassword.bind(this)}>
                    <h1 className="text-center mb-8 fw-400">Reset Password</h1>
                    <p className="text-center mb-40">Enter your email address so we can reset your password</p>
                    <div className="form-group mb-16">
                        <FormInput name='email'
                                    type='text'
                                    placeholder='Email address'
                                    error={errors.email}
                                    value={form.email}
                                    onChange={handleInputChange.bind(this)}
                        />
                        {
                            errors && errors["not_exist"] ? 
                                <div className="invalid-error mb-16 mt-16"><i class="fas fa-exclamation-circle"></i> <div className={"invalid-error-warning-right"}> {errors["not_exist"]} </div></div> : null
                        }
                    </div>
                    <button type="submit" style={{background: (props.colors[props.client] || props.colors['default'])}}>Submit</button>
                    <div className={"mt-16 cursor-pointer text-primary hover-underline"} onClick={() => props.goToLoginPage()}><i class="fas fa-arrow-left"></i> Back to login</div>
                </form> : null
            }
            {
                isReseted && !props.token ?
                <div className="text-center" style={{margin: "auto"}}>
                    <h1 className="mb-40 fw-400">Please check your email</h1>
                    <p>An email has been sent to <strong>{form.email}</strong>, simply click the link in the email to reset your password.</p>
                </div> : null
            }
            {
                !isChanged && props.token ? 
                <form className="change-form" onSubmit={handleChangePassword.bind(this)}>
                    <h1 className="text-center mb-40 fw-400">Change Password</h1>
                    <div className="form-group mb-16">
                        <FormInput name='password'
                                    type='password'
                                    placeholder={errors["password"] ? errors["password"] : "New Password"}
                                    error={errors.password}
                                    value={form.password}
                                    onChange={handleInputChange.bind(this)}/>
                    </div>
                    <div className="form-group mb-16">
                        <FormInput name='password_confirmation'
                                    type='password'
                                    placeholder={errors["password_confirmation"] ? errors["password_confirmation"] : "Confirm New Password"}
                                    error={errors.password_confirmation}
                                    value={form.password_confirmation}
                                    onChange={handleInputChange.bind(this)}/>
                    </div>
                    {
                        errors && errors["not_confirmed"] ? 
                            <span className="invalid-error mb-16"><i class="fas fa-exclamation-circle"></i> {errors["not_confirmed"]}</span> : null
                    }
                    {
                        errors && errors["password"] ?
                            <span className="invalid-error mb-16"><i class="fas fa-exclamation-circle"></i> {errors["password"]}</span> : null
                    }
                    <button type="submit" style={{background: (props.colors[props.client] || props.colors['default'])}}>Save Changes</button>
                </form> : null
            }
            {
                isChanged && props.token ?
                    <div className="text-center" style={{margin: "auto"}}>
                        <img src={successIcon} width="72px"/>
                        <h1 className="fw-400 mb-40">Success!</h1>
                        <p>Your new password has been set successfully!</p>
                        <div className={"mt-16 cursor-pointer text-primary"} onClick={() => props.goToLoginPage()}><i class="fas fa-arrow-left"></i> Back to login</div>
                    </div> : null
            }
        </Fragment>   
    )
}

export default ForgotPassword