import React, { Fragment } from 'react';
import "./AlertTooltip.css";

function AlertTooltip(props) {

    return (
        <Fragment>
            <div class="tooltip-wrap">
                <div class="tooltip-icon" style={{background: props.bgColor}}>
                    <i class="fas fa-question"></i>
                </div>
                <div class={"tooltip-text " + props.textPosition}>
                    <i class="fas fa-exclamation-triangle fa-2x"></i>
                    <span>{props.text}</span>
                </div>
            </div>
        </Fragment>
    )
}

export default AlertTooltip