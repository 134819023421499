import React, { useState, useEffect, Fragment } from "react";
import AccountDetails from "admin/administrator/AccountDetails";
import Logins from "admin/administrator/Logins";
import StockPreferences from "admin/administrator/StockPreferences";
import {BrowserRouter as NavLink} from "react-router-dom";
import PricingLevels from "admin/administrator/PricingLevels";
import alertify from 'alertifyjs';
import axios from "axios1";
import CustomButton from "admin/administrator/CustomButton";
import UserPreferences from 'admin/administrator/UserPreferences.js';
import {withRouter} from 'react-router-dom';
import _ from "lodash";
import ChangeSaveOrDiscardModal from 'admin/administrator/ChangeSaveOrDiscardModal/ChangeSaveOrDiscardModal';
import Notify from "components/Notify";
import Block from "components/Block";
import Loading from "admin/administrator/LoadingComponent/Loading.component";
import { useTranslation } from 'react-i18next';
import i18n, { t } from 'i18next';

const EditAccount = props => {
    const { t, i18n } = useTranslation();
    const [cataloguePreferences, setCataloguePreferences] = useState([]);
    const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
    const [branches, setBranches] = useState([]);
    const [account, setAccount] = useState({});
    const [price_level_id, setPrice_level_id] = useState(null);
    const [isSupplier, setIsSupplier] = useState(false);
    const [loading, setLoading] = useState(false);
    const [api_id, setApi_id] = useState(null);
    const [credentials, setCredentials] = useState("");
    const [brands, setBrands] = useState([]);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [brandIds, setBrandIds] = useState([]);
    const [accountPreferences, setAccountPreferences] = useState([]);
    const [logins, setLogins] = useState([]);
    const [priceLevels, setPriceLevels] = useState([]);
    const [branches2, setBranches2] = useState([]);
    const [updatedBranches, setUpdatedBranches] = useState([]);
    const [errors, setErrors] = useState([]);
    const [catalogueModalShow, setCatalogueModalShow] = useState(false);
    const [visibleNotify, setVisibleNotify] = useState(false);
    const [branchIds, setBranchIds] = useState([]);
    const [approveModal, setApproveModal] = useState({
        show: false,
        title: '',
        message: 'Would you like to send an email containing login credentials to each new/updated login?'
    });
    const [vrmCountries, setVrmCountries] = useState();
    
    useEffect(() => {
        axios.get("catalogue/get-vrm-countries")
            .then((response) => {
                if (response.statusText === "OK") {
                    setVrmCountries(response.data);
                }
            })
    }, []);

    var unblock = () => {};
    
    useEffect(() => {
        getValues();
    }, [])

    useEffect(() => {
        unblock();
    })

    useEffect(() => {
         if (cataloguePreferences.hasOwnProperty('form') || cataloguePreferences.hasOwnProperty('groups')) {
                unblock = props.history.block(() => {
                setShouldBlockNavigation(true)
                return false;
            });
        }
    }, [cataloguePreferences])

    useEffect(() => {
        let newBranches2 = [];
        let newBranches = branches;
        let mergedBranches = [];
        let activeBranches = _.map(branches2, (branch) => {
            branch.active = 1;
            return branch;
        });        
        activeBranches.forEach((branch2) => {
            let index = newBranches.findIndex((branch) => {
                return branch.id == branch2.id;
            })
            if (index > -1) {
                newBranches[index].active = 1;
                newBranches[index].checked = true;
                newBranches2.push(newBranches[index]);
                newBranches.splice(index, 1);
            }
        });
        mergedBranches = newBranches2.concat(newBranches);
        setUpdatedBranches(mergedBranches);
        setLoading(false);
    }, [branches, branches2])

    useEffect(() => {
        let loginsWithPreferences = logins.filter((login) => {
            return (login.preferences && login.preferences.groups && login.preferences.form) ? login : null;
        })
        if (loginsWithPreferences.length > 0) {
            unblock = props.history.block(() => {
                setShouldBlockNavigation(true);
                return false;
            });
        }
    }, [logins])

    useEffect(() => {
        let branchesIds = [];
        _.map(updatedBranches, function (element) {
            if (element.checked) {
                branchesIds.push(element.id)
            }
        })
        setBranchIds(branchesIds);
    }, [updatedBranches])

    const allBranches = () => {
        axios.get("catalogue/accounts/branches")
            .then((response) => {
                if (response.data.success) {
                    getValues();
                    setBranches(response.data.branches)
                } else {
                    alert("Error: " + response.data.msg);
                }
            })
    }

    const setSPriceLevelId = value => {
        setAccount({ ...account, price_level_id: value });
        setPrice_level_id(value);
    }

    const setBespokePrice = value => setAccount({ ...account, bespoke_price_id: value });    
    const setIsSupplierFromChild = accountType => setIsSupplier((accountType == "Supplier" ? true : false));
    
    const getValues = () => {
        setLoading(true);
        axios.get("catalogue/accounts/" + props.match.params.id + "/edit")
            .then((response) => {
                if (response.data.success) {
                    
                    if (response.data.account.business_type_id == 9) {
                        
                        const selectedBrands = response.data.account.brands.map((brand) => {
                            return {id: brand.id, name: brand.name}
                        });
                        const brandIds = response.data.account.brands.map((brand) => {
                            return brand.id;
                        })

                        setIsSupplier(true);
                        setApi_id(response.data.api_id);
                        setCredentials(response.data.credentials);
                        setBrands(response.data.brands);
                        setSelectedBrands(selectedBrands);
                        setBrandIds(brandIds)                        
                    }

                    const logins = response.data.logins.map((login) => {
                        login['password_confirmation'] = login.password;
                        return login;
                    })

                    setAccount(response.data.account);
                    setAccountPreferences(response.data.accountPreferences);
                    setLogins(logins);
                    setPriceLevels(response.data.priceLevels);
                    setBranches(response.data.branches);
                    setBranches2(response.data.branches2);
                } else {
                    props.history.push(`/admin/accounts`)
                }
            })
    }

    const getPreferences = async () => {
        let newCataloguePreferences = cataloguePreferences;
        let account_id = account.id;
        if (!newCataloguePreferences.form) {
            await axios.get("catalogue/preferences?type=" + 'account' + '&id=' + account_id)
                .then((response) => {
                    if (response.data.success) {
                        newCataloguePreferences = response.data.preferences;
                    }
                })
        }
        return newCataloguePreferences;
    }

    const getSections = group_id => axios.get("catalogue/preferences/sections?groupId=" + group_id + "&type=account&id=" + account.id);
    const getBrands = section_id => axios.get("catalogue/preferences/brands?sectionId=" + section_id + "&type=account&id=" + account.id)
    
    const setPriceLevelsFromChild = priceLevel => {
        let newPriceLevels = priceLevels;
        let existingPriceLevel = _.find(newPriceLevels, function (element) {
            return (element.section_id == priceLevel.section_id && element.brand_id == priceLevel.brand_id) ? true : false;
        })
        if (existingPriceLevel) {
            existingPriceLevel.price_level_id = priceLevel.price_level_id;
            existingPriceLevel.price_level = priceLevel.price_level;
        } else {
            newPriceLevels.push(priceLevel);
        }

        setPriceLevels([...newPriceLevels]);
    }

    const removePriceLevelException = priceLevel => {
        setLoading(true);
        if (priceLevel.id) {
            axios.delete("catalogue/price-level-exceptions/" + priceLevel.id)
                .then( response => {
                    let newPriceLevels = priceLevels.filter((item) => {
                        return item.id != priceLevel.id;
                    })
                    setPriceLevels(newPriceLevels);
                    setLoading(false);
                })
        } else {
            let newPriceLevels = _.clone(priceLevels);
            _.remove(newPriceLevels, function (item) {
                return (item.brand_id == priceLevel.brand_id && item.section_id == priceLevel.section_id) ? true : false;
            });
            setLoading(false);
            setPriceLevels(newPriceLevels)
        }
    }

    const getLoginsAfterSave  = id => {
        axios.get("catalogue/accounts/logins/" + id)
            .then((response) => {
                if (response.data.success) {
                    let newLogins = response.data.logins.map((login) => {
                        login['password_confirmation'] = login.password;
                        return login;
                    })
                    setLogins(newLogins);
                }
            });
    }

    const handleEditClick = () => {
        let filter = logins ? _.filter(logins, (e) => { return !e.id || e.is_updated ? true : false; }) : [];
        if (filter.length) {
// && branchIds.length > 0
            let collection = approveModal;
            collection.show = true;
            collection.title = "You have added or updated logins for account " + account.name + ".";
            setShouldBlockNavigation(false)
            setApproveModal({...collection});
        }else {
            updateAccountFunc(false);
        }
        props.history.block(() => true);
    }

    const updateAccountFunc = (status = false) => {
        //setLoading(true);
        setApproveModal({...approveModal, show: false})
        const newAccount = account;
        newAccount.price_level_id = newAccount.price_level_id ? newAccount.price_level_id : price_level_id;
        let newLogins = JSON.parse(JSON.stringify(logins));
        const newCataloguePreferences = JSON.parse(JSON.stringify(cataloguePreferences));
        if (newLogins && newLogins.length > 0) {
            newLogins = _.filter(newLogins, login => {
                
                if(typeof login.deleted_at == 'undefined' || (typeof login.deleted_at != 'undefined' && login.deleted_at == null )) {
                    if (login.preferences) {
                        if (login.preferences.groups) {
                            let groups = login.preferences.groups;
                            login.preferences.groups = filtreGroups(groups);
                        }
                        if (login.preferences.form) {
                            login.preferences.form = filtreForm(login.preferences.form);
                        }
                    }
                    return login;
                }
            })
        }
        newCataloguePreferences.groups = filtreGroups(newCataloguePreferences.groups)
        if (newCataloguePreferences.form) {
            newCataloguePreferences.form = filtreForm(newCataloguePreferences.form);
        }

//        if(branchIds.length == 0) {
//            alertify.error("At least one branch should be checked.");
//            return;
//        }

        setLoading(true);
        setShouldBlockNavigation(false);

        axios.put("catalogue/accounts/" + props.match.params.id, {
            account: newAccount,
            logins: newLogins,
            credentials: credentials,
            cataloguePreferences: newCataloguePreferences,
            brandIds: brandIds,
            api_id: api_id,
            notificationStatus: status,
            priceLevels: priceLevels,
            branchIds: branchIds
        }).then((response) => {
            const newErrors = response.data.errors ? response.data.errors : [];
            setLoading(false);
            setErrors(newErrors);
            if (response.data.success && !response.data.errors) {
                alertify.success(response.data.message);
                props.history.block(() => true);
                props.history.push("/admin/accounts");
            } else {
                let message = response.data && response.data.errors && response.data.errors.length > 0 ? response.data.errors.toString() : response.data.message;
                alertify.error(message);
            }
        })
    }

    const filtreForm = form => {
        if (form.additional_elements) {
            let additional_elements = _.filter(form.additional_elements, function (additional_element) {
                if (additional_element.checked) {
                    return additional_element.value;
                }
            })
            form.additional_elements = _.map(additional_elements, 'value');
        }
        if(form.search_types) {
            let search_types = _.filter({...form.search_types}, search_type => {
                if (search_type.checked) {
                    return search_type.value;
                }
            })
            form.search_types = _.map(search_types, 'value');
        }
        if (form.manufacturers) {
            let manufacturers = _.filter(form.manufacturers, manufacture => {

                if (manufacture.checked) {
                    return manufacture.name;
                }
            })
            form.manufacturers = _.map(manufacturers, 'name');
        }
        return form;
    }

    const filtreGroups = groups => {
        groups = _.map(groups, group => {
            let response = {
                id: group.id,
                sections: {}
            };
            if (group.sections) {
                response.sections = _.map(group.sections, section => {
                    let response = {
                        id: section.id,
                        checked: section.checked,
                        // brands: []
                    };
                    if (section.brands) {
                        let brands = _.filter(section.brands, brand => {
                            if (brand.checked) {
                                return brand.id;
                            }
                        })

                        response.brands = _.map(brands, 'id');
                    }
                    return response;
                })
            }
            return response;
        });
        return groups;
    }

    const closeNotifyApprove = () => setApproveModal({...approveModal, show: false});

    const Delete = () => {
        setLoading(true);
        axios.post("catalogue/accounts/" + props.match.params.id, {_method: 'DELETE'})
        .then((response) => {   
            setLoading(false);
            if(response.data.success){
                alertify.success(response.data.message);
                props.history.push(`/admin/accounts`)
            }else{
                alertify.error("Error occured");
            }
        })
    }

    alertify.defaults = {
        notifier: {
            position: 'top-right',
            delay: 5
        }
    }
    
    return (
        <Fragment>
            <Loading loading={loading}/>
            {
                shouldBlockNavigation &&
                    <ChangeSaveOrDiscardModal
                        saveChanges={handleEditClick}
                        closeChangesModalDisregard={() => {
                            logins.map((login) => {
                                delete login.preferences.groups;
                                delete login.preferences.form;
                            });
                            setShouldBlockNavigation(false)
                            props.history.block(() => true);
                        }}
                        modalShow={shouldBlockNavigation}
                        closeChangesModal={() => setShouldBlockNavigation(false)}
                    />
            }
            <div className={"page-content admin-wrapper"}>
                <div className="grid">
                    <div className="grid__row">
                        <div className="grid__column grid__column--3">
                            <Block type="1" title={t("customer details")}>
                                <AccountDetails account={account}
                                            setAccount={setAccount}
                                            setCurrentBranch={() => {}}
                                            isSupplier={isSupplier}
                                            setPriceLevels={setPriceLevelsFromChild}
                                            setIsSupplier={setIsSupplierFromChild}
                                            brands={brands}
                                            selectedBrands={selectedBrands}
                                            errors={errors}
                                            setErrors={ setErrors}
                                            vrmCountries={vrmCountries}/>
                            </Block>
                            <Block type="6" title={t("pricing")} style={{marginTop: "24px"}}>
                                <PricingLevels savedPriceLevels={priceLevels}
                                        removePriceLevelException={removePriceLevelException}
                                        save={setPriceLevelsFromChild}
                                        setSPriceLevelId={setSPriceLevelId}
                                        priceLevelId = {account.price_level_id}
                                        setBespokePrice={setBespokePrice}
                                        bespokePriceId={account.bespoke_price_id}
                                        errors={errors}/>
                            </Block>
                        </div>
                        <div className="grid__column grid__column--6">
                            <Block type="1" title={t("users")}>
                                <Logins cataloguePreferences={cataloguePreferences}
                                        account={account}
                                        logins={logins}
                                        setLogins={setLogins}
                                        isCustomer={true}
                                        edit={true}/>
                                </Block>
                        </div>
                        <div className="grid__column grid__column--3">
                            <Block type="1" title={t("stock preferences")}>
                                <StockPreferences branches={updatedBranches}
                                        setBranches={ setUpdatedBranches }
                                        customer="true"/>
                            </Block>
                        </div>
                    </div>
                </div>
                <div className="page-actions">
                    <div className="page-actions__action">
                        <CustomButton title={t("Account Preferences")} type={"gray"} onClick={() => setCatalogueModalShow(true)}/>
                    </div>
                    <div className="page-actions__action">
                        <NavLink to={"/admin/client/dashboard"}>
                            <CustomButton title={t("Cancel")} type={"lightgray"}/>
                        </NavLink>
                    </div>
                    <div className="page-actions__action">
                        <CustomButton title="Delete" type={"red"} onClick={() => setVisibleNotify(true)}/>
                    </div>
                    <div className="page-actions__action">
                        <CustomButton title={t("Save")} type={"green"} onClick={handleEditClick}/>
                    </div>
                </div>
            </div>
            <UserPreferences show={catalogueModalShow}
                                preferences={getPreferences}
                                getSections={getSections}
                                getBrands={getBrands}
                                currentElement={{
                                    name: account.name,
                                    title: "Catalogue Preferences for Account - "
                                }}
                                isCustomer={true}
                                onHide={setCatalogueModalShow}
                                updatePreferencesValues={ setCataloguePreferences }/>
            <Notify type={"warning"}
                    visible={approveModal.show}
                    close={closeNotifyApprove}
                    primaryText={approveModal.title}
                    secondaryText={approveModal.message}
                    actions={[
                        {
                            name: "no",
                            action: updateAccountFunc.bind(this, false),
                            type: "lightgray"
                        },
                        {
                            name: "yes",
                            action: updateAccountFunc.bind(this, true),
                            type: "blue"
                        }
                    ]}/>
            <Notify type={"danger"}
                    visible={visibleNotify}
                    close={() => setVisibleNotify(false)}
                    primaryText={t("Are you sure?")}
                    secondaryText={`The ${account.business_type ? account.business_type.name : ""} "${account.name}" will be permanently deleted.`}
                    actions={[
                        {
                            name: t("cancel"),
                            action: () => setVisibleNotify(false),
                            type: "lightgray"
                        },
                        {
                            name: t("delete"),
                            action: Delete.bind(this),
                            type: "red"
                        }
                    ]}/>
        </Fragment>
    );
}

export default withRouter(EditAccount);

