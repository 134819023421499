import React, {Component} from "react";
// import "admin/administrator/LostSales.css";
// import "admin/administrator/CustomElements.css";
import {Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup} from "react-bootstrap";
import axios from "axios1";
var moment = require('moment');

class LostSaleDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            productId: null,
            productDetails: []
        }
    }

    render() {
        return (
            <div className={"page-content"}>
                <div className={"lost-sales-details-wrapper"}>
                    <div className={"lsd-modal-header"}>
                        <div className={"general-info-item"}>
                            <span>{this.props.stats.stats.part_number}</span>
                            {/* <img class="brand-image" src={this.props.stats.stats.brand_image}></img> */}
                        </div>
                    </div>
                    <div className={"lsd-modal-body"}>
                        <div className={"product-details"}>
                            <div className={"details-body"}>
                                <Row className="show-grid">
                                    <Col md={5}>
                                        <div className={"product-stats"}>
                                            <div className={"stat-item"}>
                                                <div className={"stat-name-value"}>
                                                    <span className={"stat-name"}>BRAND</span>
                                                    <img class="brand-image-lost-sale" src={this.props.stats.stats.brand_image}></img>
                                                </div>
                                            </div>
                                            <div className={"stat-item"}>
                                                <div className={"stat-name-value"}>
                                                    <span className={"stat-name"}>REASON</span>
                                                    <span className={"stat-value"}>{this.props.stats.stats.reason}</span>
                                                </div>
                                            </div>
                                            <div className={"stat-item"}>
                                                <div className={"stat-name-value"}>
                                                    <span className={"stat-name"}>section</span>
                                                    <span className={"stat-value"}>{this.props.stats.section}</span>
                                                </div>
                                                <div className={"stat-image"}>
                                                    <img width={"50px"} src={this.props.stats.section_image} />
                                                </div>
                                            </div>
                                            <div className={"stat-item"}>
                                                <div className={"stat-name-value"}>
                                                    <span className={"stat-name"}>last 12 months sales</span>
                                                    <span className={"stat-value"}>
                                                        {this.props.stats.stats.quantity ? this.props.stats.stats.quantity : "0"}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className={"stat-item"}>
                                                <div className={"stat-name-value"}>
                                                    <span className={"stat-name"}>current branch</span>
                                                    <span className={"stat-value"}>stock</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"product-stocks"}>
                                            {
                                                this.props.stats.stock.length > 0 ?
                                                    <table className={"custom-table table table-bordered"}>
                                                        <thead>
                                                            <tr>
                                                                <th>branch</th>
                                                                <th>qty</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            this.props.stats.stock.map((branch,index) => {
                                                                return <tr  key={index}>
                                                                            <td>{branch.branch}</td>
                                                                            <td>{branch.quantity}</td>
                                                                        </tr>
                                                            })
                                                        }
                                                        </tbody>
                                                    </table> : null
                                            }
                                        </div>
                                    </Col>
                                    <Col md={7}>
                                        <div className={"product-img"}>
                                            <img src={this.props.stats.image} />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LostSaleDetails;