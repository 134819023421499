import React, {Fragment, useEffect, useState} from "react";
import axios from "axios1";
import alertify from "alertifyjs";
import _ from "lodash";
import TableActions from "admin/administrator/TableActions";
import {connect} from "react-redux";
import {undoDeleteAccount} from "redux/actions/deleteAccounts";
import CustomStatus from "admin/administrator/CustomStatus";
import CustomUndo from "admin/administrator/CustomUndo";
import CustomReactTable from "admin/administrator/CustomReactTable.js";
import Loading from "admin/administrator/LoadingComponent/Loading.component";
import Block from "components/Block";
import { useTranslation } from 'react-i18next';

function ViewAccounts(props) {
    const { t, i18n } = useTranslation();
    const [accounts, setAccounts] = useState([]);
    const [dataFilter, setDataFilter] = useState('all');
    const [loading, setloading] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteAccountsQueue, setDeleteAccountsQueue] = useState(null);
    const [deleteInterval, setDeleteInterval] = useState(false);
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(1);
    const [showActions, setShowActions] = useState(false);

    const columns = React.useMemo(
        () => [
            {
                Header: t('Acc No'),
                accessor: 'accountNumber',
                disableSortBy: true,
                className: "textLeft",
            },
            {
                Header: t('Account Name'),
                accessor: 'accountName',
                disableSortBy: true,
                className: "textLeft",
                Cell: ({row}) => (
                    row.values.accountName ? row.values.accountName.replace(`_deleted_${row.original.accountId}`, "") : row.values.accountName
                ),
            },
            {
                Header: t('Contact'),
                accessor: 'contact_name',
                disableSortBy: true,
                className: "textLeft",
            },
            {
                Header: t('Email'),
                accessor: 'email',
                disableSortBy: true,
                className: "textLeft",
                Cell: ({row}) => (
                    row.values.email ? row.values.email.replace(`_deleted_${row.original.accountId}`, "") : row.values.email
                ),
            },
            {
                Header: t('Account Type'),
                accessor: 'accountType',
                disableSortBy: false,
                className: "textLeft",
            },
            {
                Header: t('Price Level'),
                accessor: 'priceLevelName',
                disableSortBy: false,
                className: "textLeft",
                Cell: props => (
                    props.row.original.priceLevelName ? (props.row.original.priceLevelName + (props.row.original.bespokePriceName ? ('/' + props.row.original.bespokePriceName) : '')) : ""
                ),
            },            
            {
                Header: t('Status'),
                Cell: ({row}) => (
                   <CustomStatus item={row.original} isAccount={true}/>
                ),
            },
            {
                Header: t('Actions'),
                className: "textCenter",
                Cell: (row) => (
                   <TableActions 
                        key={row.row.original.accountId}
                        id={row.row.original.accountId}
                        showActions={showActions}
                        showActionPopup={showActionPopup.bind(this, row.row.original.accountId)}
                        // restore
                        deleted={row.row.original.deleted_at}
                        onRestore={onRestore.bind(this, row.row.original.accountId)}                        
                        // edit
                        onEditClick={() => props.history.push(initEditLink(row.row))}
                        // suspend
                        suspendChecked={row.row.original.suspend}
                        onSuspendClick={handleSuspendClick.bind(this, row.row.original)}
                        // approve
                        activeChecked={(row.row.original.approved) ? true : false}
                        activeDisabled={(row.row.original.logins_count > 0 || row.row.original.accountType == "Supplier") ? false : true}
                        onActiveChange={handleSwitchChange.bind(this, row.row.original)}        
                        //delete
                        isAccount={true}
                        accountType={row.row.original.accountType}
                        name={row.row.original.accountName}
                        delete={deleteAccount.bind(this, row.row.original.accountId, row.row.original.accountType)}
                    />
                ),
            }
        ]
    );

    useEffect(() => {
        checkDelete();
    }, []);

    useEffect(() => {
        if(deleteAccountsQueue) {
            setDeleteInterval(setTimeout(function() {
                deleteSupplier(deleteAccountsQueue.id);
                undo();
            }, 6000));
        }
    }, [deleteAccountsQueue]);
    
    function deleteAccount(id, type = ''){
        setloading(true);
        
        if(type.toLowerCase() == 'supplier') {
            setDeleteAccountsQueue({undoTitle: t("This supplier has been deleted"), id: id});
            setShowDeleteModal(true);
            setloading(false);
            setShowActions(false);
        }else {
            axios.post("catalogue/accounts/" + id ,{_method: 'DELETE'})
            .then((response) => {
                setloading(false);
                if(response.data.success){
                    alertify.success(response.data.message);
                    rerenderAccountsWithoutId(id);
                }else{
                    alertify.error(t("Error occured"));
                }
            })
        }
    }

    // Filter funcs Begin
    
    function onRestore(id) {
      axios.post("catalogue/accounts/restore/" + id)
        .then((response) => {
            if(response.data.success){
                alertify.success(response.data.message);
                let newAccounts = [];
                _.map(accounts, function (element) {
                    if (element.accountId != parseInt(id)) {
                        newAccounts.push(element);
                    }
                });
                setAccounts(newAccounts);
                setShowActions(false);
            }else{
                alertify.error(t("Error occured"));
            }
        })        
    }

    // Filter funcs End


    function showActionPopup(id) {
        if(showActions != id) {
            setShowActions(id);    
        }else {
            setShowActions(false);
        }
    }

    function checkDelete() {
        if (props.deleteAccounts && props.deleteAccounts.length > 0) {
            setDeleteAccountsQueue(props.deleteAccounts[0]);
            setShowDeleteModal(true);
        }
    }
    
    function undo() {
        props.undoDeleteAccount();
        setShowDeleteModal(false);
        setDeleteAccountsQueue(null);
        clearTimeout(deleteInterval);
    }

    function onHide() {
        let id = deleteAccountsQueue.id;
        clearTimeout(deleteInterval);
        showDeleteModal(false);
        deleteAccountsQueue(null);
        deleteSupplier(id);
        props.undoDeleteAccount();
    }

    function deleteSupplier(id) {
        setloading(true);
        axios.post("catalogue/accounts/" + id, {_method: 'DELETE'})
            .then((response) => {
                setloading(false);
                if (response.data.success) {
                    rerenderAccountsWithoutId(id);
                    alertify.success(response.data.message);
                } else {
                    alertify.error(t("Error occured"));
                }
            })
    }

    function rerenderAccountsWithoutId(id) {
        let newAccounts = [];
        _.map(accounts, function (element) {
            if (element.accountId != parseInt(id)) {
                newAccounts.push(element);
            }
        });
        setAccounts(newAccounts);
    }

    function initEditLink(props) {
        let accountId = props.original.accountId;
        let accountType = props.original.accountType;
        let urls = {
            Branch: "/admin/EditBranch/" + accountId,
            Supplier: "/admin/editSupplier/" + accountId,
        }
        return urls[accountType] ? urls[accountType] : "/admin/accounts/edit/" + accountId;
    }
    
    function handleSwitchChange(prop, checked) {
        const accountId = prop.accountId;
        const disableOrEnable = (checked) ? 1 : 0;
        approveOrDisapproveAccount(accountId, false, checked, disableOrEnable);
    }

    function handleSuspendClick(prop) {
        const accountId = prop.accountId;
        approveOrDisapproveAccount(accountId, false, false, 0, 1);
    }

    function approveOrDisapproveAccount(accountId, status, checked = true, disableOrEnable = 1, suspend = 0) {
        setloading(true);
        axios.put("catalogue/accounts/status/" + accountId + '/' + disableOrEnable + '/' + suspend, {status: status})
            .then((response) => {
                let newAccounts = JSON.parse(JSON.stringify(accounts));
                if (response.data.success) {
                    let account = _.find(newAccounts, {accountId: response.data.account.id});
                    account.approved = checked;
                    account.suspend = suspend;
                    alertify.success(response.data.msg);
                } else {
                    alertify.error(response.data.msg);
                }
                setAccounts(newAccounts)
                setloading(false);
            });
    }

    const handleSort = ( sortBy = false, search = '', page = 1 ) => {
        //Remove server sort
        let id = sortBy ? sortBy.id : '';
        let desc = sortBy ? sortBy.desc : '';
        axios.get("catalogue/view-accounts?page=" + page + "&search=" + search + "&sort=" + id + "&desc=" + desc + '&filter=' + dataFilter )
            .then((response) => {
                if (response.data.success) {
                    setAccounts(response.data.accounts);
                    setPage(page);
                    setPages(response.data.pages);
                }
            });
    };

    return (
        <Fragment>
            <Loading loading={loading}/>
            <div className={"page-content admin-wrapper"}>
                <Block title={t("Accounts List")} type="1">
                    <CustomReactTable columns={columns}
                        pagination={true}
                        page={page}
                        pages={pages}
                        getData={handleSort.bind(this)}
                        data={accounts}
                        searchDisabled={false}
                        dsiabledSort={false}
                        setFilter={setDataFilter}
                        dataFilter={dataFilter}
                        filters= {[
                            {name: t('Default View'), value: 'all'},
                            {name: t('Active'), value: 'active'},
                            {name: t('Inactive'), value: 'inactive'},
                            {name: t('Suspend'), value: 'suspended'},
                            {name: t('Pending'), value: 'pending'},
                            {name: t('Deleted'), value: 'deleted'}
                        ]}
                    />
                </Block>
                <CustomUndo show={showDeleteModal}
                        title={t("The supplier has been deleted")}
                        onHide={() => onHide()}
                        undo={() => undo()}/>
            </div>
        </Fragment>
    );
}

const mapStateToProps = state => ({
    deleteAccounts: state.deleteAccounts,
})

const mapDispatchToProps = {undoDeleteAccount};

export default connect(mapStateToProps, mapDispatchToProps)(ViewAccounts);