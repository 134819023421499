import React, { Component } from "react";
import 'components/CustomElements.css';
import 'components/CartSummary.css';
import {connect} from "react-redux";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";

class CartSummary extends Component
{
    constructor(props)
    {
        super(props);
    }

    render()
    {
        return (
            <div className="cart-summary">
                <div className="cart-summary__header">
                    <div className="cart-summary-pr-amount text-bold"><span>Subtotal</span><span>{this.subtotal()}</span></div>
                    {/*<div className="cart-summary-pr-amount"><span>Shipping</span><span>£12.00</span></div>*/}
                    {/*<span className="cart-summary-divider"></span>*/}
                    {/*<div className="cart-summary-pr-amount text-bold"><span>Total</span><span>{this.subtotal()}</span></div>*/}
                </div>
                <div className="cart-summary__body"></div>
                <div className="cart-summary__footer">
                    {   this.props.shoppingCart.length > 0 ?
                        <NavLink to="/Checkout" className="custom-green-btn">PROCEED TO CHECKOUT</NavLink>
                        : null
                    } 
                    <p className="car-summary-p">You wil able to review your order before submiting</p>
                    <NavLink className={"custom-gray-btn"} to="/Search">Back to Search</NavLink>
                </div>
            </div>
        );
    }

    subtotal()
    {
        let total = 0;
        let currency = "";
        this.props.shoppingCart.forEach((product) => {
            total += product.quantity * product.product.price;
            currency = product.product.currency.symbol;
        })
        return currency + parseFloat(Math.round(total * 100) / 100).toFixed(2);
    }
}

const mapStateToProps = state => ({
    shoppingCart: state.shoppingCart
})

export default connect(mapStateToProps)(CartSummary);