import React, { useEffect, useState } from 'react';
// import "./AuthContainer.css";
import LoginSmallImage from "components/clients/LoginSmallImage.js";
import LoginLogo from "components/clients/LoginLogo.js";
import SignIn from "components/Auth/SignIn";
import SignUp from "components/Auth/SignUp";
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from "react-redux";

function AuthContainer(props) {
    const [client, setClient] = useState(process.env.REACT_APP_CLIENT);
    const [colors, setColors] = useState({
        'default': '#F9A700',
        'newNordic': 'black',
        'gt': 'black',
        'stoneacre': '#348e44',
        'eurobrake': '#009dd8',
        'viking': 'black',
        'titan': 'black',
        'nk': '#FC3000',
        'quickco': '#55BB44'
    });

    useEffect(() => {
        showSigninForm();
    }, [])

    function showSignupForm() {
        let formSignUp = document.getElementsByClassName("signup")[0];
        let formSignIn = document.getElementsByClassName("signin")[0];
        if(document.body.contains(formSignIn) && document.body.contains(formSignIn)) {
            formSignUp.classList.add("signup--visible");
            formSignIn.classList.remove("signin--visible");
        }
    }

    function showSigninForm() {
        let formSignUp = document.getElementsByClassName("signup")[0];
        let formSignIn = document.getElementsByClassName("signin")[0];
        if(document.body.contains(formSignIn) && document.body.contains(formSignIn)) {
            formSignUp.classList.remove("signup--visible");
            formSignIn.classList.add("signin--visible");
        }
    }

    return (
        <div className={"auth-container"} style={{backgroundImage: `url(${LoginSmallImage})`}}>
            <SignIn logo={LoginLogo} colors={colors} client={client} showSignupForm={() => showSignupForm()} settings={props.settings}/>
            <SignUp logo={LoginLogo} 
                    colors={colors} 
                    client={client}
                    showSigninForm={() => showSigninForm()}/>
        </div>
    )
}

const mapStateToProps = (state) => ({
    settings: state.settings,
})

export default withRouter(connect(
    mapStateToProps,
    null
)(AuthContainer));