import React from "react";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import 'components/Login/LogoutBtn.css';
import { resetSwitchUser } from "redux/actions/authActions";

const ClearBtn = props => {
    const clear = () => {
        props.resetSwitchUser();
        window.location.reload();
    }

    return (
        <div className="subnav__link" onClick={clear} style={props.size ?? ''}>
            Clear
        </div>
    );
}

const mapDispatchToProps = {
    resetSwitchUser: resetSwitchUser,
};


export default withRouter(connect(
    null,
    mapDispatchToProps
)(ClearBtn));