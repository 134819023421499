import React, { Component } from "react";
import { Fragment } from "react";

export default class Fitment extends Component
{
    constructor(props){
        super(props);
    }

    render(){
        let product = this.props.product.product;
        let notes = product.part_number.fitmentNote ? product.part_number.fitmentNote : (product.pivot && product.pivot.note ? product.pivot.note : "");
        return (
            <Fragment>
                {
                    notes ? 
                        <span>{ notes }</span> : <span>N/A</span>
                }
                {/* <div id="accordion">
                    <div className="card-header">
                        {  
                            notes ? 
                           
                        <h5 className="mb-0">
                            { notes }
                        </h5>
                         :  null
                        }
                    </div>
                </div> */}
            </Fragment>
        )
    }
}