import React, { useState, useEffect, useRef  } from 'react';
import { useTable, useSortBy } from 'react-table';
import CustomPagination from "admin/administrator/CustomPagination.js";
import CustomTableSearch from "admin/administrator/CustomTableSearch.js";
import sorting_icon_default from "images/sorting_icon_default.svg";
import sorting_icon_down_active from "images/sorting_icon_down_active.svg";
import sorting_icon_up_active from "images/sorting_icon_up_active.svg";
import { useTranslation } from 'react-i18next';

const CustomReactTable = ({
        columns,
        data,
        pagination = false,
        page = 1,
        pages = 0,
        getData = () => {},
        print = 0,
        setFilter= () => {},
        setSearchToParent=() => {},
        filters= [
            {name: 'Default View', value: 'all'},
            {name: 'Active', value: 'active'},
            {name: 'Inactive', value: 'inactive'},
            {name: 'Suspend', value: 'suspended'},
            {name: 'Deleted', value: 'deleted'}
        ],
        searchDisabled = true,
        dsiabledSort = true,
        dataFilter = null,
        hiddenColumns = []
    }) => {

    const { t, i18n } = useTranslation();

    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(page);
    const prevPage = usePrevious(page);
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
          ref.current = value;
        });
        return ref.current;
    }
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: {sortBy}
    } = useTable( 
        {
            columns,
            data,
            disableSortBy: dsiabledSort,
            manualSortBy: true,
            initialState: {
                hiddenColumns: hiddenColumns
            }
        },
        useSortBy 
    );

    useEffect(() => {
        if( !dsiabledSort) {
            let sort = sortBy && sortBy.length > 0 ? sortBy[0] : false;
            getData(sort, search, currentPage);
        }else if( !searchDisabled && ( search || search === '') ) {            
            getData(search);
        }else if(prevPage && prevPage != currentPage){
            getData(currentPage)
        }
    }, [sortBy, search, currentPage, dataFilter]);

    const setSearchAttr = item => {
        setSearch(item)
        setCurrentPage(1);
        setSearchToParent(item);
    }

    const printClass = print === 1 ? "table-to-print" : "table-not-to-print";
    
    return (
        <div className={printClass + " table"}>
            {
                dataFilter || !searchDisabled ? 
                <div className={"table__header"}>
                    { searchDisabled ? null : <CustomTableSearch search={ search } setSearch={ setSearchAttr } /> }
                    {dataFilter && (
                        <div className="selector">
                            <select className="selector__selector" onChange={ e => { setCurrentPage(1); setFilter(e.target.value) }}>
                                {
                                    filters.map(filter => {
                                        return <option
                                            value={filter.value}
                                            selected={ filter.value == dataFilter }
                                        >{t(filter.name)}</option>
                                    })
                                }
                            </select>
                        </div>
                    )}
                </div> : null
            }
            <div className="table__content">
                <table {...getTableProps()} className="table__table">
                    <thead className="table__thead">
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="table__tr">
                            {headerGroup.headers.map(column => (
                            <th
                                className={"table__th " + column.className + ' table__th-sortable' }
                                // {...column.getHeaderProps()}
                                {...column.getHeaderProps(column.getSortByToggleProps())}
                            >
                                <span className="table__th-title">
                                    {column.render('Header')}
                                </span>
                                {
                                    column.disableSortBy != undefined && !column.disableSortBy ?
                                        (
                                            column.isSorted
                                            ? column.isSortedDesc
                                                ? <img className="table__th-icon" src={sorting_icon_down_active} />
                                                : <img className="table__th-icon" src={sorting_icon_up_active} />
                                            : <img className="table__th-icon" src={sorting_icon_default} />
                                        )
                                    : ''
                                }
                            </th>
                            ))}
                        </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="table__tbody">
                        {rows.map(row => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()} className="table__tr">
                            {row.cells.map(cell => {
                                return (
                                <td
                                    {...cell.getCellProps()}
                                    className={"table__td " + cell.column.className}
                                    data-attr={cell.column.Header}
                                >
                                    {cell.render('Cell')}
                                </td>
                                )
                            })}
                            </tr>
                        )
                        })}
                    </tbody>
                </table>
            </div>
            {
                pagination && <CustomPagination page={page} pages={pages} sendRequest={(setCurrentPage)}/>
            }
        </div>
    );
}

export default CustomReactTable;