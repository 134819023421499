import React, { Component, Fragment } from "react";
import 'components/ShoppingCartProduct.css';
import {connect} from "react-redux";
import { addProduct, removeProduct } from "redux/actions/shoppingCart";
import _ from "lodash";
import alertify from "alertifyjs";
import NoImage from 'images/noimage2.png';
import { isNull } from "util";

class ShoppingCartProduct extends Component
{
    constructor(props)
    {
        super(props);

        this.textInput = React.createRef();

        alertify.defaults = {
            notifier: {
                position: 'top-right',
                delay: 5
            }
        }
    }


    handleQuantityChange(event)
    {
        if(this.props.product.product.quantity < this.textInput.current.value) {
            alertify.error("Out of stock!");
            return;
        }
        this.props.dispatch(addProduct(this.props.product.product, event.target.value));
    }

    handleSubtractClick(event)
    {
        if(this.textInput.current.value == 1) {
            alertify.error("Quantity must be greater than zero!");
            return;
        }
        this.props.dispatch(addProduct(this.props.product.product, -1));
    }

    handleAddClick(product)
    {
        if(this.props.product.product.quantity <= this.textInput.current.value) {
            alertify.error("Out of stock!");
            return;
        }
        this.props.dispatch(addProduct(this.props.product.product, 1));
    }

    handleRemoveProduct(product) {
        this.props.dispatch(removeProduct(this.props.product));
    }

    render()
    {   
        let lookup = this.props.product.lookup;
        let product = this.props.product.product;
        let index = this.findIndex(this.props.shoppingCart, product.id);
        let productInState = (this.props.shoppingCart.length)? this.props.shoppingCart[index]:[];
        return (
            // style={{ backgroundImage: `url(require("../images/poligon.png"))` }}
            <div className="cart-product-wrapper">
                <div className="remove-item" onClick={() => this.handleRemoveProduct(product)}>
                    <div className={"remove-polygon-wings"}></div>
                    <div className={"remove-poligon-wrappper"}>
                        <i class="fas fa-trash-alt"></i>
                    </div>
                </div>
                <div className="cart-product-body">
                         <div className="cart-product-body-header line-w-17">
                            <span>{product.part_number.part_number}</span>
                        </div>
                        {/* {
                            this.props.settings.images_in_shopping_cart ? <div className="cart-product-image image-padding line-w-17"><img className="cart-product-image__img" src={ this.productImage(product) } /></div> : null
                        } */}
                        <div className="cart-product-body-header line-w-17">
                            {
                                product.part_number.brand.image ? <div className="cart-brand-image image-padding line-w-17"><img className="cart-brand-image__img" src={ product.part_number.brand.image } /></div> : null
                            }
                            {
                                <span style={{fontSize: "0.6rem", color: "#eda9a9" }}>{lookup ? lookup : product.part_number.brand.name}</span>
                            }
                        </div>
                        <div className="cart-product-body-description line-w-17">
                            <div className="cart-product-body-header-title">{product.part_number.section.name}</div>
                        </div>
                        <div className="cart-product-body-footer-quantity line-w-17">
                            <div className="cart-product-body-footer-quantity__subract" onClick={this.handleSubtractClick.bind(this)}>-</div>
                            <div className="cart-product-body-footer-quantity__quantity"><input ref={this.textInput} value={productInState.quantity} onChange={this.handleQuantityChange.bind(this)} type="number" /></div>
                            <div className="cart-product-body-footer-quantity__add" onClick={this.handleAddClick.bind(this)}>+</div>
                        </div>
                        {
                            this.props.settings.show_prices ? 
                                <Fragment>
                                    <div className="cart-product-body-unit-price line-w-17">
                                        {product.currency.symbol + "" + parseFloat(Math.round(product.price * 100) / 100).toFixed(2)}
                                    </div>
                                    <div className="cart-product-body-total-price line-w-17">
                                        <div>{product.currency.symbol + "" + parseFloat(Math.round(productInState.quantity * product.price * 100) / 100).toFixed(2)}</div>
                                    </div>
                                </Fragment>
                            : null   
                        }
                    {/* </div> */}
                </div>
            </div>
        );
    }

    findIndex(products, id)
    {
        return _.findIndex(products, (element) => {
            return element.id == id;
        });
    }

    productImage(product)
    {
        //if(product.part_number.images.length == 0) { return "/images/noImage.png"; }
        return !isNull(product.part_number.section.image)? product.part_number.section.image:NoImage;
    }
}

// part_number.products[0]

const mapStateToProps = state => ({
    shoppingCart: state.shoppingCart,
})

export default connect(mapStateToProps)(ShoppingCartProduct);