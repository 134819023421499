import React, {useState, useEffect} from "react";
import Stock from "components/FullInfo/Stock";
import TabDataList from "components/FullInfo/TabDataList";
import Images from "components/FullInfo/Images";
import _ from "lodash";
import {connect} from "react-redux";
import "components/FullInfo/Main.css";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useTranslation } from 'react-i18next';
import axios from "axios1";

const Main = props => {
    const [product, setProduct] = useState([]);
    const [loading, setLoading] = useState(false);
    const [vehicles, setVehicles] = useState([]);
    const [oenumbers, setOenumbers] = useState([]);
    const [tehnicalDetails, setTehnicalDetails] = useState([]);
    // const [branches, setBranches] = useState([]);
    // const [suppliers, setSuppliers] = useState([]);
    // const defaultStock = props.product.stock[0].quantity > 0 ? true : false;
    const [tabIndex, setTabIndex] = useState(0);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const newProduct = JSON.parse(JSON.stringify(props.product));
        // newProduct.stock.shift();
        setProduct(newProduct);
        // setBranches(filterStock(newProduct, item => item.business_type === "Branch"));
        // setSuppliers(filterStock(newProduct, item => item.business_type === "Supplier"));

    }, [])

    useEffect(() => {
        // let indexes =  (props.settings.show_supplier_stock_info && props.auth.business_type_id == 3) ? 4 : 3
        let indexes = 2; 
        if(tabIndex === indexes) {
            if(!vehicles.length) {
                setLoading(true);
                loadVehicles();
            }
        }

        if(tabIndex === (indexes - 1)) {
            if(!oenumbers.length) {
                setLoading(true);
                loadOeNumbers();
            }
        }

        if(tabIndex === (indexes - 2)) {
            if(!tehnicalDetails.length) {
                setLoading(true);
                loadTehnicalDetails();
            }
        }
    }, [tabIndex])

    const loadVehicles = () => {
        const part_number_id = product.part_number_id;
        axios.get(`/catalogue/search/vehicles/${part_number_id}`)
            .then((response) => {
                if (response.data.success) {
                    setVehicles(response.data.vehicles);
                    setLoading(false)
                }
            });
    }

    const loadOeNumbers = () => {
        const part_number_id = product.part_number_id;
        axios.get(`/catalogue/search/oe-numbers/${part_number_id}`)
            .then((response) => {
                if (response.data.success) {
                    setOenumbers(response.data.oenumbers);
                    setLoading(false);
                }
            });
    }

    const loadTehnicalDetails = () => {
        const newProduct = JSON.parse(JSON.stringify(props.product));
        // newProduct.stock.shift();
        setProduct(newProduct);
        
        const part_number_id = newProduct.part_number_id;
        axios.get(`/catalogue/search/tehnical-details/${part_number_id}`)
            .then((response) => {
                if (response.data.success) {
                    setTehnicalDetails(response.data.tehnicalDetails);
                    setLoading(false);
                }
            });
    }
    
    const filterStock = (item, callBack) => {
        if(item.stock.length > 0) {
            return _.filter(item.stock, (item) => {
                return callBack(item);
            });
        }
        return item.stock;
    }

    return (
        <tr className="table__tr">
            <td className="table__td table__td--entire-row" colSpan={props.columns}>
                <div className="grid">
                    <div className="grid__row">
                        <div className="grid__column grid__column--8">
                            <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
                                <TabList>
                                    {/* <Tab>{t("Branch Stock")}</Tab>
                                    {
                                        (props.settings.show_supplier_stock_info && props.auth.business_type_id == 3) ? <Tab>{t("Supplier Stock")}</Tab> : null
                                    } */}
                                    <Tab>{t("Technical Details")}</Tab>
                                    <Tab>{t("Cross References")}</Tab>
                                    <Tab>{t("Vehicles")}</Tab>
                                </TabList>
                                {/* <TabPanel>
                                    <Stock  stockLoad={props.stockLoad}
                                            stocks={branches}
                                            stockVisibility = {props.settings.stock_visibility}
                                            title={t("Branch")}
                                            section_lookup_id={props.section_lookup_id}
                                            shoppingCart={props.shoppingCart}
                                            lookup={props.lookup}
                                            settings = {props.settings}
                                            product = {product}
                                            defaultStock = {defaultStock}/>
                                </TabPanel>
                                {
                                    (props.settings.show_supplier_stock_info && props.auth.business_type_id == 3) ? 
                                        <TabPanel>
                                            <Stock  stockLoad={props.stockLoad}
                                                    stocks={suppliers}
                                                    stockVisibility = {props.settings.stock_visibility}
                                                    title={t("Supplier")}
                                                    section_lookup_id={props.section_lookup_id}
                                                    shoppingCart={props.shoppingCart}
                                                    lookup={props.lookup}
                                                    settings = {props.settings}
                                                    product = {product}
                                                    defaultStock= {defaultStock}/>
                                        </TabPanel>
                                    : null
                                } */}
                                <TabPanel>
                                {
                                    <TabDataList
                                        title = 'TehnicalDetails'
                                        columns = {[
                                            'Name',
                                            'Value'
                                        ]}
                                        items = {tehnicalDetails}
                                        loading= {loading}
                                    />
                                }
                                </TabPanel>

                                <TabPanel>
                                {
                                    <TabDataList
                                        title = 'Cross References'
                                        columns = {[
                                            'Manufacturer',
                                            'OEnumber'
                                        ]}
                                        items = {oenumbers}
                                        loading= {loading}
                                    />
                                }
                                </TabPanel>
                                <TabPanel>
                                {
                                    <TabDataList
                                        title = 'Vehicles'
                                        columns = {[
                                            'Make',
                                            'Model',
                                            'Years',
                                            'Kw',
                                            'Hp',
                                            'BodyStyle'
                                        ]}
                                        items = {vehicles}
                                        loading= {loading}
                                    />
                                }
                                </TabPanel>
                            </Tabs>
                        </div>
                        <div className="grid__column grid__column--4">
                            <Tabs>
                                <TabList>
                                    <Tab>{t("Images")}</Tab>
                                </TabList>
                                <TabPanel>
                                    <Images product={product}/>
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    );
}

const mapStateToProps = state => ({
    products: state.shoppingCart,
    settings: state.settings,
    auth: state.auth.user.account
})

export default connect(mapStateToProps)(Main);