import React, {useState, useEffect} from "react";
import _ from "lodash";
import CustomPagination from "admin/administrator/CustomPagination";
import CustomReactTable from "admin/administrator/CustomReactTable.js";
import {BrowserRouter as Router, NavLink} from "react-router-dom";
import axios from "axios1";
import alertify from "alertifyjs";
import CustomButton from "../CustomButton";
import { useTranslation } from 'react-i18next';

var moment = require('moment');

function BranchesTable(props) {
    const { t, i18n } = useTranslation();
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(1);
    const [branches, setBranches] = useState([]);

    const columns = React.useMemo(
        () => [
            {
                Header: t("Branch name"),
                accessor: "branch",
                className: "textLeft",
                Cell: ({row}) => (
                    row.values.branch ? row.values.branch.replace(`_deleted_${row.original.id}`, "") : row.values.branch
                ),
            },
            {
                Header: t("user logins"),
                accessor: "activeLog.active",
                className: "textRight"
            },
            {
                Header: t("VRM"),
                accessor: "lookups",
                className: "textRight"
            },
            {
                Header: t("cost"),
                accessor: "cost",
                className: "textRight",
                Cell: ({value}) => (value.toFixed(2))
            },
            {
                Header: t("View"),
                accessor: "id",
                className: "textCenter",
                Cell: ({value, row}) => (
                    <NavLink
                        to={"/admin/branch/" + value + "/" + row.original.branch + "/dashboard/"}
                        exact>
                        <CustomButton title={t("View")} type={"blue"}/>
                    </NavLink>
                )
            }
        ]
    );

    useEffect(() => {
       getItems(); 
    }, []);

    useEffect(() => {
        getItems();
    }, [props.date])

    function getItems(page = 1) {
        axios.get('stats/branches? page=' + page + "&date=" + moment(props.date).format('YYYY-MM'))
            .then((response) => {
                if (response.data.success) {
                    setBranches(response.data.data.branches);
                    setPage(page);
                    setPages(response.data.data.pages);
                } else {
                    alertify.error(response.data.message);
                }
            });
    }

    return (
        <React.Fragment>
            <CustomReactTable columns={columns} 
                                pagination={true} 
                                page={page}
                                pages={pages} getData={(page) => getItems(page)}
                                data={branches}/>
        </React.Fragment>
    );
}

export default BranchesTable