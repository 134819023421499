import React, {Component} from "react";
import {BrowserRouter as Router, Route, NavLink} from "react-router-dom";
// import "admin/administrator/CustomElements.css";
// import "admin/administrator/LoginTotals/LoginTotalsWrapper.css";
import axios from "axios1";
import _ from "lodash";

export default class LoginTotalsWrapper extends Component {
    constructor(props) {
        super(props);

        this.state = {
            logins: {
                day: 0,
                month: 0,
                year: 0
            },
        }
    }

    componentDidMount() {
        this.LogintsTotals();
    }

    LogintsTotals() {
        axios.get("/catalogue/logins-totals")
            .then(response => {
                if(response.data.hasOwnProperty('success') && response.data.success) {
                    this.setState({logins: response.data.logins});
                }
            })
    }

    render() {
        return (
            <div className={"login-totals-wrapper"}>
                <div className={"login-totals-month"}>
                    <span className={"icon icon-user-circle"}></span>
                    <span style={{marginBottom: '10px'}} className={"analiticsMainText"}>Login Totals</span>
                    <NavLink to={"/admin/login-totals/accounts"} exact>
                        <button className={"custom-btn"}>Details</button>
                    </NavLink>
                </div>
                <div className={"login-totals-ranks"}>
                    <table className={"table table-bordered"}>
                        <tr>
                            <td>This Year</td>
                            <td>{this.state.logins.year}</td>
                        </tr>
                        <tr>
                            <td>This Month</td>
                            <td>{this.state.logins.month}</td>
                        </tr>
                        <tr>
                            <td>Today</td>
                            <td>{this.state.logins.day}</td>
                        </tr>
                    </table>
                </div>
            </div>
        );
    }

}