import axios from 'axios1';

export const reloadSettings = () => (
    (dispatch) => {
        return axios.get("catalogue/userSettings")
            .then(response => {
                dispatch({ type: 'INITIAL_SETTINGS', settings: response.data.settings });
                return response.data;
            });
    }
);