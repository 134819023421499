import React from "react";

const Modal = ({children, title, size, type, active, closeModal, scrollable, backdrop, printable}) => {
    return (
        <div className={"modal modal--" + type + (active ? " modal--active" : "")}>
            {
                backdrop && <div className="modal__backdrop" onClick={() => closeModal()}></div>
            }
            <div className={"modal__dialog modal__dialog--" + size + (scrollable ? " modal__dialog--scrollable" : "") + (printable && active ? " div-to-print" : "")}>
                <div className="modal__header">
                    <span className="modal__title">{title}</span>
                    <span className="modal__close no-print" onClick={() => closeModal()}>
                        <i class="fas fa-times"></i>
                    </span>
                </div>
                <div className="modal__content">
                    <div className="modal__body">{children}</div>
                </div>
            </div>
        </div>
    )
}

Modal.defaultProps = {
    title: "Enter Modal Title",
    size: "",
    type: "light",
    active: false,
    scrollable: false,
    backdrop: true,
    printable: false
}

export default Modal