import React, { Fragment, useState, useEffect} from "react";
import CustomReactTable from "admin/administrator/CustomReactTable.js";
import CustomTotalLogins from "admin/administrator/CustomTotalLogins.js";
import CustomVRMLookups from "admin/administrator/CustomVRMLookups.js";
import CustomOrders from "admin/administrator/CustomOrders.js";
import axios from "axios1";
import _ from "lodash";
import { useTranslation } from 'react-i18next';
// import "admin/administrator/BranchLoginsComponent.css";

const getBranchLogins = (branch_id, date, setData, setPage, setPages, page = 1) => {
    axios.get("stats/accounts/" + branch_id + "/logins", {params: {date: date, page: page}} )
    .then((response) => {
        if (response.data.success) {
            setData(response.data.data.logins);
            setPage(page);
            setPages(response.data.data.pages);
        }
    })
}

const AccountLoginsComponent = (props) => {
    const { t, i18n } = useTranslation();
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(0);

    const columns = React.useMemo(
        () => [
            {
                Header: t("Users"),
                accessor: "login",
                className: "textLeft"
            },
            {
                Header: t("Total Logins"),
                accessor: "logins",
                className: "textRight",
                Cell: ({value, row}) => (<CustomTotalLogins value={value} original={row.original} branch={props.branch} date={props.date} />)
            },
            {
                Header: t("VRM Lookups"),
                accessor: "lookups",
                className: "textRight",
                Cell: ({value, row}) => (<CustomVRMLookups value={value} original={row.original} branch={props.branch} date={props.date} />)
            },
            {
                Header: t("Cost"),
                accessor: "cost",
                className: "textRight",
                Cell: ({value}) => (value.toFixed(2))
            },
        ]
    );

    useEffect(() => {
        getBranchLogins(props.branch.id, props.date, setData, setPage, setPages);
    },props.date);

    return  (<div className={"branch-logins-component-wrapper"}>
                <CustomReactTable columns={columns}
                    pagination={true}
                    page={page}
                    pages={pages}
                    getData={getBranchLogins.bind(this, props.branch.id, props.date, setData, setPage, setPages)}
                    data={data}/>
            </div>);
}

export default AccountLoginsComponent;