import React, {Component} from 'react';
// import "admin/administrator/AccountStatistics.css";
import axios from "axios1";
import {BrowserRouter as Router, Route, NavLink} from "react-router-dom";
import PageTitle from 'admin/administrator/PageTitle';
import DatePicker from "react-datepicker";
import Chart from 'admin/administrator/Chart';

var moment = require('moment');
var store = require('store');

class AccountStatistics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            LoginMonth: 0,
            LoginYear: 0,
            LoginToDate: 0,
            lookups: {
                vrm: 0,
                vehicle: 0,
                PartNumber: 0
            },
            numbers: {
                datasets: [{
                    data: [],
                    label: '',
                    backgroundColor: '',
                    borderColor: '',
                    borderWidth: 0,
                    hoverBackgroundColor: '',
                    hoverBorderColor: '',
                }],
                labels: []
            },
            month: new Date(),


        }
    }

    componentDidMount() {
        this.LoginStatistics();
        this.LookupTotals();
        this.LastYearLogins();
    }


    LoginStatistics() {
        axios.get("/catalogue/logins")
            .then(response => {
                var data = response.data.logins[0];
                this.setState({LoginMonth: data.byMonth, LoginYear: data.byYear, LoginToDate: data.whole})
            })
    }

    LastYearLogins() {
        axios.get("/catalogue/last-year-logins")
            .then(response => {
                let numbers = {
                    labels: response.data.labels,
                    datasets: [{
                        data: response.data.values,
                        label: "",
                        backgroundColor: 'rgba(170,235,255,1)',
                        borderColor: '#7FE1FC',
                        borderWidth: 1,
                        hoverBackgroundColor: '#54D8FE',
                        hoverBorderColor: '#7FE1FC',
                    }]
                }
                this.setState({numbers: numbers});
            })
    }

    LookupTotals() {
        const month = this.state.month != "" ? moment(this.state.month).format('YYYY-MM') : "";
        axios.get("/catalogue/lookups?month=" + month)
            .then(response => {
                var data = response.data.lookups[0];
                var vrm = (data.vrm !== null) ? data.vrm : 0;
                var vehicle = (data.vehicle !== null) ? data.vehicle : 0;
                var partNumber = (data.partNumber !== null) ? data.partNumber : 0;
                this.setState({lookups: {vrm: vrm, vehicle: vehicle, PartNumber: partNumber}});
            })
    }


    handleChangeDate(month) {
        this.setState({month: month}, () => {
            this.LookupTotals();
        }); 
    }


    render() {

        return (
            <div>
                <div className={"page-content"}>
                    <div className={"container"}>
                        <div className={"row"}>
                            <div className={"left-statistics"}>

                                <div className="loginStatistics statisticsColomns">
                                    <div className="row">
                                        <div className="col-sm-4 pr-0">
                                            <p>Login Statistics</p>
                                        </div>
                                        <div className="col text-center">
                                            <span class="icon-User-tie"></span>
                                        </div>
                                        <div className="col">
                                            <NavLink className="DetailedBtn"
                                                     to="/admin/account-statistics/login-totals">Details</NavLink>
                                        </div>
                                    </div>
                                    <div className="row statistics">
                                        <div className="col">
                                            This month
                                        </div>
                                        <div className="col">
                                            this year
                                        </div>
                                        <div className="col">
                                            to date
                                        </div>
                                    </div>
                                    <div className="row actualNumbers">
                                        <div className="col">
                                            {this.state.LoginMonth}
                                        </div>
                                        <div className="col">
                                            {this.state.LoginYear}
                                        </div>
                                        <div className="col">
                                            {this.state.LoginToDate}
                                        </div>
                                    </div>
                                </div>

                                <div className="LookupTotals statisticsColomns">
                                    <div className="row">
                                        <div className="col-sm-4 pr-0">
                                            <p>Lookup Totals</p>
                                            <DatePicker
                                                className={"account-statistics-date"}
                                                onChange={this.handleChangeDate.bind(this)}
                                                selected={this.state.month}
                                                name="month"
                                                value={this.state.month}
                                                placeholderText="choose month"
                                                showMonthYearPicker
                                                dateFormat="MMM yyyy"
                                            ></DatePicker>
                                        </div>
                                        <div className="col text-center">

                                            <span class="icon-car-front"></span>
                                        </div>
                                        <div className="col">
                                            <NavLink className="DetailedBtn"
                                                     to="/admin/account-statistics/lookup-totals">Details</NavLink>
                                        </div>
                                    </div>
                                    <div className="row statistics">
                                        <div className="col">
                                            VRM
                                        </div>
                                        <div className="col">
                                            Vehicle
                                        </div>
                                        <div className="col">
                                            PartNumber
                                        </div>
                                    </div>
                                    <div className="row actualNumbers">
                                        <div className="col">
                                            {this.state.lookups.vrm}
                                        </div>
                                        <div className="col">
                                            {this.state.lookups.vehicle}
                                        </div>
                                        <div className="col">
                                            {this.state.lookups.PartNumber}
                                        </div>
                                    </div>
                                </div>

                                <div className="TopPerfAccounts statisticsColomns">
                                    <div className="row">
                                        <div className="col-sm-4 pr-0">
                                            <p>Top Performing Accounts</p>
                                            <DatePicker
                                                className={"account-statistics-date"}
                                                onChange={this.handleChangeDate.bind(this)}
                                                selected={this.state.month}
                                                name="month"
                                                value={this.state.month}
                                                placeholderText="choose month"
                                                showMonthYearPicker
                                                dateFormat="MMM yyyy"
                                            ></DatePicker>
                                        </div>
                                        <div className="col text-center">
                                            <span class="icon-cup"></span>
                                        </div>
                                        <div className="col">
                                            <NavLink className="DetailedBtn" to="/admin/loginTotal">Details</NavLink>
                                        </div>
                                    </div>
                                    <div className="row statistics">
                                        <div className="col">
                                            1st
                                        </div>
                                        <div className="col">
                                            2nd
                                        </div>
                                        <div className="col">
                                            3rd
                                        </div>
                                    </div>
                                    <div className="row actualNumbers">
                                        <div className="col">
                                            Cardif
                                        </div>
                                        <div className="col">
                                            Newport
                                        </div>
                                        <div className="col">
                                            Swansea
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"right-statistics"}>
                                <div className="col statisticsColomns">
                                    <Chart numbers={this.state.numbers}></Chart>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default AccountStatistics