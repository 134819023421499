import React, { Fragment, useState, useEffect }  from 'react';
import {Modal} from "react-bootstrap";
// import "admin/administrator/CustomUndo.css";
import _ from "lodash";

const modalClose = (onHide) => {
   onHide(); 
}

const CustomUndo = (props) => {
    return (
        <Fragment>
            {
                props.show ?
                    <div className={"undo"} >
                        <div className={"undo__title"}>{props.title}</div>
                        <div className={"undo__link"} onClick={props.undo.bind(this)}>Undo</div>
                        <i  className={"undo__close fas fa-times"} onClick={props.onHide.bind(this)} ></i>
                    </div> : null
            }
        </Fragment>
    );
}
export default CustomUndo;