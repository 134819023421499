import React, {Fragment, useEffect, useState} from "react";
import Notify from "components/Notify";
import axios from "axios1";

const RequestPriceBtn  = partnumber => {
    const [show, setShow] = useState(false);
    const requestPrice = () => {
        axios.post("catalogue/price-request-email",{partnumber: partnumber}).then(() => {
            setShow(false);
        });
    }
    
    return (
        <Fragment>
            <div className="cart-btn ml-8" onClick={() => setShow(!show)}>
                <span className={'cart-btn__btn'}>
                    Price Request
                </span>
            </div>
            <Notify type={"primary"}
                visible={show}
                close={() => setShow(false)}
                primaryText={"This will send a message to request the price for this item. Do you wish to continue ? "}
                // secondaryText={"Try search by vehicle selection or part number"}
                actions={[
                    {
                        name: "No",
                        action: () => setShow(false),
                        type: "red"
                    },
                    {
                        name: "Yes",
                        action: () => requestPrice(),
                        type: "blue"
                    }
            ]}/>
        </Fragment>
    );
}

export default RequestPriceBtn;
