import React from 'react';
import {ChromePicker} from 'react-color';
import {Component} from "react";
import _ from "lodash";
import axios from "axios1";
// import "admin/administrator/SettingStyles.css";
import {Container, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup} from "react-bootstrap";
import alertify from 'alertifyjs';
import PageTitle from "admin/administrator/PageTitle";
import $ from "jquery";
import ChangeThemeIcon from '../../components/ChangeThemeIcon';


export default class SettingStyles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayColorPickerMain: false,
            displayColorPickerHover: false,
            file: null,
            main_color: '#3964E6',
            hover_color: '#808080',
            errors: [],
            loading: false,
        };
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleLogoClick = this.handleLogoClick.bind(this);
    }

    componentDidMount() {
        this.setStateValues();
    }

    setStateValues(preferences) {
        axios.get("/catalogue/settings/styles")
            .then((response) => {
                this.setState({theme: response.data.styles.theme, image: response.data.styles.image}, () => {
                });
            });
    }

    handleClick(btn) {
        let obj = {};
        obj[btn] = !this.state[btn];
        this.setState(obj);
    };

    handleClickColorPicker(btn) {
        let obj = {};
        obj[btn] = true;
        this.setState(obj);
    }

    handleClose(btn) {
        let obj = {};
        obj[btn] = !this.state[btn];
        this.setState(obj)
    };

    handleChange(color, value) {
        let obj = {};
        obj[color] = value.hex;
        this.setState(obj);
    };


    handleSaveClick() {
        // this.props.onHide();
        $(".nordic-logo").attr('src', this.state.image);
        // this.props.updateStylesValues({theme: this.state.theme , logo: this.state.file});
    }

    handleFileChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
            return;

        let reader = new FileReader();
        reader.onload = (e) => {
            this.setState({
                image: e.target.result,
            })
        };
        this.setState({
            file: files[0]
        });
        reader.readAsDataURL(files[0]);
    }

    handleLogoClick() {
        this.refs.fileUploader.click();
    }

    render() {
        alertify.defaults = {
            notifier: {
                position: 'top-right',
                delay: 5
            }
        }

        const popover = {
            position: 'absolute',
            zIndex: '2',
        }
        const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }
        return (
            <div>
                <div className={"page-content"}>
                    <div className={"container"}>
                        <div className={"row"}>
                            <div className={"dashboard-page-wrapper "}>
                                <div className={"dashboard-page-body"}>
                                    <div className={"dashboard-user-preferences-body"}>
                                        <div className={"dashboard-styles-section-logo styles-border"}>
                                            <div className="dashboard-styles-sections-header">
                                                <span>CORPORATE LOGO</span></div>

                                            <div className={"dashboard-styles-logo-picker"}
                                                 onClick={this.handleLogoClick}>
                                                <div className={"dashboard-styles-logo"}>
                                                    <input type="file" id="file" ref="fileUploader"
                                                           onChange={this.handleFileChange}
                                                           style={{display: "none"}}></input>
                                                    <img className="dashboard-styles-corporate-logo"
                                                         alt="logo"
                                                         src={this.state.image}/>
                                                    <p>Click to change</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"dashboard-styles-theme-section styles-border"}>
                                            <div className="dashboard-styles-sections-header">
                                                <span>THEME</span></div>
                                            <div className={"dashboard-styles-theme"}>

                                                <div className={"dashboard-styles-theme-wrapper"}>
                                                    <div className={"light-dark-text"}>Dark</div>
                                                        <ChangeThemeIcon></ChangeThemeIcon>
                                                    <div className={"light-dark-text"}>Light</div>

                                                </div>

                                                <div className="coloPickerWrapper">

                                                    <div className={"dashboard-styles-item-wrapper"}
                                                         style={{marginTop: 30}}>
                                                        <div className={"dashboard-styles-btn"}
                                                             style={{background: this.state.main_color}}>Main
                                                            Colour
                                                        </div>
                                                        <div>{this.state.main_color}</div>
                                                        <div className={"dashboard-styles-color-picker"}
                                                             onClick={this.handleClick.bind(this, "displayColorPickerMain")}
                                                             style={{background: this.state.main_color}}>
                                                        </div>
                                                        {this.state.displayColorPickerMain ?
                                                            <div style={popover}>
                                                                <div style={cover}
                                                                     onClick={this.handleClose.bind(this, 'displayColorPickerMain')}/>
                                                                <ChromePicker color={this.state.main_color}
                                                                              onChange={() => this.handleChange.bind(this, 'main_color')}/>
                                                            </div> : null
                                                        }
                                                    </div>
                                                    <div className={"dashboard-styles-item-wrapper"}
                                                         style={{marginTop: 15}}>
                                                        <div className={"dashboard-styles-btn"}
                                                             style={{background: this.state.hover_color}}>Hover
                                                            Colour
                                                        </div>
                                                        <div>{this.state.hover_color}</div>
                                                        <div className={"dashboard-styles-color-picker"}
                                                             onClick={this.handleClickColorPicker.bind(this, "displayColorPickerHover")}
                                                             style={{background: this.state.hover_color}}>
                                                        </div>
                                                        {this.state.displayColorPickerHover ?
                                                            <div style={popover}>
                                                                <div style={cover}
                                                                     onClick={this.handleClose.bind(this, "displayColorPickerHover")}/>
                                                                <ChromePicker color={this.state.hover_color}
                                                                              onChange={() => this.handleChange.bind(this, 'hover_color')}/>
                                                            </div> : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* style={ {background:(this.state.theme) ? "white" : "#202020"} } */}

                                    </div>
                                    <div className={"setting-styles-button"}>
                                        <div className={"add-user-cancel-save-buttons-wrapper"}>
                                            <div className={"add-user-cancel-button"}>cancel</div>
                                            <div className={"add-user-save-button"}
                                                 onClick={this.handleSaveClick.bind(this)}>Save
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}