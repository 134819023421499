import React, { Component } from "react";
// import "admin/administrator/BranchView.css";
import Switch from "react-switch";
import axios from "axios1";
import _ from 'lodash';
import { GridLoader } from 'react-spinners';
import ReactTable from "react-table";
import BestSellers from "admin/administrator/BestSellers.js";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import alertify from 'alertifyjs';

export default class BranchView extends Component
{
    constructor(props){
        super(props);

        this.state = {
            branches: [],
            loading: false,
            columns: [
                {
                    Header: 'Branch Name',
                    accessor: 'name',
                    style: {
                        textAlign: "center" 
                    },
                    className: 'td-dark',
                },
                {
                    Header: "Number of Users",
                    accessor: "usersNumber",
                    Cell: row => (
                        <div>
                            {
                                row.original.users.length ?
                                    <NavLink to={"/admin/userList/" + row.original.id} exact className={""}>
                                        {row.original.users.length}
                                    </NavLink>
                                    :
                                    <div style={{ color: "#007bff" }}>0</div>
                            }
                        </div>
                    ),
                    width: 150,
                    style: {
                        textAlign: "center",
                    },
                    className: 'actions-td'
                },
                {
                    Header: 'Created',
                    accessor: 'created_at',
                    style: {
                        textAlign: "center"
                    },
                    className: 'td-dark'
                },
                {
                    Header: () => (
                        <span>
                        <i className="fas fa-edit" /> Actions
                    </span>
                    ),
                    Cell: props => (
                        <div>
                            <NavLink to={"/admin/branches/edit/" + props.original.id} exact className={"btn btn-danger branch-view"}>
                                View / Edit
                            </NavLink>
                        </div>
                    ),
                    width: 150,
                    style: {
                        textAlign: "center",
                    },
                    className: 'actions-td'
                },
                {
                    Header: () => (
                        <span>
                            <i className="fas fa-clipboard-check"></i> Enable / Disable
                        </span>
                    ), 
                    accessor: "active",
                    Cell: props => (
                        <Switch width={38} height={20} checked={(props.original.active)?true:false} onChange={this.handleSwitchChange.bind(this, props.original.id)}/>
                    ),
                    width: 150,
                    style: {
                        textAlign: "center",
                    },
                    className: 'actions-td'
                },
            ],
        }

        

        this.getSections();
    }

    getSections() {
        axios.get("catalogue/branches/list")
            .then((response) => {
                this.setState({branches: response.data.branches});
            });
    }
    
    handleSwitchChange(prop, checked){
        const branchId = prop;
        const disableOrEnable = (checked)?1:0;
        this.setState({loading:true});
        axios.put("catalogue/branches/actions/"+branchId+'/'+disableOrEnable)
            .then((response)=>{
                let branches = [...this.state.branches];
                if(response.data.success){
                    let index =_.findIndex(branches, {id: response.data.branch.id});
                    branches[index].active = checked;
                    alertify.success(response.data.msg);
                }else{
                    alertify.error(response.data.msg);
                }
                this.setState({branches: branches, loading: false});
            });
        
    }


    render()
    {
        let data;

        if(this.state.userNotFound) {
            data = [];
        } else {
            data = this.state.branches
        }

        return (
            <div className={"dashboard-page-wrapper"}>
                {
                    this.state.loading ?
                        <div className='sweet-loading'>
                            <GridLoader
                                sizeUnit={"px"}
                                size={15}
                                color={'#0066b8'}
                                loading={this.state.loading}
                            />
                        </div> : null
                }
                <div className={"dashboard-page-navigations"}>
                    <div>
                        <i className="fas fa-code-branch"></i>
                        <span> Add / Edit Branch</span>
                    </div>
                    <div>
                        <button className="back-btn" onClick={() => this.props.history.goBack()}>
                            Go Back
                        </button>
                    </div>
                </div>
                <div className={"add-new-search-wrapper"}>
                    <div className={"branch-button"}>
                        <div className={"branch-back-new-buttons-wrapper"}>
                            <NavLink style={{textDecoration: "none"}} to="/admin/branches/add" exact className={"admin-link add-new-btn"} activeClassName="active">
                                <div className={"branch-add-new-button"}>
                                    Create New Branch
                                </div>
                            </NavLink>
                        </div>
                    </div>
                    <div className={"search-wrapper"}>
                        <input type="text"
                               placeholder={"Search..."}
                               className={"users-search"}
                               // onChange={this.filterByCompany.bind(this)}
                        />
                    </div>
                </div>
                <ReactTable 
                        data={data}
                        columns={this.state.columns}
                        defaultPageSize={10}
                        minRows={0}
                    />
                <div style={{marginTop: "200px",marginBottom: "200px" , width: "772px" , height: "436px"}}>
                <BestSellers />
                </div>
            </div>
        ); 
    }
    
}
