import React, { useEffect, useState } from 'react';
import "./SearchFilterBtn.css";
import { useTranslation } from 'react-i18next';

function SearchFilterBtn(props) {
    
    const { t, i18n } = useTranslation();

    return <button className="vehicle__filter" onClick={props.handleClick}>
                <i className="fas fa-filter"></i>{t("Filter")}
                {
                    props.filtersCount > 0 &&
                        <span className="badge badge--danger">{props.filtersCount}</span>
                }
            </button>
}

export default SearchFilterBtn;