import React, { Component } from "react";
import CatalogueHeader from "components/CatalogueHeader";
import $ from "jquery";

class MasterPage extends Component
{
    constructor(props)
    {
        super(props);
    }

    render()
    {
        // $("body").removeAttr('class');
        // $("body").addClass('light');
        return (
            <div>
                {
                    // window.location.pathname != '/Login' ?
                     <CatalogueHeader /> 
                    //  : null
                }
                {this.props.children}
            </div>
        );
    }
}

export default MasterPage