import React, { Component } from "react";
import axios from "axios1";
import ReactTable from "react-table";
import CustomDatePicker from 'admin/administrator/CustomDatePicker';
// import "admin/administrator/VehicleLookups.css";
import "react-datepicker/dist/react-datepicker.css";
// import "admin/administrator/CustomElements.css";
// import "admin/administrator/vehicleManufacturerLookups/VehicleManufacturerLookupsDetailed.css";
import PageTitle from "admin/administrator/PageTitle";
import {NavLink} from "react-router-dom";
import CustomPieChart2d from 'components/Charts/CustomPieChart2d';
var moment = require('moment');

export default class VehicleManufacturerLookupsDetailed extends Component
{
    constructor(props)
    {
        super(props);
        let d = new Date();
        d = d.setMonth(d.getMonth() - 2);
        let d2 = new Date();
        d2 = d2.setMonth(d2.getMonth() - 0);
        this.state={
            lookupsChart: [
                ["Task", "Vehicles"]
            ],
            details :{
                total:879,
                lookups:32423
            },
            accounts:[],
            form:{
                account_id:this.props.location.state.account,
                month:this.props.location.state.month||d,
                endMonth:this.props.location.state.endMonth||d,
            },
            items:[],
            select_month:'',
            select_endMonth:'',
        }
    }

    componentDidMount(){
        this.getAccounts();
        this.getItems();
    }

    getAccounts(){
        axios.get("catalogue/view-accounts")
        .then((response) => {
            this.setState({accounts: response.data.accounts})
        });
    }



    handleChangeDate(month) {
        let form = { ...this.state.form };
        form['month'] = month;
        var select_month = month;
        this.setState({form:form, select_month:select_month}, () => {
            this.getItems();
        });
    }

    handleChangeEndDate(endMonth) {
        let form = { ...this.state.form };
        form['endMonth'] = endMonth;
        var select_endMonth = endMonth;
        this.setState({form:form, select_endMonth:select_endMonth}, () => {
            this.getItems();
        });
    }



    getItems(chart=null){
        let form = {...this.state.form};
        form.endMonth = moment(form.endMonth).format('YYYY-MM-DD');
        form.month = moment(form.month).format('YYYY-MM-DD');
        let make = this.props.match.params.make;
        let model = this.props.match.params.model;
        let engine = this.props.match.params.engine;
        axios.get("catalogue/orders/"+make+"/"+model+"/"+engine,{params:form})
        .then((response) => {
            let dataLabelsForChart = [];
            let dataValuesForChart = [];
            response.data.bySections.map((make) => {
                dataLabelsForChart.push(make.section);
                dataValuesForChart.push(make.quantity);
            })
            let details = {...this.state.details};
            details["total"] = response.data.orders_meta[0].quantity;
            details["lookups"] = response.data.orders_meta[0].lookups;
            this.setState({dataLabelsForChart:dataLabelsForChart,dataValuesForChart:dataValuesForChart,items:response.data.orders, details:details});
        });
    }

    handleInputChange(formName, event)
    {
        let value = event.target.value;

        let form = { ...this.state.form };

        form[formName] = value;
        this.setState({form:form}, () => {
            this.getItems();
        });
    }

    render() {
        let model = this.props.match.params.model;
        let make = this.props.match.params.make;
        let engine = this.props.match.params.engine;
        return (
            <div className={"vehicleManufacturerWrapper lookups-wrapper vehicles admin-wrapper"}>
                <div className="row">
                    <div className="col">
                        <p className="font-weight-bold">Product Sales For: {make+" "+model+" "+engine+". "}
                        From <span className="font-weight-bold">{moment(this.state.form.month).format('DD-MM-YYYY')}</span> To <span className="font-weight-bold">{moment(this.state.form.endMonth).format('DD-MM-YYYY')}</span></p>
                        <p>total: <span  className="font-weight-bold">{this.state.details.total}</span> item(s) sold from <span  className="font-weight-bold">{this.state.details.lookups}</span></p>
                    </div>
                    <div className="col">
                        <div className="row text-right custom-filter ">
                            <div className="col pl-0 filter-group">
                                <select className={"textUppercase"} name="accounts"  value={this.state.form.account_id} onChange={this.handleInputChange.bind(this, "account_id")}>
                                <option value="">All Accounts</option>
                                    {
                                        this.state.accounts.map((account) => {
                                            return <option key={account.accountId}
                                            value={account.accountId}>{account.accountName}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col pl-0 filter-group" >
                                <CustomDatePicker
                                    value={this.state.form.month} selected={this.state.select_month}
                                    placeholder={"choose from date"}
                                    onChange={this.handleChangeDate.bind(this)}
                                    format={"DD-MM-YYYY"}>
                                </CustomDatePicker>
                            </div>
                            <div className="col pl-0 filter-group" >
                                <CustomDatePicker
                                    value={this.state.form.endMonth} selected={this.state.select_endMonth}
                                    placeholder={"choose to date"}
                                    onChange={this.handleChangeEndDate.bind(this)}
                                    format={"DD-MM-YYYY"}>
                                </CustomDatePicker>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className="col ">
                        <div className="rowBack">
                            <table class="custom-table table table-bordered ">
                                <thead>
                                    <tr>
                                        <th scope="col">date</th>
                                        <th scope="col">account name</th>
                                        <th scope="col">account type</th>
                                        <th scope="col">section(s)</th>
                                        <th scope="col">oder no</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.items.map((item) => {
                                            return  <tr>
                                                        <td>{item.date}</td>
                                                        <td>{item.account}</td>
                                                        <td>{item.type}</td>
                                                        <td>
                                                        {
                                                            item.images?
                                                            item.images.split(',').map((img)=>{
                                                                return <img src={img} style={{float:"left",width:"50px"}}/>
                                                            }):null
                                                        }
                                                        </td>
                                                        <td className={"text-center"}>
                                                            <NavLink to ={'/admin/order-status-details/'+item.order_id} className="custom-btn" >#{item.order_id}</NavLink>
                                                        </td>
                                                    </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col  rowBack">
                        <CustomPieChart2d labels={this.state.dataLabelsForChart} values={this.state.dataValuesForChart}/>
                    </div>
                </div>
            </div>
        )
    }
}
