import React, {Component} from "react";
import Hubs from "admin/administrator/ManageHubs";
import ManageBrands from "admin/administrator/ManageBrands";
import Brands from "admin/administrator/Brands";
import Settings from "admin/administrator/Settings";
import Styles from "admin/administrator/SettingStyles";
import Sections from "admin/administrator/Sections";
import Branches from "admin/administrator/BranchView";
import Menu from "admin/administrator/Menu";
import Communication from "admin/administrator/Communication";
import AddUser from "admin/administrator/AddUser";
import UserList from "admin/administrator/UserList";
import Statistics from 'admin/administrator/Statistics';
// import "admin/index.css";
import {BrowserRouter as Router, Route, NavLink} from "react-router-dom";
import OrdersDetails from "admin/administrator/OrdersDetails";
import AbandonedCartsDetails from "admin/administrator/AbandonedCartsDetails";
import Lookups from "admin/administrator/Lookups";
import LookupCharts from "admin/administrator/LookupCharts";
import Charts from "admin/administrator/Charts";
import salesDetails from "admin/administrator/salesDetails";
import AdminHeader from "admin/administrator/AdminHeaderLeft";
import SelectAccount from "admin/administrator/account/SelectAccount";
import AddSupplier from "admin/administrator/account/AddSupplier";
import EditSupplier from "admin/administrator/account/EditSupplier";
import AddBranch from "admin/administrator/account/AddBranch";
import EditBranch from "admin/administrator/account/EditBranch";
import AddAccount from "admin/administrator/AddAccount";
import AccountStatistics from "admin/administrator/AccountStatistics";
// import LoginTotals from "admin/administrator/LoginTotals";
// import LoginTotalsUsers from "admin/administrator/LoginTotalUsers";
import EditAccount from "admin/administrator/EditAccount";
import ViewAccounts from "admin/administrator/ViewAccounts";
import Downloads from "admin/administrator/Downloads";
// import LookupTotals from "admin/administrator/LookupTotals";
import Hamburger from "admin/administrator/Hamburger";
import VehicleManufacturerLookups from "admin/administrator/vehicleManufacturerLookups/VehicleManufacturerLookups";
import vehicleManufacturerLookupsDetailed
    from "admin/administrator/vehicleManufacturerLookups/VehicleManufacturerLookupsDetailed";
import OrderStatusDetails from "admin/administrator/vehicleManufacturerLookups/OrderStatusDetails";
import TopAccounts from "admin/administrator/TopAccounts/TopAccounts";
import SalesTotalsByUser from "admin/administrator/TopAccounts/SalesTotalsByUser";
import SalesDetails from "admin/administrator/TopAccounts/SalesDetails";
import LookupTotals from "admin/administrator/lookup_totals/LookupTotals";
import LookupTotalsByUser from "admin/administrator/lookup_totals/LookupTotalsByUser";
import LookupDetails from "./administrator/lookup_totals/LookupDetails";
import LostSales from "admin/administrator/LostSales";
import IsAdmin from "admin/IsAdmin";
import IsAdminOrBranch from "admin/IsAdminOrBranch";
import LoginTotals from "admin/administrator/LoginTotals/LoginTotals";
import LoginTotalsByUser from "admin/administrator/LoginTotals/LoginTotalsByUser";
import LogintTotalsLookups from "admin/administrator/LoginTotals/LogintTotalsLookups";
import UserSession from "admin/administrator/LoginTotals/UserSession";
import BestSellerDetails from "./administrator/BestSellers/BestSellerDetails";
import SalesDetailsPrint from "./administrator/TopAccounts/SalesDetailsPrint";
import vrmServiceStatistics from "./administrator/vrmServiceStatistics/vrmServiceStatistics";
import PriceLevelMainContainer from "./administrator/PriceLevels/PriceLevelMainContainer";
import ClientDashboardIndex from "./administrator/ClientDashboard/ClientDashboardIndex";
import BranchDashboard from "admin/administrator/BranchDashboard/BranchDashboard";
import AccountDashboard from "admin/administrator/AccountDashboard/AccountDashboard";
import BespokePrices from "admin/administrator/BespokePrices/BespokePrices";
import ImageSlider from "admin/administrator/ImageSlider/ImageSlider";

export default class index extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="content">
                {/* <Hamburger></Hamburger> */}
                <AdminHeader/>
                <IsAdmin>
                    <Route path="/admin" exact component={Menu}/>
                    <Route path="/admin/manageHubs" component={Hubs}/>
                    <Route path="/admin/manageBrands" component={ManageBrands}/>
                    <Route path="/admin/statistics" component={Statistics}/>
                    <Route path="/admin/ordersDetails" component={OrdersDetails}/>
                    <Route path="/admin/abandonedCartsDetails" component={AbandonedCartsDetails}/>
                    <Route path="/admin/Lookups" component={Lookups}/>

                    <Route path="/admin/lost-sales/:detail" component={LostSales}/>

                    <Route path="/admin/lookup-totals/accounts" exact component={LookupTotals}/>
                    <Route path="/admin/lookup-totals/logins/:id" exact component={LookupTotalsByUser}/>
                    <Route path="/admin/lookup-totals/lookups/:id" exact component={LookupDetails}/>

                    <Route path="/admin/login-totals/accounts" exact component={LoginTotals}/>
                    <Route path="/admin/login-totals/logins/:id" exact component={LoginTotalsByUser}/>
                    <Route path="/admin/login-totals/lookups/:id" exact component={LogintTotalsLookups}/>
                    <Route path="/admin/login-totals/session/:id" exact component={UserSession}/>

                    <Route path="/admin/LookupCharts" component={LookupCharts}/>
                    <Route path="/admin/charts" component={Charts}/>
                    <Route path="/admin/salesDetails" component={salesDetails}/>
                    <Route path="/admin/settings" exact component={Settings}/>
                    <Route path="/admin/bespoke-prices" exact component={BespokePrices}/>
                    <Route path="/admin/image-slider" exact component={ImageSlider}/>
                    {/* <Route path="/admin/styles" exact component={Styles} /> */}
                    <Route path="/admin/brands" component={Brands}/>
                    <Route path="/admin/sections" component={Sections}/>
                    <Route path="/admin/communication" component={Communication}/>
                    <Route path="/admin/addUser/:id?" component={AddUser}/>
                    <Route path="/admin/userList/:branchId?" component={UserList}/>
                    {/* <Route path="/admin/branches" exact component={Branches}/> */}
                    {/* <Route path="/admin/branches/add" component={AddBranch}/>
                <Route path="/admin/branches/edit/:id" component={AddBranch}/> */}
                    <Route path="/admin/selectAccount" exact component={SelectAccount}/>
                    <Route path="/admin/addAccount" exact component={AddAccount}/>
                    <Route path="/admin/addSupplier" exact component={AddSupplier}/>
                    <Route path="/admin/editSupplier/:id" exact component={EditSupplier}/>
                    <Route path="/admin/addBranch" exact component={AddBranch}/>
                    <Route path="/admin/EditBranch/:id" exact component={EditBranch}/>
                    <Route path="/admin/account-statistics" exact component={AccountStatistics}/>
                    {/* <Route path="/admin/account-statistics/login-totals" exact component={LoginTotals} /> */}
                    {/* <Route path="/admin/account-statistics/login-totals/detail/:id"  component={LoginTotalsUsers} /> */}
                    <Route path="/admin/account-statistics/lookup-totals" exact component={LookupTotals}/>
                    <Route path="/admin/vehicle-manufactuer-lookups/:name/:month/:end_month" exact
                           component={VehicleManufacturerLookups}/>
                    <Route path="/admin/vehicle-manufactuer-lookups-details/:make/:model/:engine/" exact
                           component={vehicleManufacturerLookupsDetailed}/>
                    <Route path="/admin/order-status-details/:order_id" exact component={OrderStatusDetails}/>
                    <Route path="/admin/account-statistics/lookup-totals" exact component={LookupTotals}/>
                    <Route path="/admin/accounts/edit/:id" component={EditAccount}/>
                    <Route path="/admin/accounts" exact component={ViewAccounts}/>
                    <Route path="/admin/top-accounts/accounts" exact component={TopAccounts}/>
                    <Route path="/admin/top-accounts/logins/:id" exact component={SalesTotalsByUser}/>
                    <Route path="/admin/top-accounts/sale-details/:id" exact component={SalesDetailsPrint}/>
                    <Route path="/admin/top-accounts/sales/:id" exact component={SalesDetails}/>
                    <Route path="/admin/best-sellers/detail/:part_number" exact component={BestSellerDetails}/>
                    <Route path="/admin/vrmServiceStatistics" exact component={vrmServiceStatistics}/>
                    <Route path="/admin/downloads" exact component={Downloads}/>
                    <Route path="/admin/client/dashboard" exact component={ClientDashboardIndex}/>
                    {/* <Route path="/admin/branch/:id/:name/dashboard" exact component={BranchDashboard}/> */}
                 </IsAdmin>
                 <IsAdminOrBranch>
                    <Route path="/admin/account/:id/:name/dashboard" exact component={AccountDashboard}/>
                    <Route path="/admin/branch/:id/:name/dashboard" exact component={BranchDashboard}/>
                 </IsAdminOrBranch>
            </div>
        );
    }
}