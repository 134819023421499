import React, { Component } from "react";
import CustomPieChart from "components/Charts/CustomPieChart";
import AccountDetails from "admin/administrator/AccountDetails";
import ChangeThemeIcon from "components/ChangeThemeIcon";
import AlertAddresses from 'admin/administrator/AlertAddresses.js';
import Schedule from 'admin/administrator/Schedule.js';
import "./ComponentSamples.css";
// import "admin/administrator/AccountDetails.css";
import UserAddress from "admin/administrator/UserAddress.js";
import CustomTable from "admin/administrator/CustomTable.js";
import CustomButton from "admin/administrator/CustomButton.js";
import CustomProductPrices from "admin/administrator/CustomProductPrices.js";
import CustomPagination from "admin/administrator/CustomPagination";
import CustomDatePicker from 'admin/administrator/CustomDatePicker';
import CustomContainer from "custom_components/CustomContainer";

var moment = require('moment');
export default class VehicleLookups extends Component
{
    constructor(props)
    {
        super(props);
    }

    componentDidMount(){
        setTimeout(() => {
            let element = document.getElementById(this.props.match.params.componentName);

            if(element) element.scrollIntoView(true);

            document.querySelector(".header").style.display = "none";
        }, 1000); 
    }

    render(){
        return (
            <div className="sampleComponentsBody">
                <div id="CustomPieChart" className="sampleComponentWrapper">
                    <a href="https://github.com/tsogi/catalogue/wiki/React-Components-API" target="_blank">CustomPieChart</a>
                    <CustomPieChart data={[["Task", "Vehicles"],["BMW", 56],["Mercedes", 1000],["Audi", 43]]} width={800} />
                </div>
                <div id="AccountDetails" className="sampleComponentWrapper AccountDetails">
                    <a href="https://github.com/tsogi/catalogue/wiki/React-Components-API" target="_blank">AccountDetails</a>
                    <AccountDetails setAccount={(account) => { /* this.setState({ account: account }) */ console.log("Account updated", account); }}/>
                </div>
                <div id="ChangeThemeIcon" className="sampleComponentWrapper ChangeThemeIcon" style={{marginBottom:'50px'}}>
                    <a href="https://github.com/tsogi/catalogue/wiki/React-Components-API" target="_blank">ChangeThemeIcon</a>
                    <ChangeThemeIcon></ChangeThemeIcon>
                </div>

                <div id="AlertAddresses" className="sampleComponentWrapper AlertAddresses" style={{marginBottom:'50px'}}>
                    <a href="https://github.com/tsogi/catalogue/wiki/React-Components-API" target="_blank">AlertAddresses</a>
                    <AlertAddresses
                        settings={{
                            system_from_address: "",
                            stock_alerts_to: "",
                            price_alerts_to: "",
                        }}
                        updateAlertAddressesValues={(data) => { /*this.updateAlertAddressesValues*/ console.log("Account updated", data);}}
                    />
                </div>

                <div id="Schedule" className="sampleComponentWrapper Schedule" style={{marginBottom:'50px'}}>
                    <a href="https://github.com/tsogi/catalogue/wiki/React-Components-API" target="_blank">Schedule</a>
                    <Schedule
                        settings={{
                            send_emails_min: 0,
                            check_ftp_min: 0,
                        }}
                        updateScheduleValues= { (data) => {/* this.updateScheduleValues} */ console.log("Schedule updated", data);}} />
                </div>

                <div id="CustomButton" className="sampleComponentWrapper CustomButton" style={{marginBottom:'50px'}}>
                    <a href="https://github.com/tsogi/catalogue/wiki/React-Components-API" target="_blank">CustomButton</a>
                    <CustomButton title={"Title for title 👺"} type={"gray"} />
                </div>

                <div id="CustomProductPrices" className="sampleComponentWrapper CustomProductPrices" style={{marginBottom:'50px'}}>
                    <a href="https://github.com/tsogi/catalogue/wiki/React-Components-API" target="_blank">CustomProductPrices</a>
                    <CustomProductPrices data={[
                        {
                            quantity: 5,
                            product:
                                {
                                    price: 7,
                                    currency: {
                                        symbol: '$'
                                    },
                                }
                        },
                        {
                            quantity: 2,

                            product:
                                {
                                    price: 5,
                                    currency: {
                                        symbol: '$'
                                    },
                                }
                        },
                        {
                            quantity: 4,
                            product:
                                {
                                    price: 2,
                                    currency: {
                                        symbol: '$'
                                    },
                                }
                        }
                    ]}
                    />
                </div>

                <div id="UserAddress" className="sampleComponentWrapper UserAddress" style={{marginBottom:'50px'}}>
                    <a href="https://github.com/tsogi/catalogue/wiki/React-Components-API" target="_blank">UserAddress</a>
                    <UserAddress data={ {title:"title",info:{
                            title_1: "Something for information",
                            title_2: "Only smiles : 🤖🤖🤖👺👺🤖🤖🤖",
                            title_3: "More one for example",
                        }}} />
                </div>

                <div id="CustomTable" className="sampleComponentWrapper CustomTable" style={{marginBottom:'50px'}}>
                    <a href="https://github.com/tsogi/catalogue/wiki/React-Components-API" target="_blank">CustomTable</a>
                    <CustomTable data={[
                        {
                            product:
                                {
                                    price: 0,
                                    quantity: 0,
                                    part_number:
                                        {
                                            part_number: 1231231,
                                            section:
                                                {
                                                    name: "Your section name"
                                                }
                                        },
                                },
                            lookup: "your lookup title",
                        },
                        {
                            product:
                                {
                                    price: 0,
                                    quantity: 0,
                                    part_number:
                                        {
                                            part_number: 1231231,
                                            section:
                                                {
                                                    name: "Your section name2"
                                                }
                                        },
                                },
                            lookup: "your lookup title 2",
                        }
                    ]} />
                </div>

                <div id="CustomPagination" className="sampleComponentWrapper CustomPagination" style={{marginBottom:'50px'}}>
                    <a href="https://github.com/tsogi/catalogue/wiki/React-Components-API" target="_blank">CustomPagination</a>

                    <CustomPagination page={1} pages={20} sendRequest={() => {}}/>
                </div>

                <div id="CustomDatePicker" className="sampleComponentWrapper CustomDatePicker" style={{marginBottom:'50px'}}>
                    <a href="https://github.com/tsogi/catalogue/wiki/React-Components-API" target="_blank">Custom Date Picker</a>
                    <CustomDatePicker value={"2019-08-02"} selected={"2019-08-02"} placeholder={"Month"} onChange={(data)=>{console.log('response: ',data )}} format={"MMMM YYYY"}></CustomDatePicker>
                </div>

                <div id="CustomContainer" className="sampleComponentWrapper CustomContainer" style={{marginBottom:'50px'}}>
                    <a href="https://github.com/tsogi/catalogue/wiki/React-Components-API" target="_blank">Custom Container</a>
                    <CustomContainer name={"Basic"}>
                        <h3>Basic Container</h3>
                    </CustomContainer>
                    <hr/>
                    <CustomContainer name={"Dark Header"} type={"darkHeader"}>
                        <h3>Dark Header Container</h3>
                    </CustomContainer>
                    <hr/>
                    <CustomContainer name={"Header Left"} type={"headerLeft"}>
                        <h3>Header Left Container</h3>
                    </CustomContainer>
                </div>

            </div>
        );
    }
}