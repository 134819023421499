import React, { Fragment, useEffect, useState, useRef } from 'react';
import "./AdImage.css";
import axios from "axios1";
import Slider from 'react-slick';
var moment = require('moment');

function AdImage() {
    const [slider, setSlider] = useState([]);
    const [duration, setDuration] = useState(10);
    const [smallImages, setSmallImages] = useState([]);

    const sliderRef = useRef();

    const settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        draggable: false,
        autoplay: true,
        autoplaySpeed: duration * 1000
    };

    useEffect(() => {
        getSmallImages();
        getSlider();
    }, []);

    function getSlider() {
        axios.get("catalogue/slider")
            .then((response) => {
                let duration = response.data.settings.duration;
                let updatedDate = response.data.settings.updated_at;
                let imagesQuantity = response.data.images.length;
                let currentDate = moment(new Date().toUTCString()).subtract(1, 'hours');
                let diff = currentDate.diff(updatedDate, "seconds");
                let iteration = Math.floor(diff / duration);
                let currentImage = iteration < imagesQuantity ? iteration : iteration % imagesQuantity;

                setSlider(response.data.images);
                setDuration(duration);
                if(sliderRef.current)
                    sliderRef.current.slickGoTo(currentImage);
            });
    }

    function getSmallImages() {
        axios.get("catalogue/ad-images")
            .then((response) => {
                setSmallImages(response.data.small_images);
            });
    }

    return (
        <div className="slider">
            <div className="slider__primary">
                {
                    slider.length > 0 &&
                        <Slider {...settings} ref={sliderRef}>
                            {
                                slider.map( slide => {
                                    let backgroundImage = slide.image;
                                    return <div>
                                        <div className="slider__primary-item" style={{backgroundImage: `url(${backgroundImage})`}}></div>
                                    </div>
                                })
                            }
                        </Slider>
                }
            </div>
            <div className="slider__secondary">
                {
                    smallImages && Object.keys(smallImages).length > 0 ?
                        <Fragment>
                            {
                                smallImages['left'] && smallImages['left'].address ?
                                    <a href={smallImages['left'].address} 
                                        className="slider__item" 
                                        style={{backgroundImage: "url('"+(smallImages['left'] ? smallImages['left'].image : null)+"')"}}
                                        target="_blank"></a> :
                                    <a  className="slider__item" 
                                        style={{backgroundImage: "url('"+(smallImages['left'] ? smallImages['left'].image : null)+"')"}}
                                        target="_blank"></a>
                            }
                            {
                                smallImages['center'] && smallImages['center'].address ?
                                    <a href={smallImages['center'].address} 
                                        className="slider__item" 
                                        style={{backgroundImage: "url('"+(smallImages['center'] ? smallImages['center'].image : null)+"')"}}
                                        target="_blank"></a> :
                                    <a  className="slider__item" 
                                        style={{backgroundImage: "url('"+(smallImages['center'] ? smallImages['center'].image : null)+"')"}}
                                        target="_blank"></a>
                            }
                            {
                                smallImages['right'] && smallImages['right'].address ?
                                    <a href={smallImages['right'].address} 
                                        className="slider__item" 
                                        style={{backgroundImage: "url('"+(smallImages['right'] ? smallImages['right'].image : null)+"')"}}
                                        target="_blank"></a> :
                                    <a  className="slider__item" 
                                        style={{backgroundImage: "url('"+(smallImages['right'] ? smallImages['right'].image : null)+"')"}}
                                        target="_blank"></a>
                            }
                        </Fragment> : null
                }
            </div>
        </div>
    )
}

export default AdImage