import React, { useState, useEffect }  from 'react';
// import {Container, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup} from "react-bootstrap";
import CustomButton from "admin/administrator/CustomButton";
import { generatePassword } from 'utils/helper';
// import "admin/administrator/CustomAddLoginModal.css";
import _ from "lodash";
import FormInput from "admin/administrator/FormInput/FormInput.component";
import { Fragment } from 'react';
import Modal from "components/Modal";
import { useTranslation } from 'react-i18next';

const CustomAddLoginModal = (props) => {
    const { t, i18n } = useTranslation();
    const [errors, setErrors] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [showPassword, setShowPassword] = useState('password');
    const [icon, setIcon] = useState('fas fa-eye-slash');

    const generateNewPassword = () => {
        const pwd = generatePassword({uppercase: true, lowercase: true, symbols: true, numbers: true}, 6);
        props.handlePasswordChange({ password: pwd, password_confirmation: pwd });
    }

    const handleShowHideClick = () => {
        let status = 'text'
        let icon = 'fas fa-eye';
        
        if(showPassword == 'text') {
            status = 'password';
            icon = 'fas fa-eye-slash';
        }

        setIcon(icon);
        setShowPassword(status)
    }

    useEffect(() => {
        setErrors(props.errors)
        const hasError = _.find(props.errors, error => error != '');
        
        if(hasError) {
            setDisabled(true);
        }else {
            setDisabled(false);
        }
    }, [props.errors])

    return (
        <Fragment>
            <Modal size="sm" title={props.title} type="light" active={props.show} closeModal={() => props.onHide()}>
                <div>
                    <div className={"mb-16"}>
                        <div className={"mb-4 fw-500 fs-12"}>{t("Name")} *</div>
                        <FormInput
                            name='name'
                            type='text'
                            placeholder=''
                            error={errors.nameError}
                            value={props.form.name}
                            onChange={e => props.handleFormChange("name", e.target.value)}
                            handleBlur={e => props.handleFromBlur("name", e.target.value)}
                            required
                        />
                    </div>
                    <div className={"mb-16"}>
                        <div className={"mb-4 fw-500 fs-12"}>{t("Email")} *</div>
                        <FormInput
                            name='email'
                            type='email'
                            placeholder=''
                            error={errors.emailError ? errors.emailError : errors.repeat_emailError}
                            value={props.form.email}
                            onChange={e => props.handleFormChange("email", e.target.value.trim())}
                            handleBlur={e => props.handleFromBlur("email", e.target.value.trim())}
                            required
                        />
                    </div>
                    <div className={"mb-16"}>
                        <div class="flex mb-4 fw-500 fs-12">
                            <span>{t("Password")} * <span>({t("Min. 6 characters")})</span></span>
                            <span className={"auto-generate"} onClick={generateNewPassword.bind(this)}>{t("Auto-Generate Password")}</span>
                        </div>
                        <div className="login-input-wrapper" >
                            <FormInput
                                name='password'
                                type={showPassword}
                                placeholder=''
                                error={errors.passwordError}
                                value={props.form.password}
                                onChange={e => props.handleFormChange("password", e.target.value)}
                                handleBlur={e => props.handleFromBlur("password", e.target.value)}
                                required
                                showTypeChanger={true}
                                icon={icon}
                                handleShowHideClick={e => handleShowHideClick()}
                            />
                        </div>
                    </div>
                    <div>
                        <div className={"mb-4 fw-500 fs-12"}>{t("Re-type Password")} *</div>
                        <FormInput
                            name='password_confirmation'
                            type={showPassword}
                            placeholder=''
                            error={errors.password_confirmationError}
                            value={props.form.password_confirmation}
                            onChange={e => props.handleFormChange("password_confirmation", e.target.value)}
                            handleBlur={e => props.handleFromBlur("password_confirmation", e.target.value)}
                            required
                        />
                    </div>
                    <div className={"modal__actions"}>
                        <CustomButton
                            title={t("Cancel")}
                            type={"lightgray"}
                            onClick={props.onHide.bind(this)}
                        />
                        <CustomButton
                            title={props.buttonTitle}
                            type={"blue"}
                            margins={["ml-8"]}
                            disabled={disabled}
                            onClick={props.handleSaveLogin.bind(this)}
                        />
                    </div>
                </div> 
            </Modal>
        </Fragment>
    );
}
export default CustomAddLoginModal;