import React, { Fragment } from "react";
// import "admin/administrator/CustomCheckBox.css";

const CustomCheckBox = ({ checked, onChange, name }) => {
    return  <div className={"checkbox"}>
                <input 
                    type="checkbox"
                    checked={checked}
                    onChange={onChange || null}
                    key={"element" + name}
                    id={"element" + name}
                    className="checkbox__btn"
                />
                <label className="checkbox__label" htmlFor={"element" + name}></label>
            </div>;
}
  
export default CustomCheckBox;
