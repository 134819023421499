import React, { useState, Fragment } from "react";
// import "admin/administrator/CustomDelete.css";

const icon = "far fa-trash-alt";

const CustomDelete = (data) => {
    
    const [hover, setHover] = useState(false);

    var iconStyles = {
          animationDuration: `${(data.duration || 0.1)}s`,
          animationIterationCount: 1,
          animationName: `react-fade-${(hover ? 'in' : 'out')}`,
          animationTimingFunction: hover ? 'ease-in' : 'ease-out',
          display: 'inline-block',
          cursor: 'pointer'
    };

    iconStyles.fontSize = data.fontSize || "16px";
    iconStyles.color = hover ? ( data.hoverColor || "red" ) : ( data.color || "#5a5b5c" );    
    
    const inEffect = `
        @keyframes react-fade-in {
        0%   { color: ${( data.color || "#5a5b5c" )}; }
        100% { color: ${( data.hoverColor || "red" )}; }
        }
    `;

    const outEffect = `
        @keyframes react-fade-out {
        0%   { color: ${( data.hoverColor || "red" )}; }
        100% { color: ${( data.color || "#5a5b5c" )}; }
        }
    `;

    return  <Fragment>
                <style children={hover ? inEffect : outEffect} />
                <div 
                    key={"element-" + data.key}
                    style={iconStyles}
                    className={"custom-delete-container"}
                    
                    onMouseEnter={()=>{
                        setHover(true);
                    }}
                    
                    onMouseLeave={()=>{
                        setHover(false);
                    }}

                    onClick={data.onClick}
                >
                    <i className={ data.icon || icon }></i>
                </div>
            </Fragment>;
}
  
export default CustomDelete;