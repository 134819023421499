import React, { useState, useEffect } from 'react';
// import "./SignIn.css";
import FormInput from "admin/administrator/FormInput/FormInput.component";
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from "react-redux";
import { getUser, setCart, setCompare } from "redux/actions/authActions";
import { reloadSettings } from "redux/actions/settings";
import { setOrdersQty, setCustomerOrdersQty } from "redux/actions/orders";
import ForgotPassword from '../ForgotPassword/ForgotPassword';

import { switchLang } from "redux/actions/authActions";
import { useTranslation } from 'react-i18next';

var store = require('store');

function SignIn(props) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState([]);
    const [warning, setWarning] = useState(false);
    const [showPassword, setShowPassword] = useState('password');
    const [icon, setIcon] = useState('fas fa-eye-slash');
    const [forgotPassword, setForgotPassword] = useState(false);
    const [token, setToken] = useState(null);
    const languages = ["en", "de", "da", "fr", "ru"];
    let currentLanguage = localStorage.getItem("lang") ? localStorage.getItem("lang").toUpperCase() : "EN";
    const { t, i18n } = useTranslation();

    useEffect(() => {
        let params = new URLSearchParams(window.location.search);
        let token = params.get('change_password_token');
        if(token) {
            setToken(token);
        } else if(props.auth && props.auth.token) {
            window.localStorage.removeItem("token");
            window.localStorage.removeItem("user");
            let warning = { title: 'You have been logged out !', body: 'Your credentials are being used on another device.' };
            setWarning(warning);
        }
    }, []);

    function handleInputChange(name, event) {
        if(name == "email") {
            setEmail(event.target.value);
        }
        if(name == "password") {
            setPassword(event.target.value);
        }
    }

    function handleSubmit(e) {
        e.preventDefault();
        let userEmail = email;
        let userPassword = password;
        props.getUser(userEmail, userPassword).then((response) => {
            if(!response.token) {
                setErrors(response.errors);
                return false;
            }
            props.reloadSettings();
            store.set("token", response.token);
            store.set("user", response.user);
            props.history.push('/search');
            props.setCart();
            props.setCompare();
            props.setOrdersQty();
            props.setCustomerOrdersQty();
        });
    }
    function changeLanguage(lang){
        localStorage.setItem('lang', lang);
        currentLanguage = lang.toUpperCase();
        i18n.changeLanguage(lang);
        props.switchLang(lang);
    }

    const handleShowHideClick = () => {
        let status = 'text'
        let icon = 'fas fa-eye';
        
        if(showPassword == 'text') {
            status = 'password';
            icon = 'fas fa-eye-slash';
        }

        setIcon(icon);
        setShowPassword(status)
    }

    function goToLoginPage() {
        setForgotPassword(false);
        setToken(null);
        props.history.push('/Login');
    }

    return (
        <div className="signin">
            <img src={props.logo.logo} width={props.logo.size}/>
            {
                !forgotPassword && !token ? 
                <form onSubmit={handleSubmit.bind(this)}>
                    <h1 className="text-center mb-40 fw-400">{t('Welcome Back')}</h1>
                    <div className="form-group mb-16">
                        <FormInput
                            name='email'
                            type='text'
                            placeholder={t('Email')}
                            error={errors.email}
                            value={email}
                            onChange={handleInputChange.bind(this, "email")}
                            className="bordered"
                        />
                    </div>
                    <div className="form-group">
                        <FormInput
                            name='password'
                            type={showPassword}
                            placeholder={t("Password")}
                            error={errors.password}
                            value={password}
                            onChange={handleInputChange.bind(this, "password")}
                            className="bordered"
                            showTypeChanger={true}
                            icon={icon}
                            handleShowHideClick={e => handleShowHideClick()}
                        />
                    </div>
                    <div className="mt-16 mb-16 text-primary cursor-pointer hover-underline" onClick={() => setForgotPassword(true)}>{t("Forgotten Password")}?</div>
                    {
                        errors['error'] ? 
                            <span className="invalid-error mb-16"><i class="fas fa-exclamation-circle"></i> {errors['error']}</span> : null
                    }
                    {
                        warning ? <div className="invalid-error mb-16"><i class="fas fa-exclamation-circle"></i> <div className={"invalid-error-warning-right"}> <span>{ warning.title }</span> <span>{warning.body}</span> </div></div> : null
                    }
                    <button type="submit" style={{background: (props.colors[props.client] || props.colors['default'])}}>{t('Login')}</button>
                    {
                        props.settings.registration_from_site ? 
                            <div className="flex flex-justify-center mt-16">{t("Don't have an account")}? <span className="ml-4 text-primary cursor-pointer fw-700 hover-underline" onClick={() => props.showSignupForm()}>{t("Sign Up")}</span></div> : null
                    }
                </form> : <ForgotPassword colors={props.colors} client={props.client} goToLoginPage={goToLoginPage.bind(this)} token={token}/>
            }
            <div className="sign-in-lang">
                {languages.map((lang, i) => {
                    if(lang.toUpperCase() != currentLanguage){
                        return (<span onClick={() => changeLanguage(lang)}>{lang === 'da'? 'DK' : lang.toUpperCase()}</span>)
                    }else{
                        return (<span className="active-lang" onClick={() => changeLanguage(lang)}>{lang === 'da'? 'DK' : lang.toUpperCase()}</span>)
                    }
                })}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    settings: state.settings,
    auth: state.auth
})

const mapDispatchToProps = {
    getUser: getUser,
    setCart: setCart,
    setCompare: setCompare,
    reloadSettings: reloadSettings,
    setOrdersQty: setOrdersQty,
    setCustomerOrdersQty: setCustomerOrdersQty,
    switchLang: switchLang
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SignIn));