import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from "lodash";
import 'components/ShoppingCartIcon.css';
import Icon from 'components/Icon';
var store = require('store');

class ShoppingCartIcon extends Component {
    constructor(props)
    {
        super(props);
    }

    render() {

        let products = this.props.products;

        return (
            <span className={"nav__icon"}>
                <Icon file="Basket.svg" type="dark" width="24px" height="24px"/>
                {
                    this.props.token ?
                        <span className="badge badge--danger">{this.calculateQuantity(products)}</span>
                        : null
                }
            </span>
        ); 
    }

    calculateQuantity(products)
    {
        let quantity = 0;

        _.forEach(products, (element) => {
            quantity += parseInt(element.quantity);
        });

        return quantity > 99 ? "99+" : quantity;
    }
}

const mapStateToProps = state => ({
    products: state.shoppingCart,
    token: state.auth.token
})

export default connect(
    mapStateToProps,
    null
)(ShoppingCartIcon);