import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
var store = require('store');

export const ProtectedRoute = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (store.get('token')) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: '/Login',
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
      }}
    />
  );
};
