import React, {useState, useEffect} from "react";
import "react-datepicker/dist/react-datepicker.css";
import {withRouter} from 'react-router-dom';
import axios from "axios1";

const LostSales = props => {
    const [lookup, setLookup] = useState([]);
    const [productsLookup, setProductsLookup] = useState([]);
    const [sectionLookup, setSectionLookup] = useState([]);
    const [productDetails, setProductDetails] = useState({
        show: false
    });
    
    useEffect(() => {
        var id = props.match.params.detail;
        getSectionLookups(id);
    }, []);

    const getSectionLookups = id => {
        axios.get("catalogue/section-lookups/"+ id )
            .then((response) => {
                let data = response.data;
                setLookup(data.lookup[0]);
                setProductsLookup(data.productsLookup);
                setSectionLookup(data.sectionLookup[0]);
            })
    }

    const handleShowProductDetails = id => axios.get("catalogue/product-lookups/" + id).then( response => setProductDetails({...productDetails, show: true, product: response.data.product[0], stock: response.data.stock}));

    return (
        <div className={"page-content admin-wrapper"}>
            <div className={"container-fluid"}>
                    <div className={"login-totals-page-wrapper"}>
                    <div className={"row"}>
                        <div className={"col-lg-12"}>
                            <div class="page-title-wrapper">
                                <span>Lost Sales</span>
                                <button class="go-back" onClick={() => props.history.goBack()}><i class="fas fa-reply"></i> Back</button>
                            </div>
                        </div>
                        <div className={"col-lg-12"}>
                            <div className="row">
                                <div className={"col-lg-3"}>
                                    <div className={"general-info-item"}>
                                        <span>{lookup.lookup_type} : </span>
                                        <span>{lookup.details}</span>
                                    </div>
                                    <div className={"general-info-item"}>
                                        <span>DATE: </span>
                                        <span>{sectionLookup.date}</span>
                                    </div>
                                    <div className={"general-info-item"}>
                                        <span>TIME: </span>
                                        <span>{sectionLookup.time}</span>
                                    </div>
                                </div>
                                <div className={"col-lg-3"}>
                                    <div className={"general-info-item"}>
                                        <span>product group: </span>
                                        <span>{sectionLookup.group}</span>
                                    </div>
                                    <div className={"general-info-item"}>
                                        <span>product section: </span>
                                        <span>{sectionLookup.section}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"col-lg-12"}>
                            <span className={"lost-sales-qty"}>The following {productsLookup.length} items were offered but none were purchased.</span>
                        </div> 
                    </div>
                    <div className={"row lost-sales-body"}> 
                        <div className={"col-lg-6"}>
                            {
                                 productsLookup.length > 0 &&
                                <table className={"custom-table table table-bordered"}>
                                    <thead>
                                        <tr>
                                            <th>Part No</th>
                                            <th>Brand</th>
                                            <th>Stock</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        productsLookup.map((product) => {
                                            return <tr>
                                                    <td>{product.part_number}</td>
                                                    <td>{product.brand}</td>
                                                    <td>
                                                        <span>{product.quantity}</span>
                                                        <button onClick={handleShowProductDetails.bind(this, product.product_id)} className={product.quantity.length > 0 ? "custom-btn ml-2 btn-green" : "custom-btn ml-2 btn-red"}>
                                                        {product.quantity.length > 0 ?  <i className="fas fa-info-circle"></i> : <i className={"fas fa-exclamation-triangle"}></i>}
                                                        </button>
                                                    </td>
                                                </tr>
                                        })
                                    }
                                    </tbody>
                                </table> 
                            } 
                        </div> 
                        {
                            productDetails.show &&
                            <div className={"col-lg-6"}>
                                <div className={"product-details"}>
                                    <div className={"details-header"}>
                                        <span>{productDetails.product.part_number}</span>
                                    </div>
                                    <div className={"details-body"}>
                                    <div className={"row"}>
                                        <div className={"col-lg-5"}>
                                                <div className={"product-stats"}>
                                                    <div className={"stat-item"}>
                                                        <div className={"stat-name-value"}>
                                                            <span className={"stat-name"}>section</span>
                                                            <span className={"stat-value"}>{productDetails.product.section}</span>
                                                        </div>
                                                        <div className={"stat-image"}>
                                                            <img width={"50px"} src={"sdasd"} />
                                                        </div>
                                                    </div>
                                                    <div className={"stat-item"}>
                                                        <div className={"stat-name-value"}>
                                                            <span className={"stat-name"}>last 12 months sales</span>
                                                            <span className={"stat-value"}>
                                                                {productDetails.product.quantity}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className={"stat-item"}>
                                                        <div className={"stat-name-value"}>
                                                            <span className={"stat-name"}>current branch</span>
                                                            <span className={"stat-value"}>stock</span>
                                                        </div>
                                                        <div className={"stat-image"}>
                                                            <img width={"50px"} src={"dasdas"} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"product-stocks"}>
                                                    {
                                                        productDetails.stock.length > 0 &&
                                                            <table className={"custom-table table table-bordered"}>
                                                                <thead>
                                                                    <tr>
                                                                        <th>branch</th>
                                                                        <th>qty</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                <tr>
                                                                    <td>branch</td>
                                                                    <td>quantity</td>
                                                                </tr>
                                                                {
                                                                    productDetails.stock.map((branch) => {
                                                                        return <tr>
                                                                                    <td>{branch.account}</td>
                                                                                    <td>{branch.quantity > 0 ? branch.quantity : branch.quantity + ' <i className={"fas fa-exclamation-triangle"}></i>'}</td>
                                                                                </tr>
                                                                    })
                                                                }
                                                                </tbody>
                                                            </table>
                                                    }
                                                </div>
                                            </div>
                                            <div className={"col-lg-7"}>
                                                <div className={"product-img"}>
                                                    <img src={productDetails.product.url} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(LostSales);