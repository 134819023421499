import React, { Component } from "react";
import axios from "axios1";
import LookupsPlate from "admin/administrator/LookupsPlate";
import LookupsVehicle from "admin/administrator/LookupsVehicle";
import LookupsPartNumber from "admin/administrator/LookupsPartNumber";
// import "admin/administrator/Lookups.css";

export default class Lookups extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            lookupsHistory: {
                plates: {
                    lookups: [], 
                    lookupsCount: 0
                },
                vehicles: {
                    lookups: [],
                    lookupsCount: 0
                },
                numbers: {
                    lookups: [],
                    lookupsCount: 0
                },
                showSelect:false,
            },
            users: [],
        }
    }

    componentDidMount() {
        this.getLookupsHistory();
    }

    getLookupsHistory() {
        axios.get("/catalogue/lookups/history")
            .then((response) => {
                const lookupsHistory = response.data.data;
                // const users = this.getUsersFromHistory(lookupsHistory, 'plates');
                this.setState({lookupsHistory:lookupsHistory});
            })
    }

    getUsersFromHistory(history, type) {
        let result = Object.values(history[type].lookups.reduce((r, o) =>  {
            r[o.users[0].id] = r[o.users[0].pivot.user_id] || {...o};
            return r;
            },{}));

        return result;
    }

    render() {

        const lookupsHistory = this.state.lookupsHistory;

        return (
            <div className={"dashboard-page-wrapper"}>
                <div className={"dashboard-page-navigations"}>
                    <div>
                        <i className="fas fa-chart-line"></i>
                        <span> User Statistics</span>
                    </div>
                    <div>
                        <button className="back-btn" onClick={() => this.props.history.goBack()}>
                            Go Back
                        </button>
                    </div>
                </div>
                <div className={"dashboard-page-body"}>
                    <div className="row"> 
                    <div className="nav flex-column nav-pills lookups-tabs" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <a 
                            className="active" 
                            id="v-pills-home-tab" 
                            data-toggle="pill" 
                            href="#v-pills-home" 
                            role="tab" 
                            aria-controls="v-pills-home" 
                            aria-selected="true">
                            Plate Lookups
                            <span className="lookups-qty">{lookupsHistory.plates.lookupsCount}</span>
                        </a>
                        <a 
                            id="v-pills-profile-tab" 
                            data-toggle="pill" 
                            href="#v-pills-profile" 
                            role="tab" 
                            aria-controls="v-pills-profile" 
                            aria-selected="false">
                            Vehicle Lookups
                            <span className="lookups-qty">{lookupsHistory.vehicles.lookupsCount}</span>
                        </a>
                        <a 
                            id="v-pills-messages-tab" 
                            data-toggle="pill" 
                            href="#v-pills-messages" 
                            role="tab" 
                            aria-controls="v-pills-messages" 
                            aria-selected="false">
                            PartNumber Lookups
                            <span className="lookups-qty">{lookupsHistory.numbers.lookupsCount}</span>
                        </a>
                    </div>
                    <div className="tab-content lookups-tabs-content" id="v-pills-tabContent">
                        <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                            <LookupsPlate lookupsHistory={lookupsHistory.plates} showSelect={lookupsHistory.showSelect} />
                        </div>
                        <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                            <LookupsVehicle lookupsHistory={lookupsHistory.vehicles} showSelect={lookupsHistory.showSelect} />
                        </div>
                        <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                            <LookupsPartNumber lookupsHistory={lookupsHistory.numbers} showSelect={lookupsHistory.showSelect} />
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}