import React, { Component } from "react";
// import "admin/administrator/ActivityTrends.css";
import * as d3 from "d3";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class ActivityTrends extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            data: [
                // name, lookedup, sold
                    ["00:00", 100, 60],
                    ["01:00", 150, 70],
                    ["02:00", 120, 80],
                    ["03:00", 150, 60],
                    ["04:00", 60, 60],
                    ["05:00", 100, 60],
                    ["06:00", 16, 10],
                    ["07:00", 100, 60],
                    ["08:00", 89, 60],
                    ["09:00", 100, 90],
                    ["10:00", 14, 60],
                    ["11:00", 100, 60],
                    ["12:00", 55, 30],
                    ["13:00", 130, 60],
                    ["14:00", 100, 60],
                    ["15:00", 45, 80],
                    ["16:00", 100, 60],
                    ["17:00", 66, 60],
                    ["18:00", 100, 60],
                    ["19:00", 88, 20],
                    ["20:00", 100, 60],
                    ["21:00", 99, 60],
                    ["22:00", 80, 80],
                    ["23:00", 80, 60],
                ],
            accounts: [
                { id: 1, name: "Cardiff" },
                { id: 2, name: "Swansea" },
                { id: 3, name: "Liverpool" },
            ],
            form: {
                accountId: 2,
                date: new Date()
            },
            d3: {
                xScale: null,
                yScale: null
            }
        }

        setTimeout(() => {
            let data = [ ...this.state.data ];
            data[1] = ["01:00", 100, 50];
            this.setState({data:data}, () => { this.updateChart(); })
        }, 5000);
    }

    handleInputChange(formName, event){
        let form = { ...this.state.form };
        form[formName] = event.target.value;
        this.setState({ form: form });
    }

    handleDateChange(date) {
        let form = { ...this.state.form };
        form.date = date;
        this.setState({
            form: form
        });
    }

    componentDidMount() {
        this.initializeChart();
    }

    componentDidUpdate() {
        // this.updateChart();
    }

    render() {
        return <div className="ActivityTrendsComponent" style={{width: this.props.width + "px"}}>
                <div className="AcitivityTrendsHeader">
                    <div className="AcitivityTrendsHeader-label">Activity Trends</div>
                    <div className="AcitivityTrendsHeader-menu">
                        <div className="AcitivityTrendsHeader-menu-legend">
                            <div className="averageProductSold">
                                <div className="averageProductSold-squire"></div>
                                <div className="averageProductSold-label">Average product sold</div>
                            </div>
                            <div className="averageLookups">
                                <div className="averageLookups-squire"></div>
                                <div className="averageLookups-label">Average Lookups</div>
                            </div>
                        </div>
                        <div className="AcitivityTrendsHeader-menu-filters">
                            <div className="filter-select-wrapper">
                                <div>Account</div>
                                <select className="bestsellers-select" value={this.state.form.accountId} onChange={this.handleInputChange.bind(this, "accountId")}>
                                {
                                    this.state.accounts.map((account) => {
                                        return <option key={account.id} value={account.id}>{account.name}</option>
                                    })
                                }
                                </select>
                            </div>
                            <div className="filter-select-wrapper">
                                <div>Date</div>
                                <DatePicker className="bestsellers-select" todayButton={"Today"} selected={this.state.form.date} onChange={this.handleDateChange.bind(this)} />
                            </div>
                        </div>
                    </div>
                </div>
                <svg className="ActivityTrendsSvg" width={this.props.width} height={this.props.height} ref={el => this.svgEl = el}></svg>
            </div>
    }

    initializeChart() {
        var margin = {top: 20, right: 20, bottom: 30, left: 30},
            width = this.props.width - margin.right - margin.left,
            height = this.props.height - margin.top - margin.bottom;

        var xScale = d3.scaleBand()
            .range([0, width])
            .paddingInner(0.4);

        var yScale = d3.scaleLinear()
            .rangeRound([height, 0]);

        var svg = d3.select(this.svgEl);

        var g = svg.append("g")
            .attr("class", "chart")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        // add the X gridlines
        g.append("g")
            .attr("class", "xGrid")
            .attr("transform", "translate(0," + height + ")");

        // add the Y gridlines
        g.append("g")
            .attr("class", "yGrid");

        g.append("g")
            .attr("class", "axis axis--x")
            .attr("transform", "translate(0," + height + ")");

        g.append("g")
            .attr("class", "axis axis--y");

        let D3 = { ...this.state.d3 };
        D3.xScale = xScale;
        D3.yScale = yScale;

        this.setState({
            d3: D3
        }, () => { this.updateChart(); });
    }

    updateChart() {
        // These variables should be removed from here
        var margin = {top: 20, right: 20, bottom: 30, left: 30},
            width = this.props.width - margin.right - margin.left,
            height = this.props.height - margin.top - margin.bottom;

        // We need global xScale here
            this.state.d3.xScale.domain(this.state.data.map(function(d) {
                return d[0];
            }));

        // Wee need global yScale here
            this.state.d3.yScale.domain([0, d3.max(this.state.data, (function (d) {
                return d[1];
            }))]);

        // We need global xGrid here
            d3.select(".xGrid").call(d3.axisBottom(this.state.d3.xScale)
                .ticks(5)
                .tickSize(-height)
                .tickFormat("")
            )

        // We need global yGrid here
            d3.select(".yGrid").call(d3.axisLeft(this.state.d3.yScale)
                .ticks(5)
                .tickSize(-width)
                .tickFormat("")
            );

        // We need global x axis here
            d3.select(".axis--x").call(d3.axisBottom(this.state.d3.xScale));

        // We need global y axis here
            d3.select(".axis--y").call(d3.axisLeft(this.state.d3.yScale));

        const rx = 12;
        const ry = 12;

        var line = d3.line()
            .x((d, i) => { return this.state.d3.xScale(d[0]) + this.state.d3.xScale.bandwidth() / 2; })
            .y(d => { return this.state.d3.yScale(d[2]); })
            .curve(d3.curveMonotoneX);

        var updateBar = d3.select(".chart").selectAll(".bar")
            .data(this.state.data);

        updateBar.transition().attr("d", d => `
                M${this.state.d3.xScale(d[0])},${this.state.d3.yScale(d[1]) + ry}
                a${rx},${ry} 0 0 1 ${rx},${-ry}
                h${this.state.d3.xScale.bandwidth() - 2 * rx}
                a${rx},${ry} 0 0 1 ${rx},${ry}
                v${height - this.state.d3.yScale(d[1]) - ry}
                h${-(this.state.d3.xScale.bandwidth())}Z
              `);

        var enterBar = updateBar.enter();

        enterBar.append("path")
            .attr("class", "bar")
            .attr("d", d => `
                M${this.state.d3.xScale(d[0])},${this.state.d3.yScale(d[1]) + ry}
                a${rx},${ry} 0 0 1 ${rx},${-ry}
                h${this.state.d3.xScale.bandwidth() - 2 * rx}
                a${rx},${ry} 0 0 1 ${rx},${ry}
                v${height - this.state.d3.yScale(d[1]) - ry}
                h${-(this.state.d3.xScale.bandwidth())}Z
              `)

        var updateLine = d3.select(".chart").selectAll(".line")
            .data(this.state.data);


        updateLine.transition().attr("d", line(this.state.data)); // 11. Calls the line generator

        var enterLine = updateLine.enter();

        enterLine.append("path")
            .attr("class", "line") // Assign a class for styling
            .attr("d", line(this.state.data)); // 11. Calls the line generator
    }
}