import React, { Component } from "react";
import axios from "axios1";
import ReactTable from "react-table";
import DatePicker from "react-datepicker";
// import "admin/administrator/VehicleLookups.css";
import "react-datepicker/dist/react-datepicker.css";
// import "admin/administrator/CustomElements.css";
import CustomPieChart from 'components/Charts/CustomPieChart';
// import "admin/administrator/vehicleManufacturerLookups/OrderStatusDetails.css";
import OrderStatus from "admin/administrator/OrderStatus";
import {NavLink} from "react-router-dom";
var moment = require('moment');

export default class OrderStatusDetails extends Component
{
    constructor(props)
    {
        super(props);
        this.state={
            orderDetails : {
                date: "",
                status: "",
                order_id: "",
                shipping: "",
                vat: "",
                address_1: "",
                address_2: "",
                town: "",
                postal_code: ""
               },
               products:[]
        }
    }

    
    componentDidMount(){
        this.getItems();
    }

    getItems(){
        axios.get("catalogue/orders/"+this.props.match.params.order_id)
        .then((response) => {
            this.setState({orderDetails:response.data.order_meta[0], products:response.data.order_details})
        });
    }



    render() {
        return (
            
            <div className={"vehicleManufacturerWrapper lookups-wrapper vehicles admin-wrapper"} style={{color:"black"}}>
                <OrderStatus products={this.state.products} orderDetails={this.state.orderDetails}></OrderStatus>
            </div>
        )
    }
}
