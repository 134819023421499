import React, { Component } from "react";
// import "admin/administrator/GeneralClientOrBranchStatistics/index.css";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import i18n, { t } from 'i18next';

export default withTranslation()(class Orders extends Component
    {
        constructor(props)
        {
            super(props);
         }
    
        render()
        {
            return (
                <div className="flex mt-16">
                    <div className="flex">
                        <div>{t("Orders")}</div>
                    </div>
                    <div className="fw-700">{this.props.orders}</div> 
                </div>
            );
        }
    
    })
