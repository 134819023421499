import React, {Fragment, useEffect, useState, useRef} from "react";
import CustomButton from "admin/administrator/CustomButton";
import FormSelect from "admin/administrator/FormSelect/FormSelect.component";
import ReturnFormPDF from "account/Orders/ReturnForm/Forms/ReturnFormPDF";
import ReturnFormCorePdf from "account/Orders/ReturnForm/Forms/ReturnFormCorePdf";
import ReturnFormWarrantyPdf from "account/Orders/ReturnForm/Forms/ReturnFormWarrantyPdf";
import Loading from "admin/administrator/LoadingComponent/Loading.component";
import Block from "components/Block";
import {connect, useDispatch} from "react-redux";
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';

const ReturnForm = props => {
    const { t, i18n } = useTranslation();    
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const [loading, setLoading] = useState(true);
    const [selectedType, setSelectedType] = useState(null);
    const formTypes = [
        { id: 1, name: t('form_type_return_form'), component: <ReturnFormPDF ref={componentRef} account={props.user?.user?.account} /> } ,
        { id: 2, name: t('form_type_return_form_core'), component: <ReturnFormCorePdf ref={componentRef} account={props?.user.user?.account}/> },
        { id: 3, name: t('form_type_return_form_warranty'), component: <ReturnFormWarrantyPdf ref={componentRef} account={props?.user.user?.account}/> }
    ];

    useEffect(() => {
        setLoading(true);
        setSelectedType(formTypes[0]);
        return () => {
            setSelectedType(null)
        }
    }, [])

    useEffect(() => {
        setLoading(false);
    }, [selectedType])


    const handleChange = e => setSelectedType(formTypes.find(item => item.id === parseInt(e.target.value)))    
 
    return (
        <Fragment>
            <Loading loading={loading} />
            {
                selectedType && 
                    <div className="content">
                        <div className="container">
                            <div className="flex mb-16">
                                <FormSelect options={ formTypes }
                                    selectedId={ selectedType?.id }
                                    key={`${selectedType.name}_${selectedType.id}`}
                                    handleChange={ handleChange }
                                />
                                <CustomButton title={t('print_form')} type={"blue"} onClick={handlePrint} margins={['ml-8']}/>
                            </div>
                            <Block title={selectedType.name} type="1">
                                {selectedType.component}
                            </Block>
                        </div>
                    </div>
            }       
        </Fragment>
    );
}

const mapStateToProps = state => ({
    user: state.auth
})

export default connect(mapStateToProps)(ReturnForm);
