import React, {Component} from "react";
import axios from "axios1";
import ReactTable from "react-table";
import DatePicker from "react-datepicker";
import CustomDatePicker from 'admin/administrator/CustomDatePicker';
import CustomPagination from "admin/administrator/CustomPagination";
// import "admin/administrator/VehicleLookups.css";
import "react-datepicker/dist/react-datepicker.css";
// import "admin/administrator/CustomElements.css";
// import "admin/administrator/vehicleManufacturerLookups/VehicleManufacturerLookups.css";
import {NavLink} from "react-router-dom";
import PageTitle from "admin/administrator/PageTitle";
import CustomPieChart2d from 'components/Charts/CustomPieChart2d';

var moment = require('moment');

export default class VehicleManufacturerLookups extends Component {
    constructor(props) {
        super(props);

        let d = new Date();
        d = d.setMonth(d.getMonth() - 2);
        let d2 = new Date();
        d2 = d2.setMonth(d2.getMonth() - 0);
        this.state = {
            details: {
                account: "All Accounts",
                total: 0,
                lookups: 0
            },
            accounts: [],
            models: [],
            engines: [
                {id: 0, name: "All Engines", active: '1'}
            ],
            form: {
                account_id: null,
                model: null,
                engine: null,
                month: this.props.match.params.month || d,
                endMonth: this.props.match.params.end_month || d2,
            },
            select_month: '',
            select_endMonth: '',
            items: [],
            dataLabelsForChart: [],
            dataValuesForChart: [],
            page: 1,
            pages: 0,
        }
    }

    componentDidMount() {
        this.getAccounts();
        this.getModels(this.props.match.params.name);
        this.getItems();

        // let form = { ...this.state.form };
        // form['month'] = ;
        // this.setState({form:form});
    }

    getItems(page = 1) {
        let form = {...this.state.form};
        form.endMonth = moment(form.endMonth).format('YYYY-MM-DD');
        form.month = moment(form.month).format('YYYY-MM-DD');
        axios.get("catalogue/lookups-by-manufacturers/" + this.props.match.params.name + "?page=" + page, {params: form})
            .then((response) => {
                let dataLabelsForChart = [];
                let dataValuesForChart = [];
                response.data.makes.map((make) => {
                    dataLabelsForChart.push(make.Model);
                    dataValuesForChart.push(make.lookups);
                })
                let details = {...details};
                details["lookups"] = response.data.total[0].lookups;
                details["total"] = response.data.total[0].quantity;

                this.setState({
                    dataLabelsForChart: dataLabelsForChart,
                    dataValuesForChart: dataValuesForChart,
                    items: response.data.makes,
                    engines: response.data.engines,
                    details: details,
                    pages: response.data.pages,
                    page: page
                });
            });
    }

    getAccounts() {
        axios.get("catalogue/view-accounts")
            .then((response) => {
                this.setState({accounts: response.data.accounts})
            });
    }

    getModels(make) {
        axios.get("catalogue/models-for-manufacturers-lookup/" + make)
            .then((response) => {
                this.setState({models: response.data.models});
            })
    }

    handleChangeDate(month) {
        let form = {...this.state.form};
        form['month'] = month;
        var select_month = month;
        this.setState({form: form, select_month: select_month}, () => {
            this.getItems();
        });
    }

    handleChangeEndDate(endMonth) {
        let form = {...this.state.form};
        var select_endMonth = endMonth;
        form['endMonth'] = endMonth;
        this.setState({form: form, select_endMonth: select_endMonth}, () => {
            this.getItems();
        });
    }


    handleInputChange(formName, event) {

        let value = event.target.value;

        let form = {...this.state.form};

        form[formName] = value;

        if (formName == "account_id") {
            var index = event.nativeEvent.target.selectedIndex;
            let details = {...this.state.details};
            details['account'] = event.nativeEvent.target[index].text;
            this.setState({details: details});
        }

        this.setState({form: form}, () => {
            this.getItems();
        });
    }

    render() {
        return (

            <div className={"vehicleManufacturerWrapper lookups-wrapper vehicles admin-wrapper"}>
                <div className="row">
                    <div className="col">
                        {/*<p className="font-weight-bold">{this.state.details.account}</p>*/}
                        <p>From <span
                            className="font-weight-bold">{moment(this.state.form.month).format('DD-MM-YYYY')}</span> To <span
                            className="font-weight-bold">{moment(this.state.form.endMonth).format('DD-MM-YYYY')}</span>
                        </p>
                        <p>total: <span className="font-weight-bold">{this.state.details.total}</span> item(s) sold
                            from <span className="font-weight-bold">{this.state.details.lookups}</span> Lookups</p>
                    </div>
                    <div className="col">
                        <div className="row text-right custom-filter ">
                            <div className="col pl-0 filter-group">
                                <select className={"textUppercase"} name="accounts" value={this.state.form.account_id}
                                        onChange={this.handleInputChange.bind(this, "account_id")}>
                                    <option value="">All Accounts</option>
                                    {
                                        this.state.accounts.map((account) => {
                                            return <option key={account.accountId}
                                                           value={account.accountId}>{account.accountName}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col pl-0 filter-group">
                                <CustomDatePicker
                                    value={this.state.form.month} selected={this.state.select_month}
                                    placeholder={"choose from date"}
                                    onChange={this.handleChangeDate.bind(this)}
                                    format={"DD-MM-YYYY"}>
                                </CustomDatePicker>
                            </div>
                            <div className="col pl-0 filter-group">
                                <CustomDatePicker
                                    value={this.state.form.endMonth} selected={this.state.select_endMonth}
                                    placeholder={"choose to date"}
                                    onChange={this.handleChangeEndDate.bind(this)}
                                    format={"DD-MM-YYYY"}>
                                </CustomDatePicker>
                            </div>
                            <div className="col pl-0 filter-group">
                                <select name="model" value={this.state.form.model}
                                        onChange={this.handleInputChange.bind(this, "model")}>
                                    <option value="">All Models</option>
                                    {
                                        this.state.models.map((model) => {
                                            return <option key={model.Model} value={model.Model}>
                                                {model.Model}
                                            </option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col pl-0 filter-group">
                                <select name="engine" value={this.state.form.engine}
                                        onChange={this.handleInputChange.bind(this, "engine")}>
                                    <option value="">All Engines</option>
                                    {
                                        this.state.engines.map((engine) => {
                                            return <option key={engine.Engine} value={engine.Engine}>
                                                {engine.Engine}

                                            </option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className="col ">
                        <div className="rowBack">
                            <table class="custom-table table table-bordered ">
                                <thead>
                                <tr>
                                    <th scope="col" style={{textAlign: "left"}}>Model</th>
                                    <th scope="col" style={{textAlign: "left"}}>engine</th>
                                    <th scope="col">lookups</th>
                                    <th scope="col">sales vol</th>
                                    <th scope="col" style={{textAlign: "left"}}>sales val</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.items.map((item) => {
                                        return <tr>
                                            <td style={{textAlign: "left"}}>{item.Model}</td>
                                            <td style={{textAlign: "left"}}>{item.Engine}</td>
                                            <td>{(item.lookups) ? item.lookups : 0}</td>
                                            <td>{(item.quantity) ? item.quantity : 0}</td>
                                            <td style={{textAlign: "left"}}>
                                                <NavLink to={{
                                                    pathname: "/admin/vehicle-manufactuer-lookups-details/" + this.props.match.params.name + "/" + item.Model + "/" + item.Engine + "/",
                                                    state: {
                                                        month: moment(this.state.form.month).format('YYYY-MM-DD'),
                                                        endMonth: moment(this.state.form.endMonth).format('YYYY-MM-DD'),
                                                        account: this.state.form.account_id
                                                    }
                                                }}
                                                         className="custom-btn">£ {(item.price) ? item.price : 0}
                                                </NavLink></td>
                                        </tr>
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        {
                            this.state.pages > 0 ?
                                <CustomPagination
                                    page={this.state.page}
                                    pages={this.state.pages}
                                    sendRequest={this.getItems.bind(this)}
                                /> : null
                        }
                    </div>
                    <div className="col  rowBack ">
                        <CustomPieChart2d labels={this.state.dataLabelsForChart}
                                          values={this.state.dataValuesForChart}/>
                    </div>
                </div>
            </div>
        )
    }
}
