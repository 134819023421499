import React from 'react';
import CustomButton from "admin/administrator/CustomButton.js";

const Notify = ({type, primaryText, secondaryText, visible, close, actions, backdrop, data}) => {
    const types = {
        primary: {
            icon: "info-circle",
            color: "#2569A1"
        },
        warning: {
            icon: "exclamation-circle",
            color: "#E6A117"
        },
        danger: {
            icon: "exclamation-triangle",
            color: "#CC3333"
        }
    }

    return (
        <div className={"notify notify--" + type  + (visible ? " notify--active" : "")}>
            {
                (close && backdrop) && <div className="notify__backdrop" onClick={() => close()}></div>
            }
            <div className="notify__dialog">
                {
                    close && <i class="fas fa-times notify__close" onClick={() => close()}></i>
                }
                <div className="notify__content">
                    {
                        type &&
                        <div className="notify__icon">
                            <span style={{color: types[type].color}}>
                                <i class={'fas fa-' + types[type].icon}></i>
                            </span>
                        </div>
                    }
                    <div className="notify__text">
                        <div className="notify__primary-text">{primaryText}</div>
                        {
                            secondaryText && <div className="notify__secondary-text">{secondaryText}</div>
                        }
                        <div className="notify__data">
                            {
                                (data && data.length > 0) &&
                                    <ul>
                                        {
                                            data.map((item) => {
                                                return <li>{item}</li>
                                            })
                                        }
                                    </ul>
                            }
                        </div>
                    </div>
                </div>
                {
                    actions.length > 0 &&
                    <div className="notify__actions">
                        {
                            actions.map((action) => {
                                return <CustomButton title={action.name} type={action.type} onClick={() => action.action()} margins={['ml-8']} />
                            })
                        }
                    </div>
                }
            </div>
        </div>
    )
}

Notify.defaultProps = {
    type: null,
    primaryText: "Primary Text",
    visible: false,
    actions: [],
    close: null,
    backdrop: true,
    data: []
}

export default Notify