import React from "react";
// import "./PricingOption.style.css";
import CustomContainer from 'custom_components/CustomContainer.js';
import FormInput from "admin/administrator/FormInput/FormInput.component";
import Block from "components/Block";

const PricingOption = ({ title, handleChange, disabled, currency = '£', className, inputProps, type, width }) => (
    <Block title={title} type={type || "1"}>
        {
            inputProps.map( ({ label, name, value }) => (
                <div className="flex mb-8" key={name}>
                    <div>{label}</div>
                    <div>
                        <FormInput
                            type='text'
                            key={ name }
                            name={ name }
                            value={ value }
                            placeholder={ name }
                            className={className}
                            onChange={ handleChange }
                            disabled={ disabled }
                        />
                    </div>
                </div>
            ))
        }
    </Block>
)

export default PricingOption;