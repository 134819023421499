import React, {useState, useEffect} from "react";
// import "admin/administrator/TopVehicleMakes.css";
import axios from "axios1";
import CustomPieChart2d from 'components/Charts/CustomPieChart2d';
import CustomReactTable from "admin/administrator/CustomReactTable.js";
var moment = require('moment');

const columns = [
    {
        Header: 'Manufacturer',
        accessor: 'make'
    },
    {
        Header: 'Lookups',
        accessor: 'lookups'
    },
];

const TopVehicleMakes = (props) => {
    const [makes, setMakes] = useState([]);
    const [labels, setLabels] = useState([]);
    const [values, setValues] = useState([]);

    useEffect(() => {
        getTopMakes();
    },[]);

    function getTopMakes() {
        axios.get("stats/accounts/" + props.branchId + "/top-makes?date=" + moment().format("YYYY-MM"))
            .then((response) => {
                let makes = response.data.makes;
                let labels = [];
                let values = [];
                makes.map((make) => {
                    labels.push(make.make);
                    values.push(make.lookups);
                });
                setMakes(makes);
                setLabels(labels);
                setValues(values);
            })
    }

    return (
        <div className={"table-chart"}>
            <div className={"table-wrap"}>
                <CustomReactTable columns={columns} data={makes}/>
            </div>
            <div className={"chart-wrap"}>
                <CustomPieChart2d labels={labels}
                                  values={values}/>
            </div>
        </div>
    )
}

export default TopVehicleMakes;