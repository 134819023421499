import React, {Component} from "react";
// import "./AlertAddresses.css";
import _ from "lodash";

export default class AlertAddresses extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // form: {
            //     system_from_address: "",
            //     stock_alerts_to: "",
            //     price_alerts_to: "",
            // },
            // errors: {
            //     system_from_address: false,
            //     stock_alerts_to: false,
            //     price_alerts_to: false,
            // }
            systemFromAddress: ""
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.settings !== this.props.settings) {
            let errors = [];
            _.map(nextProps.settings, function (element, index) {
                const expression = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
                errors[index] = expression.test(String(element).toLowerCase()) ? true : false;
            })
            this.setState({form: nextProps.settings, errors: errors});
        }
    }

    // handleInputChange(property, event) {
    //     let form = {...this.state.form};
    //     let errors = {...this.state.errors};
    //     form[property] = event.target.value;
    //     const expression = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
    //     errors[property] = expression.test(String(event.target.value).toLowerCase()) ? true : false;
    //     this.setState({form: form, errors: errors}, () => {
    //         this.props.updateAlertAddressesValues(form);
    //     });
    // }

    handleInputChange(event) {
        this.setState({systemFromAddress: event.target.value}, () => {
            this.props.setStateFromChild("systemFromAddress", this.state.systemFromAddress);
        })
    }

    render() {
        return (
            <input
                className={"form-control"}
                value={this.state.systemFromAddress}
                onChange={this.handleInputChange.bind(this)}
                placeholder={"no-reply@catalogue.com"}></input>
            // <div className={"alert-adresses-wrapper"}>
            //     <div className={"alert-adresses-input-wrapper"}>
            //         <div className={"alert-adresses-header"}><i className={"fas fa-envelope"}></i>System From Address</div>
            //         <input className={(this.state.errors.system_from_address) ? "system_from_address valid-alert" : "system_from_address error-alert"} value={this.state.form.system_from_address}
            //                 onChange={this.handleInputChange.bind(this,"system_from_address")}
            //                 placeholder={"System from adress"}></input>
            //     </div>
            //     <div className={"alert-adresses-input-wrapper"}>
            //         <div className={"alert-adresses-header"}><i className={"fas fa-bell"}></i>Send stock alerts to</div>
            //         <input className={(this.state.errors.stock_alerts_to) ? "stock_alerts_to valid-alert" : "stock_alerts_to error-alert"} value={this.state.form.stock_alerts_to}
            //                 onChange={this.handleInputChange.bind(this,"stock_alerts_to")}
            //                 placeholder={"Send stock alerts to"}></input>
            //     </div>
            //     <div className={"alert-adresses-input-wrapper"}>
            //         <div className={"alert-adresses-header"}><i className={"fas fa-bell"}></i>Send price alerts to</div>
            //         <input className={(this.state.errors.price_alerts_to) ? "price_alerts_to valid-alert" : "price_alerts_to error-alert"} value={this.state.price_alerts_to}
            //                  onChange={this.handleInputChange.bind(this,"price_alerts_to")}
            //                 placeholder={"Send price alerts to"}></input>
            //     </div>
            // </div>
        );
    }

}