import React, {useState, useEffect, Fragment} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import _, { filter } from 'lodash';
import { t } from 'i18next';

const AxlesFilter = ({
        searchResult,
        saveSearchResult,
        // chooseAxle,
        name,
        section,
        products
    }) => {
    
    const [positions, setPositions] = useState({
        left: false,
        right: false,
    });
    
    useEffect(() => {
        let selectedAxles = {...searchResult.selectedAxlesSection};
        _.map(products[section], product => {
            if (product.position) {
                _.map(positions, (position, key) => {
                    if(selectedAxles[section] && selectedAxles[section][name]) {
                        if(product.position.toLowerCase().includes(key) && !selectedAxles[section][name][key]) {
                            if(product.position.toLowerCase().includes(name.toLowerCase()))
                                selectedAxles[section][name][key] = false;
                        }
                    }else {
                        if(product.position.toLowerCase().includes(key)) {
                            if(!selectedAxles[section]) {
                                selectedAxles[section] = {}
                            };
                            selectedAxles[section][name] = {};
                            if(product.position.toLowerCase().includes(name.toLowerCase()))
                                selectedAxles[section][name][key] = false;
                        }
                    }
                });
            }
        })

        // concat
        saveSearchResult('selectedAxlesSection', selectedAxles)
        if(selectedAxles[section] && selectedAxles[section][name]) {
            setPositions(selectedAxles[section][name]);
        }else {
            setPositions({})
        }
    }, [])

    const chooseAxle = (section, name, axle) => {
        let newpos =  positions ? {...positions} : {};
        newpos[axle] = newpos[axle] ? false : true;
        if(['left', 'right'].includes(axle)) {
            if(newpos[axle]) {
               if(axle === 'right') {
                newpos['left'] = false;
               }else {
                newpos['right'] = false;
               }
            }
        }

        let newselectedAxlesSection = searchResult.selectedAxlesSection;
        searchResult.selectedAxlesSection[section][name] = newpos;
        saveSearchResult("selectedAxles", newselectedAxlesSection);
        setPositions({...newpos});
    }

    return (
        <div className="filters filters--section">                           
            {
                ( positions.hasOwnProperty('left') && positions.hasOwnProperty('right') ) &&
                <div className="filters__list-container ml-40">
                    <span className="filters__list-title">{t("Positions")}: </span>
                    <div className="filters__list">
                    {
                        _.map( positions, (value, key) => { 
                            if(key === 'left' || key === 'right') {
                                return <div className={"filters__item " + (value ? 'filters__item--selected' : '')}
                                    onClick={() => chooseAxle(section, name, key)}>
                                        <span>{t(key)}</span>
                                    </div>;
                            }
                        })
                    }
                    </div>
                </div>
            }
                
        </div>
    );
}


const mapStateToProps = (state) => ({
    searchResult: state.searchResult,
});

const mapDispatchToProps = dispatch => {
    return {
        saveSearchResult: (key, value) => dispatch({
            type: 'SAVE_SEARCH_RESULTS',
            payload: {key, value}
        })
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(AxlesFilter));