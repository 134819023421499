var store = require('store');

let initState = {
    searchData: store.get("searchData") ? store.get("searchData") : {type: "", value: ""}
}

const product = (state = initState, action) => {
    switch (action.type) {
        case 'SAVE_SELECTED_OPTIONS':
            let options = action.payload;
            store.set('options', options)
            return {
                ...state,
                options: options
            }
        case 'SAVE_VEHICLE_ID':
            let searchDataVehicleId = {
                type: action.payload.type,
                value: action.payload.value
            }
            store.set('searchData', searchDataVehicleId)
            return {
                ...state,
                searchData: searchDataVehicleId
            }
        case 'SAVE_PART_NUMBER':
            let searchDataPartNumber = {
                type: action.payload.type,
                value: action.payload.value
            }
            store.set('searchData', searchDataPartNumber)
            return {
                ...state,
                searchData: searchDataPartNumber
            }
        case 'SAVE_REG_NUMBER':
            let searchDataRegNumber = {
                type: action.payload.type,
                value: action.payload.value
            }
            store.set('searchData', searchDataRegNumber)
            return {
                ...state,
                searchData: searchDataRegNumber
            }
        default:
            return state;
    }
}

export default product;