import React, {useEffect, useState, Fragment} from "react";
import {connect} from "react-redux";
import axios from "axios1";
import alertify from 'alertifyjs';
import RegInput from 'components/RegInput';
import VinInput from 'components/VinInput';
import PartNumberInput from 'components/PartNumberInput';
import CustomRadioButton from "admin/administrator/CustomRadioButton";
import _ from 'lodash';
import Notify from "components/Notify";
import Loading from "admin/administrator/LoadingComponent/Loading.component";
import { useTranslation } from 'react-i18next';

const SearchByRegNumber = ({
        settings,
        user,

        setClearRegNumber,
        setClearPartNumber,
        setClearVehicle,
        setClearVinNumber,

        clearPartNumber,
        clearVinNumber,
        clearRegNumber,

        searchResult,
        saveSearchResult,
        saveSelectedSearchType
    }) => {
    
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [vehicleName, setVehicleName] = useState("");
    const [partNumber, setPartNumber] = useState("");
    const [regNumber, setRegNumber] = useState("");
    const [vinNumber, setVinNumber] = useState("");
    const [showVinWarning, setShowVinWarning] = useState(false);
    const [visibleNotify, setVisibleNotify] = useState(false);
    const [visibleNotifyPart, setVisibleNotifyPart] = useState(false);
    const [visibleNotifyReg, setVisibleNotifyReg] = useState(false);
    const [visibleNotifyVin, setVisibleNotifyVin] = useState(false);
    const [visibleNotifyVinLength, setVisibleNotifyVinLength] = useState(false);
    const [modalText, setModalText] = useState("");
    const [modalTitle, setModalTitle] = useState("Is this your vehicle?");
    const [showMdalButtons, setShowMdalButtons] = useState(true);
    const [isShowing, setIsShowing] = useState(false);
    const [showLastTen, setShowLastTen] = useState(false);
    const [partNumberNotificationShow, setPartNumberNotificationShow ] = useState(false);
    const [notificationModalMessage, setNotificationModalMessage] = useState("");
    const [searchTypes, setSearchTypes] = useState([
        { 
            label: t("Veh Reg"),
            title: "vrm",
            checked: true,
            disabled: false,
        },
        { 
            label: t("Non Veh"),
            title: "non",
            checked: false,
            disabled: false,
        },
        {
            label: t("VIN"),
            title: "vin",
            checked: false,
            disabled: false,
        },
    ]);

    const triggerFindProductsClick = React.createRef();

    const components = {
            vrm: () => <RegInput regNumber={regNumber}
                    _handleKeyDown={_handleKeyDown}
                    isGerman = {user?.user?.account?.branch_id === 159}
                    handleRegNumberChange={handleRegNumberChange}
                    handleFindButtonClick={handleFindButtonClick}/>,
            non: () => <PartNumberInput
                    partNumber={ partNumber }
                    _handlePartNumberKeyDown={ _handlePartNumberKeyDown }
                    handlePartNumberChange={ handlePartNumberChange }
                    triggerFindProductsClick={ triggerFindProductsClick }
                    handleFindButtonClick= { handleFindPartNumberButtonClick }                
                    placeholder={t("Enter Non Part Number")}
                    isShowing={ partNumberNotificationShow }
                    closeModalHandler={ () => setVisibleNotifyPart(false) }/>,
            vin: () => <VinInput
                vinNumber={vinNumber}
                _handleKeyDown={_handleVinKeyDown}
                handleVinNumberChange={handleVinNumberChange}
                handleFindByVinNumber={handleFindByVinNumber}/>
    };

    useEffect(() => {
        if(clearPartNumber) {
            setPartNumber("");
        }
        
        if(clearVinNumber) {
            setVinNumber("");
        }

        if(clearRegNumber) {
            setRegNumber("");
        }

        // else if(this.props.searchResult.type == "partNumber" && this.state.partNumber == "") {
        //     partNumber = this.props.searchResult.value;
        // } else {
        //     partNumber = this.state.partNumber;
        // }
    }, [clearPartNumber, clearVinNumber, clearRegNumber])

    useEffect(() => {
        // if(settings.search_types && settings.approve_users > 0)
                
        if(searchResult.type === "regNumber") {
            setRegNumber(searchResult.value);
        }

        if(searchResult.type === "vinNumber") {
            setVinNumber(searchResult.value);
        }

        if(searchResult.type === "nonPartNumber") {
            setPartNumber(searchResult.value);
        }

        if(settings.search_types)
            initSearchTypes();
        
    }, [settings.search_types])

    useEffect(() => {
        if(searchResult.nonAutoActive) {
            // let search_types = [...searchTypes];
            
            // let search_types_setting = settings.search_types.split(",");

            let search_types = []; 
            _.map( settings.search_types.split(","), (item, index) => {
                let search_type = _.find(searchTypes, {title: item});
                if(search_type) {
                    search_type.checked = search_type.title === 'non';
                }
                search_types.push(search_type)
            });
            // let search_types2 =  _.map( searchTypes, item => {
            //     // let search_type = _.find(searchTypes, {title: item});
            //     if(item) {
            //         item.checked = item.title === 'non';
            //     }
            //     return item;
            // });
            setSearchTypes([...search_types])

            // if(_.find(search_types, {title: "non"}).checked == false) {
            //     search_types = _.map(search_types, item =>  {
            //          item.checked = item.title === 'non';
            //          return item;
            //     });
            //     setSearchTypes(search_types)
            // }

            if(!searchResult.fromNonPartNumber) {
                // if((( !this.arrayEquals(prevState.groups, this.state.groups) || !prevProps.searchResult.nonAutoActive ) || ( prevState.groups.length == 0 && this.props.searchResult.sections.length == 0) )) {
                    getNonAutoGroups();
            }
        }
    }, [searchResult.nonAutoActive, searchResult.fromNonPartNumber])
    
    const handleRegNumberChange = event => {
        if (regNumber.length <= 9) {
            if (regNumber.length == 1 && event.target.value == '') {
                setClearRegNumber(true);
            } else {
                setClearRegNumber(false);
            }
            setRegNumber(event.target.value);
        }
    }

    const _handlePartNumberKeyDown = e => {
        if (e.key === 'Enter') {
            handleFindPartNumberButtonClick();
        }else if( (e.key === 'Backspace' || e.key === 'Delete') && partNumber === getText(document.activeElement)) {
            setClearPartNumber(true);
        }
    }


    const _handleKeyDown = e => {
        if (e.key === 'Enter') {
            handleFindButtonClick();
        } else if ((e.key === 'Backspace' || e.key === 'Delete') && (regNumber === getText(document.activeElement))) {
            setClearRegNumber(true);
        }
    }

    const _handleVinKeyDown = e => {
        if (e.key === 'Enter') {
            handleFindByVinNumber();
        } else if ((e.key === 'Backspace' || e.key === 'Delete') && (vinNumber === getText(document.activeElement))) {
            setClearVinNumber(true);
        }
    }

    const getText = elem => {
        if((elem.tagName === "INPUT" && elem.type === "text")) {
            return elem.value.substring(elem.selectionStart, elem.selectionEnd);
        }
        return null;
    }

    const handlePartNumberChange = event => {
        if(partNumber.length == 1 && event.target.value == '') {
            setClearPartNumber(true);
        }else {
            setPartNumber(event.target.value);
            setClearPartNumber(false);
        }
    }

    const handleFindPartNumberButtonClick = () => {
        if (partNumber == "") return;
        setLoading(true);

        setClearRegNumber(true);
        setClearVehicle(true);

        saveSearchResult("fromNonPartNumber", true);
        getGroupsByPartNumber();
    }

    const handleVinNumberChange = event => setVinNumber(event.target.value);
    const handleFindByVinNumber = () => {
        if(vinNumber.length !== 17) {
            setVisibleNotifyVinLength(true);
            return;
        }
        clearState();
        getGroupsByVinNumber();
    }
    const handleFindButtonClick = () => {
        if (regNumber == "") return;
        clearState();
        getGroupsByRegNumber();
    }

    const clearState = () => {
        saveSearchResult("products", []);
        saveSearchResult("sections", []);
        saveSearchResult("hideForPartNumber", false);
        setClearPartNumber(true);
        setClearVehicle(true);   
    }

    const openPartNumberModalHandler = value => {
        setVisibleNotifyPart(true);
        setNotificationModalMessage(value);
    }

    const getNonAutoGroups = () => {
        axios.get("catalogue/groups-list")
            .then(response => {
                const newGroups = [];
                Object.keys(response.data).map((key) => {
                    if(typeof response.data[key] === 'object') {
                        newGroups.push(response.data[key]);
                    }
                });
                saveSearchResult("groups", newGroups);
            })
    }

    const getGroupsByVinNumber = () => {
        setLoading(true);
        axios.get("catalogue/search/by-vin-number/" + vinNumber)
            .then((response) => {
                if (response.data.success) {
                    if(!response.data.groups) setVisibleNotifyVin(true);
                    let groups = response.data.groups && response.data.groups.length >= 1 ? (response.data.groups && response.data.groups.length > 1 ? response.data.groups : [response.data.groups[0]]) : [];
                    let v = response.data.vehicle;
                    let text = getVehicleName(v);

                    saveSearchResult("type", "vinNumber");
                    saveSearchResult("value", vinNumber);
                    saveSearchResult('groups', groups);
                    saveSearchResult("vehicleName", text);
                    saveSearchResult('lookup_id', response.data.lookup_id);
                    // this.props.setStateFromChild("resultSearchTitle", text);

                    workOnResponse(groups, response.data.lookup_id);
                } else {
                    setShowVinWarning(true);
                }

                setLoading(false);
            })
    }

    const getGroupsByRegNumber = () => {
        setLoading(true);
        axios.get("catalogue/search/by-reg-number/" + regNumber.replace(/\s/g, ''))
            .then((response) => {
                if (response.data.success) {
                    if (response.data.warning) {
                        setVisibleNotifyReg(true);
                    } else  {
                        let groups = response.data.groups.length >= 1 ? (response.data.groups.length > 1 ? response.data.groups : [response.data.groups[0]]) : [];
                        let lookup_id = response.data.lookup_id;
                        let v = response.data.vehicle;
                        let text = getVehicleName(v);
                        // this.props.setStateFromChild("resultSearchTitle", text);
                        saveSearchResult("type", "regNumber");
                        saveSearchResult("value", regNumber);
                        saveSearchResult("vehicleName", text);
                        saveSearchResult("productsQuantity", 0);
                        saveSearchResult("selectedAxlesSection", []);

                        if (!v || v.Make === undefined) {
                            setIsShowing(true);
                        } else {
                            let text = getVehicleName(v);
                            // this.props.setStateFromChild("resultSearchTitle", text);
                            if (settings.vrm_vehicle_confirmation) {
                                saveSearchResult("vehicleName", text);
                                setVisibleNotify(true);
                                setModalText(text);
                                setModalTitle(t("Is this your vehicle") + "?");
                                setShowMdalButtons(true);
                            }

                            workOnResponse(groups, lookup_id);
                        }
                    }
                } else {
                    alertify.error(response.data.warning ? response.data.warning : response.data.message)
                }
                setLoading(false);
            })
    }

    const getGroupsByPartNumber = () => {
        setLoading(true);
        axios.get("catalogue/search/by-non-part-number/" + partNumber)
            .then((response) => {
                if(response.data.success){
                    let groups = response.data.groups;
                    if(!Object.keys(groups).length){
                        openPartNumberModalHandler();
                    }
                    let lookup_id = response.data.lookup_id;
                    saveSearchResult("vehicleName", '');
                    saveSearchResult("productsQuantity", 0);
                    // this.props.setStateFromChild("lookup_id", lookup_id);
                    // this.props.setStateFromChild("groups", groups);
                    // this.props.setStateFromChild("resultSearchTitle", this.state.partNumber);
                    saveSearchResult("type", "nonPartNumber");
                    saveSearchResult("value", partNumber);
                    saveSearchResult("groups", groups);
                    saveSearchResult("lookup_id", lookup_id);
                    saveSearchResult("selectedGroups", []);
                    saveSearchResult("selectedSections", []);
                    saveSearchResult("selectedBrands", []);
                    saveSearchResult("products", []);
                    saveSearchResult("filteredProducts", []);
                    saveSearchResult("showProducts", false);
                    // saveSearchResult("hideForPartNumber", true);
                }else{
                    openPartNumberModalHandler();
                    alertify.error(response.data.warning?response.data.warning:"An error accured");
                }
                setLoading(false);
            })
    }

    const workOnResponse = (groups, lookup_id) => {
        saveSearchResult("groups", groups);
        
        setLoading(false);
        setShowLastTen(false);

        saveSearchResult("type", "regNumber");
        saveSearchResult("value", regNumber);
        saveSearchResult("groups", groups);
        saveSearchResult("lookup_id", lookup_id);
        saveSearchResult("selectedGroups", []);
        saveSearchResult("selectedSections", []);
        saveSearchResult("selectedBrands", []);
        saveSearchResult("products", []);
        saveSearchResult("filteredProducts", []);
        saveSearchResult("productsQuantity", 0);
        saveSearchResult("showProducts", false);
        // saveSearchResult("hideForPartNumber", false);
    }

    const getVehicleName = vehicle => {
        if (vehicle) {
            if (vehicle.EndYr == "0") {
                vehicle.EndYr = "";
            }
            const appClient = process.env.REACT_APP_CLIENT;
            // let ktype = user.user.admin && (appClient == "dev" || appClient == "staging") ? "- ktype: " + vehicle.Ktype : "";
            let ktype = "- KType: " + vehicle.Ktype;
            let nameSelectAttr = [ 'Make', 'Model', 'ModelInfo', 'ModelInfo', 'BodyStyle', 'Engine', 'Fuel', 'StartYr', 'EndYr', 'Chassis', 'HP', 'Ktype'];
            let vehicleTitle = "";

            _.map(vehicle, ( val, key ) => {
                if(_.includes(nameSelectAttr, key) && val != null) {

                    if( key == 'Engine' && !isNaN(val) ) {
                        val = Number(val).toFixed(1);
                    }

                    if(key == 'HP' && !isNaN(val)) {
                        val = val + "hp";
                    }

                    if(key == 'StartYr') {
                        val = val + " -";
                    }
                    
                    vehicleTitle+= val + " ";

                }
            });
            return vehicleTitle + ktype;
        }
    }

    const initSearchTypes = () => {
        if(settings.search_types) {
            let search_types_setting = settings.search_types.split(",");
            let search_types =  _.map( search_types_setting, (item, index) => {
                let search_type = _.find(searchTypes, {title: item});
                if(search_type) {
                    search_type.checked = false;
                    if( index === 0 ) {
                        search_type.checked = true;
                        if(search_type.title == 'non') {
                            saveSelectedSearchType("nonAutoActive", true);
                        }
                    }
                }
                return search_type;
            })

            // if(search_types.length < 3) {
                _.map(searchTypes, (item) => {
                    let exist = _.find(search_types, {title: item.title});
                    if(!exist) {
                        item.disabled = true;
                        item.checked = false;
                        search_types.push(item)
                    }else {
                        item.disabled = false;
                    }
                })
            // }
            setSearchTypes([...search_types]);
        }
    }

    const onRadioBtnHandler = event => {
        let name =  event.target.name;
        if(event.target.name == 'non') {
            saveSearchResult("nonAutoActive", true);
            setClearVehicle(true);
        }else {
            saveSearchResult("nonAutoActive", false);
            saveSearchResult("fromNonPartNumber", false);
            setClearVehicle(false);
        }

        setClearRegNumber(true);
        setClearVinNumber(true);
        setClearPartNumber(true);
        // setClearVehicle(true);

        saveSearchResult("groups", []);
        saveSearchResult("sections", []);
        saveSearchResult("showProducts", false);

        saveSearchResult("products", []);
        saveSearchResult("vehicleName", "");
        saveSearchResult("selectedGroups", []);
        saveSearchResult("selectedSections", []);
        saveSearchResult("selectedBrands", []);
        saveSearchResult("products", []);
        saveSearchResult("filteredProducts", []);
        saveSearchResult("productsQuantity", 0);
        saveSearchResult("hideForPartNumber", false);

        let search_types = _.map(searchTypes, searchType => {
            searchType.checked = searchType.title == name ? true : false;
            return searchType;
        });
        setSearchTypes(search_types);
    }

    alertify.defaults = {
        notifier: {
            position: 'top-right',
            delay: 5
        }
    }

    return (
        <Fragment>
            <Loading loading={loading}/>
            <Notify type={"primary"}
                    visible={visibleNotifyReg}
                    close={() => setVisibleNotifyReg(false)}
                    primaryText={t("The vehicle registration you entered could not be found")}
                    secondaryText={t("Try search by vehicle selection or part number")}
                    actions={[
                        {
                            name: "ok",
                            action: () => setVisibleNotifyReg(false),
                            type: "blue"
                        }
                    ]}/>
            <Notify type={"primary"}
                    visible={visibleNotifyVin}
                    close={() => setVisibleNotifyVin(false)}
                    primaryText={t("The VIN number you entered could not be found")}
                    secondaryText={t("Try search by vehicle selection or part number")}
                    actions={[
                        {
                            name: "ok",
                            action: () => setVisibleNotifyVin(false),
                            type: "blue"
                        }
                    ]}/>
            <Notify type={"primary"}
                    visible={visibleNotifyVinLength}
                    close={() => setVisibleNotifyVinLength(false)}
                    primaryText={t("VIN Number must be exactly 17 characters in length")}
                    secondaryText={""}
                    actions={[
                        {
                            name: "ok",
                            action: () => setVisibleNotifyVinLength(false),
                            type: "blue"
                        }
                    ]}/>
            <Notify type={"primary"}
                    visible={visibleNotifyPart}
                    close={() => setVisibleNotifyPart(false)}
                    primaryText={t("The part number you entered could not be found")}
                    secondaryText={!searchResult.nonAutoActive ? t("Try search by vehicle selection or registration") : ""}
                    actions={[
                        {
                            name: "ok",
                            action: () => setVisibleNotifyPart(false),
                            type: "blue"
                        }
                    ]}/>
            <Notify type={"primary"}
                    visible={visibleNotify}
                    close={() => setVisibleNotify(false)}
                    primaryText={modalTitle}
                    secondaryText={modalText}
                    actions={[
                        {
                            name: t("yes"),
                            action: () => setVisibleNotify(false),
                            type: "blue"
                        },
                        {
                            name: t("no"),
                            action: () => setVisibleNotify(false),
                            type: "lightgray"
                        },
                    ]}/>

            <div className="flex mb-12">
                {
                    _.map(searchTypes, searchType => (
                        <CustomRadioButton
                            name={searchType.title}
                            label={user?.user?.account?.branch_id === 159 && searchType.label === 'Veh Reg' ? 'KBA' : searchType.label}
                            checked={searchType.checked}
                            onChange={onRadioBtnHandler}
                            disabled={searchType.disabled}
                        />
                    ))
                }
            </div>
            { _.map( searchTypes, searchType => (searchType.checked && !searchType.disabled) && components[searchType.title]() ) }
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    options: state.products.options,
    partNumber: state.products.partNumber,
    searchResult: state.searchResult,
    userId: state.auth.user,
    user: state.auth,
    settings: state.settings
})

const mapDispatchToProps = dispatch => {
    return {
        saveSearchResult: (key, value) => dispatch({
            type: 'SAVE_SEARCH_RESULTS',
            payload: {key, value}
        }),
        saveSelectedSearchType: (key, value) => dispatch({
            type: 'SAVE_SELECTED_SEARCH_TYPE',
            payload: {key, value}
        })
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchByRegNumber);
