import React, {Fragment, useState, useEffect} from 'react';
// import "./ImageSlider.css";
import SmallSlide from "admin/administrator/ImageSlider/SmallSlide";
import SliderSettings from "admin/administrator/ImageSlider/SliderSettings";
import MainSlider from "admin/administrator/ImageSlider/MainSlider";
import UploadModal from "admin/administrator/ImageSlider/UploadModal";

function ImageSlider() {
    const [showModal, setShowModal] = useState(false);

    function openModal() {
        setShowModal(true);
    }

    function closeModal() {
        setShowModal(false);
    }

    return (
        <Fragment>
            <div className={"dashboard-page-body"}>
                <div className={"page-content admin-wrapper"}>
                    <div className="image-slider">
                        <div className="grid">
                            <div className="grid__row">
                                <div className="grid__column grid__column--9">
                                    <div className="slider-primary">
                                        <MainSlider openModal={() => openModal()}/>
                                    </div>
                                    <div className="slider-secondary">
                                        <SmallSlide position={"left"}/>
                                        <SmallSlide position={"center"}/>
                                        <SmallSlide position={"right"}/>
                                    </div>
                                </div>
                                <div className="grid__column grid__column--3">
                                    <div className="slider-settings">
                                        <SliderSettings/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ImageSlider