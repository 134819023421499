import React, { Component } from 'react';
// import "./Hamburger.css";
import axios from "axios1";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";


var store = require('store');

class Hamburger extends Component {
    constructor(props)
    {
        super(props);
    }

    render() {
        return (
            <div className="hamburger-wrapper">
                <span class="icon-Hamburger"></span>
            </div>
        );
    }
}


export default Hamburger