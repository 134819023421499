import React, {Fragment, useState, useEffect} from 'react';
import "./MessageBox.css";

function MessageBox(props) {

    const [icon, setIcon] = useState(null);

    useEffect(() => {
        setIconType();
    }, [])

    function setIconType() {
        switch(props.type) {
            case "success":
                setIcon("check-circle");
                break;
            case "warning":
                setIcon("exclamation-triangle");
                break;
            default:
                setIcon("check-circle");
        }
    }

    return (
        <div className={"message-inner message-" + props.type}>
            <i class={"fas fa-" + icon}></i>
            <span>{props.text}</span>
        </div>
    )
}

export default MessageBox