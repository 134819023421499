import React, {Fragment, useState, useEffect} from 'react';
import "./MainSlide.css";
import dragIcon from "images/drag-icon.png";
import visibleIcon from "images/visible.png";
import notVisibleIcon from "images/not-visible.png";
import trashIcon from "images/trash.png";
import {sortableHandle} from 'react-sortable-hoc';

function MainSlide(props) {
    const DragHandle = sortableHandle(() => <img className="slide-drag" src={dragIcon}/>);

    return (
        <div className="main-slide">
            <div className="slide-img" style={{backgroundImage: "url('"+((props.image.image.id == undefined) ? props.image.image.imagePreview : props.image.image.image)+"')"}}>
                <div className="slide-trash" onClick={() => props.removeSlide(props.image.index)}>
                    <img src={trashIcon}/>
                </div>
            </div>
            <div className="slide-footer">
                <DragHandle/>
                <span className="slide-title">{props.image.image.name}</span>
                <div className="slide-status">
                    <img src={(props.image.image.is_visible == 1 ? visibleIcon : notVisibleIcon)} 
                        onClick={() => props.changeVisibilityStatus(props.image.index)}/>
                </div>
            </div>
        </div>
    )
}

export default MainSlide