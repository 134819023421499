import React, { Component } from "react";
import axios from "axios1";
import ReactTable from "react-table";
// import "admin/administrator/AbandonedCartsDetails.css";
import "react-datepicker/dist/react-datepicker.css";
var moment = require('moment');

export default class OrdersDetails extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            abandonedCartsUsers: [],
            abandonedCartsProducts: [],
            dateFrom: "",
            dateTo: "",
            errors: [],
            columns: [
                {
                    Header: 'Company',
                    accessor: '',
                    style: {
                        textAlign: "center"
                    }
                },
                {
                    expander: true,
                    width: 200,
                    Header: 'More',
                    Expander: ({ isExpanded, row, ...rest }) =>
                    <div>
                      {isExpanded
                        ? <button className="btn show-details">Hide Details</button>
                        : <button className="btn show-details">Show Details</button>}
                    </div>,
                    style: {
                        textAlign: "center"
                    }
                },
            ],
            subColumns: [
                {
                    Header: 'Part Num.',
                    accessor: 'part_number.part_number',
                    style: {
                        textAlign: "center"
                    }
                },
                {
                    id: 'price_net',
                    Header: 'Price',
                    accessor: d => d.price + ' ' + d.currency.symbol,
                    style: {
                        textAlign: "center"
                    }
                },
                {
                    Header: 'Quantity',
                    accessor: 'pivot.quantity',
                    style: {
                        textAlign: "center"
                    }
                },
                {
                    Header: 'Date',
                    accessor: 'pivot.created_at',
                    style: {
                        textAlign: "center"
                    }
                }
            ]
        }
    }

    componentDidMount() {
        this.getAbandonedCarts()
    }

    getAbandonedCarts() {
        axios.get("/catalogue/getAbandonedCarts")
            .then(response => {
                let abandonedCartsUsers = Object.keys(response.data).map((key) => {
                    return key;
                })
                this.setState({abandonedCartsUsers: abandonedCartsUsers, abandonedCartsProducts: response.data})
            })
    }

    resetFilter() {
        this.getAbandonedCarts()
    }

    handleChangeDateFrom(date) {
        this.setState({dateFrom: date});
    }

    handleChangeDateTo(date) {
        this.setState({dateTo: date});
    }

    render() {

        const data = this.state.abandonedCartsUsers
        
        return (
            <div className={"dashboard-page-wrapper"}>
                <div className={"dashboard-page-navigations"}>
                    <div>
                        <i className="fas fa-chart-line"></i>
                        <span> User Statistics</span>
                    </div>
                    <div>
                        <button className="back-btn" onClick={() => this.props.history.goBack()}>
                            Go Back
                        </button>
                    </div>
                </div>
                <div className={"dashboard-page-body"}>
                    <ReactTable 
                        data={data}
                        columns={this.state.columns}
                        defaultPageSize={10}
                        minRows={0}
                        SubComponent={row => {
                            return (
                                <div className="sub-orders">
                                    <ReactTable data={this.state.abandonedCartsProducts[row.original]} 
                                                columns={this.state.subColumns} 
                                                minRows={0}
                                                showPagination={false}
                                                showPageSizeOptions={false}
                                    />
                                </div>
                            )
                          }}
                    />
                </div>
            </div>
        )
    }
}