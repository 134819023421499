import React, {Component, Fragment} from "react";
import axios from "axios1";
import {CSVLink, CSVDownload} from "react-csv";
import FormInput from "admin/administrator/FormInput/FormInput.component";
import Block from "components/Block";
import Loading from "admin/administrator/LoadingComponent/Loading.component";

export default class DashboardAnalytics extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            searchValue: "",
            brand: "",
            brandId: null,
            brands: [],
            brandsNotFound: false,
            partNumbers: [],
            loading: false
        }
    }

    getBrands(e) {
        if (e.target.value != "") {
            this.setState({loading: true, brands: [], searchValue: e.target.value, brand: "", brandId: null});
            axios.get("catalogue/filtered-brands/" + e.target.value).then((response) => {
                if (response.data.hasOwnProperty("success") && response.data.success) {
                    this.setState({brands: response.data.brands, loading: false, brandsNotFound: false})
                    if (response.data.brands.length == 0) {
                        this.setState({brandsNotFound: true})
                    }
                }
                this.setState({loading: false});
            });
        } else {
            this.setState({brands: [], searchValue: ""})
        }
    }

    getPartNumbersByBrandId(brand, brandId) {
        this.setState({loading: true});
        axios.get("catalogue/part-numbers-without-image/" + brandId).then((response) => {
            if (response.data.hasOwnProperty("success") && response.data.success) {
                let partNumbers = [];
                if (typeof response.data.partNumbersWithoutImage === 'object') {
                    let result = Object.keys(response.data.partNumbersWithoutImage).map((key) => {
                        return [response.data.partNumbersWithoutImage[key]];
                    })
                    result.map(partNumber => partNumbers.push({partNumber: partNumber}));
                } else {
                    response.data.partNumbersWithoutImage.map(partNumber => partNumbers.push({partNumber: partNumber}));
                }
                this.setState({
                    brand: brand,
                    brands: [],
                    searchValue: "",
                    brandId: brandId,
                    partNumbers: partNumbers
                });
            }
            this.setState({loading: false});
        })
    }

    afterDownload() {
        this.setState({brand: "", brands: [], brandId: null})
    }

    render() {
        return (
            <Fragment>
                <Loading loading={this.state.loading}/>
                <div className={"page-content admin-wrapper"}>
                    <div className={"downloads-wrapper"}>
                        <Block type="1" title="download part numbers without image">
                        <div className={"downloads-input"}>
                                <FormInput
                                    name=''
                                    type='text'
                                    placeholder='Enter brand name...'
                                    error={""}
                                    value={this.state.searchValue != "" ? this.state.searchValue : this.state.brand}
                                    onChange={this.getBrands.bind(this)}
                                    className="bordered"
                                />
                                {
                                    this.state.searchValue ?
                                        <div className={"brands-list"}>
                                            <ul>
                                                {
                                                    this.state.brands.map((brand) => {
                                                        return <li
                                                            onClick={this.getPartNumbersByBrandId.bind(this, brand.name, brand.id)}>{brand.name}</li>;
                                                    })
                                                }
                                            </ul>
                                            {
                                                this.state.brandsNotFound ?
                                                    <span>Brands not found</span> : null
                                            }
                                            {
                                                this.state.loading ?
                                                    <img
                                                        src={"https://nika.myavail.info/public/images/vehicleLoading.gif"}/> : null
                                            }
                                        </div> : null
                                }
                                {
                                    this.state.brandId && this.state.searchValue == "" ?
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px'}}>
                                            <span
                                                className={"found-qty"}>Found <strong>{this.state.partNumbers.length}</strong> part numbers</span>
                                            {
                                                this.state.partNumbers.length > 0 ?
                                                    <CSVLink
                                                        className={"download-btn"}
                                                        data={this.state.partNumbers}
                                                        filename={this.state.brand + ".csv"}
                                                        onClick={() => {
                                                            this.afterDownload();
                                                        }}
                                                    >
                                                        Download
                                                    </CSVLink> : null
                                            }
                                        </div> : null
                                }
                            </div>
                        </Block>
                    </div>
                </div>
            </Fragment>
        );
    }
}