import React from 'react';
import './FtpDescription.css';

function FtpDescription({textFirst, textSecond, textThird = null}) {
    return (
        <div className={"ftp-description"}>
            <p>{textFirst}</p>
            <br/>
            <p>{textSecond}</p>
            <br/>            
            {
                textThird && <p>{textThird}</p>
            }
        </div>
    )
}

FtpDescription.defaultProps = {
    textFirst: "",
    textSecond: ""
}

export default FtpDescription;