import React, {Fragment, useEffect, useState, useCallback} from "react";
import {BrowserRouter as Router, Route, NavLink} from "react-router-dom";
import PageTitle from "admin/administrator/PageTitle";
import axios from "axios1";
import _ from "lodash";
import {connect} from "react-redux";
import CustomPagination from "admin/administrator/CustomPagination";
import CustomDatePicker from 'admin/administrator/CustomDatePicker';
import alertify from "alertifyjs";
import OrdersDataTable from "account/Orders/OrdersDataTable";
import OrdersAdminActionTable from "account/Orders/OrdersAdminActionTable";
import {updateOutstandingOrdersQty} from "redux/actions/shoppingCart";
import {setOrdersQty, setCustomerOrdersQty} from "redux/actions/orders";
import CustomReactTable from "admin/administrator/CustomReactTable.js";
import CustomButton from "admin/administrator/CustomButton";
import OrderComponent from "admin/administrator/OrderComponent.js";
import CustomCheckBox from "admin/administrator/CustomCheckBox";
import CustomModal from "admin/administrator/customModal.js";
import CustomContainer from 'custom_components/CustomContainer.js';
import Block from "components/Block";
import { useTranslation } from 'react-i18next';
import { array } from "prop-types";
var moment = require('moment');

function MyOrders(props) {
    const [filters, setFilters] = useState({
        start_date: "",
        end_date: ""
    });
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [timeInterval, setTimeInterval] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [search, setSearch] = useState('');
    const [companyInfo, setCompanyInfo] = useState({});
    const { t, i18n } = useTranslation();

    useEffect(() => {
        console.log('props.auth?.user?.account?.business_type_id', props.auth?.user?.account?.business_type_id)
        axios.get("catalogue/get-owner")
            .then((response) => {
                console.log(response);

                if (response.data.success) {
                    setCompanyInfo({
                        "name": response.data?.owner?.name ?? '',
                        "address_1": response.data?.owner?.address_1,
                        "town": response.data?.owner?.town,
                        "county": response.data?.owner?.county,
                        "postal_code": response?.data?.owner?.postal_code
                    });
                }
            })
    }, []);

    const columns = React.useMemo(
        () => [
            {
                Header: t('Order Id'),
                accessor: 'order_id',
                className: "textRight",
                disableSortBy: true,
            },
            {
                Header: t('Date/Time'),
                className: "textLeft",
                disableSortBy: true,
                Cell: props => (
                    moment(props.row.original.created_at).format("DD-MM-YYYY HH:mm:ss")
                ),
            },
            {
                Header: t('Company'),
                accessor: 'company',
                className: "textLeft",
                disableSortBy: true,
            },
            {
                Header: t('Items'),
                accessor: 'quantity',
                className: "textRight",
                disableSortBy: true,
            },
            {
                Header: t('Total Price'),
                accessor: 'totalPrice',
                className: "textRight",
                disableSortBy: true,
            },
            {
                Header: t('Details'),
                className: "textCenter",
                disableSortBy: true,
                Cell: row => (
                    <OrderComponent value={row.row.original.order_id} 
                                    title={t("Details")}
                                    original={row.row.original} 
                                    branch={props.branch} 
                                    date={props.date} 
                                    setRequestInterval={setRequestInterval.bind(this)}
                                    clearRequestInterval={clearRequestInterval.bind(this)}
                                    companyInfo={companyInfo}
                                    printable={true}
                                    />
                ),
            },
            {
                Header: t('Completed'),
                accessor: "is_completed",
                className: "textCenter",
                disableSortBy: false,
                Cell: ({value, row}) => {
                    return props.auth?.user?.admin == 1 ?
                        (
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <CustomCheckBox
                                    name={row.original.order_id}
                                    checked={value}
                                    value={value}
                                    onChange={handleIsCompletedChecked.bind(this, row.original.order_id, value)}
                                />
                            </div>
                        ) :
                        (
                            row.original.is_completed ?
                                <i className="fas fa-check fa-2x" style={{color: 'green'}}></i>
                                : <i className="fas fa-times fa-2x" style={{color: 'red'}}></i>
                        )
                }
            }
        ]
    );

    useEffect(() => {
        setLoading(true);
        setRequestInterval();

        return () => {
            clearRequestInterval();
        }
    }, []);

    useEffect(() => {
        lookupsByAccounts(filters, false, search );
    }, [filters['start_date'], filters['end_date']]);

    const lookupsByAccounts = useCallback( ( filters, sortBy = false, search = '', page = 1) => {
        let id = sortBy ? sortBy.id : '';
        let desc = sortBy ? sortBy.desc : '';
        axios.get("/catalogue/account/orderHistory?page=" + page + "&search=" + search + "&sort=" + id + "&desc=" + desc + "&start_date=" + filters['start_date'] + "&end_date=" + filters['end_date'])
            .then((response) => {
                if (response.data.success) {
                    setData(response.data.orders);
                    if(response.data.pages >= page) {
                        setPage(page);
                    }else {
                        setPage(response.data.pages);
                    }
                    setPages(response.data.pages);
                    setLoading(false);
                }
            });
    }, []);

    function setRequestInterval() {
        let timeInterval = setInterval(() => {
            lookupsByAccounts(filters);
        }, 60 * 1000 );

        setTimeInterval(timeInterval);
    }

    function clearRequestInterval() {
        clearInterval(timeInterval);
    }

    // function countOutstandingOrdersQty() {
    //     axios.get("/catalogue/account/orders/outstanding-orders-qty")
    //         .then((response) => {
    //             props.dispatch(updateOutstandingOrdersQty(response.data));
    //         })
    // }

    function handleChange(key, event) {
        let dateFilters = filters;
        let value = event.target.value;
        dateFilters[key] = (value != "all") ? value : null;
        setFilters(dateFilters);
    }

    function handleChangeDate(key, date) {
        let dateFilters = filters;
        dateFilters[key] = moment(date).format('YYYY-MM-DD');
        (key == 'start_date') ? setSelectedDate(date) : setSelectedEndDate(date);
        setFilters(dateFilters);
    }

    function handleIsCompletedChecked(order_id, is_completed) {
        let isCompleted = (is_completed == 1) ? 0 : 1;
        let orders = [...data];
        _.find(orders, {order_id: order_id}).is_completed = isCompleted;
        setData(orders);
        axios.put("catalogue/account/orders/status/" + order_id + '/' + isCompleted)
            .then((response) => {
                if (response.data.order.is_completed == 1) {
                    alertify.success(response.data.msg);
                } else {
                    alertify.error(response.data.msg);
                }
                axios.get("/catalogue/account/orderHistory?all=true")
                    .then((response) => {          
                        props.dispatch(setOrdersQty(response.data.orders.length));
                    });
                axios.get("/catalogue/account/reverseOrderHistory?all=true")
                    .then((response) => {          
                        props.dispatch(setCustomerOrdersQty(response.data.orders.length));
                    });
            });
    }

    return (
        <Fragment>
            <div className="content">
                <div className="container">
                    <div className="flex flex-justify-start mb-16">
                        <div>
                            <CustomDatePicker 
                                value={filters['start_date']} selected={selectedDate}
                                placeholder={t("Start Date")}
                                onChange={handleChangeDate.bind(this, "start_date")}
                                format={"DD-MM-YYYY"}>
                            </CustomDatePicker>
                        </div>
                        <div className="ml-8">
                            <CustomDatePicker
                                value={filters['end_date']} selected={selectedEndDate}
                                placeholder={t("End Date")}
                                onChange={handleChangeDate.bind(this, "end_date")}
                                format={"DD-MM-YYYY"}>
                            </CustomDatePicker>
                        </div>
                    </div>
                    <Block title={t("Orders List")} type="1">
                        <CustomReactTable columns={columns}
                                        hiddenColumns={props.auth?.user?.account?.business_type_id == 3 ? [] : ['is_completed']}
                                        pagination={true}
                                        page={page}
                                        pages={pages}
                                        getData={lookupsByAccounts.bind(this, filters)}
                                        setSearchToParent={setSearch}
                                        data={data}
                                        searchDisabled={false}
                                        dsiabledSort={false}/>
                    </Block>
                </div>
            </div>
            <CustomModal
                    show={showModal}
                    width={900}
                    onHide={() => { setShowModal(false) }}
                    title = {""}
                >
                <CustomContainer 
                        type={"withoutBg"}
                        name={""}
                        width={"100%"}
                        margins={['mb-4']}>
                            <CustomReactTable columns={columns}
                                data={data} 
                                pagination={true}
                                getData={() => {}}
                                page={page}
                                pages={pages} 
                            />
                </CustomContainer>
            </CustomModal>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(
    mapStateToProps,
    null
)(MyOrders);
