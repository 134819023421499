import React, { useState } from 'react'
import FormInput from "admin/administrator/FormInput/FormInput.component";
import { useTranslation } from 'react-i18next';

const CustomTableSearch = ({ search, setSearch }) => {
    const { t, i18n } = useTranslation();
    return ( 
        <FormInput
            type='text'
            placeholder={t('Search')}
            error={""}
            value={search}
            onChange={event => setSearch(event.target.value)}
        />
    );
}

export default CustomTableSearch;