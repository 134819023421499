import React, {Fragment} from "react";
import Select from 'react-select';

const SupplierBrands = ({ brands, selectedBrands, error, setSelectedBrands}) => {
    const handleChange = newValue => {
        const items = newValue.map((brand) => {
            return {id: brand.id, name: brand.name};
        })
        setSelectedBrands(items)
    };

    return (
        <Fragment>
            {/* <div className="mb-16 d-none">If you need to add brands that are not yet in the list below, please contact the data support team by emailing your requirements to 
                <a style={{textDecoration: 'underline'}}>data@parts-cat.co.uk</a>
            </div> */}
            <Select
                options={brands}
                isMulti
                closeMenuOnSelect={false}
                getOptionLabel={brand => brand.name}
                getOptionValue={brand => brand.id}
                onChange={handleChange}
                isDisabled={brands.length == 0}
                value={selectedBrands ? selectedBrands : []}
            />
            {
                error && ( <span className={"input__error"}>{error}</span> )
            }
        </Fragment>
    )
}

export default SupplierBrands;
