import React, {useEffect, useState, Fragment} from "react";
import SupplierDetails from "admin/administrator/account/SupplierDetails";
import CustomButton from "admin/administrator/CustomButton";
import {BrowserRouter as NavLink} from "react-router-dom";
import CustomStockFeed from 'admin/administrator/CustomStockFeed.js';
import ChooseFileAndUpload from 'components/ChooseFileAndUpload';
import alertify from 'alertifyjs';
import axios from "axios1";
import _ from "lodash";
import DownloadSamplePriceFile from "components/DownloadSamplePriceFile";
import ChangeSaveOrDiscardModal from 'admin/administrator/ChangeSaveOrDiscardModal/ChangeSaveOrDiscardModal';
import Block from "components/Block";
import Loading from "admin/administrator/LoadingComponent/Loading.component";
import { useTranslation } from 'react-i18next';

const AddSupplier = props => {
    const [supplier, setSupplier] = useState([]);
    const [brands, setBrands] = useState([]);
    const [selectedBrands, setSelectedBrands] = useState([]);;
    const [credentials, setCredentials] = useState([]);
    const [apis, setApis] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [apiId, setApiId] = useState(null);
    const [errors, setErrors] = useState({
        name: '',
        contact_name: '',
        address_1: '',
        town: '',
        phone: '',
        email: ''
    });
    const [types, setTypes] = useState({
        prefix: "",
        suffix: ""
    });
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [fileName, setFileName] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [fileSwitch, setFileSwitch] = useState(0);
    const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
    const [user, setUser] = useState({ login_email: "", password: ""});
    const [initialProducts, setInitialProducts] = useState([]);
    const [percentage, setPercentage] = useState(0);
    const [priceLevelValueTypeId, setPriceLevelValueTypeId] = useState(1);
    const { t, i18n } = useTranslation();

    var unblock = () => {};

    useEffect(() => {
        getSupplier();
    }, [])

    useEffect(() => {
        unblock();
    })

    useEffect(() => {
        if(!products.length && shouldBlockNavigation === false) {
            props.history.block(() => true)
        }
    }, [shouldBlockNavigation, products])

    useEffect(() => {
        if(products.length > 0) {
            unblock = props.history.block(() => {
                setShouldBlockNavigation(true);
                return false;
            });
        }
    }, [products])

    const getSupplier = () => {
        axios.get("catalogue/suppliers/create")
            .then((response) => {
                if (response.data.success) {
                    setSupplier(response.data.supplier || []);
                    setApis(response.data.apis);
                    setBrands(response.data.brands);
                    setLoading(false);
                }
            })
    }

    const handleSaveClick = () => {
        const newSupplier = supplier;
        newSupplier.prefix = types.prefix;
        newSupplier.suffix = types.suffix;
        const newSelectedBrands = _.map(selectedBrands, element => element.id);
        setLoading(true);
        axios.post("catalogue/suppliers", {
            supplier: newSupplier,
            user: user,
            apiId: apiId,
            credentials: credentials,
            selectedBrands: newSelectedBrands,
            products: products
        }).then((response) => {
                setLoading(false);
                let newErrors = response.data.errors ? response.data.errors : [];
                setErrors(newErrors);
                setLoading(false);
                if (response.data.success && !response.data.errors) {
                    alertify.success(response.data.message);
                    props.history.block(() => true);
                    props.history.push("/admin/accounts");
                } else {
                    let message = response.data && response.data.errors && response.data.errors.length > 0 ? response.data.errors.toString() : response.data.message;
                    alertify.error(message);
                    setShouldBlockNavigation(false);
                }
            })
    }

    alertify.defaults = {
        notifier: {
            position: 'top-right',
            delay: 5
        }
    }
    
    return (
        <Fragment>
            <Loading loading={loading}/>
            {
                shouldBlockNavigation &&
                    <ChangeSaveOrDiscardModal
                        saveChanges={handleSaveClick}
                        closeChangesModalDisregard={() => { 
                            setProducts([]);                             
                            setShouldBlockNavigation(false);
                            props.history.block(() => true);
                        }}
                        modalShow={shouldBlockNavigation}
                        closeChangesModal={() => setShouldBlockNavigation(false)}/>
            }
            <div className={"page-content admin-wrapper"}>
                <div className="grid">
                    <div className="grid__row">
                        <div className="grid__column grid__column--6">
                            <SupplierDetails setSupplier={setSupplier}
                                            apiId={apiId}
                                            brands={brands}
                                            supplier={supplier}
                                            selectedBrands={selectedBrands}
                                            setSelectedBrands={setSelectedBrands}
                                            setErrors={setErrors}
                                            errors={errors}
                                            types={types}/>
                        </div>
                        <div className="grid__column grid__column--6">
                            <Block title={t("supplier stock feed")} type={"1"}>
                                <CustomStockFeed apiId={apiId}
                                            apis={apis}
                                            setApiId={setApiId}
                                            setCredentials={setCredentials}
                                            errors={errors}
                                            showFtp={false}/>
                            </Block>
                        </div>
                    </div>
                    <div className="grid__row">
                        <div className="grid__column grid__column--6">
                            <Block title={t("pricing")} type={"1"}>
                                <DownloadSamplePriceFile/>
                                <ChooseFileAndUpload products={products}
                                            filteredProducts={filteredProducts}
                                            fileName={fileName}
                                            showModal={showModal}                                                
                                            setProducts={setProducts}
                                            setInitialProducts={setInitialProducts}
                                            setPercentage={setPercentage}
                                            setFileName={setFileName}
                                            setPriceLevelValueTypeId={setPriceLevelValueTypeId}
                                            supplier={supplier}
                                            switch={fileSwitch}/>
                            </Block>
                        </div>
                    </div>
                </div>
                <div className="page-actions">
                    <div className="page-actions__action">
                        <NavLink to={"/admin/client/dashboard"}>
                            <CustomButton title={t("Cancel")} type={"lightgray"}/>
                        </NavLink>
                    </div>
                    <div className="page-actions__action">
                        <CustomButton title={t("Save")} type={"green"} onClick={handleSaveClick}/>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default AddSupplier;