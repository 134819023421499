import React, {Component} from "react";
import AddToCartBtn from "components/AddToCartBtn";
import RequestPriceBtn from "components/RequestPriceBtn";
import AddToCompareBtn from "components/AddToCompareBtn";
import FullInfo from "components/FullInfo/Main";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import _ from "lodash";
import axios from "axios1";
import alertify from 'alertifyjs';
// import "components/SectionTable.css";
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';
import LostSale from "admin/administrator/LostSale/LostSale.component";
import pluralize from "pluralize";
import NoImage from "images/no-img.png";
import CustomButton from "../admin/administrator/CustomButton";
import RequestCallBtn from "./RequestCallBtn";
import { Fragment } from "react";
import Block from "components/Block";
import AxlesFilter from "components/AxlesFilter";
import { withTranslation } from 'react-i18next';
import i18n, { t } from 'i18next';
import { GridLoader } from 'react-spinners';

class SectionTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showFullInfoStockLoad: true,
            showFullInfo: {},
            showAltProducts: {},
            lostSale: {},
            columns: 0,
            lostReason: [
                {id: 1, title: t("Associated part out of stock")},
                {id: 2, title: t("Price too expensive")},
                {id: 3, title: t("Brand reject")},
            ],

            showlostSale: {},
            lostSales: [],
        };

        this.showFullInfo = {}
        this.showAltProducts = {}
    }

    componentDidMount() {
        this.countTableColumns();
        document.body.addEventListener('click', (e) => {
            if( document.contains(document.getElementsByClassName('lost-sale__list')[0]) && (document.getElementsByClassName('lost-sale__list')[0].contains(e.target) || document.getElementsByClassName('lost-sale__btn')[0].contains(e.target))) {
                return;
            } else {
                let showlostSale = {...this.state.showlostSale};
                showlostSale = _.map(showlostSale, function (value) {
                    if (value) {
                        value = false;
                        return value;
                    }
                });
                this.setState({showlostSale: false});
            }
        });
    }

    initAdditionalElements() {
        var additionalElements = this.props.settings.additional_elements != "" ? this.props.settings.additional_elements.split(',') : [];
        var hideForPartNumber = this.props.searchResult.hideForPartNumber;
        if (hideForPartNumber) {
            additionalElements = _.filter(additionalElements, function (element) {
                return (element != 'notes') ? true : false;
            });
            var baseWidtchForAdditional = 23.5;
        }

        var len = 0;
        var additionalElementsArray = additionalElements ? _.map({...additionalElements}, function (element) {
            var width = 10;
            if (element == 'notes') {
                width = 25;
            }
            len += width;
            return {name: element, width: width};
        }) : [];
        if (this.props.products) {
            var data = Array.from(this.props.products);
            window.filterProp = "position";
            var positionShow = true;
        }
        return {
            additional_elements: additionalElements,
            additionalElementsArray: additionalElementsArray,
            len: len,
            positionShow: positionShow
        };
    }

    addDefaultSrc(event) {  
        event.target.setAttribute("src", NoImage);
    }

    handleShowFullInfo(productId) {
        this.showAltProducts[productId] = false;
        this.showFullInfo[productId] = !this.showFullInfo[productId];
        this.getFullInfoStock(productId);
    }

    getStockByPartNumber(currentProduct) {
        let stockIds = [];
        let stockArray = JSON.parse(JSON.stringify(currentProduct.stock))
        stockArray.shift();
        stockIds = this.sortStockIds(stockArray, stockIds, currentProduct);
        return axios.post("catalogue/search/stock", { accounts: stockIds });
    }


    sortStockIds(stock, stockIds, currentProduct) {
        _.map(stock, function (element) {
            if (element.quantity === null) {
                // stockIds.push(element.id);
                stockIds.push({
                    part_number_id: currentProduct.part_number_id,
                    // account_id: element.account_id
                });
            }
        })
        return stockIds;
    }

    getFullInfoStock(productId) {
        let products = this.props.products;
        let currentProduct = _.find(products[this.props.section], {part_number_id: productId});
        // if (currentProduct && currentProduct.stock && currentProduct.stock.length > 1) {
        if (currentProduct) {
            this.getStockByPartNumber(currentProduct).then((partNumbersResponse) => {
                if (partNumbersResponse.data.hasOwnProperty('success') && partNumbersResponse.data.success) {
                    let productIds = partNumbersResponse.data.productIds;
                    currentProduct = _.map(currentProduct.stock, function (element) {
                        // let currentAccount =  _.find(productIds, {account_id: element.account_id })
                        
                        // if(currentAccount) {
                        //     element.quantity = currentAccount.stock;
                        // }

                        return element;
                    })
                    
                    this.setState({
                        showFullInfoStockLoad: true,
                        showAltProducts: this.showAltProducts,
                        showFullInfoStockLoad: false,
                        showFullInfo: this.showFullInfo
                    });
                }
            })
        }
    }

    handleShowAltProducts(productId) {
        this.showFullInfo[productId] = false;
        this.showAltProducts[productId] = !this.showAltProducts[productId];
        this.setState({showAltProducts: this.showAltProducts, showFullInfo: this.showFullInfo});
    }

    handleImageClick(click) {
        const viewer = new Viewer(click.target, {
            backdrop: true,
            title: false,
            zIndex: 100000,
            hidden: function () {
                viewer.destroy();
            }
        });
        viewer.show();
    }

    lostSaleClick(product_price_id) {
        let lostSales = [];
        let showlostSale = {...this.state.showlostSale};
        axios.get("/catalogue/lost-sales?product_price_id=" + product_price_id)
            .then((response) => {
                if (response.data.success) {
                    showlostSale = this.showLostSale(showlostSale, product_price_id);
                    lostSales = response.data.lostSales;
                    this.setState({lostSales: lostSales, showlostSale: showlostSale});
                } else {
                    alertify.error(response.data.msg);
                }
                //window.scrollTo(0, 0)
            });
    }

    showLostSale(showlostSale, product_price_id) {
        if (showlostSale[product_price_id]) {
            showlostSale[product_price_id] = !showlostSale[product_price_id];
        } else {
            showlostSale = _.map(showlostSale, function (value) {
                value = false;
                return value;
            })
            showlostSale[product_price_id] = true;
        }
        return showlostSale;
    }

    handleOptionChange( reason_id, product_price_id, comment = '' ) {
        let lostSales = {...this.state.lostSales};

        let showlostSale = {...this.state.showlostSale};

        if (lostSales) {
            lostSales = _.map(lostSales, function (element) {
                if (element.id == reason_id) {
                    element.checked = !element.checked;
                }
                return element;
            });
            axios.post("/catalogue/lost-sales", {lost_reason_id: reason_id, product_price_id: product_price_id, comment: comment})
                .then((response) => {
                    if (!response.data.success) {
                        alertify.error(response.data.message);
                    }
                });
        }
        // showlostSale = this.showLostSale(showlostSale, product_price_id);
        this.setState({
            lostSales: lostSales,
            // showlostSale: showlostSale
        });
    }

    initProductsByPosition() {
        let products = JSON.parse(JSON.stringify(this.props.products));
        let selectedAxlesSection = this.props.searchResult.selectedAxlesSection;

        let productsByPosition = [];
        let filteredData = [];
        let allProducts = [];
        let front = [];
        let rear = [];
        
        _.map(products[this.props.section], element => {
            if (element.position) {
                if (element.position.toLowerCase().includes("rear")) {
                    rear.push(element);
                } else {
                    front.push(element);
                }
            }else {
                allProducts.push(element);
            }
        })

        if (selectedAxlesSection[this.props.section]) {
            if(rear.length > 0) {
                rear = _.filter(rear, product => {
                    let includesAll = true;
                    _.map(selectedAxlesSection[this.props.section]['Rear'], (element, key) => { 
                        if (element && !product.position.toLowerCase().includes(key)) {
                            includesAll = false;
                        }
                    });
                    return includesAll; 
                })
            }

            if(front.length > 0) {
                front = _.filter(front, product => {

                    let includesAll = true;
                    _.map(selectedAxlesSection[this.props.section]['Front'], (element, key) => { 

                        if (element && !product.position.toLowerCase().includes(key)) {
                            includesAll = false;
                        }
                    });

                    return includesAll; 
                })
            }
        }

        if (front.length > 0) {
            front = _.orderBy(front, function (element) {
                return element.position.toLowerCase().includes("left") ? false : true;
            });
            front = _.orderBy(front, item => item.part_number.part_number, ['asc']);
            productsByPosition.push({name: t("Front"), value: front})
        
        }
        if (rear.length > 0) {
            rear = _.orderBy(rear, function (element) {
                return element.position.toLowerCase().includes("left") ? false : true;
            });
            rear = _.orderBy(rear, item => item.part_number.part_number, ['asc']);
            productsByPosition.push({name: t("Rear"), value: rear})
        }

        if ((front.length == 0 && rear.length == 0) || allProducts.length > 0) {
            allProducts = _.orderBy(allProducts, item => item.part_number.part_number, ['asc']);
            productsByPosition.push({name: "", value: allProducts})
        }

        return productsByPosition[0] && productsByPosition[0].value.length > 0 ? productsByPosition : [];        
    }

    ucFirstAllWords(str, name) {
        var pieces = "";
        if (str) {
            pieces = str.split(" ");
            for (var i = 0; i < pieces.length; i++) {
                var j = pieces[i].charAt(0).toUpperCase();
                pieces[i] = j + pieces[i].substr(1).toLowerCase();
            }
            pieces = pieces.join(" ");
        }
        return pieces.replace(name, '');
    }

    generateStockQuantity(product, stockLoad, stock_visibility) {
        return stockLoad ? <div className='sweet-loading'>
        <GridLoader
            sizeUnit={"px"}
            size={5}
            color={'#63666a'}
            loading={this.state.loading}
        />

        </div> : ( stock_visibility === "quantity" ? (product.SalesUnit ? product.quantity + ' | ' + product.SalesUnit : product.quantity) : (product.quantity > 0 ? t("In Stock") : t("Out of Stock")));
    }
    
    generateStockRetailPrice(product, stockLoad) {
        return stockLoad ? <div className='sweet-loading'>
                <GridLoader
                    sizeUnit={"px"}
                    size={5}
                    color={'#63666a'}
                    loading={this.state.loading}
                />
                </div> : (isNaN(parseFloat(product.netGrossPrice).toFixed(2)) ? '' : parseFloat(product.netGrossPrice).toFixed(2));
    }

    generateStockDepositPrice(product, stockLoad) {
        return stockLoad ? <div className='sweet-loading'>
                <GridLoader
                    sizeUnit={"px"}
                    size={5}
                    color={'#63666a'}
                    loading={this.state.loading}
                />
                </div> : (isNaN(parseFloat(product.depositPrice).toFixed(2)) ? '' : parseFloat(product.depositPrice).toFixed(2));
    }

    generateStockPrice(product, stockLoad) {
        if(stockLoad) {
            return <div className='sweet-loading'>
                <GridLoader
                    sizeUnit={"px"}
                    size={5}
                    color={'#63666a'}
                    loading={this.state.loading}
                />
            </div>;
        }else {
            if(product.price) {
                return <AddToCartBtn lookup={this.props.lookup}
                        product={product} alternatives={0}
                        section_lookup_id={this.props.section_lookup_id}/>
            }else {
                return <RequestPriceBtn partnumber={product?.part_number?.part_number}/>
            }
        }
    }

    countTableColumns() {
        this.setState({columns: document.getElementById('products-table').rows[0].cells.length});
    }

    render() {
        let stockLoad = this.props.stockLoad;
        let baseWidth = this.props.searchResult.hideForPartNumber ? 15 : 0;
        let actionWidth = baseWidth > 0 ? 35 : 25;
        let products = this.props.products;
        let productsByPosition = this.initProductsByPosition();
        var visibleElements = this.initAdditionalElements();
        let lostSales = _.map(this.state.lostSales, function (element) {
            return element;
        });
        let stock_visibility = this.props.settings.stock_visibility;
        let len = (stock_visibility != "hidden" ? visibleElements.len + 5 : visibleElements.len);
        let additionalElementsArray = visibleElements.additionalElementsArray;
        let table_cell_width = ((70 + baseWidth) - len) / ((visibleElements.positionShow ? 1 : 0) + 1);
        return (
            <Fragment>
                {
                    Object.keys(products).length > 0 &&
                        _.map(productsByPosition, product_item => {
                            return <Block title={this.props.section + " " + t('position_' + product_item.name)} type="1" style={{marginBottom: "16px"}}>
                                { !this.props.searchResult.hideForPartNumber && <AxlesFilter products={products} name={product_item.name} section={this.props.section} /> }
                                <div className="table table--type-2">
                                    <table className="table__table" id="products-table">
                                        <thead className="table__thead">
                                            <tr className="table__tr">
                                                <th className="table__th">{t("Brand")}</th>
                                                <th className="table__th textLeft">{t("Part Number")}</th>
                                                {
                                                    visibleElements.positionShow && !this.props.searchResult.hideForPartNumber &&
                                                        <th className="table__th">{t("Position")}</th>
                                                }
                                                {
                                                    additionalElementsArray.map((element) => {
                                                        return <th className={"table__th textLeft"}>{t(element.name)}</th>
                                                    })
                                                }
                                                {
                                                    this.props.settings.show_full_info ?
                                                        <th className="table__th">{t("Full Info")}</th> : null
                                                }
                                                {
                                                    stock_visibility != "hidden" &&
                                                        <th className="table__th textRight">{t("Stock")}</th>
                                                }
                                                <th className="table__th">Core Charge</th>
                                                <th className="table__th">Retail price</th>
                                                <th className="table__th">{t("Action")}</th>
                                            </tr>
                                        </thead>
                                        
                                            {
                                                _.map(product_item.value, (product, index) => {
                                                    // product.branch_id = product?.stock[0]?.account_id;
                                                    // product.defaultStockQty = product?.stock[0]?.quantity;
                                                    let isCollapsed = this.state.showFullInfo[product.part_number_id] ? "table__tbody--collapsed" : this.state.showAltProducts[product.part_number_id] ? "table__tbody--collapsed" : "";
                                                    
                                                    return  <tbody className={"table__tbody " + isCollapsed}>
                                                        <tr className={"table__tr"}>
                                                                <td className="table__td">
                                                                    {
                                                                        !this.props.settings.show_brand_logos ? product.part_number.brand.name : product.part_number.brand.image ?
                                                                            <img src={product.part_number.brand.image} width="54px"/> : 
                                                                                <span>{product.part_number.brand.name}</span>
                                                                    }
                                                                </td>
                                                                <td className="table__td textLeft">{product.part_number.part_number}</td>
                                                                {
                                                                    (visibleElements.positionShow && !this.props.searchResult.hideForPartNumber) &&
                                                                        <td className={"table__td"}>
                                                                            {
                                                                                this.ucFirstAllWords(product.position, product_item.name)
                                                                            }
                                                                        </td>
                                                                }
                                                                {
                                                                    additionalElementsArray.length > 0 ?
                                                                        additionalElementsArray.map((element) => {
                                                                            let warrantyVallue = product.part_number.warranty ? product.part_number.warranty.value : "";
                                                                            let notesWidth = element.name === "notes" ? "220px" : "auto";
                                                                            return <td className={"table__td textLeft " + element.name}>
                                                                                        {element.name === "warranty" ? warrantyVallue : element.name === "notes" ? 
                                                                                            <div style={{width: notesWidth, wordWrap: "break-word"}}>{product.part_number.fitmentNote}</div> : 
                                                                                            (element.name === "images" && product.part_number.allImages[0]) ?
                                                                                                <img src={product.part_number.allImages[0].url}
                                                                                                    width="54px"
                                                                                                    alt={product.part_number.id}
                                                                                                    onError={(e) => this.addDefaultSrc(e, product.part_number.allImages[0])}                                                                                                    
                                                                                                    onClick={this.handleImageClick}/> :
                                                                                                    (element.name === "images" && !product.part_number.allImages[0]) ? <img src={NoImage} width={"54px"} alt={t("Not Found")}/> : null}
                                                                                    </td>
                                                                        }) : null
                                                                }
                                                                {
                                                                    this.props.settings.show_full_info ?
                                                                        <td className="table__td">
                                                                            {
                                                                                // product.stock?.length > 1 &&
                                                                                    <CustomButton type={"lightgray"}
                                                                                            title={this.state.showFullInfo[product.part_number_id] ? t("Hide") : t("Show")}
                                                                                            onClick={this.handleShowFullInfo.bind(this, product.part_number_id)}
                                                                                            // style={{width: "70px"}}
                                                                                            />
                                                                            }                                                                            
                                                                        </td>
                                                                    : null
                                                                }
                                                                {
                                                                    stock_visibility == "hidden" ? null : <td className={"table__td"}>{ this.generateStockQuantity(product, stockLoad, stock_visibility) }</td>
                                                                }
                                                                {
                                                                    
                                                                    <td className={"table__td"}>{this.generateStockDepositPrice(product, stockLoad)}</td>
                                                                    // <td className={"table__td"}>{ (Number(product.rfand) && product.rfand !== 'No') ? 'YES' : '' }</td>
                                                                }
                                                                {
                                                                    // <td className={"table__td"}>{ (product?.pricetype?.toUpperCase() === 'SET') ? `SET - ${product.retailprice}` : product.retailprice }</td>
                                                                    <td className={"table__td"}>{ this.generateStockRetailPrice(product, stockLoad) }</td>                                                                    
                                                                }
                                                                <td className="table__td" style={{width: "300px"}}>
								{
								    !parseInt(product?.part_number?.part_num_status) ? 
                                                                        <div className="flex flex-justify-center">
                                                                            No Longer Available
                                                                        </div>
                                                                    :<div className="flex flex-justify-center">
                                                                        {
                                                                            (this.props.settings.lost_sale && product.product_price_id) ?
                                                                                <LostSale 
                                                                                    product={ product }
                                                                                    lostSales={ lostSales }
                                                                                    showlostSale={ this.state.showlostSale }
                                                                                    lostSaleClick={ this.lostSaleClick.bind(this) }
                                                                                    handleOptionChange={ this.handleOptionChange.bind(this)}/>
                                                                            : null
                                                                        }
                                                                        {
                                                                            this.props.settings.enable_shopping_cart ? (
                                                                                this.generateStockPrice(product, stockLoad)                                                                                
                                                                            ) : null
                                                                        }
                                                                        {
                                                                            <AddToCompareBtn product={product} section_lookup_id={this.props.section_lookup_id}/>
                                                                        }
                                                                        {
                                                                            this.props.user && !([3, 9, 11].includes(this.props.user.account.business_type_id)) && this.props.settings.enable_request_call_btn ? <RequestCallBtn product={ product }/> : null
                                                                        }
                                                                    </div>
								}
                                                                </td>
                                                            </tr>
                                                            {
                                                                this.state.showFullInfo[product.part_number_id] &&
                                                                    <FullInfo handleHideFullInfo={this.handleShowFullInfo.bind(this)}
                                                                            product={product}
                                                                            shoppingCart={this.props.settings.enable_shopping_cart}
                                                                            lookup={this.props.lookup}
                                                                            stockLoad={this.state.showFullInfoStockLoad}
                                                                            section_lookup_id={this.props.section_lookup_id}
                                                                            columns={this.state.columns}/>
                                                            }
                                                    </tbody>
                                                })
                                            }
                                    </table>
                                </div>
                            </Block>
                        })
                }
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    settings: state.settings,
    searchResult: state.searchResult,
    user: state.auth.user
})

const mapDispatchToProps = dispatch => {
    return {
        saveSearchResult: (key, value) => dispatch({
            type: 'SAVE_SEARCH_RESULTS',
            payload: {key, value}
        })
    }
}

export default withTranslation()(withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SectionTable)));
