import React, {Component} from "react";
// import "admin/administrator/TopAccounts/TopAccounts.css";
// import "admin/administrator/CustomElements.css";
import CustomPieChart from "components/Charts/CustomPieChart.js";
import CustomPagination from "admin/administrator/CustomPagination";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {GridLoader} from 'react-spinners';
import axios from "axios1";
import {BrowserRouter as Router, Route, NavLink} from "react-router-dom";
import {Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup} from "react-bootstrap";
import CustomPieChart2d from "components/Charts/CustomPieChart2d";
import CustomDatePicker from 'admin/administrator/CustomDatePicker';
import { connect } from "react-redux";
import {selectTopAccountMonth} from "redux/actions/statisticActions";

var moment = require('moment');

class SalesTotalsByUser extends Component {

    constructor(props) {
        super(props);

        this.state = {
            accountId: null,
            loading: false,
            logins: [],
            filters: {
                month: "",
            },
            selectedDate: new Date(),
            page: 1,
            pages: 0,
            dataLabelsForChart: [],
            dataValuesForChart: []
        }
    }

    componentDidMount() {
        this.initMonth('month');
        this.getLogins(this.state.page);
    }

    handleChangeDate(key, month) {
        let filters = this.state.filters;
        filters[key] = moment(month).format('YYYY-MM');
        this.props.dispatch(selectTopAccountMonth(filters[key]))
        this.setState({filters: filters, selectedDate: month}, () => {
            this.getLogins();
        });
    }

    getLogins(page = 1) {
        let data = this.state.filters;
        this.setState({loading: true});
        axios.get("/catalogue/sales-by-accounts/" + this.props.match.params.id + "?page=" + page, {params: data})
            .then((response) => {
                let dataLabelsForChart = [];
                let dataValuesForChart = [];
                response.data.accounts.map((account) => {
                    dataLabelsForChart.push(account.name);
                    dataValuesForChart.push(account.price);
                });
                this.setState({
                    logins: response.data.accounts,
                    loading: false,
                    pages: response.data.pages,
                    page: page,
                    dataLabelsForChart: dataLabelsForChart,
                    dataValuesForChart: dataValuesForChart,
                });
            })
    }
    
    initMonth(key = 'month') {
        var month = this.props.topAccountsMonth ? moment(this.props.topAccountsMonth).toDate() : this.state.selectedDate.month;
        var selectedDate = this.state.selectedDate;
        selectedDate.month = month;
        var filters = this.state.filters;
        filters[key] = moment(month).format('YYYY-MM');
        this.props.dispatch(selectTopAccountMonth(filters[key]))
        this.setState({selectedDate: selectedDate, filters: filters});
    }

    render() {
        return (
            <div className={"page-content admin-wrapper"}>
                <Container fluid={true}>
                    <Row className="show-grid">
                        <Col md={12}>
                            <div className={"page-title-wrapper"}>
                                <span>sales totals by user</span>
                                <button onClick={() => this.props.history.goBack()} className="go-back">
                                    <i className="fas fa-reply"></i> Back
                                </button>
                            </div>
                        </Col>
                    </Row>
                    <Row className="show-grid">
                        <Col md={12}>
                            <div className={"custom-filter"}>
                                <div className={"filter-group"}>
                                    <label>Month</label>
                                    <CustomDatePicker value={this.state.filters['month']} selected={this.state.selectedDate.month} placeholder={"Month"} onChange={this.handleChangeDate.bind(this, "month")} format={"MMMM YYYY"} showMonthYearPicker={true} ></CustomDatePicker>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="show-grid">
                        <Col md={6}>
                            {
                                this.state.logins.length > 0 ?
                                    <table className={"custom-table table table-bordered"}>
                                        <thead>
                                        <tr>
                                            <th>login</th>
                                            <th>sales</th>
                                            <th>info</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.logins.map((login, index) => {
                                                return <tr key={index}>
                                                    <td>{login.name}</td>
                                                    <td>{login.price}</td>
                                                    <td>
                                                        <NavLink to={"/admin/top-accounts/sales/" + login.id} exact>
                                                            <button className={"custom-btn"}>Details</button>
                                                        </NavLink>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                        </tbody>
                                    </table> : null
                            }
                            {
                                this.state.pages > 0 ?
                                    <CustomPagination
                                        page={this.state.page}
                                        pages={this.state.pages}
                                        sendRequest={this.getLogins.bind(this)}
                                    /> : null
                            }
                        </Col>
                        <Col md={6}>
                            <div className={"top-accounts-chart"}>
                                <CustomPieChart2d labels={this.state.dataLabelsForChart}
                                                  values={this.state.dataValuesForChart}/>
                            </div>
                        </Col>
                    </Row>
                </Container>
                {
                    this.state.loading ?
                        <div className='sweet-loading'>
                            <GridLoader
                                sizeUnit={"px"}
                                size={15}
                                color={'#0066b8'}
                                loading={this.state.loading}
                            />
                        </div> : null
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    topAccountsMonth: state.statistic.topAccountsMonth
})

export default connect(mapStateToProps)(SalesTotalsByUser);