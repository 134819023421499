import React, { Component } from "react";
import axios from "axios1";
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-labels'
// import "admin/administrator/salesDetails.css";

export default class salesDetails extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            sales: {
                datasets:[{
                    data: [],
                    label: 'Sold',
                    backgroundColor: 'rgba(233, 30, 30, 0.5)',
                    borderColor: 'rgba(233, 30, 30, 1)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(233, 30, 30, 0.7)',
                    hoverBorderColor: 'rgba(233, 30, 30, 1)',
                }],
                labels:[]
            },
            filteredSales: {
                datasets:[{
                    data: [],
                    label: 'Sold',
                    backgroundColor: 'rgba(233, 30, 30, 0.5)',
                    borderColor: 'rgba(233, 30, 30, 1)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(233, 30, 30, 0.7)',
                    hoverBorderColor: 'rgba(233, 30, 30, 1)',
                }],
                labels:[]
            }
        }
    }

    componentDidMount() {
        this.getTopSales().then((response) => {
            this.filterByTopQty(10);
        });
    }

    filterByTopQty(value) {
        let salesData = [...this.state.sales.datasets[0].data];
        let salesLabels = [...this.state.sales.labels];
        let filteredSales = {
            datasets:[{
                data: [],
                label: 'Sold',
                backgroundColor: 'rgba(4, 129, 224, 0.5)',
                borderColor: 'rgba(4, 129, 224, 1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(4, 129, 224, 0.7)',
                hoverBorderColor: 'rgba(4, 129, 224, 1)',
            }],
            labels:[]
        }

        salesData.length = value;
        salesLabels.length = value;

        filteredSales.datasets[0].data = salesData;
        filteredSales.labels = salesLabels;

        this.setState({filteredSales: filteredSales});
    }

    getTopSales() {
        return axios.get("/catalogue/products/topSales")
                .then((response) => {
                    let salesCount = [];
                    let products = [];
                    Object.keys(response.data).map((key) => {
                        products.push(response.data[key].product.part_number.part_number);
                        salesCount.push(response.data[key].count);
                    })

                    let sales = {
                        labels: products,
                        datasets: [{
                            data: salesCount,
                            label: 'Sold',
                            backgroundColor: 'rgba(4, 129, 224, 0.5)',
                            borderColor: 'rgba(4, 129, 224, 1)',
                            borderWidth: 1,
                            hoverBackgroundColor: 'rgba(4, 129, 224, 0.7)',
                            hoverBorderColor: 'rgba(4, 129, 224, 1)',
                        }]
                    }

                    this.setState({sales: sales})
                })
    }

    render() {

        const data = this.state.filteredSales.datasets[0].data.length > 0 ? this.state.filteredSales : this.state.sales;

        return (
            <div className={"dashboard-page-wrapper"}>
                <div className={"dashboard-page-navigations"}>
                    <div>
                        <i className="fas fa-chart-line"></i>
                        <span>Top Sales</span>
                    </div>
                    <div>
                        <button className="back-btn" onClick={() => this.props.history.goBack()}>
                            <i className="fas fa-reply"></i>
                        </button>
                    </div>
                </div>
                <div className={"dashboard-page-body"}>
                    <div>
                        <select className="top-qty-ddl" onChange={(e) => this.filterByTopQty(e.target.value)}>
                            <option value="20">Top 20</option>
                            <option value="10" selected>Top 10</option>
                            <option value="5">Top 5</option>
                            <option value="3">Top 3</option>
                        </select>
                    </div>
                    <Bar
                        data={data}
                        options={{
                            plugins: {
                                labels: [
                                    {
                                        render: 'value',
                                        fontColor: '#e8e8e8',
                                    }
                                ]
                            },
                            animation: {
                                easing: 'linear'
                            },
                            scales: {
                                scaleShowGridLines: false,
                                scaleShowVerticalLines: false,
                                yAxes: [{
                                    gridLines: {
                                        display: false,
                                        color: '#5c676b'
                                    },
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Quantity',
                                        fontColor: '#8e8e8e',
                                        fontSize: 14,
                                    },
                                    ticks: {
                                        fontColor: '#5c676b',
                                        min: 0,
                                        stepSize: 3,
                                    },
                                }],
                                xAxes: [{
                                    gridLines: {
                                        display: false,
                                        color: '#5c676b'
                                    },
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Part Number',
                                        fontColor: '#8e8e8e',
                                        fontSize: 14
                                    },
                                    ticks: {
                                        fontColor: '#5c676b',
                                    }
                                }],
                            }
                        }}
                        redraw
                    />
                </div>
            </div>
        )
    }
}