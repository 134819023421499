import React from 'react';
// import "./PreferencesFormItem.style.css";
import CustomSwitch from "admin/administrator/CustomSwitch";
import CustomSortable from "admin/administrator/CustomSortable";
import FormSelect from "admin/administrator/FormSelect/FormSelect.component"

const PreferencesFormItem = ({ label, title, value, handleChange, options, sortables, handleOrder }) => (
    <div className={`flex mt-8 ${sortables ? 'direction-column' : ''}` }>
        <div>{label}</div>
        {
            options ?
                <FormSelect 
                    handleChange= {event => handleChange(event.target.value, title)}
                    options= { options }
                    selectedId= { value } 
                />
            : (
                sortables ? 
                <div>
                    <CustomSortable
                            items={ value }
                            disabled={ false }
                            callBack={ items => handleOrder(items) }
                            onChangeCheckBox={ (property, event) => handleChange(event.target.checked, title, property) }
                    />
                </div>
                :
                <CustomSwitch
                    checked={ parseInt(value) }
                    onChange={ event => handleChange( event, title ) }
                />
            )           
        }
    </div>
);

export default PreferencesFormItem;