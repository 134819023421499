import React from "react";
import Layout from "account/Orders/ReturnForm/Components/Layout";
import _ from "lodash";
import "account/Orders/ReturnForm/Forms/ReturnFormPDF.css";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const ReturnFormPDF =  React.forwardRef((props, ref) => {
    const { t, i18n } = useTranslation();
    const tableRowCount = 7; 
    let date = new Date().toLocaleString({timeZone: 'Europe/London'}, {
        month:"2-digit", day: "numeric", year: "numeric", hour: "2-digit", minute: "2-digit"
    });
    const local = {
        'DEU': 'de',
        'DNK': 'da',
        'FRA': 'fr'
    };
    return (
        <Layout ref={ref}>
            <div className={`pdf-section`}>
                <div className="pdf-card">
                    <div className="pdf-mg-bottom-25">{date}</div>

                    {/* <div className="pdf-card-top pdf-mg-bottom-25">
                        <div className="pdf-flex-centred pdf-relative">
                            <p className="pdf-card-badge">{t("return_form_agreed")}</p>
                            <h3>{t("return_form_return")}</h3>
                        </div>
                        <p>{t("return_form_delivery")}</p>
                    </div>
                    <div className="pdf-card-body">
                        <div className="pdf-form-input"><p className="pdf-font-size-sm">{t("return_form_tick_appropriate")}</p></div>
                        <div className="pdf-form-input pdf-flex"><p>1. {t("return_form_night_express")}</p> <input type="checkbox" className="pdf-checkbox"/> </div>
                        <div className="pdf-form-input pdf-flex"><p>2. {t("return_form_carrier")}</p> <input type="checkbox" className="pdf-checkbox"/> </div>
                        <div className="pdf-form-input pdf-flex"><p>3. {t("return_form_own_choice")}</p> <input type="checkbox" className="pdf-checkbox"/> </div>
                    </div> */}
                    <div className="pdf-card-footer pdf-flex">
                        {/* <h4>{t("return_form_recipient")}</h4> */}
                        <ul>
                            <li>{i18next.t('return_form_address_1', { lng:  local[props.account?.areaid] ?? 'en' })}</li>
                            <li>{i18next.t('return_form_address_2', { lng:  local[props.account?.areaid] ?? 'en' })}</li>
                            <li>{i18next.t('return_form_address_3', { lng:  local[props.account?.areaid] ?? 'en' })}</li>
                            <li>{i18next.t('return_form_address_4', { lng:  local[props.account?.areaid] ?? 'en' })}</li>
                            <li>{i18next.t('return_form_address_5', { lng:  local[props.account?.areaid] ?? 'en' })}</li>
                        </ul>
                    </div>
                </div>
                <div className="pdf-card">
                    <p className="pdf-mg-bottom-25">{t("return_form_warranty_garantie")}</p>
                    <div className="pdf-flex">
                        <div className="pdf-card-left">
                            <div className="pdf-form-input pdf-flex"><p className="pdf-bold">{t("return_form_warranty_phone")}</p></div>
                            {
                                props.account?.areaid === 'DEU' && <div className="pdf-form-input pdf-flex"><p className="pdf-bold">{t("return_form_warranty_fax")}</p></div>
                            }
                            <div className="pdf-form-input pdf-flex"><p className="pdf-bold">{i18next.t('return_form_warranty_mail', { lng:  local[props.account?.areaid] ?? 'en' })}</p></div>
                        </div>
                        <div className="pdf-card-right">
                            <img src="https://nk2.availcat.com/static/media/nk-logo.00bc6c05.png" width={123} height={80}></img>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pdf-section pdf-mg-top">
                <div className="pdf-card">
                    <div className="pdf-card-left pdf-mg-bottom-25">
                        <h3>{t("return_form_warranty_guarantee_warranty_request")}</h3>
                    </div>
                    <div className="pdf-list-warpper pdf-mg-top pdf-mg-bottom-25">
                        <div className="pdf-form-input pdf-flex-verticaly-centred"><p className="pdf-bold">{t("return_form_warranty_return_reference")}</p><input style={{height: '30px'}} type="text"/></div>
                        <div className="pdf-form-input pdf-flex-verticaly-centred"><p className="pdf-bold">{t("return_form_warranty_customer_number")}</p><input style={{height: '30px', width: '50%'}} value={props.account?.account_number} type="text"/></div>
                        <div className="pdf-form-input pdf-flex-verticaly-centred"><p className="pdf-bold">{t("return_form_warranty_company_name")}</p><input style={{height: '30px', width: '50%'}} value={props.account?.name}  type="text"/></div>

                        <div className="pdf-form-input pdf-flex-verticaly-centred"><p className="pdf-bold">{t("return_form_warranty_gaseworker")}</p><input style={{height: '30px'}} type="text"/></div>
                        <div className="pdf-form-input pdf-flex-verticaly-centred"><p className="pdf-bold">{t("return_form_warranty_your_casenumber")}</p><input style={{height: '30px'}} type="text"/></div>
                        <div className="pdf-form-input pdf-flex-verticaly-centred"><p className="pdf-bold">{t("return_form_warranty_invoice_ref")}</p><input style={{height: '30px'}} type="text"/></div>
                        <div className="pdf-form-input pdf-flex-verticaly-centred"><p className="pdf-bold">{t("return_form_warranty_item_number")}</p><input style={{height: '30px'}} type="text"/></div>
                        <div className="pdf-form-input pdf-flex-verticaly-centred"><p className="pdf-bold">{t("return_form_warranty_item_type")}</p><input style={{height: '30px'}} type="text"/></div>
                        <div className="pdf-form-input pdf-flex-verticaly-centred"><p className="pdf-bold">{t("return_form_warranty_installation_date")}</p><input style={{height: '30px'}} type="text"/></div>                        
                        <div className="pdf-form-input pdf-flex-verticaly-centred"><p className="pdf-bold">{t("return_form_warranty_car_make_model")}</p><textarea style={{height: '30px',width: "50%"}}></textarea></div>
                        <div className="pdf-form-input pdf-flex-verticaly-centred"><p className="pdf-bold">{t("return_form_warranty_registration_date")}</p><input style={{height: '30px'}} type="text"/></div>
                        <div className="pdf-form-input pdf-flex-verticaly-centred"><p className="pdf-bold">{t("return_form_warranty_reason_for_request")}</p><textarea style={{height: '30px', width: "50%"}}></textarea></div>
                        <div className="pdf-form-input pdf-flex-verticaly-centred"><p className="pdf-bold">{t("return_form_warranty_attachment")}</p><input style={{height: '30px'}} type="text"/></div>
                        <div className="pdf-form-input pdf-flex-verticaly-centred"><p className="pdf-bold">{t("return_form_warranty_date")}</p><input style={{height: '30px'}} type="text"/></div>
                    </div>
                </div>
                <div className="pdf-card">
                    <div className="pdf-card-left pdf-mg-bottom-25">
                        <h4>{i18next.t('return_form_warranty_completed_by_sbs_germany', { lng:  local[props.account?.areaid] ?? 'en' })}</h4>
                    </div>
                    <div className="pdf-flex-verticaly-centred not-editable-box"><p className="pdf-bold">{t("return_form_warranty_warranty_number")}</p></div>
                    <div className="pdf-flex-verticaly-centred not-editable-box"><p className="pdf-bold">{t("return_form_warranty_credit_note")}</p></div>
                    <div className="pdf-flex-verticaly-centred not-editable-box"><p className="pdf-bold">{t("return_form_warranty_gl")}</p></div>
                    <div className="pdf-form-input pdf-flex-verticaly-centred not-editable-box"><p className="pdf-bold">{t("return_form_warranty_tel")}</p></div>

                    <div className="pdf-form-input pdf-flex-verticaly-centred"><p className="pdf-bold">{t("return_form_warranty_or_delivery_note_number")}</p><input style={{height: '30px'}} type="text"/></div>
                    <div className="pdf-form-input pdf-flex-verticaly-centred"><p className="pdf-bold">{t("return_form_warranty_qty")}</p><input style={{height: '30px'}} type="text"/></div>
                    <div className="pdf-form-input pdf-flex-verticaly-centred"><p className="pdf-bold">{t("return_form_warranty_mounting_datae")}</p><input style={{height: '30px'}} type="text"/></div>
                    <div className="pdf-form-input pdf-flex-verticaly-centred"><p className="pdf-bold">{t("return_form_warranty_kba_nr")}</p></div>
                    <div className="pdf-form-input pdf-flex-verticaly-centred"><p className="pdf-bold">{t("return_form_warranty_zu2")}</p><input style={{height: '30px'}} type="text"/></div>
                    <div className="pdf-form-input pdf-flex-verticaly-centred"><p className="pdf-bold">{t("return_form_warranty_zu3")}</p><input style={{height: '30px'}} type="text"/></div>

                    <div className="pdf-form-input pdf-flex-verticaly-centred"><p className="pdf-bold">{t("return_form_warranty_cc_kw")}</p><input style={{height: '30px'}} type="text"/></div>
                    <div className="pdf-form-input pdf-flex-verticaly-centred"><p className="pdf-bold">{t("return_form_warranty_milage_km")}</p><input style={{height: '30px'}} type="text"/></div>
                    <div className="pdf-form-input pdf-flex-verticaly-centred"><p className="pdf-bold">{t("return_form_warranty_signature")}</p><input style={{height: '30px'}} type="text"/></div>
                </div>
            </div>

            <div className="pdf-card-top pdf-mg-bottom-25 border-botom-dashed">
                <p>{t("return_form_warranty_original_invoice_workshop")}</p>
            </div>

            <div className="pdf-mg-top">
                <div className="pdf-flex-verticaly-centred">
                    <div className="pdf-card-witch-20">
                        <div className="pdf-form-input pdf-flex"><p>{t("return_form_warranty_completed_sbs")}</p></div>
                        <div className="pdf-form-input pdf-flex"><p>{t("return_form_warranty_control_type")}</p></div>
                    </div>
                    <div className="pdf-form-input pdf-flex pdf-card-witch-20"><p>{t("return_form_warranty_credit_note")}</p> <div className="pdf-fake-checkbox"></div> </div>
                    <div className="pdf-form-input pdf-flex pdf-card-witch-20"><p>{t("return_form_warranty_replacement_delivery")}</p> <div className="pdf-fake-checkbox"></div> </div>
                    <div className="pdf-form-input pdf-flex pdf-card-witch-20">
                        <div>
                            <p><b>{t("return_form_warranty_scrapped_site")}</b></p>
                            <div className="pdf-flex-verticaly-centred"><p>{t("return_form_warranty_only_area_sales_manager")}</p> <div className="pdf-fake-checkbox"></div> </div>
                        </div>
                    </div>
                </div>
                <div className="pdf-flex-verticaly-centred">
                    <div className="pdf-card-witch-20">
                        <div className="pdf-form-input pdf-flex"><p>{t("return_form_warranty_responsible_at_sbs")}</p></div>
                    </div>
                    <div className="pdf-form-input pdf-flex pdf-card-witch-20"><p>{t("return_form_warranty_credit_note")}</p> <div className="pdf-fake-checkbox"></div> </div>
                    <div className="pdf-form-input pdf-flex pdf-card-witch-20"><p>{t("return_form_warranty_nk")}</p> <div className="pdf-fake-checkbox"></div> </div>
                    <div className="pdf-form-input pdf-flex pdf-card-witch-20"><p>{t("return_form_warranty_al")}</p> <div className="pdf-fake-checkbox"></div> </div>
                </div>
                <div className="pdf-flex-verticaly-centred">
                    <div className="pdf-card-witch-20">
                        <div className="pdf-form-input pdf-flex"><p></p></div>
                    </div>
                    <div className="pdf-form-input pdf-flex pdf-card-witch-20"><p>{t("return_form_warranty_pawn")}</p> <div className="pdf-fake-checkbox"></div> </div>
                    <div className="pdf-form-input pdf-flex pdf-card-witch-20"><p>{t("return_form_warranty_autre")}</p> <div className="pdf-fake-checkbox"></div> </div>
                    <div className="pdf-form-input pdf-flex pdf-card-witch-20"></div>
                </div>
            </div>
            <div className="pdf-card-top pdf-mg-bottom-25">
                <div className="pdf-card">
                    <div className="pdf-form-input pdf-flex"><p>{t("return_form_date_right")}</p> <span className="pdf-underline"></span> </div>
                    <div className="pdf-form-input pdf-flex"><p>{t("return_form_warranty_signature")}</p> <span className="pdf-underline"></span> </div>
                </div>
            </div>
            <div className="pdf-card-top pdf-mg-bottom-25">
                <h3>{t("return_form_warranty_please_note_return_shipment_must_duspatch")}</h3>
            </div>
        </Layout>
    );
});

export default ReturnFormPDF;

