import React, {Fragment} from 'react';
import {Component} from "react";
import _ from "lodash";
import PreferencesForm from "admin/administrator/PreferencesForm/PreferencesForm.component";
import {settingPreferencesForm} from "utils/preferenceFormsConfig";
import CustomSelectAllAble from "admin/administrator/CustomSelectAllAble";
import CustomSortable from "admin/administrator/CustomSortable";
import Loading from "admin/administrator/LoadingComponent/Loading.component";
import CustomButton from "admin/administrator/CustomButton";
import axios from "axios1";
import alertify from 'alertifyjs';
import "admin/administrator/UserPreferences.css";
import Modal from "components/Modal";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Block from "components/Block";
import { withTranslation } from 'react-i18next';

class CustomCatalogueDefaults extends Component {
    constructor(props) {
        super(props);
        this.state = {
            key: "tab-1",
            loading: false,
            sectionLoad:false,
            brandsLoad:false,
            form: {
                search_types: [],
                additional_elements: [],
                manufactures: [],
                encrypt_part_numbers: false,
                enable_shopping_cart: false,
                images_in_shopping_cart: false,
                show_brand_logos: false,
                show_prices: false,
                show_full_info: false,
                show_xrefs: false,
                stockOption: 'hidden',
                stock_visibility: "quantity",
                search_type: "advanced",
                multi_select_groups: false,
                enable_vehicle_selection: false,
                vrm_vehicle_confirmation: false,
                search_filter:false,
                lost_sale: true,
                show_supplier_stock_info: true,
            },

            stockOptions: [
                {name: "In/Out of stock", value: "inOut"},
                {name: "Actual stock quantity", value: "quantity"},
                {name: "Do not display stock", value: "hidden"},
            ],

            searchTypes: [
                {name: "Advanced Search", value: "advanced"},
                {name: "Easy Search", value: "easy"},
            ],

            selected: {
                groupId: 0,
                sectionId: 0,
            },

            brandsHidden: true,
            showBrandsAll: true,
            manufacturersAll: true,
            groups: [],
            sections: [],
            brands: [],
            showBrands: [],
            errors: [],
        };
    }

    // Init CustomCatalogueDefaults Begin ===>
    componentWillReceiveProps(nextProps) {
        if (nextProps.show != this.props.show) {
            this.setState({loading: true});
            this.setStateValues();
        }
    }

    setStateValues() {
        let awaitPref = this.props.preferences();
        awaitPref.then((preferences) => {
            let form = preferences.form;
            let groups = preferences.groups;
            this.setState({
                form: form,
                groups: groups,
                loading: false,
            });
        })
    }
    //<=== Init CustomCatalogueDefaults End

    updateAdditionalElementsOrder(items) {
        let form = {...this.state.form};
        form.additional_elements = items;
        this.setState({form: form});
    }

    searchTypesOrderChange(items) {
        let form = {...this.state.form};
        form.search_types = items;
        this.setState({form: form});
    }

    updateManufacturersOrder(items) {
        let checkAll = true;
        let form = {...this.state.form};
        _.find(items,function(element) {
            if(!element.checked) {
                checkAll = false;
            }
        })
        form.manufacturers = items;
        this.setState({form: form, manufacturersAll: checkAll});
    }

    updateGroupsOrder(items) {
        this.setState({ groups: items});
    }

    updateSectionOrder(items) {
        let selected = {...this.state.selected};
        let groups = this.state.groups;
        groups = _.map(groups, function (group) {
            if (group.id == parseInt(selected.groupId)) {
                group.sections = items;
            }
            return group;
        })
        this.setState({ groups: groups, sections: items });
    }

    updateBrandsOrder(items) {
        let selected = {...this.state.selected};
        let groups = this.state.groups;
        groups = _.map(groups,function(group){
            if(group.id == parseInt(selected.groupId)){
                group.sections = _.map(group.sections,function(section){
                    if(section.id == parseInt(selected.sectionId)) {
                        section.checked = _.find(items, (element) => {return element.checked == true ? element: null}) ? true : false;
                        section.brands = items;
                    }
                    return section;
                })
            }
            return group;
        });

        this.setState({groups : groups, showBrands: items});
    }

    getSections(group_id) {
        this.setState({sectionLoad:true});
        axios.get("catalogue/preferences/sections?groupId=" + group_id)
        .then((response) => {
            this.setState({sectionLoad:false});
            if(response.status == 200) {
                let sections = response.data;
                let groups = JSON.parse(JSON.stringify(this.state.groups));
                groups = _.map(groups,function(element){
                    if(element.id == group_id) {
                        element.sections = sections;
                    }
                    return element;
                })

                this.setState({
                    groups: groups,
                    sections: sections
                });
            }
        })
    }

    getBrandsForSection(section_id) {
        this.setState({brandsLoad:true});
        let selected = {...this.state.selected};
        let groups = {...this.state.groups};

        axios.get("catalogue/preferences/brands?sectionId=" + section_id)
        .then((response) => {
            this.setState({brandsLoad:false});
            if(response.status == 200) {

                let brands = response.data;
                if( brands && brands.length > 0) {
                    groups = _.map(groups,function(element) {
                        if(element.id == selected.groupId) {
                            element.sections = _.map(element.sections,function(section) {
                                if(section.id == section_id) {
                                    section.checked = _.find(brands, (element) => {return element.checked == true ? element: null}) ? true : false;
                                    section.brands = brands;
                                }
                                return section;
                            })
                        }
                        return element;
                    })
                }

                this.setState({showBrands: brands, groups: groups});
            }
        })

    }
    // <=== Orderable Components functions

    // Event Handlers ===>
    handleBrandChange(items){
        this.setState({ showBrands: items },()=>{
            this.updateBrandsOrder(items);
        });
    }

    handleSectionClick(sectionId){
        let selected = this.state.selected;
        selected.sectionId = sectionId;
        let stateData = {};

        if(selected.sectionId != 0) {

            let group = _.find({...this.state.groups},{id:selected.groupId});
            if(group.sections && group.sections.length > 0) {

                let section = _.find(group.sections,{id: selected.sectionId});
                if(section.brands)
                    stateData.showBrands = section.brands;
                else
                    this.getBrandsForSection(selected.sectionId)
            }

            stateData.selected = selected;
            stateData.brandsHidden = false;
            this.setState(stateData);

        }else{
            this.setState({brandsHidden:true});
        }
    }

    handleAdditionalElementChange(property, event) {
        let form = {...this.state.form};
        let element = _.find(form.additional_elements, { value: property });
        element.checked = event.target.checked;
        this.setState({ form: form });
    }

    handleManufacturersChange(manufacturers) {
        let form = this.state.form;
        form.manufacturers = manufacturers;
        this.setState({ form: form });
    }

    handleSwitchChange = (value, title, property) => {
        let form = {...this.state.form};        

        if(property) {
            let element = _.find(form[title], { value: property });
            element.checked = value;
        } else {
            value = typeof value === "boolean" ? ( value ? "1" : "0" ) : value;
            form[title] = value;
        }

        this.setState({form: form});
    }

    // handleSwitchChange(property,checked) {
    //     let form = { ...this.state.form };
    //     form[property] = checked ? "1" : "0";
    //     this.setState({ form : form});
    // }

    handleValueChange(property, event) {
        let form = {...this.state.form};
        form[property] = event.target.value;
        this.setState({form: form});
    }

    handleGroupClick(groupId) {
        let selected = this.state.selected;
        selected.groupId = groupId;
        selected.sectionId = 0;
        let stateDate = {};
        let groups = JSON.parse(JSON.stringify(this.state.groups));
        let group = _.find(groups, {id: groupId});
        if(group.sections && group.sections.length > 0) {
            stateDate.sections = group.sections;
        }else {
            this.getSections(selected.groupId);
        }
        stateDate.selected = selected;
        stateDate.brandsHidden = true;
        this.setState(stateDate);
    }

    handleSaveClick() {
        this.setState({loading: true});
        this.props.onHide();
        let groups = _.map({...this.state.groups});
        let form = this.state.form;
        let prefVal = {form: form, groups: groups};
        this.props.updatePreferencesValues(prefVal);
        var selected = this.state.selected;
        selected.groupId = 0;
        selected.sectionId = 0;
        this.setState({selected: selected, brandsHidden: true, loading: false});
    }

    onCheckAllManufactures(items) {
        var form = {...this.state.form}
        form.manufacturers = items;
        this.setState({form: form})
    }

    updateSearchTypesOrder(items) {
        let form = {...this.state.form};
        form.search_types = items;
        this.setState({form: form});
    }

    handleSearchTypeChange(property, event) {
        let form = {...this.state.form};
        let type = _.find(form.search_types, { value: property });
        type.checked = event.target.checked;
        this.setState({ form: form });
    }

    setKey(k) {
        this.setState({key: k});
    }

    // <=== Event Handlers

    // Modal functions ===>
    returnEmmpty(brands=null){
        let element = [];
        let el2=[];
        var length = 13;
        if(brands){
            el2.push(<div className="md-checkbox">
            <input type="checkbox" checked='true' id={"brand"}/>
            <label htmlFor={"brand"}></label>
        </div>);
            length = 11;
        }
        for (let i = 0; i < length; i++) {
            element.push(
                <li className="ui-state-default">
                    <span className={"sortable-list-name-wrapper"}>
                    <span className="settings-arrows-icons"><i
                        className="fas fa-sort-up"></i><i
                        className="fas fa-sort-down"></i></span>
                        <span
                            className="sortable-element-name"></span>
                    </span>
                    {el2}
                </li>
            )
        }
        return element;
    }

    closeModal() {
        this.props.onHide();
        var selected = this.state.selected;
        selected.groupId = 0;
        selected.sectionId = 0;
        this.setState({selected: selected, brandsHidden: true});
    }
    // <=== Modal functions

    render() {
        alertify.defaults = {
            notifier: {
                position: 'top-right',
                delay: 5
            }
        }

        const { t } = this.props;

        const groupsList = _.filter(this.state.groups, function (element) {
            return true
        });

        let found = "";
        if (this.state.selected.groupId && this.state.sections.length > 0) {
            found = _.find(this.state.sections, {id: this.state.selected.sectionId});
            found = found ? `for ${found.name}` : ""
        }
        return (
            <Fragment>
                <Loading loading={this.state.loading} />
                <Modal size="lg" title={t("Catalogue Default Preferences")} type="dark" active={this.props.show} closeModal={() => this.props.onHide()}>
                    <Tabs>
                        <TabList>
                            <Tab>{t("General")}</Tab>
                            <Tab>{t("Group/Section/Brand")}</Tab>
                            <Tab>{t("Vehicle Manufacturers")}</Tab>
                        </TabList>

                        <TabPanel>
                            <div className="grid">
                                <div className="grid__row">
                                    <div className="grid__column grid__column--7">
                                        <PreferencesForm 
                                            handleSwitchChange={ this.handleSwitchChange }
                                            form={ this.state.form }
                                            showElements={ settingPreferencesForm }
                                        />
                                    </div>
                                    <div className="grid__column grid__column--5">
                                        <Block type="4" title={t("Additional elements to show in search results table")}>
                                            <CustomSortable items={this.state.form.additional_elements}
                                                            disabled={false}
                                                            // maxHeight = {"300px"}
                                                            callBack={this.updateAdditionalElementsOrder.bind(this)}
                                                            onChangeCheckBox={this.handleAdditionalElementChange.bind(this)}/>
                                        </Block>
                                        <Block type="4" title={t("Search types")} style={{marginTop: "24px"}}>
                                            <CustomSortable items={this.state.form.search_types}
                                                            disabled={false}
                                                            // maxHeight = {"300px"}
                                                            callBack={this.updateSearchTypesOrder.bind(this)}
                                                            onChangeCheckBox={this.handleSearchTypeChange.bind(this)}/>
                                        </Block>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="grid">
                                <div className="grid__row">
                                    <div className="grid__column grid__column--4">
                                        <Block type="4" title={t("Groups")}>
                                            <CustomSortable items={groupsList.length ? groupsList : []}
                                                            disabled={false}
                                                            // maxHeight = {"570px"}
                                                            selectedId={this.state.selected.groupId}
                                                            callBack={this.updateGroupsOrder.bind(this)}
                                                            onClick={this.handleGroupClick.bind(this)}/>
                                        </Block>
                                    </div>
                                    <div className="grid__column grid__column--4">
                                        <Block type="4" title={t("Sections")}>
                                            {
                                                this.state.selected.groupId && this.state.sections ?
                                                    <CustomSortable
                                                        items={this.state.sections}
                                                        disabled={false}
                                                        // maxHeight = {"570px"}
                                                        selectedId={this.state.selected.sectionId}
                                                        callBack={this.updateSectionOrder.bind(this)}
                                                        onClick={this.handleSectionClick.bind(this)}
                                                    />
                                                : <div className={"dragable-list-elements-wrapper-limb"}></div>
                                            }
                                        </Block>
                                    </div>
                                    <div className="grid__column grid__column--4">
                                        <Block type="4" title={t("Brands")}>
                                            {
                                                this.state.brandsHidden ?
                                                    <div className={"dragable-list-elements-wrapper-limb"}></div>
                                                :   <div>
                                                    <Loading loading={this.state.brandsLoad}/>
                                                    {
                                                        <CustomSelectAllAble
                                                            // ===> Custom Check All Props Begin
                                                            cheeck_all_name={"showBrands"}
                                                            onCheckAll={this.updateBrandsOrder.bind(this)}
                                                            // <=== Custom Check All Props End

                                                            // ===> Custom Sortable Props Begin
                                                            items={this.state.showBrands}
                                                            disabled={false}
                                                            callBack={this.updateBrandsOrder.bind(this)}
                                                            onChangeCheckBox={this.handleBrandChange.bind(this)}
                                                            // <=== Custom Sortable Props End
                                                        />
                                                    }
                                                </div>
                                            }
                                        </Block>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="grid">
                                <div className="grid__row">
                                    <div className="grid__column grid__column--4">
                                        <Block type="4" title={t("Manufacturers")}>
                                            <CustomSelectAllAble
                                                    // ===> Custom Check All Props Begin
                                                    cheeck_all_name={"manufacturers"}
                                                    onCheckAll={this.onCheckAllManufactures.bind(this)}
                                                    // <=== Custom Check All Props End

                                                    // ===> Custom Sortable Props Begin
                                                    items={this.state.form.manufacturers}
                                                    disabled={false}
                                                    callBack={this.updateManufacturersOrder.bind(this)}
                                                    onChangeCheckBox={this.handleManufacturersChange.bind(this)}
                                                    // <=== Custom Sortable Props End
                                                />
                                        </Block>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                    </Tabs>
                    <div className="modal__actions">
                        <CustomButton
                            title={t("Cancel")}
                            type={"lightgray"}
                            onClick={this.closeModal.bind(this)}
                            margins={['mr-8']}
                        />
                        <CustomButton
                            title={t("Save")}
                            type={"blue"}
                            onClick={this.handleSaveClick.bind(this)}
                        />
                    </div>
                </Modal>
            </Fragment>
        );
    }
}

export default withTranslation()(CustomCatalogueDefaults)
