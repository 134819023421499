import React, {useEffect, useState, Fragment } from "react";
import SearchByMake from "components/SearchByMake";
import SearchByPartNumber from "components/SearchByPartNumber";
import SearchByRegNumber from "components/SearchByRegNumber";
import ProductsList from "components/ProductsList";
import { reloadSettings } from "redux/actions/settings";
import Filters from 'components/Filters';
import {connect} from "react-redux";
import "components/pages/SearchProducts.css";
import AdImage from "components/AdImage/AdImage";
import {withRouter} from "react-router-dom";
import SwitchUser from "components/switchUser";
import Block from "components/Block";
import { useTranslation } from 'react-i18next';

const SearchProducts = ({
        reloadSettings,
        searchResult,
        settings,
        auth
    }) => {

    const { t, i18n } = useTranslation();
    const [clearRegNumber, setClearRegNumber] = useState(false);
    const [clearPartNumber, setClearPartNumber] = useState(false);
    const [clearVehicle, setClearVehicle] = useState(false);
    const [clearVinNumber, setClearVinNumber] = useState(false);
    // changed from usestate(true) ->
    const [stockLoad, setStockLoad] = useState(false);
    // const [fromPartNumber, setFromPartNumber] = useState(false);
    const [nonAutoActive, setNonAutoActive] = useState(false);
    const [showExtraFilter, setShowExtraFilter] = useState(false);

    useEffect(() => {
        reloadSettings();
    }, [])

    const countProductsQuantity = products => {
        let count = 0;
        for (var section in products)
            count += parseInt(products[section].length);
        return count;
    }
    
    return (
        <div className={"content"}>
            { ((auth.user && auth.user.account.business_type_id == 3) || (auth.previousUser && auth.previousUser.account.business_type_id == 3)) && <SwitchUser /> }
            <div className="container mb-16">
                <div className="search">
                    <div className="grid">
                        <div className="grid__row">
                            {
                                settings.search_types && settings.search_types.length > 0 &&
                                    <div className="grid__column grid__column--3">
                                        {/* Search Type */}
                                        <Block title={t("Search Type")+":"} type="2">
                                            <SearchByRegNumber setClearRegNumber={setClearRegNumber}
                                                            setClearPartNumber={setClearPartNumber}
                                                            setClearVehicle={setClearVehicle}
                                                            setClearVinNumber={setClearVinNumber}
                                                            countProductsQuantity={countProductsQuantity}
                                                            clearRegNumber={clearRegNumber}
                                                            clearPartNumber={clearPartNumber}
                                                            clearVinNumber={clearVinNumber}
                                                            nonAutoActive={nonAutoActive} />
                                        </Block>
                                    </div>
                            }
                            <div className="grid__column grid__column--6">
                                <Block title={t("Search by Vehicle Selection")} type="2" disabled={searchResult.nonAutoActive ? (settings.search_types && settings.search_types.split(",").length > 1 ? true : false) : !settings.enable_vehicle_selection}>
                                    <SearchByMake setStateFromChild={() => {}}
                                                setClearRegNumber={setClearRegNumber}
                                                setClearPartNumber={setClearPartNumber}
                                                setClearVehicle={setClearVehicle}
                                                setClearVinNumber={setClearVinNumber}
                                                countProductsQuantity={countProductsQuantity}
                                                clearVehicle={clearVehicle}
                                                nonAutoActive={searchResult.nonAutoActive}/>
                                </Block>
                            </div>
                            <div className="grid__column grid__column--3">
                                <Block title={t("Search by Part Number")} subtitle={t("(Please enter the part number written exactly as used by the vehicle manufacturer)")} type="2" disabled={searchResult.nonAutoActive}>
                                    <SearchByPartNumber setClearPartNumber={setClearPartNumber}
                                                setClearRegNumber={setClearRegNumber}
                                                setClearVehicle={setClearVehicle}
                                                setClearVinNumber={setClearVinNumber}
                                                clearPartNumber={clearPartNumber}
                                                setStateFromChild={() => {}}
                                                nonAutoActive={searchResult.nonAutoActive}
                                                countProductsQuantity={countProductsQuantity}/>
                                </Block>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                <Fragment>
                    <div className={"container"}>                      
                        <Filters showExtraFilter={showExtraFilter}
                            countProductsQuantity={countProductsQuantity}
                            hasAxles={true}
                            setClearPartNumber={setClearPartNumber}
                            setStockLoad={setStockLoad}
                        />
                        {
                            searchResult.showProducts &&
                                <ProductsList lookup={searchResult.lookup_id}
                                            products={searchResult.filteredProducts}
                                            section_lookup_id={searchResult.section_lookup_id}
                                            stockLoad={stockLoad}/>                                                        
                        }
                    </div>
                    <div className={"container"}>
                        <AdImage/>
                    </div>
                </Fragment>
            }
            <div className="awsLogoWrapper">
                {/* <a target="_blank" href="https://aws.amazon.com/what-is-cloud-computing"><img src="https://d0.awsstatic.com/logos/powered-by-aws.png" alt="Powered by AWS Cloud Computing" /></a> */}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    searchResult: state.searchResult,
    settings: state.settings,
    auth: state.auth
})

const mapDispatchToProps = {
    reloadSettings: reloadSettings,
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
    null
)(SearchProducts));