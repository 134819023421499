import React, { Fragment } from 'react';
import AuthContainer from "components/Auth/AuthContainer";

function Auth() {
    return (
        <Fragment>
            <AuthContainer/>
        </Fragment>
    )
}

export default Auth