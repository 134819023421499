import React, {Component} from "react";
import { Fragment } from "react";

export default class Warranty extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let product = this.props.product;
        return (
            <Fragment>
                {
                    product.part_number.warranty ?

                        <span>
                            {product.part_number.warranty.value}
                        </span>
                        : null 
                }
            </Fragment>
        )
    }
}