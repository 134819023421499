import React, {Component} from "react";
import axios from "axios1";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {GridLoader} from 'react-spinners';
import { singleIntegerToFloatInString } from 'utils/number';
import _ from "lodash";
import alertify from 'alertifyjs';

class AdvancedSearchMenu extends Component {
    constructor(props) {
        super(props);

        this.select = {
            make: {
                value: "select",
                options: [
                    {Make: "Make"},
                ]
            },
            model: {
                value: "select",
                options: [
                    {Model: "Model"}
                ]
            },
            fuel: {
                value: "select",
                options: [
                    {Fuel: "Fuel"}
                ]
            },
            engine: {
                value: "select",
                options: [
                    {Engine: "Engine"}
                ]
            },
            bodystyle: {
                value: "select",
                options: [
                    {BodyStyle: "BodyStyle"}
                ]
            },
            year: {
                value: "select",
                options: [
                    {Year: "Year"}
                ]
            },
            vehicle: {
                value: "select",
                fullName: "",
                options: [
                    {id: 0, Vehicle: "Vehicle"},
                ]
            }
        };

        this.state = {
            makesLoading: true,
            modelsLoading: false,
            fuelsLoading: false,
            enginesLoading: false,
            bodystylesLoading: false,
            yearsLoading: false,
            vehiclesLoading: false,
            select: this.select,
        }

        this.getMakes();
    }

    componentDidMount() {
        this.setOptions()
    }

    setOptions() {
        let options = this.props.options;
        this.select.vehicle.value = options.options ? options.options.vehicle.value : "select"
        this.select.vehicle.fullName = options.options ? options.options.vehicle.fullName : null

        this.select.vehicle.options.push({id: this.select.vehicle.value, Vehicle: this.select.vehicle.fullName})

        this.setState({select: this.select})
    }

    getMakes() {
        axios.get("/catalogue/vehicles/makes")
            .then((response) => {
                if(response && response.data) {
                    this.select.make.options = response.data;
                    this.select.make.options.unshift({Make: "Make"});
                    this.setState({select: this.select, makesLoading: false});
                }
            })
    }

    getModels(make) {
        this.setState({modelsLoading: true});
        axios.get("/catalogue/vehicles/models/" + make)
            .then((response) => {
                this.select.model.options = response.data;
                this.select.model.options.unshift({Model: "Model"});
                this.setState({select: this.select, modelsLoading: false});
            })
    }

    getFuels(make, model) {
        this.setState({fuelsLoading: true});
        axios.get("/catalogue/vehicles/fuels/" + make + "/" + model)
            .then((response) => {
                this.select.fuel.options = response.data;
                this.select.fuel.options.unshift({Fuel: "Fuel"});
                this.setState({select: this.select, fuelsLoading: false});
            })
    }

    getEngines(make, model, fuel) {
        this.setState({enginesLoading: true});
        axios.get("/catalogue/vehicles/engines/" + make + "/" + model + "/" + fuel)
            .then((response) => {
                this.select.engine.options = response.data;
                this.select.engine.options.unshift({Engine: "Engine"});
                this.setState({select: this.select, enginesLoading: false});
            })
    }

    getBodyStyles(make, model, engine, fuel) {
        this.setState({bodystylesLoading: true});
        axios.get("/catalogue/vehicles/bodystyles/" + make + "/" + model + "/" + engine + "/" + fuel)
            .then((response) => {
                this.select.bodystyle.options = response.data;
                this.select.bodystyle.options.unshift({BodyStyle: "BodyStyle"});
                this.setState({select: this.select, bodystylesLoading: false});
            })
    }

    getYears(make, model, engine, fuel, bodystyle) {
        this.setState({yearsLoading: true});
        axios.get("/catalogue/vehicles/years/" + make + "/" + model + "/" + engine + "/" + fuel + "/" + bodystyle)
            .then((response) => {
                this.select.year.options = this.listYears(response.data);
                this.select.year.options.unshift({Year: "Year"});
                this.setState({select: this.select, yearsLoading: false});
            })
    }

    getVehicles(make, model, engine, fuel, bodystyle, year) {
        this.setState({vehiclesLoading: true});
        axios.get("/catalogue/vehicles/index/" + make + "/" + model + "/" + engine + "/" + fuel + "/" + bodystyle + "/" + year)
            .then((response) => {
                this.select.vehicle.options = response.data;
                this.select.vehicle.options.unshift({id: 0, Vehicle: "Vehicle"});
                this.setState({select: this.select, vehiclesLoading: false});
            })
    }

    makeChanged(event) {
        this.select.model.value = 'Model';
        this.select.fuel.value = 'Fuel';
        this.select.engine.value = 'Engine';
        this.select.bodystyle.value = 'BodyStyle';
        this.select.year.value = 'Year';
        this.select.vehicle.value = 'Vehicle';
        this.select.make.value = event.target.value;
        this.setState({select: this.select, loading: true});
        this.getModels(this.select.make.value);
        this.props.setStateFromChild("groups", []);
        this.props.setStateFromChild("clearRegNumber", true);
        this.props.setStateFromChild("clearPartNumber", true);
        this.props.saveSearchResult("selectedGroups", []);
        this.props.saveSearchResult("selectedSections", []);
        this.props.saveSearchResult("selectedBrands", []);
        this.props.saveSearchResult("products", []);
        this.props.saveSearchResult("filteredProducts", []);
        this.props.saveSearchResult("productsQuantity", 0);
        this.props.saveSearchResult("showProducts", false);
        this.props.saveSearchResult("groups", []);
    }

    modelChanged(event) {
        this.select.fuel.value = 'Select Fuel';
        this.select.engine.value = 'Select Engine';
        this.select.bodystyle.value = 'Select BodyStyle';
        this.select.year.value = 'Select Year';
        this.select.vehicle.value = 'Select Vehicle';
        this.select.model.value = event.target.value;
        this.setState({select: this.select, loading: true});
        this.getFuels(this.select.make.value, this.select.model.value);
    }

    fuelChanged(event) {
        this.select.engine.value = 'Select Engine';
        this.select.bodystyle.value = 'Select BodyStyle';
        this.select.year.value = 'Select Year';
        this.select.vehicle.value = 'Select Vehicle';
        this.select.fuel.value = event.target.value;
        this.setState({select: this.select, loading: true});
        this.getEngines(this.select.make.value, this.select.model.value, this.select.fuel.value);
    }

    engineChanged(event) {
        this.select.bodystyle.value = 'Select BodyStyle';
        this.select.year.value = 'Select Year';
        this.select.vehicle.value = 'Select Vehicle';
        this.select.engine.value = event.target.value;
        this.setState({select: this.select, loading: true});
        this.getBodyStyles(this.select.make.value, this.select.model.value, this.select.fuel.value, this.select.engine.value);
    }

    bodystyleChanged(event) {
        this.select.year.value = 'Select Year';
        this.select.vehicle.value = 'Select Vehicle';
        this.select.bodystyle.value = event.target.value;
        this.setState({select: this.select, loading: true});
        this.getYears(this.select.make.value, this.select.model.value, this.select.fuel.value, this.select.engine.value, this.select.bodystyle.value);
    }

    yearChanged(event) {
        this.select.vehicle.value = 'Select Vehicle';
        this.select.year.value = event.target.value;
        this.setState({select: this.select, loading: true});
        this.getVehicles(this.select.make.value, this.select.model.value, this.select.fuel.value, this.select.engine.value, this.select.bodystyle.value, this.select.year.value);
    }

    vehicleChanged(event) {
        var index = event.nativeEvent.target.selectedIndex;
        this.select.vehicle.fullName = event.nativeEvent.target[index].text
        this.select.vehicle.value = event.target.value;
        this.setState({select: this.select});
        this.props.setStateFromChild("clearRegNumber", true);
        this.props.setStateFromChild("clearPartNumber", true);
        this.getGroupsByVehicleId();
    }

    getGroupsByVehicleId() {
        const token = this.props.token;
        if (!token) {
            this.props.history.push({pathname: '/Catalogue'});
            this.setState({redirectToLogin: true});
            return;
        }
        if (this.select.vehicle.value == "select" || this.select.vehicle.value == 0) {
            return;
        }
        this.setState({loading: true});
        
        axios.get("catalogue/search/by-vehicle/" + this.select.vehicle.value)
            .then((response) => {
                this.setState({loading: false});
                if (response.data.success) {
                    let groups = response.data.groups;
                    if (!Object.keys(groups).length) {
                        alertify.error("products not found");
                    }
                    let lookup_id = response.data.lookup_id;

                    this.props.setStateFromChild("lookup_id", lookup_id);
                    this.props.setStateFromChild("groups", groups);

                    this.props.saveSelectedOptions(this.state.select);

                    this.props.saveSearchResult("type", "vehicleId");
                    this.props.saveSearchResult("value", this.state.regNumber);
                    this.props.saveSearchResult("groups", groups);
                    this.props.saveSearchResult("lookup_id", lookup_id);
                    this.props.saveSearchResult("selectedGroups", []);
                    this.props.saveSearchResult("selectedSections", []);
                    this.props.saveSearchResult("selectedBrands", []);
                    this.props.saveSearchResult("products", []);
                    this.props.saveSearchResult("filteredProducts", []);
                    this.props.saveSearchResult("productsQuantity", 0);
                    this.props.saveSearchResult("showProducts", false);
                    this.props.saveSearchResult("vehicleName", this.select.vehicle.fullName);
                    this.props.saveSearchResult("hideForPartNumber", false);
                } else {
                    alertify.error(response.data.warning ? response.data.warning : "An error accured");
                }
            })
    }

    _handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.handleFindProductsClick();
        }
    }

    listYears(object) {
        let years = [];

        Object.keys(object).map((key) => {
            let startYear = object[key].start_year;
            let endYear = object[key].end_year;

            endYear = endYear == 0 ? startYear : endYear;

            for (var i = startYear; i <= endYear; i++) {
                years.push({Year: i})
            }
        })

        let uniqueYears = _.uniqBy(years, "Year");

        return uniqueYears;
    }

    render() {
        const divFluidWidth = {
            width: '100%'
        }

        const colourStyles = {
            control: styles => ({
                ...styles,
                width: '200px',
                backgroundColor: '#1E394E',
                border: 'none',
                borderRadius: 'none'
            }),
        };
        alertify.defaults = {
            notifier: {
                position: 'top-right',
                delay: 5
            }
        }

        return (
            <div style={divFluidWidth}>
                 {
                    this.state.loading ?
                        <div className='sweet-loading'>
                            <GridLoader
                                sizeUnit={"px"}
                                size={15}
                                color={'#0066b8'}
                                loading={this.state.loading}
                            />
                        </div> : null
                }
                <div className="vehicleSelector">
                    <select value={this.state.select.make.value} onChange={this.makeChanged.bind(this)}
                            className={"transparentSelect button-grey withLoading " + (this.state.makesLoading ? "loading" : "")}>
                        {
                            this.state.select.make.options.map((option) => {
                                return <option key={option.Make} value={option.Make}>{option.Make}</option>;
                            })
                        }
                    </select>
                    <select value={this.state.select.model.value} onChange={this.modelChanged.bind(this)}
                            className={"transparentSelect withLoading " + (this.state.modelsLoading ? "loading" : "")}>
                        {
                            this.state.select.model.options.map((option) => {
                                return <option key={option.Model} value={option.Model}>{option.Model}</option>;
                            })
                        }
                    </select>
                    <select value={this.state.select.fuel.value} onChange={this.fuelChanged.bind(this)}
                            className={"transparentSelect withLoading " + (this.state.fuelsLoading ? "loading" : "")}>
                        {
                            this.state.select.fuel.options.map((option) => {
                                return <option key={option.Fuel} value={option.Fuel}>{option.Fuel}</option>;
                            })
                        }
                    </select>
                    <select value={this.state.select.engine.value} onChange={this.engineChanged.bind(this)}
                            className={"transparentSelect withLoading " + (this.state.enginesLoading ? "loading" : "")}>
                        {
                            this.state.select.engine.options.map((option) => {
                                return <option key={option.Engine} value={option.Engine}>{singleIntegerToFloatInString(option.Engine)}</option>;
                            })
                        }
                    </select>
                    <select value={this.state.select.bodystyle.value} onChange={this.bodystyleChanged.bind(this)}
                            className={"transparentSelect withLoading " + (this.state.bodystylesLoading ? "loading" : "")}>
                        {
                            this.state.select.bodystyle.options.map((option) => {
                                return <option key={option.BodyStyle}
                                               value={option.BodyStyle}>{option.BodyStyle}</option>;
                            })
                        }
                    </select>
                    <select value={this.state.select.year.value} onChange={this.yearChanged.bind(this)}
                            className={"transparentSelect withLoading " + (this.state.yearsLoading ? "loading" : "")}>
                        {
                            this.state.select.year.options.map((option) => {
                                return <option key={option.Year} value={option.Year}>{option.Year}</option>;
                            })
                        }
                    </select>
                    <select value={this.state.select.vehicle.value}
                            onKeyPress={this._handleKeyPress.bind(this)}
                            onChange={this.vehicleChanged.bind(this)}
                            className={"transparentSelect withLoading " + (this.state.vehiclesLoading? "loading" : "")}
                    >
                        {
                            this.state.select.vehicle.options.map((option) => {
                                return <option key={option.id} value={option.id}>{option.Vehicle}</option>;
                            })
                        }
                    </select>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    token: state.auth.token,
    options: state.products,
    searchResult: state.searchResult
})

const mapDispatchToProps = dispatch => {
    return {
        saveSelectedOptions: (options) => dispatch({type: 'SAVE_SELECTED_OPTIONS', payload: options}),
        saveSearchResult: (key, value) => dispatch({
            type: 'SAVE_SEARCH_RESULTS',
            payload: {key, value}
        })
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(AdvancedSearchMenu));