import React, {useEffect, useState, Fragment} from "react";
import axios from "axios1";
import CustomDelete from "admin/administrator/CustomDelete";
import AddPriceLevelExceptions from "admin/administrator/AddPriceLevelExceptions";
import Loading from "admin/administrator/LoadingComponent/Loading.component";
import _ from "lodash";
import { useTranslation } from 'react-i18next';

const PricingLevels = props => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [priceLevels, setPriceLevels] = useState([]);
    const [bespokes, setBespokes] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [selectedPricingLevel, setSelectedPricingLevel] = useState(null);
    const [groups, setGroups] = useState([]);
    const [defaultPriceLevelName, setDefaultPriceLevelName] = useState("");
    const [selectedSection, setSelectedSection] = useState(null);

    useEffect(() => {
        getPriceLevels();
        getBespokes();
    }, []);

    const getPriceLevels = brand_id => {
        const newSelectedPricingLevel = _.find(props.savedPriceLevels, function (element) {
            return (element.section_id == selectedSection.id && element.brand_id == brand_id) ? true : false;
        })
        setLoading(true);
        axios.get("catalogue/price-levels")
            .then((response) => {
                setPriceLevels(response.data);
                let newDefaultPriceLevelName = _.find(response.data, {default: 1});
                console.log(newDefaultPriceLevelName);
                props.setSPriceLevelId(newDefaultPriceLevelName.id);
                newDefaultPriceLevelName = newDefaultPriceLevelName ? newDefaultPriceLevelName.name : "";
                setSelectedPricingLevel(newSelectedPricingLevel);
                setDefaultPriceLevelName(newDefaultPriceLevelName);
                setLoading(false);
        });
    }

    const getBespokes = () => {
        axios.get("catalogue/bespokes-all")
            .then((response) => {
                const sortedBespokes = response.data.sort(function(a, b) {
                    let x = a.name.toLowerCase();
                    let y = b.name.toLowerCase();
                    return x < y ? -1 : x > y ? 1 : 0;
                });
                setBespokes(sortedBespokes)
            });
    }

    const removePriceLevelException = priceLevel => {
        props.removePriceLevelException(priceLevel)
        setSelectedBrand(null);
        setSelectedPricingLevel(null);
    }

    const defaultPriceLevel = _.find(priceLevels, {default: 1});
    return (
        <Fragment>
            <Loading loading={loading}/>
            <div className={"pl-selectors"}>
                <div className="pl-selectors__item">
                    <span className="pl-selectors__label">{t("Default Pricing Level")}</span>
                    <div className="selector">
                        <select onChange={event => props.setSPriceLevelId(event.target.value)} 
                                className={"selector__selector pl-selectors__selector " + (props.errors['price_level_id'] ? "selector__selector--error" : "")}>
                            <option value="">{t("Select Pricing Level")}</option>
                            {
                                priceLevels.map(priceLevel => {
                                    return <option
                                    key={priceLevel.id}
                                    value={priceLevel.id}
                                    selected={(props.priceLevelId && props.priceLevelId!=null) ? props.priceLevelId==priceLevel.id : priceLevel.id === defaultPriceLevel.id}>
                                        {priceLevel.name}
                                    </option>
                                })
                            }
                        </select>
                        {
                            props.errors['price_level_id'] ? ( <span className={"selector__error"}>{props.errors['price_level_id']}</span> ) : null
                        }
                    </div>
                </div>
                <div className="pl-selectors__item">
                    <span className="pl-selectors__label">{t("Select Bespoke Price")}</span>
                    <div className="selector">
                        <select onChange={event => props.setBespokePrice(event.target.value)} className="selector__selector pl-selectors__selector">
                            <option value="">{t("Select Pricing Level")}</option>
                            {
                                bespokes.map((bespoke) => {
                                    return <option
                                        key={bespoke.id}
                                        value={bespoke.id}
                                        selected={(props.bespokePriceId && props.bespokePriceId!=null) ? props.bespokePriceId==bespoke.id : bespoke.default === ""}>
                                        {bespoke.name}
                                    </option>
                                })
                            }
                        </select>
                    </div>
                </div>
            </div>

            <div className={"pls"}>
                <div className="mb-8">{t("Pricing Level Exceptions")}</div>
                {
                    props.savedPriceLevels.length > 0 &&
                        <div className="table mb-16">
                            <table className={"table__table"}>
                                <thead className="table__thead">
                                <tr className="table__tr">
                                    <th className="table__th">{t("Product Section")}</th>
                                    <th className="table__th">{t("Brand")}</th>
                                    <th className="table__th">{t("Price Level")}</th>
                                    <th className="table__th">{t("Action")}</th>
                                </tr>
                                </thead>
                                <tbody className="table__tbody">
                                {
                                    props.savedPriceLevels.map((priceLevel, index) => {
                                        return <tr className="table__tr">
                                            <td className="table__td">{priceLevel.section}</td>
                                            <td className="table__td">{priceLevel.brand}</td>
                                            <td className="table__td">{priceLevel.price_level}</td>
                                            <td className="table__td">
                                                <CustomDelete key={index} onClick={() => removePriceLevelException(priceLevel)}/>
                                            </td>
                                        </tr>
                                    })
                                }
                                </tbody>
                            </table> 
                        </div>
                }
                <AddPriceLevelExceptions groups={groups} save={props.save} savedPriceLevels={props.savedPriceLevels} />                
            </div>          
        </Fragment>
    );
}

export default PricingLevels;