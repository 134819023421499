import React from "react";
import SearchByRegNumberImage from 'images/regNumberSearch.png';

const FormInput = ({ className='', showTypeChanger = false, handleShowHideClick, icon, handleChange, handleBlur, handleKeyDown, error, ...otherProps }) => (
    <div className={"input " + className}>
        <div className="input__flag">
            <img src={SearchByRegNumberImage}/>
        </div>
        <div className={`input__input`}>
            <input className={error && "input__error"} onChange={ handleChange } onBlur={handleBlur} onKeyDown={handleKeyDown} {...otherProps} autoComplete={className == "input--reg" ? "off" : Math.random()} ></input>
            {
                showTypeChanger &&
                    <i className={ `${icon} input__icon `} onClick={ e => handleShowHideClick() }></i>
            }
        </div>
        {
            error && ( <span className={"input__error"}>{error}</span> )
        }
    </div>
)

export default FormInput;