import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import IdleTimer from 'react-idle-timer';
import ReactDOM from 'react-dom';
import CustomButton from "admin/administrator/CustomButton";
import { connect } from "react-redux";
import { LogoutUser } from "redux/actions/authActions";
import { reloadSettings } from "redux/actions/settings";
import $ from "jquery";
import {GridLoader} from 'react-spinners';
import Modal from "components/Modal";

var interval = 0;

class AlertSessionTimeOutModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timeout: 1000 * 60 * 30,
            showModal: false,
            userLoggedIn: false,
            isTimedOut: false
        }

        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleLogout = this.handleLogout.bind(this)
    }

    // componentDidMount() {
    //     window.addEventListener('beforeunload', this.alertUser)
    //     window.addEventListener('unload', this.handleTabClosing)
    // }

    // componentWillUnmount() {
    //     window.removeEventListener('beforeunload', this.alertUser)
    //     window.removeEventListener('unload', this.handleTabClosing)
    // }

    // handleTabClosing() {
    //     this.logout();
    // }
    
    // alertUser(event) {
    //     event.preventDefault()
    //     event.returnValue = ''
    // }

    _onAction(e) {
        this.setState({isTimedOut: false})
    }
     
    _onActive(e) {
        this.setState({isTimedOut: false})
    }
    
    _onIdle(e) {
        const isTimedOut = this.state.isTimedOut
        if(this.props.auth.user) {            
            if (isTimedOut) {
                // this.renderModal();
                // clearInterval(interval);
                // interval = setInterval( this.myTimer.bind(this), 1000);
                this.logout();
            } else {
                // this.setState({showModal: true})
                this.idleTimer.reset();
                this.setState({isTimedOut: true})
                this.logout();
            }
        }
    }

    handleClose() {
        clearInterval(interval);
        ReactDOM.unmountComponentAtNode(document.getElementById('sessionmodalWrapper'));
        this.setState({showModal: false})
    }

    handleLogout() {
        this.logout();
    }

    renderModal() {
        ReactDOM.render(
        <Modal size="sm" title={"Warning"} type="dark" active={true} closeModal={this.closeModal}>
            <div>
                <div>You have been inactive for a while and will be logged out in: </div>
                <div className="timer"><span id="LogoutTimer"> 2:00 </span>  minutes</div>
            </div>
            <div className={"modal__actions"}>
                 <CustomButton
                        title={"Logout"}
                        type={"lightgray"}
                        onClick={this.handleLogout}
                        margins={['mr-8']}
                    />
                <CustomButton
                    title={"Continue"}
                    type={"blue"}
                    onClick={this.handleClose}
                />
            </div>
        </Modal>
        , document.getElementById('sessionmodalWrapper'));
    }

    // myTimer() {
    //         var timer = $('#LogoutTimer').html();
    //         timer = timer.split(':');
    //         var minutes = timer[0];
    //         var seconds = timer[1];
    //         seconds -= 1;
    //         if(seconds == 0 && minutes == 0) this.logout()
    //         if(minutes < 0) this.logout();
    //         else if (seconds < 0 && minutes != 0) {
    //             minutes -= 1;
    //             seconds = 59;
    //         }
    //         else if (seconds < 10 && seconds.length != 2) seconds = '0' + seconds;
    //         $('#LogoutTimer').html(minutes + ':' + seconds);
    // }

    logout(){
        this.setState({showModal: false, loading: true});
        clearInterval(interval);
        ReactDOM.unmountComponentAtNode(document.getElementById('sessionmodalWrapper'));
        this.props.LogoutUser().then(() => {
            window.location.href = '/Login';
            this.setState({loading:false});
        });

    }

      render() {
        return (
            <Fragment>
                {
                    this.state.loading ?
                    <div className='sweet-loading'>
                        <GridLoader
                            sizeUnit={"px"}
                            size={15}
                            color={'#0066b8'}
                            loading={this.state.loading}
                        />
                    </div> : null
                }
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    onAction={this.onAction}
                    debounce={250}
                    timeout={ this.state.timeout } />
                    {/* {
                        (this.state.showModal && this.props.auth.user)? this.renderModal() : null
                    } */}
            </Fragment>
        );
    }
}

const mapDispatchToProps = {
    reloadSettings,
    LogoutUser
};

const mapStateToProps = (state) => ({
    auth: state.auth,
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AlertSessionTimeOutModal);