import React, { Component } from "react";
import axios from "axios1";
import LookupChartsPlate from "admin/administrator/LookupChartsPlate";
import LookupChartsVehicle from "admin/administrator/LookupChartsVehicle";
import LookupChartsPartNumber from "admin/administrator/LookupChartsPartNumber";
// import "admin/administrator/LookupCharts.css";

export default class LookupCharts extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            lookups: {
                data: {
                    plates: [],
                    vehicles: [],
                    numbers: [],
                    nonAutoNumbers: []
                }
            },
            plates: {
                datasets:[{
                    data: [],
                    label: '',
                    backgroundColor: [],
                    borderColor: '',
                    borderWidth: 0,
                }], 
                labels:[] 
            },
            vehicles: {
                datasets:[{
                    data: [],
                    label: '',
                    backgroundColor: '',
                    borderColor: '',
                    borderWidth: 0,
                    hoverBackgroundColor: '',
                    hoverBorderColor: '',
                }],
                labels:[]
            },
            numbers: {
                datasets:[{
                    data: [],
                    label: '',
                    backgroundColor: '',
                    borderColor: '',
                    borderWidth: 0,
                    hoverBackgroundColor: '',
                    hoverBorderColor: '',
                }],
                labels:[]
            },
            nonAutoNumbers: {
                datasets:[{
                    data: [],
                    label: '',
                    backgroundColor: '',
                    borderColor: '',
                    borderWidth: 0,
                    hoverBackgroundColor: '',
                    hoverBorderColor: '',
                }],
                labels:[]
            },
        }
    }

    componentDidMount() {
        this.getTopLookups();
    }

    getTopLookups() {
        axios.get("/catalogue/lookups/top")
            .then((response) => {
                this.setState({lookups: response.data}, () => {
                    this.getTopPlates();
                    this.getTopVehicles();
                    this.getTopNumbers();
                    this.getTopNonAutoNumbers();
                });
            })
    }

    getTopPlates() {
        let dataCount = [];
        let data = [];
        let lookups = this.state.lookups;
        lookups.data['plates'].map((item) => {
            data.push(item.reg_number);
            dataCount.push(item.count);
        })

        let plates = {
            labels: data,
            datasets: [{
                data: dataCount,
                label: 'Looked Up',
                backgroundColor: ['#0581E1', '#03A6DF', '#03CADF', '#04E1D2', '#04E088', '#04E040', '#80E003', '#CADF04', '#E0AD04', '#DF6404'],
                borderColor: '#202020',
                borderWidth: 5
            }]
        }

        this.setState({plates: plates});
    }

    getTopVehicles() {
        let dataCount = [];
        let data = [];
        let lookups = this.state.lookups;
        lookups.data['vehicles'].map((item) => {
            if (item.vehicle){
                data.push(item.vehicle.Make + ' ' + item.vehicle.Model);
                dataCount.push(item.count);
            }
        })

        let vehicles = {
            labels: data,
            datasets: [{
                data: dataCount,
                label: 'Looked Up',
                backgroundColor: 'rgba(4, 129, 224, 0.5)',
                borderColor: 'rgba(4, 129, 224, 1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(4, 129, 224, 0.7)',
                hoverBorderColor: 'rgba(4, 129, 224, 1)',
            }]
        }

        this.setState({vehicles: vehicles});
    }

    getTopNumbers() {
        let dataCount = [];
        let data = [];
        let lookups = this.state.lookups;
        lookups.data['numbers'].map((item) => {
            data.push(item.part_number);
            dataCount.push(item.count);
        })

        let numbers = {
            labels: data,
            datasets: [{
                data: dataCount,
                label: 'Looked Up',
                backgroundColor: 'rgba(4, 129, 224, 0.5)',
                borderColor: 'rgba(4, 129, 224, 1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(4, 129, 224, 0.7)',
                hoverBorderColor: 'rgba(4, 129, 224, 1)',
            }]
        }


        this.setState({numbers: numbers});
    }

    getTopNonAutoNumbers() {
        let dataCount = [];
        let data = [];
        let lookups = this.state.lookups;
        lookups.data['nonAutoNumbers'].map((item) => {
            data.push(item.part_number);
            dataCount.push(item.count);
        })

        let nonAutoNumbers = {
            labels: data,
            datasets: [{
                data: dataCount,
                label: 'Looked Up',
                backgroundColor: 'rgba(4, 129, 224, 0.5)',
                borderColor: 'rgba(4, 129, 224, 1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(4, 129, 224, 0.7)',
                hoverBorderColor: 'rgba(4, 129, 224, 1)',
            }]
        }


        this.setState({nonAutoNumbers: nonAutoNumbers});
    }

    render() {

        let plates = this.state.plates
        let vehicles = this.state.vehicles
        let numbers = this.state.numbers
        let nonAutoNumbers = this.state.nonAutoNumbers

        return (
            <div className={"dashboard-page-wrapper"}>
                <div className={"dashboard-page-navigations"}>
                    <div>
                        <i className="fas fa-chart-line"></i>
                        <span> Top Lookups</span>
                    </div>
                    <div>
                        <button className="back-btn" onClick={() => this.props.history.goBack()}>
                            Go Back
                        </button>
                    </div>
                </div>
                <div className={"dashboard-page-body"}>
                    <div className="row">
                        <div className="nav flex-column nav-pills lookups-tabs" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <a
                                className="active" 
                                id="v-pills-home-tab" 
                                data-toggle="pill" 
                                href="#v-pills-home" 
                                role="tab" 
                                aria-controls="v-pills-home" 
                                aria-selected="true">
                                Plate Lookups
                                <span className="lookups-qty">
                                    <i className="far fa-eye"></i>
                                </span>
                            </a>
                            <a
                                id="v-pills-profile-tab" 
                                data-toggle="pill" 
                                href="#v-pills-profile" 
                                role="tab" 
                                aria-controls="v-pills-profile" 
                                aria-selected="false">
                                Vehicle Lookups
                                <span className="lookups-qty">
                                    <i className="far fa-eye"></i>
                                </span>
                            </a>
                            <a
                                id="v-pills-messages-tab" 
                                data-toggle="pill" 
                                href="#v-pills-messages" 
                                role="tab" 
                                aria-controls="v-pills-messages" 
                                aria-selected="false">
                                PartNumber Lookups
                                <span className="lookups-qty">
                                    <i className="far fa-eye"></i>
                                </span>
                            </a>
                        </div>
                        <div className="tab-content lookups-tabs-content" id="v-pills-tabContent">
                            <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                <LookupChartsPlate plates={plates} />
                            </div>
                            <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                <LookupChartsVehicle vehicles={vehicles} />
                            </div>
                            <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                <LookupChartsPartNumber numbers={numbers} />
                            </div>
                            <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                <LookupChartsPartNumber numbers={nonAutoNumbers} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}