import React, {Component} from "react";
// import "admin/administrator/LoginTotals/LoginTotals.css";
// import "admin/administrator/CustomElements.css";
import {BrowserRouter as Router, Route, NavLink} from "react-router-dom";
import axios from "axios1";
import _ from "lodash";
import CustomPagination from "admin/administrator/CustomPagination";
import CustomPieChart from 'components/Charts/CustomPieChart';
import PageTitle from 'admin/administrator/PageTitle';
import {GridLoader} from 'react-spinners';
import DatePicker from "react-datepicker";
import CustomPieChart2d from "components/Charts/CustomPieChart2d";
import CustomDatePicker from 'admin/administrator/CustomDatePicker';
import { connect } from "react-redux";
import {selectLoginTotalsMonth} from "redux/actions/statisticActions";

var moment = require('moment');

class LoginTotals extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: {
                account_id: null,
                from: "",
                to: "",
            },
            selectedDate: {
                from: new Date(),
                to: new Date()
            },
            page: 1,
            pages: 0,
            logins: [],
            accounts: [],
            loading: false,
            dataLabelsForChart: [],
            dataValuesForChart: []
        }
    }

    componentDidMount() {
        this.setState({loading: true});
        this.initMonth();
        this.LoginByAccounts(this.state.page);
    }

    LoginByAccounts(page = 1) {
        var filters = {...this.state.filters};
        axios.get("/catalogue/logins-by-accounts?page=" + page, {params: filters})
            .then(response => {
                let accounts = response.data.accounts.map((account) => {
                    return {id: account.id, name: account.name.toUpperCase()}
                });
                let dataLabelsForChart = [];
                let dataValuesForChart = [];
                response.data.logins.map((login) => {
                    dataLabelsForChart.push(login.account);
                    dataValuesForChart.push(login.total);
                });
                this.setState({
                    logins: response.data.logins,
                    page: page,
                    pages: response.data.pages,
                    accounts: accounts,
                    dataLabelsForChart: dataLabelsForChart,
                    dataValuesForChart: dataValuesForChart,
                    loading: false
                });
            })
    }

    handleChange(key, event) {
        let filters = this.state.filters;
        let value = event.target.value;
        filters[key] = (value != "all") ? value : null;
        this.setState({filters: filters}, () => {
            this.LoginByAccounts();
        });
    }

    handleChangeDate(key, date) {
        let selectedDate = this.state.selectedDate;
        let filters = this.state.filters
        filters[key] = moment(date).format('YYYY-MM-DD');
        selectedDate[key] = date;
        this.props.dispatch(selectLoginTotalsMonth(selectedDate))
        this.setState({filters: filters, selectedDate: selectedDate}, () => {
            this.LoginByAccounts();
        });
    }

    initMonth() {
        let from = this.props.loginTotalsFrom ? moment(this.props.loginTotalsFrom).toDate() : "";
        let to = this.props.loginTotalsTo ? moment(this.props.loginTotalsTo).toDate() : "";
        var selectedDate = this.state.selectedDate;
        selectedDate['from'] = from;
        selectedDate['to'] = to;
        var filters = this.state.filters;
        filters['from'] = from ? moment(from).format('YYYY-MM-DD') : from;
        filters['to'] = to ? moment(to).format('YYYY-MM-DD') : to;
        this.setState({selectedDate: selectedDate, filters: filters});
    }

    render() {
        return (
            <div className={"page-content admin-wrapper"}>
                <div className={"container-fluid"}>
                    <div className={"login-totals-page-wrapper"}>
                        {
                            this.state.loading ?
                                <div className='sweet-loading'>
                                    <GridLoader
                                        sizeUnit={"px"}
                                        size={15}
                                        color={'#0066b8'}
                                        loading={this.state.loading}
                                    />
                                </div> : null
                        }

                        <div className={"row"}>
                            <div className={"col-lg-12"}>
                                <div class="page-title-wrapper">
                                    <span>Login totals</span>
                                </div>
                                <div className={"login-totals-filter"}>
                                    <div className={"custom-filter"}>
                                        <div className={"filter-group"}>
                                            <label htmlFor="account">Account</label>
                                            <select className={"textUppercase"} onChange={this.handleChange.bind(this, 'account_id')}>
                                                <option value={""}>All Accounts</option>
                                                {
                                                    this.state.accounts.map((account) => {
                                                        return <option key={account.id}
                                                                       value={account.id}>{account.name}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className={"filter-group"}>
                                            <label htmlFor="account">From</label>
                                            <CustomDatePicker value={this.state.filters['from']} selected={this.state.selectedDate.from} placeholder={"Date From"} onChange={this.handleChangeDate.bind(this, "from")} format={"DD-MM-YYYY"} ></CustomDatePicker>
                                        </div>
                                        <div className={"filter-group"}>
                                            <label htmlFor="account">To</label>
                                            <CustomDatePicker value={this.state.filters['to']} selected={this.state.selectedDate.to} placeholder={"Date To"} onChange={this.handleChangeDate.bind(this, "to")} format={"DD-MM-YYYY"} ></CustomDatePicker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-lg-6"}>
                                {
                                    this.state.logins.length > 0 ?
                                        <table className={"custom-table table table-bordered"}>
                                            <thead>
                                            <tr>
                                                <th>ACCOUNT TYPE</th>
                                                <th>ACCOUNT NAME</th>
                                                <th>LOGIN TOTAL</th>
                                                <th>INFO</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.logins.map((login) => {
                                                    return <tr>
                                                        <td>{login.type}</td>
                                                        <td>{login.account}</td>
                                                        <td>{login.total}</td>
                                                        <td>
                                                            <NavLink to={"/admin/login-totals/logins/" + login.id}
                                                                     exact>
                                                                <button className={"custom-btn"}>Details</button>
                                                            </NavLink>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table> : null
                                }
                                {
                                    this.state.pages > 0 ?
                                        <CustomPagination
                                            page={this.state.page}
                                            pages={this.state.pages}
                                            sendRequest={this.LoginByAccounts.bind(this)}
                                        /> : null
                                }
                            </div>
                            <div className={"col-lg-6"}>
                                <CustomPieChart2d labels={this.state.dataLabelsForChart}
                                                  values={this.state.dataValuesForChart}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    loginTotalsFrom: state.statistic.tloginTotalsFrom,
    loginTotalsTo: state.statistic.tloginTotalsTo
})

export default connect(mapStateToProps)(LoginTotals);