import React, { Component } from "react";
// import "./Schedule.css";
import alertify from "alertifyjs";
import { withTranslation } from 'react-i18next';
import i18n, { t } from 'i18next';

export default withTranslation()(class Schedule extends Component
{
    constructor(props){
        super(props);

        this.state = {
            form: {
               send_emails_min: 0,
               check_ftp_min: 0,
            },
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.settings !== this.props.settings) {
            this.setState({form:nextProps.settings},()=>{

            });
        }
    }

    handleQuantityChange(property,event)
    {
        let form = {...this.state.form};
        let keyValue = event.target.value;
        if(form[property] != 0) {
            keyValue =  event.target.value;
        }
        const expression = /^([1-9][0-9]{0,2}|1000)$/;
        if(expression.test(String(keyValue))) {
            form[property] = parseInt(keyValue);
            this.setState({form: form},()=>{
                this.props.updateScheduleValues(form);
            });
        }
    }

    handleSubtractClick(property)
    {
        let form = {...this.state.form};
        if( form[property] <= 0) {
            form[property] = 0;
            alertify.error(t("Quantity must be greater or equal than zero")+"!");
        }else {
            form[property] = form[property] - 1;
        }
        this.setState({form: form},()=>{
            this.props.updateScheduleValues(form);
        });
    }

    handleAddClick(property)
    {
        let form = {...this.state.form};
        form[property] = form[property] + 1;
        this.setState({form: form},()=>{
            this.props.updateScheduleValues(form);
        });
    }

render()
    {
        return (
            <div className={"schedule-wrapper"}>
                <div className={"schedule-header"}>{t("SCHEDULE")}</div>
                {/* <div className={"schedule-input-wrapper"}>
                    <div className={"schedule-input-header"}>Send email alerts every:</div>
                    <div className="schedule-quantity">
                        <div className="schedule-quantity-btn" onClick={this.handleSubtractClick.bind(this,"send_emails_min")}>-</div>
                        <div className="schedule-quantity-quantity"><input type={"number"}  min={"0"} onChange={this.handleQuantityChange.bind(this,"send_emails_min")} placeholder={"0"} value={parseInt(this.state.form.send_emails_min)} /></div>
                        <div className="schedule-quantity-btn" onClick={this.handleAddClick.bind(this,"send_emails_min")}>+</div>
                    </div>
                    <span>mins.</span>
                </div> */}
                <div className={"schedule-input-wrapper"}>
                    <div className={"schedule-input-header"}>{t("Check FTP for new stock feeds every")}:</div>
                    <div className="schedule-quantity">
                        <div className="schedule-quantity-btn" onClick={this.handleSubtractClick.bind(this,"check_ftp_min")}>-</div>
                        <div className="schedule-quantity-quantity"><input type={"number"}  min={"0"} onChange={this.handleQuantityChange.bind(this,"check_ftp_min")} placeholder={"0"} value={parseInt(this.state.form.check_ftp_min)} /></div>
                        <div className="schedule-quantity-btn" onClick={this.handleAddClick.bind(this,"check_ftp_min")}>+</div>
                    </div>
                    <span>{t("mins")}.</span>
                </div>
            </div>
        );
    }

})