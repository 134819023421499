import React, {Component} from 'react';
import axios from 'axios1';
// import "admin/administrator/WarningModal/WarningModal.css";
import $ from "jquery";
import {GridLoader} from 'react-spinners';
import CustomButton from "admin/administrator/CustomButton";

import {Container, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup} from "react-bootstrap";

export default class WarningModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    render() {
        return (
            <Modal
                show={this.props.modalShow}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={this.props.closeModal}
                className="warning-modal">
                {
                    this.state.loading ?
                        <div className='sweet-loading'>
                            <GridLoader
                                sizeUnit={"px"}
                                size={15}
                                color={'#0066b8'}
                                loading={this.state.loading}
                            />
                        </div> : null
                }
                <Modal.Body>
                    <div className="row">
                        <div className="col-sm-2">
                            <span className="warningIcon"><i className="fas fa-exclamation-triangle"></i></span>
                        </div>
                        <div className="col text-left" style={{paddingLeft: '5px'}}>
                            <h6 className="warning ">{this.props.title}</h6>
                            <div class="warning-data">
                                {
                                    this.props.data.length > 0 ? 
                                        <ul>
                                            {
                                                this.props.data.map((account) => {
                                                    return <li>{account}</li>
                                                })
                                            }
                                        </ul> : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className={"error-footer"}>
                        <div style={{marginLeft:'auto'}}>
                            <CustomButton title={"Close"} type={"blue"} onClick={this.props.closeModal}/>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        );
    }
}
