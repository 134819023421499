const deleteAccounts = (state = [], action) => {
    switch (action.type) {
        case 'ADD_TO_DELETE_QUEUE':
            return addToDeleteQueue(state, action)
        case 'UNDO_ACCOUNT':
            return undoAccount(state, action)
        default:
            return state
    }
}

let undoAccount = (state, action) => {
    let state2 = [...state];
    state2 = [];
    return state2;
}

let addToDeleteQueue = (state, action) => {
    let state2 = [...state];
    state2.push(action.item);
    return state2;
}

export default deleteAccounts