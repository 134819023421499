import React, {Component} from "react";
import "account/user/ChangePassword.css";
import axios from "axios1";
import alertify from "alertifyjs";


export default class ChangePassword extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            errors:[],
            loading: false,
            form:{
                CurrentPassword:"",
                password:"",
                password_confirmed:""
            }
        }
    }

    handleInputChange(formName, event){
        let value = event.target.value;
        let form = { ...this.state.form };
        form[formName] = value;
        this.setState({ form: form });
    }

    handleSave(e){
        e.preventDefault();
        this.setState({loading:true});
        axios.post("/change-password",this.state.form)
        .then(response=>{
            this.setState({loading:false});
            if(response.data.errors) {
                this.setState({errors: response.data.errors});
                alertify.error(response.data.msg);
                return false;
            }else{
                this.setState({errors: []})
                alertify.success(response.data.msg);
            }
        })
        .catch(function (error) {
        });
    }

    render()
    {
        alertify.defaults = {
            notifier: {
                position: 'top-right',
                delay: 5
            }
        }
        return (
            <div className={"dashboard-page-wrapper"}>
                <div className={"dashboard-page-navigations password"}>
                    <div className="heading UserAccountHeader" style={{margin:0, width:"100%"}}>Change Password
                    </div>
                </div>
                <div className="row dashboard-add-user-body user-account">
                    <div className="col-sm-12">
                        <div className={"add-user-credentials change-user-credentials"}>
                            <input className={"user-account-forms"}  type={"password"} placeholder={"Current Password"} value={this.state.CurrentPassword} onChange={this.handleInputChange.bind(this, "CurrentPassword")} />
                            {
                                this.state.errors['CurrentPassword'] ? 
                                <span className="error">{this.state.errors["CurrentPassword"]}</span>
                                : null
                            }

                            <input className={"user-account-forms"} type={"password"} placeholder={"New Password"} value={this.state.password} onChange={this.handleInputChange.bind(this, "password")}/>
                            {
                                this.state.errors['password'] ? 
                                <span className="error">{this.state.errors["password"]}</span>
                                : null
                            }

                            <input className={"user-account-forms"} type={"password"} placeholder={"Confirm New Password"} value={this.state.password_confirmation} onChange={this.handleInputChange.bind(this, "password_confirmation")}/>
                            {
                                this.state.errors['password_confirmation'] ? 
                                <span className="error">{this.state.errors["password_confirmation"]}</span>
                                : null
                            }
                        </div>
                        <div className={"add-user-cancel-save-buttons-wrapper"}>
                            <div className={"add-user-save-button"} onClick={this.handleSave.bind(this)}>Save</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

