import React, { Fragment } from "react";
import { useTranslation } from 'react-i18next';
// import "./PreferencesForm.style.css";
import PreferencesFormItem from "admin/administrator/PreferencesFormItem/PreferencesFormItem.component";

const PreferencesForm = ({ handleSwitchChange, form, isBranch, showElements, handleOrder = () => {} }) => {
    const { t } = useTranslation();
    let elements = showElements;
    if(!isBranch) {
        elements = showElements.filter(function( obj ) {
            return obj.title != 'account_selection' && obj.title != 'admin' && obj.title != 'show_supplier_stock_info';
        });
    }
    return elements.map( ({ label, title, options= false, gap= false, sortables= false }) => (
            <Fragment>   
                <PreferencesFormItem 
                    label={ t(label) }
                    title={ title }
                    options={ options }
                    sortables={ sortables }
                    handleChange={ handleSwitchChange }
                    handleOrder={ handleOrder }
                    value={ form[title] || '0' }
                />
                {/* { gap ? <span className="settings-span-hr"></span> : null } */}
            </Fragment>
    ))
};

export default PreferencesForm;
