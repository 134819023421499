import React, { Fragment, useState, useEffect } from "react";
import axios from "axios1";

const IsAdminOrBranch = (props) => {
    const [enableAccess, setEnableAccess] = useState(false);
    
    useEffect(() => {
        axios.get("/catalogue/check-user").then((response) => {
            if( response.data.admin ||  response.data.account.business_type_id == 3 ) {
                setEnableAccess(true);
            }
        })
    }, []);

    return enableAccess ? props.children : null;
}

export default IsAdminOrBranch;