var store = require('store');

let initState = {
    date: new Date()
}

const dashboardDate = (state = initState, action) => {
    switch (action.type) {
        case 'SET_DATE':
            return {
                ...state,
                date: action.date
            }
        default:
            return state
    }
}

export default dashboardDate