import LogoSpartan from "images/spartan-logo.png";
import LogoQuickco from "images/quickco-logo.jpg";
import LogoNordic from "images/nordic-logo.png";
import LogoNK from "images/nk-logo.png";
import LogoStoneacre from "images/stoneacre_logo.png";
import LogoEuroBrake from "images/eurobrake_logo.png";
import LogoViking from "images/viking_logo.png";
import LogonewNordic from "images/nordicNewLogo.png";
import LogoGT from "images/gt-exhaust-logo.png";
import LogoTitan from "images/titan-logo.png";
import LogoWessling from "images/wessling-logo.svg";

let logo = LogoNordic;
let size = "187.5px";
switch(process.env.REACT_APP_CLIENT){
    case "spartan": 
        logo = LogoSpartan; 
        size = "160.5px";
    break;
    case "quickco": 
        logo = LogoQuickco; 
        size = "110px";
    break;
    case "nk": 
        logo = LogoNK; 
        size = "70px";
    break;
    case "stoneacre": 
        logo = LogoStoneacre; 
        size = "150px";
    break;
    case "eurobrake": 
        logo = LogoEuroBrake; 
        size = "110px";
    break;
    case "newNordic": 
        logo = LogonewNordic; 
        size = "180px";
    break;
    case "gt": 
        logo = LogoGT; 
        size = "155px";
    break;
    case "viking": 
        logo = LogoViking; 
        size = "250px";
    break;
    case "titan": 
        logo = LogoTitan; 
        size = "164px";
    break;
    case "wessling": 
        logo = LogoWessling; 
        size = "164px";
    break;
    default: break;
}

export default { logo: logo, size: size };