import React, {Component, Fragment} from 'react';
import {BrowserRouter as Router, Route, NavLink} from "react-router-dom";
import {connect} from "react-redux";
// import "admin/administrator/AdminHeaderLeft.css";
import Icon from 'components/Icon';

var store = require('store');

class AdminHeaderLeft extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <Fragment>
                {  
                    this.props.auth && this.props.auth.user && this.props.auth.user.admin ? 
                    <div className="leftbar">
                        <div className="leftnav">
                            <ul className="leftnav__list">
                                <li className="leftnav__item">
                                    <NavLink to="/admin/accounts" className={"leftnav__link"} activeClassName="leftnav__link--active">
                                        <Icon file="User-cog.svg" type="dark" width="36px" height="36px"/>
                                    </NavLink>
                                </li>
                                <li className="leftnav__item">
                                    <NavLink to="/admin/selectAccount" className={"leftnav__link"} activeClassName="leftnav__link--active">
                                        <Icon file="user-plus.svg" type="dark" width="36px" height="36px"/>
                                    </NavLink>
                                </li>
                                <li className="leftnav__item">
                                    <NavLink to="/admin/settings" className={"leftnav__link"} activeClassName="leftnav__link--active">
                                        <Icon file="book-cog.svg" type="dark" width="36px" height="36px"/>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div> : null  
                } 
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
})

export default  connect(mapStateToProps, null, null, {pure: false})(AdminHeaderLeft);
