import React, {useState, useEffect, Fragment} from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import AdvancedSearchMenu from "components/AdvancedSearchMenu";
import EasySearchMenu from "components/EasySearchMenu";
import AlertTooltip from "components/AlertTooltip";
import product from "redux/reducers/products";
import { useTranslation } from 'react-i18next';

const SearchByMake = props => {
    const [vehiclesCount, setVehiclesCount] = useState(null);
    const [selectedAtLeastOne, setSelectedAtLeastOne] = useState(null);

    useEffect(() => {
        if(props.clearVehicle) {
            setVehiclesCount(null)
            setSelectedAtLeastOne(null)
        }
    }, [props.clearVehicle])

    const { t, i18n } = useTranslation();
    return (
        <Fragment>
            <div className="vcount">
                    {
                        vehiclesCount > 0 && (!props.clearVehicle ? true : ( !props.nonAutoActive ? true : false )) ?
                            <span className={"vcount__text " + (vehiclesCount == 1 ? 'vcount__text--single' : '')}>
                                {vehiclesCount} {t("Vehicle")}{vehiclesCount > 1 ? 's' : null} {t("Found")}
                            </span>
                            :
                            selectedAtLeastOne ?
                                <span className={"vcount__text " + (vehiclesCount == 1 ? 'vcount__text--single' : '')}>0 {t("Vehicle")} {t("Found")}</span>
                            : null
                    }
                    {
                        vehiclesCount > 1 && !props.clearVehicle ?
                            <AlertTooltip bgColor={"#4175A6"} 
                                        textPosition={"textRight"} 
                                        text={t("To reduce the chance of ordering incorrect parts, select more vehicle details")}/> 
                            : null
                    }
            </div>
            {
                props.settings.search_type == "advanced" ?
                    <AdvancedSearchMenu setVehiclesCount={setVehiclesCount.bind(this)} 
                                    setSelectedAtLeastOne={setSelectedAtLeastOne.bind(this)}
                                    countProductsQuantity={props.countProductsQuantity} 
                                    clearVehicle={props.clearVehicle}
                                    enable_vehicle_selection={props.settings.enable_vehicle_selection}
                                    nonAutoActive= {props.nonAutoActive}
                                    setClearRegNumber={props.setClearRegNumber}
                                    setClearPartNumber={props.setClearPartNumber}
                                    setClearVehicle={props.setClearVehicle}
                                    settings={props.settings}
                                    setClearVinNumber={props.setClearVinNumber}/>
                : <EasySearchMenu setStateFromChild={props.setStateFromChild} countProductsQuantity={props.countProductsQuantity} />
            } 
        </Fragment>
    )
}

const mapStateToProps = state => ({
    token: state.auth.token,
    options: state.products,
    settings: state.settings
})

const mapDispatchToProps = dispatch => {
    return {
        saveSelectedOptions: (options) => dispatch({type: 'SAVE_SELECTED_OPTIONS', payload: options}),
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchByMake));
