import React, { useEffect, useState } from 'react';
// import "./SignUp.css";
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from "react-redux";
import axios from 'axios1';
import _ from "lodash";
import successIcon from "images/success-png.png";
import FormInput from "admin/administrator/FormInput/FormInput.component";
import { useTranslation } from 'react-i18next';

var store = require('store');

function SignUp(props) {
    const { t, i18n } = useTranslation();
    const [accounts, setAccounts] = useState([]);
    const [errors, setErrors] = useState([]);
    const [businessTypes, setBusinessTypes] = useState([]);
    const [form, setForm] = useState({
        name: '',
        business_type_id: null,
        branch_id: null,
        name: '',
        contact_name: '',
        email: '',
        phone: '',
        address_1: '',
        address_2: '',
        town: '',
        county: '',
        postal_code: '',
    });

    useEffect(() => {
        getBusinessTypes();
    }, []);

    function handleInputChange(event) {
        let f = {...form};
        f[event.target.name] = event.target.value;
        if(event.target.name == 'business_type_id') {
            getAccountsByBusinessType(f[event.target.name]);
        }
        setForm(f);
    }

    function getAccountsByBusinessType(id) {
        axios.get('/catalogue/accounts-by-business-type/' + id).then(response => {
            setAccounts(response.data.accounts);
        })
    }

    function getBusinessTypes() {
        axios.get('/catalogue/getBusinessTypes').then(response => {
            let businessTypesWithoutSupplierAndBranch = response.data.filter((bt) => {
                return bt.id != 9 && bt.id != 3;
            });
            setBusinessTypes(businessTypesWithoutSupplierAndBranch);
        })
    }

    function handleSubmit(e) {
        e.preventDefault();
        axios.post('/register', form).then(response => {
            if(!response.data.token) {
                window.scrollTo(0, 0);
                setErrors(response.data.errors);               
                if( response.data.errors && response.data.errors['approval'] ) {
                    var form = [];
                    _.map(form, (value, key) => {
                        form[key] = '';
                    });
                    setForm(form);
                }
                return false;
            }
            const token = response.data.token;
            store.set("token", token);
            props.afterRegistration(token)
            props.history.push('/search');            
        });
    }

    return (
        <div className="signup">
            <img src={props.logo.logo} width={props.logo.size}/>
            {
                errors && !errors['approval'] ?
                <form onSubmit={handleSubmit.bind(this)}>
                    <h1 className="text-center mb-8 fw-400">{t("Create Account")}</h1>
                    <p className="text-center mb-40">{t("Please, fill all required fields marked with '*'")}</p>
                    <div className="grid">
                        <div className="grid__row">
                            <div className="grid__column grid__column--6">
                                <FormInput
                                    name='name'
                                    type='text'
                                    placeholder={t("Name Of Company/Account")+"*"}
                                    error={errors.name}
                                    value={form.name}
                                    onChange={handleInputChange.bind(this)}
                                />
                            </div>
                            <div className="grid__column grid__column--6">
                                <div className="selector">
                                    <select className={"selector__selector " + (errors && errors["business_type_id"] ? "selector__selector--error" : "")}
                                            name="business_type_id"
                                            onChange={handleInputChange.bind(this)}>
                                        <option value="">{t("Select business type")}</option>
                                        {
                                            businessTypes.map((item) => {
                                                return <option key={item.id} value={item.id}>{item.name}</option>
                                            })
                                        }
                                    </select>
                                    {
                                        errors && errors["business_type_id"] ? <span className="selector__error">{errors["business_type_id"]}</span> : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="grid__row">
                            <div className="grid__column grid__column--6">
                                <FormInput
                                    name='contact_name'
                                    type='text'
                                    placeholder={t("Full Name")+"*"}
                                    error={errors.contact_name}
                                    value={form.contact_name}
                                    onChange={handleInputChange.bind(this)}
                                />
                            </div>
                            <div className="grid__column grid__column--6">
                                <FormInput
                                    name='email'
                                    type='text'
                                    placeholder={t("Email address (the username)")+"*"}
                                    error={errors.email}
                                    value={form.email}
                                    onChange={handleInputChange.bind(this)}
                                />
                            </div>
                        </div>
                        <div className="grid__row">
                            <div className="grid__column grid__column--6">
                                <FormInput
                                    name='phone'
                                    type='text'
                                    placeholder={t("Contact no")+"*"}
                                    error={errors.phone}
                                    value={form.phone}
                                    onChange={handleInputChange.bind(this)}
                                />
                            </div>
                            <div className="grid__column grid__column--6">
                                <FormInput
                                    name='address_1'
                                    type='text'
                                    placeholder={t("Address Line")+" 1*"}
                                    error={errors.address_1}
                                    value={form.address_1}
                                    onChange={handleInputChange.bind(this)}
                                />
                            </div>
                        </div>
                        <div className="grid__row">
                            <div className="grid__column grid__column--6">
                                <FormInput
                                    name='address_2'
                                    type='text'
                                    placeholder={t("Address Line")+" 2"}
                                    error={""}
                                    value={form.address_2}
                                    onChange={handleInputChange.bind(this)}
                                />
                            </div>
                            <div className="grid__column grid__column--6">
                                <FormInput
                                    name='town'
                                    type='text'
                                    placeholder={t("Town")+"*"}
                                    error={errors.town}
                                    value={form.town}
                                    onChange={handleInputChange.bind(this)}
                                />
                            </div>
                        </div>
                        <div className="grid__row">
                            <div className="grid__column grid__column--6">
                                <FormInput
                                    name='county'
                                    type='text'
                                    placeholder={t("County")}
                                    error={""}
                                    value={form.county}
                                    onChange={handleInputChange.bind(this)}
                                />
                            </div>
                            <div className="grid__column grid__column--6">
                                <FormInput
                                    name='postal_code'
                                    type='text'
                                    placeholder={t("Post Code / Zip")}
                                    error={""}
                                    value={form.postal_code}
                                    onChange={handleInputChange.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                    {
                        errors && errors['name'] ?
                            <div className={"text-danger fw-700 text-center mb-16"}>*{t("Required")}</div>
                            : null
                    }
                    <button type="submit" style={{background: (props.colors[props.client] || props.colors['default'])}}>{t("Sign Up")}</button>
                    <div className="flex flex-justify-center mt-16">{t("Already have an account")}? <span className="text-primary ml-4 cursor-pointer fw-700 hover-underline" onClick={() => props.showSigninForm()}>{t("Sign In")}</span></div>
                </form> : 
                <div className={"submited"}>
                    <img src={ successIcon } alt="check" />
                    <h3>{t("Your catalogue application has been submitted successfully")}!</h3>
                    <p>{errors['approval']}</p>
                    <span onClick={() => props.showSigninForm()}><i class="fas fa-arrow-left"></i> {t("Back to login")}</span>
                </div>
            }
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        afterRegistration: (token) => dispatch({ type: 'AFTER_REGISTRATION', payload: token }),
    }
}

export default withRouter(connect(
    null,
    mapDispatchToProps
)(SignUp));