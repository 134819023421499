export const settingPreferencesForm = [
    { label: "Allow online registrations from website", title: "registration_from_site" },
    { label: "Online registrations from website require approval", title: "approve_users" },
    { label: "Enable Shopping Cart", title: "enable_shopping_cart" },
    { label: "Show images in shopping Cart", title: "images_in_shopping_cart", gap: true },
    { label: "Show product brand logos in results", title: "show_brand_logos" },
    { label: "Show prices", title: "show_prices", gap: true },
    // {   
    //     label: "Search Types",
    //     title: "search_types",
    //     sortables: [
    //         {name: "Vehicle registration (vrm)", value: "vrm", checked: true},
    //         {name: "VIN (chassis number)", value: "vin", checked: true},
    //         {name: "NON vehicle related parts", value: "non", checked: true},
    //     ], 
    //     gap: true 
    // },
    {   
        label: "Show stock in search results as",
        title: "stock_visibility",
        options: [
            {name: "In/Out of stock", id: "inOut"},
            {name: "Actual stock quantity", id: "quantity"},
            {name: "Do not display stock", id: "hidden"},
        ], 
        gap: true 
    },
    { label: "Multi-Select Groups", title: "multi_select_groups", gap: true },
    { label: "Enable Account Selection", title: "account_selection", gap: true },
    { label: "Enable search by vehicle selection", title: "enable_vehicle_selection", gap: true },
    { label: "Enable VRM vehicle confirmation", title: "vrm_vehicle_confirmation" },
    { label: "Enable Search Filter", title: "search_filter", gap: true },
    { label: "Enable Lost Sale", title: "lost_sale",  gap: true },
    { label: "Enable callback request", title: "enable_request_call_btn", gap: true},
    { label: "Display Full info button in results", title: "show_full_info", gap: true },
    { label: "Enable Supplier Stock in Full info", title: "show_supplier_stock_info", gap: true },
]

export const userPreferencesForm = (disabled) =>{
    let formItems =  [
        { label: "Enable Administrator Privileges", title: "admin" },
        { label: "Enable Shopping Cart", title: "enable_shopping_cart" },
        { label: "Show images in shopping Cart", title: "images_in_shopping_cart", gap: true },
        { label: "Show product brand logos in results", title: "show_brand_logos" },
        { label: "Show prices", title: "show_prices", gap: true },
        // {   
        //     label: "Search Types",
        //     title: "search_types",
        //     sortables: [
        //         {name: "Vehicle registration (vrm)", value: "vrm", checked: true},
        //         {name: "VIN (chassis number)", value: "vin", checked: true},
        //         {name: "NON vehicle related parts", value: "non", checked: true},
        //     ], 
        //     gap: true 
        // },
        {   
            label: "Show stock in search results as",
            title: "stock_visibility",
            options: [
                {name: "In/Out of stock", id: "inOut"},
                {name: "Actual stock quantity", id: "quantity"},
                {name: "Do not display stock", id: "hidden"},
            ], 
            gap: true 
        },
        { label: "Multi-Select Groups", title: "multi_select_groups", gap: true },
        { label: "Enable Account Selection", title: "account_selection", gap: true },
        { label: "Enable search by vehicle selection", title: "enable_vehicle_selection", gap: true },
        { label: "Enable VRM vehicle confirmation", title: "vrm_vehicle_confirmation" },
        { label: "Enable Search Filter", title: "search_filter", gap: true },
        { label: "Enable Lost Sale", title: "lost_sale",  gap: true },
        { label: "Enable callback request", title: "enable_request_call_btn", gap: true },
        { label: "Display Full info button in results", title: "show_full_info", gap: true },
        { label: "Enable Supplier Stock in Full info", title: "show_supplier_stock_info", gap: true },
    ];

    disabled = disabled();
    if(disabled.length > 0 ) {
        formItems = formItems.filter( element => !disabled.includes(element.title));
    }
    return formItems;
}