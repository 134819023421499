import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from "components/catalogue/App";
import 'alertifyjs/build/css/alertify.css'
// import './index.css';
import * as serviceWorker from './serviceWorker';
import './i18n';



ReactDOM.render(
    <Suspense fallback="">
        <App />
    </Suspense>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
