import React from "react";
import _ from "lodash";
import { useTranslation } from 'react-i18next';

function CustomAdditionalNotes(props) {

    const { t, i18n } = useTranslation();
    return (
        <input name={"description"} 
                className={"additional-info"}
                placeholder={t("my_order_num")} 
                onChange={(e) => props.updateAdditionalNotesValue(e.target.value)}
                value={props.notes}
                maxlength={20} />
    ); 
}

export default CustomAdditionalNotes;